import { PatchQuery } from '../../common/PatchQuery';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';

export default class SiteClimateControlUpdateEmailsCommand extends PatchQuery<SiteClimateControlDto> {
  alertEmails: string[];

  constructor(id: number, alertEmails: string[]){
    super(id);
    this.alertEmails = alertEmails;
  }

  public targetUrl(): string {
    return '/ClimateControl/Site/UpdateAlertEmails';
  }
}