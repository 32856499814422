import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType'
import { Label } from '@shared/components/atoms/Form/Form';
import { t } from 'i18next';
import { transparentize } from 'polished';
import { MutableRefObject, RefObject } from 'react';
import styled, { css } from 'styled-components';

type PropTypes = {
  tabRefs: MutableRefObject<{ [key: number]: HTMLDivElement | null }>,
  selected: number
  onTabClick: (utility: EnergyMeterType, currentIndex: number) => void,
  lineRef: RefObject<HTMLDivElement>,
  hasMeterNumber?: boolean,
  hasMeterReading?: boolean,
  hasConsumption?: boolean,
  onUtilityDialogWarningToggle: (nextSelectedUtility: EnergyMeterType, nextIndex: number) => void,
  helperDataMeterType?: EnergyMeterType;
}

type UtilityTabs = {
  utilityType: EnergyMeterType,
  icon: IconDefinition,
  width: number,
  color: string,
};

export const utilityTabs: UtilityTabs[] = [
  { utilityType: EnergyMeterType.Electricity, icon: solid('bolt'), width: 13, color: '#FFB500' },
  { utilityType: EnergyMeterType.Heating, icon: solid('fire-flame-curved'), width: 13, color: '#FF821B' },
  { utilityType: EnergyMeterType.Cooling, icon: solid('snowflake'), width: 13, color: '#0074e4' },
  { utilityType: EnergyMeterType.Gas, icon: solid('fire'), width: 13, color: '#00C851' },
  { utilityType: EnergyMeterType.Water, icon: regular('droplet'), width: 13, color: '#1B98FF' }
]

const BillingMeterUtilityTabs = (
  {
    tabRefs,
    selected,
    onTabClick,
    lineRef,
    hasMeterNumber,
    hasMeterReading,
    hasConsumption,
    onUtilityDialogWarningToggle
  }: PropTypes) => {

  return (
    <Tabs>
      {utilityTabs.map((utilityTab, i) => {
        return (
          <Tab
            key={utilityTab.utilityType.toString()}
            ref={element => tabRefs.current[i] = element}
            selected={selected === i}
            onClick={() => {
              if (hasMeterNumber && (hasMeterReading || hasConsumption)) {
                return onUtilityDialogWarningToggle(utilityTab.utilityType, i);
              }
              return onTabClick(utilityTab.utilityType, i)
            }}
            color={utilityTab.color}
          >
            <Icon icon={utilityTab.icon} $width={utilityTab.width} />
            <Text>{t(`UtilityConsumption.${utilityTab.utilityType.toString()}`, { ns: 'molecules' })}</Text>
          </Tab>
        );
      })}
      <TabLine ref={lineRef} />
    </Tabs>
  )
}

export default BillingMeterUtilityTabs;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Tab = styled.div<{ selected: boolean, color: string }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 12px;
  transition: all 150ms ease;
  color: ${p => transparentize(0.2, p.theme.palette.text.fair)};
  cursor: pointer;

  ${p => p.selected && css`

    ${Label} {
      color: ${p => p.theme.palette.text.medium};
    }

    ${Icon} {
      color: ${p.color};
    }
  `}
`;

const TabLine = styled.div`
  height: 2px;
  background-color: ${p => p.theme.palette.primary};
  transition: left 300ms ease, width 300ms ease;

  position: absolute;
  bottom: 0px;
  left: 0;
`;

const Icon = styled(FontAwesomeIcon) <{ $width?: number }>`
  width: ${p => p.$width ?? 17}px;
  height: 17px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  user-select: none;
`;
