import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { AlertRuleTrigger } from '@shared/api/models/AlertRule/AlertRule';
import { Select } from '@shared/components/atoms/Select/Select';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type TriggerMetricSelectProps = {
  trigger: AlertRuleTrigger;
  onChange: (trigger: AlertRuleTrigger) => void;
}

const TriggerMetricSelect = ({ trigger, onChange }: TriggerMetricSelectProps) => {
  const { t } = useTranslation();

  const options = useMemo(() => [
    {
      label: t('TriggerConfiguration.Device', { ns: 'settingsAlerting' }),
      options: [
        { label: t(MetricType.BatteryVoltage, { ns: 'enums' }), value: MetricType.BatteryVoltage },
        { label: t(MetricType.RSSI, { ns: 'enums' }), value: MetricType.RSSI },
        { label: t(MetricType.SignalStrength, { ns: 'enums' }), value: MetricType.SignalStrength },
        { label: t(MetricType.SNR, { ns: 'enums' }), value: MetricType.SNR },
      ],
    },
    {
      label: t('TriggerConfiguration.Environmental', { ns: 'settingsAlerting' }),
      options: [
        { label: t(MetricType.Temperature, { ns: 'enums' }), value: MetricType.Temperature },
        { label: t(MetricType.Humidity, { ns: 'enums' }), value: MetricType.Humidity },
        { label: t(MetricType.CO2, { ns: 'enums' }), value: MetricType.CO2 },
        { label: t(MetricType.Light, { ns: 'enums' }), value: MetricType.Light },
        { label: t(MetricType.Noise, { ns: 'enums' }), value: MetricType.Noise },
        { label: t(MetricType.PIR, { ns: 'enums' }), value: MetricType.PIR },
        { label: t(MetricType.VOC, { ns: 'enums' }), value: MetricType.VOC },
        { label: t(MetricType.Pressure, { ns: 'enums' }), value: MetricType.Pressure },
        { label: t(MetricType.CO, { ns: 'enums' }), value: MetricType.CO },
        { label: t(MetricType.O2, { ns: 'enums' }), value: MetricType.O2 },
        { label: t(MetricType.H2S, { ns: 'enums' }), value: MetricType.H2S },
        { label: t(MetricType.Pollutants, { ns: 'enums' }), value: MetricType.Pollutants },
        { label: t(MetricType.Particulates2_5, { ns: 'enums' }), value: MetricType.Particulates2_5 },
        { label: t(MetricType.Particulates10, { ns: 'enums' }), value: MetricType.Particulates10 },
        { label: t(MetricType.WaterLeak, { ns: 'enums' }), value: MetricType.WaterLeak },
        { label: t(MetricType.WaterMeterAlarm, { ns: 'enums' }), value: MetricType.WaterMeterAlarm },
      ],
    },
    {
      label: 'Occupancy',
      options: [
        { label: t(MetricType.CalculatedOccupancy, { ns: 'enums' }), value: MetricType.CalculatedOccupancy },
        { label: t(MetricType.IsolatedPeopleCount, { ns: 'enums' }), value: MetricType.IsolatedPeopleCount }
      ]
    },
    {
      label: 'Weather',
      options: [
        { label: t(MetricType.WindSpeedAverage, { ns: 'enums' }), value: MetricType.WindSpeedAverage },
        { label: t(MetricType.WindDirectionAverage, { ns: 'enums' }), value: MetricType.WindDirectionAverage }
      ],
    }
  ], [t]);

  return (
    <Select
      value={trigger.metric && { label: t(trigger.metric, { ns: 'enums' }), value: trigger.metric }}
      options={options}
      onChange={selected => selected && onChange({ metric: selected.value })}
      menuHeight={250}
    />
  );
};

export default TriggerMetricSelect;