import { Space } from '@shared/api/models/Space/Space';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class TenantUsersUpdateCommand extends PostQuery<Space> {
  userId: number;
  tenantsToAdd?: string[];
  tenantsToRemove?: string[];

  constructor(userId: number, tenantsToAdd: string[], tenantsToRemove: string[]) {
    super();
    this.userId = userId;
    this.tenantsToAdd = tenantsToAdd;
    this.tenantsToRemove = tenantsToRemove;
  }

  public targetUrl(): string {
    return '/tenants/TenantUsersUpdate';
  }
}
