import EsgMetricType from '@dashboard/api/enums/EsgMetricType/EsgMetricType';
import { MetricTypesToCalculateEsgMetric, getEsgExplorerMetricOptions } from '@dashboard/configs/esgExplorer/EsgExplorer_MetricOptions';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Select } from '@shared/components/atoms/Select/Select';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TOutlierConfig } from './OutlierWidget';
import Filter from './Filter';
import { Button } from '@shared/components/atoms/Button/Button';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

type OptionGroup = {
  label: string;
  options: Option[];
}

type Option = {
  label: string;
  value: EsgMetricType;
}

export const esgMetricTypeExistInSite = (metricTypes: MetricType[], esgMetricType: EsgMetricType) => {
  for (const metricType of MetricTypesToCalculateEsgMetric[esgMetricType]) {
    if (metricTypes.includes(metricType)) {
      return true;
    }
  }

  return false;
};

type PropTypes = {
  config: TOutlierConfig;
  siteMetricTypes?: MetricType[];
  onChange: (config: TOutlierConfig) => void;
}

const MetricSelect = ({ config, siteMetricTypes, onChange }: PropTypes) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { getUnit, localisation } = useLocalisation();
  const [options, setOptions] = useState<OptionGroup[]>([]);

  useEffect(() => {
    if (!siteMetricTypes) {
      return;
    }

    const metricOptions: OptionGroup[] = [];

    getEsgExplorerMetricOptions(localisation).forEach(group => {
      const filteredOptions = group.options.filter(x => esgMetricTypeExistInSite(siteMetricTypes, x.value));

      if (filteredOptions.length > 0) {
        metricOptions.push({
          label: group.label ?? '',
          options: group.options
            .filter(opt => esgMetricTypeExistInSite(siteMetricTypes, opt.value))
            .map(opt => ({
              label: t(opt.label, { ns: 'enums', areaUnit: getUnit(AltUnits.Area) }),
              value: opt.value
            }))
        })
      }
    });

    setOptions(metricOptions);
  }, [config, siteMetricTypes, localisation, t, getUnit]);

  return (
    <FlexRow>
      <div style={{ width: 250 }}>
        <Select
          options={options}
          value={options.flatMap(x => x.options).find(x => x.value === config.metric)}
          onChange={option => {
            if (!option) {
              return;
            }

            onChange({
              ...config,
              metric: option.value,
              filter: undefined
            });
            trackAction('metric_select', 'overview_outliers', { metric: option.value, time_span: config.timeRangeType, filter: config.filter?.type });
          }}
          menuHeight={250}
        />
      </div>

      {config.filter &&
        <>
          <Filter
            config={config}
            onChange={filter => onChange({
              ...config,
              filter: filter
            })}
          />

          <Button
            small
            tertiary
            label={t('RemoveFilter', { ns: 'common' })}
            onClick={() => onChange({ ...config, filter: undefined })}
          />
        </>
      }

      {!config.filter &&
        <Button
          small
          tertiary
          label={t('AddFilter', { ns: 'common' })}
          onClick={() => onChange({ ...config, filter: { metric: config.metric } })}
        />
      }
    </FlexRow>
  );
};

export default MetricSelect;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;