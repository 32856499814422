import { Alert } from '@shared/api/models/Alert/Alert';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export class AlertsGetBySiteQuery extends PostQuery<Alert[]> {
  siteId: number;
  page: number;
  pageSize: number;

  constructor(siteId: number, page: number, pageSize: number) {
    super();
    this.siteId = siteId;
    this.page = page;
    this.pageSize = pageSize;
  }

  public targetUrl(): string {
    return '/alerting/GetAlertsBySite';
  }
}
