import { Trans } from 'react-i18next';
import styled from 'styled-components';
import ReportSectionHeading from './ReportSectionHeading';

const ReportGlossary = ({ pageHeaders }: { pageHeaders: JSX.Element[] }) => {
  return (
    <Wrapper>
      {pageHeaders[0]}

      <ReportSectionHeading label='Reading this report / Glossary of terms' />

      <p>
        <Trans i18nKey='molecules:ESG.KilowattHourText'>
          <Medium>Kilowatt Hour:</Medium> The kilowatt-hour (kWh) is a unit of energy consumption equal to one kilowatt of power sustained for one hour.
          If an appliance rated at 1000 watts was used for 1 hour, it would use 1 Kilowatt hour (kWh) of electricity. Similarly, a 500 watt
          appliance used for 2 hours would also use one kWh. Energy, especially electricity, is sold in kWh.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.HeatUsedText'>
          <Medium>Heat used:</Medium> Typically, a building’s Low Temperature Hot Water system (LTHW) heats water in the central boilers and circulates it
          around the building at around 70 degrees. A Heat Input Unit (HIU) in each apartment uses a heat exchanger to take heat from
          the LTHW system and use it to heat the water in the apartment’s own radiator system. Domestic Hot Water (DHW) is also
          heated by the HIU. Once heated, the (now cooler) LTHW is returned to the boilers to be reheated and recirculated. A heat
          meter in the apartment’s HIU measures the amount of heat taken from the LTHW system and reports it in kilowatt hours. Note
          that the total of all heat use reported does not equal the total amount of energy burned by the boilers. This is likely to be
          higher, as there are inefficiencies and system circulation temperature losses to contend with as well.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.ElectricityUsedText'>
          <Medium>Electricity used:</Medium> This is also measured in kWh and is measured at each apartment’s electricity meter. An appliance’s power
          rating multiplied by the number of hours it is used for will give the usage in kWh. There are various estimates on what
          constitutes average electricity use in the UK but most are between 8.5 to 12 kWh per day (255-360 kWh per month) for an
          average household of 2.4 people. This assumes that heating is not electrically powered.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.WaterUsedText'>
          <Medium>Water used:</Medium> Water use is measured in cubic meters of water – i.e. 1 cubic meter fits in a cube with all sides measuring one
          meter. To convert to litres, divide by 0.001. So, 1 cubic metre equals 1000 litres (and weighs about 1 metric tonne).
          The Energy Saving Trust has calculated that the typical person uses 142 litres of water per day (equal to about 4.2 cubic meters
          in an average month). A modern shower will use around 9 litres of water per minute but a power shower may use nearly double
          that. A dishwasher uses around 9.5 litres of water per cycle and a washing machine uses around 7.5 litres per kilogram of load.
          Dual flush toilets typically use 4-6 litres of water per flush and a running tap uses approximately 6 litres per minute.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.CarbonEmissionText'>
          <Medium>Carbon Emissions:</Medium> Measured in grammes (or kilogrammes) of Carbon Dioxide per kWh – this is the amount of Carbon Dioxide
          released into the atmosphere as a result of energy generation and use. For electricity, this varies depending on the mix of
          generation sources. In some areas CO2 emissions are very low because their energy comes predominantly from renewable
          sources such as wind, solar and hydroelectric. As demand rises, non-renewable sources that burn fossil fuels are brought online
          to meet demand, thus the emissions factor rises. Dividing the emissions per kWh by the floor area of each apartment allows us
          to compare all apartments emissions equally. This is expressed in kilogrammes of CO2 per square meter. A person in a studio
          apartment may use less heat in total than someone in a two bed apartment, but when examined on a per square meter basis
          they may be using much more and over heating their space, or leaving windows open whilst heating is on.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.PerformanceScoresText'>
          <Medium>Typical Performance Scores:</Medium> The average of the measurements taken from all apartments of that type, presented as a typical
          apartment against which all other apartments of that type can be compared.
          The histogram graphs for heat, electricity and water usage split the range of usage values across all apartments of a particular
          type into 10 "buckets" and the number of apartments that fall into each usage bucket is displayed on the Y axis. The typical
          apartment is in the bucket displayed in a slightly darker colour. The further to the right a bucket is, the more of that resource is
          being used.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.AverageTemperatureText'>
          <Medium>Average Temperature:</Medium> The average room temperature in the living space of the apartment over the reporting period. The Low
          and High markers indicate the range of average maximum and minimum temperatures seen in apartments of the type.
          Typically, during the winter heating season (October to April), the Energy Saving Trust recommends heating a living space to
          between 18-21 degrees C.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.HumidityText'>
          <Medium>Humidity:</Medium> The amount of moisture (water vapour) contained in the air is called humidity. The higher the temperature of the air
          the more water it is able to hold. The term ‘relative humidity’ describes how much moisture air contains at a given temperature.
          100% relative humidity means the air is holding as much moisture as it can at that temperature.
          The more humid the air, the more energy it takes to warm the space because it is also warming the water in the air. This means
          it will be more expensive to heat. A good range of indoor humidity for comfort and health is between 30-60% during cooler
          months of the year. Mould is likely to occur if the relative humidity indoors is 70% or more for long periods of time. Keeping
          humidity levels under 50% also helps to minimise or control dust mites. But it is also possible to be too dry. Humidity levels
          below about 30% lead to dry skin and nasal passages, increasing the potential for respiratory illnesses.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.CO2Text'>
          <Medium>CO2:</Medium> The amount of carbon dioxide in the air, measured in parts per million (PPM). Lower than 400 is normal background CO2
          in outdoor ambient air. 400-1000 ppm are typical of occupied indoor spaces with good air exchange. 1000-2000 ppm
          Complaints of drowsiness and poor air. 2000-5000 ppm - Headaches, sleepiness, and stale stuffy air. Poor concentration, loss of
          attention, increased heart rate and slight nausea may be present. 5000ppm - Workplace exposure limit in most countries. Note
          that short spikes in CO2 readings can sometimes be triggered by residents spraying aerosols containing volatile organic
          compounds (VOCs) such as hairspray or deodorant near the sensor.
        </Trans>
      </p>

      {pageHeaders[1]}

      <p>
        <Trans i18nKey='molecules:ESG.OccupancyText'>
          <Medium>Occupancy:</Medium> Based on whether movement was seen in a space in a given 24 hour period. The higher the number of movements
          per day, the more likely this is to be caused by an occupier, but of course, the sensor cannot tell whether this was a resident or
          a housekeeping or maintenance visit.
        </Trans>
      </p>

      <p>
        <Trans i18nKey='molecules:ESG.ComfortText'>
          <Medium>Comfort:</Medium> A combined score, made up of temperature, CO2 levels, light levels, humidity and noise. A maximum of 10 would be
          equate to a temperature of around 19-20 degrees, humidity between 40 & 50%, CO2 levels less than 700 ppm, light above 1000
          lux and noise below 60dB.
        </Trans>
      </p>


      <p style={{ marginBottom: '1mm' }}>
        <Trans i18nKey='molecules:ESG.AllApartmentValuesHeading'>
          <Medium></Medium>
        </Trans>
      </p>
      <Trans i18nKey='molecules:ESG.AllApartmentValuesList'>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </Trans>


      <p style={{ marginBottom: '1mm' }}>
        <Trans i18nKey='molecules:ESG.NoiseHeading'>
          <Medium></Medium>
          <br /><br />
        </Trans>
      </p>

      <Trans i18nKey='molecules:ESG.NoiseList'>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </Trans>
    </Wrapper>
  );
};

export default ReportGlossary;

const Wrapper = styled.div`
  display: none;

  @media print {
    display: block;
    font-size: 8pt;
    color: ${p => p.theme.palette.printStyles.text.fair};
  }
`;

const Medium = styled.span`
  font-weight: 500;
  color: ${p => p.theme.palette.printStyles.text.medium};
`;