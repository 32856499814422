import styled from 'styled-components';
import EsgExplorerRuleDto from '@dashboard/api/models/EsgExplorerRuleDto';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { Site } from '@shared/api/models/Site/Site';
import Rule from './Rule';
import { useTranslation } from 'react-i18next';

interface IRuleList {
  site: Site;
  rules: EsgExplorerRuleDto[];
  refreshRules: () => void;
  loading: boolean;
}

const RuleList = ({ site, rules, refreshRules, loading }: IRuleList) => {
  const { t } = useTranslation(['molecules']);

  return (
    <Table>
      <TitleRow style={{ fontWeight: 500 }}>
        <div style={{ width: 130 }}>
          {t('ESG.Name', { ns: 'molecules' })}
        </div>
        <div style={{ width: 100 }}>
          {t('ESG.Schedule', { ns: 'molecules' })}
        </div>
        <div style={{ width: 60 }}>
          {t('Enabled', { ns: 'common' })}
        </div>
      </TitleRow>

      {loading &&
        <Loading />
      }

      {!loading && rules.map((rule) => (
        <Rule
          key={rule.id}
          rule={rule}
          refreshRules={refreshRules}
          siteName={site.name}
        />
      ))}
    </Table>
  );
};

export default RuleList;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 18px 15px;

  font-weight: 500;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};

  > * {
    flex-shrink: 0;
  }
`;