import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IPopover, PopoverPlacement } from './Popover.types';

export const Popover = React.forwardRef<HTMLDivElement, IPopover>(({
  popoverContent,
  visible,
  placement,
  color,
  offsetX,
  offsetY,
  alignWithTarget,
  hideTriangle,
  containerStyles,
  fixed,
  children
}, ref) => {
  const targetDivRef = useRef<HTMLDivElement>(null);
  const [fixedPosition, setFixedPosition] = useState<{ x: number, y: number }>();
  const [xOffset, setXOffset] = useState<number>(0);
  const [yOffset, setYOffset] = useState<number>(0);
  const distance = 7;

  const alignPosition = useCallback(() => {
    if (fixed) {
      const rect = targetDivRef.current?.getBoundingClientRect();
      rect && setFixedPosition({ x: rect.x, y: rect.y });
    } else {
      setFixedPosition(undefined);
    }
  }, [fixed]);

  useEffect(() => {
    const target = targetDivRef.current;

    if (!target) {
      return;
    }

    setXOffset(target.offsetWidth);
    setYOffset(target.offsetHeight);
  }, [targetDivRef, visible]);

  return (
    <Wrapper ref={ref} id="popover">
      {visible && (
        <PositioningContainer
          fixedPosition={fixedPosition}
          placement={placement}
          xOffset={xOffset + (offsetX ?? 0)}
          xOffsetExtra={offsetX ?? 0}
          yOffset={yOffset + (offsetY ?? 0)}
          distance={distance}
          alignWithTarget={alignWithTarget}
        >
          <Container style={containerStyles} color={color}>
            <TriangleWrapper placement={placement} hide={hideTriangle}>
              <Triangle placement={placement} color={color} />
            </TriangleWrapper>
            <Content>{popoverContent}</Content>
          </Container>
        </PositioningContainer>
      )}

      <div onMouseEnter={alignPosition} ref={targetDivRef}>{children}</div>
    </Wrapper>
  );
}
);

const Wrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  position: relative;
  z-index: 11;
`;

const PositioningContainer = styled.div<{ fixedPosition?: { x: number, y: number }, placement: PopoverPlacement; xOffset: number; yOffset: number; distance: number; xOffsetExtra: number; alignWithTarget?: boolean; }>`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;

  transform: ${({ placement, xOffset, yOffset, distance, xOffsetExtra }) =>
    (placement === PopoverPlacement.Bottom &&
      `translate(calc(-50% + ${xOffset / 2}px), ${yOffset + distance + 5}px)`) ||
    (placement === PopoverPlacement.BottomRight &&
      `translate(calc(-85% + ${xOffset / 2 + 8}px), ${yOffset + distance + 5}px)`) ||
    (placement === PopoverPlacement.BottomLeft &&
      `translate(calc(-15% + ${xOffset / 2 - 8}px), ${yOffset + distance + 5}px)`) ||
    (placement === PopoverPlacement.Top &&
      `translate(calc(-50% + ${xOffset / 2}px), calc(-100% - ${distance + 5}px))`) ||
    (placement === PopoverPlacement.TopRight &&
      `translate(calc(-85% + ${xOffset / 2 + 8}px), calc(-100% - ${distance + 5}px))`) ||
    (placement === PopoverPlacement.TopLeft &&
      `translate(calc(-15% + ${xOffset / 2 - 8}px), calc(-100% - ${distance + 5}px))`) ||
    (placement === PopoverPlacement.Right &&
      `translate(${xOffset + distance}px, calc(-50% + ${yOffset / 2}px))`) ||
    (placement === PopoverPlacement.Left &&
      `translate(calc(-100% - ${distance + xOffsetExtra}px), calc(-50% + ${yOffset / 2}px))`)};

  ${(p) => p.alignWithTarget && css`
    transform: ${(p.placement === PopoverPlacement.Bottom &&
      `translate(calc(-50% + ${p.xOffset / 2}px), ${p.yOffset + p.distance + 5}px)`) ||
    (p.placement === PopoverPlacement.BottomRight &&
      `translate(calc(-100% + ${p.xOffset}px), ${p.yOffset + p.distance + 5}px)`) ||
    (p.placement === PopoverPlacement.BottomLeft &&
      `translate(0, ${p.yOffset + p.distance + 5}px)`) ||
    (p.placement === PopoverPlacement.Top &&
      `translate(calc(-50% + ${p.xOffset / 2}px), calc(-100% - ${p.distance + 5}px))`) ||
    (p.placement === PopoverPlacement.TopLeft &&
      `translate(0, calc(-100% - ${p.distance + 5}px))`) ||
    (p.placement === PopoverPlacement.TopRight &&
      `translate(calc(-100% + ${p.xOffset}px), calc(-100% - ${p.distance + 5}px))`)};
  `}

  ${(p) => p.placement === PopoverPlacement.FixedTop && css`
    position: fixed;
    top: 70px;
    margin: 0 10px;
  `}

  ${p => p.fixedPosition && css`
    position: fixed;
    top: ${p.fixedPosition.y}px;
    left: ${p.fixedPosition.x}px;
  `}
`;

const Container = styled.div<{ visible?: boolean; color?: string }>`
  @keyframes blowUpPopover {
    0% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }

  animation: blowUpPopover 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;

  background-color: ${(p) => p.color ?? p.theme.palette.popover.background};
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 0 13px 3px ${(p) => p.theme.palette.popover.shadow};
`;

const TriangleWrapper = styled.div<{ placement?: PopoverPlacement; hide?: boolean; }>`
  display: ${(p) => (p.hide ? 'none' : 'block')};
  width: 100%;
  height: 100%;
  visibility: hidden;
  pointer-events: none;

  position: absolute;
  top: 0px;
  left: 0px;

  transform: ${(p) =>
    (p.placement === PopoverPlacement.Bottom &&
      'translate(calc(-50% + 8px), -5px)') ||
    (p.placement === PopoverPlacement.BottomRight && 'translate(-15%, -5px)') ||
    (p.placement === PopoverPlacement.BottomLeft &&
      'translate(calc(-85% + 16px), -5px)') ||
    (p.placement === PopoverPlacement.Top &&
      'translate(calc(-50% + 8px), calc(100% + -11px))') ||
    (p.placement === PopoverPlacement.TopLeft &&
      'translate(calc(-85% + 16px), calc(100% + -11px))') ||
    (p.placement === PopoverPlacement.TopRight &&
      'translate(-15%, calc(100% + -11px))') ||
    (p.placement === PopoverPlacement.Right &&
      'translate(calc(-100% + 11px), calc(50% - 8px))') ||
    (p.placement === PopoverPlacement.Left &&
      'translate(5px, calc(50% - 8px))')};
`;

const Triangle = styled.div<{ placement: PopoverPlacement; color?: string }>`
  width: 16px;
  height: 16px;
  background: ${(p) => p.color ?? p.theme.palette.popover.background};
  transform: rotate(45deg);
  border-radius: 4px;

  visibility: visible;
  position: absolute;
  top: 0px;
  right: 0px;
`;
