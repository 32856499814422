import BuildingPage_Heatmap from '@dashboard/components/organisms/BuildingPage/BuildingPage_Heatmap';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Route } from '@shared/types/Route';

export const HeatmapRoutes: Route[] = [
  {
    path: 'heatmap',
    element: BuildingPage_Heatmap,
    analytics: { page: 'heatmap', category: 'heatmap' },
    link: {
      label: 'Heatmap',
      path: 'heatmap',
      icon: regular('grid'),
      analytics: { action: 'heatmap', category: 'feature_navigation' }
    }
  },
];