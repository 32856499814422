import { SiteFairUsePolicy } from '@shared/api/models/FairUse/SiteFairUsePolicy';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class SiteFairUsePolicyGetByConfigurationIdQuery extends GetQuery<SiteFairUsePolicy[]> {
  configurationId: number;

  constructor(configurationId: number) {
    super();
    this.configurationId = configurationId;
  }

  public targetUrl(): string {
    return `/FairUse/policies/GetByConfigurationId/${this.configurationId}`;
  }
}