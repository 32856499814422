import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class UnreadAlertCountBySiteGetQuery extends GetQuery<number> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/alerting/GetAlertUnreadCountBySite/${this.siteId}`;
  }
}
