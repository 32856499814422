import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import EsgExplorerTemplateCreateCommand from '@dashboard/api/queries/esgExplorer/EsgExplorerTemplateCreateCommand';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import { Input } from '@shared/components/atoms/Form/Form';
import { useApi } from '@shared/hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

interface ISaveToTemplate {
  siteId: number;
  template: EsgExplorerTemplateDto;
  isEnabled?: boolean;
  inSavingState: boolean;
  setInSavingState: (state: boolean) => void;
  refreshTemplates: () => void;
}

const SaveToTemplate = ({ siteId, template, isEnabled, inSavingState, setInSavingState, refreshTemplates }: ISaveToTemplate) => {
  const { t } = useTranslation(['molecules']);
  const theme = useTheme();
  const { execute, loading } = useApi();
  const [templateName, setTemplateName] = useState<string>('');

  const reset = useCallback(() => {
    setInSavingState(false);
    setTemplateName('');
  }, [setInSavingState]);

  useEffect(() => {
    if (!isEnabled) {
      reset();
    }
  }, [isEnabled, reset]);

  const handleSave = useCallback(async () => {
    await execute({
      query: new EsgExplorerTemplateCreateCommand(siteId, templateName, template.configuration, template.private),
      successMessage: t('ESG.TemplateSaved', { ns: 'molecules' })
    });

    refreshTemplates();
    reset();
  }, [template, templateName, execute, refreshTemplates, reset, siteId, t]);

  return (
    <Row>
      <StepOne isVisible={!inSavingState}>
        <Button
          tertiary
          label={<><SaveIcon icon={solid('floppy-disk')} />{t('ESG.SaveAsTemplate', { ns: 'molecules' })}</>}
          onClick={() => setInSavingState(true)}
          disabled={!isEnabled}
          style={{ width: '100%' }}
        />
      </StepOne>

      <StepTwo isVisible={inSavingState}>
        <Input
          type="text"
          placeholder={t('ESG.TemplateName', { ns: 'molecules' })}
          value={templateName}
          onInput={(e) => setTemplateName(e.currentTarget.value)}
          style={{ width: '135px' }}
        />

        <Button
          tertiary
          label={t('Cancel', { ns: 'common' })}
          onClick={reset}
          disabled={loading}
          color={theme.palette.red}
          style={{ margin: '0 10px 0 15px' }}
        />
        <Button
          label={t('ESG.Save', { ns: 'molecules' })}
          onClick={handleSave}
          loading={loading}
        />
      </StepTwo>
    </Row>
  );
};

export default SaveToTemplate;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
`;

const StepOne = styled.div<{ isVisible: boolean; }>`
  position: absolute;
  left: 0px;
  opacity: 0;
  visibility: hidden;
  transition: all 150ms ease;
  width: 100%;

  ${p => p.isVisible && css`
    opacity: 1;
    visibility: visible;
  `}
`;

const StepTwo = styled.div<{ isVisible: boolean; }>`
  position: absolute;
  left: 0px; 
  opacity: 0;
  visibility: hidden;
  transition: all 150ms ease;

  ${p => p.isVisible && css`
    opacity: 1;
    visibility: visible;
  `}

  display: flex;
  align-items: center;
`;

const SaveIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  margin-right: 8px;
  color: ${p => p.theme.action.active};
`;