import PeopleCountingPhysicalDevice from '@settings/api/models/PeopleCounting/PeopleCountingPhysicalDevice';
import PeopleCountingAssignCommand from '@settings/api/queries/PeopleCounting/PeopleCountingAssignCommand';
import PeopleCountingDeviceDeleteCommand from '@settings/api/queries/PeopleCounting/PeopleCountingDeviceDeleteCommand';
import PeopleCountingUpdateCommand from '@settings/api/queries/PeopleCounting/PeopleCountingUpdateCommand';
import PeopleCountingZoneGetByIdQuery from '@settings/api/queries/PeopleCounting/PeopleCountingZoneGetByIdQuery';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PeopleCountingAssignDevicesList, { PhysicalDeviceFormValue } from './PeopleCountingAssignDevicesList';

const PeopleCountingZoneAssign = () => {
  const { t } = useTranslation(['settingsPeopleCounting']);
  const { virtualDeviceId } = useParams<{ virtualDeviceId: string }>();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { execute } = useApi();
  const { data: peopleCountingZone, transform: physicalDevicesData, loading, execute: fetchPhysicalDevices } = useApiState({
    query: virtualDeviceId === undefined ? undefined : new PeopleCountingZoneGetByIdQuery(parseInt(virtualDeviceId)),
    transformFunc: (devices) => devices.physicalDevices,
    initialTransformState: []
  }, [virtualDeviceId]);

  const onSave = async (physicalDevicesFormValues: PhysicalDeviceFormValue[]) => {
    setSavingInProgress(true);

    const promises: Promise<PeopleCountingPhysicalDevice | undefined>[] = [];

    const uniqueValues = new Set(physicalDevicesFormValues.map(v => v.physicalDeviceId));

    if (uniqueValues.size < physicalDevicesFormValues.length) {
      toast.error(t('ZoneAssign.DuplicateEntries', { ns: 'settingsPeopleCounting' }));
      return;
    }

    // Determine which devices have been deleted from the form
    const devicesFormValuesIds = physicalDevicesFormValues.map(x => x.id);

    const deleted = physicalDevicesData.filter(x => !devicesFormValuesIds.some(p => p === x.physicalDevice.id));

    deleted.forEach((device) => {
      deleteDevice(device);
    });

    physicalDevicesFormValues.forEach((formValue) => {

      // Create a new people counting if the object has no 'id' property
      if (formValue.id === undefined) {
        promises.push(assignDevice(formValue));
        return;
      }

      // Update people counting if device already exists and form fields have been modified
      const originalDevice = physicalDevicesData.find(x => x.physicalDevice.id === formValue.id);

      if (originalDevice && (originalDevice.direction !== formValue.direction)) {
        promises.push(updateDevice(formValue));
      }

    });

    await Promise.all(promises);
    fetchPhysicalDevices();

    setSavingInProgress(false);
  };

  const assignDevice = async (deviceFormValues: PhysicalDeviceFormValue) => {
    if (virtualDeviceId === undefined || !peopleCountingZone || !deviceFormValues.physicalDeviceId || !deviceFormValues.direction) {
      return;
    }

    return await execute({
      query: new PeopleCountingAssignCommand(peopleCountingZone.spaceId, parseInt(virtualDeviceId), deviceFormValues.physicalDeviceId, deviceFormValues.direction),
      successMessage: `${t('ZoneAssign.AssignedDevice', { ns: 'settingsPeopleCounting' })}${deviceFormValues.physicalDeviceName}`,
      errorMessage: `${t('ZoneAssign.AssignedDeviceFailed', { ns: 'settingsPeopleCounting' })}${deviceFormValues.physicalDeviceName}`
    });
  };

  const updateDevice = async (deviceFormValues: PhysicalDeviceFormValue) => {
    if (virtualDeviceId === undefined || !deviceFormValues.physicalDeviceId || !deviceFormValues.direction) {
      return;
    }

    return await execute({
      query: new PeopleCountingUpdateCommand(deviceFormValues.physicalDeviceId, parseInt(virtualDeviceId), deviceFormValues.direction),
      successMessage: `${t('ZoneAssign.UpdatedDevice', { ns: 'settingsPeopleCounting' })}${deviceFormValues.physicalDeviceName}`,
      errorMessage: `${t('ZoneAssign.UpdatedDeviceFailed', { ns: 'settingsPeopleCounting' })}${deviceFormValues.physicalDeviceName}`
    });
  };

  const deleteDevice = async (deviceFormValues: PeopleCountingPhysicalDevice) => {
    if (virtualDeviceId === undefined) {
      return;
    }

    await execute({
      query: new PeopleCountingDeviceDeleteCommand(parseInt(virtualDeviceId), deviceFormValues.physicalDevice.id),
      successMessage: `${t('ZoneAssign.RemovedDevice', { ns: 'settingsPeopleCounting' })}${deviceFormValues.physicalDevice.friendlyName}`,
      errorMessage: `${t('ZoneAssign.RemovedDeviceFailed', { ns: 'settingsPeopleCounting' })}${deviceFormValues.physicalDevice.friendlyName}`
    });
  };

  if (loading) {
    return (
      <LoadingWidget
        label={t('Loading', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('Back', { ns: 'navigation' })}
          url='./../..'
        />

        <Card cardTitle={`${peopleCountingZone?.virtualDeviceName} -  ${t('AssignDeviceList.AssignDevices', { ns: 'settingsPeopleCounting' })}`}>
          <div>
            <div className="row">
              <div className="col">
                <Property>{t('AssignDeviceList.DeviceName', { ns: 'settingsPeopleCounting' })}</Property>
              </div>
              <div className="col">
                <Property>{t('AssignDeviceList.Direction', { ns: 'settingsPeopleCounting' })}</Property>
              </div>
            </div>
            <PeopleCountingAssignDevicesList
              physicalDevices={physicalDevicesData}
              onSave={onSave}
              savingInProgress={savingInProgress}
            />
          </div>
        </Card>
      </Container>
    </PaddedContainer>
  );
};

export default PeopleCountingZoneAssign;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;

const Property = styled.div`
  font-size: 14px;
  font-weight: 500;
`;