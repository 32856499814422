import { PostQuery } from '@shared/api/queries/common/PostQuery';
import { DeviceModel } from '@shared/api/enums/DeviceModel/DeviceModel';
import { EnergyMeterCategory } from '@shared/api/enums/EnergyMeterCategory/EnergyMeterCategory';
import { EnergyMeterTopologyLevel } from '@shared/api/enums/EnergyMeterTopologyLevel/EnergyMeterTopologyLevel';
import { Device } from '@shared/api/models/Device/Device';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

export default class DeviceCreateCommand extends PostQuery<Device> {
  spaceId?: number;
  deviceModel?: DeviceModel;
  deviceIdentifier?: string;
  friendlyName?: string;

  meterTopologyLevel?: EnergyMeterTopologyLevel;
  meterCategory?: EnergyMeterCategory;
  parentMeterId?: number;
  isBillingMeter: boolean;

  pulseAssignments?: {
    metricType: MetricType;
    pulsesPerUnit: number;
    initialValue?: number;
    channel?: string
  }[]

  metadata?: string;

  public targetUrl(): string {
    return '/devices';
  }
}
