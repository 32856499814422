import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { useTheme } from 'styled-components';
import TriggerTypeConfig from './TriggerTypeConfig';
import { AlertRuleFlexChild, AlertRuleFlexRow, AlertRuleLabel } from '../AlertRuleConfiguration';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TriggerTypeSelect from './TriggerTypeSelect';
import TriggerMetricSelect from './TriggerMetricSelect';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { Circle } from './TriggerConfiguration';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { useTranslation } from 'react-i18next';
import { AlertRuleTrigger, AlertRuleTriggerType } from '@shared/api/models/AlertRule/AlertRule';

const isValid = (value?: number | null) => {
  return value !== undefined;
}

export const isTriggerComplete = (trigger: AlertRuleTrigger) => {
  return trigger.metric && trigger.type &&
    ((trigger.type === AlertRuleTriggerType.GreaterThan && isValid(trigger.threshold))
      || (trigger.type === AlertRuleTriggerType.LessThan && isValid(trigger.threshold))
      || (trigger.type === AlertRuleTriggerType.WithinRange && isValid(trigger.min) && isValid(trigger.max))
      || (trigger.type === AlertRuleTriggerType.OutsideRange && isValid(trigger.min) && isValid(trigger.max))
      || (trigger.type === AlertRuleTriggerType.State)
    );
};

interface ITrigger {
  trigger: AlertRuleTrigger;
  onChange: (trigger: AlertRuleTrigger) => void;
  remove: () => void;
  showAndLabel: boolean;
}

const Trigger = ({ trigger, onChange, remove, showAndLabel }: ITrigger) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isComplete = isTriggerComplete(trigger);

  return (
    <div style={{ marginBottom: '20px' }}>
      <AlertRuleFlexRow>
        <div style={{ marginTop: '30px', marginRight: '5px' }}>
          <Tooltip
            content={t('TriggerConfiguration.RemoveTrigger', { ns: 'settingsAlerting' })}
            placement={TooltipPlacement.Right}
            fontSize='12px'
          >
            <Circle
              color={theme.palette.red}
              onClick={remove}>
              <FontAwesomeIcon icon={solid('xmark')} />
            </Circle>
          </Tooltip>
        </div>

        <AlertRuleFlexChild>
          <AlertRuleLabel>{t('TriggerConfiguration.When', { ns: 'settingsAlerting' })}</AlertRuleLabel>
          <TriggerMetricSelect
            trigger={trigger}
            onChange={onChange}
          />
        </AlertRuleFlexChild>

        {trigger.metric &&
          <div style={{ width: '180px', flexShrink: 0 }}>
            <AlertRuleLabel>{t('TriggerConfiguration.Is', { ns: 'settingsAlerting' })}</AlertRuleLabel>
            <TriggerTypeSelect
              trigger={trigger}
              onChange={onChange}
            />
          </div>
        }

        <TriggerTypeConfig
          trigger={trigger}
          onChange={onChange}
        />

        {showAndLabel &&
          <>
            <AndLabelLine largeGap={!isComplete} />
            <AndLabel largeGap={!isComplete}>&nbsp;{t('TriggerConfiguration.AND', { ns: 'settingsAlerting' })}&nbsp;</AndLabel>
          </>
        }
      </AlertRuleFlexRow>

      {!isComplete &&
        <>
          <HelperMessage>
            <WarningIcon icon={solid('exclamation-triangle')} />
            {t('TriggerConfiguration.Warning', { ns: 'settingsAlerting' })}
          </HelperMessage>
        </>
      }
    </div>
  );
}

const HelperMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: ${p => p.theme.palette.text.weak};
  margin: 5px 0 0 40px;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.palette.orange};
  margin-right: 4px;
`;

const AndLabel = styled.div<{ largeGap: boolean }>`
  position: absolute;
  top: ${p => p.largeGap ? '83px' : '75px'};
  left: -2px;
  font-size: 10px;
  line-height: 13px;
  color: ${p => p.theme.palette.text.weak};
  background-color: ${p => p.theme.palette.backgrounds.surface};
  font-weight: 500;
  transform: rotate(270deg);
`;

const AndLabelLine = styled.div<{ largeGap: boolean }>`
  position: absolute;
  top: 57px;
  left: 10px;
  height: ${p => p.largeGap ? '65px' : '47px'};
  border-right: 1px dashed ${p => p.theme.palette.borders.strong};
`;

export default Trigger;