import { SpaceFairUsePolicy } from '@shared/api/models/FairUse/SpaceFairUsePolicy';
import { GenericDeleteQuery } from '../../common/GenericDeleteQuery';

export class SpaceFairUsePolicyBulkDeleteCommand extends GenericDeleteQuery<SpaceFairUsePolicy> {
  siteFairUsePolicyId: number;
  spaceFairUsePolicyIds: number[];

  constructor(siteFairUsePolicyId: number, spaceFairUsePolicyIds: number[]){
    super();
    this.spaceFairUsePolicyIds = spaceFairUsePolicyIds;
    this.siteFairUsePolicyId = siteFairUsePolicyId;
  }

  public targetUrl(): string {
    return '/FairUse/Exceptions/BulkDelete'
  }
}
  