import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSize } from '@shared/hooks/useSize';
import { timeout } from '@shared/utils/CommonUtils';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

type PropTypes = {
  children?: ReactNode;
}

const Carousel = ({ children }: PropTypes) => {
  const [wasDragged, setWasDragged] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolledRight, setIsScrolledRight] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const mouseState = useRef({ startX: 0, scrollLeft: 0, isDown: false });
  const { domRect } = useSize(ref);

  const checkScrollState = useCallback(() => {
    if (!ref.current) {
      return;
    }

    setIsScrollable(ref.current.scrollWidth > ref.current.clientWidth);
    setIsScrolled(ref.current.scrollLeft > 0)
    setIsScrolledRight(Math.abs(ref.current.scrollWidth - ref.current.clientWidth - ref.current.scrollLeft) <= 1);
  }, [])

  useEffect(() => {
    const execute = async () => {
      checkScrollState();
      await timeout(300);
      checkScrollState();
    };

    execute();
  }, [domRect, wasDragged, checkScrollState]);

  const handleDragStart = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!ref.current || !isScrollable) {
      return;
    }

    const startX = e.pageX - ref.current.offsetLeft;
    const scrollLeft = ref.current.scrollLeft;

    mouseState.current = { startX, scrollLeft, isDown: true }
    ref.current.style.cursor = 'grabbing';
  }, [isScrollable]);

  const handleDragEnd = useCallback(() => {
    mouseState.current = { ...mouseState.current, isDown: false }

    if (!ref.current) {
      return;
    }

    ref.current.style.cursor = isScrollable ? 'grab' : 'default';
    setWasDragged(s => !s);
  }, [isScrollable]);

  const handleDrag = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    if (!mouseState.current.isDown || !ref.current) {
      return;
    }

    const x = e.pageX - ref.current.offsetLeft;
    const scroll = x - mouseState.current.startX;

    ref.current.scrollLeft = mouseState.current.scrollLeft - scroll;
    checkScrollState();
  }, [checkScrollState]);

  const move = useCallback((distance: number) => {
    if (!ref.current) {
      return;
    }

    ref.current.scrollBy({ left: distance, behavior: 'smooth' });
    setWasDragged(s => !s);
  }, []);

  return (
    <Container>
      <DragContainer
        ref={ref}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDrag}
        isScrollable={isScrollable}
      >
        {children}
      </DragContainer>

      {isScrolled &&
        <>
          <Overlay />
          <IconButton
            icon={solid('chevron-left')}
            onClick={() => move(-300)}
          />
        </>
      }

      {!isScrolledRight &&
        <>
          <Overlay $right />
          <IconButton
            icon={solid('chevron-right')}
            onClick={() => move(300)}
            $right
          />
        </>
      }
    </Container>
  );
};

export default Carousel;

const Container = styled.div`
  position: relative;
`;

const DragContainer = styled.div<{ isScrollable: boolean }>`
  overflow-x: hidden;
  cursor: ${p => p.isScrollable ? 'grab' : 'default'};
`;

const IconButton = styled(FontAwesomeIcon) <{ $right?: boolean }>`
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 10;
  transform: translateX(-50%);

  color: ${p => p.theme.primary.contrast};
  background-color: ${p => p.theme.primary.main};
  width: 12px;
  height: 12px;
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
  
  ${p => p.$right && css`
    left: unset;
    right: 0px;
    transform: translateX(50%);
  `}
`;

const Overlay = styled.div<{ $right?: boolean }>`
  position: absolute;
  left: -10px;
  top: 50%;
  z-index: 9;

  height: 100%;
  width: 0px;
  transform: translateY(-50%);
  box-shadow: 10px 0 17px 13px ${p => p.theme.primary.background};

  ${p => p.$right && css`
    left: unset;
    right: -10px;
    box-shadow: -10px 0 17px 13px ${p => p.theme.primary.background};
  `}
`;