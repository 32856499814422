import { useState } from 'react';
import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { IInternalNode, IInternalNodeGroup } from '../CascaderMultiSelect.types';
import { cloneDeep } from 'lodash';
import Checkbox from './Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { updateNodeAndChildren } from '../CascaderUtils';

interface ICascadeGroup<TValue> {
  group: IInternalNodeGroup<TValue>;
  depth: number;
  onChange: (updatedGroup: IInternalNodeGroup<TValue>) => void;
  disabled?: boolean;
  hideChildGroupLabel?: boolean;
  onMobile: boolean;
  activeGroupId?: string;
  setActiveGroupId: (id?: string) => void;
  parentGroup?: IInternalNodeGroup<TValue>;
}

const CascadeLevel = <TValue,>({ group, depth, onChange, disabled, hideChildGroupLabel, onMobile, activeGroupId, setActiveGroupId, parentGroup }: ICascadeGroup<TValue>) => {
  const [activeNodeId, setActiveNodeId] = useState<string>();
  const maxDepth = 10;
  const disableSelection = disabled || group.nodes.filter(x => x.selected || x.partial).length === group.selectLimit;
  const isActive = activeGroupId === group.id;

  /**
   * Called when a node label/row is clicked
   */
  const handleNodeClick = (node: IInternalNode<TValue>, hasChildren: boolean) => {
    if (hasChildren) {
      setActiveNodeId(node.id);
      setActiveGroupId(node.childGroup?.id);
    } else {
      handleNodeSelect(node);
    }
  };

  /**
   * Called when a node label/row is selected
   */
  const handleNodeSelect = (node: IInternalNode<TValue>) => {
    if (node.selected || node.partial || !disableSelection) {
      const modifiedNode = updateNodeAndChildren(node, !node.selected);
      handleNodeChange(modifiedNode);
    }
  };

  /**
   * Called when a node/checkbox was selected, replacing the updated node
   */
  const handleNodeChange = (updatedNode: IInternalNode<TValue>) => {
    const groupCopy = cloneDeep(group);
    groupCopy.nodes = group.nodes.map(node => node.id === updatedNode.id ? updatedNode : node);
    onChange(groupCopy);
  };

  /**
   * Recursively called (from updated node/nodeGroup upwards to root nodeGroup), replacing the updated nodeGroup
   */
  const handleGroupChange = (updatedGroup: IInternalNodeGroup<TValue>) => {
    const groupCopy = cloneDeep(group);

    groupCopy.nodes = group.nodes.map(node => {
      if (node.childGroup?.id !== updatedGroup.id) {
        return node;
      }

      // Replace updated child group
      node.childGroup = updatedGroup;

      // Check if all child nodes are selected (use negated Array.some() instead of Array.every() for performance reasons)
      const selected = !node.childGroup.nodes.some(x => !x.selected);

      // Check if the node is partially selected (at least one but not all child nodes are selected or partially selected)
      const partial = selected ? false : node.childGroup.nodes.some(x => x.selected || x.partial);

      return {
        ...node,
        selected: selected,
        partial: partial,
      };
    })

    onChange(groupCopy);
  };

  if (group.hide || group.nodes.length === 0 || depth >= maxDepth) {
    return null;
  }

  const childGroupName = group.nodes.find(x => x.childGroup?.header)?.childGroup?.header;

  return (
    <>
      <FlexColumn $onMobile={onMobile} activeGroup={isActive}>
        <Header hasBackRow={onMobile && parentGroup !== undefined}>
          <div style={{ width: '100%' }}>
            {onMobile && parentGroup &&
              <BackRow onClick={() => setActiveGroupId(parentGroup?.id)}>
                <BackArrow
                  icon={solid('arrow-left')}
                />

                {parentGroup.header}
              </BackRow>
            }

            <FlexRow>
              <HeaderLabel>
                {group.header}
              </HeaderLabel>

              {!hideChildGroupLabel &&
                <RightAligned>
                  <SubHeader>
                    {childGroupName &&
                      <>
                        ({childGroupName})
                      </>
                    }
                  </SubHeader>
                </RightAligned>
              }
            </FlexRow>
          </div>
        </Header>
        <NodeList>
          {group.nodes.map((node) => {
            const highlightRow = node.id === activeNodeId && !onMobile;
            const hasChildren = node.childGroup !== undefined && node.childGroup.nodes.length > 0;

            return (
              <NodeRow
                key={node.id}
                active={highlightRow}
                hasChildren={hasChildren}
                onClick={() => handleNodeClick(node, hasChildren)}
              >
                <Checkbox
                  node={node}
                  onSelect={handleNodeSelect}
                  disabled={disableSelection}
                />
                <LabelRow>
                  <Label active={highlightRow}>
                    {node.label}
                  </Label>
                  <RightAligned>
                    <NumNodes active={highlightRow}>
                      {node.childGroup?.nodes && node.childGroup.nodes.length > 0 && `(${node.childGroup.nodes.length})`}
                    </NumNodes>

                    {!node.childGroup?.hide && node.childGroup?.nodes && node.childGroup.nodes.length > 0 &&
                      <Icon
                        icon={solid('chevron-right')}
                        $active={highlightRow}
                      />
                    }
                  </RightAligned>
                </LabelRow>
              </NodeRow>
            );
          })}
        </NodeList>
      </FlexColumn>

      {group.nodes.filter(node => node.id === activeNodeId).map((childNode) => childNode.childGroup && (
        <CascadeLevel
          key={childNode.id}
          group={childNode.childGroup}
          depth={depth + 1}
          onChange={handleGroupChange}
          disabled={!childNode.partial && disableSelection}
          hideChildGroupLabel={hideChildGroupLabel}
          onMobile={onMobile}
          activeGroupId={activeGroupId}
          setActiveGroupId={setActiveGroupId}
          parentGroup={group}
        />
      ))}
    </>
  );
}

export default CascadeLevel;

const FlexColumn = styled.div<{ $onMobile: boolean, activeGroup: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 240px;
  
  &:not(:last-child) {
    border-right: 1px solid ${p => p.theme.palette.borders.weak};
  }

  ${p => p.$onMobile && css`
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    height: 300px;
    overflow: hidden;
    transition: all 150ms ease;

    ${p.activeGroup && css`
      opacity: 1;
      visibility: visible;
    `}
  `}
`;

const NodeList = styled.div`
  overflow: hidden auto;
  min-height: 150px;
`;

const Header = styled.div<{ hasBackRow: boolean }>`
  display: flex;
  align-items: center;

  height: 50px;
  flex-shrink: 0;
  padding: 0 12px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0px 5px 4px -2px ${p => p.theme.palette.shadows.medium};
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};

  ${p => p.hasBackRow && css`
    height: 75px;
  `}
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const HeaderLabel = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};

  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubHeader = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
  line-height: normal;
`;

const Label = styled.div<{ active: boolean }>`
  font-size: 14px;
  color: ${p => p.theme.palette.text.medium};
  padding-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  ${p => p.active && css`
    color: ${p => p.theme.palette.text.medium};
    font-weight: 500;
  `}
`;

const NodeRow = styled.div<{ active: boolean, hasChildren: boolean; }>`
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  border-left: 2px solid transparent;

  ${p => !p.active && css`
    &:nth-child(even) {
      background-color: ${p => p.theme.palette.tables.evenRow};
    }
    
    &:nth-child(odd) {
      background-color: ${p => p.theme.palette.tables.unevenRow};
    }
    
    &:hover {
      background-color: ${p => p.theme.palette.tables.backgroundHighlight};

      ${p.hasChildren && css`
        background-color: ${p => darken(0.05, p.theme.palette.tables.unevenRow)};
        border-left-color: ${p => p.theme.palette.primary};
      `}
    }
  `}

  ${p => p.active && css`
    background-color: ${p => darken(0.05, p.theme.palette.tables.unevenRow)};
    border-left-color: ${p => p.theme.palette.primary};
  `}
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 22px);
`;

const RightAligned = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NumNodes = styled.div <{ active: boolean }>`
  margin-left: 5px;
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};

  ${p => p.active && css`
    color: ${p => p.theme.palette.primary};
  `}
`;

const Icon = styled(FontAwesomeIcon) <{ $active: boolean }>`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  color: ${p => p.theme.palette.text.weak};

  ${p => p.$active && css`
    color: ${p => p.theme.palette.primary};
  `}
`;

const BackRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 0 10px 0;

  font-size: 16px;
  line-height: 16px;
  color: ${p => p.theme.palette.text.fair};

  &:hover {
    color: ${(p) => p.theme.palette.primary};
  }
`;

const BackArrow = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  padding: 5px;
  margin-right: 8px;
  border-radius: 50%;
  color: ${p => p.theme.palette.primary};
  border: 1px solid ${(p) => p.theme.palette.borders.medium};
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms ease;

  ${BackRow}:hover & {
    border-color: ${(p) => p.theme.palette.primary};
  }
`;