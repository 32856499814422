export type ChartDataPoint = {
  x: number,
  y: string
};

/**
 * Definition for a unit type of a chart dataset point.
 */
export type ChartDataUnit = {
  /**
   * Label for that unit
   */
  label?: string,
  /**
   * Prefix for that unit
   */
  prefix?: string,
  /**
   * Suffix for that unit
   */
  suffix?: string,
  /**
   * Function to be applied to each value individually
   */
  modifier?: (x: string) => string | number
}

export type ChartDataset = {
  label: string,
  displayLabel: string,
  legendSubLabel?: string,
  dataset: ChartDataPoint[],
  dataUnit: ChartDataUnit,
  labels: string[],
  color: string,
  yAxisId?: string;
  categoryColors?: { [key: string]: string }
};

export enum LineBarChartType {
  Line = 'line',
  Bar = 'bar',
}

export type LineBarChartOverrides = {
  /**
   * Line tension
   */
  tension?: number,
  /**
   * Do not fill line chart with color
   */
  fillLineDisabled?: boolean
  /**
   * y-axis step size
   */
  yAxisStepSize?: number
  /**
   * stacked chart
   */
  stacked?: boolean
}