import DoughnutChart from '@dashboard/components/atoms/charts/DoughnutChart';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import WidgetCard from '../../WidgetCard';
import { DefaultTheme, useTheme } from 'styled-components';
import { LocalisationFunction, useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useApiState } from '@shared/hooks/useApiState';
import SiteTemperaturesGetQuery from '@dashboard/api/queries/metrics/SiteTemperaturesGetQuery';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import NoData from '@shared/components/atoms/NoData/NoData';
import SiteTemperatureDto from '@dashboard/api/models/SiteTemperaturesDto';
import TemperatureModal from './TemperatureModal';
import { TDoughnutChartLegend } from '@dashboard/components/atoms/charts/DoughnutChartLegend';
import { TFunction } from 'i18next';
import { capitalize } from '@shared/utils/StringUtils';
import { createTooltipOptions } from './ChartUtils';

const createDataset = (dto: SiteTemperatureDto, unit: string, toLocale: LocalisationFunction, t: TFunction, theme: DefaultTheme) => {
  return {
    data: [dto.numSpacesInRange, dto.numUnderheatedSpaces, dto.numOverheatedSpaces],
    colors: [theme.widget.green, theme.widget.blue, theme.widget.red],
    labels: [
      `${capitalize(t('InRange', { ns: 'common' }))}`,
      `${capitalize(t('Under', { ns: 'common' }))} ${toLocale(MetricType.Temperature, dto.minTemperature, { round: 1 })}${unit}`,
      `${capitalize(t('Over', { ns: 'common' }))} ${toLocale(MetricType.Temperature, dto.maxTemperature, { round: 1 })}${unit}`
    ],
  };
}

const createLegend = (data: SiteTemperatureDto, unit: string, toLocale: LocalisationFunction, t: TFunction, theme: DefaultTheme): TDoughnutChartLegend => {
  return {
    title: t('TotalSpaces', { ns: 'common' }),
    items: [
      {
        label: `${data.numSpacesInRange} ${t('InRange', { ns: 'common' })}`,
        color: theme.widget.green,
        link: { path: 'spaces/in-range', analytics: { action: 'spaces_in_range', category: 'overview_live_updates' } }
      },
      {
        label: `${data.numUnderheatedSpaces} ${t('under', { ns: 'common' })} ${toLocale(MetricType.Temperature, data.minTemperature, { round: 1 })}${unit}`,
        color: theme.widget.blue,
        link: { path: 'spaces/underheated', analytics: { action: 'spaces_underheated', category: 'overview_live_updates' } }
      },
      {
        label: `${data.numOverheatedSpaces} ${t('over', { ns: 'common' })} ${toLocale(MetricType.Temperature, data.maxTemperature, { round: 1 })}${unit}`,
        color: theme.widget.red,
        link: { path: 'spaces/overheated', analytics: { action: 'spaces_overheated', category: 'overview_live_updates' } }
      }
    ]
  };
}

export const SiteTemperatureDefaultRange = {
  min: 18,
  max: 24
}

const TemperatureWidget = () => {
  const theme = useTheme();
  const { toLocale, getUnit } = useLocalisation();
  const { t } = useTranslation();
  const unit = getUnit(MetricType.Temperature);
  const { site, siteMetadata } = useSiteContext();

  const { data, loading } = useApiState({
    query: siteMetadata && new SiteTemperaturesGetQuery(site.id, siteMetadata.minTemperature ?? SiteTemperatureDefaultRange.min, siteMetadata.maxTemperature ?? SiteTemperatureDefaultRange.max)
  }, [site, siteMetadata]);

  if (!siteMetadata || (data && !data.hasTemperatureDevices)) {
    return null;
  }

  return (
    <WidgetCard
      icon={regular('thermometer-three-quarters')}
      title={t('Temperature', { ns: 'common' })}
      titleElement={<TemperatureModal />}
      loading={{
        state: loading,
        icon: duotone('thermometer-three-quarters'),
        label: t('Loading', { ns: 'status' })
      }}
    >

      <>
        {!data &&
          <NoData
            subLabel={t('DataUnavailable', { ns: 'status' })}
            styles={{ paddingTop: 40 }}
            iconColor={theme.primary.light}
          />
        }

        {data && data.hasTemperatureDevices &&
          <DoughnutChart
            dataset={createDataset(data, unit, toLocale, t, theme)}
            size="120px"
            cutout="83%"
            arcBorderWidth={2}
            innerValue={`${toLocale(MetricType.Temperature, data.averageSiteTemperature, { round: 1 })}${unit}`}
            innerValueStyles={{ fontSize: '24px' }}
            innerSubLabel={t('AvgTemp', { ns: 'common' })}
            legend={createLegend(data, unit, toLocale, t, theme)}
            tooltipOptions={createTooltipOptions(theme)}
          />
        }
      </>
    </WidgetCard>
  )
}

export default TemperatureWidget;