import { DevicesWithMetricTypes } from '@dashboard/api/models/DevicesWithMetricTypes';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class DevicesGetByBuildingIdDeviceTypeQuery extends GetQuery<DevicesWithMetricTypes> {
  buildingId: number;
  deviceType: DeviceType;

  constructor(buildingId: number, deviceType: DeviceType) {
    super();
    this.buildingId = buildingId;
    this.deviceType = deviceType;
  }

  public targetUrl(): string {
    return `/devices/DevicesGetByBuildingIdDeviceType/${this.buildingId}/${this.deviceType}`;
  }
}
