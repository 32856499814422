import { AlertRule } from '@shared/api/models/AlertRule/AlertRule';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class AlertRulesGetBySiteQuery extends GetQuery<AlertRule[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }
  public targetUrl(): string {
    return `/alerting/GetRulesBySite/${this.siteId}`;
  }
}
