import { HeatingScheduleDto } from '@shared/api/models/HeatingSchedule/HeatingScheduleDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class HeatingSchedulesGetBySpaceIdQuery extends GetQuery<HeatingScheduleDto[]> {
  spaceId: number;

  constructor(spaceId: number) {
    super();
    this.spaceId = spaceId;
  }

  public targetUrl(): string {
    return `/schedules/GetBySpaceId/${this.spaceId}`;
  }
}
