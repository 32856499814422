import PeopleCountingPhysicalDevice from '@settings/api/models/PeopleCounting/PeopleCountingPhysicalDevice';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class PeopleCountingAssignCommand extends PostQuery<PeopleCountingPhysicalDevice> {
  spaceId: number;
  virtualDeviceId: number;
  physicalDeviceId: number;
  direction: string;

  constructor(spaceId: number, virtualDeviceId: number, physicalDeviceId: number, direction: string) {
    super();
    this.spaceId = spaceId;
    this.virtualDeviceId = virtualDeviceId;
    this.physicalDeviceId = physicalDeviceId;
    this.direction = direction;
  }

  public targetUrl(): string {
    return '/peoplecounting/assign';
  }
}
