import cardDotDark from '@shared/assets/images/card-dot-dark.svg';
import cardDot from '@shared/assets/images/card-dot.svg';
import { Card } from '@shared/components/molecules/Card/Card';
import { useThemeContext } from '@shared/contexts/ThemeContext/ThemeContext';
import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

const SkeletonLoader = () => {
  const { isDarkTheme } = useThemeContext();

  return (
    <StyledCard isDarkTheme={isDarkTheme}>
      <Value />
      <Label />
    </StyledCard>
  )
}

export default SkeletonLoader

const breatheAnimation = keyframes`
to {
    background-position-x: -200%;
  }
`;

const StyledCard = styled(Card) <{ isDarkTheme: boolean }>`
  position: relative;
  height: 130px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  background-image: ${p => p.isDarkTheme ? `url(${cardDotDark})` : `url(${cardDot})`};
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 0px 2px 2px 0px ${p => p.theme.palette.shadows.extraLight};
`;

const Value = styled.div`
  width: 78px;
  height: 15px;
  border-radius: 20px;
  margin-bottom: 30px;
  background: #eee;
  background: ${p => `linear-gradient(110deg, ${p.theme.skeleton.background} 8%, ${transparentize(0.8, '#fff')} 18%, ${p.theme.skeleton.background} 33%)`};
  border-radius: 5px;
  background-size: 200% 100%;
  animation: ${breatheAnimation} 1.5s linear infinite;
`;

const Label = styled.div`
  width: 103px;
  height: 8px;
  border-radius: 20px;
  background-color: ${p => p.theme.skeleton.background};
  background: ${p => `linear-gradient(110deg, ${p.theme.skeleton.background} 8%, ${transparentize(0.8, '#fff')} 18%, ${p.theme.skeleton.background} 33%)`};
  border-radius: 5px;
  background-size: 200% 100%;
  animation: ${breatheAnimation} 1.5s linear infinite;
`;