import UserProfilePasswordUpdateCommand from '@dashboard/api/queries/userProfile/UserProfilePasswordUpdateCommand';
import { Button } from '@shared/components/atoms/Button/Button';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { useApi } from '@shared/hooks/useApi';
import { isEmpty } from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  userId: number
}

type UpdatePasswordFormValues = {
  password: string,
  passwordConfirm: string
}

const UpdatePassword = ({ userId }: PropTypes) => {
  const { t } = useTranslation(['common']);
  const { execute, loading: savingInProgress } = useApi();
  const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm<UpdatePasswordFormValues>();

  const onSave: SubmitHandler<UpdatePasswordFormValues> = async data => {
    await execute({
      query: new UserProfilePasswordUpdateCommand(userId, data.password),
      successMessage: t('ProfilePagePasswordForm.ChangeSuccess', { ns: 'common' }),
      errorMessage: t('ProfilePagePasswordForm.ChangeError', { ns: 'common' })
    });

    reset();
  }

  return (
    <CardSection>
      <Form>
        <Title>
          {t('ProfilePagePasswordForm.Title', { ns: 'common' })}
        </Title>
        <div className="row">
          <div className="col-md-6">
            <Label>{t('ProfilePagePasswordForm.NewPassword', { ns: 'common' })}</Label>
            <Input
              type="password"
              {...register('password', {
                required: t('ProfilePagePasswordForm.Required', { ns: 'common' }),
                minLength: { value: 8, message: t('ProfilePagePasswordForm.MinLength', { ns: 'common' }) },
                maxLength: { value: 255, message: t('ProfilePagePasswordForm.MaxLength', { ns: 'common' }) }
              })}
            />
            <ErrorMessage>{errors.password?.message}</ErrorMessage>
          </div>
          <div className="col-md-6">
            <Label>{t('ProfilePagePasswordForm.Confirm', { ns: 'common' })}</Label>
            <Input
              type="password"
              {...register('passwordConfirm', {
                validate: value => value === getValues('password') || t('ProfilePagePasswordForm.MatchError', { ns: 'common' })
              })} />
            <ErrorMessage>{errors.passwordConfirm?.message}</ErrorMessage>
          </div>
        </div>
      </Form>

      <Button
        label={t('ProfilePagePasswordForm.Change', { ns: 'common' })}
        onClick={handleSubmit(onSave)}
        disabled={!isEmpty(errors)}
        loading={savingInProgress}
        style={{ margin: '32px auto 0 auto' }}
      />
    </CardSection>
  );
};

export default UpdatePassword;

const CardSection = styled.div`
  margin-top: 30px;
  padding: 30px 0 10px 0;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Title = styled.div`
  font-weight: 500;
  padding-bottom: 20px;
`;