import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { EnergyConsumptionWidgetConfig } from './helpers/ModifierHelpers';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { ReportAggregateCategory } from '@dashboard/api/enums/ReportAggregateCategory';

const getTooltipText = (config: EnergyConsumptionWidgetConfig) => {
  switch (config.data.energyType) {
    case EnergyMeterType.Electricity:
    case EnergyMeterType.Heating:
    case EnergyMeterType.Water:
      return (
        <Trans i18nKey='molecules:ESG.DataFromSubMetersOnly'>
          <Bold></Bold>
        </Trans>
      );
    case EnergyMeterType.Gas:
      return (
        <Trans i18nKey='molecules:ESG.DataFromMainMetersOnly'>
          <Bold></Bold>
        </Trans>
      );
    default:
      return null;
  }
}

type InfoTooltipProps = {
  config: EnergyConsumptionWidgetConfig;
  category: ReportAggregateCategory;
}

const InfoTooltip = ({ config, category }: InfoTooltipProps) => {
  const text = getTooltipText(config);

  if (category !== ReportAggregateCategory.Site || !text) {
    return null;
  }

  return (
    <Wrapper>
      <Tooltip
        placement={TooltipPlacement.Left}
        content={
          <TooltipContent>
            {text}
          </TooltipContent>
        }
      >
        <InfoIcon
          icon={regular('circle-info')}
        />
      </Tooltip>
    </Wrapper>
  );
};

export default InfoTooltip;

const Wrapper = styled.div`
  position: absolute;
  bottom: 21px;
  right: 25px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const TooltipContent = styled.div`
  width: 280px;
  padding: 10px;
  font-size: 12px;
`;

const Bold = styled.span`
  font-weight: 600;
`;