import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { ISearchField } from './SearchField.types';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { Input } from '../Form/Form';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export const SearchField = ({ placeholder, onSearchChange, width, style, hasAutoFocus }: ISearchField) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    onSearchChange(value);
  }, [value, onSearchChange]);

  return (
    <Container style={style} width={width}>
      <MagnifierIcon icon={faMagnifyingGlass} />

      <StyledInput
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        hasClearIcon={value.length > 0}
        autoFocus={hasAutoFocus}
      />

      {value.length > 0 &&
        <ClearIcon
          icon={solid('xmark')}
          onClick={() => setValue('')}
        />
      }
    </Container>
  );
};

const Container = styled.div<{ width?: string }>`
  position: relative;
  width: ${p => p.width ?? 'auto'};
`;

const MagnifierIcon = styled(FontAwesomeIcon) <{ $boxStyle?: boolean }>`
  position: absolute;
  top: 12px;
  left: 13px;
  font-size: 12px;
  color: ${p => p.theme.text.secondary};

  @media (max-width: 768px) {
    visibility: hidden;
  }

  ${p => p.$boxStyle && css`
    margin-left: 5px;
  `}
`;

const StyledInput = styled(Input) <{ hasClearIcon: boolean }>`
  padding-left: 35px; // Space for magnifier icon
  padding-right: ${p => p.hasClearIcon ? '35px' : 0}; // Space for clear icon

  &::placeholder {
    color: ${p => p.theme.text.secondary};
  }
`;

const ClearIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 6px;
  right: 6px;

  width: 14px;
  height: 14px;
  padding: 6px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;