import { Button } from '@shared/components/atoms/Button/Button';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { IInformationDialog } from './InformationDialog.types';

export const InformationDialog = ({ modalRef, isOpen, hide, title, content, button }: IInformationDialog) => {
  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      title={title}
      hide={hide}
      width="400px"
      footer={<Button tertiary onClick={hide} {...button} />}
    >
      {content}
    </Modal>
  );
};
