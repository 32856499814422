import { Feature } from '@shared/api/models/Feature/Feature';
import TenantFeatureListItem from './TenantFeatureListItem';
import { Tenant } from '@shared/api/models/Tenant/Tenant';
import styled from 'styled-components';

interface ITenantFeatureList {
  features: Feature[];
  tenant: Tenant;
  refresh: () => void;
}

const TenantFeatureList = ({ features, tenant, refresh }: ITenantFeatureList) => {
  return (
    <Wrapper>
      {features.map((feature, i) => (
        <TenantFeatureListItem
          key={i}
          feature={feature}
          tenant={tenant}
          refresh={refresh}
        />
      ))}
    </Wrapper>
  );
};

export default TenantFeatureList;

const Wrapper = styled.div`
  padding: 10px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;