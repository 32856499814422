import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ExceptionForm } from '../types/ExceptionForm';
import ExceptionsForm from './ExceptionsForm';

type EditExceptionProps = {
  selectedExceptions: SpaceClimateControlDto[];
  externalSensorModeEnabled: boolean;
  singleSelectedException?: SpaceClimateControlDto;
  onEditException: () => void;
  onCancel: () => void;
}

const EditException = ({ selectedExceptions, singleSelectedException, externalSensorModeEnabled, onEditException, onCancel }: EditExceptionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { reset, watch } = useFormContext<ExceptionForm>();
  const { toLocale } = useLocalisation();
  const endDate = watch('endDate');

  useEffect(() => {
    if (singleSelectedException) {
      reset({
        spaceIds: [singleSelectedException.spaceId],
        minTemp: toLocale(MetricType.Temperature, singleSelectedException.minTemp ?? 0, { round: 0 }),
        maxTemp: toLocale(MetricType.Temperature, singleSelectedException.maxTemp ?? 0, { round: 0 }),
        targetTemp: toLocale(MetricType.Temperature, singleSelectedException.targetTemp ?? 0, { round: 0 }),
        controlMode: singleSelectedException.controlMode,
        externalSensorModeEnabled: externalSensorModeEnabled,
        showEndDate: !!singleSelectedException.endDate,
        endDate: singleSelectedException.endDate ? dayjs(new Date(singleSelectedException.endDate)) : undefined
      });
    } else if (selectedExceptions[0]) {
      reset({
        spaceIds: selectedExceptions.map(x => x.spaceId),
        minTemp: toLocale(MetricType.Temperature, selectedExceptions[0].minTemp ?? 0, { round: 0 }),
        maxTemp: toLocale(MetricType.Temperature, selectedExceptions[0].maxTemp ?? 0, { round: 0 }),
        targetTemp: toLocale(MetricType.Temperature, selectedExceptions[0].targetTemp ?? 0, { round: 0 }),
        controlMode: selectedExceptions[0].controlMode,
        externalSensorModeEnabled: externalSensorModeEnabled,
        showEndDate: !!selectedExceptions[0].endDate,
        endDate: selectedExceptions[0].endDate ? dayjs(new Date(selectedExceptions[0].endDate)) : undefined
      });
    }
  }, [reset, singleSelectedException, selectedExceptions, toLocale, externalSensorModeEnabled]);

  const getLabels = () => {
    if (singleSelectedException) {
      return (
        <SelectedLabel key={uuidv4()}>
          {singleSelectedException.spaceName}
        </SelectedLabel>);
    } else {
      return (selectedExceptions.map((exception) => (
        <SelectedLabel key={uuidv4()}>
          {exception.spaceName}
        </SelectedLabel>)
      ))
    }
  }

  return (
    <Container>
      <Title
        text={t('ClimateControl.EditException', { ns: 'molecules' })}
      />
      <StyledFlexColumn>
        <LabelContainer >
          {getLabels()}
        </LabelContainer>
        <ExceptionsForm />
      </StyledFlexColumn>
      <ButtonContainer>
        <Button
          tertiary
          label={t('Cancel', { ns: 'common' })}
          onClick={onCancel}
          color={theme.palette.red}
        />
        <Button
          disabled={endDate ? dayjs().isAfter(endDate) : false}
          label={t('Update', { ns: 'common' })}
          onClick={onEditException}
        />
      </ButtonContainer>
    </Container>
  )
}

export default EditException;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 24px;
`;

const StyledFlexColumn = styled(FlexColumn)`
  gap: 30px;
  padding-bottom: 10px;
  max-width: 400px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const LabelContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 5px;
  padding: 9px 54px 9px 10px;
  background-color: ${p => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.action.outlineBorder};
  border-radius: 4px;
  box-shadow: ${p => p.theme.palette.forms.input.boxShadow};
`;

const SelectedLabel = styled.div`
  font-size: 12px;
  padding: 2px 9px;
  border-radius: 14px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};
  max-width: 200px;
`;