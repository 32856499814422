import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from '@shared/components/atoms/Form/Form';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { transparentize } from 'polished';
import { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

interface IConfigGroup {
  title: string;
  tooltipText?: string;
  topRightElement?: ReactNode;
  children?: ReactNode;
  styles?: React.CSSProperties;
  titleStyles?: React.CSSProperties;
}

const ConfigGroup = ({ title, tooltipText, topRightElement, children, styles, titleStyles }: IConfigGroup) => {
  const theme = useTheme();

  return (
    <div style={styles}>
      <FlexRow>
        <GroupTitle style={titleStyles}>
          <Label small>
            {title}
          </Label>

          {tooltipText &&
            <InfoIconWrapper>
              <Tooltip
                content={tooltipText}
                placement={TooltipPlacement.Right}
                hideTriangle
                fontSize='12px'
                tooltipColor={transparentize(0.15, theme.palette.tooltip.background.primary)}
              >
                <InfoIcon icon={regular('circle-info')} />
              </Tooltip>
            </InfoIconWrapper>
          }
        </GroupTitle>

        {topRightElement}
      </FlexRow>

      {children}
    </div>
  );
};

export default ConfigGroup;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GroupTitle = styled.div`
  background-color: ${p => p.theme.palette.backgrounds.surface};
  padding: 0 2px;
  
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  display: flex;
`;

const InfoIconWrapper = styled.div`
  position: relative;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 7px;
  color: ${p => p.theme.palette.text.weak};
`;