import { stringToPercentage } from '@shared/utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: Humidity
 */
const HeatmapConfigHumidity: IHeatmapConfig = {
  accessor: (x: string) => {
    return stringToPercentage(x, 0);
  },

  scale: {
    points: [
      {
        threshold: 50,
        label: '50%',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 60,
        label: '60',
        color: DefaultScaleColors.YELLOW,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 75,
        label: '75',
        color: DefaultScaleColors.ORANGE,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 85,
        label: '85',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigHumidity);