import PeopleCountingPhysicalDevice from '@settings/api/models/PeopleCounting/PeopleCountingPhysicalDevice';
import { GenericDeleteQuery } from '@shared/api/queries/common/GenericDeleteQuery';

export default class PeopleCountingDeviceDeleteCommand extends GenericDeleteQuery<PeopleCountingPhysicalDevice> {
  deviceId: number;
  virtualDeviceId: number;

  constructor(virtualDeviceId: number, deviceId: number) {
    super();
    this.deviceId = deviceId;
    this.virtualDeviceId = virtualDeviceId;
  }

  public targetUrl(): string {
    return '/peoplecounting/deletedevice';
  }
}
