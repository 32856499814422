import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type RadioInputFormFieldProps = {
  label: string;
  id: string;
  name: string;
}

const RadioInputFormField = ({ label, id, name }: RadioInputFormFieldProps) => {
  const { register } = useFormContext();

  return (
    <RadioField>
      <RadioInput
        type="radio"
        id={id}
        value={id}
        {...register(name)}
      />
      <Label htmlFor={id}>{label}</Label>
    </RadioField>
  )
}

export default RadioInputFormField;

const RadioField = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & + & {
    margin-top: 10px;
  }
`;

const RadioInput = styled.input`
  margin: 0;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${(p) => p.theme.palette.forms.label.color};
`;