import { ComponentPropsWithoutRef, ReactNode } from 'react';

export enum PopoverPlacement {
  Top = 'Top',
  TopRight = 'TopRight',
  TopLeft = 'TopLeft',

  Bottom = 'Bottom',
  BottomRight = 'BottomRight',
  BottomLeft = 'BottomLeft',

  Left = 'Left',
  Right = 'Right',

  FixedTop = 'FixedTop'
}

export interface IPopover extends ComponentPropsWithoutRef<'div'> {
  /**
   * Popover content
   */
  popoverContent?: ReactNode;
  /**
   * Is popover visible?
   */
  visible?: boolean;
  /**
   * Position of popover
   */
  placement: PopoverPlacement;
  /**
   * Color of the popover
   */
  color?: string;
  /**
   * Additional offset to shift popover on the x-axis
   */
  offsetX?: number;
  /**
   * Additional offset to shift popover on the y-axis
   */
  offsetY?: number;
  /**
   * Align the popover with the target div (the triangle will not be centered)
   */
  alignWithTarget?: boolean;
  /**
   * Hide triangle
   */
  hideTriangle?: boolean;
  /**
   * Additional styles applied to the container
   */
  containerStyles?: React.CSSProperties;
  /**
   * Set position to fixed instead of absolute
   */
  fixed?: boolean;
}
