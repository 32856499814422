import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

type EnergyWidgetCategoryGroupProps = {
  label: string;
  open?: boolean;
  inPrintMode: boolean;
  children?: ReactNode;
}

const EnergyWidgetCategoryGroup: React.FC<EnergyWidgetCategoryGroupProps> = ({ label, open, inPrintMode, children }) => {
  const [isOpen, setIsOpen] = useState(open ? true : false);

  return (
    <Wrapper open={isOpen}>
      <CollapseHandler open={isOpen} onClick={() => setIsOpen(prevState => !prevState)}>
        <Label open={isOpen}>
          {label}
        </Label>
        <Chevron icon={isOpen ? solid('chevron-up') : solid('chevron-down')} />
      </CollapseHandler>

      <Content open={isOpen || inPrintMode}>
        {children}
      </Content>
    </Wrapper>
  );
};

export default EnergyWidgetCategoryGroup;

const Wrapper = styled.div<{ open: boolean }>`
  margin: 0 -15px 20px -15px;
  border: 1px solid ${p => p.theme.palette.borders.medium};
  box-shadow: 0px 0px 8px 0px ${(p) => p.theme.palette.shadows.medium};
  border-radius: 5px;

  @media print {
    margin: 0;
    border: none;
    box-shadow: none;
    border-radius: none;
  }
`;

const CollapseHandler = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 20px;
  transition: padding 200ms ease;

  ${p => !p.open && css`
    padding: 10px 20px;
  `}

  @media print {
    display: none;
  }
`;

const Label = styled.div<{ open: boolean }>`
  color: ${p => p.theme.palette.text.medium};
  font-size: 22px;
  font-weight: 500;
  padding-left: 2px;
`;

const Content = styled.div<{ open: boolean }>`
  display: ${p => p.open ? 'block' : 'none'};
  padding: 0 20px 20px 20px;

  @media print {
    padding: 0;
  }
`;

const Chevron = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.text.medium};
  margin: 7px 8px 0 0;
`;