import styled from 'styled-components';
import { useSize } from '@shared/hooks/useSize';
import useTemperatureChart from './useTemperatureChart';
import { SiteTemperatureChartDto } from '@dashboard/api/models/SiteTemperatureChartDto';
import { TimeRange } from '../SiteTemperatureChartWidget';
import ChartLegend from './ChartLegend';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import NoData from '@shared/components/atoms/NoData/NoData';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { TimeRangeType } from '../Interactions/TimeRangeSelect';

export const getChartHeight = (width?: number) => {
  if (width === undefined || width > 800) {
    return '300px';
  }

  if (width > 600) {
    return '250px'
  }

  return '200px';
};

type PropTypes = {
  data: SiteTemperatureChartDto;
  timeRangeType: TimeRangeType;
  range: TimeRange;
  showPreviousYear: boolean;
};

const TemperatureChart = ({ data, timeRangeType, range, showPreviousYear }: PropTypes) => {
  const { t } = useTranslation();
  const { getUnit } = useLocalisation();
  const { canvasRef, datasets, hiddenDatasets, toggleDataset } = useTemperatureChart({ data, timeRangeType, range, showPreviousYear });
  const { width } = useSize(canvasRef);

  if (datasets?.length === 0) {
    return (
      <NoData
        large
        styles={{ height: 320, justifyContent: 'center' }}
        icon={duotone('temperature-empty')}
        label={t('NoSiteTemperatureData', { ns: 'status' })}
        subLabel={t('TryDifferentTimeRange', { ns: 'status' })}
      />
    );
  }

  return (
    <Wrapper>
      <UnitLabel>
        {getUnit(MetricType.Temperature)}
      </UnitLabel>

      <ChartContainer width={width}>
        <canvas id="siteTemperatureChart" ref={canvasRef} />
      </ChartContainer>

      <ChartLegend
        datasets={datasets}
        hiddenDatasets={hiddenDatasets}
        toggleDataset={toggleDataset}
      />
    </Wrapper>
  );
};

export default TemperatureChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

const ChartContainer = styled.div<{ width?: number }>`
  position: relative;
  height: ${({ width }) => getChartHeight(width)};
  width: 100%;
  margin-bottom: 25px;
  margin-left: -10px;
  transition: height 200ms ease;
`;

const UnitLabel = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
  margin-bottom: 7px;
`;