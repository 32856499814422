import DeviceNoteAttachmentDto from '@dashboard/api/models/DeviceNoteAttachmentDto';
import { PostFormDataQuery } from '@shared/api/queries/common/PostFormDataQuery';

export class DeviceNoteAttachmentCreateCommand extends PostFormDataQuery<DeviceNoteAttachmentDto> {

  constructor(deviceNoteId: number, file: File) {
    const formData = new FormData();
    formData.append('DeviceNoteId', deviceNoteId.toString());
    formData.append('File', file)

    super(formData);
  }

  public targetUrl(): string {
    return '/devicenotes/uploadattachment';
  }
}