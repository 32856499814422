import { Building } from '@shared/api/models/Building/Building';
import { NewBuilding } from '@shared/api/models/Building/NewBuilding';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class BuildingCreateCommand extends PostQuery<Building> {
  siteId: number;

  name: string;
  email?: string;
  phoneNumber?: string;
  useSiteAddress: boolean;
  assetArea?: number;

  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  country: string;
  postCode?: string;

  constructor(siteId: number, building: NewBuilding) {
    super();
    this.siteId = siteId;

    this.name = building.name;
    this.email = building.email;
    this.phoneNumber = building.phoneNumber;
    this.useSiteAddress = building.useSiteAddress;
    this.assetArea = building.assetArea;

    this.addressLine1 = building.address.addressLine1;
    this.addressLine2 = building.address.addressLine2;
    this.city = building.address.city;
    this.region = building.address.region;
    this.country = building.address.country;
    this.postCode = building.address.postCode;
  }

  public targetUrl(): string {
    return '/buildings';
  }
}
