import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import { ErrorMessage, Input, Label } from '@shared/components/atoms/Form/Form';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { useModal } from '@shared/hooks/useModal';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { SiteTemperatureDefaultRange } from './TemperatureWidget';
import { useApi } from '@shared/hooks/useApi';
import SiteMetadataUpdateCommand from '@settings/api/queries/SiteMetadata/SiteMetadataUpdateCommand';
import { SiteMetadata } from '@shared/api/models/SiteMetadata/SiteMetadata';
import { useCallback, useEffect } from 'react';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

type FormValues = {
  viewType: string;
  minTemperature: number;
  maxTemperature: number;
}

const TemperatureModal = () => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { toLocale, fromLocale, getUnit } = useLocalisation();
  const unit = getUnit(MetricType.Temperature);
  const theme = useTheme();
  const { execute, loading } = useApi();
  const { siteMetadata, refreshSiteMetadata } = useSiteContext();
  const { ref, isOpen, toggle } = useModal({});
  const methods = useForm<FormValues>({
    mode: 'onChange'
  });

  const initForm = useCallback(() => {
    methods.setValue('minTemperature', toLocale(MetricType.Temperature, siteMetadata?.minTemperature ?? SiteTemperatureDefaultRange.min, { round: 1 }));
    methods.setValue('maxTemperature', toLocale(MetricType.Temperature, siteMetadata?.maxTemperature ?? SiteTemperatureDefaultRange.max, { round: 1 }));
  }, [siteMetadata?.minTemperature, siteMetadata?.maxTemperature, methods, toLocale]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onSave = async (formValues: FormValues) => {
    trackAction('temperature_widget_save', 'overview_live_updates');

    if (!siteMetadata) {
      return;
    }

    const modifiedSiteMetadata: SiteMetadata = {
      ...siteMetadata,
      minTemperature: fromLocale(MetricType.Temperature, formValues.minTemperature, { round: 2 }),
      maxTemperature: fromLocale(MetricType.Temperature, formValues.maxTemperature, { round: 2 }),
    }

    await execute({
      query: new SiteMetadataUpdateCommand(modifiedSiteMetadata),
      pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    refreshSiteMetadata();
    toggle();
  }

  const onCancel = () => {
    toggle();
    initForm();
  }

  const handleToggle = () => {
    trackAction('temperature_widget_settings', 'overview_live_updates');
    toggle();
  }

  return (
    <>
      <Icon
        icon={solid('up-right-and-down-left-from-center')}
        onClick={handleToggle}
      />

      <Modal
        ref={ref}
        isOpen={isOpen}
        hide={toggle}
        title={`${t('Temperature', { ns: 'common' })} (${unit})`}
        width='380px'
      >
        <Column>
          {t('SiteOverview.TemperatureWidget.SetupThresholdsText', { ns: 'molecules' })}

          <FormProvider {...methods}>
            <FlexRow>
              <div>
                <Label style={{ fontSize: 12 }}>{t('SiteOverview.TemperatureWidget.MinimumTemperature', { ns: 'molecules' })}</Label>
                <Input {...methods.register('minTemperature', { max: { value: methods.watch('maxTemperature') - 1, message: t('SmallerThanMaxValue', { ns: 'validation' }) } })} type='number' min={0} />
                <ErrorMessage style={{ fontSize: 10 }}>{methods.formState.errors.minTemperature?.message}</ErrorMessage>
              </div>
              <div>
                <Label style={{ fontSize: 12 }}>{t('SiteOverview.TemperatureWidget.MaximumTemperature', { ns: 'molecules' })}</Label>
                <Input {...methods.register('maxTemperature', { min: { value: methods.watch('minTemperature'), message: t('LargerThanMinValue', { ns: 'validation' }) } })} type='number' />
                <ErrorMessage style={{ fontSize: 10 }}>{methods.formState.errors.maxTemperature?.message}</ErrorMessage>
              </div>
            </FlexRow>
          </FormProvider>

          <HelpText>
            {t('SiteOverview.TemperatureWidget.LiveDataText', { ns: 'molecules' })}
          </HelpText>

          <Buttons>
            <Button
              tertiary
              label={t('Cancel', { ns: 'common' })}
              color={theme.palette.red}
              onClick={onCancel}
              disabled={loading}
            />
            <Button
              label={t('Save', { ns: 'common' })}
              onClick={methods.handleSubmit(onSave)}
              disabled={loading}
            />
          </Buttons>
        </Column>
      </Modal>
    </>
  );
};

export default TemperatureModal;

const Icon = styled(FontAwesomeIcon) <{ margin?: string }>`
  width: 13px;
  height: 13px;
  margin: ${p => p.margin};
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;
  margin-left: auto;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-size: 14px;
  color: ${p => p.theme.text.primary};
`;

const FlexRow = styled.div`
  display: flex;
  gap: 8px;

  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;

  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const HelpText = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;