import styled, { css } from 'styled-components';
import { TDataset } from './useChart';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  datasets: TDataset[];
}

const YAxisTitles = ({ datasets }: PropTypes) => {
  const { t } = useTranslation();
  const { getUnit } = useLocalisation();

  if (datasets.length === 0) {
    return null;
  }

  const hasTemperatureAxis = datasets.some(x => x.metricType === MetricType.Temperature || x.metricType === MetricType.TemperatureTarget);
  const hasPIRAxis = datasets.some(x => x.metricType === MetricType.PIR);

  return (
    <Container>
      {hasTemperatureAxis &&
        <Label>
          {getUnit(MetricType.Temperature)}
        </Label>
      }

      {hasPIRAxis &&
        <Label alignRight>
          {t('PIR', { ns: 'enums' })}
        </Label>
      }
    </Container>
  );
};

export default YAxisTitles;

const Container = styled.div`
  display: flex;
  padding: 3px 3px 5px 11px;
`;

const Label = styled.div<{ alignRight?: boolean }>`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};

  ${p => p.alignRight && css`
    margin-left: auto;
  `}
`;