import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnergyMeterType_Suffix } from '@shared/api/enums/EnergyMeterType/EnergyMeterType_Suffix';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { FairUseForm } from '@shared/api/models/FairUse/FairUseForm';
import { SpaceFairUsePolicy } from '@shared/api/models/FairUse/SpaceFairUsePolicy';
import { SpaceTypeFairUsePolicy } from '@shared/api/models/FairUse/SpaceTypeFairUsePolicy';
import { Input } from '@shared/components/atoms/Form/Form';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useModal } from '@shared/hooks/useModal';
import { stringToNumber } from '@shared/utils/NumberUtils';
import _ from 'lodash';
import { FormEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

type ExceptionProps = {
  exception: SpaceFairUsePolicy,
  index: number,
  editMode: boolean,
  spaceTypeFairUsePolicy: SpaceTypeFairUsePolicy;
}

const Exception = ({ exception, index, editMode, spaceTypeFairUsePolicy }: ExceptionProps) => {
  const { t } = useTranslation();
  const { register, control, getValues, setValue, formState: { errors } } = useFormContext<FairUseForm>();
  const { isOpen: dialogIsOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});

  const updateExceptionChange = (exceptionsIndex: number, updatedValue: number): void => {
    setValue(`exceptions.${exceptionsIndex}.allowance`, updatedValue);
  }

  const deleteException = async () => {
    const exceptions = getValues('exceptions')?.filter((filteredException: SpaceFairUsePolicy) => !_.isEqual(filteredException.spaceIds, exception.spaceIds));
    setValue('exceptions', exceptions);
    toggleDialog();
  };

  return (
    <ExceptionWrapper>

      <ExceptionGroup>
        <ExceptionTitle>
          {exception.buildingName} &nbsp; {exception.name}
        </ExceptionTitle>
      </ExceptionGroup>

      {!getValues(`exceptions.${index}.exempt`) &&
        <ExceptionInputGroup>
          <ExceptionTitle>
            {exception.spaceType ? SpaceType_DisplayString(exception.spaceType) : 'Cluster'}
          </ExceptionTitle>
          <ExceptionTitle>
            <ExceptionInput
              error={!!(errors.exceptions && errors?.exceptions[index])}
              disabled={!editMode}
              onInput={(e: FormEvent<HTMLInputElement>) => updateExceptionChange(index, stringToNumber(e.currentTarget.value) ?? 0)}
              {...register(`exceptions.${index}.allowance`, {
                valueAsNumber: true,
                required: {
                  value: true,
                  message: t('FairUsage.Validation.RequiredSpaceType', { ns: 'molecules', spaceType: `${exception.buildingName} - ${exception.name}` })
                },
                min: {
                  value: 1,
                  message: t('FairUsage.Validation.EnterANaturalNumber', { ns: 'molecules', spaceType: `${exception.buildingName} - ${exception.name}` })
                },
                max: {
                  value: 999999,
                  message: t('FairUsage.Validation.NumberIsTooLong', { ns: 'molecules', spaceType: `${exception.buildingName} - ${exception.name}` })
                },
                validate: {
                  equal: (value) => value === spaceTypeFairUsePolicy.allowance ?
                    t('FairUsage.Validation.ExceptionCannotEqual', {
                      ns: 'molecules',
                      spaceTypeAllowance: `${spaceTypeFairUsePolicy.allowance}`,
                      spaceName: `${exception.buildingName} - ${exception.name}`,
                      spaceType: `${exception.spaceType}`
                    }) : true
                }
              })}
              type='number'
            />
          </ExceptionTitle>
          <ExceptionTitle>
            {EnergyMeterType_Suffix(exception.meterType)}
          </ExceptionTitle>
        </ExceptionInputGroup>
      }

      <ExceptionGroup>
        <ExemptContainer>
          <Controller
            control={control}
            name={`exceptions.${index}.exempt`}
            render={({ field: { onChange } }) => (
              <Switch
                checked={!!exception && exception.exempt}
                onChange={onChange}
                disabled={!editMode}
                styles={{ marginRight: '10px' }}
              />
            )}
          />
        </ExemptContainer>
        <ExceptionTitle>
          {t('FairUsage.Exceptions.Exempt', { ns: 'molecules' })}
        </ExceptionTitle>
        {editMode &&
          <>
            <WarningDialog
              modalRef={dialogRef}
              isOpen={dialogIsOpen}
              sectionOne={t('FairUsage.Exceptions.DeleteExceptionDialog', { ns: 'molecules' })}
              confirmButton={t('Remove', { ns: 'common' })}
              onCancel={toggleDialog}
              onConfirm={() => deleteException()}
            />
            <ExceptionButton
              icon={solid('minus')}
              onClick={toggleDialog}
            />
          </>
        }
      </ExceptionGroup>
    </ExceptionWrapper>
  );
};

export default Exception;

const ExceptionWrapper = styled.div`
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};

  &:last-child {
    border: none;
  }
`;

const ExceptionGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 30%;
  padding: 5px 0;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
    align-items: center;
  }
`;

const ExceptionInputGroup = styled(ExceptionGroup)`
& p {
  flex: 1 0 30%;

  &:first-child {
    text-align: end;
    padding-right: 5px;
  }
  }
`;

const ExceptionTitle = styled.p`
  margin: 0 5px;
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.palette.forms.label.color};
`;

const ExceptionButton = styled(FontAwesomeIcon)`
  width: 10px;
  height: 10px;
  color: ${p => p.theme.palette.text.fair};
  padding: 8px;
  background: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 2px 4px 0 ${p => p.theme.palette.shadows.strong};
  border-radius: 6px;
  margin: 0 10px;
  cursor: pointer;
`;

const ExceptionInput = styled(Input) <{ error: boolean }>`
  height: 30px;
  width: 90px;
  text-align: center;
  
  ${p => p.error && css`
  border: 1px solid ${p => p.theme.palette.red};
  `}
`;

const ExemptContainer = styled.div`
  justify-self: flex-end;
`;