import { Building } from '@shared/api/models/Building/Building';
import { DeleteBlobQuery } from '@shared/api/queries/common/DeleteBlobQuery';

export default class FloorplanDeleteCommand extends DeleteBlobQuery<Building> {
  floorId: number;

  constructor(blobName: string, floorId: number) {
    super(blobName);
    this.floorId = floorId;
  }

  public targetUrl(): string {
    return '/floors/floorplan';
  }
}
