import styled from 'styled-components';
import DeviceCardEnergyMeters from './DeviceCardEnergyMeters';
import { useTranslation } from 'react-i18next';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';

type PropTypes = {
  device: DeviceWithMetrics;
  summaryView?: boolean;
  inSidebar?: boolean;
}

const DeviceCardDetails = ({ device, inSidebar }: PropTypes) => {
  const { getDeviceConfig } = useDeviceConfigContext();
  const deviceConfig = getDeviceConfig(device.deviceModel);
  const { t } = useTranslation(['molecules', 'deviceConfigs', 'enums']);
  const { displayString } = useLocalisation();

  if (!deviceConfig) {
    return (
      <Wrapper>
        <Centered>{t('DeviceCard.DeviceNotSupported', { ns: 'molecules' })}</Centered>
      </Wrapper>
    );
  }

  return (
    <Wrapper inSidebar={inSidebar}>
      {deviceConfig.deviceType === DeviceType.Metering &&
        <DeviceCardEnergyMeters deviceId={device.id} />
      }

      {deviceConfig.ui.detailsCard.sections.map((section, i) => {
        const hasSomeMetrics = section.metrics.some(metricConfig => device.metrics.some(m => m.metricType === metricConfig.metricType));

        if (!hasSomeMetrics) {
          return null;
        }

        return (
          <div key={i}>
            {section.title &&
              <Title
                text={t(section.title, { ns: 'deviceConfigs' })}
                size='sm'
                subtitle={section.subTitle ? { text: t(section.subTitle, { ns: 'deviceConfigs' }) } : undefined}
              />
            }

            <MetricList>
              {section.metrics.map((metricConfig, i) => {
                const metric = device.metrics.find(m => m.metricType === metricConfig.metricType);

                if (!metric) {
                  return null;
                }

                return (
                  <LabelValuePair
                    key={i}
                    label={metricConfig.label ? t(metricConfig.label, { ns: 'deviceConfigs' }) : t(metric.metricType, { ns: 'enums' })}
                    value={displayString(metric.metricType, metric.value)}
                    prefix={metricConfig.prefix && t(metricConfig.prefix, { ns: 'deviceConfigs' })}
                    size="xs"
                    valueStyle={{ fontWeight: 500 }}
                  />
                );
              })}
            </MetricList>

            {i !== deviceConfig.ui.detailsCard.sections.length - 1 &&
              <Divider />
            }
          </div>
        );
      })}
    </Wrapper>
  );
};

export default DeviceCardDetails;

const Wrapper = styled.div<{ inSidebar?: boolean }>`
  > * {
    padding: ${p => p.inSidebar ? '0' : '0 24px'};
  }

  > :first-child {
    padding: ${p => p.inSidebar ? '12px 0 0 0' : '16px 24px 0 24px'};
    border-top: 1px solid ${p => p.theme.action.divider};
  }

  > :last-child {
    padding: ${p => p.inSidebar ? '0 0 12px 0' : '0 24px 16px 24px'};
  }

  > :only-child {
    padding: ${p => p.inSidebar ? '12px 0' : '16px 24px'};
    border-top: 1px solid ${p => p.theme.action.divider};
  }
`;

const Centered = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const MetricList = styled.div`
  display: flex;
  gap: 20px 40px;
  flex-wrap: wrap;
`;

const Divider = styled.div`
  margin: 14px 0;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;