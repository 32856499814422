import countries from 'i18n-iso-countries';

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const getCountries = () => {
  const countryData = countries.getNames('en', { select: 'official' })
  const countriesArray = Object.entries(countryData)
    .map(([key, value]) => {
      return {
        label: value,
        code: key
      }
    });

  return countriesArray;
}
