import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeType } from '@shared/theme/theme';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const getTypeConfig = (theme: ThemeType, type?: SystemMessageType) => {
  switch (type) {
    case SystemMessageType.Error:
      return { color: theme.palette.systemMessage.error, icon: solid('hexagon-exclamation') };
    case SystemMessageType.Warning:
      return { color: theme.palette.systemMessage.warning, icon: solid('triangle-exclamation') };
    case SystemMessageType.Success:
      return { color: theme.palette.systemMessage.success, icon: solid('circle-check') };
    case SystemMessageType.Information:
    default:
      return { color: theme.palette.systemMessage.information, icon: solid('circle-info') };
  }
};

export enum SystemMessageType {
  Error = 'Error',
  Warning = 'Warning',
  Information = 'Information',
  Success = 'Success'
}

type PropTypes = {
  messages: ReactNode[];
  type?: SystemMessageType;
  dissmisable?: boolean;
  styles?: React.CSSProperties;
  hideIcon?: boolean;
  dissmisPermanentlyKey?: string;
};

const SystemMessage = ({ messages, type, dissmisable, styles, hideIcon = true, dissmisPermanentlyKey }: PropTypes) => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(true);
  const config = getTypeConfig(theme, type);

  // Hide the message if it has previously been dismissed
  useEffect(() => {
    if (dissmisPermanentlyKey && localStorage.getItem(dissmisPermanentlyKey)) {
      setIsVisible(false);
    }
  }, [dissmisPermanentlyKey]);

  const handleClose = useCallback(() => {
    setIsVisible(false);

    if (dissmisPermanentlyKey) {
      localStorage.setItem(dissmisPermanentlyKey, 'dismissed')
    }
  }, [dissmisPermanentlyKey]);

  if (!isVisible) {
    return null;
  }

  return (
    <Banner
      color={config.color}
      style={styles}
    >
      {messages.map((message, i) => (
        <FlexRow key={uuidv4()}>
          {!hideIcon &&
            <TypeIcon icon={config.icon} color={config.color} />
          }

          {message}

          {dissmisable && i === 0 &&
            <CloseIcon icon={solid('xmark')} onClick={handleClose} />
          }
        </FlexRow>
      ))}
    </Banner>
  );
};

export default SystemMessage;

const Banner = styled.div<{ color: string }>`
  width: 100%;
  border: 2px solid ${p => p.color};
  border-radius: 4px;
  border-left-width: 14px;
  padding: 12px 16px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
`;

const TypeIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`;

const CloseIcon = styled(FontAwesomeIcon)` 
  font-size: 16px;
  color: ${p => p.theme.palette.systemMessage.xmark};
  padding: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.topbar.iconHoverBackground};
  }
`;