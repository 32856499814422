import { useCallback, useEffect, useRef } from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { Chart, Filler, LineController, LinearScale, BarController, BarElement, PointElement, LineElement, ChartTypeRegistry, ChartData, ChartOptions, ScriptableContext, TooltipItem } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { numberWithCommas } from '@shared/utils/NumberUtils';
import { rgbaToRgb } from '@dashboard/utils/ColorUtils';

Chart.register(LineController, BarElement, BarController, LinearScale, PointElement, LineElement);

export type DailyConsumptionDataset = {
  label: string,
  dataset: number[],
  dataUnit: string,
  color: string,
};

export interface IDailyConsumptionChart {
  /**
   * Chart datasets
   */
  datasets: DailyConsumptionDataset[];
  /**
   * Array of labels
   */
  labels?: string[];
  /**
   * Is true when the page is about to open the print dialog, rerender graphs in printable dimensions.
   */
  inPrintMode: boolean;
}

const DailyConsumptionChart = ({ datasets, labels, inPrintMode }: IDailyConsumptionChart) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const currentBackground = theme.palette.backgrounds.surface;

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, number[], string> => {
    return {
      labels: labels,
      datasets: datasets.map(x => (
        {
          label: x.label,
          data: x.dataset,
          dataUnit: x.dataUnit,
          backgroundColor: (scriptableContext: ScriptableContext<keyof ChartTypeRegistry>) => createGradient(scriptableContext, x.color, currentBackground, inPrintMode),
          borderColor: (scriptableContext: ScriptableContext<keyof ChartTypeRegistry>) => createGradient(scriptableContext, x.color, currentBackground, inPrintMode)
        }
      )),
    }
  }, [datasets, labels, currentBackground, inPrintMode]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const chart = new Chart(context, {
        type: 'line',
        data: constructData(),
        options: constructOptions(theme, inPrintMode, datasets[0]?.dataUnit ?? ''),
        plugins: [Filler],
      });

      return () => chart.destroy();
    }
  }, [theme, inPrintMode, datasets, constructData]);

  return (
    <Wrapper inPrintMode={inPrintMode}>
      <canvas id="myChart" ref={canvasRef} />
    </Wrapper>
  );
};

export default DailyConsumptionChart;

const Wrapper = styled.div<{ inPrintMode: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 5px;

  ${p => p.inPrintMode && css`
    width: 179.3mm;
    height: 23.5mm;
    padding-top: 0.5mm;
  `}
`;

const constructOptions = (theme: DefaultTheme, inPrintMode: boolean, unitSuffix: string): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {
      padding: {
        top: 14,
      },
    },
    scales: {
      x: {
        display: false
      },
      y: {
        position: 'right',
        grid: {
          borderDash: [6, 12],
          color: theme.palette.charts.gridLineColorStrong,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          z: 1,
          mirror: true,
          labelOffset: inPrintMode ? -7 : -8,
          padding: 0,
          callback: (value, index, ticks) => {
            // Draw only the first and last tick
            return index === 0 || index === ticks.length - 1 ? `${numberWithCommas(value as number, 0)} ${unitSuffix}     ` : undefined;
          },
          color: inPrintMode ? theme.palette.printStyles.text.fair : theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: inPrintMode ? 9 : 11,
            weight: '500',
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 1,
        fill: true,
        tension: 0.4,
      },
      bar: {
        borderWidth: 0,
        borderRadius: 5,
      },
      point: {
        radius: 0,
        hoverRadius: 3,
        hoverBackgroundColor: '#fff',
        hoverBorderWidth: 2,
        hitRadius: 10,
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'point',
        intersect: false,
        displayColors: false,
        caretPadding: 9,
        caretSize: 6,
        backgroundColor: theme.palette.backgrounds.surface,
        cornerRadius: 3,
        padding: 6,
        borderColor: theme.palette.borders.medium,
        borderWidth: 1,
        titleMarginBottom: 3,
        titleColor: theme.palette.text.fair,
        titleFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        bodyColor: theme.palette.text.medium,
        bodyFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        callbacks: {
          label: (item: TooltipItem<'bar' | 'line'>) => {
            const dataset = (item.dataset as unknown as DailyConsumptionDataset);
            const unit = dataset.dataUnit;

            return `${numberWithCommas(item.raw as number, 0)} ${unit}`;
          },
        },
      },
      legend: {
        display: false,
      },
    }
  };
}

const createGradient = (scriptableContext: ScriptableContext<keyof ChartTypeRegistry>, color: string, currentBackground: string, inPrintMode: boolean): (CanvasGradient | string) => {
  const chart: Chart = scriptableContext.chart;
  const { ctx, chartArea } = chart;

  if (!ctx || !chartArea) {
    return color;
  }

  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, rgbaToRgb(transparentize(inPrintMode ? 0.85 : 0.95, color), currentBackground));
    gradient.addColorStop(1, color);
  }

  return gradient ?? color;
}