import { callPatch, IAuthConfig } from '@shared/utils/ConnectApiUtils';
import { BaseQuery } from './BaseQuery';

export abstract class BulkPatchQuery<TDto> extends BaseQuery<TDto> {
  public ids?: number[];

  constructor(ids?: number[]) {
    super();
    this.ids = ids;
  }

  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.apiBaseUrl);
    const response = await callPatch(apiUrl, this, authConfig, controller);

    return response.data;
  }
}
