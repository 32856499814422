import { Tenant } from '@shared/api/models/Tenant/Tenant';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class TenantUpdateCommand extends PatchQuery<Tenant> {
  tenantId: string;
  name?: string;
  email?: string;

  constructor(tenant: Tenant) {
    super();
    this.tenantId = tenant.tenantId;
    this.name = tenant.name;
    this.email = tenant.email;
  }

  public targetUrl(): string {
    return '/tenants/update';
  }
}
