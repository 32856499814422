import styled from 'styled-components';
import { transparentize } from 'polished';
import { BrowserMultiFormatReader, Result } from '@zxing/library';
import { useCallback, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';

//#region BarcodeScanner component

type PropTypes = {
  enabled?: boolean,
  onUpdate: (text: string) => void,
}

const BarcodeScanner = ({ enabled, onUpdate }: PropTypes) => {
  if (!enabled) {
    return null;
  }

  return (
    <Wrapper>
      <WebcamComponent
        onUpdate={(_, result: any) => result && onUpdate(result.text)} // eslint-disable-line @typescript-eslint/no-explicit-any
        onError={(err) => console.log(err)}
        stopStream={!enabled}
      />
    </Wrapper>
  );
};

export default BarcodeScanner;

const Wrapper = styled.div`
  margin: 8px 2px;
  padding: 8px 8px 0 8px;
  border: 2px dashed ${p => transparentize(0.5, p.theme.palette.text.weak)};
  border-radius: 4px;

  video {
    border-radius: 4px;
  }
`;

//#endregion

//#region WebcamComponent component

interface IWebcamProps {
  onUpdate: (arg0: unknown, arg1?: Result | undefined) => void;
  onError?: ((arg0: string | DOMException) => void);
  width?: string | number;
  height?: string | number;
  facingMode?: 'environment' | 'user';
  delay?: number | undefined;
  videoConstraints?: MediaTrackConstraints;
  stopStream?: boolean;
}

/**
 * Based on 'BarcodeScannerComponent.tsx' from https://github.com/jamenamcinteer/react-qr-barcode-scanner
 */
const WebcamComponent = ({
  onUpdate,
  onError,
  width = '100%',
  height = '100%',
  facingMode = 'environment',
  delay = 500,
  videoConstraints,
  stopStream,
}: IWebcamProps): React.ReactElement => {
  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    const codeReader = new BrowserMultiFormatReader();
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (imageSrc) {
      codeReader
        .decodeFromImage(undefined, imageSrc)
        .then((result) => {
          onUpdate(null, result);
        })
        .catch((err) => {
          onUpdate(err);
        });
    }
  }, [onUpdate]);

  useEffect(() => {
    if (stopStream) {
      let stream = webcamRef?.current?.video?.srcObject;
      if (stream) {
        const mediaStream = stream as MediaStream;
        mediaStream.getTracks().forEach((track: MediaStreamTrack) => {
          mediaStream.removeTrack(track);
          track.stop();
        });
        stream = null;
      }
    }
  }, [stopStream, capture]);

  useEffect(() => {
    const interval = setInterval(capture, delay);
    return () => {
      clearInterval(interval);
    };
  }, [capture, delay]);

  return (
    <Webcam
      width={width}
      height={height}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      videoConstraints={
        videoConstraints ?? {
          facingMode,
        }
      }
      audio={false}
      onUserMediaError={onError}
    />
  );
};

//#endregion