import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { ConsumptionChartDataset, ConsumptionChartStack } from './useMonthlyChart';
import { LineBarChartType } from '@shared/components/molecules/Charts/Chart.types';
import { v4 as uuidv4 } from 'uuid';
import { groupBy, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  datasets: ConsumptionChartDataset[];
  onChange: (hiddenDatasets: { [key: string]: string }) => void;
  isGasWidget: boolean;
}

const ChartLegend = ({ datasets, onChange, isGasWidget }: PropTypes) => {
  const { t } = useTranslation();
  const [hiddenDatasets, setHiddenDatasets] = useState<{ [key: string]: string }>({});
  let groups = Object.entries(groupBy(datasets, x => x.legend.group));
  groups = orderBy(groups, x => x[0]);

  /**
   * Reset when datasets change
   */
  useEffect(() => {
    setHiddenDatasets({});
  }, []);

  /**
   * Modify the object containing keys (the dataset id) of the hidden datasets as they are selected/deselected in the legend.
   */
  const handleToggle = useCallback((id: string) => {
    const hidden = { ...hiddenDatasets };
    const arrayIndex = hidden[id];

    if (arrayIndex) {
      delete hidden[id];
    } else {
      hidden[id] = id;
    }

    setHiddenDatasets(hidden);
    onChange(hidden);
  }, [hiddenDatasets, onChange]);

  return (
    <Wrapper>
      {groups.map(([group, datasets]) => {
        if (group === ConsumptionChartStack.Total) {
          return null;
        }

        return (
          <Category key={group} showBorder={!isGasWidget}>
            {!isGasWidget &&
              <CategoryLabel>
                {t(`SiteOverview.${group}`, { ns: 'molecules' })}
              </CategoryLabel>
            }

            <Items>
              {orderBy(datasets, x => x.legend.order).map(({ label, type, id, legend }) => {
                const isHidden = !!hiddenDatasets[id];
                const isLine = type === LineBarChartType.Line;

                return (
                  <Item
                    key={uuidv4()}
                    onClick={() => handleToggle(id)}
                    inactive={isHidden}
                  >
                    <TypeSymbol
                      isLine={isLine}
                      color={legend.color}
                    />

                    {isHidden &&
                      <LineThrough />
                    }

                    <Label inactive={isHidden}>
                      {label}
                    </Label>
                  </Item>
                );
              })}
            </Items>
          </Category>
        )
      })}
    </Wrapper>
  );
};

export default ChartLegend;

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const Category = styled.div<{ showBorder: boolean }>`
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;
  
  ${p => p.showBorder && css`
    padding: 10px 13px 7px 13px;
    border-color: ${p => p.theme.palette.borders.medium};
  `}
`;

const CategoryLabel = styled.div`
  position: absolute;
  top: -9px;
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
  width: max-content;
  padding: 0 7px 0 6px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
`;

const Item = styled.div<{ inactive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Label = styled.div<{ inactive: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.inactive ? p.theme.palette.text.weak : p.theme.palette.text.fair};
  user-select: none;
`;

const TypeSymbol = styled.div<{ isLine: boolean, color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  flex-shrink: 0;
  background-color: ${p => p.color};

  ${p => p.isLine && css`
    height: 3px;
    background-color: ${p.color};
  `}
`;

const LineThrough = styled.div`
  position: absolute;
  left: -4px;
  width: calc(100% + 8px);
  height: 2px;
  border-radius: 2px;
  background-color: ${p => transparentize(0.5, p.theme.palette.text.fair)};
`;