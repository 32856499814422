import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { Chart, Filler, Tooltip, LineController, LinearScale, TimeScale, BarController, BarElement, PointElement, LineElement, Legend } from 'chart.js';
import ZoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import ChartOptionsFactory, { ChartAxisPosition, LineBarChartOverrides, xAxisTimeUnit } from './ChartOptionsFactory';
import ChartLegend from './ChartLegend';
import { ChartDataPoint, ChartDataset, LineBarChartType } from '@shared/components/molecules/Charts/Chart.types';
import { TimeScaleRange } from '@dashboard/utils/ChartUtils';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';

Chart.register(LineController, BarElement, BarController, LinearScale, TimeScale, PointElement, LineElement);

export interface ILineBarChartProps {
  /**
   * Chart datasets
   */
  datasets: ChartDataset[];
  /**
   * Array of labels
   */
  labels?: string[];
  /**
   * Chart type (default: 'line')
   */
  type?: LineBarChartType;
  /**
   * Label of the y-axis
   */
  yAxisLabel?: string;
  /**
   * Label of the y-axis
   */
  yAxisLabelPosition?: ChartAxisPosition;
  /**
   * Label of the y1-axis
   */
  y1AxisLabel?: string;
  /**
   * Time unit of the x-axis
   */
  xAxisTimeUnit?: xAxisTimeUnit;
  /**
   * Time range to zoom into
   */
  timeScaleRange?: TimeScaleRange;
  /**
   * Show legend?
   */
  showLegend?: boolean;
  /**
   * Calls function with last zoom/pan min and max tick values
   */
  onZoomPanComplete?: (min: number, max: number) => void;
  /**
   * Options to override default chart options.
   */
  chartOptions?: LineBarChartOverrides;
  /**
   * boolean value to determine whether chart is multi axis or not
   */
  isMultiAxisChart?: boolean
  /**
   * Enable zoom
   */
  enableZoom?: boolean
}

const LineBarChart = ({ type = LineBarChartType.Line, labels, datasets, yAxisLabel, yAxisLabelPosition, y1AxisLabel, xAxisTimeUnit, timeScaleRange, showLegend = false, onZoomPanComplete, chartOptions, isMultiAxisChart, enableZoom }: ILineBarChartProps) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart<'bar' | 'line', ChartDataPoint[], string>>();
  const { localisation } = useLocalisation();

  /**
   * Create ChartOptionsFactory object to access common/shared chart option definitions.
   */
  const OptionsFactory = useMemo(() => {
    return new ChartOptionsFactory(theme, yAxisLabel, yAxisLabelPosition, y1AxisLabel, xAxisTimeUnit, type, onZoomPanComplete, isMultiAxisChart, localisation);
  }, [yAxisLabel, yAxisLabelPosition, theme, type, onZoomPanComplete, isMultiAxisChart, xAxisTimeUnit, y1AxisLabel, localisation]);

  /**
   * Create chart data object containing the different datasets.
   */
  const data = useMemo(() => ({
    labels: labels,
    datasets: datasets.map(x => (
      {
        label: x.label,
        displayLabel: x.displayLabel,
        data: x.dataset,
        dataUnit: x.dataUnit,
        borderColor: transparentize(0.2, x.color),
        backgroundColor: type === LineBarChartType.Line ? transparentize(0.85, x.color) : x.color,
        pointBorderColor: transparentize(0.2, x.color),
        pointHoverBorderColor: x.color,
        yAxisID: x.yAxisId
      }
    )),
  }), [type, labels, datasets]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const enabledPlugins = enableZoom ? [Filler, Tooltip, Legend, ZoomPlugin] : [Filler, Tooltip, Legend];

      const chart = new Chart(context, {
        type: type,
        data: data,
        plugins: enabledPlugins,
        options: OptionsFactory.LineBarChartOptions(chartOptions)
      });

      setChart(chart);

      return () => chart.destroy();
    }
  }, [type, data, chartOptions, OptionsFactory, enableZoom]);

  /**
   * Show/hide datasets from the chart as they are selected/deselected in the legend component.
   */
  const handleLegendChange = (hiddenDatasets: number[]) => {
    if (chart) {
      chart.data.datasets.forEach((dataset, i) => {
        dataset.hidden = hiddenDatasets.includes(i);
      });

      chart.update();
    }
  };

  /**
   * Set the time unit and zoom range of the x-axis and update the chart.
   */
  useMemo(() => {
    if (chart && timeScaleRange && enableZoom) {
      (chart.options.scales as any).x.time.unit = timeScaleRange.xScaleTimeUnit; // eslint-disable-line @typescript-eslint/no-explicit-any
      chart.stop(); // make sure animations are not running
      chart.zoomScale('x', timeScaleRange.scaleRange, 'none');
    }
  }, [chart, timeScaleRange, enableZoom]);

  return (
    <Wrapper style={{ height: '100%', width: '100%' }}>

      <div style={{ position: 'relative', height: '100%', width: showLegend ? 'calc(99% - 200px)' : '99%' }}>
        <canvas id="myChart" ref={canvasRef} />
      </div>

      {showLegend &&
        <ChartLegend
          datasets={datasets}
          onchange={handleLegendChange}
        />
      }

    </Wrapper>
  );
};

export default LineBarChart;

const Wrapper = styled.div`
  display: flex;
`;