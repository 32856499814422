import previewImageDark from '@shared/assets/images/climate-control-preview-dark.svg';
import previewImage from '@shared/assets/images/climate-control-preview.svg';
import dotPattern from '@shared/assets/images/dot-pattern-background.svg';
import { Title } from '@shared/components/atoms/Title/Title';
import { useThemeContext } from '@shared/contexts/ThemeContext/ThemeContext';
import PreviewBanner from '@src/components/shared/PreviewBanner/PreviewBanner';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ClimateControlPreview = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useThemeContext();

  return (
    <Container>
      <StyledImage
        src={dotPattern}
        alt='dot-pattern-backgound'
      />
      <Title
        size='lg'
        text={t('ClimateControl.TemperatureControl', { ns: 'molecules' })}
        style={{ marginBottom: '20px' }}
      />

      <PreviewBanner
        feature={'Temperature Control'}
        heading={t('ClimateControl.Preview.Heading', { ns: 'molecules' })}
        bulletPoints={[
          t('ClimateControl.Preview.P1', { ns: 'molecules' }),
          t('ClimateControl.Preview.P2', { ns: 'molecules' }),
          t('ClimateControl.Preview.P3', { ns: 'molecules' }),
          t('ClimateControl.Preview.P4', { ns: 'molecules' })
        ]}
        image={isDarkTheme ? previewImageDark : previewImage}
      />
    </Container>
  );
};

export default ClimateControlPreview;

const Container = styled.div`
  position: relative;
  padding: 30px 35px;

  @media (max-width: 600px) {
    padding: 20px 25px;
  }

  @media (max-width: 375px) {
    padding: 10px 15px;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  
  @media (max-width: 1200px) {
    width: 1200px;
  }
`;