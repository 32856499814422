import { useCallback, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import styled, { useTheme } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeviceGatewayGetAllQuery from '@settings/api/queries/Gateway/DeviceGatewayGetAllQuery';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Device } from '@shared/api/models/Device/Device';
import { ICascaderMultiSelectNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApiState } from '@shared/hooks/useApiState';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { includesCI } from '@shared/utils/StringUtils';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import DeviceModelFilter from '@src/components/shared/Filters/DeviceModelFilter';
import DeviceTypeFilter from '@src/components/shared/Filters/DeviceTypeFilter';
import { LocationFilter } from '@src/components/shared/Filters/LocationFilter/LocationFilter';
import { HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';
import { BuildingHierarchiesWithDevicesGetBySiteIdQuery } from '@dashboard/api/queries/building/BuildingHierarchiesWithDevicesGetBySiteIdQuery';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

const DeviceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { site } = useSiteContext();
  const { getDisplayString, getDeviceType } = useDeviceConfigContext();
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<HierarchySpace>>();
  const [searchString, setSearchString] = useState<string>('');
  const [devices, setDevices] = useState<Device[]>([]);
  const [resetPulse, setResetPulse] = useState<number>(0);
  const [tableRecordCount, setTableRecordCount] = useState<number>();
  const [spaceFilter, setSpaceFilter] = useState<HierarchySpace[] | undefined>([]);
  const [modelFilter, setModelFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const { data: buildings, loading } = useApiState({
    query: new BuildingHierarchiesWithDevicesGetBySiteIdQuery(site.id),
    initialState: [],
  }, []);
  const { data: gateways } = useApiState({
    query: new DeviceGatewayGetAllQuery(),
    initialState: [],
  }, []);

  const isGatewayDevice = (device: string) => device.includes('Kerlink');

  const hasFilters = useMemo(() => (
    modelFilter.length > 0 || typeFilter.length > 0 || spaceFilter
  ), [modelFilter, typeFilter, spaceFilter]);

  const tableColumns: ITableColumn<Device>[] = useMemo(() => ([
    {
      label: t('DeviceManagement.FriendlyName', { ns: 'settingsDevice' }),
      key: 'friendlyName'
    },
    {
      label: t('DeviceManagement.DeviceModel', { ns: 'settingsDevice' }),
      key: 'deviceModel',
      displayFormat: ({ deviceModel }) => isGatewayDevice(deviceModel) ? deviceModel : getDisplayString(deviceModel),
    },
    {
      label: t('DeviceManagement.DeviceIdentifier', { ns: 'settingsDevice' }),
      key: 'deviceIdentifier'
    },
    {
      label: t('DeviceManagement.Building', { ns: 'settingsDevice' }),
      key: 'building'
    },
    {
      label: t('DeviceManagement.Space', { ns: 'settingsDevice' }),
      key: 'spaceName'
    }
  ]), [getDisplayString, t]);

  useEffect(() => {
    const options: ICascaderMultiSelectNodeGroup<HierarchySpace> = {
      header: t('DeviceManagement.Buildings', { ns: 'settingsDevice' }),
      nodes: orderBy(buildings, x => x.name.toLocaleLowerCase()).map(building => ({
        label: `${building.name}`,
        childGroup: {
          header: t('DeviceManagement.Floors', { ns: 'settingsDevice' }),
          nodes: orderBy(building.floors, x => x.floorNumber).map(floor => ({
            label: `${floor.name}`,
            childGroup: {
              header: t('DeviceManagement.Spaces', { ns: 'settingsDevice' }),
              nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map(space => ({
                label: `${space.name}`,
                value: space
              }))
            }
          }))
        }
      }))
    }

    const devices: Device[] = buildings.flatMap(b =>
      b.floors.flatMap(f =>
        f.spaces.flatMap(s =>
          s.devices.map(d =>
            ({ ...d, spaceName: s.name, building: b.name })
          )
        )
      )
    );

    if (gateways) {
      gateways.forEach(gateway => {
        return devices.push({
          building: gateway.buildingName,
          site: gateway.siteName,
          id: gateway.id,
          manufacturer: 'Kerlink',
          deviceModel: gateway.deviceGatewayModel.deviceModel,
          deviceIdentifier: gateway.deviceIdentifier,
          friendlyName: gateway.friendlyName,
          spaceName: gateway.space,
          isBillingMeter: false,
          lastMeasuredOn: '',
          createdOn: ''
        });
      });
    }

    setDevices(devices);
    setCascaderOptions(options);
  }, [gateways, buildings, t]);

  const searchFilter = useCallback((x: Device) => {
    return includesCI(x.friendlyName, searchString)
      || includesCI(x.deviceModel, searchString)
      || includesCI(x.deviceIdentifier, searchString)
      || includesCI(x.spaceName, searchString)
  }, [searchString]);

  const locationFilter = useCallback((x: Device) => {
    return (spaceFilter && x.spaceId) ? spaceFilter.map(x => x.id).includes(x.spaceId) : true;
  }, [spaceFilter]);

  const deviceModelFilter = useCallback((x: Device) => {
    return modelFilter.length > 0 ? modelFilter.includes(x.deviceModel) : true;
  }, [modelFilter]);

  const deviceTypeFilter = useCallback((x: Device) => {
    return typeFilter.length > 0 ? typeFilter.includes(getDeviceType(x.deviceModel)) : true;
  }, [typeFilter, getDeviceType]);

  const deviceModelOptions = useMemo(() => (
    devices.map(x => x.deviceModel)
  ), [devices]);

  return (
    <PaddedContainer largePadding>
      <FlexContainer>
        <Title
          text={
            <>
              {t('DeviceManagement', { ns: 'common' })} {tableRecordCount !== undefined && <ThemeColored>({tableRecordCount})</ThemeColored>}
            </>
          }
        />

        <RightAligned>
          <FlexRow>
            {hasFilters &&
              <Button
                tertiary
                label='Clear filters'
                onClick={() => setResetPulse(prev => prev + 1)}
              />
            }

            <DeviceModelFilter
              deviceModels={deviceModelOptions}
              onChange={setModelFilter}
              resetPulse={resetPulse}
            />

            <DeviceTypeFilter
              devices={devices}
              onChange={setTypeFilter}
              resetPulse={resetPulse}
            />

            {cascaderOptions &&
              <LocationFilter
                label={t('Space', { ns: 'common' })}
                group={cascaderOptions}
                onChange={setSpaceFilter}
                resetPulse={resetPulse}
              />
            }

            <SearchField
              placeholder={t('DeviceManagement.SearchDevices', { ns: 'settingsDevice' })}
              onSearchChange={setSearchString}
              style={{ background: theme.action.filled }}
            />

            <Link to={'create'}>
              <Button
                tertiary
                circle
                label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
                tooltip={{ content: t('DeviceManagement.AddDevice', { ns: 'settingsDevice' }), placement: TooltipPlacement.Left }}
              />
            </Link>
          </FlexRow>
        </RightAligned>
      </FlexContainer>

      <Table
        columns={tableColumns}
        records={devices}
        recordKey="id"
        emptyMessage={t('DeviceManagement.NoDevicesFound', { ns: 'settingsDevice' })}
        defaultSortColumn="friendlyName"
        onRowClick={(row: Device) => { navigate(isGatewayDevice(row.deviceModel) ? `gateway/${row.id}` : `${row.id}`) }}
        loading={loading}
        cardEffect
        highlightString={searchString}
        filters={[searchFilter, locationFilter, deviceModelFilter, deviceTypeFilter]}
        fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 200}
        onRecordCountChange={setTableRecordCount}
      />
    </PaddedContainer>
  );
}

export default DeviceList;

const FlexContainer = styled.div` 
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const FlexRow = styled.div` 
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const RightAligned = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-left: auto;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;