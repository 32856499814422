import { DeviceStatus } from '@dashboard/api/enums/DeviceStatus';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeviceStatusGetQuery from '@shared/api/queries/Devices/DeviceStatusGetQuery';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { useApiState } from '@shared/hooks/useApiState';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type PropTypes = {
  deviceId: number;
}

const DeviceCardConnectionStatus = ({ deviceId }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data } = useApiState({
    query: new DeviceStatusGetQuery(deviceId)
  }, [deviceId]);

  if (!data) {
    return (
      <LabelValuePair
        label={t('ConnectionStatus', { ns: 'common' })}
        value='-'
        size="xs"
      />
    );
  }

  const status = data.status.toLowerCase();

  const label = status === DeviceStatus.Online
    ? t('Connected', { ns: 'common' })
    : t('Disconnected', { ns: 'common' });

  const icon = status === DeviceStatus.Online
    ? <Icon icon={solid('wifi')} color={theme.widget.green} />
    : <Icon icon={solid('wifi-slash')} color={theme.widget.red} />;

  return (
    <div>
      <Label>
        {t('ConnectionStatus', { ns: 'common' })}
      </Label>
      <FlexRow>
        {icon}
        <Value>{label}</Value>
      </FlexRow>
    </div>
  );
};

export default DeviceCardConnectionStatus;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.text.secondary};
  padding-bottom: 2px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-top: -1px;
`;