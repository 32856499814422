import { ThemeType } from './lightTheme';

export const darkTheme: ThemeType = {
  id: 2,
  name: 'Dark',

  fontFamily: 'DM Sans',

  palette: {
    primary: '#007CF1',
    secondary: '#374596',
    green: '#44A901',
    orange: '#FFAA37',
    red: '#FF4B5A',

    text: {
      weak: '#848484',
      fair: '#c4c4c4',
      medium: '#dddddd', // default
      onPrimary: '#121212',
      onSecondary: '#000'
    },

    backgrounds: {
      background: '#121212',
      surface: '#1e1e1e',
      surfaceStrong: '#1a1a1a',
      climateControl: '#232330',
      info: '#121212'
    },

    borders: {
      weak: '#303030',
      medium: '#535353', // default
      strong: '#696969'
    },

    shadows: {
      extraLight: '#0c0c0c',
      light: '#ffffff4D',
      medium: '#0c0c0c',
      strong: '#000'
    },

    buttons: {
      background: '#303030',
      hoverBackground: '#343438'
    },

    charts: {
      tooltipText: '#dddddd',
      tooltipBackground: '#1a1a1a',
      gridLineColor: '#181818',
      gridLineColorStrong: '#000000',
      legend: {
        evenItem: '#161616',
        unevenItem: '#101010'
      }
    },

    forms: {
      label: {
        color: '#c4c4c4'
      },
      input: {
        border: '#303030',
        background: '#2a2a2e',
        placeholderText: '#848484',
        disabledText: '#b7b7b7',
        disabledBorder: '#1a1a1a',
        disabledBackground: '#1a1a1a',
        disabledDarkBackground: '#1a1a1a',
        shadow: '#1a1a1d',
        boxShadow: '0px 5px 2px -4px #1a1a1d',
        focusShadow: '#0c0c0e'
      },
      switch: {
        innerShadow: '#0c0c0e',
        background: {
          checked: '#007CF1',
          unchecked: '#3e3e48',
          disabled: '#93B6F4'
        },
        dotShadow: {
          checked: '#0c0c0e',
          unchecked: '#23232a'
        }
      },
      select: {
        label: {
          shadow: '#0c0c0c5c'
        }
      }
    },

    tables: {
      titleRow: '#161616',
      evenRow: '#1e1e1e',
      unevenRow: '#1a1a1a',
      backgroundHighlight: '#161616c2'
    },

    map: {
      road: '#303030',
      transit: '#272727',
      landscape: '#1A1A1A',
      water: '#121212'
    },

    scrollbar: {
      track: 'transparent',
      thumb: '#494949'
    },

    sidebar: {
      collapseHandleShadow: '#000000',
      expandHandleShadow: '#0c0c0c'
    },

    navbar: {
      link: '#949494',
      selectedLink: '#dddddd'
    },

    topbar: {
      iconHoverBackground: '#141414'
    },

    cascader: {
      checkboxBorder: '#747681',
      checkboxBackground: '#1e1e1e'
    },

    select: {
      hover: '#1a1a1a'
    },

    popover: {
      background: '#1e1e1e',
      shadow: '#000'
    },

    dropdown: {
      shadowWeak: 'rgba(0, 0, 0, 0.9)',
      shadowStrong: 'rgba(0, 0, 0)'
    },

    tooltip: {
      background: {
        primary: '#fff',
        secondary: '#628AD0'
      },
      shadow: '#000',
      textColor: '#222533'
    },

    esgReport: {
      chartColor: '#007CF1',
      cardShadow: '#00000003',
      energyWidgetFooter: '#2e2e2e',
      metricMeterBackground: '#121212'
    },

    printStyles: {
      chartColor: '#0099f1',
      text: {
        medium: '#3f4753',
        fair: '#5d6777',
        weak: '#cad0df'
      },
      borders: {
        medium: '#EEF2FC'
      }
    },

    stickyNotes: {
      text: '#222533',
      background: '#fddc57',
      backgroundLight: '#fef196',
      backgroundHeader: '#f0f8ff',
      shadow1: '#000000',
      shadow2: '#000000'
    },

    climateControlHeatmap: {
      green: '#44A901',
      red: '#ff3434',
      orange: '#FF8E0A',
      grey: '#D9D9D9'
    },

    seasonalityTable: {
      itemBackground: '#2a2a2e'
    },

    siteOverview: {
      alerts: {
        urgent: '#E7091B',
        important: '#FF5511',
      },
      utilities: {
        green: '#44A901',
        red: '#E7091B'
      },
      charts: {
        primary: '#00a6ff',
        secondary: '#0b0b0b',
        lines: '#E2E6F5'
      }
    },

    systemMessage: {
      error: '#E7091B',
      warning: '#F51',
      information: '#4967F9',
      success: '#3D9800',
      xmark: '#c4c4c4'
    }
  },

  text: {
    primary: '#dddddd',
    secondary: '#848484',
    contrast: '#000814',
    disabled: '#9699a7',
    light: '#848484',
    white: '#ffffff'
  },

  primary: {
    main: '#276eea',
    dark: '#1b4da3',
    light: '#528bee',
    contrast: '#ffffff',
    hover: '#151515',
    selected: '#151515',
    focus: '#151515',
    outlinedBorder: '#1b4da3',
    background: '#151515'
  },

  action: {
    active: '#848484',
    hover: '#0e0e0e',
    selected: '#0e0e0e',
    disabledBackground: '#151515',
    focus: '#0e0e0e',
    disabled: '#151515',
    outlineBorder: '#0e0e0e',
    divider: '#0e0e0e',
    filled: '#151515'
  },

  complimentary: {
    yellow: {
      main: '#FFB500',
      dark: '#FF9300',
      light: '#FFCD29'
    },
    orange: {
      main: '#FF821B',
      dark: '#E36600',
      light: '#FFC42E'
    },
    blue: {
      main: '#1B98FF',
      dark: '#1A78DD',
      light: '#63B7FF'
    },
    purple: {
      main: '#863EFF',
      dark: '#5D2BB2',
      light: '#9E64FF'
    },
    green: {
      main: '#00C851',
      dark: '#00AD1F',
      light: '#8FDF9C'
    }
  },

  background: {
    container: '#1f1f1f'
  },

  shadow: {
    primary: '#0c0c0c',
    dark: 'rgb(0, 0, 0, 0.85)'
  },

  success: {
    main: '#44a901',
    dark: '#007300',
    light: '#6dce1c',
    contrast: '#ffffff',
    hover: '#f0f9eb',
    selected: '#daeecc',
    focus: '#c3e3ad',
    outlinedBorder: '#a1d37f',
    background: '#e9f5e1'
  },

  info: {
    main: '#4967F9'
  },

  warning: {
    main: '#F51',
    outlinedBorder: 'rgba(255, 85, 17, 0.50)'
  },

  error: {
    main: '#E7091B'
  },

  previewBanner: {
    background: '#1C1C1C',
    disabledButtonText: '#5e5e5e',
    disabledButtonBackground: '#0e0e0e'
  },

  widget: {
    green: '#00C851',
    red: '#EB3A48',
    blue: '#63B7FF'
  },

  skeleton: {
    background: '#292c2f'
  },

  grey: {
    light: '#2A2A2E'
  }
};