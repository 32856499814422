import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSize } from '@shared/hooks/useSize';
import { includesCI } from '@shared/utils/StringUtils';
import { transparentize } from 'polished';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../Button/Button';
import NoData from '../NoData/NoData';
import { SearchField } from '../SearchField/SearchField';
import { ICheckboxSelectGroup } from './CheckboxSelect.types';

interface IDropdown<TValue> {
  groups: ICheckboxSelectGroup<TValue>[];
  onSelect: (groupIndex: number, optionIndex: number) => void;
  selectAll: () => void;
  deselectAll: () => void;
  maxHeight?: number;
  hideSearch?: boolean;
  alignAbove?: boolean;
}

export const CheckboxSelectDropdown = <TValue,>({ groups, onSelect, selectAll, deselectAll, maxHeight, hideSearch, alignAbove }: IDropdown<TValue>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchString, setSearchString] = useState<string>('');
  const checkboxRef = useRef<HTMLDivElement>(null);
  const { width: checkboxWidth } = useSize(checkboxRef);

  const searchFilter = useCallback((x: string) => {
    return includesCI(x, searchString);
  }, [searchString]);

  let filteredGroups = groups.map(g => ({ ...g, options: g.options.filter(x => searchFilter(x.label)) }));
  filteredGroups = filteredGroups.filter(g => g.options.length > 0);
  const hasSelected = groups.flatMap(x => x.options).some(x => x.selected);

  return (
    <FlexColumn alignAbove={alignAbove} ref={checkboxRef}>
      <HeaderRow alignAbove={alignAbove} checkboxWidth={checkboxWidth ?? 0}>
        {!hideSearch &&
          <SearchField
            placeholder={t('Search', { ns: 'common' })}
            onSearchChange={setSearchString}
          />
        }

        <ButtonRow>
          {hasSelected &&
            <Button
              tertiary
              label={t('Clear', { ns: 'common' })}
              onClick={deselectAll}
              color={theme.palette.red}
              style={{ flexShrink: 0, padding: 8, fontSize: 14 }}
            />
          }

          <Button
            tertiary
            label={t('SelectAll', { ns: 'common' })}
            onClick={selectAll}
            style={{ flexShrink: 0, padding: 8, fontSize: 14 }}
          />
        </ButtonRow>
      </HeaderRow>

      <OptionsWrapper maxHeight={maxHeight}>
        {filteredGroups.length === 0 &&
          <NoData
            label={t('NoOptions', { ns: 'status' })}
            styles={{ margin: '20px 0' }}
          />
        }

        {filteredGroups.length > 0 && groups.map((group, i) => {
          if (!group.options.some(x => searchFilter(x.label))) {
            return null;
          }

          return (
            <div key={uuidv4()}>
              {group.label &&
                <GroupLabel>
                  {group.icon &&
                    <GroupIcon>
                      {group.icon}
                    </GroupIcon>
                  }

                  {group.label}
                </GroupLabel>
              }

              {group.options.map((option, j) => searchFilter(option.label) && (
                <Option key={option.label} selected={option.selected} onClick={() => onSelect(i, j)}>
                  <Checkbox selected={option.selected}>
                    {option.selected &&
                      <CheckIcon icon={solid('check')} />
                    }
                  </Checkbox>

                  <FlexRow>
                    <Label>
                      {option.label}
                    </Label>
                  </FlexRow>
                </Option>
              ))}
            </div>
          );
        })}
      </OptionsWrapper>
    </FlexColumn>
  );
}

const FlexColumn = styled.div<{ alignAbove?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${p => p.alignAbove && css`
    flex-direction: column-reverse;
  `}
`;

const OptionsWrapper = styled.div<{ maxHeight?: number }>`
  overflow: hidden auto;
  height: auto;
  max-height: ${p => p.maxHeight ?? 240}px;
  padding: 4px 0;
`;

const HeaderRow = styled.div<{ checkboxWidth: number, alignAbove?: boolean }>`
  display: flex;
  flex-direction: ${p => p.checkboxWidth < 300 ? 'column' : 'row'};
  align-items: center;
  gap: 5px;
  padding: 10px 16px 10px 16px;
  border-bottom: 1px solid ${p => transparentize(0.5, p.theme.action.divider)};
  justify-content: space-between;

  ${p => p.alignAbove && css`
    border-bottom: none;
    border-top: 1px solid ${p => transparentize(0.5, p.theme.action.divider)};
  `}
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

const Label = styled.div`
  width: calc(100% - 15px);
  font-size: 14px;
  line-height: 18px;
  color: ${p => p.theme.palette.text.medium};
  padding-right: 5px;
  cursor: pointer;
`;

const GroupLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  padding: 8px 19px;
`;

const GroupIcon = styled.div`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  text-align: center;
`;

const Option = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  border-left: 3px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.select.hover};
    border-left-color: ${p => p.theme.primary.main};
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 11px;
  color: ${p => p.theme.palette.text.onPrimary};
`;

const Checkbox = styled.div<{ selected?: boolean }>`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border: 1px solid ${p => p.theme.palette.cascader.checkboxBorder};
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  ${p => !p.selected && css`
    &:hover {
      border-color: ${p => transparentize(0.3, p.theme.palette.primary)};
    }
  `}

  transition: border-color 150ms ease;

  ${p => p.selected && css`
  border-color: ${p.theme.palette.primary};
    background-color: ${p.theme.palette.primary};  `}
`;