import { SpaceType, SpaceTypeOptions } from '@shared/api/enums/SpaceType/SpaceType';
import Filter, { FilterOptionGroup } from '@src/components/shared/Filters/Filter/Filter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  spaceTypes: SpaceType[];
  onChange: (selected: SpaceType[]) => void;
}

const SpaceTypeFilter = ({ spaceTypes, onChange }: PropTypes) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<FilterOptionGroup<SpaceType>[]>([]);

  useEffect(() => {
    const spaceTypeOptions: FilterOptionGroup<SpaceType>[] = [];

    SpaceTypeOptions.forEach(group => {
      const filteredOptions = group.options.filter(x => spaceTypes.includes(x.value));

      if (filteredOptions.length > 0) {
        spaceTypeOptions.push({
          ...group,
          options: filteredOptions.map(opt => ({
            ...opt,
            label: t(opt.label, { ns: 'enums' })
          }))
        })
      }
    });

    setOptions(spaceTypeOptions);
  }, [spaceTypes, t]);

  return (
    <Filter
      label={t('SpaceType', { ns: 'common' })}
      options={options}
      onChange={onChange}
      rightAlign
    />
  );
};

export default SpaceTypeFilter;