import Sidebar from './Layout/Sidebar';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { ProtectedRoute } from '@shared/components/navigation/ProtectedRoute/ProtectedRoute';
import styled from 'styled-components';
import { Route as RouteType } from '@shared/types/Route';
import { useEffect } from 'react';

type PropTypes = {
  routes?: RouteType[];
}

const SiteSettingsPage = ({ routes }: PropTypes) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Navigate to first child route
  useEffect(() => {
    const path = routes?.[0].link?.path;
    if (location.pathname.split('/').pop() === 'settings' && path) {
      navigate(path, { replace: true });
    }
  }, [location, routes, navigate]);

  return (
    <FlexRow>
      <Sidebar routes={routes} />

      <Content>
        <Routes>
          {routes?.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ProtectedRoute
                  route={route}
                />
              }
            />
          ))}
        </Routes>
      </Content>
    </FlexRow>
  );
};

export default SiteSettingsPage;

const FlexRow = styled.div`
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;