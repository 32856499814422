import { SiteFairUseConfiguration } from '@shared/api/models/FairUse/SiteFairUseConfiguration';
import { PatchQuery } from '../../common/PatchQuery';

export class SiteFairUseConfigurationUpdateCommand extends PatchQuery<SiteFairUseConfiguration> {
  id: number;
  date?: Date;
  enabled: boolean;

  constructor(id: number, enabled: boolean, date?: Date) {
    super();
    this.id = id;
    this.enabled = enabled;
    this.date = date;
  }

  public targetUrl(): string {
    return '/FairUse/configuration';
  }
}