import DoughnutChart, { DoughnutChartDataset } from '@dashboard/components/atoms/charts/DoughnutChart';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { useModal } from '@shared/hooks/useModal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  intensityForecast?: number;
  dataset?: DoughnutChartDataset;
}

const GenerationMixModal = ({ intensityForecast, dataset }: PropTypes) => {
  const { t } = useTranslation();
  const { ref, isOpen, toggle } = useModal({});

  if (intensityForecast === undefined || !dataset) {
    return null;
  }

  return (
    <>
      <InfoIcon
        icon={solid('up-right-and-down-left-from-center')}
        onClick={toggle}
      />

      <Modal
        ref={ref}
        isOpen={isOpen}
        hide={toggle}
        title={t('EnergyWidgets.CarbonIntensity', { ns: 'molecules' })}
        width='max-content'
      >
        <FlexColumn>
          <Description>
            {t('EnergyWidgets.CarbonIntensityDescription', { ns: 'molecules' })}
          </Description>

          <DoughnutChart
            dataset={dataset}
            size="190px"
            cutout="86%"
            arcBorderWidth={2}
            innerValue={<Value>{intensityForecast}</Value>}
            innerSubLabel={<Label>gCO&#8322;/kWh</Label>}
            innerWrapperStyles={{ gap: 10 }}
            legend={{}}
          />
        </FlexColumn>
      </Modal>
    </>
  );
};

export default GenerationMixModal;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 0 10px;
`;

const Description = styled.span`
  font-size: 14px;
  color: ${p => p.theme.text.primary};
  max-width: 330px;
`;

const Value = styled.span`
  font-size: 28px;
`;

const Label = styled.span`
  font-size: 14px;
`;

const InfoIcon = styled(FontAwesomeIcon) <{ margin?: string }>`
  width: 13px;
  height: 13px;
  margin: ${p => p.margin};
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;
  margin-left: auto;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;
