import SitesGetAllWithHierarchyQuery from '@dashboard/api/queries/site/SitesGetAllWithHierarchyQuery';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeviceCreateCommand from '@settings/api/queries/Devices/DeviceCreateCommand';
import EnergyMetersGetByBuildingIdQuery from '@settings/api/queries/EnergyMeters/EnergyMetersGetByBuildingIdQuery';
import BarcodeScanner from '@settings/components/atoms/BarcodeScanner/BarcodeScanner';
import { DeviceModel } from '@shared/api/enums/DeviceModel/DeviceModel';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { EnergyMeterCategory } from '@shared/api/enums/EnergyMeterCategory/EnergyMeterCategory';
import { EnergyMeterTopologyLevel } from '@shared/api/enums/EnergyMeterTopologyLevel/EnergyMeterTopologyLevel';
import { EnergyMeterTopologyDto } from '@shared/api/models/EnergyMeter/EnergyMeterTopologyDto';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Button } from '@shared/components/atoms/Button/Button';
import { CascaderSingleSelect } from '@shared/components/atoms/CascaderSingleSelect/CascaderSingleSelect';
import { INodeGroup } from '@shared/components/atoms/CascaderSingleSelect/CascaderSingleSelect.types';
import { Checkbox } from '@shared/components/atoms/Checkbox/Checkbox';
import { ErrorMessage, Input, Label } from '@shared/components/atoms/Form/Form';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Select } from '@shared/components/atoms/Select/Select';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { Card } from '@shared/components/molecules/Card/Card';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { IDeviceConfig } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IDeviceConfig';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { createDataObject } from '@shared/utils/ObjectUtils';
import { alphabeticalSort } from '@shared/utils/StringUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { cloneDeep, groupBy, isEmpty, orderBy } from 'lodash';
import { transparentize } from 'polished';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import styled, { useTheme } from 'styled-components';
import DeviceCreatePulseCounter from './DeviceCreate_PulseCounter';
import DeviceCreatePanelHeater from './DeviceCreate_PanelHeater';
import { HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';

export type DeviceModelOption = {
  label: string;
  value: DeviceModel;
  deviceType: DeviceType;
};

type MeterTopologyOption = {
  label: EnergyMeterTopologyLevel;
  value: EnergyMeterTopologyLevel;
};

type MeterCategoryOption = {
  label: EnergyMeterCategory;
  value: EnergyMeterCategory;
};

const DeviceCreate = () => {
  const { site } = useSiteContext();
  const { t } = useTranslation(['settingsDevice']);
  const theme = useTheme();
  const navigate = useNavigate();
  const { allDeviceConfigs, getDeviceConfig, getDisplayString } = useDeviceConfigContext();
  const formMethods = useForm<DeviceCreateCommand>();
  const { register, handleSubmit, setValue, clearErrors, reset, control, formState: { errors }, getValues } = formMethods;
  const [deviceIdentifierPlaceholder, setDeviceIdentifierPlaceholder] = useState<string>('0000000000000000');
  const [deviceIdentifierType, setDeviceIdentifierType] = useState<string>('DevEUID');
  const [barcodeScannerEnabled, setBarcodeScannerEnabled] = useState(false);
  const [spaceSelectOptions, setSpaceSelectOptions] = useState<INodeGroup<HierarchySpace>>();
  const meterCategoryOptions = Object.values(EnergyMeterCategory).map(x => ({ label: x, value: x }));
  const meterTopologyOptions = Object.values(EnergyMeterTopologyLevel).map(x => ({ label: x, value: x }));
  const [meterTopologyLevel, setMeterTopologyLevel] = useState<EnergyMeterTopologyLevel | undefined>();
  const [meterCategory, setMeterCategory] = useState<EnergyMeterCategory | undefined>();
  const [deviceConfig, setDeviceConfig] = useState<IDeviceConfig>();
  const [isBillingMeter, setIsBillingMeter] = useState(false);
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<HierarchySpace>();

  const { execute, loading: savingInProgress } = useApi();
  const { data: siteHierarchy } = useApiState({
    query: new SitesGetAllWithHierarchyQuery(),
    initialState: []
  }, []);


  const deviceModelOptions = useMemo(() => (
    Object.entries(groupBy(allDeviceConfigs, x => x.manufacturer))
      .sort((a, b) => alphabeticalSort(a[0], b[0]))
      .map((group) => ({
        label: group[0],
        options: group[1]
          .sort((a, b) => alphabeticalSort(a?.deviceModel, b?.deviceModel))
          .map(deviceModel => ({
            label: getDisplayString(deviceModel.deviceModel), value: deviceModel?.deviceModel, deviceType: deviceModel?.deviceType
          }))
      }))
  ), [allDeviceConfigs, getDisplayString]);

  register('spaceId', { required: t('DeviceCreate.RequiredField', { ns: 'settingsDevice' }) });

  const onSave: SubmitHandler<DeviceCreateCommand> = async data => {
    data.meterTopologyLevel = meterTopologyLevel;
    data.meterCategory = meterCategory;

    const dataCopy = cloneDeep(data);

    if (deviceConfig?.isPulseCounter) {
      // Prevent saving when there is no PulseAssignment with a MetricType
      if (!data.pulseAssignments?.some(x => x.metricType)) {
        return;
      }

      // Remove PulseAssignments with no selected MetricType
      dataCopy.pulseAssignments = dataCopy.pulseAssignments?.filter(x => x.metricType);
    }

    dataCopy.isBillingMeter = isBillingMeter;

    const result = await execute({
      query: createDataObject(DeviceCreateCommand, dataCopy),
      successMessage: t('DeviceCreate.DeviceCreated', { ns: 'settingsDevice' }),
      errorMessage: t('DeviceCreate.DeviceCreateFailed', { ns: 'settingsDevice' }),
      displayApiError: true,
      toastOptions: { autoClose: 5000 }
    });

    if (result === undefined) {
      return;
    }

    onClear();
    navigate('../devices');
  }

  const onClear = () => {
    setValue('friendlyName', undefined);
    setValue('deviceIdentifier', undefined);
    setValue('pulseAssignments', undefined);
    setMeterTopologyLevel(undefined);
    setMeterCategory(undefined);
    reset();
  };

  useEffect(() => {
    const options: INodeGroup<HierarchySpace> = {
      header: t('DeviceCreate.Sites', { ns: 'settingsDevice' }),
      nodes: orderBy(siteHierarchy, x => x.name.toLocaleLowerCase()).filter(x => x.id === site.id)
        .map((site) => ({
          label: `${site.name}`,
          childGroup: {
            header: t('DeviceCreate.Buildings', { ns: 'settingsDevice' }),
            nodes: orderBy(site.buildings, x => x.name.toLocaleLowerCase()).map((building) => ({
              label: `${building.name}`,
              childGroup: {
                header: t('DeviceCreate.Floors', { ns: 'settingsDevice' }),
                nodes: orderBy(building.floors, x => x.floorNumber).map((floor) => ({
                  label: `${floor.name}`,
                  childGroup: {
                    header: t('DeviceCreate.Spaces', { ns: 'settingsDevice' }),
                    nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map((space) => ({
                      label: `${space.name}`,
                      value: space,
                      selectable: true
                    }))
                  }
                })),
              },
            })),
          },
        })),
    };

    setSpaceSelectOptions(options);
  }, [site.id, siteHierarchy, t])

  const onDeviceModelChange = async (deviceModel: DeviceModel, deviceType: DeviceType) => {
    checkIfBuildingHasBillingMeter(selectedSpace?.id);

    try {
      const deviceConfig = getDeviceConfig(deviceModel);

      setDeviceConfig(deviceConfig);
      setDeviceIdentifierPlaceholder(deviceConfig?.devEUIFormat ?? '0000000000000000');
      setDeviceIdentifierType(deviceConfig?.devEUIType ?? 'DevEUID');

      if (deviceType === DeviceType.Metering && !deviceConfig?.isSmartMeter) {
        setMeterTopologyLevel(EnergyMeterTopologyLevel.Sub);
        setMeterCategory(EnergyMeterCategory.Resident);
      } else {
        setMeterTopologyLevel(undefined);
        setMeterCategory(undefined);
      }

      if (!deviceConfig?.isPulseCounter) {
        setValue('pulseAssignments', undefined);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onTopologyChange = async (topology: EnergyMeterTopologyLevel) => {
    checkIfBuildingHasBillingMeter(selectedSpace?.id);

    if (topology === EnergyMeterTopologyLevel.Sub) {
      setMeterCategory(EnergyMeterCategory.Resident);
    }
    else {
      setMeterCategory(undefined);
    }
  }

  const getBuildingIdFromSpaceId = (spaceId: number | undefined): number | undefined => {
    if (spaceId !== undefined) {
      let buildingId = 0;

      siteHierarchy.forEach((hierarchy) => {
        hierarchy.buildings.forEach((building) => {
          if (building.floors.some(x => x.spaces.some(y => y.id === spaceId))) {
            buildingId = building.id
          }
        })
      })
      return buildingId;
    }
    return undefined;
  };

  const getEnergyMetersInBuilding = async (buildingId: number): Promise<EnergyMeterTopologyDto[] | undefined> => {
    const energyMeters = await execute({
      query: new EnergyMetersGetByBuildingIdQuery(buildingId)
    });
    return energyMeters;
  }

  const checkIfBuildingHasBillingMeter = async (spaceId: number | undefined): Promise<void> => {
    const buildingId = getBuildingIdFromSpaceId(spaceId);
    let meters: EnergyMeterTopologyDto[] = [];

    if (buildingId !== undefined) {
      const result = await getEnergyMetersInBuilding(buildingId);

      if (result !== undefined) {
        meters = result;
      }

      const mainMeters = meters?.filter(x => x.meterTopologyLevel == EnergyMeterTopologyLevel.Main);

      if (deviceConfig?.energyMeterTypes !== undefined && deviceConfig?.energyMeterTypes !== null) {
        const firstEnergyMeterType = deviceConfig.energyMeterTypes[0];
        const filteredMeters = mainMeters.filter(x => x.meterType.toString() === firstEnergyMeterType);

        if (filteredMeters.some(x => x.isBillingMeter === true)) {
          setIsCheckBoxDisabled(true)
          setIsBillingMeter(false)
        } else {
          setIsCheckBoxDisabled(false)
        }
      }
    }
  }

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('BackToDevices', { ns: 'navigation' })}
          url='./..'
        />

        <FormProvider {...formMethods}>
          <Card cardTitle={t('DeviceCreate.AddDevice', { ns: 'settingsDevice' })}>
            <FormContainer>
              <FlexRow>
                <Column>
                  <Label>{t('DeviceCreate.DeviceModel', { ns: 'settingsDevice' })}</Label>
                  <Controller
                    control={control}
                    name="deviceModel"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        value={deviceModelOptions.flatMap(x => x.options.find(y => y.value === value))}
                        isSearchable={true}
                        onChange={(selected: SingleValue<DeviceModelOption | undefined>) => {
                          if (selected) {
                            onDeviceModelChange(selected.value, selected.deviceType);
                            onChange(selected.value);
                          }
                        }}
                        options={deviceModelOptions}
                        innerRef={ref}
                        menuHeight={250}
                      />
                    )}
                  />
                  <ErrorMessage>{errors.deviceModel?.message}</ErrorMessage>
                </Column>
              </FlexRow>

              {deviceConfig?.isUtopiSmartPanelHeater &&
                <DeviceCreatePanelHeater
                  deviceConfig={deviceConfig}
                  selectedSpace={selectedSpace}
                  setValue={setValue}
                />
              }

              <FlexRow>
                <Column>
                  <FlexRow style={{ justifyContent: 'space-between' }}>
                    <Label>{t('DeviceCreate.DeviceIdentifier', { ns: 'settingsDevice' })} ({deviceIdentifierType})</Label>
                    <IconWrapper onClick={() => setBarcodeScannerEnabled(!barcodeScannerEnabled)}>
                      <Icon icon={regular('qrcode')} />
                    </IconWrapper>
                  </FlexRow>
                  <Input
                    placeholder={deviceIdentifierPlaceholder}
                    {...register('deviceIdentifier', { required: t('DeviceCreate.RequiredField', { ns: 'settingsDevice' }) })} />
                  <ErrorMessage>{errors.deviceIdentifier?.message}</ErrorMessage>
                  <BarcodeScanner
                    enabled={barcodeScannerEnabled}
                    onUpdate={(value) => {
                      setValue('deviceIdentifier', value);
                      setBarcodeScannerEnabled(false);
                    }}
                  />
                </Column>
              </FlexRow>
              <FlexRow>
                <Column style={{ minWidth: '180px' }}>
                  <Label>{t('DeviceCreate.DeviceLocation', { ns: 'settingsDevice' })}</Label>
                  {spaceSelectOptions &&
                    <CascaderSingleSelect
                      group={spaceSelectOptions}
                      placeholder={t('DeviceCreate.DeviceLocationPlaceholder', { ns: 'settingsDevice' })}
                      onChange={space => {
                        setValue('spaceId', space?.id);
                        if (errors.spaceId?.message) {
                          clearErrors('spaceId');
                        }
                        setSelectedSpace(space)
                        checkIfBuildingHasBillingMeter(space?.id);
                      }}
                      confirmButton={t('Apply', { ns: 'common' })}
                      cancelButton={t('Cancel', { ns: 'common' })}
                    />
                  }
                  <ErrorMessage>{errors.spaceId?.message}</ErrorMessage>
                </Column>
              </FlexRow>
              <FlexRow>
                <Column>
                  <Label>{t('DeviceCreate.FriendlyName', { ns: 'settingsDevice' })}</Label>
                  <Input {...register('friendlyName', { required: t('DeviceCreate.RequiredField', { ns: 'settingsDevice' }) })} />
                  <ErrorMessage>{errors.friendlyName?.message}</ErrorMessage>
                </Column>
              </FlexRow>
            </FormContainer>
          </Card>

          {deviceConfig &&
            <DeviceCreatePulseCounter
              deviceConfig={deviceConfig}
              register={register}
              control={control}
              errors={errors}
              getValues={getValues}
            />
          }

          {
            meterTopologyLevel !== undefined &&
            <Card
              cardTitle={t('DeviceCreate.AddEnergyMeter', { ns: 'settingsDevice' })}
              centered
              maxWidth='400px'
            >
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('DeviceCreate.TopologyLevel', { ns: 'settingsDevice' })}</Label>
                  <Select
                    options={meterTopologyOptions}
                    value={meterTopologyOptions.filter(({ value }) => value === meterTopologyLevel)}
                    onChange={(selected: SingleValue<MeterTopologyOption | undefined>) => {
                      if (selected) {
                        setMeterTopologyLevel(selected.value)
                        onTopologyChange(selected.value);
                      }
                    }}
                  />
                  <ErrorMessage>{errors.meterTopologyLevel?.message}</ErrorMessage>
                </div>

                {meterTopologyLevel === EnergyMeterTopologyLevel.Main &&
                  <div className="col-md-6">
                    <Label>{t('DeviceCreate.BillingMeter', { ns: 'settingsDevice' })}</Label>
                    <CheckboxWrapper>
                      <Tooltip
                        content={isCheckBoxDisabled ? `${deviceConfig?.energyMeterTypes}` + t('DeviceCreate.BillingMeterAlreadyPresent', { ns: 'settingsDevice' }) : ''}
                      >
                        <Checkbox
                          checked={isBillingMeter}
                          onChange={setIsBillingMeter}
                          disabled={isCheckBoxDisabled}
                        />
                      </Tooltip>
                    </CheckboxWrapper>
                    <ErrorMessage>{errors.meterCategory?.message}</ErrorMessage>
                  </div>
                }

                {meterTopologyLevel === EnergyMeterTopologyLevel.Sub &&
                  <div className="col-md-6">
                    <Label>{t('DeviceCreate.MeterCategory', { ns: 'settingsDevice' })}</Label>
                    <Select
                      options={meterCategoryOptions}
                      defaultValue={meterCategoryOptions.filter(({ value }) => value === EnergyMeterCategory.Resident)}
                      onChange={(selected: SingleValue<MeterCategoryOption | undefined>) => {
                        if (selected) {
                          setMeterCategory(selected.value)
                        }
                      }}
                    />
                    <ErrorMessage>{errors.meterCategory?.message}</ErrorMessage>
                  </div>
                }
              </div>
            </Card>
          }

          <Card>
            <FlexRow style={{ justifyContent: 'space-between' }}>
              <Button
                label={t('DeviceCreate.AddDevice', { ns: 'settingsDevice' })}
                onClick={handleSubmit(onSave)}
                disabled={!isEmpty(errors)}
                loading={savingInProgress}
              />
              <Button
                tertiary
                label={t('DeviceCreate.ClearFields', { ns: 'settingsDevice' })}
                onClick={onClear}
                color={theme.palette.red}
              />
            </FlexRow>
          </Card>
        </FormProvider>
      </Container>
    </PaddedContainer>
  )
}

export default DeviceCreate;

const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  width: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 15px;
  color: ${p => p.theme.palette.text.weak};
`;

const IconWrapper = styled.div`
  width: 23px;
  height: 23px;
  margin-top: -5px;
  border: 2px dashed ${p => transparentize(0.15, p.theme.palette.text.weak)};
  border-radius: 2px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
`;