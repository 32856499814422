import { DeviceNoteSource } from '@dashboard/api/enums/DeviceNoteSource';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoData from '@shared/components/atoms/NoData/NoData';
import { Paging } from '@shared/components/molecules/Table/Paging';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useSpaceContext } from '@src/components/pages/SpacePage/SpaceProvider';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { shouldUseModifedDate } from '../../DevicePage/DevicePage_Notes';

const SpacePage_Notes = () => {
  const { t } = useTranslation();
  const { localisation } = useLocalisation();
  const { deviceNotes } = useSpaceContext();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);

  const pageSizeOptions = useMemo(() => [
    { label: 'Show 5', value: 5 },
    { label: 'Show 10', value: 10 },
    { label: 'Show 20', value: 20 },
    { label: 'Show All', value: deviceNotes?.length ?? 5 }
  ], [deviceNotes]);

  /**
   * Sort by the modifiedOn date if that date is newer than "0001-01-01 00:00:00.000000" which is the default value
   * in the database if it has never been updated, otherwise sort by the createdOn date.
   */
  const sortedNotes = useMemo(() => orderBy(deviceNotes, x => shouldUseModifedDate(x) ? x.modifiedOn : x.createdOn, 'desc'), [deviceNotes]);

  const pagedNotes = useMemo(() => {
    const startIndex = page * pageSize;
    return sortedNotes.slice(startIndex, startIndex + pageSize);
  }, [sortedNotes, page, pageSize]);

  if (deviceNotes.length === 0) {
    return (
      <NoData
        label={t('NoDeviceNotesFound', { ns: 'status' })}
        icon={regular('note-sticky')}
        styles={{ margin: '80px auto' }}
      />
    )
  }

  return (
    <>
      <List>
        {pagedNotes.map(note => (
          <ProtectedLink link={{ path: `device/${note.deviceId}/notes` }} key={note.id}>
            <Item>
              <div>
                <Name>{note.createdByName ?? t('Unknown', { ns: 'common' })}</Name>
                {note.source && note.source !== DeviceNoteSource.Unknown &&
                  <Source>&nbsp;&nbsp;{t('via', { ns: 'common' })} {t(`DeviceNoteSource.${note.source}`, { ns: 'enums' })}</Source>
                }
              </div>

              <Text>
                {note.contents}
              </Text>

              <SmallText>
                <Icon icon={solid('sensor-on')} />
                {t(`${note.deviceModel}.DisplayString`, { ns: 'deviceConfigs' })}, {note.deviceFriendlyName}, {note.deviceIdentifier}
              </SmallText>

              <SmallText>
                {dayjs(new Date(shouldUseModifedDate(note) ? note.modifiedOn : note.createdOn)).format(localisation.dateFormats.dateAndTime)}
              </SmallText>
            </Item>
          </ProtectedLink>
        ))}
      </List>

      <PagingWrapper>
        <Paging
          numItems={deviceNotes.length}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          pageSizeOptions={pageSizeOptions}
        />
      </PagingWrapper>
    </>
  );
};

export default SpacePage_Notes;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Item = styled.div`
  font-size: 14px;
  padding: 16px;
  border: 1px solid ${p => p.theme.action.divider};
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 ${p => p.theme.shadow.primary};
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  &:hover {
    border-color: ${p => p.theme.primary.outlinedBorder};
  }
`;

const Name = styled.span`
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;

const Source = styled.span`
  font-size: 12px;
  font-style: italic;
  color: ${p => p.theme.text.light};
`;

const Text = styled.div`
  color: ${p => p.theme.text.primary};
`;

const SmallText = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const PagingWrapper = styled.div`
  margin: 20px 0 0 auto;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.primary.light};
  margin-right: 5px;
`;