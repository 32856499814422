import { DeviceNoteDetails } from '@dashboard/api/models/DeviceNoteDetails';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class DeviceNotesGetNotesBySpaceId extends GetQuery<DeviceNoteDetails[]> {
  spaceId: number;

  constructor(spaceId: number) {
    super();
    this.spaceId = spaceId;
  }

  public targetUrl(): string {
    return `/devicenotes/GetBySpaceId/${this.spaceId}`;
  }
}
