import styled, { css, useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Title } from '@shared/components/atoms/Title/Title';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { SpaceType_Icon } from '@shared/api/enums/SpaceType/SpaceType_Icon';
import { Icon } from '@shared/components/atoms/Icon/Icon';
import { SpaceType_Color } from '@shared/api/enums/SpaceType/SpaceType_Color';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { HeatmapSpaceDto } from '@dashboard/api/models/HeatmapSpaceDto';
import { useLayoutContext } from '@src/contexts/LayoutContext';

type PropTypes = {
  spaces?: HeatmapSpaceDto[]
}

const FloorplanSidepanel = ({ spaces }: PropTypes) => {
  const { t } = useTranslation(['molecules']);
  const { isFullScreen } = useLayoutContext();
  const theme = useTheme();

  return (
    <SidePanel isFullScreen={isFullScreen}>
      <SpaceList>
        <Title
          text={t('Floorplan.Spaces', { ns: 'molecules' })}
          size='xs'
          style={{ color: theme.palette.text.weak, fontSize: '16px' }}
        />
        {spaces?.map((space) => (
          <Link to={`../space/${space.spaceId}`} key={`${space.floorId}-${space.spaceId}`}>
            <SpaceListItem>
              <Tooltip
                content={SpaceType_DisplayString(space.spaceType)}
                placement={TooltipPlacement.TopLeft}
              >
                <Icon
                  icon={SpaceType_Icon(space.spaceType)}
                  iconSize='20px'
                  squareSize='32px'
                  squareColor={SpaceType_Color(space.spaceType)}
                  iconColor='#fff'
                  shadowBottom
                />
              </Tooltip>
              <Title
                text={space.spaceName}
                size='sm'
                style={{ color: theme.palette.text.weak, fontWeight: 400, fontSize: '16px' }}
              />
            </SpaceListItem>
          </Link>
        )
        )}
      </SpaceList>
    </SidePanel>
  );
};

export default FloorplanSidepanel;

const SidePanel = styled.div<{ isFullScreen: boolean }>`
  min-width: 300px;
  max-width: 300px;
  height: ${`calc(100vh - ${TopBarHeight}px - ${HorizontalNavbarHeight}px)`};
  overflow: auto;
  
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 10px 8px 0 ${p => p.theme.palette.shadows.medium};

  ${p => p.isFullScreen && css`
    display: none;
  `}
`;

const SpaceList = styled.div`
  margin: 38px 28px;
  white-space: nowrap;

  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SpaceListItem = styled.div`
  width: 100%;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 10px;
`;