import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class DevicesWithLatestMetricsGetBySpaceIdQuery extends GetQuery<DeviceWithMetrics[]> {
  spaceId: number;

  constructor(spaceId: number) {
    super();
    this.spaceId = spaceId;
  }

  public targetUrl(): string {
    return `/devices/DevicesWithLatestMetricsGetBySpaceId/${this.spaceId}`;
  }
}
