import { useSize } from '@shared/hooks/useSize';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { calculateOverflowingLabelCount, createNodeTree, getSelectedLabelsAndValues } from '../CascaderMultiSelect/CascaderUtils';
import { ICascaderDropdownSelectNodeGroup, IInternalNodeGroup } from './CascaderDropdownSelect.types';

interface IuseCascaderMultiSelect<TValue> {
  group: ICascaderDropdownSelectNodeGroup<TValue>;
  onChange: (values?: TValue[]) => void;
  includeChildValues?: boolean;
  maxFieldHeight?: number;
  resetPulse?: boolean;
}

const useCascaderDropdownSelect = <TValue,>({ group, onChange, includeChildValues, resetPulse }: IuseCascaderMultiSelect<TValue>) => {
  const [appliedNodeGroup, setAppliedNodeGroup] = useState<IInternalNodeGroup<TValue> | undefined>();
  const [transientNodeGroup, setTransientNodeGroup] = useState<IInternalNodeGroup<TValue> | undefined>();
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const labelContainerRef = useRef<HTMLDivElement>(null);
  const { width: labelContainerWidth } = useSize(labelContainerRef);
  const [numHiddenItems, setNumHiddenItems] = useState<number>(0);

  /**
   * Set applied and transient nodeGroups on first render or when the "group" prop changes
   */
  const initializeNodeGroups = useCallback((group: ICascaderDropdownSelectNodeGroup<TValue>, includeChildValues?: boolean) => {
    const nodeTree = createNodeTree('', cloneDeep(group));
    setAppliedNodeGroup(nodeTree);
    setTransientNodeGroup(cloneDeep(nodeTree));
    const { labels } = getSelectedLabelsAndValues(nodeTree, false, includeChildValues);
    setSelectedLabels(labels);
  }, []);


  useEffect(() => {
    initializeNodeGroups(group, includeChildValues);
  }, [group, includeChildValues, initializeNodeGroups, resetPulse]);

  /**
   * Set transient nodeGroups when the appliedNodeGroup changes
   */
  useEffect(() => {
    setTransientNodeGroup(cloneDeep(appliedNodeGroup));
  }, [appliedNodeGroup]);

  // Determine how many items are not visible in the select field
  useEffect(() => {
    setNumHiddenItems(calculateOverflowingLabelCount(labelContainerRef, labelContainerWidth, selectedLabels))
  }, [labelContainerRef, labelContainerWidth, selectedLabels]);

  const handleSelect = (modifiedNodeGroup: IInternalNodeGroup<TValue>) => {
    // Make transientNodeGroup the appliedNodeGroup
    setAppliedNodeGroup(modifiedNodeGroup);

    // Recursively look for all selected nodes, set labels for display in select field, and pass selected values to parent component.
    const { labels, values } = getSelectedLabelsAndValues(modifiedNodeGroup, false, includeChildValues);
    setSelectedLabels(labels);
    onChange(labels.length > 0 ? values : undefined);
  };

  const clearAll = () => {
    initializeNodeGroups(group, includeChildValues);
  };

  return {
    transientNodeGroup,
    selectedLabels,
    labelContainerRef,
    numHiddenItems,
    handleSelect,
    clearAll
  };
};

export default useCascaderDropdownSelect;