import { User } from '@shared/api/models/User/User';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class ExternalUserSitesUpdateCommand extends PostQuery<User> {
  userId: number;
  sitesToRemove: number[];
  sitesToAdd: number[];

  constructor(userId: number, sitesToAdd: number[], sitesToRemove: number[]) {
    super();
    this.userId = userId;
    this.sitesToAdd = sitesToAdd;
    this.sitesToRemove = sitesToRemove;
  }

  public targetUrl(): string {
    return '/userSites/updateExternal';
  }
}
