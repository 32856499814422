import { DeviceDetails } from '@shared/api/models/Device/DeviceDetails';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class DevicesGetByBuildingIdQuery extends GetQuery<DeviceDetails[]> {
  buildingId: number;

  constructor(buildingId: number) {
    super();
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return `/devices/DevicesGetByBuildingId/${this.buildingId}`;
  }
}
