import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CsvObject, UnitScheduleForm, regexAlphaNumeric, regexIntOnly } from './CsvUtils';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { Input } from '@shared/components/atoms/Form/Form';
import { alphabeticalSort } from '@shared/utils/StringUtils';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface EditableTableProps {
  data: CsvObject[];
}

const EditableTable = ({ data }: EditableTableProps) => {
  const { t } = useTranslation();
  const { register, control, watch, reset, formState: { errors } } = useFormContext<UnitScheduleForm>();
  const { fields } = useFieldArray({
    control,
    name: 'data'
  });

  const occupiedOptions = ['TRUE', 'FALSE'];
  const spaceTypeOptionsInCluster = [SpaceType.Bedroom, SpaceType.LKD];
  const spaceTypeOptionsAll = Object.values(SpaceType).sort((a, b) => alphabeticalSort(a, b)).filter(item => item !== 'Bedroom' && item !== 'LKD');

  useEffect(() => {
    reset({ data: data });
  }, [data, reset]);

  return (
    <>
      {fields.length < 1 && 
        <MainErrorWrapper>
          {t('Buildings.Spaces.SpaceManagement.CsvPreview.CsvError', { ns: 'settingsAsset' })}
        </MainErrorWrapper>
      }

      <Parent>
        {fields && fields.length > 0 &&
          <Child style={{ display: 'flex' }}>
            <IndexWrapper></IndexWrapper>        
            <TableHeader>
              {t('Buildings.Spaces.SpaceManagement.CsvPreview.BuildingName', { ns: 'settingsAsset' })}
            </TableHeader>
            <TableHeader>
              {t('Buildings.Spaces.SpaceManagement.CsvPreview.FloorNumber', { ns: 'settingsAsset' })}
            </TableHeader>
            <TableHeader>
              {t('Buildings.Spaces.SpaceManagement.CsvPreview.UnitName', { ns: 'settingsAsset' })}
            </TableHeader>
            <TableHeader>
              {t('Buildings.Spaces.SpaceManagement.CsvPreview.UnitType', { ns: 'settingsAsset' })}
            </TableHeader>
            <TableHeader>
              {t('Buildings.Spaces.SpaceManagement.CsvPreview.ClusterName', { ns: 'settingsAsset' })}
            </TableHeader>
            <TableHeader>
              {t('Buildings.Spaces.SpaceManagement.CsvPreview.Area', { ns: 'settingsAsset' })}
            </TableHeader>
            <TableHeader>
              {t('Buildings.Spaces.SpaceManagement.CsvPreview.Occupied', { ns: 'settingsAsset' })}
            </TableHeader>
          </Child>
        }
      </Parent>

      <Parent style={{ height: '500px', minHeight: '300px' }}>
        {fields.map((item, index) =>
          <Child key={item.id}>
            <IndexWrapper>
              {index + 2}
            </IndexWrapper>
            
            <InputWrapper>
              <CustomInput error={errors?.data?.[index]?.buildingName !== undefined}
                {...register(`data.${index}.buildingName`, {
                  required: {
                    value: true,
                    message: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' })
                  },
                  pattern: {
                    value: regexAlphaNumeric,
                    message: t('Buildings.Spaces.SpaceManagement.CsvPreview.NoSpecialCharacters', { ns: 'settingsAsset' })
                  }
                }
                )}
                type='string'
              />
              <ErrorMessageWrapper>{errors?.data?.[index]?.buildingName?.message}</ErrorMessageWrapper>
            </InputWrapper>
            
            <InputWrapper>  
              <CustomInput error={errors?.data?.[index]?.floorNumber !== undefined}
                {...register(`data.${index}.floorNumber`, {
                  required: {
                    value: true,
                    message: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' })
                  },
                  pattern: {
                    value: regexIntOnly,
                    message: t('Buildings.Spaces.SpaceManagement.CsvPreview.IntegersOnly', { ns: 'settingsAsset' })
                  }
                })}
                type='number'
              />
              <ErrorMessageWrapper>{errors?.data?.[index]?.floorNumber?.message}</ErrorMessageWrapper>
            </InputWrapper>

            <InputWrapper>  
              <CustomInput error={errors?.data?.[index]?.unitName !== undefined}
                {...register(`data.${index}.unitName`, {
                  required: {
                    value: true,
                    message: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' })
                  },
                  pattern: {
                    value: regexAlphaNumeric,
                    message: t('Buildings.Spaces.SpaceManagement.CsvPreview.NoSpecialCharacters', { ns: 'settingsAsset' })
                  }
                })}
                type='string'
              />
              <ErrorMessageWrapper>{errors?.data?.[index]?.unitName?.message}</ErrorMessageWrapper>
            </InputWrapper>

            <InputWrapper>  
              <CustomSelect error={errors?.data?.[index]?.unitType !== undefined}
                {...register(`data.${index}.unitType`, {
                  required: {
                    value: true,
                    message: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' })
                  }
                })}> 
                {watch(`data.${index}.clusterName`) === '-' || watch(`data.${index}.clusterName`) === '' ?
                  spaceTypeOptionsAll.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  )) :
                  spaceTypeOptionsInCluster.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))
                }
              </CustomSelect>
            </InputWrapper>

            <InputWrapper>  
              <CustomInput error={errors?.data?.[index]?.clusterName !== undefined}
                {...register(`data.${index}.clusterName`, {
                  pattern: {
                    value: regexAlphaNumeric,
                    message: t('Buildings.Spaces.SpaceManagement.CsvPreview.NoSpecialCharacters', { ns: 'settingsAsset' })
                  }
                })}
                type='string'
              />
              <ErrorMessageWrapper>{errors?.data?.[index]?.clusterName?.message}</ErrorMessageWrapper>
            </InputWrapper>
              
            <InputWrapper>  
              <CustomInput error={errors?.data?.[index]?.area !== undefined}
                {...register(`data.${index}.area`, {
                  required: {
                    value: true,
                    message: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' })
                  },
                  min: {
                    value: 1,
                    message: t('Buildings.Spaces.SpaceManagement.CsvPreview.MinValue', { ns: 'settingsAsset' })
                  },
                  max: {
                    value: 100000,
                    message: t('Buildings.Spaces.SpaceManagement.CsvPreview.MaxValue', { ns: 'settingsAsset' })
                  }                  
                })}
                type='number'
              />
              <ErrorMessageWrapper>{errors?.data?.[index]?.area?.message}</ErrorMessageWrapper>
            </InputWrapper>
                
            <InputWrapper>     
              <CustomSelect error={errors?.data?.[index]?.occupied !== undefined}
                {...register(`data.${index}.occupied`, {
                  required: {
                    value: true,
                    message: t('Buildings.BuildingCreate.FieldRequired', { ns: 'settingsAsset' })
                  }
                })}>
                {occupiedOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </CustomSelect>
            </InputWrapper>
          </Child>
        )}
      </Parent>
    </>
  );
};

const Parent = styled.div`
  height: '100%';
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
`;

const Child = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

const CustomSelect = styled.select <{ error: boolean }>`
    display: flex;
    align-items: center;
    padding: 5px 15px;
    margin: 8px 0px;
    flex-basis: 0px;
    flex-grow: 1;
    width: 160px;
    font-family: inherit;
    font-weight: 100;
    font-size: 14px;
    color: ${p => p.theme.palette.text.medium};
    box-sizing: border-box;
    border: none;
    border-radius: 5px;

    ${p => p.error && css`
      border: 1px solid ${p => p.theme.palette.red};
    `}

    &:hover {
      box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
      background-color: ${p => p.theme.palette.backgrounds.surface};
    }
`;

const CustomInput = styled(Input) <{ error: boolean }>`
    color: inherit;
    font-size: 14px;
    font-weight: 100;
    font-family: inherit;
    text-align: left;  
    height: 28px;
    margin: 8px 0px 8px 0px;
    background-color: transparent;  
    
    display: flex;
    align-items: center;
    padding: 15px 15px;
    flex-basis: 0px;
    flex-grow: 1;
    width: 160px;
    border: none;
    box-shadow: none;
    
    ${p => p.error && css`
      border: 1px solid ${p => p.theme.palette.red};
    `}

    &:hover {
      box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
      background-color: ${p => p.theme.palette.backgrounds.surface};
    }
  
    &:focus {
      outline: none;
      border-color: ${p => ((p.value === '') ? `1px solid ${p.theme.palette.red}` : p.theme.palette.forms.input.border)};
      box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
    }
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 15px 0px 15px;
  flex-basis: 0px;
  flex-grow: 1;
  max-width: 160px;
  font-weight: 500;    
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const IndexWrapper = styled.div`
  padding: 8px;
  height: 50px;
  min-width: 40px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  align-items: center;
`;

const ErrorMessageWrapper = styled.div`
  text-align: left;
  font-size: 10px;
  color: ${p => p.theme.palette.red};
`;

const MainErrorWrapper = styled.div`
  text-align: left;
  font-size: 14px;
  color: ${p => p.theme.palette.red};
`;



export default EditableTable;