import { SiteFairUsePolicy } from '@shared/api/models/FairUse/SiteFairUsePolicy'
import { useTranslation } from 'react-i18next'
import { Button } from '@shared/components/atoms/Button/Button'
import { useApi } from '@shared/hooks/useApi'
import styled, { useTheme } from 'styled-components'
import Seasonality from './Seasonality/Seasonality'
import { FormProvider, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FairUseState } from '@shared/api/enums/FairUse/FairUseState'
import { RouterPrompt } from '@shared/components/navigation/RouterPrompt/RouterPrompt'
import { valuesDiffer } from '@shared/utils/ObjectUtils'
import { SiteFairUsePolicyUpdateCommand } from '@shared/api/queries/FairUse/SitePolicy/SiteFairUsePolicyUpdateCommand'
import { getDefaultValues } from '@dashboard/components/molecules/FairUse/FairUseUtils'
import { useEffect, useState } from 'react'
import { FairUseForm } from '@shared/api/models/FairUse/FairUseForm'
import AnnualAllowanceForm from './AnnualAllowanceForm/AnnualAllowanceForm'
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType'
import { SiteFairUseEnergyConsumption } from '@shared/api/models/FairUse/SiteFairUseEnergyConsumption'
import AnnualAllowanceUtility from './AnnualAllowanceForm/AnnualAllowanceUtility'
import Exceptions from './ExceptionsTable/ExceptionsTable'
import { SpaceFairUsePolicy } from '@shared/api/models/FairUse/SpaceFairUsePolicy'
import { SpaceFairUsePolicyBulkCreateCommand } from '@shared/api/queries/FairUse/Exceptions/SpaceFairUsePolicyBulkCreateCommand'
import { SpaceFairUsePolicyBulkDeleteCommand } from '@shared/api/queries/FairUse/Exceptions/SpaceFairUsePolicyBulkDeleteCommand'
import { SpaceFairUsePolicyBulkUpdateCommand } from '@shared/api/queries/FairUse/Exceptions/SpaceFairUsePolicyBulkUpdateCommand'
import _ from 'lodash'
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext'
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink'

type AnnualAllowanceProps = {
  siteFairUsePolicy: SiteFairUsePolicy,
  editMode: boolean;
  energyMeterType: EnergyMeterType;
  energyConsumption?: SiteFairUseEnergyConsumption;
  exceptions?: SpaceFairUsePolicy[];
  handleEditModeChange: (editMode: boolean) => void;
  refresh: () => void;
}

const AnnualAllowance = ({ siteFairUsePolicy, editMode, energyMeterType, energyConsumption, exceptions, handleEditModeChange, refresh }: AnnualAllowanceProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const theme = useTheme();
  const formMethods = useForm<FairUseForm>();
  const { setFocus, reset, getValues, setValue, formState: { defaultValues, errors } } = formMethods;
  const { trackAction } = useAnalytics();
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

  useEffect(() => {
    reset(getDefaultValues(siteFairUsePolicy, editMode, energyMeterType, exceptions));
  }, [reset, siteFairUsePolicy, editMode, energyMeterType, exceptions]);

  const onSave = async (updatedSiteFairUsePolicy: FairUseForm): Promise<void> => {
    setSavingInProgress(true);
    await execute({
      query: new SiteFairUsePolicyUpdateCommand(updatedSiteFairUsePolicy.id, FairUseState.Active, updatedSiteFairUsePolicy.spaceTypePolicies, updatedSiteFairUsePolicy.seasonalityAdjustments),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' }),
      pendingMessage: t('ChangesSavePending', { ns: 'status' }),
    });
    await saveExceptions();
    trackAction('save_edit', 'fair_use');
    refresh();
  };

  const saveExceptions = async (): Promise<void> => {
    const exceptions = getValues('exceptions')
    const defaultExceptions = defaultValues?.exceptions;

    if (exceptions && exceptions?.length > 0) {
      if (defaultExceptions && defaultExceptions?.length > 0 && exceptions?.some(exception => exception.id === 0)) {
        exceptions.forEach((exception) => {
          const matchingDefaultException = defaultExceptions?.find(defaultExceptions => _.isEqual(defaultExceptions?.spaceIds, exception.spaceIds));
          if (matchingDefaultException && matchingDefaultException.id) {
            exception.id = matchingDefaultException.id;
          }
        });
      }

      const newExceptions = exceptions?.filter((exception: SpaceFairUsePolicy) =>
        defaultExceptions ? !defaultExceptions?.find(defaultException => defaultException?.id === exception.id) : true);

      const updatedExceptions = exceptions?.filter((exception: SpaceFairUsePolicy) => {
        const defaultException = defaultExceptions ? defaultExceptions?.find(defaultException => defaultException?.id === exception.id) : null;
        return defaultException ? exception.allowance !== defaultException?.allowance || exception.exempt !== defaultException?.exempt : false;
      });

      if (newExceptions.length > 0 || defaultExceptions?.some(defaultException => newExceptions.some(element => defaultException?.id === element.id))) {
        await createExceptions(siteFairUsePolicy.id, newExceptions);
      }

      if (updatedExceptions.length > 0) {
        await updateExceptions(updatedExceptions);
      }
    }

    const deletedExceptions = defaultExceptions ? defaultExceptions?.filter(defaultException =>
      !exceptions?.find(exceptions => exceptions.id === defaultException?.id)) : [];

    if (deletedExceptions.length > 0 && defaultExceptions?.some(defaultException => deletedExceptions.some(element => defaultException?.id === element?.id))) {
      await deleteExceptions(deletedExceptions as SpaceFairUsePolicy[]);
    }
  }

  const createExceptions = async (siteId: number, exceptions: SpaceFairUsePolicy[]) => {
    await execute({
      query: new SpaceFairUsePolicyBulkCreateCommand(siteId, exceptions),
      errorMessage: t('ExceptionAddError', { ns: 'status' }),
    })
  }

  const updateExceptions = async (exceptions: SpaceFairUsePolicy[]) => {
    await execute({
      query: new SpaceFairUsePolicyBulkUpdateCommand(exceptions),
      errorMessage: t('ChangesSaveError', { ns: 'status' }),
    })
  }

  const deleteExceptions = async (exceptions: SpaceFairUsePolicy[]) => {
    const exceptionIds = exceptions.map(spaceFairUsePolicy => spaceFairUsePolicy.id);
    await execute({
      query: new SpaceFairUsePolicyBulkDeleteCommand(siteFairUsePolicy.id, exceptionIds),
      errorMessage: t('ExceptionAddError', { ns: 'status' }),
    })
  }

  const checkForChanges = (): boolean => {
    return formMethods.formState.defaultValues ? valuesDiffer(formMethods.formState.defaultValues, getValues()) : false;
  }

  const getValidationErrors = (): JSX.Element => {
    return (
      <>
        {errors?.spaceTypePolicies?.map &&
          errors.spaceTypePolicies.map((x, i) => {
            return (
              <ErrorElementWrapper key={`spaceTypePoliciesError-${x}`}>
                <FontAwesomeIcon color={theme.palette.red} icon={faTriangleExclamation} />
                <ErrorElementItem onClick={() => setFocus(`spaceTypePolicies.${i}.allowance`)}>
                  {x?.allowance?.message}
                </ErrorElementItem>
              </ErrorElementWrapper>
            )
          })
        }
        {errors?.exceptions?.map &&
          errors.exceptions.map((x, i) => {
            return (
              <ErrorElementWrapper key={`exceptionsError-${x}`}>
                <FontAwesomeIcon color={theme.palette.red} icon={faTriangleExclamation} />
                <ErrorElementItem onClick={() => setFocus(`exceptions.${i}.allowance`)}>
                  {x?.allowance?.message}
                </ErrorElementItem>
              </ErrorElementWrapper>
            )
          })
        }
        {errors.remainingPercentage &&
          <ErrorElementWrapper>
            <FontAwesomeIcon
              color={theme.palette.red}
              icon={faTriangleExclamation} />
            <ErrorElementItem onClick={() => setFocus('remainingPercentage')}>
              {errors.remainingPercentage?.message}
            </ErrorElementItem>
          </ErrorElementWrapper>
        }
      </>
    )
  }

  const handleUtilityChange = (energyMeterType: EnergyMeterType): void => {
    setValue('energyMeterType', energyMeterType);
  }

  return (
    <>
      <RouterPrompt when={checkForChanges()} />

      <TitleRow>
        <FairUseTitle>
          {t('FairUsage.AnnualAllowance', { ns: 'molecules' })}
        </FairUseTitle>
        {!editMode &&
          <ProtectedLink link={{ path: `exceeding-spaces/${siteFairUsePolicy.id}`, analytics: { action: 'exceeding_spaces', category: 'fair_use' } }}>
            <Button
              tertiary
              label={t('FairUsage.ViewExceedingSpaces', { ns: 'molecules' })}
              color={theme.palette.primary}
              style={{ marginLeft: 'auto' }}
            />
          </ProtectedLink>
        }
      </TitleRow>

      <TitleRow>
        <AnnualAllowanceUtility
          energyMeterType={energyMeterType}
          onValueChange={(energyMeterType: EnergyMeterType) => handleUtilityChange(energyMeterType)}
        />
        {!editMode &&
          <Button
            label={t('Edit', { ns: 'common' })}
            onClick={() => handleEditModeChange(!editMode)}
            style={{ alignSelf: 'start' }}
          />
        }
      </TitleRow>

      {(errors.spaceTypePolicies || errors.remainingPercentage || errors.exceptions) &&
        <ErrorBannerWrapper>
          <ErrorBannerMessage>
            {t('FairUsage.Validation.ErrorBannerHeading', { ns: 'molecules' })}
          </ErrorBannerMessage>
          {getValidationErrors()}
        </ErrorBannerWrapper>
      }

      <FormProvider {...formMethods}>
        {editMode &&
          <AnnualAllowanceForm />
        }
        <Seasonality editMode={editMode} energyConsumption={energyConsumption} />
        <Exceptions editMode={editMode} policyId={siteFairUsePolicy.id} />
      </FormProvider>

      {editMode &&
        <ButtonContainer>
          {siteFairUsePolicy.state === FairUseState.Draft &&
            <Button
              tertiary
              label={t('Reset', { ns: 'common' })}
              color={theme.palette.red}
              onClick={() => reset()}
            />
          }
          {siteFairUsePolicy.state === FairUseState.Active &&
            <Button
              tertiary
              label={t('Cancel', { ns: 'common' })}
              color={theme.palette.red}
              onClick={() => {
                reset();
                handleEditModeChange(!editMode);
              }}
            />
          }
          <Button
            label={t('Save', { ns: 'common' })}
            onClick={(formMethods.handleSubmit(onSave))}
            disabled={savingInProgress}
          />
        </ButtonContainer>
      }
    </>
  )
}

export default AnnualAllowance

const FairUseTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  margin: 0;
`;

const TitleRow = styled.div`
  display: flex;
  gap: 10px;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;

  @media (min-width: 1200px) {
    flex-flow: row nowrap;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
`;

const ErrorBannerWrapper = styled.div`
    border-radius: 5px;
    border-width: 2px 2px 2px 20px;
    border-style: solid;
    border-color: ${p => p.theme.palette.red};;
    padding: 15px;
    margin: 15px 0;
`;

const ErrorBannerMessage = styled.div`
  margin: 0 0 15px;
  font-weight: 500;
`;

const ErrorElementWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

const ErrorElementItem = styled.span`
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;