import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class DeviceNotesGetNotesByDeviceId extends GetQuery<DeviceNote[]> {
  deviceId: number;

  constructor(deviceId: number) {
    super();
    this.deviceId = deviceId;
  }

  public targetUrl(): string {
    return `/devicenotes/getbydeviceid/${this.deviceId}`;
  }
}
