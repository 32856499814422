import { UserPreference } from '@shared/api/models/User/UserPreference';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class UserPreferenceGetByUserIdQuery extends GetQuery<UserPreference> {
  userId: number;

  constructor(userId: number) {
    super();
    this.userId = userId;
  }

  public targetUrl(): string {
    return `/UserPreferences/GetByUserId/${this.userId}`;
  }
}