import { transparentize } from 'polished';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

export type ToggleOption<TValue> = {
  label: string,
  value: TValue
};

type ToggleProps<TValue> = {
  defaultSelected?: ToggleOption<TValue>;
  options: ToggleOption<TValue>[];
  onChange: (value: TValue) => void;
  buttonStyle?: React.CSSProperties;
};

const Toggle = <TValue,>({ defaultSelected, options: optionsProp, onChange, buttonStyle }: ToggleProps<TValue>) => {
  const [options, setOptions] = useState<ToggleOption<TValue>[]>([]);
  const [selected, setSelected] = useState<ToggleOption<TValue>>();

  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected]);

  useEffect(() => {
    setOptions(optionsProp);

    if (defaultSelected) {
      setSelected(defaultSelected);
    } else if (optionsProp[0]) {
      setSelected(optionsProp[0])
    }
  }, [optionsProp, defaultSelected]);

  const handleChange = (option: ToggleOption<TValue>) => {
    setSelected(option);
    onChange(option.value);
  };

  if (options.length === 0) {
    return null;
  }

  return (
    <ToggleWrapper>
      {options.map((option, i) => (
        <ToggleButton
          key={`${option.label}-${i}`}
          selected={option === selected}
          onClick={() => handleChange(option)}
          style={buttonStyle}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleWrapper>
  );
};

export default Toggle;

const ToggleWrapper = styled.div`
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 1px 4px ${p => p.theme.palette.shadows.medium}, 0px 1px 4px ${p => p.theme.palette.shadows.medium};
  display: flex;
  flex-shrink: 0;
`;

const ToggleButton = styled.div<{ selected: boolean }>`
  padding: 2px 8px 1px 8px;
  user-select: none;
  cursor: pointer;
  
  text-align: center; 
  width: auto;
  
  font-size: 14px;
  font-weight: 500;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  color: ${p => p.theme.palette.text.fair};

  ${({ selected }) => selected && css`
    background-color: ${p => transparentize(0.1, p.theme.palette.primary)};
    color: ${p => p.theme.palette.text.onPrimary};
  `}
`;