import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class UserSetRoleCommand extends PatchQuery<unknown> {
  userId: number;
  roleId: number;

  constructor(userId: number, roleId: number) {
    super(0);

    this.userId = userId;
    this.roleId = roleId;
  }

  public targetUrl(): string {
    return '/users/role';
  }
}
