import ExportCsvDto from '@dashboard/api/models/ExportCsvDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class CsvDevicesInactiveGetBySiteIdQuery extends GetQuery<ExportCsvDto> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/exports/CsvDevicesInactiveBySite/${this.siteId}`;
  }
}
