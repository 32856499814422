import styled, { css } from 'styled-components';
import { ILoading } from './Loading.types';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Loading = (props: ILoading) => (
  <SytledLoading className="spinner" {...props}>
    <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />

    {props.message && <Message>{props.message}</Message>}
  </SytledLoading>
);

const SytledLoading = styled.div<ILoading>`
  display: ${(p) => (p.inline ? 'inline-block' : 'block')};
  text-align: ${(p) => (p.alignLeft ? 'left' : 'center')};
  margin: ${(p) => (p.inline ? 0 : '20px')};

  ${(p) => p.fullWidthCentered && css`
    width: 100%;
  `};

  svg {
    width: ${(p) => p.size || '40px'};
    height: ${(p) => p.size || '40px'};

    path {
      fill: ${(p) => p.color || p.theme.palette.primary};
    }
  }
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.palette.text.weak};
  margin-top: 5px;
`;
