import { transparentize } from 'polished';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export interface IMapMarker {
  id: number;
  lat: number;
  lng: number;
  title: string;
  text: string;
  link: string;
  isHovered: boolean;
}

interface IMapMarkerComponent {
  lat: number;
  lng: number;
  markerId: number;
  marker: IMapMarker;
  interactive?: boolean;
}

export const MapMarker = ({ marker, interactive }: IMapMarkerComponent) => {
  const [isHovered, setIsHovered] = useState(false);

  const styledMarker = (
    <StyledMarker
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      lat={marker.lat}
      lng={marker.lng}
      hover={(isHovered || marker.isHovered)}
      interactive={interactive}
    />
  );

  return (
    <>
      {interactive && (isHovered || marker.isHovered) &&
        <Tooltip>
          <TooltipTitle>
            {marker.title}
          </TooltipTitle>
          <TooltipText>
            {marker.text}
          </TooltipText>
        </Tooltip>
      }

      {interactive &&
        <Link to={marker.link}>
          {styledMarker}
        </Link>
      }

      {!interactive &&
        styledMarker
      }
    </>
  );
}

const StyledMarker = styled.div<{ lat: number, lng: number, hover: boolean, interactive?: boolean }>`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  z-index: 1;

  background-color: #FFF;
  border: 6px solid ${p => transparentize(0.2, p.theme.palette.primary)};
  cursor: pointer;

  ${p => p.interactive && p.hover && css`
    border-color: ${p => transparentize(0, p.theme.palette.primary)};
  `}

  ${p => !p.interactive && css`
    cursor: auto;
    border-color: ${p => transparentize(0.1, p.theme.palette.primary)};
  `}
`;

const Tooltip = styled.div`
  position: absolute;
  top: -30px;
  left: 30px;
  min-width: 140px;
  min-height: 70px;
  z-index: 2;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;

  background-color: ${p => p.theme.palette.backgrounds.surface};
  border: 1px solid ${p => p.theme.palette.borders.weak};
  box-shadow: 0px 4px 8px rgba(34, 37, 51, 0.07);
  border-radius: 3px;
`;

const TooltipTitle = styled.div`
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  color: ${p => p.theme.palette.text.medium};
  margin-bottom: 5px;
`;

const TooltipText = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
`;