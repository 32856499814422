import { useCallback } from 'react';
import { useAsync } from './useAsync';
import { BaseQuery } from '@shared/api/queries/common/BaseQuery';
import { authConfig } from '@shared/configs/authentication/authConfig';
import { ToastOptions } from 'react-toastify';

export type TUseApiExecute = <TData>({ query, successMessage, errorMessage, pendingMessage, displayApiError, toastOptions }: ExecutePropTypes<TData>) => Promise<TData | undefined>;

export interface ExecutePropTypes<TData> {
  /**
   * Query/Command
   */
  query: BaseQuery<TData>;
  /**
   * Toast success message
   */
  successMessage?: string;
  /**
   * Toast error message
   */
  errorMessage?: string;
  /**
   * Toast pending message
   */
  pendingMessage?: string;
  /**
   * Display the error message returned from the API if present (if set to true, custom errorMessage will not be displayed)
   */
  displayApiError?: boolean;
  /**
   * Toast options
   */
  toastOptions?: ToastOptions;
}

/**
 * A hook to help with executing queries/commands.
 *
 * @example
 *   const { execute } = useApi();
 *
 *   query: new UserProfileUpdateCommand(modifiedUser.fullName, modifiedUser.phoneNumber, modifiedUser.displayName),
 *     successMessage: "Profile updated",
 *     errorMessage: "Failed to update profile"
 *   });
 */
export const useApi = () => {
  const { execute: executeAsyncFunc, loading, error, controller } = useAsync();

  const execute: TUseApiExecute = useCallback(
    <TData>({ query, successMessage, errorMessage, pendingMessage, displayApiError, toastOptions }: ExecutePropTypes<TData>) => {
      return executeAsyncFunc({
        asyncFunc: async () => await query.execute(authConfig, controller.current),
        successMessage: successMessage,
        errorMessage: errorMessage,
        pendingMessage: pendingMessage,
        displayApiError,
        toastOptions
      });
    },
    [executeAsyncFunc, controller]
  );

  return {
    execute,
    loading,
    error,
    controller: controller.current
  };
};
