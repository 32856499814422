import { RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Modal } from '../../Modal/Modal';
import StepCount from './StepCount';
import { Title } from '@shared/components/atoms/Title/Title';
import { Button } from '@shared/components/atoms/Button/Button';
import Cookie from 'js-cookie';
import { OnboardingModalCookie } from './Onboarding';
import ImageOnboardingSlide1 from '@shared/assets/images/onboarding-slide1.png'
import ImageOnboardingSlide2 from '@shared/assets/images/onboarding-slide2.png'
import ImageOnboardingSlide3 from '@shared/assets/images/onboarding-slide3.png'
import ImageOnboardingSlide4 from '@shared/assets/images/onboarding-slide4.png'

type TOnboardingStep = {
  image: string;
  title: string;
  text: string;
  customElement?: JSX.Element;
}

type PropTypes = {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  toggle: () => void;
}

const OnboardingModal = ({ modalRef, isOpen, toggle }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [index, setIndex] = useState(0);

  // Reset index to display first step when the modal is opened/closed
  useEffect(() => {
    setIndex(0);
  }, [isOpen]);

  const hideModal = () => {
    Cookie.set(OnboardingModalCookie, 'true', {
      sameSite: 'strict',
      expires: 365,
    });

    toggle();
  }

  const steps: TOnboardingStep[] = useMemo(() => [
    {
      image: ImageOnboardingSlide1,
      title: t('Onboarding.Slide1.Title', { ns: 'molecules' }),
      text: t('Onboarding.Slide1.Text', { ns: 'molecules' })
    },
    {
      image: ImageOnboardingSlide2,
      title: t('Onboarding.Slide2.Title', { ns: 'molecules' }),
      text: t('Onboarding.Slide2.Text', { ns: 'molecules' })
    },
    {
      image: ImageOnboardingSlide3,
      title: t('Onboarding.Slide3.Title', { ns: 'molecules' }),
      text: t('Onboarding.Slide3.Text', { ns: 'molecules' })
    },
    {
      image: ImageOnboardingSlide4,
      title: t('Onboarding.Slide4.Title', { ns: 'molecules' }),
      text: t('Onboarding.Slide4.Text', { ns: 'molecules' })
    }
  ], [t]);

  const step = steps[index];

  return (
    <Modal
      ref={modalRef}
      hide={toggle}
      isOpen={isOpen}
      plainModal
      width="max-content"
    >
      <Wrapper>
        <Image src={step.image} />

        <Content>
          <Title text={step.title} size='lg' />

          <Text>
            {step.text}
          </Text>

          {step.customElement}

          <Footer>
            {index === 0 &&
              <Button
                tertiary
                label={t('SkipForNow', { ns: 'common' })}
                onClick={hideModal}
                color={theme.text.primary}
                analytics={{ action: 'skip', category: 'whats_new' }}
              />
            }

            {index > 0 &&
              <Button
                tertiary
                label={t('Back', { ns: 'common' })}
                onClick={() => setIndex(prev => prev - 1)}
                color={theme.text.primary}
              />
            }

            <StepCount
              currentStep={index}
              numSteps={steps.length}
            />

            {index !== steps.length - 1 &&
              <Button
                tertiary
                label={t('Next', { ns: 'common' })}
                onClick={() => setIndex(prev => prev + 1)}
                analytics={{ action: 'next', category: 'whats_new' }}
              />
            }

            {index === steps.length - 1 &&
              <Button
                tertiary
                label={t('Close', { ns: 'common' })}
                onClick={hideModal}
                analytics={{ action: 'close', category: 'whats_new' }}
              />
            }
          </Footer>
        </Content>
      </Wrapper>
    </Modal>
  );
};

export default OnboardingModal;

const Wrapper = styled.div`
  width: 550px;
`;

const Content = styled.div`
  min-height: 280px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 4px 4px 0 0;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
`;

const Footer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;