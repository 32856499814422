import { useParams } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CsvDevicesInactiveGetByBuildingIdQuery from '@dashboard/api/queries/exports/CsvDevicesInactiveGetByBuildingIdQuery';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Title } from '@shared/components/atoms/Title/Title';
import { Button } from '@shared/components/atoms/Button/Button';
import InactiveDevicesWidget from '../InactiveDevicesWidget/InactiveDevicesWidget';
import { Building } from '@shared/api/models/Building/Building';
import useCsvExport from '@dashboard/hooks/useCsvExport';

type PropTypes = {
  building: Building
}

const Devices_Status = ({ building }: PropTypes) => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const { handleCsvExport: exportDevices, loading: loadingDevicesExport } = useCsvExport();
  const { t } = useTranslation(['molecules']);

  const handleDevicesExport = async () => {
    if (buildingId === undefined) {
      return;
    }

    const query = new CsvDevicesInactiveGetByBuildingIdQuery(parseInt(buildingId));
    const fileName = `${building.name}${t('DeviceCard.FileNameDeviceStatus', { ns: 'molecules' })}.csv`
    exportDevices(query, fileName);
  };

  return (
    <>
      <BackButton
        url='./..'
        label={t('BackToDevices', { ns: 'navigation' })}
      />

      <FlexRow>
        <Title text={t('DeviceCard.DeviceStatus', { ns: 'molecules' })} />
        <Button
          label={t('DeviceCard.ExportDevices', { ns: 'molecules' })}
          onClick={handleDevicesExport}
          loading={loadingDevicesExport}
          style={{ marginLeft: 'auto' }}
        />
      </FlexRow>

      <FlexRow style={{ marginTop: '20px' }}>
        {buildingId &&
          <InactiveDevicesWidget buildingId={parseInt(buildingId)} />
        }
      </FlexRow>
    </>
  );
}

export default Devices_Status;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;