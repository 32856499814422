import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class SpaceClimateControlGetByTempMonitoringDeviceQuery extends GetQuery<SpaceClimateControlDto> {
  deviceId: number;

  constructor(deviceId: number) {
    super();
    this.deviceId = deviceId;
  }

  public targetUrl(): string {
    return `/ClimateControl/space/GetByTempMonitoringDevice/${this.deviceId}`;
  }
}
