import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import FlexRow from '@dashboard/components/atoms/FlexRow/FlexRow';
import { IGradientStep } from '@dashboard/types/GradientStep';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Input } from '@shared/components/atoms/Form/Form';
import Slider from '@shared/components/atoms/Form/Slider';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ClimateControlConstants } from './ClimateControlUtils';

type ClimateControlSliderProps = {
  minTemp: number;
  maxTemp: number;
  targetTemp: number;
  minTempFormId: string;
  maxTempFormId: string;
  targetTempFormId: string;
  climateControlSliderGradientSteps: IGradientStep[];
}

const ClimateControlSlider = ({ minTemp, maxTemp, targetTemp, minTempFormId, maxTempFormId, targetTempFormId, climateControlSliderGradientSteps }: ClimateControlSliderProps) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();
  const { getUnit, toLocale } = useLocalisation();

  const stepDownOneTemp = watch('fallbackSettings.noMotionThreshold1Temp');
  const stepDownTwoTemp = watch('fallbackSettings.noMotionThreshold2Temp');
  const offlineSensorTemp = watch('fallbackSettings.offlineSensorTemp');
  const unit = getUnit(MetricType.Temperature);
  const minRange = toLocale(MetricType.Temperature, ClimateControlConstants.TempRangeMin);
  const maxRange = toLocale(MetricType.Temperature, ClimateControlConstants.TempRangeMax);

  const handleChangeMaxThumb = (value: number) => {
    // Update step-down temperature values if they fall outside the range
    if (value < stepDownOneTemp) {
      setValue('fallbackSettings.noMotionThreshold1Temp', value);
    }
    if (value < stepDownTwoTemp) {
      setValue('fallbackSettings.noMotionThreshold2Temp', value);
    }
    if (value < offlineSensorTemp) {
      setValue('fallbackSettings.offlineSensorTemp', value);
    }
  };

  const handleChangeMinThumb = (value: number) => {
    // Update step-down temperature values if they fall outside the range
    if (value > stepDownOneTemp) {
      setValue('fallbackSettings.noMotionThreshold1Temp', value);
    }
    if (value > stepDownTwoTemp) {
      setValue('fallbackSettings.noMotionThreshold2Temp', value);
    }
    if (value > offlineSensorTemp) {
      setValue('fallbackSettings.offlineSensorTemp', value);
    }
  };

  return (
    <>
      <StyledFlexColumn>
        <Slider
          value={minTemp}
          minRange={minRange}
          maxRange={maxRange}
          gradientSteps={climateControlSliderGradientSteps}
          minValue={minRange}
          maxValue={targetTemp - 1}
          formId={minTempFormId}
          onChange={thumb => handleChangeMinThumb(thumb)}
        />
        <Slider
          value={maxTemp}
          minRange={minRange}
          maxRange={maxRange}
          gradientSteps={climateControlSliderGradientSteps}
          onChange={thumb => handleChangeMaxThumb(thumb)}
          minValue={targetTemp + 1}
          maxValue={maxRange}
          formId={maxTempFormId}
        />
        <Slider
          value={targetTemp}
          minRange={minRange}
          maxRange={maxRange}
          gradientSteps={climateControlSliderGradientSteps}
          minValue={minTemp + 1}
          maxValue={maxTemp - 1}
          formId={targetTempFormId}
          showTicks={true}
        />
      </StyledFlexColumn>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <FlexRow style={{ flexDirection: 'column', gap: 5 }}>
          <NumberInputLabel>{t('ClimateControl.Minimum', { ns: 'molecules' })}</NumberInputLabel>
          <FlexRow style={{ gap: 10, alignItems: 'center' }}>
            <Controller
              control={control}
              name={minTempFormId}
              render={({ field: { onChange, value } }) => (
                <StyledNumberInput
                  type="number"
                  value={value ?? 0}
                  onChange={e => e.currentTarget.value && onChange(e.currentTarget.value)}
                />
              )}
            />
            <Unit>{unit}</Unit>
          </FlexRow>
        </FlexRow>
        <FlexRow style={{ flexDirection: 'column', gap: 5 }}>
          <NumberInputLabel>{t('ClimateControl.InitialTarget', { ns: 'molecules' })}</NumberInputLabel>
          <FlexRow style={{ gap: 10, alignItems: 'center' }}>
            <Controller
              control={control}
              name={targetTempFormId}
              render={({ field: { onChange, value } }) => (
                <StyledNumberInput
                  type="number"
                  value={value ?? 0}
                  onChange={e => e.currentTarget.value && onChange(e.currentTarget.value)}
                />
              )}
            />
            <Unit>{unit}</Unit>
          </FlexRow>
        </FlexRow>
        <FlexRow style={{ flexDirection: 'column', gap: 5 }}>
          <NumberInputLabel>{t('ClimateControl.Maximum', { ns: 'molecules' })}</NumberInputLabel>
          <FlexRow style={{ gap: 10, alignItems: 'center' }}>
            <Controller
              control={control}
              name={maxTempFormId}
              render={({ field: { onChange, value } }) => (
                <StyledNumberInput
                  type="number"
                  value={value ?? 0}
                  onChange={e => e.currentTarget.value && onChange(e.currentTarget.value)}
                />
              )}
            />
            <Unit>{unit}</Unit>
          </FlexRow>
        </FlexRow>
      </FlexRow>
    </>
  );
};

export default ClimateControlSlider;

const StyledFlexColumn = styled(FlexColumn)`
  position: relative;
  gap: 30px;
  width: 100%;
  height: 20px;
  max-width: 400px;
  margin: 15px 0 15px 0;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const NumberInputLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: ${p => p.theme.text.secondary};
`;

const StyledNumberInput = styled(Input)`
  width: 60px;
  height: 32px;
  border-radius: 4px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
  text-align: left;
  padding: 10px 0 10px 10px;
`;

const Unit = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-right: 7px;
`;
