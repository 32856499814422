import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Metric } from '@shared/api/models/Metric/Metric';

export function GetMetricValue<T extends { metrics: Metric[] }>(obj: T, metricType: MetricType) {
  return obj.metrics.find(x => x.metricType === metricType)?.value;
}

export function GetMetricMeasuredOn<T extends { metrics: Metric[] }>(obj: T, metricType: MetricType) {
  return obj.metrics.find(x => x.metricType === metricType)?.measuredOn;
}
