import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import RangePicker, { TimeRange } from '@dashboard/components/atoms/RangePicker/RangePicker';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { IModalListSelectGroup } from '@shared/components/molecules/ModalListSelect/ModalListSelect.types';
import { usePopover } from '@shared/hooks/usePopover';
import { Label } from '@shared/components/atoms/Form/Form';
import { ModalListSelect } from '@shared/components/molecules/ModalListSelect/ModalListSelect';
import { Button } from '@shared/components/atoms/Button/Button';
import { Popover } from '@shared/components/atoms/Popover/Popover';
import { PopoverPlacement } from '@shared/components/atoms/Popover/Popover.types';

type PropTypes = {
  label: string,
  onRangeChange: (timeRange: TimeRange) => void,
  loading?: boolean,
  metricSelectOptions?: IModalListSelectGroup<MetricType>[],
  onMetricSelectChange?: (selectedMetrics: MetricType[]) => void,
  maxDays?: number
}

const ButtonWithRangePicker = ({ label, onRangeChange, loading, metricSelectOptions, onMetricSelectChange, maxDays }: PropTypes) => {
  const theme = useTheme();
  const { visible, toggle, ref } = usePopover({ disableCloseOnClickOutside: true });
  const [timeRange, setTimeRange] = useState<TimeRange>();
  const { t } = useTranslation(['molecules']);
  const [hasSelectedMetrics, setHasSelectedMetrics] = useState<boolean>(false);

  const handleMetricSelect = (selectedMetrics: MetricType[]) => {
    onMetricSelectChange && onMetricSelectChange(selectedMetrics);
    selectedMetrics.length !== 0 ? setHasSelectedMetrics(true) : setHasSelectedMetrics(false);
  }

  const popoverContent = (
    <PopoverContent>
      <div>
        <Label>{t('TimeRange', { ns: 'molecules' })}</Label>
        <RangePicker
          onChange={setTimeRange}
          style={{ width: '100%' }}
          maxNumDays={maxDays}
        />
      </div>

      {metricSelectOptions && onMetricSelectChange &&
        <div>
          <Label>Metrics</Label>
          <ModalListSelect
            modalWidth={'380px'}
            options={metricSelectOptions}
            onChange={(selectedMetrics: MetricType[]) => handleMetricSelect(selectedMetrics)}
            placeholder={'Select Metrics'}
            title="Metrics"
          />
        </div>
      }

      <FlexRow>
        <Button
          tertiary
          label={t('Cancel', { ns: 'molecules' })}
          onClick={toggle}
          color={theme.palette.text.weak}
        />
        <Button
          label={t('Export', { ns: 'molecules' })}
          disabled={metricSelectOptions && onMetricSelectChange ? !timeRange || !hasSelectedMetrics : !timeRange}
          onClick={() => {
            if (timeRange) {
              onRangeChange(timeRange);
              toggle();
              setTimeRange(undefined)
            }
          }}
          style={{ marginLeft: 'auto' }}
        />
      </FlexRow>
    </PopoverContent>
  );

  return (
    <Popover
      ref={ref}
      visible={visible}
      popoverContent={popoverContent}
      placement={PopoverPlacement.BottomRight}
      alignWithTarget
    >
      <Button
        label={label}
        loading={loading}
        onClick={toggle}
      />
    </Popover>
  );
};

export default ButtonWithRangePicker;

const FlexRow = styled.div`
  display: flex;
  margin-top: 10px;
`;

const PopoverContent = styled.div`
  width: 300px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;