import * as SolidIcons from '@fortawesome/pro-solid-svg-icons';
import * as RegularIcons from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FontAwesomeCollection, IColorThreshold, IListWidgetConfig, IListWidgetMetric } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import { Metric } from '@shared/api/models/Metric/Metric';
import { MetricType_TypedValue } from '@shared/api/enums/MetricType/MetricType_TypedValue';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';

export const getColor = (value: string | number | Date, thresholds?: IColorThreshold[]): string | undefined => {
  if (!thresholds || thresholds.length === 0) {
    return;
  }

  const lastThresholds = thresholds[thresholds.length - 1];

  if (value >= lastThresholds.threshold) {
    return lastThresholds.color
  }

  return thresholds.find(x => value < x.threshold)?.color;
};

interface IListWidget {
  metrics: Metric[];
  config?: IListWidgetConfig;
}

const ListWidget = ({ metrics, config }: IListWidget) => {
  if (!config) {
    return null;
  }

  return (
    <Row>
      {config.metrics.map((metricConfig, i) => (
        <ListWidgetItem
          key={i}
          config={metricConfig}
          metrics={metrics}
        />
      ))}
    </Row>
  );
};

export default ListWidget;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
`;

interface IListWidgetItem {
  config: IListWidgetMetric;
  metrics: Metric[];
}

export const ListWidgetItem = ({ config, metrics }: IListWidgetItem) => {
  const { displayString } = useLocalisation();
  const { t } = useTranslation(['deviceConfigs', 'enums']);
  const metric = metrics.find(m => m.metricType === config.metricType);

  if (!metric) {
    return null;
  }

  // Get correct icon defintion from appropriate collection
  let icon = undefined;
  if (config.icon) {
    if (config.icon.collection === FontAwesomeCollection.Solid) {
      icon = Object.entries(SolidIcons).find(i => i[0] === config.icon?.name)?.[1];
      icon = icon as SolidIcons.IconDefinition;
    } else {
      icon = Object.entries(RegularIcons).find(i => i[0] === config.icon?.name)?.[1];
      icon = icon as RegularIcons.IconDefinition
    }
  }

  // Calculate the angle to rotate the icon by if 'rotate' is configured
  let rotationAngle = config.icon?.rotate?.angleOffset ?? 0;
  const angleMetric = metrics.find(m => m.metricType === config.icon?.rotate?.angleMetricType);

  if (angleMetric) {
    const value = MetricType_TypedValue(angleMetric);
    rotationAngle += (typeof value === 'number') ? value : 0;
  }

  const iconColor = config.icon?.color ?? getColor(MetricType_TypedValue(metric), config.icon?.colorThresholds);

  return (
    <FlexRow>
      {icon &&
        <IconSquare color={iconColor}>
          <Icon
            icon={icon}
            color={iconColor}
            angle={rotationAngle}
          />
        </IconSquare>
      }
      <Column>
        <Label>
          {config.label ? t(config.label, { ns: 'deviceConfigs' }) : t(metric.metricType, { ns: 'enums' })}
        </Label>
        <Value>
          {displayString(metric.metricType, metric.value)}
        </Value>
      </Column>
    </FlexRow>
  );
};

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const IconSquare = styled.div<{ color?: string }>`
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  background-color: ${p => transparentize(0.9, p.color ?? 'grey')};
  border-radius: 4px;
  margin-right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string, angle?: number }>`
  font-size: 20px;
  color: ${p => p.theme.palette.primary};
  margin: auto;

  path {
    fill: ${p => p.color};
  }

  ${p => p.angle && css`
    transform: rotate(${p.angle}deg);
  `}
`;

const Label = styled.div<{ inline?: boolean }>`
  line-height: 15px;
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
  white-space: nowrap;
`;

const Value = styled.div`
  line-height: 16px;
  font-size: 16px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  white-space: nowrap;
`;