import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { ReactNode, useRef, useState } from 'react';
import { useXarrow } from 'react-xarrows';
import styled from 'styled-components';

interface ScrollDrag {
  children?: ReactNode
}

const ScrollDrag = ({ children }: ScrollDrag) => {
  const updateXarrow = useXarrow();
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.style.cursor = 'grabbing';

      setIsScrolling(true);
      setClientX(e.clientX);
      setClientY(e.clientY);
    }
  };

  const onMouseUp = () => {
    if (ref.current) {
      ref.current.style.cursor = 'grab';

      setIsScrolling(false);
    }
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isScrolling && ref.current) {
      ref.current.scrollLeft = scrollX - e.clientX + clientX;
      ref.current.scrollTop = scrollY - e.clientY + clientY;

      setScrollX(scrollX - e.clientX + clientX);
      setClientX(e.clientX);

      setScrollY(scrollY - e.clientY + clientY);
      setClientY(e.clientY);

      updateXarrow();
    }
  };

  return (
    <ScrollArea
      style={{ cursor: 'grab', width: '100%', overflow: 'auto' }}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      {children}
    </ScrollArea>
  );
};

export default ScrollDrag;


const ScrollArea = styled.div`
  width: 100%;
  height: ${`calc(100vh - ${TopBarHeight}px - ${HorizontalNavbarHeight}px - 130px)`};
  border-radius: 5px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 0 8px 3px ${p => p.theme.palette.shadows.medium};
  cursor: grab;
  overflow: auto;
  user-select: none;
`;