import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { IModal } from './Modal.types';

export const Modal = React.forwardRef<HTMLDivElement, IModal>(({ isOpen, hide, title, titleColor, titleBackgroundColor, closeIconHoverBackground, footer, width, children, modalStyles, dialogStyles, headerStyles, bodyStyles, footerStyles, plainModal, modalId }, ref) =>
  isOpen
    ? ReactDOM.createPortal(
      <StyledModal style={modalStyles} id={modalId}>
        <ModalDialog ref={ref} style={dialogStyles} width={width}>
          {plainModal && <>{children}</>}
          {!plainModal && (
            <>
              <ModalHeader titleColor={titleColor} backgroundColor={titleBackgroundColor} style={headerStyles}>
                {title}
                <Button tertiary circle label={<CloseButton icon={faXmark} color={titleColor} />} onClick={hide} hoverBackgroundColor={closeIconHoverBackground} style={{ padding: '4px', marginLeft: 'auto' }} />
              </ModalHeader>
              <ModalBody style={bodyStyles}>{children}</ModalBody>
              {footer && <ModalFooter style={footerStyles}>{footer}</ModalFooter>}
            </>
          )}
        </ModalDialog>
      </StyledModal>,
      document.body
    )
    : null
);

const StyledModal = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);

  transition: opacity 0.15s linear;
  overflow: auto;
`;

const ModalDialog = styled.div<{ width?: string }>`
  position: relative;
  background-color: ${(p) => p.theme.palette.backgrounds.surface};
  background-clip: padding-box;
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);

  @keyframes blowUpModal {
    0% {
      transform: scale(0.6);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: blowUpModal 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;

  /* On small screens */
  width: calc(100vw - 20px);
  margin: 15vh 10px;

  /* On large screens */
  @media (min-width: 600px) {
    width: ${(p) => p.width ?? '600px'};
    margin: 15vh auto;
  }
`;

const CloseButton = styled(FontAwesomeIcon) <{ color?: string }>`
  color: ${(p) => p.color ?? p.theme.palette.text.fair};
  width: 16px;
  height: 16px;
`;

const ModalHeader = styled.div<{
  titleColor?: string;
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;

  padding: 10px 16px;
  border-bottom: 1px solid ${(p) => p.theme.palette.borders.weak};
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => p.titleColor ?? p.theme.palette.text.medium};
  background-color: ${(p) => p.backgroundColor ?? 'none'};
  border-radius: 4px 4px 0 0;
`;

const ModalBody = styled.div`
  position: relative;
  padding: 16px;
  color: ${(p) => p.theme.palette.text.fair};
`;

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 16px;
`;
