import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

const SkeletonLoader = () => {

  return (
    <Wrapper>
      <Bar width={70} height={9} />
      <Bar width={150} height={6} />

      <Divider />

      <Bar width={70} height={9} />
      <Bar width={150} height={6} />
    </Wrapper>
  )
}

export default SkeletonLoader

const breatheAnimation = keyframes`
  to {
    background-position-x: -200%;
  }
`;

const Bar = styled.div<{ width?: number, height?: number }>`
  width: ${p => p.width ?? 130}px;
  height: ${p => p.height ?? 12}px;
  background: ${p => `linear-gradient(110deg, ${p.theme.skeleton.background} 8%, ${transparentize(0.8, '#fff')} 18%, ${p.theme.skeleton.background} 33%)`};
  border-radius: 30px;
  background-size: 200% 100%;
  animation: ${breatheAnimation} 2.5s linear infinite;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 20px 20px;
`;

const Divider = styled.div`
  margin: 10px 0;
  border-bottom: 1px solid ${p => transparentize(0.6, p.theme.action.divider)};
`;