import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';
import TemplateConfiguration from './TemplateConfiguration/TemplateConfiguration';
import TemplateList from './TemplateList/TemplateList';
import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import EsgExplorerRuleDto from '@dashboard/api/models/EsgExplorerRuleDto';
import { isCongfigValid } from '../../EsgExplorerMenu';
import { Building } from '@shared/api/models/Building/Building';

interface IManageTemplates {
  open: boolean;
  templates: EsgExplorerTemplateDto[];
  siteId: number;
  buildings: Building[];
  refreshTemplates: () => void;
  refreshRules: () => void;
  rules: EsgExplorerRuleDto[];
  onGenerate: (template: EsgExplorerTemplateDto) => void;
  loading: boolean;
}

const TemplatesTab = ({ open, templates, siteId, buildings, refreshTemplates, refreshRules, rules, onGenerate, loading }: IManageTemplates) => {
  const [selectedTemplate, setSelectedTemplate] = useState<EsgExplorerTemplateDto>();

  useEffect(() => {
    setSelectedTemplate(undefined);
  }, [open]);

  return (
    <Wrapper isHidden={!open}>
      <TemplateList
        templates={templates}
        selectedTemplate={selectedTemplate}
        siteId={siteId}
        refreshTemplates={refreshTemplates}
        refreshRules={refreshRules}
        setSelectedTemplate={setSelectedTemplate}
        rules={rules}
      />

      <TemplateConfiguration
        siteId={siteId}
        buildings={buildings}
        template={selectedTemplate}
        refreshTemplates={refreshTemplates}
        setSelectedTemplate={setSelectedTemplate}
        configIsValid={isCongfigValid(selectedTemplate?.configuration)}
        onGenerate={onGenerate}
        loading={loading}
      />
    </Wrapper>
  );
};

export default TemplatesTab;

const Wrapper = styled.div<{ isHidden: boolean }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  ${p => p.isHidden && css`
    display: none;
  `}
`;