import { MutableRefObject, RefObject } from 'react';

/**
 * Switch utility tab index and move underline
 */
export const switchUtilityTab = (
  selectedIndex: number, 
  tabRefs: MutableRefObject<{[key: number]: HTMLDivElement | null;}>,
  lineRef: RefObject<HTMLDivElement>) => {
  if (selectedIndex === undefined) {
    return;
  }

  const tab = tabRefs.current[selectedIndex];

  if (tab && lineRef.current) {
    lineRef.current.style.left = tab.offsetLeft + 'px';
    lineRef.current.style.width = tab.clientWidth + 'px';
  }
}