import ExportCsvDto from '@dashboard/api/models/ExportCsvDto';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class CsvPeopleCountingZonesWithMeasurementsAndMetricTypesQuery extends PostQuery<ExportCsvDto> {
  virtualDeviceIds: number[];
  fromDateTime: string;
  toDateTime: string;

  constructor(virtualDeviceIds: number[], fromDateTime: string, toDateTime: string) {
    super();
    this.virtualDeviceIds = virtualDeviceIds;
    this.fromDateTime = fromDateTime;
    this.toDateTime = toDateTime;
  }

  public targetUrl(): string {
    return '/exports/CsvPeopleCountingZonesWithMeasurements';
  }
}