import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { OperatorPostQuery } from '@shared/api/queries/ResidentApp/common/OperatorPostQuery';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';

export class ChallengesResidentAppCreateCommand extends OperatorPostQuery<OperatorChallengeDto> {
  title: string;
  shortTitle: string;
  description: string;
  metricType: MetricType;
  targetValue: number;
  startDate: string;
  endDate: string;
  siteId: number;
  prizes: OperatorPrizeDto[];

  constructor(
    title: string,
    shortTitle: string,
    description: string,
    metricType: MetricType,
    targetValue: number,
    startDate: string,
    endDate: string,
    siteId: number,
    prizes: OperatorPrizeDto[]
  ) {
    super();
    this.title = title;
    this.shortTitle = shortTitle;
    this.description = description;
    this.metricType = metricType;
    this.targetValue = targetValue;
    this.startDate = startDate;
    this.endDate = endDate;
    this.siteId = siteId;
    this.prizes = prizes;
  }

  public targetUrl(): string {
    return '/Challenges';
  }
}
