import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import SiteFeature from '../SiteFeature';
import { Site } from '@shared/api/models/Site/Site';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useModal } from '@shared/hooks/useModal';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import { DatePicker } from 'antd';
import { useApiState } from '@shared/hooks/useApiState';
import { SiteFairUseConfigurationCreateCommand } from '@shared/api/queries/FairUse/Configuration/SiteFairUseConfigurationCreateCommand';
import { useApi } from '@shared/hooks/useApi';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { SiteFairUseConfigurationGetBySiteIdQuery } from '@shared/api/queries/FairUse/Configuration/SiteFairUseConfigurationGetBySiteIdQuery';
import { SiteFairUseConfigurationUpdateCommand } from '@shared/api/queries/FairUse/Configuration/SiteFairUseConfigurationUpdateCommand';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import ResidentialSpaceTypesGetBySiteIdQuery from '@dashboard/api/queries/space/ResidentialSpaceTypesGetBySiteIdQuery';

type FairUseFeatureProps = {
  site: Site;
}

const FairUseFeature = ({ site }: FairUseFeatureProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const { MonthPicker } = DatePicker;
  const { isOpen: isEnableFairUseModalOpen, toggle: toggleEnableFairUseModal, ref: enableFairUseModal } = useModal({ disableCloseOnClickOutside: true });
  const { isOpen: isDisableFairUseModalOpen, toggle: toggleDisableFairUseModal, ref: disableFairUseModal } = useModal({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const { data: siteFairUseConfigurations, execute: refresh } = useApiState({
    query: new SiteFairUseConfigurationGetBySiteIdQuery(site.id),
  }, [site]);
  const { data: residentialSpaces } = useApiState({
    query: new ResidentialSpaceTypesGetBySiteIdQuery(site.id),
  }, [site]);

  useEffect(() => {
    setIsEnabled(!!siteFairUseConfigurations?.enabled);
  }, [siteFairUseConfigurations]);

  const hasResidentialSpaces = (residentialSpaces: SpaceType[]): boolean => {
    return residentialSpaces && residentialSpaces.length > 0;
  };
  
  const handleChange = (enabled: boolean) => {
    if (enabled) {
      toggleEnableFairUseModal();
    } else {
      toggleDisableFairUseModal();
    }
  };

  const handleUpdateFeautre = (): void => {
    if (!siteFairUseConfigurations) {
      createFairUseConfiguration();
      toggleEnableFairUseModal();
    } else if (siteFairUseConfigurations) {
      updateFairUseConfiguration();
      !isEnabled ? toggleEnableFairUseModal() : toggleDisableFairUseModal();
    }
  }

  const createFairUseConfiguration = async () => {
    if (!startDate) {
      return;
    }
    await execute({
      query: new SiteFairUseConfigurationCreateCommand(site.id, startDate),
      successMessage: t('EnableFairUseSuccess', { ns: 'status' }),
      errorMessage: t('EnableFairUseSpaceError', { ns: 'status' }),
      pendingMessage: t('EnableFairUsePending', { ns: 'status' })
    });

    refresh();
  };

  const updateFairUseConfiguration = async () => {
    if (!siteFairUseConfigurations) {
      return;
    }
    await execute({
      query: new SiteFairUseConfigurationUpdateCommand(siteFairUseConfigurations.id, !isEnabled, startDate),
      successMessage: t(`${!isEnabled ? 'EnableFairUseSuccess' : 'DisableFairUseSuccess'}`, { ns: 'status' }),
      errorMessage: t(`${!isEnabled ? 'EnableFairUseError' : 'DisableFairUseError'}`, { ns: 'status' }),
      pendingMessage: t(`${!isEnabled ? 'EnableFairUsePending' : 'DisableFairUsePending'}`, { ns: 'status' })
    });

    refresh();
  };

  const createPlaceholder = (): string => {
    let placeholder = t('FairUsage.FeatureFlag.StartDatePlaceholder', { ns: 'molecules' });
    if (siteFairUseConfigurations?.reportingPeriod) {
      placeholder = `${siteFairUseConfigurations?.reportingPeriod} ${new Date(Date.now()).getFullYear()}`;
    }
    return placeholder;
  }

  return (
    <>
      <SiteFeature
        name={t('FairUsage.FairUse', { ns: 'molecules' })}
        description={t('FairUsage.FeatureFlag.FeatureDescription', { ns: 'molecules' })}
        icon={solid('meter-bolt')}
        isEnabled={isEnabled}
        onChange={handleChange}
      />
      
      <InteractiveDialog
        modalRef={enableFairUseModal}
        isOpen={isEnableFairUseModalOpen}
        hide={toggleEnableFairUseModal}
        title={t('FairUsage.FairUse', { ns: 'molecules' })}
        confirmButton={{
          label: t('Save', { ns: 'common' }),
          onClick: handleUpdateFeautre,
          disabled: !startDate
        }}
        cancelButton={{
          label: t('Cancel', { ns: 'common' }),
          onClick: toggleEnableFairUseModal
        }}
        content={
          <>
            <SubHeader>
              {t('FairUsage.FeatureFlag.FeatureModalHeader', { ns: 'molecules' })}
            </SubHeader>
            <p>
              {t('FairUsage.FeatureFlag.FeatureModalContent', { ns: 'molecules' })}
            </p>
            <MonthPicker
              style={{ 'width': '50%' }}
              format="MMMM YYYY"
              placeholder={createPlaceholder()}
              onChange={timeRange => setStartDate(timeRange?.toDate())}
              disabled={residentialSpaces ? !hasResidentialSpaces(residentialSpaces) : true}
            />
            {(residentialSpaces ? !hasResidentialSpaces(residentialSpaces) : true) &&
              <ResidentialError> 
                {t('FairUsage.FeatureFlag.FeatureModalErrorMessageResidential', { ns: 'molecules' })} 
              </ResidentialError> 
            }       
          </>
        }
      />

      <WarningDialog
        modalRef={disableFairUseModal}
        isOpen={isDisableFairUseModalOpen}
        sectionOne={t('FairUsage.FeatureFlag.DisableFeatureDialog', { ns: 'molecules' })}
        confirmButton={t('Disable', { ns: 'common' })}
        onCancel={toggleDisableFairUseModal}
        onConfirm={handleUpdateFeautre}
      />
    </>
  )
}

export default FairUseFeature

const SubHeader = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${p => p.theme.palette.text.fair};
  padding-bottom: 5px;
`;

const ResidentialError = styled.div`
  margin-top: 15px;
  color: ${p => p.theme.palette.red};;
`;