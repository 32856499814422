import styled, { css } from 'styled-components';

export const SelectField = styled.div<{ disabled?: boolean, maxFieldHeight?: number, hasOverflow?: boolean }>`
  position: relative;
  width: 100%;
  min-height: 38px;
  max-height: ${p => p.maxFieldHeight}px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 9px 30px 9px 10px;
  overflow: hidden;

  color: ${p => p.theme.text.secondary};
  background-color: ${p => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.action.outlineBorder};
  border-radius: 4px;
  box-shadow: ${p => p.theme.palette.forms.input.boxShadow};

  transition: all 150ms ease;

  &:hover {
    overflow: auto;
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }

  ${p => p.disabled && css`
    background-color: ${p.theme.palette.forms.input.disabledBackground};
  `}
`;