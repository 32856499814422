import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

const NotSetMessage = () => {
  const theme = useTheme();
  const { t } = useTranslation('molecules');
  
  return (
    <NotSetMessageContainer>
      <FontAwesomeIcon
        icon={regular('circle-exclamation')}
        color={theme.palette.systemMessage.error}
        style={{ fontSize: '16px' }}
      />
      <span>{t('ResidentApp.NotSet')}</span>
    </NotSetMessageContainer>
  )
}

export default NotSetMessage;

const NotSetMessageContainer = styled.div`
  font-size: 14px;
  display: flex;
  gap: 6px;
  align-items: center;
  color: ${p => p.theme.palette.systemMessage.error};
  font-weight: 500;
`;