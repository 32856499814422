import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import NotSetMessage from '../Common/NotSetMessage';
import { Button } from '@shared/components/atoms/Button/Button';
import ResidentAppFormErrorMessage from '@dashboard/components/molecules/ResidentApp/Common/ResidentAppFormErrorMessage';
import { SiteThresholdForm } from './TemperatureThresholdForm';
import { SpaceTypeThresholdForm } from './ElectricityThresholdForm';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import React from 'react';
import { Input } from '@shared/components/atoms/Form/Form';

export type Threshold = {
  title: string;
  seasonOne: number;
  seasonTwo: number;
}

type ThresholdColumnItem = {
  index: number;
  title: string;
  seasonOneValue: number;
  seasonTwoValue: number;
}

type ThresholdEditableTableProps = {
  isEditMode?: boolean;
  methods?: UseFormReturn<SiteThresholdForm | SpaceTypeThresholdForm>;
  thresholds: Threshold[];
  onCancel: () => void;
  onSave: () => void;
  metricType: MetricType;
  loading?: boolean;
}

const ThresholdEditableTable = ({isEditMode, methods, thresholds, onCancel, onSave, metricType, loading}: ThresholdEditableTableProps) => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
      
  const renderInput = React.useCallback(
    (value: number, inputName: `thresholds.${number}.seasonOne.targetValue` | `thresholds.${number}.seasonTwo.targetValue`, methods?: UseFormReturn<SiteThresholdForm | SpaceTypeThresholdForm>) => {
      if (isEditMode && methods) {
        return (
          <Controller
            name={inputName}
            control={methods.control}
            defaultValue={value}
            rules={{
              required: t('RequiredField', { ns: 'validation' }),
              min: { value: 1, message: t('InvalidMinNumber', { ns: 'validation', min: 1 }) },
            }}
            render={({ field, fieldState }) => (
              <StyledInputWrapper>
                <StyledTableInput
                  error={!!fieldState.error}
                  type="number"
                  style={{ textAlign: 'center' }}   
                  {...field}
                />
                {fieldState.error && (
                  <StyledError>
                    <ResidentAppFormErrorMessage message={fieldState.error.message} />
                  </StyledError>
                )}
              </StyledInputWrapper>
            )}
          />
        );
      }
  
      return value === 0 ? <NotSetMessage /> : <>{value}</>;
    },
    [isEditMode, t]
  );

  const renderSeasonOneCell = React.useCallback(
    (record: ThresholdColumnItem) =>
      renderInput(
        record.seasonOneValue,
        `thresholds.${record.index}.seasonOne.targetValue`,
        methods,
      ),
    [renderInput, methods]
  );
  
  const renderSeasonTwoCell = React.useCallback(
    (record: ThresholdColumnItem) =>
      renderInput(
        record.seasonTwoValue,
        `thresholds.${record.index}.seasonTwo.targetValue`,
        methods,
      ),
    [renderInput, methods]
  );
  
  const tableColumns: ITableColumn<ThresholdColumnItem>[] = [
    {
      key: 'title',
      label:
        metricType === MetricType.ElectricityKwh ? t('SpaceType', { ns: 'common' }) : undefined,
      width: 8,
    },
    {
      label: t('ResidentApp.SeasonOne'),
      key: 'seasonOneTarget',
      width: 2,
      centerAlign: true,
      customElement: renderSeasonOneCell
    },
    {
      label: t('ResidentApp.SeasonTwo'),
      key: 'seasonTwoTarget',
      width: 2,
      centerAlign: true,
      customElement: renderSeasonTwoCell
    },
  ];

  const records = React.useMemo(
    () =>
      thresholds.map((threshold, index) => ({
        index,
        title: threshold.title,
        seasonOneValue: threshold.seasonOne,
        seasonTwoValue: threshold.seasonTwo,
      })),
    [thresholds]
  );
  
  return (
    <>
      <Table
        columns={tableColumns}
        records={records}
        recordKey="title"
        disablePaging
        minHeight={isEditMode ? '100px' : '0px'}
        cardEffect
        removeDefaultStyling
        disableSorting
        tableValueStyle={{ padding: isEditMode ? '40px 0' : undefined }}
      />
      {isEditMode && (
        <Buttons>
          <Button
            label={t('Cancel', { ns: 'common' })}
            tertiary
            onClick={onCancel}
            color={theme.error.main}
            disabled={loading}
          />
          <Button
            label={t('Save', { ns: 'common' })}
            onClick={onSave}
            disabled={loading}
          />
        </Buttons>
      )}
    </>
  );
}

export default ThresholdEditableTable;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 24px;
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledError = styled.div`
  position: absolute;
  margin-top: -5px;
  left: 0;
  right: 0;
`;

const StyledTableInput = styled(Input)<{error?: boolean}>`
  border: 1px solid ${(p) => p.error ? p.theme.error.main : p.theme.palette.forms.input.border};
`;