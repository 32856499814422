import { TenantFeature } from '@shared/api/models/Tenant/TenantFeature';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export class TenantFeatureBulkCreateCommand extends PostQuery<TenantFeature> {
  tenantFeatures: TenantFeature[];

  constructor(tenantFeatures: TenantFeature[]) {
    super();
    this.tenantFeatures = tenantFeatures;
  }

  public targetUrl(): string {
    return '/features/TenantFeatureBulkCreate';
  }
}
