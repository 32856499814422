import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IDetailsPanelTab } from './DetailsPanel';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';

const DetailsPanelDevices = ({ alert, onMobile }: IDetailsPanelTab) => {
  const { t } = useTranslation(['molecules', 'enums']);
  const { displayString } = useLocalisation();

  return (
    <>
      {alert.message.devices.map((device) => (
        <RowWrapper key={device.deviceId}>
          <Link to={`/site/${alert.space.floor.building.siteId}/building/${alert.space.floor.building.id}/floor/${alert.space.floor.id}/space/${alert.spaceId}/device/${device.deviceId}`} >
            <Row className='row' $onMobile={onMobile}>
              <div className='col'>
                <DeviceName>
                  {device.friendlyName}
                </DeviceName>
              </div>

              <div className='col'>
                <LabelValuePair
                  label={t(device.metricType, { ns: 'enums' })}
                  value={displayString(device.metricType, device.value)}
                  size="xs"
                  smallGap
                />
              </div>

              {!onMobile &&
                <div className='col'>
                  <LabelValuePair
                    label={t('Alerting.MeasuredOn', { ns: 'molecules' })}
                    value={format(new Date(device.measuredOn), 'dd. MMM yyy, hh:mmaaa')}
                    size="xs"
                    smallGap
                  />
                </div>
              }

              <div className='col-auto'>
                <ArrowIcon icon={light('circle-arrow-right')} />
              </div>
            </Row>
          </Link>
        </RowWrapper>
      ))}
    </>
  );
};

export default DetailsPanelDevices;

const RowWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  }
`;

const Row = styled.div<{ $onMobile: boolean }>`
  align-items: center;
  gap: 20px;
  padding: 20px 20px;
  cursor: pointer;

  ${p => p.$onMobile && css`
    gap: 10px;
    padding: 10px 20px;
  `}
`;

const DeviceName = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
  color: ${p => p.theme.palette.text.weak};

  ${Row}:hover & {
    color: ${p => p.theme.palette.primary};
  }
`;