import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { OperatorGetQuery } from '@shared/api/queries/ResidentApp/common/OperatorGetQuery';

export class ChallengesResidentAppGetAllBySiteQuery extends OperatorGetQuery<OperatorChallengeDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/Challenges/GetAllBySite/${this.siteId}`;
  }
}
