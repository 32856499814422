import styled from 'styled-components';

type ResidentAppFormErrorMessageProps = {
  message?: string;
};

const ResidentAppFormErrorMessage = ({message}: ResidentAppFormErrorMessageProps) => {
  return (
    <ErrorMessage>{message}</ErrorMessage>
  )
}

export default ResidentAppFormErrorMessage;

const ErrorMessage = styled.p`
  margin: 10px 0 0 0;
  color: ${({ theme }) => theme.palette.systemMessage.error};
  font-size: 12px;
`;