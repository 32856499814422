import { IGradientStep } from '@dashboard/types/GradientStep';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import Slider from '@shared/components/atoms/Slider/Slider';
import { ISliderThumb } from '@shared/components/atoms/Slider/Slider.types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CLIMATE_CONTROL_MAX_TEMPERATURE, CLIMATE_CONTROL_MIN_TEMPERATURE } from './ClimateControlSettingsWidget';

export const climateControlSliderGradientSteps: IGradientStep[] = [
  {
    value: 5,
    color: '#7494FF'
  },
  {
    value: 11,
    color: '#6BB1FF'
  },
  {
    value: 17,
    color: '#67FB6A'
  },
  {
    value: 23,
    color: '#FBF00A'
  },
  {
    value: 29,
    color: '#FF4B5A'
  },
];

type ClimateControlSliderProps = {
  thumbs: ISliderThumb[];
  highlightedThumb?: number;
  onChange: (thumbs: ISliderThumb[]) => void;
  setHighlightedThumb: (index?: number) => void;
}

export const ClimateControlSlider = ({ thumbs, highlightedThumb, onChange, setHighlightedThumb }: ClimateControlSliderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Slider
        metricType={MetricType.Temperature}
        min={CLIMATE_CONTROL_MIN_TEMPERATURE}
        max={CLIMATE_CONTROL_MAX_TEMPERATURE}
        thumbs={thumbs}
        gradientSteps={climateControlSliderGradientSteps}
        onChange={onChange}
        highlightedThumb={highlightedThumb}
        setHighlightedThumb={setHighlightedThumb}
      />

      <MinMaxLabels>
        <span>{t('Min', { ns: 'common' }).toUpperCase()}</span>
        <span>{t('Max', { ns: 'common' }).toUpperCase()}</span>
      </MinMaxLabels>
    </>
  );
};

const MinMaxLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
`;