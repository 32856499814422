import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { useEffect, useState } from 'react';

interface IPrintCheck {
  inPrintMode: boolean;
  onPrintModeExit: () => void;
}

const PrintCheck = ({ inPrintMode, onPrintModeExit }: IPrintCheck) => {
  const [beforePrint, setBeforePrint] = useState(false);
  const mediaPrintActive = useMediaQuery('print');

  /**
   * Use the 'beforeprint' event to trigger in Firefox as the mediaQuery check does not seem to work in FF.
   */
  useEffect(() => {
    const listener = () => {
      setBeforePrint(true);
    };

    window.addEventListener('beforeprint', listener);

    return () => window.removeEventListener('beforeprint', listener);
  }, []);

  useEffect(() => {
    if (inPrintMode && (mediaPrintActive || beforePrint)) {
      onPrintModeExit();
      setBeforePrint(false);
    } else if (inPrintMode && !mediaPrintActive && !beforePrint) {
      setTimeout(() => window.print(), 0);
    }

  }, [inPrintMode, mediaPrintActive, beforePrint, onPrintModeExit])

  return (
    <>
    </>
  );
};

export default PrintCheck;