import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type PropTypes = {
  placeholder: string;
  searchString: string;
  onChange: (str: string) => void;
  onClick: () => void;
  onClear: () => void;
}

const SearchBarInput = ({ placeholder, searchString, onChange, onClick, onClear }: PropTypes) => {

  return (
    <Container>
      <MagnifierIcon icon={solid('search')} />

      <StyledInput
        type="text"
        placeholder={placeholder}
        value={searchString}
        onChange={(e) => onChange(e.currentTarget.value)}
        hasClearIcon={searchString.length > 0}
        onClick={onClick}
      />

      {searchString.length > 0 &&
        <ClearIcon
          icon={solid('xmark')}
          onClick={onClear}
        />
      }
    </Container>
  );
};

export default SearchBarInput;

const Container = styled.div`
  position: relative;
`;

const MagnifierIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 11px;
  left: 14px;
  font-size: 12px;
  color: ${p => p.theme.action.active};
`;

const StyledInput = styled.input <{ hasClearIcon: boolean }>`
  width: 300px;
  height: 32px;
  padding-left: 33px; // Space for magnifier icon
  padding-right: ${p => p.hasClearIcon ? '30px' : 0}; // Space for clear icon

  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: ${p => p.theme.text.primary};

  border-radius: 20px;
  border: 1px solid ${p => p.theme.action.filled};
  background-color: ${p => p.theme.action.filled};

  &::placeholder {
    color: ${p => p.theme.text.secondary};
  }

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${p => p.theme.primary.main};
  }
`;

const ClearIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 12px;

  font-size: 14px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;