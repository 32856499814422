import { ClimateControlStatus } from '@shared/api/enums/ClimateControlStatus/ClimateControlStatus';
import { PatchQuery } from '../../common/PatchQuery';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';

export default class SiteClimateControlUpdateCommand extends PatchQuery<SiteClimateControlDto> {
  minTemp: number;
  maxTemp: number;
  targetTemp: number;
  fallbackTemp: number;
  controlMode: string;
  noMotionRulesEnabled: boolean;
  noMotionThreshold1: number;
  noMotionThreshold1Temp: number;
  noMotionThreshold2: number;
  noMotionThreshold2Temp: number;
  externalSensorModeEnabled: boolean;
  offlineSensorThreshold: number;
  offlineSensorTemp: number;
  status: ClimateControlStatus;

  constructor(siteClimateControl: SiteClimateControlDto) {
    super(siteClimateControl.id);
    this.minTemp = siteClimateControl.minTemp;
    this.maxTemp = siteClimateControl.maxTemp;
    this.targetTemp = siteClimateControl.targetTemp;
    this.fallbackTemp = siteClimateControl.fallbackTemp;
    this.controlMode = siteClimateControl.controlMode;
    this.noMotionRulesEnabled = siteClimateControl.noMotionRulesEnabled;
    this.noMotionThreshold1 = siteClimateControl.noMotionThreshold1;
    this.noMotionThreshold1Temp = siteClimateControl.noMotionThreshold1Temp;
    this.noMotionThreshold2 = siteClimateControl.noMotionThreshold2;
    this.noMotionThreshold2Temp = siteClimateControl.noMotionThreshold2Temp;
    this.externalSensorModeEnabled = siteClimateControl.externalSensorModeEnabled;
    this.offlineSensorThreshold = siteClimateControl.offlineSensorThreshold;
    this.offlineSensorTemp = siteClimateControl.offlineSensorTemp;
    this.status = siteClimateControl.status;
  }

  public targetUrl(): string {
    return '/ClimateControl/site';
  }
}
