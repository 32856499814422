import { Floor } from '@shared/api/models/Floor/Floor';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class FloorCreateCommand extends PostQuery<Floor> {
  name: string;
  floorNumber: number;
  shortCode?: string
  buildingId: number;

  constructor(buildingId: number, name: string, floorNumber: number, shortCode?: string) {
    super();
    this.name = name;
    this.floorNumber = floorNumber;
    this.shortCode = shortCode;
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return '/floors';
  }
}
