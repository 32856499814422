import { Device } from '@shared/api/models/Device/Device';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class DeviceMoveCommand extends PatchQuery<Device> {
  friendlyName: string;
  newSpaceId: number;
  deleteMeasurements: boolean;

  constructor(id: number, friendlyName: string, newSpaceId: number, deleteMeasurements: boolean) {
    super(id);
    this.friendlyName = friendlyName;
    this.newSpaceId = newSpaceId;
    this.deleteMeasurements = deleteMeasurements;
  }

  public targetUrl(): string {
    return '/devices/move';
  }
}