import styled, { useTheme } from 'styled-components'
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { DeviceNoteUpdateCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteUpdateCommand';
import { Button } from '@shared/components/atoms/Button/Button';
import { TextArea } from '@shared/components/atoms/Form/Form';
import { DeviceNoteSource } from '@dashboard/api/enums/DeviceNoteSource';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import NoteActions from './NoteActions';
import dayjs from 'dayjs';
import { shouldUseModifedDate } from '@dashboard/components/organisms/DevicePage/DevicePage_Notes';

interface PropTypes {
  note: DeviceNote;
  refreshNotes: () => void;
}

const SingleDeviceNote = ({ note, refreshNotes }: PropTypes) => {
  const theme = useTheme();
  const { execute, loading } = useApi();
  const { localisation } = useLocalisation();
  const [content, setContent] = useState<string>('');
  const [editMode, setEditMode] = useState(false);

  const { t } = useTranslation(['molecules']);

  useEffect(() => {
    setContent(note.contents);
  }, [note]);

  const onCancel = () => {
    setContent(note.contents);
    setEditMode(false);
  };

  const onSave = async () => {
    await execute({ query: new DeviceNoteUpdateCommand(note.id, content) });
    setEditMode(false);
    refreshNotes();
  };

  return (
    <Item key={note.id}>
      <Header>
        <div>
          <Name>{note.createdByName ?? t('Unknown', { ns: 'common' })}</Name>
          {note.source && note.source !== DeviceNoteSource.Unknown &&
            <Source>&nbsp;{t('via', { ns: 'common' })} {t(`DeviceNoteSource.${note.source}`, { ns: 'enums' })}</Source>
          }
        </div>

        <NoteActions
          note={note}
          refreshNotes={refreshNotes}
          setEditMode={setEditMode}
        />
      </Header>

      {!editMode &&
        <Content>
          {content}
        </Content>
      }

      {editMode &&
        <StyledTextArea
          onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContent(e.currentTarget.value)}
          value={content}
        />
      }

      {editMode &&
        <FlexRow>
          <Button
            tertiary
            label={t('Cancel', { ns: 'common' })}
            onClick={onCancel}
            color={theme.palette.red}
            disabled={loading}
            small
          />
          <Button
            label={t('Save', { ns: 'common' })}
            onClick={onSave}
            disabled={loading || !content}
            small
          />
        </FlexRow>
      }

      <SmallText>
        {dayjs(new Date(shouldUseModifedDate(note) ? note.modifiedOn : note.createdOn)).format(localisation.dateFormats.dateAndTime)}
      </SmallText>
    </Item>
  )
}

export default SingleDeviceNote;

const Item = styled.div`
  font-size: 14px;
  padding: 16px;
  border: 1px solid ${p => p.theme.action.divider};
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 ${p => p.theme.shadow.primary};
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledTextArea = styled(TextArea)`
  background-color: transparent;
  box-shadow: none;
  font-size: 14px;
  color: ${p => p.theme.text.primary};
  height: 100px;
  box-shadow: 0 1px 2px 0 ${p => p.theme.shadow.primary};
`;

const Name = styled.span`
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;

const Source = styled.span`
  font-size: 12px;
  font-style: italic;
  color: ${p => p.theme.text.light};
`;

const Content = styled.div`
  color: ${p => p.theme.text.primary};
  margin-bottom: 10px;
  max-height: 200px;
  overflow: auto;
`;

const SmallText = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 8px;
`;