import { OperatorPostQuery } from '@shared/api/queries/ResidentApp/common/OperatorPostQuery';

export class PushNotificationResidentAppCreateCommand extends OperatorPostQuery<unknown> {
  siteId: number;
  title: string;
  body: string;
  spaceIds?: number[];

  constructor(siteId: number, title: string, body: string, spaceIds?: number[]) {
    super();
    this.siteId = siteId;
    this.spaceIds = spaceIds;
    this.title = title;
    this.body = body;
  }

  public targetUrl(): string {
    return '/PushNotifications';
  }
}
