import styled, { css } from 'styled-components';

interface IReportPageHeader {
  pageNumber: number;
  numPages: number;
  title?: JSX.Element;
  subTitle?: JSX.Element;
  blobUri?: string;
  noPageBreak?: boolean;
}

const ReportPageHeader = ({ pageNumber, numPages, title, subTitle, noPageBreak }: IReportPageHeader) => {

  return (
    <PageHeaderWrapper noPageBreak={noPageBreak}>
      <PageHeaderRow>
        <PageNumberWrapper>
          <PageNumber>{pageNumber}</PageNumber>
          <NumPages>&nbsp;/&nbsp;{numPages}</NumPages>
        </PageNumberWrapper>
        <RightAligned>
          <PageHeaderTitleCol>
            <PageHeaderTitle>
              {title}
            </PageHeaderTitle>
            <PageHeaderSubTitle>
              {subTitle}
            </PageHeaderSubTitle>
          </PageHeaderTitleCol>
        </RightAligned>
      </PageHeaderRow>

      <PageHeaderLine />
    </PageHeaderWrapper>
  );
}

export default ReportPageHeader;

const PageHeaderWrapper = styled.div<{ noPageBreak?: boolean }>`
  display: none;

  @media print {
    display: block;
    position: relative;
    margin-left: -15mm;
    margin-bottom: 5mm;

    ${p => !p.noPageBreak && css`
      break-before: page;
      page-break-before: always;
      -webkit-break-before: page;
    `}
  }
`;

const PageHeaderRow = styled.div`
  width: 180mm;
  margin-left: 15mm;
  padding-top: 12mm;

  display: flex;
  align-items: center;
`;

const PageHeaderLine = styled.div`
  width: 180mm;
  height: 0.2mm;
  border-radius: 1mm;
  margin-left: 15mm;
  background: #0099f1;
`;

const PageNumberWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const PageNumber = styled.div`
  margin-left: 1mm;
  font-size: 10pt;
  font-weight: 500;
  color: ${p => p.theme.palette.printStyles.text.medium};
  padding-top: 1.8mm;
`;

const NumPages = styled.div`
  font-size: 8pt;
  font-weight: 400;
  color: ${p => p.theme.palette.printStyles.text.fair};
`;

const RightAligned = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5mm;
  margin-right: 0.5mm;
`;

const PageHeaderTitle = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: ${p => p.theme.palette.printStyles.text.medium};
  text-align: right;
  padding-bottom: 4px;
`;

const PageHeaderSubTitle = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #717d8f;
  text-align: right;
  padding-bottom: 3px;
`;

const PageHeaderTitleCol = styled.div`
  display: flex;
  flex-direction: column;
`;