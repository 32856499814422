import EsgExplorerRuleDto from '@dashboard/api/models/EsgExplorerRuleDto';
import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import { Site } from '@shared/api/models/Site/Site';
import styled, { css } from 'styled-components';
import RuleList from './RuleList/RuleList';
import { Button } from '@shared/components/atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { useModal } from '@shared/hooks/useModal';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import CreateRule from './RuleList/CreateRule';
import NoData from '@shared/components/atoms/NoData/NoData';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Footer } from '@dashboard/components/molecules/EsgExplorer/EsgExplorerMenu';

export type EsgTemplateSelectOption = {
  label: string,
  value: EsgExplorerTemplateDto
}

interface IAutomationTab {
  open: boolean;
  site: Site;
  rules: EsgExplorerRuleDto[];
  refreshRules: () => void;
  loading: boolean;
  templates: EsgExplorerTemplateDto[];
}

const AutomationTab = ({ open, site, rules, refreshRules, loading, templates }: IAutomationTab) => {
  const { t } = useTranslation();
  const { isOpen, toggle, ref } = useModal({});

  return (
    <Wrapper isHidden={!open}>
      {rules.length === 0 ? (
        <NoData
          icon={solid('clock-rotate-left')}
          label={t('ESG.NoRulesAddedYet', { ns: 'molecules' })}
          subLabel={t('ESG.NoAutomatedRules', { ns: 'molecules' })}
          styles={{ padding: '20% 20px' }}
        />
      ) : (
        <RuleList
          site={site}
          rules={rules}
          refreshRules={refreshRules}
          loading={loading}
        />
      )
      }

      <Modal
        ref={ref}
        isOpen={isOpen}
        title={t('ESG.AutomationRule', { ns: 'molecules' })}
        hide={toggle}
      >
        <CreateRule
          siteId={site.id}
          templates={templates}
          refreshRules={refreshRules}
          hideModal={toggle}
        />
      </Modal>

      <Footer>
        <Button
          label={t('ESG.AddRule', { ns: 'molecules' })}
          onClick={() => toggle()}
          style={{ margin: 'auto 20px 24px', width: 'calc(100% - 40px)' }}
        />
      </Footer>

    </Wrapper>
  );
};

export default AutomationTab;

const Wrapper = styled.div<{ isHidden: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${p => p.isHidden && css`
    display: none;
  `}
`;