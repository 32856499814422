import { OperatorGetQuery } from '@shared/api/queries/ResidentApp/common/OperatorGetQuery';

export class PushNotificationsResidentAppGetNoOfRecipientsBySiteId extends OperatorGetQuery<{siteId: number, noOfRecipients: number}> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/PushNotifications/GetNoOfRecipientsBySiteId/${this.siteId}`;
  }
}
