import { SalesOrder } from '@shared/api/models/SalesOrder/SalesOrder';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class SiteSalesOrderGetBySiteIdQuery extends GetQuery<SalesOrder> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/salesorders/getbysiteid/${this.siteId}`;
  }
}