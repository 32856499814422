import { SiteOperatorDto } from '@shared/api/models/ResidentApp/SiteOperatorDto';
import { OperatorPatchQuery } from '@shared/api/queries/ResidentApp/common/OperatorPatchQuery';

export default class SiteOperatorResidentAppUpdateCommand extends OperatorPatchQuery<SiteOperatorDto> {
  id: number;
  email: string;
  minTemp?: number;
  maxTemp?: number;
  electricityTargetPercentage?: number;

  constructor(id: number, email: string, minTemp?: number, maxTemp?: number, electricityTargetPercent?: number) {
    super();
    this.id = id;
    this.email = email;
    this.minTemp = minTemp;
    this.maxTemp = maxTemp;
    this.electricityTargetPercentage = electricityTargetPercent;
  }

  public targetUrl(): string {
    return '/SiteOperators/Update';
  }
}
