import { Space } from '@shared/api/models/Space/Space';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class SpacesGetallQuery extends GetQuery<Space[]> {
  floorId: number;

  constructor(floorId: number) {
    super();
    this.floorId = floorId;
  }

  public targetUrl(): string {
    return `/spaces/${this.floorId}`;
  }
}
