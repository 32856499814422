import styled, { css } from 'styled-components';

export const CommonSidebar = styled.div<{ isHidden?: boolean }>`
  background-color: ${p => p.theme.background.container};
  border-right: 1px solid ${p => p.theme.action.divider};
  box-shadow: -1px 0px 8px 0px ${p => p.theme.shadow.primary};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: auto;
  width: 250px;
  padding: 16px;

  ${p => p.isHidden && css`
    display: none;
  `}
`;