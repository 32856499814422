import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApiState } from '@shared/hooks/useApiState';
import { useTranslation } from 'react-i18next';
import PerformanceCard from './PerformanceCard';
import SkeletonLoader from './SkeletonLoader';
import { SiteTemperatureSummaryGetQuery } from '@shared/api/queries/ClimateControl/Overview/SiteTemperatureSummaryGetQuery';

type PropTypes = {
  siteId: number;
}

const PerformanceCardsSiteTemperature = ({ siteId }: PropTypes) => {
  const { t } = useTranslation();
  const { toLocale, getUnit } = useLocalisation();
  const unit = getUnit(MetricType.Temperature);
  const { data, loading } = useApiState({
    query: new SiteTemperatureSummaryGetQuery(siteId)
  }, [siteId]);

  if (loading) {
    return (
      <>
        <SkeletonLoader />
        <SkeletonLoader />
      </>
    );
  }

  return (
    <>
      <PerformanceCard
        label={t('ClimateControl.PerformanceCards.Average', { ns: 'molecules' })}
        unit={unit}
        value={data?.averageSpaceTemperature && toLocale(MetricType.Temperature, data.averageSpaceTemperature, { round: 1 })}
        error={!data?.averageSpaceTemperature}
        errorMessage={t('ClimateControl.PerformanceCards.AverageError', { ns: 'molecules' })}
      />
      <PerformanceCard
        label={t('ClimateControl.PerformanceCards.Average10Percent', { ns: 'molecules' })}
        unit={unit}
        value={data?.averageSpaceTemperatureInTopTenPercent && toLocale(MetricType.Temperature, data.averageSpaceTemperatureInTopTenPercent, { round: 1 })}
        error={!data?.averageSpaceTemperatureInTopTenPercent}
        errorMessage={t('ClimateControl.PerformanceCards.Average10PercentError', { ns: 'molecules' })}
      />
    </>
  )
}

export default PerformanceCardsSiteTemperature;