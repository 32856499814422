import { AlertingRuleSeverity } from '@dashboard/api/enums/AlertingRuleSeverity';
import { Alert } from '@shared/api/models/Alert/Alert';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export class AlertsGetBySiteIdAndAlertingRuleSeverityQuery extends PostQuery<Alert[]> {
  siteId: number;
  severities: AlertingRuleSeverity[]

  constructor(siteId: number, severities: AlertingRuleSeverity[]) {
    super();
    this.siteId = siteId;
    this.severities = severities;
  }

  public targetUrl(): string {
    return '/alerting/GetAlertsBySiteIdAndAlertingRuleSeverity';
  }
}