import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { addHours, startOfDay } from 'date-fns';
import { transparentize } from 'polished';
import { groupBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import DeviceMetricsQuery from '@dashboard/api/queries/metrics/DeviceMetricsQuery';
import ChartSelectTabs from '@dashboard/components/atoms/charts/ChartSelectTabs';
import ChartActionButtons from '@dashboard/components/atoms/charts/ChartActionButtons';
import { ChartDataUnit, TimeRangeOption, TimeScaleRange, getChartDataUnit } from '@dashboard/utils/ChartUtils';
import { LineBarChartOverrides } from '@dashboard/components/atoms/charts/ChartOptionsFactory';
import LineBarChart from '@dashboard/components/atoms/charts/LineBarChart';
import CategoryChart from '@dashboard/components/atoms/charts/CategoryChart';
import ChartTimeGranularityToggle from '@dashboard/components/atoms/charts/ChartTimeGranularityToggle';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { ChartDataset, LineBarChartType } from '@shared/components/molecules/Charts/Chart.types';
import { DeviceMetricsChartType, IDeviceMetricsChart } from '@shared/components/molecules/Charts/DeviceMetricsChart.types';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { TimeGranularity, getTimeGranularityMetrics } from '@shared/api/enums/TimeGranularity/TimeGranularity';
import { useModal } from '@shared/hooks/useModal';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { MetricType_ChartColor } from '@shared/api/enums/MetricType/MetricType_ChartColor';
import { InformationDialog } from '@shared/components/molecules/InformationDialog/InformationDialog';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import ExternalAggregateDevicesQuery from '@dashboard/api/queries/ExternalAggregateDevice/ExternalAggregateDevicesQuery';
import { TFunction } from 'i18next';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';

const formatValue = (x: string, unitType: ChartDataUnit, t: TFunction): string => {
  const modifiedValue = unitType.modifier ? unitType.modifier(x, t) : x
  return modifiedValue.toString();
};

export interface IDeviceMetricsChartProps {
  /**
   * Device
   */
  device: DeviceWithMetrics,
  /**
   * Time-range options that can be selected
   */
  timeRangeOptions: TimeRangeOption[],
  /**
   * Hide time-range selector (e.g. when there is only one option)
   */
  hideTimeRangeSelector?: boolean;
  /**
   * Default chart type
   */
  defaultChartType?: LineBarChartType;
  /**
   * Options to override default chart options.
   */
  chartOptions?: LineBarChartOverrides;
  /**
   * Hide toggle to switch between line/bar chart
   */
  hideChartTypeToggle?: boolean;
  /**
   * A boolean value indicating if the device is an external aggregate device
   */
  isExternalAggregateDevice?: boolean
}

const DeviceMetricsChart = ({ device, timeRangeOptions, hideTimeRangeSelector, defaultChartType, chartOptions, hideChartTypeToggle, isExternalAggregateDevice }: IDeviceMetricsChartProps) => {
  const { execute, loading } = useApi();
  const [charts, setCharts] = useState<IDeviceMetricsChart[]>([]);
  const { getDeviceConfig } = useDeviceConfigContext();
  const [selectedChart, setSelectedChart] = useState<number>(0);
  const [selectedRangeOption, setSelectedRangeOption] = useState<TimeRangeOption>();
  const [selectedRange, setSelectedRange] = useState<TimeScaleRange>();
  const [currentZoomRange, setCurrentZoomRange] = useState<TimeScaleRange>();
  const [hasBeenZoomed, setHasBeenZoomed] = useState(false);
  const [selectedChartType, setSelectedChartType] = useState<LineBarChartType>(defaultChartType ?? LineBarChartType.Line);
  const [selectedTimeGranularity, setSelectedTimeGranularity] = useState<TimeGranularity>(TimeGranularity.Hourly);
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const deviceModelConfig = useMemo(() => getDeviceConfig(device.deviceModel), [getDeviceConfig, device]);
  const { t } = useTranslation();
  const { toLocale, getUnit, getUnitLabel } = useLocalisation();

  const formatMetric = useCallback((item: { measuredOn: string | number | Date; metricType: MetricType | AltUnits; value: string; }, unitType: ChartDataUnit) => ({
    x: (new Date(item.measuredOn)).valueOf(),
    y: formatValue(
      toLocale(item.metricType, parseFloat(item.value)).toString(),
      unitType,
      t
    )
  }), [toLocale, t])

  /**
   * Get device metrics from the API and create charts as defined in 'DeviceMetricsChartConfigs'.
  */
  useEffect(() => {
    const getChartLabel = (fallback: string, label?: string) => label ? t(label, { ns: 'deviceConfigs' }) : t(fallback, { ns: 'enums' });
    const getAxisLabel = (fallback?: string, label?: string) => label ? t(label, { ns: 'deviceConfigs' }) : fallback;

    const getMetricsDto = async (metricTypes: MetricType[], fromDate: Date, now: Date) => {
      if (isExternalAggregateDevice) {
        return await execute({
          query: new ExternalAggregateDevicesQuery(device.id, metricTypes, fromDate.toJSON(), now.toJSON(), selectedTimeGranularity)
        });
      }

      return await execute({
        query: new DeviceMetricsQuery(device.id, metricTypes, fromDate.toJSON(), now.toJSON(), selectedTimeGranularity)
      });
    }

    const createDatasets = async () => {
      if (!selectedRangeOption) {
        return;
      }

      const chartConfigs = deviceModelConfig?.ui.metricsChart.configs ?? [];
      const metricTypes: MetricType[] = chartConfigs.flatMap(x => x.metricTypes);

      const now = new Date();
      const fromDate = startOfDay(addHours(now, -(selectedRangeOption.hours)));

      const metricDtos = await getMetricsDto(metricTypes, fromDate, now);

      if (!metricDtos) {
        return;
      }

      const metricsGroupedByType = groupBy(metricDtos, x => x.metricType);

      const chartDatasets: ChartDataset[] = Object.entries(metricsGroupedByType).map(x => {
        const metricType = x[0] as MetricType;
        const unitType = getChartDataUnit(metricType);
        unitType.suffix = getUnit(metricType);
        unitType.label = getUnitLabel(metricType);

        const labels = x[1].map(metric => (new Date(metric.measuredOn)).toString());
        const dataset = x[1].map(metric => formatMetric(metric, unitType));


        return ({
          label: metricType,
          displayLabel: t(metricType, { ns: 'enums' }),
          dataset: dataset,
          dataUnit: unitType,
          labels: labels,
          color: MetricType_ChartColor(metricType)
        });
      });

      const charts: (IDeviceMetricsChart | null)[] = chartConfigs.map((config) => {
        // Look for all relevant datasets specified in the config for this chart
        let datasets = chartDatasets.filter((dataset) => config.metricTypesFromApi
          ? config.metricTypesFromApi.includes(dataset.label as MetricType)
          : config.metricTypes.includes(dataset.label as MetricType)
        );

        // For Pulse Counters, don't render a chart when there is no data for that metric type
        if (deviceModelConfig?.isPulseCounter && datasets.length === 0) {
          return null;
        }

        // Apply colours to datasets
        datasets = datasets.map((x, i) => ({
          ...x,
          color: config.colorOverride ? config.colorOverride[i] : x.color,
          categoryColors: config.categoryColors
        }));

        // Sort datasets in the same order they are defined as in the config
        datasets = datasets.sort((a, b) => config.metricTypes.indexOf(a.label as MetricType) - config.metricTypes.indexOf(b.label as MetricType));

        // Define the chart label (label from config if specified, otherwise the metric type name of the first dataset)
        const label = getChartLabel(config.metricTypes[0], config.label);

        // Define the yAxisLabel (label from config if specified, otherwise the data unit label of the first dataset)
        const yAxisLabel = getAxisLabel(datasets[0]?.dataUnit.label, config.yAxisLabel);

        return ({
          ...config,
          label: label,
          yAxisLabel: yAxisLabel,
          datasets: datasets
        });
      });

      const validCharts: IDeviceMetricsChart[] = charts.filter((x): x is IDeviceMetricsChart => x !== null);

      setCharts(validCharts);
    };

    createDatasets();
  }, [device, selectedRangeOption, selectedTimeGranularity, execute, deviceModelConfig, t, isExternalAggregateDevice, getUnitLabel, getUnit, toLocale, formatMetric]);

  /**
   * Set default time-range option if specified
   */
  useEffect(() => {
    let defaultOption = timeRangeOptions[0];

    timeRangeOptions.forEach(option => {
      if (option.default) {
        defaultOption = option;
      }
    });

    setSelectedRangeOption(defaultOption);
  }, [timeRangeOptions]);

  /**
   * Reset chart zoom range when selected time-range changes
   */
  useEffect(() => {
    if (!selectedRangeOption) {
      return;
    }

    const now = new Date();

    const newRange: TimeScaleRange = {
      scaleRange: {
        min: +addHours(now, -(selectedRangeOption.hours)),
        max: +now,
      },
      xScaleTimeUnit: selectedRangeOption.xScaleTimeUnit
    };

    setSelectedRange(newRange);
    setCurrentZoomRange(newRange);
    setHasBeenZoomed(false);
  }, [selectedRangeOption]);

  /**
   * Set zoom range when a user zoomed on the chart
   */
  const handleZoomPanComplete = (min: number, max: number) => {
    const currentRange: TimeScaleRange = {
      scaleRange: {
        min: min,
        max: max,
      },
      xScaleTimeUnit: undefined
    };

    setCurrentZoomRange(currentRange);
    setHasBeenZoomed(true);
  }

  /**
   * Reset zoom range when reset button is clicked
   */
  const handleResetZoom = () => {
    setCurrentZoomRange(selectedRange);
    setHasBeenZoomed(false);
  }

  /**
   * Render selected chart
   */
  const renderChart = (charts: IDeviceMetricsChart[], selectedChart: number): ReactNode => {
    const chart = charts[selectedChart];

    if (!chart) {
      return null;
    }

    if (chart.datasets.length === 0) {
      return (
        <Centered>
          <div>{t('NoDataForTimeRange', { ns: 'molecules' })}</div>
        </Centered>
      )
    }

    // Render line/bar chart
    if (!chart.type || chart.type === DeviceMetricsChartType.Default) {
      return (
        <ChartWrapper>
          <LineBarChart
            type={chart.options?.chartType ?? selectedChartType ?? defaultChartType}
            labels={chart.datasets[0].labels}
            datasets={chart.datasets}
            yAxisLabel={chart.yAxisLabel}
            timeScaleRange={currentZoomRange}
            onZoomPanComplete={handleZoomPanComplete}
            showLegend={chart.datasets.length > 1}
            chartOptions={{ ...chartOptions, stacked: chart.options?.stacked }}
            enableZoom
          />
        </ChartWrapper>
      )
    }

    // Render category chart
    if (chart.type === DeviceMetricsChartType.Category) {
      return (
        <CategoryChartWrapper>
          <CategoryChart
            labels={chart.datasets[0].labels}
            datasets={chart.datasets}
            timeScaleRange={currentZoomRange}
            onZoomPanComplete={handleZoomPanComplete}
          />
        </CategoryChartWrapper>
      )
    }
  };

  const renderTimeGranularity = (): ReactNode => {
    const chart = charts[selectedChart];
    if (!chart) {
      return null;
    }

    const selectedMetric = chart.metricTypes[0];

    const timeGranularityMetrics = getTimeGranularityMetrics();

    if (timeGranularityMetrics.includes(selectedMetric)) {
      return (
        <ChartTimeGranularityToggle
          timeGranularities={selectedRangeOption?.timeGranularities}
          selectedTimeGranularity={selectedTimeGranularity}
          onChange={setSelectedTimeGranularity}
        />
      )
    }
  }

  const onSelectedRangeChange = (range: TimeRangeOption) => {
    setSelectedRangeOption(range)

    const timeGranularities = range.timeGranularities;
    setSelectedTimeGranularity(timeGranularities !== undefined ? timeGranularities[0] : TimeGranularity.Hourly);
  }

  return (
    <div style={{ paddingBottom: '1px' }}>
      <InformationDialog
        modalRef={modalRef}
        isOpen={modalIsOpen}
        hide={toggleModal}
        title={t('ChartInteractions', { ns: 'molecules' })}
        button={{
          label: 'Close',
          onClick: toggleModal
        }}
        content={
          <>
            <div>
              <Trans i18nKey='molecules:Zoom'>
                <Bold></Bold>
              </Trans>
            </div><br />

            <div>
              <Trans i18nKey='molecules:Pan'>
                <Bold></Bold>
              </Trans>
            </div>
          </>
        }
      />

      <FlexRow>
        {!hideTimeRangeSelector && timeRangeOptions.map(range => (
          <Option
            key={range.label}
            selected={range === selectedRangeOption}
            onClick={() => onSelectedRangeChange(range)}
          >
            {range.label}
          </Option>
        ))}

        <RightAlign>
          {renderTimeGranularity()}

          <ChartActionButtons
            hasBeenZoomed={hasBeenZoomed}
            handleResetZoom={handleResetZoom}
            selectedChartType={selectedChartType}
            setSelectedChartType={setSelectedChartType}
            toggleModal={toggleModal}
            hideChartTypeToggle={hideChartTypeToggle}
          />
        </RightAlign>

      </FlexRow>

      {!selectedRangeOption &&
        <Centered>{t('NoTimeRange', { ns: 'molecules' })}</Centered>
      }

      {loading &&
        <Loading message={t('LoadingData', { ns: 'molecules' })} />
      }
      {!loading &&
        <>
          <ChartSelectTabs
            charts={charts}
            selected={selectedChart}
            onChange={setSelectedChart}
            selectedChartType={selectedChartType}
          />

          {renderChart(charts, selectedChart)}
        </>
      }
    </div>
  );
}

export default DeviceMetricsChart;

const ChartWrapper = styled.div`
  margin: 24px;
  height: 300px;
  width: auto;
  
  user-select: none;
`;

const CategoryChartWrapper = styled.div`
  margin: 24px;
  height: 200px;
  width: auto;

  background-color: ${p => transparentize(0.5, p.theme.palette.backgrounds.surfaceStrong)};
  border-radius: 5px;
`;

const Option = styled.div<{ selected: boolean }>`
  padding: 3px 11px;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.weak};
  border: 1px solid ${p => p.theme.palette.borders.medium};
  cursor: pointer;
  border-radius: 3px;

  ${p => p.selected && css`
    color: ${p.theme.palette.text.onPrimary};
    background-color: ${p.theme.palette.primary};
    border-color: ${p.theme.palette.primary};
  `}
`;

const Bold = styled.span`
  font-weight: 500;
`;

const FlexRow = styled.div`
  display:flex;
  align-items: center;
  gap: 15px;

  padding: 20px 20px 20px 30px;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Centered = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.palette.text.weak};
`;

const RightAlign = styled.div`
  display:flex;
  margin-left: auto;
  align-items: center;
  gap: 15px;
`;