import { RefObject, useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export const useSize = <T extends HTMLElement>(target: RefObject<T> | null) => {
  const [clientRect, setClientRect] = useState<DOMRect>();
  const [domRect, setDomRect] = useState<DOMRectReadOnly>();
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();

  useLayoutEffect(() => {
    if (target) {
      const element = target.current;
      if (element) {
        setDomRect(element.getBoundingClientRect());
      }
    }
  }, [target]);

  useResizeObserver(target, (entry) => {
    setClientRect(entry.target.getBoundingClientRect());
    setDomRect(entry.contentRect);
    setHeight(entry.contentRect.height);
    setWidth(entry.contentRect.width);
  });

  return {
    clientRect,
    domRect,
    height,
    width
  };
};
