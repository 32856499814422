import { GenericDeleteQuery } from '../common/GenericDeleteQuery';

export class TenantFeatureDeleteCommand extends GenericDeleteQuery<unknown> {
  tenantId: string;
  featureId: number;

  constructor(tenantId: string, featureId: number) {
    super();
    this.tenantId = tenantId;
    this.featureId = featureId;
  }

  public targetUrl(): string {
    return '/features/DeleteTenantFeature';
  }
}
