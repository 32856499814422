import { HeatingScheduleDto } from '@shared/api/models/HeatingSchedule/HeatingScheduleDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class HeatingSchedulesGetBySiteIdQuery extends GetQuery<HeatingScheduleDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/schedules/GetCurrent/${this.siteId}`;
  }
}
