import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { transparentize } from 'polished';
import { HeatmapSpaceDto } from '@dashboard/api/models/HeatmapSpaceDto';
import { getLabelAndScaleColor } from '@dashboard/configs/heatmap/HeatmapConfig';
import { SquareWithValue } from '@dashboard/components/atoms/Square/SquareWithValue';
import { Square } from '@dashboard/components/atoms/Square/Square';
import { OutOfDateCircle } from '@dashboard/components/atoms/Square/OutOfDateCircle';
import { SpaceType_Icon } from '@shared/api/enums/SpaceType/SpaceType_Icon';
import { SpaceType_Color } from '@shared/api/enums/SpaceType/SpaceType_Color';
import { Icon } from '@shared/components/atoms/Icon/Icon';
import { Metric } from '@shared/api/models/Metric/Metric';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

// Adjust the font size depending on the length of the value displayed in the square (and the size of the square when displayed on the Floorplan)
const getFontSize = (displayValue: string | number, size: number, onFloorplan?: boolean): number => {
  let fontSize = 14;
  let factor = 1;

  if (onFloorplan) {
    factor = size / 32;
  }

  const displayLength = displayValue.valueOf().toString().length;

  if (displayLength > 3) {
    fontSize = 12;
  }

  if (displayLength > 4) {
    fontSize = 10;
  }

  return Math.min(fontSize * factor, 16);
};

type PropTypes = {
  space: HeatmapSpaceDto;
  toggleModal: () => void;
  showSpaceIcon: boolean;
  onFloorplan?: boolean;
  size?: number;
  loadingRefetch?: boolean;
  metricType?: MetricType;
}

const HeatmapSquare = ({ space, toggleModal, showSpaceIcon, onFloorplan, size = 36, loadingRefetch, metricType }: PropTypes) => {
  const theme = useTheme();
  const { trackAction } = useAnalytics();
  const { toLocale, getUnit } = useLocalisation();
  const squareSize = `${size}px`;

  const handleToggle = () => {
    toggleModal();
    trackAction('view_space', 'heatmap');
  };

  let metric: Metric | null = null;

  if (space.measurementMeasuredOn && space.measurementValue && space.metricType) {
    metric = {
      metricType: space.metricType,
      measuredOn: space.measurementMeasuredOn,
      value: space.measurementValue
    };
  }

  if (showSpaceIcon) {
    return (
      <SquareWithValue
        size={squareSize}
        color={SpaceType_Color(space.spaceType)}
        onClick={handleToggle}
        style={onFloorplan ? {
          boxShadow: '0px 1px 10px 1px rgb(0 0 0 / 50%)'
        } : {}}
      >
        <Icon
          icon={SpaceType_Icon(space.spaceType)}
          iconSize={`${size * 0.65}px`}
          squareSize={squareSize}
          hideSquare
          iconColor='#fff'
        />
      </SquareWithValue>
    );
  }

  if (loadingRefetch && space.fetchOlderMeasurement) {
    return (
      <Square
        size={squareSize}
        onClick={handleToggle}
      >
        <Loading size='14px' color={theme.palette.text.weak} />
      </Square>
    );
  }

  // The space does not have the selected device model installed
  if (!space.deviceId) {
    return (
      <Square
        size={squareSize}
        onClick={handleToggle}
        style={onFloorplan ? {
          backgroundColor: '#EEF2FC',
          borderColor: '#E2E6F5',
          boxShadow: '0px 1px 10px 1px rgb(0 0 0 / 50%)'
        } : {}}
      >
        <NoDeviceIcon icon={regular('xmark')} />
      </Square>
    );
  }

  if (!metricType) {
    return null;
  }

  const config = getLabelAndScaleColor(metricType, metric, toLocale, getUnit);

  // There is no config if there is no metric and no fallback value defined for the metric type
  if (!config) {
    return (
      <Square
        size={squareSize}
        onClick={handleToggle}
        style={onFloorplan ? {
          backgroundColor: '#EEF2FC',
          borderColor: '#E2E6F5',
          boxShadow: '0px 1px 10px 1px rgb(0 0 0 / 50%)'
        } : {}}
      >
        <DeviceIssueIcon icon={regular('sensor-triangle-exclamation')} />

        {space.deviceIsOffline &&
          <OutOfDateCircle size='11px' />
        }
      </Square>
    );
  }

  // Label, color, and text-color of the square
  const [displayValue, color, textColor] = config;

  return (
    <SquareWithValue
      size={squareSize}
      color={color}
      textColor={textColor}
      onClick={toggleModal}
      style={onFloorplan ? {
        boxShadow: '0px 1px 10px 1px rgb(0 0 0 / 50%)'
      } : {}}
    >
      <DisplayValue
        size={squareSize}
        fontSize={getFontSize(displayValue, size, onFloorplan)}
      >
        {displayValue}
      </DisplayValue>

      {space.deviceIsOffline &&
        <OutOfDateCircle size='11px' />
      }
    </SquareWithValue>
  );
};

export default HeatmapSquare;

const DeviceIssueIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => p.theme.palette.text.weak};
  opacity: 0.7;
`;

const NoDeviceIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => transparentize(0.5, p.theme.palette.text.weak)};
  opacity: 0.7;
`;

const DisplayValue = styled.div<{ size: string; fontSize: number }>`
  width: ${p => p.size};
  height: ${p => p.size};
  font-size: ${p => p.fontSize}px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
`;