import styled from 'styled-components';
import { Link } from 'react-router-dom';
import placeholderImage from '@settings/assets/images/buildingImagePlaceholder.png'
import { transparentize } from 'polished';
import { Asset } from '@shared/api/models/Asset/Asset';
import { useFileFromBlobStorage } from '@shared/hooks/useFileFromBlobStorage';
import { Card } from '@shared/components/molecules/Card/Card';

type PropTypes = {
  asset: Asset;
  createlinkPath: (assetId: number) => string,
};

const AssetListItem = ({ asset, createlinkPath }: PropTypes) => {
  const { blobUri } = useFileFromBlobStorage(asset.imageId);

  return (
    <>
      <StyledLink to={createlinkPath(asset.id)}>
        <Card>
          <FlexContainer>
            <Image src={blobUri ?? placeholderImage} />
            <div>
              <ItemName>
                {asset.name}
              </ItemName>
              <ItemDetails>
                {`
                  ${asset.address.addressLine1},
                  ${asset.address.addressLine2 ? asset.address.addressLine2 + ',' : ''}
                  ${asset.address.city},
                  ${asset.address.postCode}
                `}
              </ItemDetails>
            </div>
          </FlexContainer>
        </Card>
      </StyledLink>
    </>
  );
}

export default AssetListItem;

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.palette.text.medium};

  &:hover > div {
    background-color: ${p => transparentize(0.95, p.theme.palette.primary)};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
`;

const ItemName = styled.span`
  display: block;
  font-weight: 500;
  font-size: 20px;

  ${StyledLink}:hover & {
    color: ${p => p.theme.palette.primary};
  }
`;

const ItemDetails = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
`;

const Image = styled.img`
  object-fit: cover;
  width: 100px;
  height: 60px;
  margin-right: 20px;
  border-radius: 3px;
  border: 1px solid #E2E6F5;
`;