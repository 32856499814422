import { useState } from 'react';
import { IInternalNode, IInternalNodeGroup } from '../CascaderSingleSelect.types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Checkbox } from './Checkbox';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ICascadeLevel<TValue> {
  group: IInternalNodeGroup<TValue>;
  depth: number;
  selectedNode?: IInternalNode<TValue>;
  onChange: (node: IInternalNode<TValue>) => void;
  childOfSelected?: boolean;
  onMobile: boolean;
  activeGroupId?: string;
  setActiveGroupId: (id?: string) => void;
  parentGroup?: IInternalNodeGroup<TValue>;
}

export const CascadeLevel = <TValue,>({ group, depth, selectedNode, childOfSelected, onChange, onMobile, activeGroupId, setActiveGroupId, parentGroup }: ICascadeLevel<TValue>) => {
  const [activeNodeId, setActiveNodeId] = useState<string>();
  const maxDepth = 10;
  const isActive = activeGroupId === group.id;

  /**
   * Called when a node label/row is clicked
   */
  const handleNodeClick = (node: IInternalNode<TValue>, hasChildren: boolean) => {
    if (hasChildren) {
      setActiveNodeId(node.id);
      setActiveGroupId(node.childGroup?.id);
    } else if (!node.disabled) {
      handleNodeSelect(node);
    }
  };

  /**
   * Called when a node label/row is selected
   */
  const handleNodeSelect = (node: IInternalNode<TValue>) => {
    if (node?.selectable !== undefined) {
      onChange(node);
    }
  };

  if (group.nodes.length === 0 || depth >= maxDepth) {
    return null;
  }

  return (
    <>
      <FlexColumn $onMobile={onMobile} activeGroup={isActive}>
        <Header hasBackRow={onMobile && parentGroup !== undefined}>
          <div style={{ width: '100%' }}>
            {onMobile && parentGroup &&
              <BackRow onClick={() => setActiveGroupId(parentGroup?.id)}>
                <BackArrow
                  icon={solid('arrow-left')}
                />

                {parentGroup.header}
              </BackRow>
            }

            <FlexRow>
              <HeaderLabel>
                {group.header}
              </HeaderLabel>
            </FlexRow>
          </div>
        </Header>
        <NodeList>
          {group.nodes.map((node) => {
            const highlightRow = node.id === activeNodeId && !onMobile;
            const hasChildren = node.childGroup !== undefined && node.childGroup.nodes.length > 0;

            return (
              <NodeRow
                key={node.id}
                active={highlightRow}
                hasChildren={hasChildren}
                onClick={() => handleNodeClick(node, hasChildren)}
              >
                <Checkbox
                  node={node}
                  selected={selectedNode === node}
                  onSelect={handleNodeSelect}
                  childOfSelected={childOfSelected}
                />
                <LabelRow>
                  <Label active={highlightRow} disabled={node.disabled}>
                    {node.label}
                  </Label>
                  <RightAligned>
                    <NumNodes active={highlightRow}>
                      {node.childGroup?.nodes && node.childGroup.nodes.length > 0 && `(${node.childGroup.nodes.length})`}
                    </NumNodes>

                    {node.childGroup?.nodes && node.childGroup.nodes.length > 0 &&
                      <Icon
                        icon={solid('chevron-right')}
                        $active={highlightRow}
                      />
                    }
                  </RightAligned>
                </LabelRow>
              </NodeRow>
            );
          })}
        </NodeList>
      </FlexColumn>

      {group.nodes.filter(node => node.id === activeNodeId).map((childNode) => childNode.childGroup && (
        <CascadeLevel
          key={childNode.id}
          group={childNode.childGroup}
          depth={depth + 1}
          selectedNode={selectedNode}
          onChange={onChange}
          childOfSelected={childOfSelected || childNode === selectedNode}
          onMobile={onMobile}
          activeGroupId={activeGroupId}
          setActiveGroupId={setActiveGroupId}
          parentGroup={group}
        />
      ))}
    </>
  );
}


const FlexColumn = styled.div<{ $onMobile: boolean, activeGroup: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 240px;
  
  &:not(:last-child) {
    border-right: 1px solid ${p => p.theme.palette.borders.weak};
  }

  ${p => p.$onMobile && css`
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    height: 300px;
    overflow: hidden;
    transition: all 150ms ease;

    ${p.activeGroup && css`
      opacity: 1;
      visibility: visible;
    `}
  `}
`;

const NodeList = styled.div`
  overflow: hidden auto;
  min-height: 150px;
`;

const Header = styled.div<{ hasBackRow: boolean }>`
  display: flex;
  align-items: center;

  height: 50px;
  flex-shrink: 0;
  padding: 0 12px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0px 5px 4px -2px ${p => p.theme.palette.shadows.medium};
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};

  ${p => p.hasBackRow && css`
    height: 75px;
  `}
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const HeaderLabel = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};

  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div<{ active: boolean, disabled?: boolean }>`
  font-size: 14px;
  color: ${p => p.theme.palette.text.medium};
  padding-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  ${p => p.active && css`
    color: ${p => p.theme.palette.text.medium};
    font-weight: 500;
  `}

  ${p => p.disabled && css`
    color: ${p => p.theme.palette.text.weak};
    font-weight: 400;
  `}
`;

const NodeRow = styled.div<{ active: boolean, hasChildren: boolean; }>`
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  border-left: 2px solid transparent;

  ${p => !p.active && css`
    &:nth-child(even) {
      background-color: ${p => p.theme.palette.tables.evenRow};
    }
    
    &:nth-child(odd) {
      background-color: ${p => p.theme.palette.tables.unevenRow};
    }
    
    &:hover {
      background-color: ${p => p.theme.palette.tables.backgroundHighlight};

      ${p.hasChildren && css`
        background-color: ${p => darken(0.05, p.theme.palette.tables.unevenRow)};
        border-left-color: ${p => p.theme.palette.primary};
      `}
    }
  `}

  ${p => p.active && css`
    background-color: ${p => darken(0.05, p.theme.palette.tables.unevenRow)};
    border-left-color: ${p => p.theme.palette.primary};
  `}
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;;
`;

const RightAligned = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NumNodes = styled.div <{ active: boolean }>`
  margin-left: 5px;
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};

  ${p => p.active && css`
    color: ${p => p.theme.palette.primary};
  `}
`;

const Icon = styled(FontAwesomeIcon) <{ $active: boolean }>`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  color: ${p => p.theme.palette.text.weak};

  ${p => p.$active && css`
    color: ${p => p.theme.palette.primary};
  `}
`;

const BackRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 0 10px 0;

  font-size: 16px;
  line-height: 16px;
  color: ${p => p.theme.palette.text.fair};

  &:hover {
    color: ${(p) => p.theme.palette.primary};
  }
`;

const BackArrow = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  padding: 5px;
  margin-right: 8px;
  border-radius: 50%;
  color: ${p => p.theme.palette.primary};
  border: 1px solid ${(p) => p.theme.palette.borders.medium};
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms ease;

  ${BackRow}:hover & {
    border-color: ${(p) => p.theme.palette.primary};
  }
`;