import styled from 'styled-components';
import UtopiLogo from '@shared/assets/images/utopi-logo.png';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useFileFromBlobStorage } from '@shared/hooks/useFileFromBlobStorage';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { Link } from 'react-router-dom';

export const TopBarTenantLogo = () => {
  const { tenant } = useTenantContext();
  const { blobUri } = useFileFromBlobStorage(tenant.operatorLogoId, BlobStorageContainerType.Shared);

  return (
    <Link to='/'>
      <LogoImage src={blobUri ?? UtopiLogo} />
    </Link>
  );
};

const LogoImage = styled.img`
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 40px;
  flex-shrink: 0;

  @media (min-width: 600px) {
    max-width: 150px;
  }
`;
