import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { IIcon } from './Icon.types';

export const Icon = (props: IIcon) => {
  if (!props.icon) {
    return null;
  }

  return (
    <StyledIcon {...props}>
      {props.icon}
    </StyledIcon>
  );
};

const StyledIcon = styled.div.attrs<IIcon>(p => ({
  style: {
    width: p.squareSize ?? '40px',
    height: p.squareSize ?? '40px'
  }
})) <IIcon>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: ${(p) => p.iconColor ?? p.theme.palette.primary};
  background-color: ${(p) => p.squareColor ?? p.theme.palette.primary};
  border: 1px solid ${(p) => (p.hideBorder && 'transparent') || p.squareColor || p.theme.palette.primary};
  border-radius: 4px;

  cursor: ${(p) => (p.onClick ? 'pointer' : 'inherit')};
  transition: all 0.2s;

  svg {
    width: ${(p) => p.iconSize ?? '12px'};
    height: ${(p) => p.iconSize ?? '12px'};

    * {
      stroke: ${(p) => p.iconColor ?? p.theme.palette.text.onPrimary};

      ${(p) => p.iconFillColor && css`
        fill: ${p.iconFillColor};
      `}
    }

    text {
      fill: ${(p) => p.iconColor ?? p.theme.palette.text.onPrimary};
    }

    rect {
      ${(p) => p.iconFillColor && css`
        fill: ${p.iconFillColor};
      `}
    }
  }

  ${(p) => p.outline && css`
      border: 1px solid ${p.squareColor ?? p.theme.palette.primary};
      background-color: transparent;

      svg {
        path {
          stroke: ${p.iconColor ?? p.theme.palette.primary};
        }
      }

      &:hover {
        background-color: ${p.squareColor ?? p.theme.palette.primary};

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    `}

  ${(p) => p.outlineOnHover && css`
      &:hover {
        border: 1px solid ${p.squareColor ?? p.theme.palette.primary};
        background-color: transparent;

        svg {
          path {
            stroke: ${p.squareColor ?? p.theme.palette.primary};
          }
        }
      }
    `}

  ${(p) => p.shadowBottom && css`
      box-shadow: 0 10px 8px -4px ${transparentize(0.6, p.squareColor ?? p.theme.palette.primary)};
    `}

  ${(p) => p.shadowOnHover && css`
      &:hover {
        box-shadow: 0px 3px 8px ${transparentize(0.6, p.squareColor ?? p.theme.palette.primary)};
      }
    `}

  ${(p) => p.transitionOnHover && css`
      &:hover {
        transition: all 150ms ease-out;
        transform: translate3d(0, -3px, 0);
      }
    `}

  ${(p) => p.hideSquare && css`
    &, &:hover {
      width: min-content;
      height: max-content;
      background-color: transparent;
      border: none;
    }
  `}
`;
