import { useTranslation } from 'react-i18next';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import styled from 'styled-components';
import FullScreenToggle from '@shared/components/atoms/FullScreenToggle/FullScreenToggle';
import SpacePage_Devices from './Devices/SpacePage_Devices';
import SpacePage_Tabs from './Tabs/SpacePage_Tabs';

const SpacePage = () => {
  const { t } = useTranslation();

  return (
    <PaddedContainer>
      <FlexRow>
        <Title
          text={t('Devices', { ns: 'common' })}
        />

        <FullScreenToggle styles={{ marginLeft: 'auto' }} />
      </FlexRow>

      <FlexColumn>
        <SpacePage_Devices />
        <SpacePage_Tabs />
      </FlexColumn>
    </PaddedContainer>
  );
};

export default SpacePage;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;