import { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ITabsBar {
  activeTab: number;
  setActiveTab: (index: number, tabKey: string) => void;
}

const TabsBar = ({ activeTab, setActiveTab }: ITabsBar) => {
  const { t } = useTranslation();
  const lineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  // Move the line when the selected tab changes
  useEffect(() => {
    const tab = tabRefs.current[activeTab];

    if (tab && lineRef.current) {
      lineRef.current.style.left = tab.offsetLeft + 'px';
      lineRef.current.style.width = tab.clientWidth + 'px';
    }
  }, [activeTab]);

  const tabs = useMemo(() => [
    {
      key: 'create',
      label: t('Create', { ns: 'common' }),
    },
    {
      key: 'templates',
      label: t('Templates', { ns: 'common' }),
    },
    {
      key: 'automation',
      label: t('Automation', { ns: 'common' }),
    }
  ], [t]);

  return (
    <Tabs>
      {tabs.map((tab, i) => (
        <Tab
          key={tab.label}
          ref={element => tabRefs.current[i] = element}
          isActive={activeTab === i}
          onClick={() => setActiveTab(i, tab.key)}
        >
          {tab.label}
        </Tab>
      ))}

      <TabLine ref={lineRef} />
    </Tabs>
  );
};

export default TabsBar;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const Tab = styled.div<{ isActive: boolean }>`
  width: 33%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;

  ${p => p.isActive && css`
    color: ${p => p.theme.text.primary};
  `}
`;

const TabLine = styled.div`
  height: 2px;
  background-color: ${p => p.theme.palette.primary};
  transition: left 300ms ease, width 300ms ease;

  position: absolute;
  bottom: 0px;
  left: 0;
`;