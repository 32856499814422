import styled from 'styled-components';
import { Route } from '@shared/types/Route';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { BreadcrumbHeight } from '@src/constants/LayoutConstants';
import Dropdown from './Dropdown';
import { GenericObject } from '@shared/utils/ObjectUtils';

type PropTypes = {
  routes?: Route[];
  customProps?: GenericObject;
}

const TabBar = ({ routes, customProps }: PropTypes) => {
  const { t } = useTranslation();
  const location = useLocation();
  const lineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  // Move the line when the selected tab changes
  useEffect(() => {
    if (!lineRef.current) {
      return;
    }

    const tabs = document.querySelectorAll('#tab-container .tab');

    for (const tabElement of Object.values(tabs)) {
      const decendantLinks = tabElement.querySelectorAll('a');

      if (Object.values(decendantLinks).some(x => x.classList.contains('active'))) {
        const tab = tabElement as HTMLElement;
        lineRef.current.style.left = tab.offsetLeft + 'px';
        lineRef.current.style.width = tab.clientWidth + 'px';
        break;
      }
    }
  }, [location]);

  return (
    <Wrapper id='tab-container'>
      {routes?.map((route, i) => {
        if (!route.link?.label) {
          return null;
        }

        if (route.link.showChildrenInDropdown && route.children && route.children.length > 0) {
          return (
            <Tab
              className='tab'
              key={route.link.label}
              ref={element => tabRefs.current[i] = element}
            >
              <Dropdown route={route} />
            </Tab>
          );
        }

        return (
          <Tab
            className='tab'
            key={route.link.label}
            ref={element => tabRefs.current[i] = element}
          >
            <StyledLink
              link={route.link}
              key={route.link.label}
              customProps={customProps}
            >
              {t(route.link.label, { ns: 'routes' })}
            </StyledLink>
          </Tab>
        );
      })}

      <TabLine ref={lineRef} />
    </Wrapper >
  );
};

export default TabBar;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${BreadcrumbHeight}px;
  display: flex;
`;

const Tab = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
`;

const StyledLink = styled(ProtectedLink)`
  padding: 15px 16px;
  cursor: pointer;
  color: ${p => p.theme.text.secondary} !important;

  &.active {
    color: ${p => p.theme.text.primary} !important;
  }
`;

const TabLine = styled.div`
  height: 2px;
  background-color: ${p => p.theme.palette.primary};
  transition: left 300ms ease, width 300ms ease;

  position: absolute;
  bottom: 0;
  left: 0;
`;