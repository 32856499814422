import EsgMetricType from '@dashboard/api/enums/EsgMetricType/EsgMetricType';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { ICheckboxSelectGroup } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect.types';
import { LocalisationType } from '@shared/localisation/localisation';

export const getEsgExplorerMetricOptions = (localisation: LocalisationType): ICheckboxSelectGroup<EsgMetricType>[] => {
  return [
    {
      label: 'Emissions',
      icon: <FontAwesomeIcon icon={solid('leaf')} color='#44A901' />,
      options: [
        { label: EsgMetricType.Emissions, value: EsgMetricType.Emissions }
      ]
    },
    {
      label: 'Electricity',
      icon: <FontAwesomeIcon icon={solid('bolt-lightning')} color='#FFB500' />,
      options: [
        { label: EsgMetricType.ElectricityTotalKwh, value: EsgMetricType.ElectricityTotalKwh },
        { label: EsgMetricType.ElectricityDailyAverage, value: EsgMetricType.ElectricityDailyAverage },
        { label: EsgMetricType.ElectricitySqmDailyAverage, value: EsgMetricType.ElectricitySqmDailyAverage },
        { label: EsgMetricType.ElectricityVarianceFromAverage, value: EsgMetricType.ElectricityVarianceFromAverage },
        { label: EsgMetricType.ElectricityVarianceFromMinimum, value: EsgMetricType.ElectricityVarianceFromMinimum }
      ]
    },
    {
      label: 'Heating',
      icon: <FontAwesomeIcon icon={solid('fire-flame-curved')} color='#FF821B' />,
      options: [
        { label: EsgMetricType.HeatingTotalKwh, value: EsgMetricType.HeatingTotalKwh },
        { label: EsgMetricType.HeatingDailyAverage, value: EsgMetricType.HeatingDailyAverage },
        { label: EsgMetricType.HeatingSqmDailyAverage, value: EsgMetricType.HeatingSqmDailyAverage },
        { label: EsgMetricType.HeatingVarianceFromAverage, value: EsgMetricType.HeatingVarianceFromAverage },
        { label: EsgMetricType.HeatingVarianceFromMinimum, value: EsgMetricType.HeatingVarianceFromMinimum }
      ]
    },
    {
      label: 'Cooling',
      icon: <FontAwesomeIcon icon={solid('snowflake')} color='#00c3ff' />,
      options: [
        { label: EsgMetricType.CoolingTotalKwh, value: EsgMetricType.CoolingTotalKwh },
        { label: EsgMetricType.CoolingDailyAverage, value: EsgMetricType.CoolingDailyAverage },
        { label: EsgMetricType.CoolingSqmDailyAverage, value: EsgMetricType.CoolingSqmDailyAverage },
        { label: EsgMetricType.CoolingVarianceFromAverage, value: EsgMetricType.CoolingVarianceFromAverage },
        { label: EsgMetricType.CoolingVarianceFromMinimum, value: EsgMetricType.CoolingVarianceFromMinimum }
      ]
    },
    {
      label: 'Water',
      icon: <FontAwesomeIcon icon={solid('tint')} color='#1B98FF' />,
      options: [
        { label: EsgMetricType.WaterTotalVolume, value: EsgMetricType.WaterTotalVolume },
        { label: EsgMetricType.WaterDailyAverage, value: EsgMetricType.WaterDailyAverage },
        { label: EsgMetricType.WaterSqmDailyAverage, value: EsgMetricType.WaterSqmDailyAverage },
        { label: EsgMetricType.WaterVarianceFromAverage, value: EsgMetricType.WaterVarianceFromAverage },
        { label: EsgMetricType.WaterVarianceFromMinimum, value: EsgMetricType.WaterVarianceFromMinimum }
      ]
    },
    {
      label: `Gas (${localisation.metrics[MetricType.GasVolume].unit})`,
      icon: <FontAwesomeIcon icon={solid('fire')} color='#00C851' />,
      options: [
        { label: EsgMetricType.GasTotalVolume, value: EsgMetricType.GasTotalVolume },
        { label: EsgMetricType.GasDailyAverage, value: EsgMetricType.GasDailyAverage },
        { label: EsgMetricType.GasSqmDailyAverage, value: EsgMetricType.GasSqmDailyAverage }
      ]
    },
    {
      label: 'Gas (kWh)',
      icon: <FontAwesomeIcon icon={solid('fire')} color='#00C851' />,
      options: [
        { label: EsgMetricType.GasTotalKwh, value: EsgMetricType.GasTotalKwh },
        { label: EsgMetricType.GasDailyAverageKwh, value: EsgMetricType.GasDailyAverageKwh },
        { label: EsgMetricType.GasSqmDailyAverageKwh, value: EsgMetricType.GasSqmDailyAverageKwh }
      ]
    },
    {
      label: 'Comfort',
      icon: <FontAwesomeIcon icon={solid('spa')} color='#00DED3' />,
      options: [
        { label: EsgMetricType.TemperatureMin, value: EsgMetricType.TemperatureMin },
        { label: EsgMetricType.TemperatureMax, value: EsgMetricType.TemperatureMax },
        { label: EsgMetricType.TemperatureAvg, value: EsgMetricType.TemperatureAvg },
        { label: EsgMetricType.HumidityMin, value: EsgMetricType.HumidityMin },
        { label: EsgMetricType.HumidityMax, value: EsgMetricType.HumidityMax },
        { label: EsgMetricType.HumidityAvg, value: EsgMetricType.HumidityAvg },
        { label: EsgMetricType.CO2Min, value: EsgMetricType.CO2Min },
        { label: EsgMetricType.CO2Max, value: EsgMetricType.CO2Max },
        { label: EsgMetricType.CO2Avg, value: EsgMetricType.CO2Avg },
        { label: EsgMetricType.NoiseMax, value: EsgMetricType.NoiseMax }
      ]
    },
  ];
}

export const EsgExplorerMetricsSortOrder = [
  EsgMetricType.Emissions,
  EsgMetricType.ElectricityTotalKwh,
  EsgMetricType.ElectricityDailyAverage,
  EsgMetricType.ElectricitySqmDailyAverage,
  EsgMetricType.ElectricityVarianceFromAverage,
  EsgMetricType.ElectricityVarianceFromMinimum,
  EsgMetricType.HeatingTotalKwh,
  EsgMetricType.HeatingDailyAverage,
  EsgMetricType.HeatingSqmDailyAverage,
  EsgMetricType.HeatingVarianceFromAverage,
  EsgMetricType.HeatingVarianceFromMinimum,
  EsgMetricType.WaterTotalVolume,
  EsgMetricType.WaterDailyAverage,
  EsgMetricType.WaterSqmDailyAverage,
  EsgMetricType.WaterVarianceFromAverage,
  EsgMetricType.WaterVarianceFromMinimum,
  EsgMetricType.GasTotalVolume,
  EsgMetricType.GasDailyAverage,
  EsgMetricType.GasSqmDailyAverage,
  EsgMetricType.GasTotalKwh,
  EsgMetricType.GasDailyAverageKwh,
  EsgMetricType.GasSqmDailyAverageKwh,
  EsgMetricType.TemperatureMin,
  EsgMetricType.TemperatureMax,
  EsgMetricType.TemperatureAvg,
  EsgMetricType.HumidityMin,
  EsgMetricType.HumidityMax,
  EsgMetricType.HumidityAvg,
  EsgMetricType.CO2Min,
  EsgMetricType.CO2Max,
  EsgMetricType.CO2Avg,
  EsgMetricType.NoiseMax
];

export const MetricTypesToCalculateEsgMetric: { [key in EsgMetricType]: MetricType[]; } = {
  Emissions: [MetricType.CarbonIntensityElectricity],
  ElectricityTotalKwh: [MetricType.ElectricityKwhDelta],
  ElectricityDailyAverage: [MetricType.ElectricityKwhDelta],
  ElectricitySqmDailyAverage: [MetricType.ElectricityKwhDelta],
  ElectricityVarianceFromAverage: [MetricType.ElectricityKwhDelta],
  ElectricityVarianceFromMinimum: [MetricType.ElectricityKwhDelta],
  HeatingTotalKwh: [MetricType.HeatingKwhDelta],
  HeatingDailyAverage: [MetricType.HeatingKwhDelta],
  HeatingSqmDailyAverage: [MetricType.HeatingKwhDelta],
  HeatingVarianceFromAverage: [MetricType.HeatingKwhDelta],
  HeatingVarianceFromMinimum: [MetricType.HeatingKwhDelta],
  CoolingTotalKwh: [MetricType.CoolingKwhDelta],
  CoolingDailyAverage: [MetricType.CoolingKwhDelta],
  CoolingSqmDailyAverage: [MetricType.CoolingKwhDelta],
  CoolingVarianceFromAverage: [MetricType.CoolingKwhDelta],
  CoolingVarianceFromMinimum: [MetricType.CoolingKwhDelta],
  WaterTotalVolume: [MetricType.WaterVolumeDelta],
  WaterDailyAverage: [MetricType.WaterVolumeDelta],
  WaterSqmDailyAverage: [MetricType.WaterVolumeDelta],
  WaterVarianceFromAverage: [MetricType.WaterVolumeDelta],
  WaterVarianceFromMinimum: [MetricType.WaterVolumeDelta],
  GasTotalVolume: [MetricType.GasVolumeDelta],
  GasDailyAverage: [MetricType.GasVolumeDelta],
  GasSqmDailyAverage: [MetricType.GasVolumeDelta],
  GasTotalKwh: [MetricType.GasVolumeDelta],
  GasDailyAverageKwh: [MetricType.GasVolumeDelta],
  GasSqmDailyAverageKwh: [MetricType.GasVolumeDelta],
  TemperatureMin: [MetricType.Temperature],
  TemperatureMax: [MetricType.Temperature],
  TemperatureAvg: [MetricType.Temperature],
  HumidityMin: [MetricType.Humidity],
  HumidityMax: [MetricType.Humidity],
  HumidityAvg: [MetricType.Humidity],
  CO2Min: [MetricType.CO2],
  CO2Max: [MetricType.CO2],
  CO2Avg: [MetricType.CO2],
  NoiseMax: [MetricType.Noise]
};