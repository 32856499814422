import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Title } from '@shared/components/atoms/Title/Title';
import { useTranslation } from 'react-i18next';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import DeviceByStatusDto from '@dashboard/api/models/DeviceByStatusDto';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { DevicesGetBySiteIdAndStatusQuery } from '@dashboard/api/queries/devices/DevicesGetBySiteIdAndStatusQuery';
import { format, formatDistance } from 'date-fns';
import { useNavigate } from 'react-router';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { useParams } from 'react-router-dom';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { DeviceStatus } from '@dashboard/api/enums/DeviceStatus';
import { useApi } from '@shared/hooks/useApi';
import styled from 'styled-components';
import DeviceStatusInfoModal from './DeviceStatusInfoModal';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { ThemeColored } from '@src/components/shared/ThemeColoredSpan/ThemeColoredSpan';
import CsvDevicesInactiveGetBySiteIdQuery from '@dashboard/api/queries/exports/CsvDevicesInactiveGetBySiteIdQuery';
import useCsvExport from '@dashboard/hooks/useCsvExport';
import { Button } from '@shared/components/atoms/Button/Button';

const DevicesByStatusList = () => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { site } = useSiteContext();
  const { getDisplayString } = useDeviceConfigContext();
  const { status } = useParams<{ status: string }>();
  const navigate = useNavigate();
  const { execute, loading } = useApi();
  const [devices, setDevices] = useState<DeviceByStatusDto[]>([]);
  const { handleCsvExport: exportDevices, loading: loadingDevicesExport } = useCsvExport();

  useEffect(() => {
    const fetchDevices = async () => {
      if (status === DeviceStatus.Online || status === DeviceStatus.Offline) {
        const devices = await execute({
          query: new DevicesGetBySiteIdAndStatusQuery(site.id, status)
        });

        if (devices) {
          setDevices(devices);
        }
      } else {
        navigate('./../..');
      }
    };

    fetchDevices();
  }, [site, status, execute, navigate]);

  const handleRowClick = useCallback((row: DeviceByStatusDto) => {
    trackAction(`view_${status}_device`, 'overview_live_updates');
    navigate(`../building/${row.buildingId}/floor/${row.floorId}/space/${row.spaceId}/device/${row.id}`);
  }, [status, trackAction, navigate]);

  const tableColumns: ITableColumn<DeviceByStatusDto>[] = useMemo(() => ([
    {
      label: t('DeviceCard.Name', { ns: 'molecules' }),
      key: 'friendlyName',
      width: 2
    },
    {
      label: t('DeviceCard.Building', { ns: 'molecules' }),
      key: 'buildingName',
      width: 2
    },
    {
      label: t('DeviceCard.Space', { ns: 'molecules' }),
      key: 'spaceName',
      width: 2
    },
    {
      label: t('DeviceCard.DeviceModel', { ns: 'molecules' }),
      key: 'deviceModel',
      width: 2,
      displayFormat: ({ deviceModel }) => getDisplayString(deviceModel),
    },
    {
      label: t('DeviceCard.DeviceIdentifier', { ns: 'molecules' }),
      key: 'deviceIdentifier',
      width: 2,
    },
    {
      label: t('DeviceCard.LastMeasuredOn', { ns: 'molecules' }),
      key: 'lastMeasuredOn',
      width: 2,
      sortFormat: device => device.lastMeasuredOn && new Date(device.lastMeasuredOn),
      displayFormat: device => device.lastMeasuredOn ? format(new Date(device.lastMeasuredOn), 'dd MMM yyyy') : 'Never',
      displaySuffix: device => device.lastMeasuredOn ? `(${formatDistance(new Date(device.lastMeasuredOn), new Date(), { addSuffix: true })})` : '',
    }
  ]), [getDisplayString, t]);

  const handleDevicesExport = async () => {
    if (site.id === undefined) {
      return;
    }

    const query = new CsvDevicesInactiveGetBySiteIdQuery(site.id);
    const fileName = `${site.name}${t('DeviceCard.FileNameDeviceStatus', { ns: 'molecules' })}.csv`
    exportDevices(query, fileName);
  };

  return (
    <PaddedContainer>
      <BackButton
        label={t('BackToOverview', { ns: 'navigation' })}
        url='./../..'
      />

      <FlexRow>
        <Title
          text={<>{t('DevicesOfflineList.' + status, { ns: 'molecules' })} <ThemeColored>({devices.length})</ThemeColored></>}
        />

        <DeviceStatusInfoModal iconStyles={{ marginLeft: '9px' }} />

        {status === DeviceStatus.Offline &&
          <Button
            label={t('DeviceCard.ExportOfflineDevices', { ns: 'molecules' })}
            onClick={handleDevicesExport}
            loading={loadingDevicesExport}
            style={{ marginLeft: 'auto' }}
          />
        }
      </FlexRow>

      <Table
        columns={tableColumns}
        records={devices}
        recordKey="id"
        emptyMessage={t('DeviceCard.NoDevicesFound', { ns: 'molecules' })}
        defaultSortColumn="friendlyName"
        onRowClick={handleRowClick}
        loading={loading}
        cardEffect
        fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 270}
      />
    </PaddedContainer>
  );
};

export default DevicesByStatusList;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;