import { transparentize } from 'polished';
import { useEffect, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, sortBy } from 'lodash';
import { substringNCharWithEllipsis } from '@shared/utils/StringUtils';
import { IModalListSelectGroup, IModalListSelect } from './ModalListSelect.types';
import { useModal } from '@shared/hooks/useModal';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { Button } from '@shared/components/atoms/Button/Button';
import { faArrowUpRightAndArrowDownLeftFromCenter, faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { SelectField } from '@shared/components/atoms/SelectField/SelectField';

//#region ModalListSelect component

export const ModalListSelect = <TValue,>({ options, onChange, modalWidth, title, placeholder, loading, disabled }: IModalListSelect<TValue>) => {
  const [optionGroups, setOptionGroups] = useState<IModalListSelectGroup<TValue>[]>([]);
  const { isOpen, toggle, ref } = useModal({});
  const theme = useTheme();

  useEffect(() => {
    setOptionGroups(sortBy(options, x => x.sortOrder));
  }, [options]);

  const handleChange = (groups: IModalListSelectGroup<TValue>[]) => {
    onChange(groups.flatMap(g => g.subGroups.flatMap(sg => sg.options)).filter(x => x.selected).map(x => x.value));
  };

  const onSelect = (groupIndex: number, subGroupIndex: number, optionIndex: number) => {
    const clonedOptions = cloneDeep(optionGroups);
    clonedOptions[groupIndex] = {
      ...clonedOptions[groupIndex],
      subGroups: clonedOptions[groupIndex].subGroups.map((sg, i) => i !== subGroupIndex ? sg : ({
        ...sg,
        options: sg.options.map((option, j) => ({
          ...option,
          selected: j === optionIndex ? !option.selected : option.selected
        }))
      }))
    };

    setOptionGroups(clonedOptions);
    handleChange(clonedOptions);
  };

  const setAllTo = (state: boolean) => {
    const clonedOptions = cloneDeep(optionGroups)
      .map(group => ({
        ...group,
        subGroups: group.subGroups.map(sg => ({
          ...sg,
          options: sg.options.map(opt => ({
            ...opt,
            selected: state
          }))
        }))
      }));

    setOptionGroups(clonedOptions);
    handleChange(clonedOptions);
  };

  const selectedOptionLabels = optionGroups.flatMap(group => group.subGroups.flatMap(sg => sg.options)).filter(x => x.selected).map(x => x.label);

  return (
    <Container>
      <Modal
        ref={ref}
        isOpen={isOpen}
        plainModal
        width={modalWidth}
        modalStyles={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
        dialogStyles={{ boxShadow: `0px 2px 12px 1px ${theme.palette.shadows.strong}` }}
      >
        <ModalContent
          groups={optionGroups}
          onSelect={onSelect}
          setAllTo={setAllTo}
          toggleModal={toggle}
          title={title}
        />
      </Modal>

      <SelectField
        disabled={disabled}
        onClick={() => {
          !disabled && toggle();
        }}
      >
        <ScrollContainer>
          {selectedOptionLabels.length === 0 &&
            <Placeholder>
              {placeholder}
            </Placeholder>
          }

          {loading &&
            <Loading
              size="15px"
              color="grey"
              style={{ margin: '6px 0 0 auto' }}
            />
          }

          {selectedOptionLabels.map((label, i) => (
            <SelectedLabel key={i}>
              {substringNCharWithEllipsis(label, 20)}
            </SelectedLabel>
          ))}
        </ScrollContainer>

        {!loading && !disabled &&
          <RightAligned>
            <OpenIcon icon={faArrowUpRightAndArrowDownLeftFromCenter} />
          </RightAligned>
        }
      </SelectField>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  margin-right: 1px;
  padding: 5px 0;
`;

const Placeholder = styled.div`
  font-size: 14px;
  margin-right: 8px;
  margin-left: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedLabel = styled.div`
  height: max-content;
  min-width: max-content;
  padding: 1px 7px;

  font-size: 14px;
  font-weight: 500;

  border-radius: 5px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};
  transition: all 150ms ease;

  &:first-child {
    margin-left: 10px;
  }

  &:last-child {
    margin-right: 5px;
  }

  &:not(:first-child) {
    margin-left: 5px;
  }
`;

const RightAligned = styled.div`
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${p => p.theme.palette.borders.medium};
  height: 18px;
  width: 28px;
`;

const OpenIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => transparentize(0.15, p.theme.palette.text.weak)};
  margin-left: 5px;
`;

//#endregion

//#region Dropdown component

interface IModalContent<TValue> {
  groups: IModalListSelectGroup<TValue>[];
  onSelect: (groupIndex: number, subGroupIndex: number, optionIndex: number) => void;
  setAllTo: (state: boolean) => void;
  toggleModal?: () => void;
  title?: string;
}

/**
 * Popover component
 */
const ModalContent = <TValue,>({ groups, onSelect, setAllTo, toggleModal, title }: IModalContent<TValue>) => {
  return (
    <FlexColumn>
      <HeaderRow>
        {title &&
          <FlexRow>
            <Title>
              {title}
            </Title>
            <CloseModalIcon icon={faXmark} onClick={toggleModal} />
          </FlexRow>
        }

        <FlexRow style={{ gap: '15px', marginTop: title ? '20px' : 0 }}>
          <HeaderButton onClick={() => setAllTo(true)}>
            <SelectClearIcon icon={faSquareCheck} />
            Select All
          </HeaderButton>
          <HeaderButton onClick={() => setAllTo(false)}>
            <SelectClearIcon icon={faSquare} />
            Clear
          </HeaderButton>

          {!title &&
            <CloseModalIcon icon={faXmark} onClick={toggleModal} />
          }
        </FlexRow>
      </HeaderRow>

      <OptionsWrapper>
        {groups.map((group, i) => (
          <div key={i}>
            <GroupLabel first={i === 0}>
              <div style={{ display: 'flex' }}>
                {group.icon &&
                  <div style={{ width: '25px' }}>{group.icon}</div>
                }

                {group.label}
              </div>
            </GroupLabel>
            {group.subGroups.map((subGroup, j) => (
              <div key={j}>
                {subGroup.label &&
                  <GroupSubLabel>
                    {subGroup.label}
                  </GroupSubLabel>
                }

                {subGroup.options.map((option, k) => (
                  <Option key={k} selected={option.selected} onClick={() => onSelect(i, j, k)}>
                    <Checkbox selected={option.selected}>
                      {option.selected &&
                        <CheckIcon icon={faCheck} />
                      }
                    </Checkbox>

                    <FlexRow>
                      <Label>
                        {option.label}
                      </Label>
                    </FlexRow>
                  </Option>
                ))}
              </div>
            ))}
          </div>
        ))}
      </OptionsWrapper>

      <FooterRow>
        <Button
          tertiary
          label="Apply"
          onClick={toggleModal}
          style={{ marginLeft: 'auto' }}
        />
      </FooterRow>
    </FlexColumn>
  );
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionsWrapper = styled.div`
  padding: 0px 16px 10px 16px;
  overflow: auto;
  height: 500px;
`;

const HeaderRow = styled.div`
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
  box-shadow: 0px 5px 4px -2px ${p => p.theme.palette.shadows.medium};
  padding: 16px;
`;

const FooterRow = styled.div`
  border-top: 1px solid ${p => p.theme.palette.borders.medium};
  box-shadow: 0px -5px 4px -3px ${p => p.theme.palette.shadows.medium};
  padding: 16px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

const HeaderButton = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${p => p.theme.palette.text.medium};
  cursor: pointer;
`;

const GroupLabel = styled.div<{ first: boolean }>`
  font-size: 16px;
  font-weight: 500;
  padding: 10px 5px 5px 5px;
  margin: 7px -5px 0 -5px;
  color: ${p => p.theme.palette.text.medium};

  ${p => !p.first && css`
    border-top: 1px solid ${p => p.theme.palette.borders.weak};
  `}
`;

const GroupSubLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0;
  color: ${p => p.theme.palette.text.fair};
`;

const Option = styled.div<{ selected?: boolean }>`
  display: flex;
  padding: 7px 0;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.select.hover};
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 11px;
  color: ${p => p.theme.palette.text.onPrimary};
`;

const Checkbox = styled.div<{ selected?: boolean }>`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border: 1px solid ${p => p.theme.palette.cascader.checkboxBorder};
  border-radius: 2px;
  margin-top: 1px;
  margin-right: 8px;
  cursor: pointer;
  transition: border-color 150ms ease;

  display: flex;
  justify-content: center;
  align-items: center;

  ${p => !p.selected && css`
    &:hover {
      border-color: ${p => transparentize(0.3, p.theme.palette.primary)};
    }
  `}

  ${p => p.selected && css`
    border-color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.primary};
  `}
`;

const CloseModalIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  margin-left: auto;

  &:hover, &:focus {
    color: ${p => p.theme.palette.primary};
  }
`;

const SelectClearIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => p.theme.palette.cascader.checkboxBorder};
  cursor: pointer;
  margin-left: auto;
  
  ${HeaderButton}:hover & {
    color: ${p => transparentize(0.2, p.theme.palette.primary)};
  }
`;

//#endregion