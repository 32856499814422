import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const BetaLabel = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {t('Beta', { ns: 'common' }).toUpperCase()}
    </Wrapper>
  );
};

export default BetaLabel;

const Wrapper = styled.div`
  font-size: 10px;
  font-weight: 500;
  border-radius: 20px;
  color: ${p => p.theme.primary.main};
  background-color: ${p => p.theme.primary.background};
  padding: 4px 8px;
`;