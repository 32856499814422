import { ReportHistoricSeriesDto } from '@dashboard/api/models/ReportEnergyConsumptionDto';
import { getAbbreviatedMonthName } from '@shared/utils/DateUtils';
import { format, subYears } from 'date-fns';
import { transparentize } from 'polished';
import styled from 'styled-components';

interface IMonthlyConsumptionChartLegend {
  reportDate: Date;
  historicSeriesData: ReportHistoricSeriesDto[];
  color: string;
}

const MonthlyConsumptionChartLegend = ({ reportDate, historicSeriesData, color }: IMonthlyConsumptionChartLegend) => {
  return (
    <Wrapper>
      <Row>
        <Column style={{ width: '13mm' }} />

        {historicSeriesData.map((x, i) => (
          <Column key={i}>
            {getAbbreviatedMonthName(x.month)}
          </Column>
        ))}
      </Row>
      <Row>
        <Column style={{ fontWeight: 500, width: '13mm' }}>
          <Circle color={color} />
          <div>{format(reportDate, 'yyyy')}</div>
        </Column>

        {historicSeriesData.map((x, i) => (
          <Column key={i}>
            {x.currentYearTotal !== null
              ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(x.currentYearTotal)
              : <Weak>--</Weak>
            }
          </Column>
        ))}
      </Row>
      <Row>
        <Column style={{ fontWeight: 500, width: '13mm' }}>
          <Circle color={transparentize(0.75, color)} />
          <div>{format(subYears(reportDate, 1), 'yyyy')}</div>
        </Column>

        {historicSeriesData.map((x, i) => (
          <Column key={i}>
            {x.previousYearTotal !== null
              ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(x.previousYearTotal)
              : <Weak>--</Weak>
            }
          </Column>
        ))}
      </Row>
    </Wrapper>
  );
};

export default MonthlyConsumptionChartLegend;

const Wrapper = styled.div`
  display: none;
  width: 100%;
  color: ${p => p.theme.palette.printStyles.text.fair};
  margin-bottom: 8px;

  @media print { 
    display: block;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 5px;
  padding: 0px 10px 3px 14px;
  overflow: hidden;
`;

const Column = styled.div`
  width: 12.25mm;
  flex-shrink: 0;
  font-size: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const Weak = styled.span`
  color: ${p => p.theme.palette.printStyles.text.weak};
`;

const Circle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${p => p.color};
  flex-shrink: 0;
  margin-right: 4px;
`;