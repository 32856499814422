import React from 'react';
import styled, { css } from 'styled-components';
import ReportSectionHeading from '../ReportSectionHeading';
import { useTranslation } from 'react-i18next';
import EsgReportDto from '@dashboard/api/models/EsgReportDto';
import ReportMetricType from '@dashboard/api/enums/ReportMetricType';
import { ReportAggregateCategory } from '@dashboard/api/enums/ReportAggregateCategory';
import EnergyConsumptionWidgetWrapper from './EnergyConsumptionWidgetWrapper';
import { useNumColumns } from './hooks/useNumColumns';
import { TFunction } from 'i18next';
import { MAX_NUMBER_OF_ENERGY_CONSUMPTION_WIDGETS_ON_PAGE } from '../useEsgReportPageHeaders';
import EnergyWidgetCategoryGroup from './EnergyWidgetCategoryGroup';

const getCategoryLabel = (aggregationCategory: string, t: TFunction) => {
  switch (aggregationCategory) {
    case (ReportAggregateCategory.Site):
      return t('Site', { ns: 'assets' });
    case (ReportAggregateCategory.Residential || ReportAggregateCategory.Apartment):
      return t('ESG.Residential', { ns: 'molecules' });
    case (ReportAggregateCategory.Landlord || ReportAggregateCategory.Common):
      return t('ESG.Landlord', { ns: 'molecules' });
    default: return t('ESG.Building', { ns: 'molecules' });
  }
}

interface IEnergyConsumptionWidgets {
  report: EsgReportDto;
  inPrintMode: boolean;
  pageHeaders: JSX.Element[];
}

const EnergyConsumptionWidgets = ({ report, inPrintMode, pageHeaders }: IEnergyConsumptionWidgets) => {
  const { t } = useTranslation();
  const { ref, numColumns } = useNumColumns();
  const showEnergyWidgets = report.energyConsumptionMeasurements.length > 0;
  const totalBuildingArea = report.metadataMeasurements.find(x => x.metricType === ReportMetricType.TotalAreaM3)?.value;

  if (!showEnergyWidgets) {
    return null;
  }

  let pageHeaderIndex = 0;

  return (
    <div ref={ref}>
      {!inPrintMode &&
        <ReportSectionHeading label={t('ESG.EnergyPerformance', { ns: 'molecules' })} />
      }

      {report.energyConsumptionMeasurements.map((dto, i) => {
        pageHeaderIndex += 1;

        return (
          <div key={i}>

            {pageHeaders[pageHeaderIndex - 1]}

            {inPrintMode &&
              <ReportSectionHeading label={t('ESG.EnergyPerformance', { ns: 'molecules' })} printSubLabel={`- ${dto.aggregationCategory}`} />
            }

            <EnergyWidgetCategoryGroup
              key={`widget-group-${i}`}
              label={getCategoryLabel(dto.aggregationCategory, t)}
              open={i === 0}
              inPrintMode={inPrintMode}
            >
              <FlexRow>
                {dto.energyConsumptionUsageData.map((data, j) => {
                  // Render a page header after every 3rd widget
                  const onNewPage = j !== 0 && j % MAX_NUMBER_OF_ENERGY_CONSUMPTION_WIDGETS_ON_PAGE === 0;

                  if (onNewPage) {
                    pageHeaderIndex += 1;
                  }

                  return (
                    <React.Fragment key={`${i}-${j}`}>
                      {onNewPage &&
                        pageHeaders[pageHeaderIndex - 1]
                      }

                      <EnergyConsumptionWidgetWrapper
                        key={`${i}-${j}`}
                        reportDate={new Date(report.reportDate)}
                        data={data}
                        inPrintMode={inPrintMode}
                        totalBuildingArea={totalBuildingArea}
                        metadata={report.metadataMeasurements}
                        numColumns={numColumns}
                        category={dto.aggregationCategory}
                      />
                    </React.Fragment>
                  );
                })}
              </FlexRow>
            </EnergyWidgetCategoryGroup>
          </div>
        );
      })}
    </div>
  );
};

export default EnergyConsumptionWidgets;

const FlexRow = styled.div<{ hide?: boolean }>`
  display: flex;
  gap: 35px;
  flex-wrap: wrap;

  @media print {
    flex-direction: column;
    gap: 20px;
  }

  ${p => p.hide && css`
    display: none;
  `}
`;