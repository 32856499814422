export enum MetricType {
  /** Common */
  BatteryVoltage = 'BatteryVoltage',
  BatteryPercentage = 'BatteryPercentage',
  RSSI = 'RSSI',
  SNR = 'SNR',
  SignalStrength = 'SignalStrength',
  DataRate = 'DataRate',
  DeviceLastMeasuredOn = 'DeviceLastMeasuredOn',

  /** Environmental (Utopi Multisensor/enLink) */
  CO2 = 'CO2',
  OutdoorHumidity = 'OutdoorHumidity',
  Humidity = 'Humidity',
  Light = 'Light',
  Noise = 'Noise',
  NoiseRaw = 'NoiseRaw',
  PIR = 'PIR',
  OutdoorTemperature = 'OutdoorTemperature',
  Temperature = 'Temperature',
  WellnessScore = 'WellnessScore',
  MotionMeasuredOn = 'MotionMeasuredOn',
  VOC = 'VOC',
  Pressure = 'Pressure',
  CO = 'CO',
  O2 = 'O2',
  H2S = 'H2S',
  Pollutants = 'Pollutants',
  Particulates10 = 'Particulates10',
  Particulates2_5 = 'Particulates2_5',
  NO = 'NO',
  NO2 = 'NO2',
  IAQ = 'IAQ',
  HCHO = 'HCHO',
  O3 = 'O3',
  WaterLeak = 'WaterLeak',
  Emissions = 'Emissions',

  /** Wellness (Utopi Multisensor) */
  CO2Score = 'CO2Score',
  HumidityScore = 'HumidityScore',
  LightScore = 'LightScore',
  NoiseScore = 'NoiseScore',
  TemperatureScore = 'TemperatureScore',

  /** Energy Monitoring */
  ElectricityKwh = 'ElectricityKwh',
  ElectricityKwhDelta = 'ElectricityKwhDelta',
  ElectricityKwhCost = 'ElectricityKwhCost',
  GasVolume = 'GasVolume',
  GasVolumeDelta = 'GasVolumeDelta',
  GasVolumeCost = 'GasVolumeCost',
  GasKwh = 'GasKwh',
  GasKwhDelta = 'GasKwhDelta',
  HeatingKwh = 'HeatingKwh',
  HeatingKwhDelta = 'HeatingKwhDelta',
  HeatingKwhCost = 'HeatingKwhCost',
  WaterVolume = 'WaterVolume',
  WaterVolumeDelta = 'WaterVolumeDelta',
  WaterVolumeCost = 'WaterVolumeCost',
  CarbonIntensityElectricity = 'CarbonIntensityElectricity',
  CoolingKwh = 'CoolingKwh',
  CoolingKwhDelta = 'CoolingKwhDelta',
  CoolingKwhCost = 'CoolingKwhCost',
  CarbonIntensityCooling = 'CarbonIntensityCooling',

  /** 3 Phase Meters */
  ElectricityGeneratedKwh = 'ElectricityGeneratedKwh',
  ElectricityGeneratedKwhDelta = 'ElectricityGeneratedKwhDelta',
  CarbonIntensityElectricitySaved = 'CarbonIntensityElectricitySaved',

  ElectricityL1Voltage = 'ElectricityL1Voltage',
  ElectricityL2Voltage = 'ElectricityL2Voltage',
  ElectricityL3Voltage = 'ElectricityL3Voltage',
  ElectricityL1Current = 'ElectricityL1Current',
  ElectricityL2Current = 'ElectricityL2Current',
  ElectricityL3Current = 'ElectricityL3Current',

  ElectricityL1PowerFactor = 'ElectricityL1PowerFactor',
  ElectricityL2PowerFactor = 'ElectricityL2PowerFactor',
  ElectricityL3PowerFactor = 'ElectricityL3PowerFactor',
  ElectricityL1ActivePower = 'ElectricityL1ActivePower',
  ElectricityL2ActivePower = 'ElectricityL2ActivePower',
  ElectricityL3ActivePower = 'ElectricityL3ActivePower',

  ElectricityTotalActivePower = 'ElectricityTotalActivePower',
  ElectricityMaxTotalPowerDemand = 'ElectricityMaxTotalPowerDemand',
  ElectricityMaxL1CurrentDemand = 'ElectricityMaxL1CurrentDemand',
  ElectricityMaxL2CurrentDemand = 'ElectricityMaxL2CurrentDemand',
  ElectricityMaxL3CurrentDemand = 'ElectricityMaxL3CurrentDemand',
  ElectricityMaxNCurrentDemand = 'ElectricityMaxNCurrentDemand',

  ElectricityTotalKwh = 'ElectricityTotalKwh',
  ElectricityL1ImportKwh = 'ElectricityL1ImportKwh',
  ElectricityL2ImportKwh = 'ElectricityL2ImportKwh',
  ElectricityL3ImportKwh = 'ElectricityL3ImportKwh',

  ElectricityL1ExportKwh = 'ElectricityL1ExportKwh',
  ElectricityL2ExportKwh = 'ElectricityL2ExportKwh',
  ElectricityL3ExportKwh = 'ElectricityL3ExportKwh',

  ElectricityL1TotalKwh = 'ElectricityL1TotalKwh',
  ElectricityL2TotalKwh = 'ElectricityL2TotalKwh',
  ElectricityL3TotalKwh = 'ElectricityL3TotalKwh',

  ElectricityL1ToL2Voltage = 'ElectricityL1ToL2Voltage',
  ElectricityL2ToL3Voltage = 'ElectricityL2ToL3Voltage',
  ElectricityL3ToL1Voltage = 'ElectricityL3ToL1Voltage',

  ElectricityGeneratedL1KwhDelta = 'ElectricityGeneratedL1KwhDelta',
  ElectricityGeneratedL2KwhDelta = 'ElectricityGeneratedL2KwhDelta',
  ElectricityGeneratedL3KwhDelta = 'ElectricityGeneratedL3KwhDelta',

  ElectricityConsumedL1KwhDelta = 'ElectricityConsumedL1KwhDelta',
  ElectricityConsumedL2KwhDelta = 'ElectricityConsumedL2KwhDelta',
  ElectricityConsumedL3KwhDelta = 'ElectricityConsumedL3KwhDelta',

  /** Occupancy (Hikvision) */
  IsolatedPeopleCount = 'IsolatedPeopleCount',
  EnterCount = 'EnterCount',
  ExitCount = 'ExitCount',
  CalculatedOccupancy = 'CalculatedOccupancy',

  /** Relay */
  RelayOutputStatus = 'RelayOutputStatus',

  /** Switch */
  ContactSwitch = 'ContactSwitch',

  /** Weather */
  WindSpeedAverage = 'WindSpeedAverage',
  WindDirectionAverage = 'WindDirectionAverage',

  /** Wavetrend */
  Sensor1Temp = 'Sensor1Temp',
  Sensor2Temp = 'Sensor2Temp',
  Sensor3Temp = 'Sensor3Temp',
  Sensor1MinTemp = 'Sensor1MinTemp',
  Sensor1MaxTemp = 'Sensor1MaxTemp',
  Sensor1FlowCount = 'Sensor1FlowCount',
  Sensor1Compliant = 'Sensor1Compliant',
  Sensor1Error = 'Sensor1Error',
  Sensor2MinTemp = 'Sensor2MinTemp',
  Sensor2MaxTemp = 'Sensor2MaxTemp',
  Sensor2FlowCount = 'Sensor2FlowCount',
  Sensor2Compliant = 'Sensor2Compliant',
  Sensor2Error = 'Sensor2Error',
  Sensor3MinTemp = 'Sensor3MinTemp',
  Sensor3MaxTemp = 'Sensor3MaxTemp',
  Sensor3FlowCount = 'Sensor3FlowCount',
  Sensor3Compliant = 'Sensor3Compliant',
  Sensor3Error = 'Sensor3Error',
  SensorScald = 'SensorScald',
  SensorScaldTemp = 'SensorScaldTemp',
  SensorFreeze = 'SensorFreeze',
  SensorFreezeTemp = 'SensorFreezeTemp',
  ErrorMessage = 'ErrorMessage',

  /** IoTSens Sound Monitor */
  Percentile_P1 = 'Percentile_P1',
  Percentile_P10 = 'Percentile_P10',
  Percentile_P50 = 'Percentile_P50',
  Percentile_P90 = 'Percentile_P90',
  Percentile_P99 = 'Percentile_P99',
  DBA_Min = 'DBA_Min',
  LAEQ = 'LAEQ',
  DBA_Max = 'DBA_Max',

  /** Milesight AM300 */
  LightLevel = 'LightLevel',

  /** Axioma Water Meter */
  WaterMeterAlarm = 'WaterMeterAlarm',

  /**External Metrics */
  CarbonIntensityForecast = 'CarbonIntensityForecast',

  /**Vutility HotDrop */
  AmpHourAccumulation = 'AmpHourAccumulation',
  AverageAmps = 'AverageAmps',
  MaximumAmps = 'MaximumAmps',
  MinimumAmps = 'MinimumAmps',
  CapacitorVoltage = 'CapacitorVoltage',

  /**Utopi Eastron SDM230 */
  ElectricityMaxImportPowerDemand = 'ElectricityMaxImportPowerDemand',
  ElectricityMaxExportPowerDemand = 'ElectricityMaxExportPowerDemand',
  ElectricityExportKwhDelta = 'ElectricityExportKwhDelta',
  ElectricityExportKwh = 'ElectricityExportKwh',

  /** MClimate Vicki TRV */
  TemperatureTarget = 'TemperatureTarget',
  MinTemperature = 'MinTemperature',
  MaxTemperature = 'MaxTemperature',
  ManualTargetTemperatureUpdate = 'ManualTargetTemperatureUpdate',
  WindowPosition = 'WindowPosition',
  ChildLock = 'ChildLock',
  MotorRange = 'MotorRange',
  MotorPosition = 'MotorPosition',
  HighMotorConsumption = 'HighMotorConsumption',
  LowMotorConsumption = 'LowMotorConsumption',
  BrokenSensor = 'BrokenSensor',
  CalibrationFailed = 'CalibrationFailed',
  AttachedBackplate = 'AttachedBackplate',
  PerceiveAsOnline = 'PerceiveAsOnline',
  OperationalMode = 'OperationalMode',
  KeepAliveTime = 'KeepAliveTime',

  /**Axioma Qalcosonic E3 */
  HeatingTemperature = 'HeatingTemperature',
  HeatingPower = 'HeatingPower',
  HeatingVolume = 'HeatingVolume',
  CoolingTemperature = 'CoolingTemperature'
}