import { callGet, IAuthConfig } from '@shared/utils/ConnectApiUtils';
import { BaseQuery } from './BaseQuery';

export abstract class GetQuery<TDto> extends BaseQuery<TDto> {
  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.apiBaseUrl);
    const response = await callGet(apiUrl, authConfig, controller);

    return response.data;
  }
}
