import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy } from 'lodash';
import { transparentize } from 'polished';
import React, { useCallback, useRef, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Row from './Row';
import TitleRow from './TitleRow';
import TableInfoWidgets from './TableInfoWidgets';
import { EsgExplorerConfiguration } from '@dashboard/api/models/EsgExplorerConfiguration';
import { EsgExplorerMetricsDto, EsgExplorerMetricsSpaceDto } from '@dashboard/api/models/EsgExplorerMetricsDto';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { useLayoutContext } from '@src/contexts/LayoutContext';
import { BreadcrumbHeight, TopBarHeight } from '@src/constants/LayoutConstants';

interface IEsgExplorerDataTable {
  data: EsgExplorerMetricsDto;
  config?: EsgExplorerConfiguration;
  fileName: string;
}

const EsgExplorerDataTable = ({ data, config, fileName }: IEsgExplorerDataTable) => {
  const theme = useTheme();
  const { isFullScreen } = useLayoutContext();
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const titleRowRef = useRef<HTMLDivElement>(null);
  const topRowsRef = useRef<HTMLDivElement>(null);
  const [showConditionalFormatting, setShowConditionalFormatting] = useState<boolean>(true);
  const [rows, setRows] = useState<EsgExplorerMetricsSpaceDto[]>([]);
  const [sortKey, setSortKey] = useState(data.spaces[0]?.esgMeasurements[0]?.esgMetricType.toString() ?? 'Name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [lockTopRows, setLockTopRows] = useState(true);
  const [isLockingEnabled, setIsLockingEnabled] = useState(true);
  const hasClusters = rows.some(x => x.bedroomsInCluster);

  const scrollTitleRowListener = useCallback(() => {
    const scrollTarget = scrollWrapperRef.current;
    const titleRowTarget = titleRowRef.current;

    if (!scrollTarget || !titleRowTarget) {
      return;
    }

    titleRowTarget.style.transform = `translate(0, ${scrollTarget.scrollTop}px)`;
    titleRowTarget.style.boxShadow = scrollTarget.scrollTop === 0 ? 'none' : `0px 3px 10px 0px ${theme.palette.shadows.strong}`;
  }, [theme]);

  const scrollTopRowsListener = useCallback(() => {
    const scrollTarget = scrollWrapperRef.current;
    const topRowsTarget = topRowsRef.current;

    if (!scrollTarget || !topRowsTarget) {
      return;
    }

    topRowsTarget.style.transform = `translate(0, ${scrollTarget.scrollTop}px)`;
    topRowsTarget.style.boxShadow = scrollTarget.scrollTop === 0 ? 'none' : `0px 3px 10px 0px ${theme.palette.shadows.strong}`;
  }, [theme]);

  // Handle locked rows at the top of the table (title row and optionally top four rows)
  useEffect(() => {
    const scrollTarget = scrollWrapperRef.current;
    const titleRowTarget = titleRowRef.current;
    const topRowsTarget = topRowsRef.current;

    if (!scrollTarget || !titleRowTarget || !topRowsTarget) {
      return;
    }

    if (lockTopRows) {
      scrollTarget.addEventListener('scroll', scrollTopRowsListener); // Lock title row and top four rows on scroll
      scrollTarget.removeEventListener('scroll', scrollTitleRowListener); // Don't lock title row individually

      // Position locked rows on current scroll position
      topRowsTarget.style.transform = `translate(0, ${scrollTarget.scrollTop}px)`;
      topRowsTarget.style.boxShadow = scrollTarget.scrollTop === 0 ? 'none' : `0px 3px 10px 0px ${theme.palette.shadows.strong}`;

      // Reset title row
      titleRowTarget.style.transform = 'translate(0, 0)';
      titleRowTarget.style.boxShadow = 'none';
    } else {
      scrollTarget.addEventListener('scroll', scrollTitleRowListener); // Only lock title row on scroll
      scrollTarget.removeEventListener('scroll', scrollTopRowsListener) // Don't lock top four rows

      // Position title row on current scroll position
      titleRowTarget.style.transform = `translate(0, ${scrollTarget.scrollTop}px)`;
      titleRowTarget.style.boxShadow = scrollTarget.scrollTop === 0 ? 'none' : `0px 3px 10px 0px ${theme.palette.shadows.strong}`;

      // Reset top four rows to the top of the table
      topRowsTarget.style.transform = 'translate(0, 0)';
      topRowsTarget.style.boxShadow = 'none';
    }

    return () => {
      scrollTarget.removeEventListener('scroll', scrollTopRowsListener);
      scrollTarget.removeEventListener('scroll', scrollTitleRowListener);
    };
  }, [lockTopRows, scrollTopRowsListener, scrollTitleRowListener, theme]);

  // Sort spaces when the key or order changes
  useEffect(() => {
    // maps each space to their corresponding SpaceType_DisplayString key so that they can be sorted 
    data.spaces.map((space: EsgExplorerMetricsSpaceDto) => space.spaceType = SpaceType_DisplayString(space.spaceType) as SpaceType);
    const sortedRows = orderBy(
      data.spaces,
      // eslint-disable-next-line no-prototype-builtins
      x => x.hasOwnProperty(sortKey)
        ? x[sortKey as keyof EsgExplorerMetricsSpaceDto] ?? ''
        : x.esgMeasurements.find(m => m.esgMetricType === sortKey)?.value ?? '',
      sortOrder);

    setRows(sortedRows);
    setIsLockingEnabled(sortedRows.length > 10);
  }, [data, sortKey, sortOrder]);

  // Update sort key or sort order
  const handleSort = (newKey: string) => {
    if (newKey === sortKey) {
      setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(newKey);
    }
  };

  const allMetrics = data.spaces.flatMap(x => x.esgMeasurements);
  const maxValues = data.spaces[0]?.esgMeasurements.map(({ esgMetricType }) => ({
    metricType: esgMetricType,
    maxValue: Math.max(...allMetrics.filter(m => m.esgMetricType === esgMetricType).map(m => m.value ?? 0)),
    minValue: Math.min(...allMetrics.filter(m => m.esgMetricType === esgMetricType).map(m => m.value ?? 0)),
  }));

  return (
    <Wrapper>
      <TableInfoWidgets
        data={data}
        config={config}
        fileName={fileName}
        showConditionalFormatting={showConditionalFormatting}
        setShowConditionalFormatting={setShowConditionalFormatting}
        isLockingEnabled={isLockingEnabled}
        lockTopRows={lockTopRows}
        setLockTopRows={setLockTopRows}
      />

      <Container>
        {isLockingEnabled &&
          <LockToggleWrapper locked={lockTopRows}>
            <LockIcon
              icon={lockTopRows ? solid('lock-keyhole') : solid('lock-keyhole-open')}
              $locked={lockTopRows}
              onClick={() => setLockTopRows(prev => !prev)}
            />
          </LockToggleWrapper>
        }

        <Card ref={scrollWrapperRef} isFullScreen={isFullScreen}>
          <FixedRowsWrapper ref={topRowsRef}>
            <TitleRow
              rowRef={titleRowRef}
              spaces={rows}
              sortKey={sortKey}
              sortOrder={sortOrder}
              handleSort={handleSort}
              showClusterInfo={hasClusters}
            />

            {rows.slice(0, 4).map((space) => (
              <Row
                key={space.spaceId}
                space={space}
                sortKey={sortKey}
                maxValues={maxValues}
                showConditionalFormatting={showConditionalFormatting}
                showClusterInfo={hasClusters}
              />
            ))}
          </FixedRowsWrapper>

          {rows.slice(4, rows.length).map((space) => (
            <Row
              key={space.spaceId}
              space={space}
              sortKey={sortKey}
              maxValues={maxValues}
              showConditionalFormatting={showConditionalFormatting}
              showClusterInfo={hasClusters}
            />
          ))}
        </Card>
      </Container>
    </Wrapper>
  );
};

export default React.memo(EsgExplorerDataTable);

const Wrapper = styled.div`
  padding: 40px;
  overflow: auto;
`;

const Container = styled.div`
  position: relative;
`;

const Card = styled.div<{ isFullScreen: boolean }>`
  position: relative;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 5px;
  box-shadow: 0px 2px 6px 3px ${p => p.theme.palette.shadows.medium};
  max-height: ${p => `calc(100vh - ${p.isFullScreen ? 0 : TopBarHeight}px - ${p.isFullScreen ? 0 : BreadcrumbHeight}px - ${p.isFullScreen ? 220 : 140}px)`};
  min-height: 300px;
  overflow: auto;
  z-index: 0;
`;

const FixedRowsWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: max-content;
`;

const LockToggleWrapper = styled.div<{ locked: boolean }>`
  position: absolute;
  top: 49px;
  left: -18px;
  width: 23px;
  height: 177px;
  border: 1px dashed ${p => transparentize(p.locked ? 0.2 : 0.7, p.theme.palette.primary)};
  border-width: 2px;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

const LockIcon = styled(FontAwesomeIcon) <{ $locked: boolean }>`
  width: 14px;
  height: 14px;
  padding: 7px;
  color: ${p => transparentize(p.$locked ? 0 : 0.3, p.theme.palette.primary)};
  background-color: ${p => p.theme.palette.backgrounds.background};
  border-radius: 50%;
  cursor: pointer;

  margin-left: -15px;

  transition: all 150ms ease;
  
  &:hover {
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;