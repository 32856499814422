import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IBackButton } from './BackButton.types';
import { ReactNode } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export const BackButton = ({ label, url, onClick, styles }: IBackButton) => {

  let element: ReactNode = null;

  if (url !== undefined) {
    element = (
      <Link to={url}>
        <Wrapper>
          <Icon icon={solid('chevron-left')} />
          <Label>{label}</Label>
        </Wrapper>
      </Link>
    );
  }

  if (onClick !== undefined) {
    element = (
      <Wrapper onClick={onClick}>
        <Icon icon={solid('chevron-left')} />
        <Label>{label}</Label>
      </Wrapper>
    );
  }

  return (
    <FlexRow style={styles}>
      {element}
    </FlexRow>
  );
};

const FlexRow = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  color: ${p => p.theme.text.secondary};
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
