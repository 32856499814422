/**
 * Returns null if string es empty, otherwise returns the string.
 */
export const nullIfEmptyString = (value: string): (null | string) => {
  return value === '' ? null : value;
}

/**
 * Checks if a string contains a given substring, checking is case insensitive.
 */
export const includesCI = (value: string, searchValue: string) => {
  return value.toLowerCase().includes(searchValue.toLowerCase());
}

/**
 * Calls itself N times recursively and returns the substring.
 */
export const substrN = (string: string, searchString: string, n: number, count = 0): string => {
  if (n === count) return string;
  return substrN(string.substr(0, string.lastIndexOf(searchString)), searchString, n, ++count);
};

/**
 * Capitalise the first character of a string
 */
export const capitalize = (str: string): string => {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str;
}

/**
 * Cut string off after specified number of characters and add ellipsis
 */
export const substringNCharWithEllipsis = (str: string, numChar: number) => {
  return str.length <= numChar ? str : str.substring(0, numChar) + '...';
};

/**
 * Validate an email address
 */
export const validateEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

/**
 * Compare function to sort alphabetically (ignoring case)
 */
export const alphabeticalSort = (a?: string, b?: string): number => {
  const x = a?.toLocaleLowerCase();
  const y = b?.toLocaleLowerCase();

  if (!x || !y) {
    return 0;
  }

  switch (true) {
    case x > y:
      return 1;
    case x < y:
      return -1;
    default:
      return 0;
  }
};