import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import IconTooltip from '@shared/components/atoms/IconTooltip/IconTooltip';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  siteClimateControl: SiteClimateControlDto;
  checked: boolean;
  onChange: (state: boolean) => void;
}

const StepDownModeFilter = ({ siteClimateControl, checked, onChange }: PropTypes) => {
  const { t } = useTranslation();
  const { getUnit, toLocale } = useLocalisation();
  const unit = getUnit(MetricType.Temperature);
  const hourLabel = t('Hour', { ns: 'common' });
  const hoursLabel = t('Hours', { ns: 'common' });

  if (!siteClimateControl.noMotionRulesEnabled) {
    return null;
  }

  const TooltipContent = (
    <ContentWrapper>
      <Title>
        {t('ClimateControl.StepDownN', { ns: 'molecules', value: 1 })}
      </Title>
      {t('ClimateControl.StepDownDescription', {
        ns: 'molecules',
        hours: siteClimateControl.noMotionThreshold1,
        hoursLabel: siteClimateControl.noMotionThreshold1 === 1 ? hourLabel : hoursLabel,
        degrees: toLocale(MetricType.Temperature, siteClimateControl.noMotionThreshold1Temp),
        unit: unit
      })}

      <Title style={{ paddingTop: 14 }}>
        {t('ClimateControl.StepDownN', { ns: 'molecules', value: 2 })}
      </Title>
      {t('ClimateControl.StepDownDescription', {
        ns: 'molecules',
        hours: siteClimateControl.noMotionThreshold2,
        hoursLabel: siteClimateControl.noMotionThreshold2 === 1 ? hourLabel : hoursLabel,
        degrees: toLocale(MetricType.Temperature, siteClimateControl.noMotionThreshold2Temp),
        unit: unit
      })}
    </ContentWrapper>
  );

  return (
    <FlewRow>
      <Switch
        checked={checked}
        onChange={onChange}
      />

      <Label>
        {t('ClimateControl.StepDownModeFilterText', { ns: 'molecules' })}
      </Label>

      <IconTooltip
        icon={regular('info-circle')}
        iconStyles={{ width: 12, height: 12, marginBottom: 1 }}
        tooltip={{
          content: TooltipContent,
          placement: TooltipPlacement.Bottom
        }}
      />
    </FlewRow>
  );
};

export default StepDownModeFilter;

const FlewRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
  padding: 0 4px 0 8px;
`;

const ContentWrapper = styled.div`
  width: 230px;
`;

const Title = styled.div`
  font-weight: 500;
  padding-bottom: 4px;
`;