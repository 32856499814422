import { ClimateControlBuildingSummaryDto } from '@shared/api/models/ClimateControl/BuildingSummary/ClimateControlBuildingSummaryDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class ClimateControlBuildingSummariesGetQuery extends GetQuery<ClimateControlBuildingSummaryDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/ClimateControl/BuildingSummaries/${this.siteId}`;
  }
}
