import { ErrorMessage, Input, Label } from '@shared/components/atoms/Form/Form';
import { Card } from '@shared/components/molecules/Card/Card';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues } from './SiteMetadataEdit';

type CertificationsAndRatingsEditProps = {
  editMode: boolean;
}

const CertificationsAndRatingsEdit = ({ editMode }: CertificationsAndRatingsEditProps) => {
  const { t } = useTranslation(['settingsAsset', 'currencies']);
  const { register, formState: { errors } } = useFormContext<FormValues>();

  return (
    <Card cardTitle={t('Sites.SiteMetadataEditing.CertificationsAndRatings', { ns: 'settingsAsset' })}>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.EPCRating', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('epc', {
              setValueAs: value => nullIfEmptyString(value),
              pattern: {
                value: /[A-G]/,
                message: t('Sites.SiteMetadataEditing.RatingValidation', { ns: 'settingsAsset' })
              }
            }
            )}
            placeholder={t('Sites.SiteMetadataEditing.RatingPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.epc?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.GRESB', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('gresb', {
              pattern: {
                value: /\d{1,3}/,
                message: t('Sites.SiteMetadataEditing.GRESBValidation', { ns: 'settingsAsset' })
              },
              max: {
                value: 100,
                message: t('Sites.SiteMetadataEditing.GRESBValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.GRESBValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.GRESBPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.gresb?.message}</ErrorMessage>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.Fitwel', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('fitwel', {
              pattern: {
                value: /\d{2,3}/,
                message: t('Sites.SiteMetadataEditing.FitwelValidation', { ns: 'settingsAsset' })
              },
              max: {
                value: 144,
                message: t('Sites.SiteMetadataEditing.FitwelValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 90,
                message: t('Sites.SiteMetadataEditing.FitwelValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.FitwelPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.fitwel?.message}</ErrorMessage>
        </div>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.BREEAM', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('breeam', {
              pattern: {
                value: /\d{1,3}/,
                message: t('Sites.SiteMetadataEditing.BREEAMValidation', { ns: 'settingsAsset' })
              },
              max: {
                value: 100,
                message: t('Sites.SiteMetadataEditing.BREEAMValidation', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.BREEAMValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            placeholder={t('Sites.SiteMetadataEditing.BREEAMPlaceholder', { ns: 'settingsAsset' })}
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.breeam?.message}</ErrorMessage>
        </div>
      </div>
    </Card>
  )
}

export default CertificationsAndRatingsEdit