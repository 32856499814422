import { ClimateControlStatus } from '@shared/api/enums/ClimateControlStatus/ClimateControlStatus';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import Overview from './Overview/Overview';
import Setup from './Setup/Setup';

const ClimateControlV2 = () => {
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();

  if (siteClimateControl?.status === ClimateControlStatus.Setup) {
    return (
      <Setup
        siteClimateControl={siteClimateControl}
        refreshSiteClimateControl={refreshSiteClimateControl}
      />
    );
  }

  return (
    <Overview
      siteClimateControl={siteClimateControl}
      refreshSiteClimateControl={refreshSiteClimateControl}
    />
  );
}

export default ClimateControlV2