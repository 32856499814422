import MonthlyWeightingInput from '@settings/components/organisms/BuildingManagement/BuildingPage/Edit/MonthlyWeightingInput';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { ErrorMessage, Input, Label } from '@shared/components/atoms/Form/Form';
import { Card } from '@shared/components/molecules/Card/Card';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormValues } from './SiteMetadataEdit';

type YearlyConsumptionTargetsEditProps = {
  editMode: boolean;
}

const YearlyConsumptionTargetsEdit = ({ editMode }: YearlyConsumptionTargetsEditProps) => {
  const { t } = useTranslation(['settingsAsset', 'currencies']);
  const { register, control, formState: { errors } } = useFormContext<FormValues>();
  const { getUnit } = useLocalisation();

  return (
    <Card cardTitle={t('Sites.SiteMetadataEditing.YearlyConsumptionTargets', { ns: 'settingsAsset' })}>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.Electricity', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('electricityConsumptionTarget', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.electricityConsumptionTarget?.message}</ErrorMessage>
        </div>
      </div>
      <div style={{ marginBottom: '35px' }}>
        <Controller
          control={control}
          name={'electricityConsumptionTargetMonthlyWeighting'}
          render={({ field: { onChange, value } }) => (
            <MonthlyWeightingInput
              weighting={value}
              onChange={(weighting) => onChange(weighting)}
              readOnly={!editMode}
            />
          )}
        />
      </div>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.Heating', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('heatingConsumptionTarget', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.heatingConsumptionTarget?.message}</ErrorMessage>
        </div>
      </div>
      <div style={{ marginBottom: '25px' }}>
        <Controller
          control={control}
          name={'heatingConsumptionTargetMonthlyWeighting'}
          render={({ field: { onChange, value } }) => (
            <MonthlyWeightingInput
              weighting={value}
              onChange={(weighting) => onChange(weighting)}
              readOnly={!editMode}
            />
          )}
        />
      </div>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.Gas', { ns: 'settingsAsset', unit: getUnit(MetricType.GasVolume) })}</Label>
          <Input
            {...register('gasConsumptionTarget', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.gasConsumptionTarget?.message}</ErrorMessage>
        </div>
      </div>
      <div style={{ marginBottom: '25px' }}>
        <Controller
          control={control}
          name={'gasConsumptionTargetMonthlyWeighting'}
          render={({ field: { onChange, value } }) => (
            <MonthlyWeightingInput
              weighting={value}
              onChange={(weighting) => onChange(weighting)}
              readOnly={!editMode}
            />
          )}
        />
      </div>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.Water', { ns: 'settingsAsset', unit: getUnit(MetricType.WaterVolume) })}</Label>
          <Input
            {...register('waterConsumptionTarget', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.waterConsumptionTarget?.message}</ErrorMessage>
        </div>
      </div>
      <div style={{ marginBottom: '25px' }}>
        <Controller
          control={control}
          name={'waterConsumptionTargetMonthlyWeighting'}
          render={({ field: { onChange, value } }) => (
            <MonthlyWeightingInput
              weighting={value}
              onChange={(weighting) => onChange(weighting)}
              readOnly={!editMode}
            />
          )}
        />
      </div>
      <div className="row" style={{ marginBottom: '15px' }}>
        <div className="col-md-6">
          <Label>{t('Sites.SiteMetadataEditing.Cooling', { ns: 'settingsAsset' })}</Label>
          <Input
            {...register('coolingConsumptionTarget', {
              pattern: {
                value: /^\d+\.?\d{0,2}$/,
                message: t('Sites.SiteMetadataEditing.MustBeNumeric2DecimalPlaces', { ns: 'settingsAsset' })
              },
              min: {
                value: 1,
                message: t('Sites.SiteMetadataEditing.ValueMinValidation', { ns: 'settingsAsset' })
              },
              setValueAs: value => stringToNumber(value)
            }
            )}
            type="number"
            readOnly={!editMode}
          />
          <ErrorMessage>{errors.coolingConsumptionTarget?.message}</ErrorMessage>
        </div>
      </div>
      <div style={{ marginBottom: '25px' }}>
        <Controller
          control={control}
          name={'coolingConsumptionTargetMonthlyWeighting'}
          render={({ field: { onChange, value } }) => (
            <MonthlyWeightingInput
              weighting={value}
              onChange={(weighting) => onChange(weighting)}
              readOnly={!editMode}
            />
          )}
        />
      </div>
    </Card>
  )
}

export default YearlyConsumptionTargetsEdit