import styled from 'styled-components';
import { transparentize } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { YearlyChartDataset } from '../useCumulativeChart';
import DottedLine from './DottedLine';
import Line from './Line';
import DashedLine from './DashedLine';

export enum CumulativeChartLegendStyle {
  Line = 'Line',
  Dashed = 'Dashed',
  Dotted = 'Dotted'
}

const renderSymbol = (style: CumulativeChartLegendStyle, color: string) => {
  switch (style) {
    case CumulativeChartLegendStyle.Line:
      return <Line color={color} />;
    case CumulativeChartLegendStyle.Dashed:
      return <DashedLine color={color} />;
    case CumulativeChartLegendStyle.Dotted:
      return <DottedLine color={color} />;
  }
};

type PropTypes = {
  datasets: YearlyChartDataset[];
  onChange: (hiddenDatasets: { [key: string]: string }) => void;
}

const ChartLegend = ({ datasets, onChange }: PropTypes) => {
  const [hiddenDatasets, setHiddenDatasets] = useState<{ [key: string]: string }>({});

  /**
   * Reset when datasets change
   */
  useEffect(() => {
    setHiddenDatasets({});
  }, []);

  /**
   * Modify the object containing keys (the dataset id) of the hidden datasets as they are selected/deselected in the legend.
   */
  const handleToggle = useCallback((id: string) => {
    const hidden = { ...hiddenDatasets };
    const arrayIndex = hidden[id];

    if (arrayIndex) {
      delete hidden[id];
    } else {
      hidden[id] = id;
    }

    setHiddenDatasets(hidden);
    onChange(hidden);
  }, [hiddenDatasets, onChange]);

  return (
    <Wrapper>
      <Items>
        {datasets.map(({ label, color, id, legend }) => {
          const isHidden = !!hiddenDatasets[id];

          return (
            <Item
              key={uuidv4()}
              onClick={() => handleToggle(id)}
              inactive={isHidden}
              color={color}
            >
              {renderSymbol(legend, color)}

              {isHidden &&
                <LineThrough />
              }

              <Label inactive={isHidden}>
                {label}
              </Label>
            </Item>
          );
        })}
      </Items>
    </Wrapper>
  );
};

export default ChartLegend;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
`;

const Item = styled.div<{ inactive: boolean, color: string }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Label = styled.div<{ inactive: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.inactive ? p.theme.palette.text.weak : p.theme.palette.text.fair};
  user-select: none;
`;

const LineThrough = styled.div`
  position: absolute;
  left: -4px;
  width: calc(100% + 8px);
  height: 2px;
  border-radius: 2px;
  background-color: ${p => transparentize(0.5, p.theme.palette.text.fair)};
`;