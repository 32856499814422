import styled, { css, useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { useModal } from '@shared/hooks/useModal';
import { InformationDialog } from '@shared/components/molecules/InformationDialog/InformationDialog';
import { Button } from '@shared/components/atoms/Button/Button';
import { ICard } from './Card.types';

export const Card = (props: ICard) => {
  const { children, cardTitle, titleStyle, titleWrapperStyle, hideSemiCircle, modalTitle, modalContent, titleRowElement, centered, maxWidth, noPadding, autoWidth, noMargin, contentWidthOnMobile } = props;
  const theme = useTheme();
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: modalRef } = useModal({});

  return (
    <>
      <InformationDialog
        modalRef={modalRef}
        isOpen={modalIsOpen}
        hide={toggleModal}
        title={modalTitle}
        content={modalContent}
        button={{ label: 'Close' }}
      />

      <StyledCard
        {...props}
        maxWidth={maxWidth}
        centered={centered}
        autoWidth={autoWidth}
        noMargin={noMargin}
        contentWidthOnMobile={contentWidthOnMobile}
      >
        {(cardTitle || modalTitle || modalContent) && (
          <>
            <TitleWrapper noPadding={noPadding} style={titleWrapperStyle}>
              <Title
                style={titleStyle}
                hideSemiCircle={hideSemiCircle}
              >

                {cardTitle}
              </Title>

              <TitleRowElementWrapper>
                {titleRowElement}

                {(modalTitle || modalContent) && (
                  <Button
                    tertiary
                    circle
                    label={
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        style={{ width: '18px', height: '18px' }}
                      />
                    }
                    color={theme.palette.text.weak}
                    style={{ padding: '7px' }}
                    onClick={toggleModal}
                  />
                )}
              </TitleRowElementWrapper>
            </TitleWrapper>
          </>
        )}
        <Content noPadding={noPadding}>{children}</Content>
      </StyledCard>
    </>
  );
};

const StyledCard = styled.div<{ maxWidth?: string; centered?: boolean; autoWidth?: boolean; noMargin?: boolean; contentWidthOnMobile?: boolean; }>`
  margin-bottom: ${(p) => (p.noMargin ? '0' : '8px')};

  background-color: ${p => p.theme.background.container};
  border: 1px solid ${p => p.theme.action.divider};
  border-radius: 4px;
  box-shadow: 0px 3px 8px ${p => p.theme.shadow.primary};

  width: ${(p) => (p.autoWidth ? 'auto' : '100%')};
  max-width: ${(p) => p.maxWidth ?? 'none'};

  ${(p) => p.centered && css`
    margin-left: auto;
    margin-right: auto;
  `}

  ${(p) => p.contentWidthOnMobile && css`
    /* On small screens */
    min-width: max-content;

    /* On large screens */
    @media (min-width: 600px) {
      min-width: unset;
    }
  `}
`;

const TitleWrapper = styled.div<{ noPadding?: boolean }>`
  position: relative;
  height: 65px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  padding-right: 13px;
  width: 100%;

  color: ${p => p.theme.text.primary};
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const TitleRowElementWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
`;

const Title = styled.div<{ hideSemiCircle?: boolean }>`
  position: relative;
  overflow: hidden;
  padding-left: 15px;
  line-height: 30px;

  @media (min-width: 600px) {
    padding-left: 20px;
  }

  ${p => !p.hideSemiCircle && css`
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -20px;
      background-color: ${(p) => p.theme.primary.main};
      width: 25px;
      height: 30px;
      border-radius: 100%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  `}
`;

const Content = styled.div<{ noPadding?: boolean }>`
  padding: 20px;

  ${p => p.noPadding && css`
    padding: 0 !important;
  `}
`;
