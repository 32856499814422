import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertRule } from '@shared/api/models/AlertRule/AlertRule';
import { PopoverPlacement } from '@shared/components/atoms/Popover/Popover.types';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useApi } from '@shared/hooks/useApi';
import { usePopover } from '@shared/hooks/usePopover';
import { useModal } from '@shared/hooks/useModal';
import AlertRuleCreateCommand from '@settings/api/queries/AlertRules/AlertRuleCreateCommand';
import { Popover } from '@shared/components/atoms/Popover/Popover';
import AlertRuleDeleteCommand from '@settings/api/queries/AlertRules/AlertRuleDeleteCommand';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

interface IAlertListActions {
  rule: AlertRule;
  refreshRules: () => void;
}

const AlertListActions = ({ rule, refreshRules }: IAlertListActions) => {
  const { t } = useTranslation(['settingsAlerting']);
  const theme = useTheme();
  const { execute } = useApi();
  const { site } = useSiteContext();
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});
  const { visible, toggle, setVisible, ref } = usePopover({});
  const navigate = useNavigate();

  const cloneRule = async (rule: AlertRule) => {
    toggle();

    const clone = cloneDeep(rule);
    clone.name = `${t('AlertingRuleActions.CopyOf', { ns: 'settingsAlerting' })} ${rule.name}`;
    const newRule = await execute({
      query: new AlertRuleCreateCommand(site.id, clone),
      successMessage: t('AlertingRuleActions.RuleSaved', { ns: 'settingsAlerting' })
    });

    newRule && navigate(`../${newRule.id}`);
    refreshRules();
  };

  const deleteRule = async (rule: AlertRule) => {
    if (!rule.id) {
      return;
    }

    toggle();

    await execute({
      query: new AlertRuleDeleteCommand(rule.id),
      successMessage: `${t('AlertingRuleActions.Rule', { ns: 'settingsAlerting' })} "${rule.name}" ${t('AlertingRuleActions.Deleted', { ns: 'settingsAlerting' })}`
    });

    refreshRules();

    // Hide Tooltip and Modal
    visible && setVisible(false);
    deleteDialogIsOpen && toggleDeleteDialog();
  };

  const popoverContent = (
    <PopoverContent onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
      <Action onClick={() => cloneRule(rule)}>
        <ActionIcon icon={regular('clone')} color={theme.palette.primary} />
        {t('AlertingRuleActions.Duplicate', { ns: 'settingsAlerting' })}
      </Action>
      <Action onClick={toggleDeleteDialog}>
        <ActionIcon icon={solid('trash-xmark')} color={theme.palette.red} />
        {t('AlertingRuleActions.Delete', { ns: 'settingsAlerting' })}
      </Action>
    </PopoverContent>
  );

  return (
    <>
      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('AlertingRuleActions.DeleteConfirmParagraphOne', { ns: 'settingsAlerting' })}
        sectionTwo={t('AlertingRuleActions.DeleteConfirmParagraphTwo', { ns: 'settingsAlerting' })}
        confirmButton={t('AlertingRuleActions.Confirm', { ns: 'settingsAlerting' })}
        onCancel={toggleDeleteDialog}
        onConfirm={() => deleteRule(rule)}
      />

      <Popover
        ref={ref}
        visible={visible}
        popoverContent={popoverContent}
        placement={PopoverPlacement.BottomRight}
        alignWithTarget={true}
        hideTriangle={true}
        offsetY={-10}
        offsetX={5}
      >
        <MenuIcon icon={regular('ellipsis-vertical')} onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggle();
        }} />
      </Popover>
    </>
  );
};

export default AlertListActions;

const MenuIcon = styled(FontAwesomeIcon)`
  margin-top: 5px;
  color: ${p => p.theme.action.active};
  cursor: pointer;
  padding: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &:hover {
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;

const PopoverContent = styled.div`
  width: 140px;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
`;

const Action = styled.div`
  padding: 5px 12px;

  &:hover {
    background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  }
`;

const ActionIcon = styled(FontAwesomeIcon) <{ color: string }>`
  font-size: 14px;
  color: ${p => p.color};
  margin-right: 8px;
  width: 15px;
`;