export enum WaterMeterAlarmCode {
  Backflow = 'Backflow',
  Burst = 'Burst',
  Dry = 'Dry',
  Leakage = 'Leakage',
  LowBattery = 'LowBattery',
  LowTemperature = 'LowTemperature',
  PermanentError = 'PermanentError',
  TemporaryError = 'TemporaryError',
  FlowExceed = 'FlowExceed',
  MagnetFraud = 'MagnetFraud',
  ModuleRemove = 'ModuleRemove',
  BackflowVolume = 'BackflowVolume'
}