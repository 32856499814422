import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { Route } from '@shared/types/Route';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  route: Route;
}

const NavItem = ({ route }: PropTypes) => {
  const { t } = useTranslation();

  if (!route.link) {
    return null;
  }

  return (
    <Link link={route.link}>
      {route.link.icon &&
        <Icon icon={route.link.icon} />
      }

      {route.link.label &&
        <Label>
          {t(route.link.label, { ns: 'routes' })}
        </Label>
      }
    </Link>
  );
};

export default NavItem;

const Link = styled(ProtectedLink)`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  border-radius: 8px;
  padding: 9px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};

    svg {
      color: ${p => p.theme.primary.main};
    }
  }

  &.active {
    font-weight: 500;
    color: ${p => p.theme.text.primary};
    background-color: ${p => p.theme.primary.hover};

    svg {
      color: ${p => p.theme.primary.main};
    }
  }
`;

const Label = styled.div`
  color: ${p => p.theme.text.primary};
  white-space: nowrap;
  transition: all 150ms ease;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  padding-right: 10px;
  color: ${p => p.theme.action.active};
  flex-shrink: 0;
`;