import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';

class Marker {
  id: number;
  name: string;
  spaceType: SpaceType;
  xCoordinate?: number;
  yCoordinate?: number;

  constructor(id: number, name: string, spaceType: SpaceType, xCoordinate?: number, yCoordinate?: number) {
    this.id = id;
    this.name = name;
    this.spaceType = spaceType;
    this.xCoordinate = xCoordinate;
    this.yCoordinate = yCoordinate;
  }
}

export default Marker;