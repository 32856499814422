import { SalesOrderItem } from '@shared/api/models/SalesOrder/SalesOrderItem';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class SiteSalesOrderBulkUpdateSalesOrderItemsCommand extends PatchQuery<SalesOrderItem[]> {
  salesOrderItems: SalesOrderItem[];

  constructor(salesOrderItems: SalesOrderItem[]) {
    super();
    this.salesOrderItems = salesOrderItems;
  }

  public targetUrl(): string {
    return '/salesorders/bulkupdatesalesorderitems';
  }
}