import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import useChart from './useChart';
import styled from 'styled-components';
import LegendItem from './LegendItem';
import { useTranslation } from 'react-i18next';
import NoData from '@shared/components/atoms/NoData/NoData';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import YAxisTitles from './YAxisTitles';

type PropTypes = {
  device: DeviceWithMetrics;
}

const SpaceDetailsDeviceChart = ({ device }: PropTypes) => {
  const { t } = useTranslation();
  const { canvasRef, datasets } = useChart({ metrics: device.metrics });

  return (
    <>
      <LegendContainer>
        {datasets.map(dataset => (
          <LegendItem key={dataset.metricType} dataset={dataset} />
        ))}
      </LegendContainer>

      <YAxisTitles datasets={datasets} />

      {datasets.length === 0 &&
        <Placeholder>
          <NoData
            label={t('DataUnavailable', { ns: 'status' })}
            icon={solid('line-chart')}
          />
        </Placeholder>
      }

      <Wrapper hide={datasets.length === 0}>
        <ChartContainer>
          <canvas id="myChart" ref={canvasRef} />
        </ChartContainer>

        <Label>
          {t('Last24Hours', { ns: 'common' })}
        </Label>
      </Wrapper>
    </>
  );
};

export default SpaceDetailsDeviceChart;

const Wrapper = styled.div<{ hide: boolean }>`
  display: ${p => p.hide ? 'none' : 'unset'};
`;

const ChartContainer = styled.div`
  height: 260px;
`;

const Placeholder = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LegendContainer = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  padding: 5px 10px 12px 10px;
`;

const Label = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.light};
  text-align: center;
  padding-top: 5px;
`;