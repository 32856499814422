import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TabBarItem } from './SearchBarDropdown';

type PropTypes = {
  tabs: TabBarItem[];
  selectedTab: number;
  onChange: (index: number) => void;
}

const SearchBarDropdownTabs = ({ tabs, selectedTab, onChange }: PropTypes) => {
  const lineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  // Move the line when the selected tab changes
  useEffect(() => {
    if (!lineRef.current) {
      return;
    }

    const tabs = document.querySelectorAll('#search-bar-tab-container .tab');
    const activeTabElement = tabs[selectedTab];

    if (activeTabElement) {
      const tab = activeTabElement as HTMLElement;
      lineRef.current.style.left = tab.offsetLeft + 'px';
      lineRef.current.style.width = tab.clientWidth + 'px';
    }
  }, [selectedTab]);

  return (
    <Wrapper id='search-bar-tab-container'>
      {tabs.map((tab, i) => (
        <Tab
          key={uuidv4()}
          ref={element => tabRefs.current[i] = element}
          className='tab'
          onClick={() => onChange(i)}
          selected={i === selectedTab}
        >
          {tab.label} {tab.loading ? <LoadingSpinner icon={solid('spinner')} className="fa-pulse" /> : `(${tab.count ?? 0})`}
        </Tab>
      ))}

      <TabLineTrack />
      <TabLine ref={lineRef} />
    </Wrapper >
  );
};

export default SearchBarDropdownTabs;

const Wrapper = styled.div`
  position: relative;
  height: 36px;
  margin: 4px 10px 0 10px;

  display: flex;
  align-items: center;
`;

const Tab = styled.div<{ selected: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.selected ? p.theme.primary.main : p.theme.text.secondary};
  padding: 0 10px;
  cursor: pointer;
`;

const TabLineTrack = styled.div`
  width: 280px;
  height: 2px;
  background-color: ${p => p.theme.action.outlineBorder};
  border-radius: 4px;

  position: absolute;
  bottom: 0;
  left: 0;
`;

const TabLine = styled.div`
  height: 2px;
  background-color: ${p => p.theme.palette.primary};
  border-radius: 4px;
  transition: left 300ms ease, width 300ms ease;

  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingSpinner = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-left: 2px;
`;