import { Radio, RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';

export enum ReportGroupingType {
  MetricType = 'MetricType',
  Space = 'Space'
}

type PropTypes = {
  value: ReportGroupingType,
  onChange: (grouping: ReportGroupingType) => void
}

const GroupingTypeSelect = ({ value, onChange }: PropTypes) => {
  const { t } = useTranslation(['molecules']);

  return (
    <Radio.Group onChange={(e: RadioChangeEvent) => onChange(e.target.value)} value={value}>
      <Radio style={{ marginTop: 8 }} value={ReportGroupingType.MetricType}>{t('DynamicReport.GroupByMetric', { ns: 'molecules' })}</Radio>
      <Radio style={{ marginTop: 8 }} value={ReportGroupingType.Space}>{t('DynamicReport.GroupBySpace', { ns: 'molecules' })}</Radio>
    </Radio.Group>
  );
};

export default GroupingTypeSelect;