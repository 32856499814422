import { Tenant } from '@shared/api/models/Tenant/Tenant';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class TenantGetByIdQuery extends PostQuery<Tenant> {
  tenantId: string;

  constructor(tenantId: string) {
    super();
    this.tenantId = tenantId;
  }

  public targetUrl(): string {
    return '/tenants/GetById';
  }
}
