import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import styled from 'styled-components';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { useApiState } from '@shared/hooks/useApiState';
import { ClimateControlGetHeatmapQuery } from '@shared/api/queries/ClimateControl/Heatmap/ClimateControlGetHeatmapQuery';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { useParams } from 'react-router-dom';
import ClimateControl_HeatmapLegend from './ClimateControl_HeatmapLegend';
import { useTranslation } from 'react-i18next';
import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import ClimateControl_HeatmapSquare from './ClimateControl_HeatmapSquare';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';

const ClimateControl_Heatmap = () => {
  const { t } = useTranslation();
  const { buildingId } = useParams<{ buildingId: string }>();
  const { data, loading } = useApiState({
    query: buildingId === undefined ? undefined : new ClimateControlGetHeatmapQuery(buildingId),
    errorMessage: t('ClimateControl.Heatmap.HeatmapLoadFailed', { ns: 'molecules' })
  }, [buildingId]);

  return (
    <PaddedContainer>
      <BackButton
        label={t('BackToClimateControlSettings', { ns: 'navigation' })}
        url='./../..'
      />

      {loading &&
        <Loading
          fullWidthCentered
          message={t('ClimateControl.Heatmap.LoadingHeatmap', { ns: 'molecules' })}
        />
      }

      {!loading &&
        <Card
          noPadding
          cardTitle={data?.buildingName}
          hideSemiCircle={true}
          style={{ minWidth: 'max-content' }}
        >
          <FlexRow>
            <FloorsColumn>
              <RowTitle>
                {t('Floors', { ns: 'assets' })}
              </RowTitle>

              {data?.floors.map((floor) => (
                <FloorNumber key={floor.id}>
                  {floor.floorNumber}
                </FloorNumber>
              ))}
            </FloorsColumn>

            <SpacesColumn>
              <RowTitle>
                {t('Spaces', { ns: 'assets' })}
              </RowTitle>

              {data?.floors.map((floor, i) => (
                <SpacesRow key={floor.id}>
                  {floor.spaces.map((space) => (
                    <ClimateControl_HeatmapSquare
                      key={space.id}
                      space={space}
                      floor={floor} link={`./../../../building/${buildingId}/floor/${floor?.id}/space/${space.id}`}
                      tooltipPlacement={i > 1 ? TooltipPlacement.Top : TooltipPlacement.Bottom}
                    />
                  ))}
                </SpacesRow>
              ))}
            </SpacesColumn>

            <LegendColumn>
              <ClimateControl_HeatmapLegend />
            </LegendColumn>
          </FlexRow>
        </Card>
      }
    </PaddedContainer >
  )
}

export default ClimateControl_Heatmap;

const FlexRow = styled.div`
  display: flex;
`;

const FloorsColumn = styled(FlexColumn)`
  width: 80px;
  align-items: center;
  justify-content: start;
  text-align: center;
  color: ${p => p.theme.palette.text.weak};
  border-right: 1px solid ${p => p.theme.palette.borders.weak};
  padding-bottom: 20px;
`;

const RowTitle = styled.div`
  width: 100%;
  padding: 15px 20px 10px 20px;
  font-size: 14px;
`;

const FloorNumber = styled(FlexRow)`
  margin-top: 6px;
  position: relative;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${p => p.theme.palette.borders.weak};
  border-radius: 3px;
  font-size: 14px;
`;

const SpacesColumn = styled(FlexColumn)`
  flex: 1;
  color: ${p => p.theme.palette.text.weak};
`;

const SpacesRow = styled(FlexRow)`
  padding: 6px 20px 0;
  height: 42px;
  gap: 6px;
`;

const LegendColumn = styled.div`
  border-left: 1px solid ${p => p.theme.palette.borders.weak};
`;