import CsvPeopleCountingZonesWithMeasurementsAndMetricTypesQuery from '@dashboard/api/queries/exports/CsvPeopleCountingZonesWithMeasurementsAndMetricTypesQuery';
import { TimeRange } from '@dashboard/components/atoms/RangePicker/RangePicker';
import useCsvExport from '@dashboard/hooks/useCsvExport';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PeopleCountingZone from '@settings/api/models/PeopleCounting/PeopleCountingZone';
import PeopleCountingZoneDeleteCommand from '@settings/api/queries/PeopleCounting/PeopleCountingZoneDeleteCommand';
import PeopleCountingZonesGetBySiteIdQuery from '@settings/api/queries/PeopleCounting/PeopleCountingZonesGetBySiteQuery';
import { Button } from '@shared/components/atoms/Button/Button';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { Title } from '@shared/components/atoms/Title/Title';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { useModal } from '@shared/hooks/useModal';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { includesCI } from '@shared/utils/StringUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import ButtonWithPeopleCountZoneAndDateSelector from './ButtonWithPeopleCountZoneAndDateSelection';



const PeopleCountingZoneList = () => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const { tenant } = useTenantContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isOpen: deleteDialogIsOpen, toggle: deleteDialogToggle, ref: deleteDialogRef } = useModal({});
  const [zoneToDelete, setZoneToDelete] = useState<PeopleCountingZone>();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [searchString, setSearchString] = useState<string>('');
  const { execute } = useApi();
  const { data: peopleCountingZones, loading, execute: fetchZones } = useApiState({
    query: new PeopleCountingZonesGetBySiteIdQuery(site.id),
    initialState: [],
  }, [site]);
  const { handleCsvExport: exportMeasurements, loading: loadingMeasurementsExport } = useCsvExport();

  const searchFilter = useCallback((x: PeopleCountingZone) => {
    return includesCI(x.virtualDeviceName, searchString)
      || includesCI(x.site, searchString)
      || includesCI(x.building, searchString);
  }, [searchString]);

  const deleteZone = async () => {
    setDeleteInProgress(true);
    if (zoneToDelete) {
      await execute({
        query: new PeopleCountingZoneDeleteCommand(
          zoneToDelete.virtualDeviceId
        ),
        successMessage: `${t('Management.ZoneDeleted', { ns: 'settingsPeopleCounting' })}${zoneToDelete.virtualDeviceName}`,
        errorMessage: `${t('Management.ZoneDeleteFailed', { ns: 'settingsPeopleCounting' })}${zoneToDelete.virtualDeviceName}`,
      });

      deleteDialogToggle();
      await fetchZones();
    }
    setDeleteInProgress(false);
  };

  const handleMeasurementsExport = async (timeRange: TimeRange, peopleCountingZoneIds: number[]) => {
    const query = new CsvPeopleCountingZonesWithMeasurementsAndMetricTypesQuery(peopleCountingZoneIds, timeRange.from.toISOString(), timeRange.to.toISOString());

    const fileName = `${tenant.name}${t('Management.FileNameExportEnterExitCount', { ns: 'settingsPeopleCounting' })}${format(timeRange.from, 'yyyyMMdd')}-${format(timeRange.to, 'yyyyMMdd')}.csv`;
    exportMeasurements(query, fileName);
  };

  const tableColumns: ITableColumn<PeopleCountingZone>[] = [
    {
      label: t('Space', { ns: 'assets' }),
      key: 'virtualDeviceName',
    },
    {
      label: t('Management.Building', { ns: 'settingsPeopleCounting' }),
      key: 'building',
    },
    {
      label: t('Management.InboundDevices', { ns: 'settingsPeopleCounting' }),
      key: 'inboundDevices',
      sortFormat: zone => zone.physicalDevices.filter(x => x.direction === 'Inbound').length,
      displayFormat: zone => zone.physicalDevices.filter(x => x.direction === 'Inbound').length.toString(),
    },
    {
      label: t('Management.OutboundDevices', { ns: 'settingsPeopleCounting' }),
      key: 'outboundDevices',
      sortFormat: zone => zone.physicalDevices.filter(x => x.direction === 'Outbound').length,
      displayFormat: zone => zone.physicalDevices.filter(x => x.direction === 'Outbound').length.toString(),
    },
    {
      key: 'action',
      width: 1,
      customElement: zone => (
        <Button
          tertiary
          circle
          label={<FontAwesomeIcon icon={solid('trash-xmark')} style={{ width: '18px', height: '18px' }} />}
          color={theme.palette.red}
          style={{ marginLeft: '5px' }}
          onClick={() => {
            setZoneToDelete(zone);
            deleteDialogToggle();
          }}
        />
      )
    },
  ];

  return (
    <>
      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('Management.DeleteZoneConfirm', { ns: 'settingsPeopleCounting' })}
        confirmButton={t('Management.Delete', { ns: 'settingsPeopleCounting' })}
        onCancel={deleteDialogToggle}
        onConfirm={() => deleteZone()}
        isLoading={deleteInProgress}
      />
      <PaddedContainer largePadding>
        <FlexContainer>
          <Title text={<>{t('Management.PeopleCountingZones', { ns: 'settingsPeopleCounting' })} <ThemeColored>({peopleCountingZones.length})</ThemeColored></>} />

          <RightAligned>
            <SearchField
              placeholder={t('Management.SearchZones', { ns: 'settingsPeopleCounting' })}
              onSearchChange={setSearchString}
              style={{ background: theme.action.filled }}
            />
            <ButtonWithPeopleCountZoneAndDateSelector
              onChange={handleMeasurementsExport}
              peopleCountingZones={peopleCountingZones}
              loading={loadingMeasurementsExport}
            />
            <Link to={'create'}>
              <Button
                tertiary
                circle
                label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
              />
            </Link>
          </RightAligned>
        </FlexContainer>

        <Table
          columns={tableColumns}
          records={peopleCountingZones}
          recordKey="virtualDeviceId"
          defaultSortColumn="virtualDeviceName"
          emptyMessage={t('Management.NoZonesFound', { ns: 'settingsPeopleCounting' })}
          onRowClick={zone => navigate(`assign/${zone.virtualDeviceId}`)}
          cardEffect
          loading={loading}
          filters={[searchFilter]}
          highlightString={searchString}
          fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 200}
        />
      </PaddedContainer>
    </>
  );
};

export default PeopleCountingZoneList;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const RightAligned = styled.div`
  margin-left: auto;

  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const ThemeColored = styled.span`
  color: ${(p) => p.theme.palette.primary};
`;
