import { SpaceFairUsePolicy } from '@shared/api/models/FairUse/SpaceFairUsePolicy';
import { GenericPatchQuery } from '../../common/GenericPatchQuery';

export class SpaceFairUsePolicyBulkUpdateCommand extends GenericPatchQuery<SpaceFairUsePolicy> {
  spaceFairUsePolicies: SpaceFairUsePolicy[];
    
  constructor(spaceFairUsePolicies: SpaceFairUsePolicy[]){
    super();
    this.spaceFairUsePolicies = spaceFairUsePolicies;
  }
    
  public targetUrl(): string {
    return '/FairUse/Exceptions/BulkUpdate';
  }
}