import { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export const useHasMinimumHeight = (minimumHeight: number) => {
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const [hasMinimumHeight, setHasMinimumHeight] = useState(false);

  useEffect(() => {
    if (height !== undefined) {
      setHasMinimumHeight(height >= minimumHeight);
    }
  }, [height, minimumHeight]);

  return {
    ref,
    height,
    hasMinimumHeight
  };
};
