import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cardDotDark from '@shared/assets/images/card-dot-dark.svg';
import cardDot from '@shared/assets/images/card-dot.svg';
import { Card } from '@shared/components/molecules/Card/Card';
import { useThemeContext } from '@shared/contexts/ThemeContext/ThemeContext';
import { WeatherIconColors } from '@shared/utils/WeatherUtils';
import styled from 'styled-components';

type PerformanceCardsProps = {
  label: string;
  unit: string;
  value?: number;
  error?: boolean;
  errorMessage?: string;
  icon?: IconProp;
  iconColor?: WeatherIconColors;
}

const PerformanceCard = ({ value, label, unit, error, errorMessage, icon, iconColor }: PerformanceCardsProps) => {
  const { isDarkTheme } = useThemeContext();

  return (
    <StyledCard isDarkTheme={isDarkTheme} error={error}>
      {error
        ?
        <>
          <Value>
            <WarningIcon icon={solid('triangle-exclamation')} />
          </Value>
          <Label style={{ marginTop: 20 }}>{errorMessage}</Label>
        </>
        :
        <>
          <Value>
            {value}
            <Unit>{unit}</Unit>
            {(icon && iconColor) &&
              <Icon
                icon={icon}
                colors={iconColor}
              />
            }
          </Value>
          <Label>{label}</Label>
        </>
      }
    </StyledCard>
  )
}

export default PerformanceCard

const StyledCard = styled(Card) <{ isDarkTheme: boolean, error?: boolean }>`
  position: relative;
  height: 128px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  border: ${p => p.error ? `1px solid ${p.theme.warning.outlinedBorder}` : 'none'};
  border-radius: 10px;
  background-image: ${p => p.isDarkTheme ? `url(${cardDotDark})` : `url(${cardDot})`};
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 0px 2px 2px 0px ${p => p.theme.palette.shadows.extraLight};
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  font-size: 48px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
`;

const Unit = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  margin-top: -18px;
  margin-left: 2px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding-top: 8px;
  color: ${p => p.theme.text.secondary};
`;

const Icon = styled(FontAwesomeIcon) <{ colors: WeatherIconColors }>`
  width: 30px;
  height: 30px;
  margin-left: 20px;

  .fa-primary {
    color: ${p => p.colors.primary};
  }
  .fa-secondary {
    color: ${p => p.colors.secondary};
  }
`;

const WarningIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.warning.main};
  width: 30px;
  height: 30px;
`;