import { darken, transparentize } from 'polished';
import styled, { useTheme } from 'styled-components';

export interface IMeter {
  value: number,
  max?: number,
  label?: string,
  labelSuffix?: string,
  prefix?: string,
  suffix?: string,
  showMaxLabel?: boolean,
  maxLabelPrefix?: string,
  maxLabelSuffix?: string,
  color?: string,
  height?: string
  labelBarGap?: string;
}

const Meter = ({ label, value, max = 100, labelSuffix, prefix, suffix, showMaxLabel, maxLabelPrefix, maxLabelSuffix, color, height, labelBarGap }: IMeter) => {
  const theme = useTheme();
  const gradientColors: string[] = [];

  if (value > 0) {
    gradientColors.push(transparentize(0.1, color ?? theme.palette.primary));
    gradientColors.push(darken(0.04, color ?? theme.palette.primary));
  }

  if (value > max) {
    const exceededPercentage = max / value * 100;
    gradientColors.push(`${darken(0.04, color ?? theme.palette.primary)} ${exceededPercentage}%`);
    gradientColors.push(`${theme.palette.red} ${exceededPercentage}%`);
  }

  return (
    <div>
      <FlexRow paddingBottom={labelBarGap}>
        {label &&
          <Label>{label}{labelSuffix && <LabelSuffix>&nbsp;{labelSuffix}</LabelSuffix>}</Label>
        }
        <Value>
          {prefix && <PreSuffix>{prefix}</PreSuffix>}{value}{suffix && <PreSuffix>{suffix}</PreSuffix>}
          {showMaxLabel &&
            <MaxValue>
              &nbsp;/{maxLabelPrefix}{max}{maxLabelSuffix}
            </MaxValue>
          }
        </Value>
      </FlexRow>
      <Bar height={height}>
        <ValueBar
          widthFactor={value / max}
          gradientColors={gradientColors.join()}
        />
      </Bar>
    </div>
  );
};


export default Meter;

const FlexRow = styled.div<{ paddingBottom?: string }>`
  display: flex;
  align-items: center;
  line-height: 20px;
  padding-bottom: ${p => p.paddingBottom ?? '10px'};
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const LabelSuffix = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const Value = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};

  margin-left: auto;
`;

const PreSuffix = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
`;

const MaxValue = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const Bar = styled.div<{ height?: string }>`
  width: 100%;
  height: ${p => p.height ?? '6px'};
  background-color: ${p => p.theme.palette.borders.medium};
  border-radius: 16px;
`;

const ValueBar = styled.div<{ widthFactor: number, gradientColors: string }>`
  height: 100%;
  width: ${p => `min(calc(100% * ${p.widthFactor}), 100%)`};
  background: ${p => `linear-gradient(90deg, ${p.gradientColors})`};
  border-radius: 16px;

  transition: width 300ms ease;
`;