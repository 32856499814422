import { SpaceClimateControlDetailsDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDetailsDto';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { RefObject } from 'react';
import SpaceDetailsModalContent from './SpaceDetailsModalContent';

type PropTypes = {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  onClose: () => void;
  onDeleteException: () => void;
  onCreateException: (spaceId: number) => void;
  onEditException: (spaceId: number) => void;
  spaceDetails?: SpaceClimateControlDetailsDto;
  unit: string
}

const SpaceDetailsModal = ({ modalRef, isOpen, onClose, onDeleteException, onCreateException, onEditException, spaceDetails, unit }: PropTypes) => {
  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      hide={onClose}
      plainModal={true}
      width={'520px'}
    >
      <SpaceDetailsModalContent
        spaceDetails={spaceDetails}
        unit={unit}
        onClose={onClose}
        onDeleteException={onDeleteException}
        onCreateException={onCreateException}
        onEditException={onEditException}
      />
    </Modal>
  );
};

export default SpaceDetailsModal;