import { transparentize } from 'polished';
import { CSSProperties } from 'react';
import { useTheme } from 'styled-components';

interface ITextHighlighter {
  text: string;
  highlight: string;
  highlightStyle?: CSSProperties;
}

const TextHighlighter = ({ text, highlight, highlightStyle }: ITextHighlighter) => {
  const theme = useTheme();
  const defaultHighlightStyle: CSSProperties = { backgroundColor: transparentize(0.3, theme.palette.orange) };

  let parts: string[] | undefined = undefined;

  try {
    const saveRegex = highlight.replace(/[[()+*?\\]/g, '\\$&');
    const regex = new RegExp(`(${saveRegex})`, 'gi');
    parts = text.split(regex);
  } catch (error) {
    console.log('TextHighlighter Regex Error:', error);
  }

  return (
    <div>
      {!parts && text}

      {parts?.map((part, i) => {
        const style = part.toLowerCase() === highlight.toLowerCase()
          ? (highlightStyle ?? defaultHighlightStyle)
          : {};

        return (
          <span key={i} style={style}>
            {part}
          </span>
        );
      })}
    </div>
  );
};

export default TextHighlighter;