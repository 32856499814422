import { HeatmapSpaceDto } from '@dashboard/api/models/HeatmapSpaceDto';
import { HeatmapConfig } from '@dashboard/components/organisms/BuildingPage/BuildingPage_Heatmap';
import { SpaceType_Color } from '@shared/api/enums/SpaceType/SpaceType_Color';
import { SpaceType_Icon } from '@shared/api/enums/SpaceType/SpaceType_Icon';
import { Icon } from '@shared/components/atoms/Icon/Icon';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';
import HeatmapTooltipContent from './HeatmapTooltipContent';

type PropTypes = {
  space: HeatmapSpaceDto;
  config: HeatmapConfig;
  noContent: boolean;
  children?: ReactNode;
}

const HeatmapTooltip = ({ space, config, noContent, children }: PropTypes) => {
  const theme = useTheme();

  const TooltipContent = (
    <Wrapper>
      <Header>
        <Icon
          icon={SpaceType_Icon(space.spaceType)}
          iconSize='22px'
          squareSize='34px'
          squareColor={SpaceType_Color(space.spaceType)}
          iconColor='#fff'
        />
        <div>
          <SpaceName>
            {space.spaceName}
          </SpaceName>
          <WeakText>
            {space.floorName}
          </WeakText>
        </div>
      </Header>

      {!noContent &&
        <Content>
          <HeatmapTooltipContent
            space={space}
            config={config}
          />
        </Content>
      }
    </Wrapper>
  );

  return (
    <Tooltip
      content={TooltipContent}
      tooltipColor={theme.palette.backgrounds.surface}
      styles={{ padding: 0, maxWidth: '300px' }}
    >
      {children}
    </Tooltip>
  );
};

export default HeatmapTooltip;

const Wrapper = styled.div`
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
  padding-bottom: 10px;
  padding: 15px 17px;
`;

const SpaceName = styled.span`
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  display: block;
`;

const WeakText = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
  display: block;
`;

const Content = styled.div`
  padding: 15px 17px 17px 17px;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};
`;