import { SpaceType } from './SpaceType';

/**
 * Get user-friendly display string
 */
export const SpaceType_DisplayString = (spaceType: SpaceType): string => {
  return spaceTypeToDisplayString[spaceType] ?? spaceType;
};

/**
 * Map SpaceType to a user-friendly display string
 */
const spaceTypeToDisplayString: { [key in SpaceType]: string } = Object.freeze({
  /** Common */
  [SpaceType.PrivateDining]: 'Private Dining',
  [SpaceType.Reception]: 'Reception',
  [SpaceType.Gym]: 'Gym',
  [SpaceType.Cinema]: 'Cinema',
  [SpaceType.Cupboard]: 'Cupboard',
  [SpaceType.Kitchen]: 'Kitchen',
  [SpaceType.Bathroom]: 'Bathroom',
  [SpaceType.Toilet]: 'Toilet',
  [SpaceType.Elevator]: 'Elevator',
  [SpaceType.Library]: 'Library',
  [SpaceType.Lounge]: 'Lounge',
  [SpaceType.Other]: 'Other',

  /** Office */
  [SpaceType.Boardroom]: 'Boardroom',
  [SpaceType.MeetingRoom]: 'Meeting Room',
  [SpaceType.OpenPlan]: 'Open Plan',
  [SpaceType.PrivateOffice]: 'Private Office',

  /** Apartment */
  [SpaceType.Studio]: 'Studio',
  [SpaceType.OneBed]: '1 Bed',
  [SpaceType.TwoBed]: '2 Bed',
  [SpaceType.ThreeBed]: '3 Bed',
  [SpaceType.FourBed]: '4 Bed',
  [SpaceType.DuplexTwoBed]: 'Duplex - 2 Bed',
  [SpaceType.DuplexThreeBed]: 'Duplex - 3 Bed',
  [SpaceType.DuplexFourBed]: 'Duplex - 4 Bed',
  [SpaceType.SharerTwoBed]: 'Sharer - 2 Bed',
  [SpaceType.SharerThreeBed]: 'Sharer - 3 Bed',
  [SpaceType.SharerFourBed]: 'Sharer - 4 Bed',
  [SpaceType.SharerFiveBed]: 'Sharer - 5 Bed',
  [SpaceType.SharerSixBed]: 'Sharer - 6 Bed',
  [SpaceType.SharerSevenBed]: 'Sharer - 7 Bed',
  [SpaceType.SharerEightBed]: 'Sharer - 8 Bed',
  [SpaceType.LKD]: 'LKD',
  [SpaceType.Penthouse]: 'Penthouse',
  [SpaceType.Bedroom]: 'Bedroom',
});