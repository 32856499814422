import styled, { useTheme } from 'styled-components';
import { Button } from '@shared/components/atoms/Button/Button';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { IInteractiveDialog } from './InteractiveDialog.types';

export const InteractiveDialog = ({ modalRef, isOpen, hide, title, content, confirmButton, cancelButton, width }: IInteractiveDialog) => {
  const theme = useTheme();

  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      title={title}
      hide={hide}
      width={width ? width : '500px'}
      footer={
        <FlexRow>
          <Button
            tertiary
            color={theme.palette.red}
            {...cancelButton}
          />
          <Button {...confirmButton} />
        </FlexRow>
      }
    >
      {content}
    </Modal>
  );
};

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`;