import styled from 'styled-components';
import { Alert, AlertStatus } from '@shared/api/models/Alert/Alert';
import AlertModal from '@shared/components/molecules/TopBar/TopBarAlerts/AlertModal/AlertModal';
import { useModal } from '@shared/hooks/useModal';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import AlertUpdateCommand from '@shared/api/queries/Alerts/AlertUpdateCommand';
import { useApi } from '@shared/hooks/useApi';

type PropTypes = {
  alert: Alert,
  refreshAlerts: () => void
}

const AlertBannerItem = ({ alert, refreshAlerts }: PropTypes) => {
  const { isOpen, toggle, ref } = useModal({});
  const onMobile = useMediaQuery('(max-width: 900px)');
  const { execute } = useApi();

  const handleAcknowledgeAlert = async () => {
    await execute({
      query: new AlertUpdateCommand(alert.id, AlertStatus.Acknowledged),
    });

    refreshAlerts();
    toggle();
  }

  return (
    <>
      <AlertOpenModalButton onClick={toggle}>
        <Medium>{alert.message.description}</Medium> - {alert.space.floor.building.name}, {alert.space.name}
      </AlertOpenModalButton>

      <AlertModal
        modalRef={ref}
        isOpen={isOpen}
        closeModal={toggle}
        alert={alert}
        onMobile={onMobile}
        acknowledgeAlert={handleAcknowledgeAlert}
      />
    </>
  )
}

export default AlertBannerItem;

const AlertOpenModalButton = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  
  &:hover, &:focus {
    text-decoration: underline;
  }
`;

const Medium = styled.span`
  font-weight: 500;
`