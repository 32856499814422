import { ChartDataset } from '@shared/components/molecules/Charts/Chart.types';
import { transparentize } from 'polished';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

//#region ChartLegend component

type PropTypes = {
  datasets: ChartDataset[],
  onchange: (hiddenDatasets: number[]) => void
}

const ChartLegend = ({ datasets, onchange }: PropTypes) => {
  const [hiddenDatasets, setHiddenDatasets] = useState<number[]>([]);

  /**
   * Reset when datasets change
   */
  useEffect(() => {
    setHiddenDatasets([]);
  }, [datasets]);

  /**
   * Modify the array containing indexes of the hidden datasets as they are selected/deselected in the legend.
   */
  const handleToggle = (index: number) => {
    const hiddenDatasetsCopy = [...hiddenDatasets];
    const arrayIndex = hiddenDatasetsCopy.indexOf(index);

    if (arrayIndex >= 0) {
      hiddenDatasetsCopy.splice(arrayIndex, 1)
    } else {
      hiddenDatasetsCopy.push(index);
    }

    setHiddenDatasets(hiddenDatasetsCopy);
    onchange(hiddenDatasetsCopy);
  };

  return (
    <FlexContainer>
      {datasets.map(({ displayLabel, legendSubLabel, color }, i) => (
        <Item
          key={i}
          label={displayLabel}
          subLabel={legendSubLabel}
          color={color}
          index={i}
          toggle={handleToggle}
          hidden={hiddenDatasets.includes(i)}
        />
      ))}
    </FlexContainer>
  );
};

export default ChartLegend;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 6px;
  padding: 0 0 0 20px;

  width: 208px;
  overflow-x: auto;

  border-left: 1px solid ${p => p.theme.palette.borders.weak};
`;

//#endregion

//#region LegendItem component

type ItemProps = {
  label: string,
  subLabel?: string,
  color: string,
  index: number,
  toggle: (index: number) => void,
  hidden: boolean
}

const Item = ({ label, subLabel, color, index, toggle, hidden }: ItemProps) => {
  return (
    <StyledItem
      onClick={() => toggle(index)}
      inactive={hidden}
      color={color}
      hasSubLabel={!!subLabel}
    >
      <ColoredDot color={color} inactive={hidden} />
      <div>
        <Label inactive={hidden} hasSubLabel={!!subLabel}>
          {label}
        </Label>
        <SubLabel>
          {subLabel}
        </SubLabel>
      </div>
    </StyledItem>
  );
};

const StyledItem = styled.div<{ inactive: boolean, color: string, hasSubLabel: boolean }>`
  padding: ${p => p.hasSubLabel ? '4px 0px' : '7px 0px'};
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;

  display: flex;
  gap: 12px;
`;

const Label = styled.div<{ inactive: boolean, hasSubLabel: boolean }>`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.inactive ? p.theme.palette.text.weak : p.theme.palette.text.fair};

  user-select: none;
  
  ${p => p.hasSubLabel && css`
    margin-bottom: -3px;
  `}
`;

const SubLabel = styled.div`
  display: block;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
  font-size: 12px;
`;

const ColoredDot = styled.div<{ color: string, inactive: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${p => transparentize(p.inactive ? 0.5 : 0, p.color)};
  margin-top: 5px;
  flex-shrink: 0;
`;

//#endregion