import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { round } from '@shared/utils/NumberUtils';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { UtilityData } from './UtilitiesSummary';

type PropTypes = {
  data: UtilityData;
};

const PercentageLabel = ({ data }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!data.targetYTD || !data.total) {
    return null;
  }

  const percentage = round((1 - (data.total / data.targetYTD)) * 100, 0);

  let label = t('SiteOverview.OnTarget', { ns: 'molecules' });
  let color = theme.palette.primary;
  let icon = null;

  if (percentage > 0) {
    label = `${percentage}% ${t('SiteOverview.BelowTarget', { ns: 'molecules' })}`;
    color = theme.palette.siteOverview.utilities.green;
    icon = solid('chevrons-down');
  } else if (percentage < 0) {
    label = `${Math.abs(percentage)}% ${t('SiteOverview.AboveTarget', { ns: 'molecules' })}`;
    color = theme.palette.siteOverview.utilities.red;
    icon = solid('chevrons-up');
  }

  return (
    <PercentageBatch color={color}>
      {icon &&
        <Icon icon={icon} />
      }

      {label}
    </PercentageBatch>
  );
};

export default PercentageLabel;

const PercentageBatch = styled.div<{ color: string }>`
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  background-color: ${p => p.color};
  border-radius: 4px;
  padding: 4px 8px;

  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 12px;
  margin-right: 5px;
`;