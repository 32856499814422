import styled, { css } from 'styled-components';
import { FilterDropdown } from './FilterDropdown';
import { cloneDeep } from 'lodash';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { usePopover } from '@shared/hooks/usePopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export type FilterOptionGroup<TValue> = {
  label?: string;
  icon?: JSX.Element;
  options: FilterOption<TValue>[];
}

export type FilterOption<TValue> = {
  label: string;
  displayElement?: ReactNode;
  value: TValue;
  selected?: boolean;
}

type PropTypes<TValue> = {
  label: string;
  options: FilterOption<TValue>[] | FilterOptionGroup<TValue>[];
  onChange: (selected: TValue[]) => void;
  styles?: React.CSSProperties;
  resetPulse?: number;
  rightAlign?: boolean;
}

const Filter = <TValue,>({ label, options: optionProps, onChange, styles, resetPulse, rightAlign }: PropTypes<TValue>) => {
  const [originalOptions, setOriginalOptions] = useState<FilterOptionGroup<TValue>[]>([]);
  const [options, setOptions] = useState<FilterOptionGroup<TValue>[]>([]);
  const { ref, visible, toggle } = usePopover({});
  const selectCount = options.flatMap(x => x.options).filter(x => x.selected).length;

  useEffect(() => {
    let optionGroups: FilterOptionGroup<TValue>[] = [];

    if (optionProps.length > 0 && 'value' in optionProps[0]) {
      optionGroups = [{ options: optionProps as FilterOption<TValue>[] }];
    } else {
      optionGroups = optionProps as FilterOptionGroup<TValue>[];
    }

    setOriginalOptions(optionGroups);
    setOptions(optionGroups);
  }, [optionProps]);

  const onSelect = (groupIndex: number, optionIndex: number) => {
    const clonedOptions = cloneDeep(options);

    clonedOptions[groupIndex] = {
      ...clonedOptions[groupIndex],
      options: clonedOptions[groupIndex].options.map((option, i) => ({
        ...option,
        selected: i === optionIndex ? !option.selected : option.selected
      }))
    };

    setOptions(clonedOptions);
    onChange(clonedOptions.flatMap(x => x.options).filter(x => x.selected).map(x => x.value));
  };

  const setAllTo = useCallback((state: boolean) => {
    const clonedOptions = cloneDeep(originalOptions).map(group => ({
      ...group,
      options: group.options.map(opt => ({ ...opt, selected: state }))
    }));

    setOptions(clonedOptions);
    onChange(clonedOptions.flatMap(x => x.options).filter(x => x.selected).map(x => x.value));
  }, [originalOptions, onChange]);

  const selectAll = useCallback(() => {
    setAllTo(true);
  }, [setAllTo]);

  const clear = useCallback(() => {
    setAllTo(false);
  }, [setAllTo]);

  useEffect(() => {
    if (resetPulse) {
      setAllTo(false);
    }
  }, [resetPulse, setAllTo]);

  return (
    <Container ref={ref} style={styles}>
      <FlexRow isActive={visible} onClick={toggle}>
        <Label isActive={visible}>
          {label}
        </Label>
        {selectCount > 0 &&
          <Count>
            {selectCount}
          </Count>
        }

        <ChevronIcon
          $isActive={visible}
          icon={solid('chevron-down')}
        />
      </FlexRow>

      <DropdownWrapper show={visible} rightAlign={rightAlign}>
        <FilterDropdown
          options={options}
          onSelect={onSelect}
          selectAll={selectAll}
          clear={clear}
        />
      </DropdownWrapper>
    </Container>
  );
};

export default Filter;

const Container = styled.div`
  position: relative;
`;

const FlexRow = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 4px;

  &:hover {
    background-color: ${p => p.theme.primary.hover};
  }

  ${p => p.isActive && css`
    background-color: ${p => p.theme.primary.hover};
    color: ${p => p.theme.primary.main} !important;
  `}
`;

const Label = styled.div<{ isActive: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};

  ${p => p.isActive && css`
    color: ${p => p.theme.primary.main} !important;
  `}
`;

const Count = styled.div`
  font-size: 12px;
  font-weight: 500;
  min-width: 18px;
  padding: 0px 5px;
  border-radius: 8px;
  color: ${p => p.theme.text.contrast};
  background-color: ${p => p.theme.primary.main};
  text-align: center;
`;

const ChevronIcon = styled(FontAwesomeIcon) <{ $isActive: boolean }>`
  font-size: 12px;
  margin-left: 5px;
  color: ${p => p.theme.text.secondary};

  ${p => p.$isActive && css`
    color: ${p => p.theme.primary.main} !important;
  `}
`;

const DropdownWrapper = styled.div<{ show?: boolean, rightAlign?: boolean }>`
  position: absolute;
  display: ${p => p.show ? 'flex' : 'none'};
  z-index: 12;
  top: 44px;
  left: 0;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 6px 12px 0 ${p => p.theme.palette.dropdown.shadowWeak}, 0 3px 6px -4px ${p => p.theme.palette.dropdown.shadowStrong}, 0 9px 25px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 300px;

  ${p => p.rightAlign && css`
    left: unset;
    right: 0;
  `}
`;