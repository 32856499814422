import { NewUser } from '@shared/api/models/User/NewUser';
import { UserSummary } from '@shared/api/models/User/UserSummary';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class UserCreateCommand extends PostQuery<UserSummary> {
  fullName: string;
  displayName?: string;
  email: string;
  phoneNumber: string;
  roleId: number;

  constructor(user: NewUser) {
    super();
    this.fullName = user.fullName;
    this.displayName = user.displayName;
    this.email = user.email;
    this.phoneNumber = user.phoneNumber;
    this.roleId = user.roleId;
  }

  public targetUrl(): string {
    return '/users';
  }
}
