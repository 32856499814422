import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { cloneDeep, isEmpty } from 'lodash';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import FloorCreateCommand from '@settings/api/queries/Floors/FloorCreateCommand';
import FloorDeleteCommand from '@settings/api/queries/Floors/FloorDeleteCommand';
import FloorUpdateCommand from '@settings/api/queries/Floors/FloorUpdateCommand';
import FloorplanDeleteCommand from '@settings/api/queries/Floors/FloorplanDeleteCommand';
import FloorplanUploadCommand from '@settings/api/queries/Floors/FloorplanUploadCommand';
import { Floor, instanceOfFloor } from '@shared/api/models/Floor/Floor';
import { NewFloor, instanceOfNewFloor } from '@shared/api/models/Floor/NewFloor';
import { BlobFileUploadArea } from '@shared/components/molecules/BlobFileUploadArea/BlobFileUploadArea';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useApi } from '@shared/hooks/useApi';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { valuesDiffer } from '@shared/utils/ObjectUtils';
import { useModal } from '@shared/hooks/useModal';
import { Button } from '@shared/components/atoms/Button/Button';
import { Card } from '@shared/components/molecules/Card/Card';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

interface IBuildingFloorEdit {
  buildingId: number,
  floor: Floor | NewFloor,
  index: number,
  onFloorCreate: (updatedFloor: Floor, index: number) => void,
  onFloorUpdate: (updatedFloor: Floor, index: number) => void,
  onFloorDelete: (index: number) => void
}

type FormValues = {
  name: string,
  shortCode?: string,
  floorNumber: number,
}

const BuildingFloorEdit = ({ buildingId, floor, index, onFloorUpdate, onFloorDelete, onFloorCreate }: IBuildingFloorEdit) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const { refreshBuildings } = useSiteContext();
  const [editMode, setEditMode] = useState(false);
  const { register, watch, reset, handleSubmit, formState: { errors } } = useForm<FormValues>();
  const { file, fileName, fileHasChanged, fileToBeDeleted, handleFileChange, handleFileDelete, resetFile } = useFileHandler(floor.floorplanId);
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteConfirmationDialog, ref: deleteDialogRef } = useModal({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [deletingInProgress, setDeletingInProgress] = useState(false);
  const { execute } = useApi();
  const formValues = watch();

  const exitEditMode = () => {
    setEditMode(false)
    reset();
    resetFile();
  };

  useEffect(() => {
    if (instanceOfFloor(floor)) {
      setEditMode(false);
    } else {
      setEditMode(true);
    }

    reset({ name: floor.name, shortCode: floor.shortCode, floorNumber: floor.floorNumber });
  }, [floor, reset]);

  useEffect(() => {
    const hasBeenModified = valuesDiffer<FormValues, Floor | NewFloor>(formValues, floor);
    setHasUnsavedChanges(hasBeenModified || fileHasChanged || fileToBeDeleted);
  }, [floor, formValues, fileHasChanged, fileToBeDeleted]);

  const onSave: SubmitHandler<FormValues> = async data => {
    setEditMode(false);
    setSavingInProgress(true);

    if (instanceOfNewFloor(floor)) {
      await createFloor(data);
    } else {
      await updateFloor(floor, data);
    }

    refreshBuildings();
    setSavingInProgress(false);
  };

  const updateFloor = async (floorToUpdate: Floor, data: FormValues) => {
    const modifiedFloor = cloneDeep(floorToUpdate);
    modifiedFloor.name = data.name;
    modifiedFloor.floorNumber = data.floorNumber;
    modifiedFloor.shortCode = data.shortCode;

    const success = await updateFloorCall(modifiedFloor);
    const { floorplanId, isMounted } = await updateFloorplan(modifiedFloor);

    if (!success || !isMounted) {
      return;
    }

    modifiedFloor.floorplanId = floorplanId;

    onFloorUpdate(modifiedFloor, index);
  };

  const updateFloorCall = async (modifiedFloor: Floor) => {
    if (valuesDiffer(modifiedFloor, floor)) {
      return await execute({
        query: new FloorUpdateCommand(modifiedFloor.id, modifiedFloor.name, modifiedFloor.floorNumber, modifiedFloor.shortCode),
        successMessage: t('Buildings.Floors.FloorEditing.ChangesSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Buildings.Floors.FloorEditing.UpdateFailed', { ns: 'settingsAsset' })
      });
    } else {
      return true;
    }
  };

  const updateFloorplan = async (floor: Floor) => {
    let isMounted = true;
    if (fileHasChanged && file && floor.id) {
      const result = await execute({
        query: new FloorplanUploadCommand(file, floor.id),
        successMessage: t('Buildings.Floors.FloorEditing.ImageSaved', { ns: 'settingsAsset' }),
        errorMessage: t('Buildings.Floors.FloorEditing.UploadFailed', { ns: 'settingsAsset' })
      });
      floor.floorplanId = result?.blobName;
      isMounted = result !== undefined;
    } else if (fileToBeDeleted && floor.floorplanId && floor.id) {
      const result = await execute({
        query: new FloorplanDeleteCommand(floor.floorplanId, floor.id),
        successMessage: t('Buildings.Floors.FloorEditing.ImageDeleted', { ns: 'settingsAsset' }),
        errorMessage: t('Buildings.Floors.FloorEditing.DeleteFailed', { ns: 'settingsAsset' })
      });
      floor.floorplanId = undefined;
      isMounted = result !== undefined;
    }

    return { floorplanId: floor.floorplanId, isMounted };
  };

  const createFloor = async (data: FormValues) => {
    const floorDto = await execute({
      query: new FloorCreateCommand(buildingId, data.name, data.floorNumber, data.shortCode),
      successMessage: t('Buildings.Floors.FloorEditing.FloorCreated', { ns: 'settingsAsset' }),
      errorMessage: t('Buildings.Floors.FloorEditing.CreateFailed', { ns: 'settingsAsset' })
    });

    if (floorDto === undefined) {
      return;
    }

    await updateFloorplan(floorDto);
    onFloorCreate(floorDto, index);
  };

  const onDelete = async () => {
    if (instanceOfFloor(floor)) {
      setDeletingInProgress(true);
      await execute({
        query: new FloorDeleteCommand(floor.id),
        successMessage: t('Buildings.Floors.FloorEditing.FloorDeleted', { ns: 'settingsAsset' }),
        errorMessage: t('Buildings.Floors.FloorEditing.DeleteFloorFailed', { ns: 'settingsAsset' })
      });

      setDeletingInProgress(false);
      toggleDeleteConfirmationDialog();
      onFloorDelete(index);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <FlexRow>
        {editMode &&
          <>
            <Button
              tertiary
              label={instanceOfFloor(floor) ? t('Cancel', { ns: 'common' }) : t('Buildings.Floors.FloorEditing.Discard', { ns: 'settingsAsset' })}
              onClick={() => instanceOfFloor(floor) ? exitEditMode() : onFloorDelete(index)}
              color={theme.palette.red}
            />

            <Button
              label={t('Buildings.Floors.FloorEditing.Save', { ns: 'settingsAsset' })}
              onClick={handleSubmit(onSave)}
              disabled={!hasUnsavedChanges || !isEmpty(errors)}
              loading={savingInProgress}
            />
          </>
        }

        {!editMode &&
          <>
            <Button
              tertiary
              label={t('Buildings.Floors.FloorEditing.Delete', { ns: 'settingsAsset' })}
              onClick={toggleDeleteConfirmationDialog}
              color={theme.palette.red}
            />
            <WarningDialog
              modalRef={deleteDialogRef}
              isOpen={deleteDialogIsOpen}
              sectionOne={t('Buildings.Floors.FloorEditing.ConfirmDeleteFloor', { ns: 'settingsAsset' })}
              confirmButton={t('Buildings.Floors.FloorEditing.Delete', { ns: 'settingsAsset' })}
              onCancel={toggleDeleteConfirmationDialog}
              onConfirm={onDelete}
              isLoading={deletingInProgress}
            />

            <Button
              label={t('Buildings.Floors.FloorEditing.Edit', { ns: 'settingsAsset' })}
              onClick={() => setEditMode(true)}
            />
          </>
        }
      </FlexRow>

      <Card cardTitle={floor.name}>
        <Form>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <Label>{t('Buildings.Floors.FloorEditing.FloorName', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('name', {
                    required: t('Buildings.Floors.FloorEditing.RequiredField', { ns: 'settingsAsset' })
                  })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.name?.message}</ErrorMessage>
              </div>
              <div className="col-md-3">
                <Label>{t('Buildings.Floors.FloorEditing.Shortcode', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('shortCode', {
                    maxLength: { value: 4, message: t('Buildings.Floors.FloorEditing.MaxChar', { ns: 'settingsAsset' }) }
                  })}
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.shortCode?.message}</ErrorMessage>
              </div>
              <div className="col-md-2">
                <Label>{t('Buildings.Floors.FloorEditing.Number', { ns: 'settingsAsset' })}</Label>
                <Input
                  {...register('floorNumber', {
                    required: 'Required',
                    valueAsNumber: true
                  })}
                  type="number"
                  readOnly={!editMode}
                />
                <ErrorMessage>{errors.floorNumber?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>{t('Buildings.Floors.FloorEditing.Floorplan', { ns: 'settingsAsset' })}</Label>
                <BlobFileUploadArea
                  blobName={fileName}
                  mainText={t('Buildings.Floors.FloorEditing.UploadImage', { ns: 'settingsAsset' })}
                  dimText={t('Buildings.Floors.FloorEditing.Filetypes', { ns: 'settingsAsset' })}
                  acceptedTypes={['image/*']}
                  onFileChange={handleFileChange}
                  onFileDelete={handleFileDelete}
                  readOnly={!editMode}
                />
              </div>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default BuildingFloorEdit;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-bottom: 20px;
`;