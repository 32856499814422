import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { useTranslation } from 'react-i18next';
import styled, { CSSProperties, useTheme } from 'styled-components';
import SiteOperatorInfo from './SiteOperatorInfo/SiteOperatorInfo';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import SiteOperatorEditModal from './SiteOperatorInfo/SiteOperatorEditModal';
import { Button } from '@shared/components/atoms/Button/Button';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import ChallengeOverviewTable from './Challenges/ChallengeOverviewTable';
import { useNavigate } from 'react-router-dom';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import IconWithText from '@dashboard/components/molecules/ResidentApp/Common/IconWithText';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { useModal } from '@shared/hooks/useModal';
import PushNotificationsModal from './Notifications/PushNotificationsModal';
import { isMetricEnabled } from './ResidentAppUtils';
import Label from './Common/Label';
import { CustomResidentAppFeature } from '@shared/api/enums/ResidentAppFeatureType/ResidentAppFeatureType';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Card } from '@shared/components/molecules/Card/Card';
import TemperatureThresholdForm from './Thresholds/TemperatureThresholdForm';
import ElectricityThresholdForm from './Thresholds/ElectricityThresholdForm';
import { usePopover } from '@shared/hooks/usePopover';
import PageWrapper from './Common/PageWrapper';

const ResidentApp = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const navigate = useNavigate();
  const { siteOperator } = useSiteContext();
  const { isSuperAdmin } = useUserContext();
  const { ref, visible, toggle } = usePopover({});
  const {isOpen: isEditModalOpen, setIsOpen: setIsEditModalOpen} = useModal({disableCloseOnClickOutside: true});
  const {isOpen: isPushNotificationModalOpen, setIsOpen: setIsPushNotificationModalOpen} = useModal({disableCloseOnClickOutside: true});

  const getTooltipOptions = (label: string, style: CSSProperties) => ({
    content: label,
    placement: TooltipPlacement.Bottom,
    fontSize: '14px',
    styles: {...style, maxWidth: 215},
    tooltipColor: theme.palette.tooltip.background.primary,
    hideTriangle: false
  });

  const isCreateChallengeOrUserDisabled = () => {
    if (!siteOperator?.email) {
      return true;
    }
  
    const hasSiteThreshold = siteOperator?.siteThresholds?.some((threshold) => threshold.seasonTarget !== 0);
    const hasSpaceTypeThreshold = siteOperator?.spaceTypeThresholds?.some((threshold) => threshold.seasonTarget !== 0);
  
    const isElectricityEnabled = isMetricEnabled(siteOperator, MetricType.ElectricityKwh);
    const isTemperatureEnabled = isMetricEnabled(siteOperator, MetricType.Temperature);
  
    if (isElectricityEnabled) {
      if (isTemperatureEnabled) {
        return !hasSiteThreshold || !hasSpaceTypeThreshold;
      }
      return !hasSpaceTypeThreshold;
    }
  
    return !hasSiteThreshold;
  };

  // handle create challenge click
  const handleCreateChallenge = () => {
    if (!siteOperator) {
      return;
    }

    // toggle dropdown if both electricity and temperature are enabled
    if (isMetricEnabled(siteOperator, MetricType.ElectricityKwh) && isMetricEnabled(siteOperator, MetricType.Temperature)) {
      toggle();
      return;
    }

    // navigate to create temp challenge page if only temperature is enabled
    if (isMetricEnabled(siteOperator, MetricType.Temperature)) {
      navigate(`./create-challenge/${MetricType.Temperature}`);
      return;
    }

    navigate(`./create-challenge/${MetricType.ElectricityKwh}`);
  }

  return (
    <PageWrapper>
      {siteOperator && (
        <PaddedContainer>
          <TitleWithButton
            title={t('ResidentApp.ResidentApp', { ns: 'molecules' })}
            button={
              <ButtonContainer>
                {isSuperAdmin && (
                  <Button
                    disabled={isCreateChallengeOrUserDisabled()}
                    label={
                      <IconWithText
                        icon={regular('bell-on')}
                        text={t('ResidentApp.SendAPushNotification')}
                      />
                    }
                    onClick={() => setIsPushNotificationModalOpen(true)}
                    secondary
                    tooltip={
                      isCreateChallengeOrUserDisabled()
                        ? getTooltipOptions(t('ResidentApp.ManageUsersDisabledTooltip'), {
                          padding: 10,
                          marginRight: -25,
                        })
                        : undefined
                    }
                  />
                )}
                <Button
                  label={
                    <IconWithText icon={regular('users')} text={t('ResidentApp.ManageUsers')} />
                  }
                  onClick={() => navigate('./manage-users')}
                  style={{ marginRight: '-16px' }}
                  secondary
                  disabled={isCreateChallengeOrUserDisabled()}
                  tooltip={
                    isCreateChallengeOrUserDisabled()
                      ? getTooltipOptions(t('ResidentApp.ManageUsersDisabledTooltip'), {
                        padding: 10,
                        marginRight: -25,
                      })
                      : undefined
                  }
                />
              </ButtonContainer>
            }
          />
          <TitleWithButton
            title={t('ResidentApp.GeneralInformation')}
            titleSize="md"
            button={
              <Button
                label={<IconWithText icon={solid('pencil')} text={t('Edit', { ns: 'common' })} />}
                tertiary
                onClick={() => setIsEditModalOpen(true)}
              />
            }
            style={{ padding: '50px 0 3px' }}
          />
          <Label
            text={t('ResidentApp.GeneralInformationDescription')}
            style={{ marginBottom: 20 }}
          />
          <SiteOperatorInfo siteOperator={siteOperator} />

          {/* show space type thresholds for electricity if enabled */}
          {isMetricEnabled(siteOperator, MetricType.ElectricityKwh) &&
            siteOperator.spaceTypeThresholds && (
            <>
              <TitleWithButton
                title={t('ResidentApp.Thresholds')}
                titleSize="md"
                style={{ padding: '35px 0 3px' }}
              />
              <Label text={t('ResidentApp.ThresholdsDescription')} style={{ marginBottom: 20 }} />
              <Label text={t('ResidentApp.ThresholdDescription2')} style={{ marginBottom: 20 }} />
              <Card>
                <ElectricityThresholdForm
                  spaceTypeThresholds={siteOperator.spaceTypeThresholds}
                />
              </Card>
            </>
          )}

          {/* show site thresholds for temperature if enabled */}
          {isMetricEnabled(siteOperator, MetricType.Temperature) && siteOperator.siteThresholds && (
            <>
              {!isMetricEnabled(siteOperator, MetricType.ElectricityKwh) && (
                <>
                  <TitleWithButton
                    title={t('ResidentApp.Thresholds')}
                    titleSize="md"
                    style={{ padding: '35px 0 3px' }}
                  />
                  <Label
                    text={t('ResidentApp.ThresholdsDescription')}
                    style={{ marginBottom: 20 }}
                  />
                  <Label
                    text={t('ResidentApp.ThresholdDescription2')}
                    style={{ marginBottom: 20 }}
                  />
                </>
              )}

              <Card>
                <TemperatureThresholdForm siteThresholds={siteOperator.siteThresholds} />
              </Card>
            </>
          )}

          {/* hide challenge section if NoChallenges toggled on */}
          {!isMetricEnabled(siteOperator, CustomResidentAppFeature.NoChallenges) && (
            <>
              <TitleWithButton
                title={t('ResidentApp.Challenges')}
                titleSize="md"
                style={{ padding: '35px 0 20px' }}
                button={
                  <ButtonWithDropdown ref={ref}>
                    <Button
                      label={
                        <IconWithText
                          icon={solid('plus')}
                          text={t('ResidentApp.CreateChallenge')}
                        />
                      }
                      disabled={isCreateChallengeOrUserDisabled()}
                      onClick={handleCreateChallenge}
                      tooltip={
                        isCreateChallengeOrUserDisabled()
                          ? getTooltipOptions(t('ResidentApp.CreateChallengeDisabledTooltip'), {
                            padding: 10,
                          })
                          : undefined
                      }
                      style={{ minWidth: 195 }}
                    />
                    {visible && (
                      <ChallengeDropdown>
                        <PlainButton onClick={() => navigate(`./create-challenge/${MetricType.ElectricityKwh}`)}>
                          {t('ResidentApp.ElectricityChallenge')}
                        </PlainButton>
                        <PlainButton
                          onClick={() => navigate(`./create-challenge/${MetricType.Temperature}`)}
                        >
                          {t('ResidentApp.TemperatureChallenge')}
                        </PlainButton>
                      </ChallengeDropdown>
                    )}
                  </ButtonWithDropdown>
                }
              />
              <ChallengeOverviewTable hasSiteOperator={!!siteOperator.email} />
            </>
          )}
        </PaddedContainer>
      )}

      <SiteOperatorEditModal
        showModal={isEditModalOpen}
        onCloseModal={() => setIsEditModalOpen(false)}
      />
      <PushNotificationsModal
        showModal={isPushNotificationModalOpen}
        onCloseModal={() => setIsPushNotificationModalOpen(false)}
      />
    </PageWrapper>
  );
};

export default ResidentApp;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-right: 16px;
`;

const ButtonWithDropdown = styled.div`
  position: relative;
`;

const ChallengeDropdown = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${p => p.theme.primary.contrast};
  padding: 4px 0;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px ${p => p.theme.palette.dropdown.shadowStrong}, 0px 1px 3px 1px ${p => p.theme.palette.dropdown.shadowWeak};`;

const PlainButton = styled.button`
  padding: 8px 12px;
  margin: 0;
  width: 100%;
  text-align: left;
  background-color: ${p => p.theme.primary.contrast};
  border: none;
  font-size: 14px;
  color: ${p => p.theme.text.primary};
  font-family: 'DM Sans';
  cursor: pointer;
  transition: background-color 150ms ease-out;

  &:hover{
    background-color: ${p => p.theme.primary.hover};
  }
`;