import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { HeatmapSpaceDto } from '@dashboard/api/models/HeatmapSpaceDto';

type PropTypes = {
  space: HeatmapSpaceDto;
  isFirstOne: boolean;
  buildingId?: number;
}

const FloorIdentifierSquare = ({ space, isFirstOne, buildingId }: PropTypes) => {
  const tooltipContent = (
    <TooltipContent>
      {space.floorShortCode} - {space.floorName}
    </TooltipContent>
  );

  return (
    <Link to={`./../building/${buildingId}/floor/${space.floorId}`}>
      <Tooltip
        content={tooltipContent}
        placement={TooltipPlacement.Right}
        hideTriangle
      >
        <Square isFirstOne={isFirstOne}>
          {space.floorNumber}
        </Square>
      </Tooltip>
    </Link>
  );
};

export default FloorIdentifierSquare;

const Square = styled.div<{ isFirstOne: boolean }>`
  position: relative;
  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 3px;

  ${p => p.isFirstOne && css`
    margin-top: 45px;
  `}
`;

const TooltipContent = styled.div`
  font-size: 14px;
  padding: 5px 3px;
`;