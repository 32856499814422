import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { User } from '@shared/api/models/User/User';
import { Button } from '@shared/components/atoms/Button/Button';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import UserUpdateCommand from '@settings/api/queries/Users/UserUpdateCommand';
import { useEffect, useState } from 'react';
import { Label } from '@shared/components/atoms/Form/Form';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { Card } from '@shared/components/molecules/Card/Card';

type PropTypes = {
  user: User;
  refreshUser: () => void;
}

const UserMfaSettings = ({ user, refreshUser }: PropTypes) => {
  const { t } = useTranslation([]);
  const { execute, loading } = useApi();
  const { isSuperAdmin } = useUserContext();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    setIsEnabled(user.mfaEnabled);
  }, [user]);

  const reset = async () => {
    await execute({
      query: new UserUpdateCommand(user.id, user.fullName, user.phoneNumber, user.mfaEnabled, null, user.displayName),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    refreshUser();
  };

  const updateMfa = async (enabled: boolean) => {
    setIsEnabled(enabled);

    await execute({
      query: new UserUpdateCommand(user.id, user.fullName, user.phoneNumber, enabled, user.mfaMethod, user.displayName),
      successMessage: enabled ? t('MfaEnabled', { ns: 'status' }) : t('MfaDisabled', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    refreshUser();
  };

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <Card centered maxWidth='700px'>
      <Title>{t('MfaSettings.Title', { ns: 'settingsUser' })}</Title>

      <FlexRow style={{ justifyContent: 'space-between' }}>
        <FlexRow>
          <FlexColumn>
            <StyledLabel>
              {t('Enabled', { ns: 'common' })}
            </StyledLabel>

            <Switch
              checked={isEnabled}
              onChange={updateMfa}
              size='sm'
            />
          </FlexColumn>

          <FlexColumn>
            <StyledLabel>
              {t('MfaSettings.CurrentMethod', { ns: 'settingsUser' })}
            </StyledLabel>

            <Value>
              {user.mfaMethod
                ? t(`MfaSettings.Methods.${user.mfaMethod}`, { ns: 'settingsUser' })
                : '--'
              }
            </Value>
          </FlexColumn>
        </FlexRow>

        <Button
          tertiary
          label={t('MfaSettings.ResetMfaSettings', { ns: 'settingsUser' })}
          onClick={reset}
          disabled={!user.mfaMethod || loading}
        />
      </FlexRow>
    </Card>
  );
};

export default UserMfaSettings;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 20px 50px;
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  margin-bottom: 15px;
`;

const StyledLabel = styled(Label)`
  margin-left: 0;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: ${p => p.theme.palette.text.medium};
`;