import DeviceListTenantLevel from '@settings/components/organisms/DeviceManagement/DeviceListTenantLevel';
import FeatureManagement from '@settings/components/organisms/FeatureManagement/FeatureManagement';
import TenantCreate from '@settings/components/organisms/TenantManagement/TenantCreate/TenantCreate';
import TenantEdit from '@settings/components/organisms/TenantManagement/TenantEdit/TenantEdit';
import TenantList from '@settings/components/organisms/TenantManagement/TenantList/TenantList';
import TenantUserList from '@settings/components/organisms/TenantUserManagement/TenantUserList';
import { Route } from '@shared/types/Route';

export const SettingsRoutes: Route[] = [
  {
    path: 'devices',
    element: DeviceListTenantLevel,
    link: {
      label: 'Devices',
      path: 'devices',
      category: 'TenantLevel'
    }
  },
  {
    path: 'tenants',
    element: TenantList,
    link: {
      label: 'Tenants',
      path: 'tenants',
      category: 'Global'
    }
  },
  {
    path: 'tenants/create',
    element: TenantCreate,
  },
  {
    path: 'tenants/:tenantId',
    element: TenantEdit,
  },
  {
    path: 'tenant-access',
    element: TenantUserList,
    link: {
      label: 'UserTenantAccess',
      path: 'tenant-access',
      category: 'Global'
    }
  },
  {
    path: 'features',
    element: FeatureManagement,
    link: {
      label: 'Features',
      path: 'features',
      category: 'Global'
    },
  }
]