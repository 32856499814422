import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import styled, { css } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { round } from '@shared/utils/NumberUtils';
import SystemMessage, { SystemMessageType } from '@shared/components/atoms/SystemMessage/SystemMessage';
import PercentageLabel from './PercentageLabel';
import ChartViewToggle, { ConsumptionChartView } from './ChartViewSelect';
import CumulativeChart from './Charts/CumulativeChart/CumulativeChart';
import MonthlyChart from './Charts/MonthlyChart/MonthlyChart';
import { UtilityData } from './UtilitiesSummary';
import { isNaN } from 'lodash';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { metricTypeToUtility } from './UtilityTabs';

const getTotalLabel = (total: number, unit: string) => {
  if (!total) {
    return `0${unit}`;
  }

  return `${round(total, 2)}${unit}`;
};

type ConsumptionWidgetProps = {
  metricType: MetricType;
  data: UtilityData;
  view: ConsumptionChartView;
  onViewChange: (view: ConsumptionChartView) => void;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const ConsumptionWidget = ({ metricType, data, view, onViewChange, conversions, onSetConversions }: ConsumptionWidgetProps) => {
  const { t } = useTranslation();

  const systemMessage = (
    <span>
      <Trans i18nKey='molecules:SiteOverview.MissingConsumptionTarget'>
        {t(`SiteOverview.${metricType}`, { ns: 'molecules' })}
        <StyledLink
          link={{
            path: './../settings/site/metadata',
            analytics: { action: 'missing_target', category: 'performance_utilities', utilities_tab: metricTypeToUtility[metricType] }
          }}
        />
      </Trans>
    </span>
  );

  return (
    <Wrapper>
      <div>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <FlexRow>
            <Label>
              {new Date().getFullYear()} {t('SiteOverview.YearToDate', { ns: 'molecules' })}: <LabelValue>{getTotalLabel(data.total, data.unit)}</LabelValue>
            </Label>

            <PercentageLabel data={data} />
          </FlexRow>

          <ChartViewToggle
            selected={view}
            onChange={onViewChange}
          />
        </FlexRow>
      </div>

      {(data.target === null || isNaN(data.target)) &&
        <SystemMessage
          messages={[systemMessage]}
          type={SystemMessageType.Information}
          dissmisable
        />
      }

      <HiddenContainer visible={view === ConsumptionChartView.Monthly}>
        <MonthlyChart
          data={data}
          conversions={conversions}
          onSetConversions={onSetConversions}
        />
      </HiddenContainer>

      <HiddenContainer visible={view === ConsumptionChartView.Cumulative}>
        <CumulativeChart
          data={data}
          conversions={conversions}
          onSetConversions={onSetConversions}
        />
      </HiddenContainer>
    </Wrapper>
  );
};

export default ConsumptionWidget;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

const LabelValue = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const StyledLink = styled(ProtectedLink)`
  font-weight: 500;
  color: ${p => p.theme.palette.primary} !important;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const HiddenContainer = styled.div<{ visible: boolean }>`
  display: none;

  ${p => p.visible && css`
    display: block;
  `}
`;