import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SiteLevelFeatureFlag } from '@shared/api/enums/FeatureFlag/SiteLevelFeatureFlag';
import { SiteFeature as TSiteFeature } from '@shared/api/models/Feature/SiteFeature';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { TFunction } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import SiteFeature from './SiteFeature';
import { useTranslation } from 'react-i18next';
import { SiteFeatureCreateCommand } from '@shared/api/queries/SiteFeatures/SiteFeatureCreateCommand';
import { SiteFeatureDeleteCommand } from '@shared/api/queries/SiteFeatures/SiteFeatureDeleteCommand';
import { useApi } from '@shared/hooks/useApi';
import { Site } from '@shared/api/models/Site/Site';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';

const getFeatureConfig = (feature: TSiteFeature, t: TFunction) => {
  switch (feature.featureFlag) {
    case SiteLevelFeatureFlag.SmartMeters:
      return {
        name: t('FeatureFlags.SmartMetering.Name', { ns: 'common' }),
        description: t('FeatureFlags.SmartMetering.Description', { ns: 'common' }),
        icon: solid('meter-bolt')
      }

    case SiteLevelFeatureFlag.HeatingSchedules:
      return {
        name: t('FeatureFlags.HeatingSchedules.Name', { ns: 'common' }),
        description: t('FeatureFlags.HeatingSchedules.Description', { ns: 'common' }),
        icon: solid('timer')
      }

    default:
      return {
        name: feature.name,
        description: feature.description,
        icon: solid('stars')
      };
  }
};

type PropTypes = {
  site: Site;
  feature: TSiteFeature;
}

const SiteFeatureGeneric = ({ site, feature }: PropTypes) => {
  const { t } = useTranslation();
  const { hasFeatureFlag: hasTenantFeatureFlag } = useTenantContext();
  const { hasSiteFeature, refreshSiteFeatures } = useSiteContext();
  const { execute, loading } = useApi();
  const [isChecked, setIsChecked] = useState(hasSiteFeature(feature.featureFlag));

  useEffect(() => {
    setIsChecked(hasSiteFeature(feature.featureFlag));
  }, [feature, hasSiteFeature]);

  const addFeature = useCallback(async () => {
    await execute({
      query: new SiteFeatureCreateCommand(site.id, feature.id),
      successMessage: t('FeatureEnabled', { ns: 'settingsFeatures' })
    });
  }, [site, feature, execute, t]);

  const removeFeature = useCallback(async () => {
    await execute({
      query: new SiteFeatureDeleteCommand(site.id, feature.id),
      successMessage: t('FeatureDisabled', { ns: 'settingsFeatures' })
    });
  }, [site, feature, execute, t]);

  const handleToggle = useCallback(async (isChecked: boolean) => {
    setIsChecked(isChecked);

    if (isChecked && !hasSiteFeature(feature.featureFlag)) {
      await addFeature();
    } else {
      await removeFeature();
    }

    refreshSiteFeatures();
  }, [feature, addFeature, removeFeature, hasSiteFeature, refreshSiteFeatures]);

  // Hide feature toggle for features depending on a tenant feature
  if (feature.dependsOnFeature && !hasTenantFeatureFlag(feature.dependsOnFeature as FeatureFlag)) {
    return null;
  }

  const featureConfig = getFeatureConfig(feature, t);

  return (
    <SiteFeature
      name={featureConfig.name}
      description={featureConfig.description}
      icon={featureConfig.icon}
      isEnabled={isChecked}
      onChange={handleToggle}
      isLoading={loading}
    />
  );
};

export default SiteFeatureGeneric;