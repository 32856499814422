import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayoutContext } from '@src/contexts/LayoutContext';
import styled, { css } from 'styled-components';
import { Site } from '@shared/api/models/Site/Site';
import { BreadcrumbHeight } from '@src/constants/LayoutConstants';
import SiteSwitcher from './SiteSwitcher';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import WeatherWidget from '../WeatherWidget';
import SearchBar from '../SearchBar/SearchBar';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';

type PropTypes = {
  site: Site;
}

const Breadcrumb = ({ site }: PropTypes) => {
  const { hasFeatureFlag } = useTenantContext();
  const { isFullScreen, toggleSidebar } = useLayoutContext();
  const { trackAction } = useAnalytics();

  const handleToggle = () => {
    trackAction('minimise_feature_navigation', 'feature_navigation');
    toggleSidebar();
  };

  return (
    <FlexRow isFullScreen={isFullScreen}>
      <Icon
        icon={solid('bars')}
        onClick={handleToggle}
      />

      <Divider />

      <SiteSwitcher site={site} />

      <Chevron icon={solid('chevron-right')} />


      <SiteName>
        {site.name}
      </SiteName>

      <RightAligned>
        {hasFeatureFlag(FeatureFlag.SpaceSearch) &&
          <SearchBar />
        }
        <WeatherWidget />
      </RightAligned>
    </FlexRow>
  );
};

export default Breadcrumb;

const FlexRow = styled.div<{ isFullScreen: boolean }>`
  width: 100%;
  height: ${BreadcrumbHeight}px;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-shrink: 0;
  background-color: ${p => p.theme.background.container};
  border-bottom: 1px solid ${p => p.theme.action.divider};
  overflow: auto;
  z-index: 2;

  ${p => p.isFullScreen && css`
    display: none;
  `}

  @media print {
    display: none;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => p.theme.primary.main};
  padding-left: 16px;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${p => p.theme.action.divider};
`;

const RightAligned = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0 16px 0 auto;

  @media (min-width: 600px) {
    margin: 0 32px 0 auto;
  }
`;

const SiteName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
  letter-spacing: 0.4px;
`;

const Chevron = styled(FontAwesomeIcon)`
  font-size: 8px;
  color: ${p => p.theme.action.active};
`;