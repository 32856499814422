import { stringToFloat } from '@shared/utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: Electricity
 */
const HeatmapConfigElectricity: IHeatmapConfig = {
  accessor: (x: string) => stringToFloat(x, 0),
  displayFormat: (x) => x,

  scale: {
    categoryScale: true,
    points: [
      {
        threshold: '0',
        label: '0kWh',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: '0',
        comparator: 'GreaterThan',
        label: '>0kWh',
        color: DefaultScaleColors.BLUE,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigElectricity);