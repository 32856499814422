export enum SliderThumbStyle {
  Dot = 'Dot',
  OutlineDot = 'OutlineDot',
  Line = 'Line'
}

export enum ComparisonOperator {
  EqualTo = 'EqualTo',
  NotEqualTo = 'NotEqualTo',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo'
}

export interface ISliderThumbLimit {
  /**
   * A limit value, can be either a specific number or the 'key' value of another thumb,
   * the limit value is then determined by the value of the dependent thumb.
   */
  limit: number | string;
  /**
   * A compariso operator to compare the newValue and limit value.
   * Example: If set to 'ComparisonOperator.LessThan', the newValue must be less than the limitValue.
   */
  operator: ComparisonOperator.LessThan | ComparisonOperator.LessThanOrEqualTo | ComparisonOperator.GreaterThan | ComparisonOperator.GreaterThanOrEqualTo;
}

export interface ISliderThumb {
  /**
   * A unique key (in the context of a single slider) for this thumb.
   * If set, this thumb can act as a limit of another thumb.
   */
  key: string;
  /**
   * The value of the thumb
   */
  value: number;
  /**
   * The label of the value/thumb
   */
  label?: string;
  /**
   * The unit suffix of the value
   */
  unit?: string;
  /**
   * The color of the thumb.
   * If set, the color won't change. If undefined, the thumb will take the color of the slider gradient at its current position.
   */
  color?: string;
  /**
   * The style of the thumb
   */
  style?: SliderThumbStyle,
  /**
   * Use the color of the slider gradient at its current position.
   * Is automatically set to true when the "color" property is undefined.
   */
  useAutoColor?: boolean;
  /**
   * A list of limits, either numerical values or dependent on other thumbs.
   */
  limits?: ISliderThumbLimit[];
  /**
   * Disable thumb
   */
  isDisabled?: boolean;
}