import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { transparentize } from 'polished';
import EnergyDoughnutWidget from './EnergyDoughnutWidget';
import GenerationMixWidget from './GenerationMixWidget';
import { Trans, useTranslation } from 'react-i18next';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { ConsumptionType } from '@dashboard/api/enums/ConsumptionType';
import SiteGetAreaQuery from '@dashboard/api/queries/site/SiteGetAreaQuery';
import SiteEnergyConsumptionQuery from '@dashboard/api/queries/metrics/SiteEnergyConsumptionQuery';
import SiteEnergyConsumptionCostQuery from '@dashboard/api/queries/metrics/SiteEnergyConsumptionCostsQuery';
import { EnergyMeterCoverageGetBySiteIdQuery } from '@dashboard/api/queries/energyMeters/EnergyMeterCoverageGetBySiteIdQuery';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import FlexRow from '@dashboard/components/atoms/FlexRow/FlexRow';
import { round } from '@shared/utils/NumberUtils';
import DayMonthToggle from './DayMonthToggle';
import { useApiState } from '@shared/hooks/useApiState';
import EnergyConsumptionDto from '@dashboard/api/models/EnergyConsumptionDto';
import EnergyConsumptionCostDto from '@dashboard/api/models/EnergyConsumptionCostDto';
import { TFunction } from 'i18next';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { Link } from 'react-router-dom';
import GasEnergyDoughnutWidget from './GasEnergyDoughnutWidget';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';

const calculateCoverage = (t: TFunction, coverageArea?: number, siteArea?: number) => {
  if (coverageArea === undefined || siteArea === undefined) {
    return '';
  }

  const areaCoverageCalculation = coverageArea / siteArea * 100;

  return (siteArea === 0 || coverageArea === 0)
    ? ''
    : areaCoverageCalculation > 100
      ? `(100${t('EnergyWidgets.Coverage', { ns: 'molecules' })})`
      : `(${round(areaCoverageCalculation)}${t('EnergyWidgets.Coverage', { ns: 'molecules' })})`
};

const calculatePerSqMetre = (siteArea: number | undefined, consumption?: number,): number => {
  const totalArea = siteArea ?? 1;
  return (consumption && totalArea !== 0) ? consumption / totalArea : 0;
};

const createEnergyCalculations = (consumptions?: EnergyConsumptionDto[], costs?: EnergyConsumptionCostDto[], siteArea?: number) => {
  if (consumptions === undefined || costs === undefined) {
    return;
  }

  // TODO: [PP] Remove WaterVolumeDelta and GasVolumeDelta data from the array for now
  // We can use this elsewhere later when we are overhauling this page
  const excludedMetrics = [
    MetricType.WaterVolumeDelta,
    MetricType.GasVolumeDelta
  ];

  const filteredConsumptions = consumptions.filter(x => !excludedMetrics.includes(x.metricType));

  const filteredUsage = filteredConsumptions.map(x => ({ metricType: x.metricType, value: x.consumption }));
  const filteredCosts = costs.filter(x => x.metricType === MetricType.ElectricityKwhCost).map(x => ({ metricType: x.metricType, value: x.cost / 100 }));
  const filteredUsagePerSqMeter = filteredConsumptions.map(x => ({ metricType: x.metricType, value: calculatePerSqMetre(siteArea, x.consumption) }));

  const gasConsumptions = consumptions.filter(x => x.metricType === MetricType.GasVolumeDelta);
  const gasUsage = gasConsumptions.map(x => ({ metricType: x.metricType, value: x.consumption }));
  const gasCosts = costs.filter(x => x.metricType === MetricType.GasVolumeCost).map(x => ({ metricType: x.metricType, value: x.cost }));

  const calculations: EnergyCalculations = {
    usage: filteredUsage,
    costs: filteredCosts,
    usagePerSqMetre: filteredUsagePerSqMeter,
    gasUsage: gasUsage,
    gasCost: gasCosts
  }

  return calculations;
};

export type MetricTypeValue = {
  metricType: MetricType,
  value: number
};

type EnergyCalculations = {
  usage: MetricTypeValue[],
  costs: MetricTypeValue[],
  usagePerSqMetre: MetricTypeValue[],
  gasUsage: MetricTypeValue[],
  gasCost: MetricTypeValue[]
}

type ConsumptionTypeToEnergyCalculationsMapping = {
  [key: string]: EnergyCalculations
}

const EnergyWidgets = () => {
  const theme = useTheme();
  const { site } = useSiteContext();
  const { t } = useTranslation(['molecules']);
  const [energyCalculations, setEnergyCalculations] = useState<ConsumptionTypeToEnergyCalculationsMapping>();
  const [consumptionType, setConsumptionType] = useState<ConsumptionType>(ConsumptionType.MonthRolling);
  const colors = [theme.palette.primary, transparentize(0.6, theme.palette.primary)];
  const { hasFeatureFlag } = useTenantContext();

  const { data: consumptionsMTD } = useApiState({
    query: new SiteEnergyConsumptionQuery(site.id, ConsumptionType.MonthRolling)
  }, [site]);

  const { data: consumptionsCurrent } = useApiState({
    query: new SiteEnergyConsumptionQuery(site.id, ConsumptionType.Current)
  }, [site]);

  const { data: costsMTD } = useApiState({
    query: new SiteEnergyConsumptionCostQuery(site.id, ConsumptionType.MonthRolling)
  }, [site]);

  const { data: costsCurrent } = useApiState({
    query: new SiteEnergyConsumptionCostQuery(site.id, ConsumptionType.Current)
  }, [site]);

  const { data: coverageArea } = useApiState({
    query: new EnergyMeterCoverageGetBySiteIdQuery(site.id)
  }, [site]);

  const { data: siteAreaDto } = useApiState({
    query: new SiteGetAreaQuery(site.id),
  }, [site]);

  useEffect(() => {
    if (!siteAreaDto || !consumptionsMTD || !consumptionsCurrent || !costsMTD || !costsCurrent) {
      return;
    }

    const calculations: ConsumptionTypeToEnergyCalculationsMapping = {};
    const calculationsMTD = createEnergyCalculations(consumptionsMTD, costsMTD, siteAreaDto.siteArea);
    const calculationsCurrent = createEnergyCalculations(consumptionsCurrent, costsCurrent, siteAreaDto.siteArea);

    if (calculationsMTD) {
      calculations[ConsumptionType.MonthRolling] = calculationsMTD;
    }

    if (calculationsCurrent) {
      calculations[ConsumptionType.Current] = calculationsCurrent;
    }

    setEnergyCalculations(calculations);
  }, [siteAreaDto, consumptionsMTD, consumptionsCurrent, costsMTD, costsCurrent]);

  if (!energyCalculations) {
    return <Loading />
  }

  const selectedEnergyCalculation = energyCalculations[consumptionType];

  return (
    <>
      <DayMonthToggle
        consumptionType={consumptionType}
        onChange={setConsumptionType}
      />

      <FlexRow>
        <EnergyDoughnutWidget
          cardTitle={t('EnergyWidgets.EnergyUsage', { ns: 'molecules' })}
          data={selectedEnergyCalculation ? selectedEnergyCalculation.usage : []}
          colors={colors}
          innerLabel={t('EnergyWidgets.KilowattHour', { ns: 'molecules' })}
          legendValueSuffix="&nbsp;kWh"
          tooltipHeading={t('EnergyWidgets.EnergyUsageWidget', { ns: 'molecules' })}
          tooltipText={
            <>
              <Trans i18nKey='molecules:EnergyWidgets.UsageText'>
                <p></p>
                <p></p>
              </Trans>
            </>
          }
        />

        <EnergyDoughnutWidget
          cardTitle={t('EnergyWidgets.EnergyCost', { ns: 'molecules' })}
          data={selectedEnergyCalculation ? selectedEnergyCalculation.costs : []}
          colors={colors}
          innerLabel={t('EnergyWidgets.TotalCost', { ns: 'molecules' })}
          tooltipHeading={t('EnergyWidgets.EnergyCostWidget', { ns: 'molecules' })}
          isCurrency={true}
          tooltipText={
            <>
              <Trans i18nKey='molecules:EnergyWidgets.CostText'>
                <p></p>
                <p></p>
              </Trans>
            </>
          }
        />

        <EnergyDoughnutWidget
          cardTitle={t('EnergyWidgets.EnergyPerM2', { ns: 'molecules' })}
          data={selectedEnergyCalculation ? selectedEnergyCalculation.usagePerSqMetre : []}
          colors={colors}
          innerLabel={t('EnergyWidgets.kWhPerM2', { ns: 'molecules' })}
          innerSubLabel={calculateCoverage(t, coverageArea, siteAreaDto?.siteArea)}
          legendValueSuffix="&nbsp;kWh/m&sup2;"
          tooltipHeading={t('EnergyWidgets.EnergyPerM2Widget', { ns: 'molecules' })}
          tooltipText={
            <>
              <Trans i18nKey='molecules:EnergyWidgets.EnergyText'>
                <p></p>
                <p></p>
              </Trans>
            </>
          }
        />

        <GenerationMixWidget />

        {hasFeatureFlag(FeatureFlag.GasEnergyWidget) &&
          <>
            <GasEnergyDoughnutWidget
              cardTitle={t('EnergyWidgets.GasUsage', { ns: 'molecules' })}
              data={selectedEnergyCalculation ? selectedEnergyCalculation.gasUsage : []}
              colors={colors}
              innerLabel={[t('EnergyWidgets.M3', { ns: 'molecules' }), t('EnergyWidgets.KilowattHour', { ns: 'molecules' })]}
              legendValueSuffix={['m³', 'kWh']}
              tooltipHeading={t('EnergyWidgets.GasUsageWidget', { ns: 'molecules' })}
              tooltipText={
                <Trans i18nKey='molecules:EnergyWidgets.GasUsageTextM3'>
                  <p></p>
                  <Link
                    style={{ color: theme.palette.primary, textDecoration: 'underline' }}
                    to='https://www.gov.uk/guidance/gas-meter-readings-and-bill-calculation'
                    target='_blank'
                    rel="noopener noreferrer"
                  />
                </Trans>
              } />

            <EnergyDoughnutWidget
              cardTitle={t('EnergyWidgets.GasCost', { ns: 'molecules' })}
              data={selectedEnergyCalculation ? selectedEnergyCalculation.gasCost : []}
              colors={colors}
              innerLabel={t('EnergyWidgets.TotalCost', { ns: 'molecules' })}
              tooltipHeading={t('EnergyWidgets.GasCostWidget', { ns: 'molecules' })}
              isCurrency={true}
              tooltipText={
                <>
                  <Trans i18nKey='molecules:EnergyWidgets.GasCostText'>
                    <p></p>
                    <p></p>
                  </Trans>
                </>
              }
            />
          </>
        }
      </FlexRow>
    </>
  )
}

export default EnergyWidgets;