import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICascaderSingleSelect, IInternalNode } from './CascaderSingleSelect.types';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CascaderModal from './Modal/CascaderModal';
import { useModal } from '@shared/hooks/useModal';
import { transparentize } from 'polished';
import { Loading } from '../Loading/Loading';
import { SelectField } from '../SelectField/SelectField';

export const CascaderSingleSelect = <TValue,>({ onChange, group, confirmButton, cancelButton, placeholder, fieldStyles, loading }: ICascaderSingleSelect<TValue>) => {
  const { isOpen: isModalOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const [selectedNode, setSelectedNode] = useState<IInternalNode<TValue>>();
  const [resetPulse, setResetPulse] = useState(false);

  const handleConfirm = (node?: IInternalNode<TValue>) => {
    setSelectedNode(node);
    onChange(node?.value);
  }

  const clearSelection = () => {
    setSelectedNode(undefined);
    setResetPulse(prevState => !prevState);
    onChange(undefined);
  };

  return (
    <Container>
      <CascaderModal
        modalRef={modalRef}
        open={isModalOpen}
        group={group}
        onConfirm={handleConfirm}
        onCancel={toggleModal}
        toggleModal={toggleModal}
        confirmButton={confirmButton}
        cancelButton={cancelButton}
        resetPulse={resetPulse}
      />

      <SelectField
        onClick={() => !loading && toggleModal()}
        style={fieldStyles}
      >
        {!selectedNode &&
          <Icon icon={solid('edit')} />
        }

        {!selectedNode &&
          <Placeholder>
            {placeholder}
          </Placeholder>
        }

        {loading &&
          <Loading
            size="15px"
            color="grey"
            style={{ margin: '6px 0 0 auto' }}
          />
        }

        {selectedNode &&
          <>
            <SelectedLabel>
              {selectedNode.label}
            </SelectedLabel>

            <Icon
              icon={solid('xmark')}
              onClick={e => {
                e.stopPropagation();
                clearSelection();
              }}
            />
          </>
        }
      </SelectField>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 8px;
  right: 8px;

  width: 12px;
  height: 12px;
  padding: 6px;
  color: ${p => transparentize(0.3, p.theme.palette.text.weak)};
  border-radius: 50%;
  
  transition: all 150ms ease;
  
  ${SelectField}:hover & {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;

const Placeholder = styled.div`
  font-size: 14px;
  margin-right: 8px;
  margin-top: 1px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedLabel = styled.div`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 7px;

  font-size: 14px;
  font-weight: 500;

  border-radius: 10px;
  color: ${p => transparentize(0.1, p.theme.palette.primary)};
  border: 1px solid ${p => transparentize(0.1, p.theme.palette.primary)};
  transition: color 150ms ease;

  ${SelectField}:hover & {
    color: ${p => p.theme.palette.primary};
    border: 1px solid ${p => p.theme.palette.primary};
  }
`;