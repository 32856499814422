export type Floor = {
  id: number,
  name: string,
  floorNumber: number,
  shortCode?: string,
  floorplanId?: string,
  spacesCount?: number
};

export const instanceOfFloor = (object: object): object is Floor => {
  return 'id' in object;
}