import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface ITitleColumn {
  label: ReactNode;
  columnSortKey?: string;
  currentSortKey?: string;
  sortOrder?: string;
  updateSortKey?: (newKey: string) => void;
  style?: React.CSSProperties;
  hide?: boolean;
}

const TitleColumn = ({ label, columnSortKey, currentSortKey, sortOrder, updateSortKey, style, hide }: ITitleColumn) => {
  const isSortKey = columnSortKey ? columnSortKey === currentSortKey : false;

  if (hide) {
    return null;
  }

  return (
    <Field
      style={style}
      highlight={isSortKey}
      onClick={() => columnSortKey && updateSortKey && updateSortKey(columnSortKey)}
      isSortable={columnSortKey !== undefined}
    >
      {label}

      {isSortKey &&
        <SortOrderIcon icon={sortOrder === 'asc' ? solid('arrow-down-short-wide') : solid('arrow-up-wide-short')} />
      }
    </Field>
  );
};

export default TitleColumn;

const Field = styled.div<{ highlight: boolean, isSortable: boolean }>`
  position: relative;
  width: 130px;
  flex-shrink: 0;
  padding: 8px 10px;
  cursor: ${p => p.isSortable ? 'pointer' : 'unset'};

  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  background-color: ${p => p.highlight ? p.theme.palette.tables.backgroundHighlight : 'unset'};
  border-right: 1px solid ${p => transparentize(0.3, p.theme.palette.borders.weak)};
  white-space: pre-line;

  &:last-child {
    border-right: none;
  }
`;

const SortOrderIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 9px;
  right: 10px;
  font-size: 14px;
  color: ${p => p.theme.palette.primary};
`;