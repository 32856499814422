import { ClustersAndSpaces } from '@shared/api/models/Space/ClustersAndSpaces';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class SpacesGetAllWithClustersQuery extends GetQuery<ClustersAndSpaces> {
  floorId: number;

  constructor(floorId: number) {
    super();
    this.floorId = floorId;
  }

  public targetUrl(): string {
    return `/spaces/getAllClustersAndSpaces/${this.floorId}`;
  }
}
