import { transparentize } from 'polished';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const Form = ({ children }: { children?: ReactNode }) => {
  return <StyledForm>{children}</StyledForm>;
};

const StyledForm = styled.div`
  width: 100%;

  div[class^='row']:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const Label = styled.label<{ small?: boolean }>`
  display: block;
  font-size: ${p => p.small ? '12px' : '14px'};
  font-weight: 400;
  color: ${(p) => p.theme.text.secondary};
  margin-bottom: 6px;
`;

export interface IInput {
  disabled?: boolean;
  readOnly?: boolean;
}

export const Input = styled.input<IInput>`
  display: block;
  height: 38px;
  width: 100%;
  padding-left: 10px;

  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: ${(p) => p.theme.text.primary};

  box-sizing: border-box;
  background-color: ${(p) => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  border-radius: 5px;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};

  appearance: textfield;
  -moz-appearance: textfield;
  ${p => !p.disabled && !p.readOnly && css`
    &:hover {
      -moz-appearance: unset;
    }
  `}

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }

  &::placeholder {
    color: ${(p) => p.theme.palette.forms.input.placeholderText};
    font-weight: 400;
  }

  ${(p) => p.disabled && css`
    -moz-appearance: textfield;
    color: ${p.theme.palette.forms.input.disabledText};
    background-color: ${p.theme.palette.forms.input.disabledBackground};

    &:hover,
    &:focus {
      border-color: ${p => p.theme.palette.forms.input.border};
    }
  `}

  ${(p) => p.readOnly && css`
    -moz-appearance: textfield;
    color: ${p.theme.palette.text.medium};
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 1px;

    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
    }

    &::placeholder {
      opacity: 0;
    }
  `}
`;

export interface ITextArea {
  disabled?: boolean;
}

export const TextArea = styled.textarea<ITextArea>`
  display: block;
  width: 100%;
  padding: 14px;
  resize: none;

  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: ${(p) => p.theme.text.primary};

  box-sizing: border-box;
  background-color: ${(p) => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  border-radius: 5px;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${p => p.theme.primary.outlinedBorder};
  }

  &::placeholder {
    color: ${(p) => p.theme.palette.forms.input.placeholderText};
    font-weight: 400;
  }

  ${(p) => p.disabled && css`
    color: ${p.theme.palette.forms.input.disabledText};
    background-color: ${p.theme.palette.forms.input.disabledBackground};

    &:hover,
    &:focus {
      border-color: ${p => p.theme.palette.forms.input.border};
    }
  `}

  ${(p) => p.readOnly && css`
    color: ${p.theme.palette.text.medium};
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 1px;

    &:hover,
    &:focus {
      box-shadow: none;
      border: none;
    }

    &::placeholder {
      opacity: 0;
    }
  `}
`;

export const ErrorMessage = styled.span<{ gap?: boolean, red?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: ${(p) => transparentize(0.4, p.theme.palette.primary)};

  ${p => p.gap && css`
    position: relative;
    top: 5px;
  `}

  ${p => p.red && css`
    color: ${(p) => p.theme.error.main};
  `}
`;
