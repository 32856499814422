import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Metric } from '@shared/api/models/Metric/Metric';

export function CalculateScores(metrics: Metric[]): Metric[] {
  const scores: Metric[] = [];
  let co2Score = 0;
  let humidityScore = 0;
  let lightScore = 0;
  let noiseScore = 0;
  let temperatureScore = 0;

  metrics.forEach(metric => {
    const metricValue = parseFloat(metric.value);
    const metricDate = metric.measuredOn;

    switch (metric.metricType) {
      case MetricType.CO2: {
        const scoreMetric = GetCo2Score(metricValue, metricDate);
        co2Score = parseInt(scoreMetric.value);
        scores.push(scoreMetric);
        break;
      }
      case MetricType.Humidity: {
        const scoreMetric = GetHumidityScore(metricValue, metricDate);
        humidityScore = parseInt(scoreMetric.value);
        scores.push(scoreMetric);
        break;
      }
      case MetricType.Light: {
        const scoreMetric = GetLightScore(metricValue, metricDate);
        lightScore = parseInt(scoreMetric.value);
        scores.push(scoreMetric);
        break;
      }
      case MetricType.Noise: {
        const scoreMetric = GetNoiseScore(metricValue, metricDate);
        noiseScore = parseInt(scoreMetric.value);
        scores.push(scoreMetric);
        break;
      }
      case MetricType.Temperature: {
        const scoreMetric = GetTemperatureScore(metricValue, metricDate);
        temperatureScore = parseInt(scoreMetric.value);
        scores.push(scoreMetric);
        break;
      }
      default:
        break;
    }
  });

  scores.push(CalculateWellnessScore(
    co2Score,
    humidityScore,
    lightScore,
    noiseScore,
    temperatureScore,
    scores[0] ? scores[0].measuredOn : ''));

  return scores;
}

const CalculateWellnessScore = (
  co2Score: number, 
  humidityScore: number, 
  lightScore: number,
  noiseScore: number,
  temperatureScore: number,
  measuredOn: string): Metric => {

  const temperatureWeight = 0.25;
  const humidityWeight = 0.2;
  const noiseWeight = 0.15;
  const lightWeight = 0.15;
  const co2Weight = 0.25;

  const weightedScore: number = (temperatureScore * temperatureWeight) + 
    (humidityScore * humidityWeight) + 
    (noiseScore * noiseWeight) + 
    (lightScore * lightWeight) + 
    (co2Score * co2Weight);

  const wellnessScore: Metric = {
    value: weightedScore.toString(),
    metricType: MetricType.WellnessScore,
    measuredOn: measuredOn
  };
  
  return wellnessScore;
}

const GetLightScore = (light: number, measuredOn: string): Metric => {
  let score = 0;

  if (light >= 40) score = 1;
  if (light >= 60) score = 2;
  if (light >= 80) score = 3;
  if (light >= 90) score = 4;
  if (light >= 100) score = 5;
  if (light >= 110) score = 6;
  if (light >= 120) score = 7;
  if (light >= 130) score = 8;
  if (light >= 140) score = 9;
  if (light >= 150) score = 10;

  const metric: Metric = {
    value: score.toString(),
    metricType: MetricType.LightScore,
    measuredOn: measuredOn
  };

  return metric;
}

const GetCo2Score = (co2: number, measuredOn: string): Metric => {
  let score = 0;

  if (co2 <= 2500) score = 1;
  if (co2 <= 2200) score = 2;
  if (co2 <= 2000) score = 3;
  if (co2 <= 1800) score = 4;
  if (co2 <= 1500) score = 5;
  if (co2 <= 1200) score = 6;
  if (co2 <= 1000) score = 7;
  if (co2 <= 850) score = 8;
  if (co2 <= 700) score = 9;
  if (co2 <= 600) score = 10;

  const metric: Metric = {
    value: score.toString(),
    metricType: MetricType.CO2Score,
    measuredOn: measuredOn
  };
  
  return metric;
}

const GetHumidityScore = (humidity: number, measuredOn: string): Metric => {
  let score = 0;

  if (humidity <= 0.93) score = 1;
  if (humidity <= 0.9) score = 2;
  if (humidity <= 0.86) score = 3;
  if (humidity <= 0.83) score = 4;
  if (humidity <= 0.8) score = 5;
  if (humidity <= 0.75) score = 6;
  if (humidity <= 0.7) score = 7;
  if (humidity <= 0.6) score = 8;
  if (humidity <= 0.5) score = 9;
  if (humidity <= 0.4) score = 10;

  const metric: Metric = {
    value: score.toString(),
    metricType: MetricType.HumidityScore,
    measuredOn: measuredOn
  };
  
  return metric;
}

const GetNoiseScore = (noise: number, measuredOn: string): Metric => {
  let score = 0;

  if (noise <= 80) score = 1;
  if (noise <= 78) score = 2;
  if (noise <= 75) score = 3;
  if (noise <= 72) score = 4;
  if (noise <= 69) score = 5;
  if (noise <= 66) score = 6;
  if (noise <= 63) score = 7;
  if (noise <= 60) score = 8;
  if (noise <= 55) score = 9;
  if (noise <= 50) score = 10;

  const metric: Metric = {
    value: score.toString(),
    metricType: MetricType.NoiseScore,
    measuredOn: measuredOn
  };
  
  return metric;
}

const GetTemperatureScore = (temperature: number, measuredOn: string): Metric => {
  let score = 0;

  const idealTemperature = 21;
  const deviation = Math.abs(temperature - idealTemperature);

  if (deviation <= 7) score = 1;
  if (deviation <= 6) score = 2;
  if (deviation <= 5) score = 3;
  if (deviation <= 4) score = 4;
  if (deviation <= 3.5) score = 5;
  if (deviation <= 3) score = 6;
  if (deviation <= 2.5) score = 7;
  if (deviation <= 2) score = 8;
  if (deviation <= 1.5) score = 9;
  if (deviation <= 1) score = 10;

  const metric: Metric = {
    value: score.toString(),
    metricType: MetricType.TemperatureScore,
    measuredOn: measuredOn
  };
  
  return metric;
}