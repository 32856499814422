import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: MotorRange
 */
const HeatmapConfigMotorRange: IHeatmapConfig = {
  accessor: (x: string) => x,

  scale: {
    points: [
      {
        threshold: 0,
        label: '0',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 200,
        label: '',
        color: DefaultScaleColors.AMBER,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 200,
        label: '>200',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      }
    ]
  }
};

export default Object.freeze(HeatmapConfigMotorRange);