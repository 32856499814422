import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { Tenant } from '@shared/api/models/Tenant/Tenant';
import { useFileFromBlobStorage } from '@shared/hooks/useFileFromBlobStorage';
import styled from 'styled-components';

type PropTypes = {
  tenant: Tenant
};

const TenantLogo = ({ tenant }: PropTypes) => {
  const { blobUri } = useFileFromBlobStorage(tenant.operatorLogoId, BlobStorageContainerType.Shared);

  return (
    <>
      {blobUri &&
        <Logo src={blobUri} />
      }
    </>
  );
}

export default TenantLogo;

const Logo = styled.img`
  width: auto;
  height: auto;
  max-width: 150px;
  max-height: 40px;
  margin-right: 20px;
`;