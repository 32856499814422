import SitePage_EsgExplorer from '@dashboard/components/organisms/SitePage/SitePage_EsgExplorer';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Route } from '@shared/types/Route';

export const EsgExplorerRoutes: Route[] = [
  {
    path: 'esg-explorer',
    element: SitePage_EsgExplorer,
    analytics: { page: 'esg_explorer', category: 'esg_explorer' },
    link: {
      label: 'ESGExplorer',
      path: 'esg-explorer',
      icon: regular('table'),
      analytics: { action: 'esg_explorer', category: 'feature_navigation' }
    }
  },
];