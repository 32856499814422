import { EnergyMeter } from '@shared/api/models/EnergyMeter/EnergyMeter';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class EnergyMeterGetByDeviceIdQuery extends GetQuery<EnergyMeter[]> {
  deviceId: number;

  constructor(deviceId: number) {
    super();
    this.deviceId = deviceId;
  }

  public targetUrl(): string {
    return `/energymeters/${this.deviceId}`;
  }
}
