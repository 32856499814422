import { SiteFeature } from '@shared/api/models/Feature/SiteFeature';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export class SiteFeatureCreateCommand extends PostQuery<SiteFeature> {
  siteId: number;
  featureId: number;

  constructor(siteId: number, featureId: number) {
    super();
    this.siteId = siteId;
    this.featureId = featureId;
  }

  public targetUrl(): string {
    return '/features/CreateSiteFeature';
  }
}
