import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpaceEnergyConsumption } from '@shared/api/models/FairUse/SpaceFairUseEnergyConsumption';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn, TableSortOrder } from '@shared/components/molecules/Table/Table.types';
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { useNavigate } from 'react-router-dom';
import { alphabeticalSort } from '@shared/utils/StringUtils';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

type ExceedingSpacesTableProps = {
  spaceEnergyConsumptions?: SpaceEnergyConsumption[];
}

const ExceedingSpacesTable = ({ spaceEnergyConsumptions }: ExceedingSpacesTableProps) => {
  const { t } = useTranslation();
  const { displayString } = useLocalisation();
  const navigate = useNavigate();
  const clusterSpaces = spaceEnergyConsumptions?.filter(spaceEnergyConsumption => spaceEnergyConsumption.clusterSpaces && spaceEnergyConsumption.clusterSpaces?.length > 0);

  const tableColumns: ITableColumn<SpaceEnergyConsumption>[] = useMemo(() => ([
    {
      label: t('Type', { ns: 'common' }),
      key: 'spaceType',
      sortFormat: ({ spaceType }) => SpaceType_DisplayString(spaceType),
      displayFormat: (value) => value.spaceType ? SpaceType_DisplayString(value.spaceType) : `Cluster - ${value.clusterSize} Bed`,
    },
    {
      label: t('Space', { ns: 'common' }),
      key: 'name',
      customElement: (value) =>
        <SpaceNameElement isSpace={!value.clusterId} onClick={() => {
          if (!value.clusterId) {
            return navigate(`../../../building/${value.buildingId}/floor/${value.floorId}/space/${value.spaceIds[0]}`)
          }
        }}>
          {value.name}
        </SpaceNameElement>
    },
    {
      label: t('FairUsage.ExceedingSpaces.Consumption', { ns: 'molecules' }),
      key: 'actualEnergyConsumption',
      disableSort: true,
      customElement: (value) => {
        if (value.clusterSpaces?.find(space => space.unit === 'kWh')) {
          return <ClusterSpacesList>
            {
              value.clusterSpaces.filter(space => space.unit === 'kWh')
                .map((clusterSpace) => {
                  return <ClusterSpacesValue key={clusterSpace.id}>
                    {clusterSpace.measurement ? `${clusterSpace.measurement} ${clusterSpace.unit}` : '-'}
                  </ClusterSpacesValue>
                })
            }
          </ClusterSpacesList>
        } else {
          return <div>{value.actualEnergyConsumption} kWh</div>
        }
      },
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName'
    },
    {
      label: t('FairUsage.ExceedingSpaces.ExceededBy', { ns: 'molecules' }),
      key: 'exceededEnergyConsumption',
      customElement: (value) =>
        <ExceededBy>
          <FontAwesomeIcon icon={faArrowUp} style={{ paddingRight: '10px' }} />
          {value.exceededEnergyConsumption} kWh
        </ExceededBy>
    },
  ]), [navigate, t]);

  const getColumns = () => {
    if (clusterSpaces && clusterSpaces?.length > 0) {
      tableColumns.splice(2, 0,
        {
          label: t('FairUsage.ExceedingSpaces.ClusterRooms', { ns: 'molecules' }),
          key: 'clusterSpacesName',
          disableSort: true,
          customElement: (value) => {
            return <ClusterSpacesList>
              {
                value.clusterSpaces?.sort((a, b) => alphabeticalSort(a.name, b.name))
                  .map(clusterSpace => {
                    return <ClusterSpacesElement key={clusterSpace.name + clusterSpace.id}
                      onClick={() => navigate(`../../../building/${value.buildingId}/floor/${value.floorId}/space/${clusterSpace.id}`)}>
                      {clusterSpace.name}
                    </ClusterSpacesElement>
                  })
              }
            </ClusterSpacesList>
          }
        }
      );
      if (clusterSpaces.find(clusterSpace => clusterSpace.clusterSpaces?.find(space => space.unit === '°C'))) {
        tableColumns.splice(3, 0,
          {
            label: t('FairUsage.ExceedingSpaces.Temperature', { ns: 'molecules' }),
            key: 'clusterSpacesTemperature',
            disableSort: true,
            customElement: (value) => {
              if (value.clusterSpaces?.find(space => space.unit === '°C')) {
                return <ClusterSpacesList>
                  {
                    value.clusterSpaces?.map(clusterSpace => {
                      return <ClusterSpacesValue key={clusterSpace.id}>
                        {clusterSpace.measurement ? `${displayString(MetricType.Temperature, clusterSpace.measurement.toString())}` : '-'}
                      </ClusterSpacesValue>
                    })
                  }
                </ClusterSpacesList>
              }
            }
          });
      }
    }
    return tableColumns;
  }

  return (
    <Table
      columns={getColumns()}
      records={spaceEnergyConsumptions ?? []}
      recordKey={'spaceName'}
      defaultSortColumn="exceededEnergyConsumption"
      defaultSortOrder={TableSortOrder.DESC}
      emptyMessage={t('FairUsage.ExceedingSpaces.NoExceedingSpacesSubtitle', { ns: 'molecules' })}
      cardEffect
      fitContent
    />
  )
}

export default ExceedingSpacesTable

const ExceededBy = styled.div`
   color: ${(p) => p.theme.palette.red};
`

const SpaceNameElement = styled.div <{ isSpace: boolean }>`
 ${p => p.isSpace && css`
 &:hover {
    color: ${(p) => p.theme.palette.primary};
    cursor: pointer;
  }
  `}
`;

const ClusterSpacesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ClusterSpacesElement = styled.div`
  padding: 2px 0px;
  cursor: pointer;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: ${(p) => p.theme.palette.primary};
  }
`;

const ClusterSpacesValue = styled.div`
  padding: 2px 0px;
`;