import { EnergyMeterBillSiteDto } from '@shared/api/models/EnergyMeter/EnergyMeterBillSiteDto';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class EnergyMeterBillHelperDataGetQuery extends PostQuery<EnergyMeterBillSiteDto> {
  meterNumber: string;

  constructor(meterNumber: string) {
    super();
    this.meterNumber = meterNumber;
  }

  public targetUrl(): string {
    return '/energyMeters/EnergyMeterBillHelperDataGetByMeterNumber';
  }
}
