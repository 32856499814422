import { SalesOrderItem } from '@shared/api/models/SalesOrder/SalesOrderItem';
import { GenericDeleteQuery } from '@shared/api/queries/common/GenericDeleteQuery';

export default class SiteSalesOrderBulkDeleteSalesOrderItemsCommand extends GenericDeleteQuery<SalesOrderItem[]> {
  siteSalesOrderId: number;
  siteSalesOrderItemIds: number[];

  constructor(siteSalesOrderId: number, siteSalesOrderItemIds: number[]) {
    super();
    this.siteSalesOrderId = siteSalesOrderId;
    this.siteSalesOrderItemIds = siteSalesOrderItemIds;
  }

  public targetUrl(): string {
    return '/salesorders/bulkdeletesalesorderitems';  
  }
}