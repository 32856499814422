import { DeviceNoteSource } from '@dashboard/api/enums/DeviceNoteSource';
import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export class DeviceNoteCreateCommand extends PostQuery<DeviceNote> {
  deviceId: number;
  contents: string;
  source?: DeviceNoteSource;

  constructor(deviceId: number, contents: string, source?: DeviceNoteSource) {
    super();
    this.deviceId = deviceId;
    this.contents = contents;
    this.source = source;
  }

  public targetUrl(): string {
    return '/devicenotes';
  }
}