import { EnergyMeterType } from './EnergyMeterType';

/**
 * Get color
 */
export const EnergyMeterType_Color = (energyMeterType: EnergyMeterType): string => {
  return energyMeterTypeToColor[energyMeterType] ?? '#000';
}

/**
 * Map EnergyMeterType to a color
 */
const energyMeterTypeToColor: { [key in EnergyMeterType]: string } = Object.freeze({
  [EnergyMeterType.Heating]: '#FF821B',
  [EnergyMeterType.Electricity]: '#FFB500',
  [EnergyMeterType.Gas]: '#00C851',
  [EnergyMeterType.Water]: '#1B98FF',
  [EnergyMeterType.Cooling]: '#0074e4',
  [EnergyMeterType.HeatingCooling]: '#0074e4'
});
