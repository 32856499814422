import { ReactComponent as Icon_SignalStrength_20 } from '@dashboard/assets/icons/signal-strength-20.svg';
import { ReactComponent as Icon_SignalStrength_40 } from '@dashboard/assets/icons/signal-strength-40.svg';
import { ReactComponent as Icon_SignalStrength_60 } from '@dashboard/assets/icons/signal-strength-60.svg';
import { ReactComponent as Icon_SignalStrength_80 } from '@dashboard/assets/icons/signal-strength-80.svg';
import { ReactComponent as Icon_SignalStrength_100 } from '@dashboard/assets/icons/signal-strength-100.svg';

export const getSignalIcon = (x: number): JSX.Element => {
  switch (true) {
    case (x <= 20):
      return <Icon_SignalStrength_20 />;
    case (x <= 40):
      return <Icon_SignalStrength_40 />;
    case (x <= 60):
      return <Icon_SignalStrength_60 />;
    case (x <= 80):
      return <Icon_SignalStrength_80 />;
    default:
      return <Icon_SignalStrength_100 />;
  }
};

export const getSignalIconColor = (x: number): string => {
  switch (true) {
    case (x <= 20):
      return '#FF4B5A'; /** red */
    case (x <= 60):
      return '#ffac47'; /** yellow */
    default:
      return '#44A901'; /** green */
  }
};