import { round } from '@shared/utils/NumberUtils';
import { TooltipItem } from 'chart.js';
import { sum } from 'lodash';
import { DefaultTheme } from 'styled-components';

export const createTooltipOptions = (theme: DefaultTheme) => ({
  enabled: true,
  intersect: false,
  displayColors: true,
  usePointStyle: true,
  caretPadding: 12,
  caretSize: 7,
  backgroundColor: theme.background.container,
  cornerRadius: 3,
  padding: 8,
  borderColor: theme.action.divider,
  borderWidth: 1,
  titleColor: theme.text.primary,
  titleFont: {
    family: theme.fontFamily,
    weight: '500',
    size: 12,
  },
  bodyColor: theme.text.secondary,
  bodyFont: {
    family: theme.fontFamily,
    weight: '500',
    size: 12,
  },
  callbacks: {
    title: (items: TooltipItem<'doughnut'>[]) => {
      return `${items[0]?.label}`;
    },
    label: (item: TooltipItem<'doughnut'>) => {
      const numSpaces = sum(item.dataset.data);
      return ` ${round(item.parsed / numSpaces * 100)}%`;
    }
  }
});