import styled from 'styled-components';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import LiveUpdates from '@dashboard/components/molecules/SiteOverviewV2/LiveUpdates/LiveUpdates';
import AlertsWidget from '@dashboard/components/molecules/SiteOverviewV2/AlertBanners/AlertsWidget';
import OutlierWidget from '@dashboard/components/molecules/SiteOverviewV2/OutlierWidget/OutlierWidget';
import UtilitiesHaveMovedBanner from '@dashboard/components/molecules/SiteOverviewV2/UtilitySummaries/UtilitiesHaveMovedBanner';

const SitePageOverviewV2 = () => {
  const { site } = useSiteContext();

  return (
    <Container>
      <TopSection>
        <AlertsWidget site={site} />
        <LiveUpdates site={site} />
      </TopSection>

      <BottomSection>
        <UtilitiesHaveMovedBanner />
        <OutlierWidget />
      </BottomSection>
    </Container>
  );
};

export default SitePageOverviewV2;

export const Container = styled.div`
  min-width: 600px;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  
  background-color: ${p => p.theme.primary.background};
  padding: 30px 35px;
  overflow: hidden;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 30px 35px;
`;