import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { ChallengesResidentAppGetByIdQuery } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppGetByIdQuery';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { useApi } from '@shared/hooks/useApi';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { CreateChallengeForm } from './ResidentApp_CreateChallenge';
import ChallengeInfoContent from './ChallengeInfoContent';
import dayjs from 'dayjs';
import { Button } from '@shared/components/atoms/Button/Button';
import { ChallengesResidentAppUpdateInfoCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppUpdateInfoCommand';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { dateToUtcDate } from '@shared/utils/DateUtils';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { getSeasonFromDates, isEndDateAfterStartDate, isMetricEnabled, isOverlappingOpenChallenge } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import { CustomResidentAppFeature } from '@shared/api/enums/ResidentAppFeatureType/ResidentAppFeatureType';
import PageWrapper from '@dashboard/components/molecules/ResidentApp//Common/PageWrapper';
import { ChallengesResidentAppGetAllBySiteQuery } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppGetAllBySiteQuery';
import { useApiState } from '@shared/hooks/useApiState';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import { round } from '@shared/utils/NumberUtils';

const ResidentApp_EditChallengeInfo = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const { siteOperator, site } = useSiteContext();
  const { challengeId } = useParams<{ challengeId: string }>(); 
  const { state } = useLocation();
  const navigate = useNavigate();
  const {execute, loading, error} = useApi();
  const [challengeData, setChallengeData] = useState<OperatorChallengeDto | undefined>(state);
  const { fromLocale, toLocale } = useLocalisation();
  const { data: challenges, loading: challengesLoading } = useApiState(
    {
      query: new ChallengesResidentAppGetAllBySiteQuery(site.id),
      errorMessage: t('ResidentApp.ChallengesLoadFailed'),
    }, []);

  const methods = useForm<CreateChallengeForm>({
    defaultValues: {
      startDate: dayjs(challengeData?.startDate),
      endDate: dayjs(challengeData?.endDate),
      averageTemperature: toLocale(MetricType.Temperature, challengeData?.targetValue ?? 0, {round: 1}),
      title: challengeData?.title,
      challengeDescription: challengeData?.description
    }
  })

  const handleUpdateChallenge = async (data: CreateChallengeForm) => {
    if(!challengeData) {
      return;
    }
    
    // validate form
    if (challengeData.metricType === MetricType.ElectricityKwh && !getSeasonFromDates(data.startDate, data.endDate)) {
      methods.setFocus('startDate');
      return;
    }

    if (challenges && isOverlappingOpenChallenge(challenges, challengeData.metricType, data.startDate, data.endDate, challengeData.id)) {
      methods.setFocus('startDate');
      return;
    }

    if (!isEndDateAfterStartDate(data.startDate, data.endDate)){
      methods.setFocus('startDate');
      return;
    }

    const targetTemp = data.averageTemperature ? fromLocale(MetricType.Temperature, data.averageTemperature, {round: 1}) : 0;
    const targetElec = data.electricityPercentage ? round(data.electricityPercentage, 0) : 0;

    const targetValue = challengeData.metricType === MetricType.Temperature ? targetTemp : targetElec;

    await execute({
      query: new ChallengesResidentAppUpdateInfoCommand(
        challengeData.id,
        data.title,
        challengeData.shortTitle ?? t('ResidentApp.NewChallenge'),
        data.challengeDescription,
        challengeData.metricType,
        targetValue,
        dateToUtcDate(data.startDate).toISOString(),
        dateToUtcDate(data.endDate).toISOString(),
        challengeData.siteId,
        challengeData.imageId ?? ''
      ),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' }),
      pendingMessage: t('ChangesSavePending', { ns: 'status' }),
    });

    if(!error) {
      navigate('../');
    }
  };

  useEffect(() => {
    const getChallenge = async () => {
      if (!challengeId) {
        return;
      }

      const response = await execute({
        query: new ChallengesResidentAppGetByIdQuery(parseInt(challengeId)),
        errorMessage: t('ResidentApp.ChallengeLoadFailed')
      });

      setChallengeData(response);
      if (!response) {
        return;
      }

      methods.setValue('startDate', dayjs(new Date(response.startDate)));
      methods.setValue('endDate', dayjs(new Date(response.endDate)));
      methods.setValue('averageTemperature', toLocale(MetricType.Temperature, response.targetValue, {round: 1}));
      methods.setValue('title', response.title ?? '');
      methods.setValue('challengeDescription', response.description ?? '');
    };

    if (!state) {
      getChallenge();
    }
  }, [challengeId, execute, methods, state, t, toLocale]);

  if (siteOperator && isMetricEnabled(siteOperator, CustomResidentAppFeature.NoChallenges)) {
    return null;
  }

  if (challengesLoading) {
    return (
      <LoadingWidget
        label={t('Loading')}
        styles={{ marginTop: 80 }}
      />
    );
  }

  return (
    <PageWrapper>
      <PaddedContainer>
        <TitleWithButton
          title={t('ResidentApp.EditChallengeInfo')}
          style={{ padding: '0 0 30px 0' }}
          backButtonUrl="../"
        />
        <EditChallengeContainer noPadding>
          {challengeData && (
            <FormProvider {...methods}>
              <form>
                <ChallengeInfoContent
                  challenge={challengeData}
                  methods={methods}
                  metricType={challengeData.metricType}
                  challenges={challenges}
                />
                <Buttons>
                  <Button
                    label={t('Cancel', { ns: 'common' })}
                    onClick={() => navigate('../')}
                    tertiary
                    color={theme.palette.systemMessage.error}
                    disabled={loading}
                  />
                  <Button
                    label={t('Update', { ns: 'common' })}
                    onClick={methods.handleSubmit((data) => handleUpdateChallenge(data))}
                    disabled={loading}
                  />
                </Buttons>
              </form>
            </FormProvider>
          )}
        </EditChallengeContainer>
      </PaddedContainer>
    </PageWrapper>
  );
};

export default ResidentApp_EditChallengeInfo;

const EditChallengeContainer = styled(Card)`
  padding: 25px 20px;
  margin-top: 30px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;