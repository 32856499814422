import { IMapMarker } from '@dashboard/components/atoms/Map/MapMarker';
import { IMapOptions } from '@dashboard/components/atoms/Map/MapUtils';
import { CustomMap } from '@dashboard/components/atoms/Map/Map';
import { Site } from '@shared/api/models/Site/Site';

interface IAssetMap {
  sites: Site[];
  hoveredAssetId?: number | null;
  mapOptions?: IMapOptions;
}

const AssetMap = ({ sites, hoveredAssetId, mapOptions }: IAssetMap) => {
  const markers: IMapMarker[] = [];

  sites.forEach(x => {
    if (x.address.latitude && x.address.longitude) {
      markers.push({
        id: x.id,
        lat: x.address.latitude,
        lng: x.address.longitude,
        title: x.name,
        text: `${x.address.addressLine1}, ${x.address.city}, ${x.address.postCode}`,
        link: `/site/${x.id}`,
        isHovered: x.id === hoveredAssetId
      });
    }
  })

  return (
    <CustomMap
      markers={markers}
      options={mapOptions}
    />
  );
};

export default AssetMap;