import { UtilityBillDto } from '@shared/api/models/UtilityBill/UtilityBill';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class EnergyMeterBillsGetAllByTenantIdQuery extends PostQuery<UtilityBillDto[]> {
  tenantId: string;

  constructor(tenantId: string) {
    super();
    this.tenantId = tenantId;
  }

  public targetUrl(): string {
    return '/energyMeters/GetAllEnergyMeterBillsByTenantId';
  }
}
