import FlexRow from '@dashboard/components/atoms/FlexRow/FlexRow';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import { Popover } from '@shared/components/atoms/Popover/Popover';
import { PopoverPlacement } from '@shared/components/atoms/Popover/Popover.types';
import { Title } from '@shared/components/atoms/Title/Title';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { usePopover } from '@shared/hooks/usePopover';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

const OverviewHeader = () => {
  const { t } = useTranslation();
  const mediumScreen = useMediaQuery('(max-width: 900px)');
  const { visible, toggle, ref } = usePopover({});
  const theme = useTheme();

  const popoverContent = (
    <ActionButtonMenu>
      <StyledLink
        link={{ path: 'exceptions', analytics: { action: 'edit_exceptions', category: 'climate_control' } }}
        style={{ color: theme.palette.text.fair }}
      >
        {t('ClimateControl.ManageExceptions', { ns: 'molecules' })}
      </StyledLink>
      <StyledLink
        link={{ path: 'edit', analytics: { action: 'edit_temperature_controls', category: 'climate_control' } }}
        style={{ color: theme.palette.text.fair }}
      >
        {t('ClimateControl.EditTemperatureControls', { ns: 'molecules' })}
      </StyledLink>
    </ActionButtonMenu>
  );

  return (
    <FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
      <Title
        size='lg'
        text={t('ClimateControl.TemperatureControl', { ns: 'molecules' })}
      />
      {mediumScreen ?
        <FlexRow style={{ flexFlow: 'nowrap' }}>
          <Popover
            ref={ref}
            visible={visible}
            popoverContent={popoverContent}
            placement={PopoverPlacement.Bottom}
            alignWithTarget={true}
            hideTriangle={true}
            offsetY={-50}
            offsetX={110}
          >
            <Button
              onClick={() => toggle()}
              secondary
              label={
                <>
                  <ActionButtonIcon icon={solid('pen-to-square')} style={{ marginRight: mediumScreen ? 8 : 0 }} />
                  {t('Edit', { ns: 'common' })}
                  <ActionButtonIcon icon={solid('chevron-down')} style={{ marginLeft: mediumScreen ? 8 : 0 }} />
                </>
              }
            />
          </Popover>
        </FlexRow> :
        <FlexRow>
          <ProtectedLink
            link={{ path: 'exceptions', analytics: { action: 'edit_exceptions', category: 'climate_control' } }}
            style={{ width: 'fit-content', height: 'fit-content' }}
          >
            <Button
              secondary
              label={
                <>
                  <ActionButtonIcon icon={solid('pen-to-square')} />
                  {t('ClimateControl.ManageExceptions', { ns: 'molecules' })}
                </>
              }
            />
          </ProtectedLink>
          <ProtectedLink
            link={{ path: 'edit', analytics: { action: 'edit_temperature_controls', category: 'climate_control' } }}
            style={{ width: 'fit-content', height: 'fit-content' }}
          >
            <Button
              secondary
              label={
                <>
                  <ActionButtonIcon icon={solid('pen-to-square')} />
                  {t('ClimateControl.EditTemperatureControls', { ns: 'molecules' })}
                </>
              }
            />
          </ProtectedLink>
        </FlexRow>
      }
    </FlexRow>
  )
}

export default OverviewHeader;

const ActionButtonIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const ActionButtonMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  right: 0;
  width: 240px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 ${p => p.theme.palette.dropdown.shadowWeak}, 0px 1px 3px 1px ${p => p.theme.palette.dropdown.shadowStrong};
  z-index: 100;
`;

const StyledLink = styled(ProtectedLink)`
  padding: 8px 12px;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;