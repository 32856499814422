/**
 * Reduce array (evenly-spaced) to specified number of values
 */
export const reduceArray = (array: number[], numValues: number): number[] => {
  const steps = (array.length - 1) / (numValues - 1);

  const marks = [];
  for (let i = 0; i < numValues; i++) {
    marks.push(array[Math.round(i * steps)]);
  }

  return marks;
}

/**
 * Map array with an asnyc callback function
 */
export const mapAsync = <T, U>(array: T[], callbackfn: (value: T, index: number, array: T[]) => Promise<U>): Promise<U[]> => {
  return Promise.all(array.map(callbackfn));
}

/**
 * Filter array with an asnyc callback function
 */
export const filterAsync = async <T>(array: T[], callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>): Promise<T[]> => {
  const filterMap = await mapAsync(array, callbackfn);
  return array.filter((_, index) => filterMap[index]);
}