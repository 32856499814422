import { OperatorGetQuery } from '@shared/api/queries/ResidentApp/common/OperatorGetQuery';
import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';

export class ChallengesResidentAppGetByIdQuery extends OperatorGetQuery<OperatorChallengeDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/Challenges/GetById/${this.id}`;
  }
}
