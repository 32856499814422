import { BlobName } from '@shared/api/models/Blob/BlobName';
import { PostFormDataQuery } from '@shared/api/queries/common/PostFormDataQuery';

export default class BuildingImageUploadCommand extends PostFormDataQuery<BlobName> {

  constructor(file: File, buildingId: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('buildingId', buildingId.toString());

    super(formData);
  }

  public targetUrl(): string {
    return '/buildings/image';
  }
}
