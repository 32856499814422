import { SiteMetricTypeDto } from '@shared/api/models/ResidentApp/SiteMetricTypeDto';
import { OperatorDeleteQuery } from '@shared/api/queries/ResidentApp/common/OperatorDeleteQuery';

export class SiteMetricTypeResidentAppDeleteCommand extends OperatorDeleteQuery<SiteMetricTypeDto>{
  id: number

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return '/SiteMetricTypes'
  }
}