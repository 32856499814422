import DynamicReportMetricOptions from '@dashboard/configs/dynamicReport/DynamicReport_MetricOptions';
import useSiteMetricTypes from '@dashboard/hooks/useSiteMetricTypes';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { CheckboxSelect } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect';
import { ICheckboxSelectGroup } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect.types';
import { IModalSelectGroup, IModalSelectOption } from '@shared/components/molecules/ModalSelect/ModalSelect.types';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IMetricSelect {
  siteId: number;
  value: MetricType[];
  onChange: (metrics: MetricType[]) => void;
}

const MetricSelect = ({ siteId, value, onChange }: IMetricSelect) => {
  const { hasPermission } = useUserContext();
  const { siteMetricTypes } = useSiteMetricTypes(siteId);
  const [options, setOptions] = useState<ICheckboxSelectGroup<MetricType>[]>([]);
  const { t } = useTranslation();
  const { getUnit } = useLocalisation();

  const localisedUnit = useCallback((subGroup: { label?: string, styles?: CSSProperties, options: IModalSelectOption<MetricType>[] }): string => {
    return subGroup.options.some(x => x.label === 'GasVolume' || x.label === 'GasKwh') ? getUnit(subGroup.options[0].value) : '';
  }, [getUnit]);

  // Extracted function to process options
  const processOptions = useCallback((subGroup: { label?: string, styles?: CSSProperties, options: IModalSelectOption<MetricType>[] }, value: MetricType[]) => subGroup.options.map(option => ({
    ...option,
    label: t(option.label, { ns: 'enums' }),
    selected: value.includes(option.value)
  })), [t]);

  // Extracted function to check if a group should be included
  const shouldIncludeGroup = (group: IModalSelectGroup<MetricType>, siteMetricTypes: MetricType[] | undefined) => group.subGroups.some(subGroup =>
    subGroup.options.some(m => siteMetricTypes?.includes(m.value))
  );

  // Refactored useEffect
  useEffect(() => {
    const filteredOptions = DynamicReportMetricOptions
      .filter(group => hasPermission(group.requirePermission))
      .filter(group => shouldIncludeGroup(group, siteMetricTypes))
      .map(group => ({
        ...group,
        options: group.subGroups.flatMap(subGroup => processOptions(subGroup, value))
      }));

    setOptions(filteredOptions);
  }, [siteMetricTypes, value, hasPermission, t, localisedUnit, processOptions]);


  return (
    <CheckboxSelect
      options={options}
      onChange={(values) => onChange(values)}
      placeholder={t('Select', { ns: 'common' })}
      dropdownMaxHeight={200}
    />
  );
};

export default MetricSelect;