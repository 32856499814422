import { Input, Label } from '@shared/components/atoms/Form/Form';
import { Button } from '@shared/components/atoms/Button/Button';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import DevicesGetAllDeviceModels from '@settings/api/queries/Devices/DevicesGetAllDeviceModels';
import { useApiState } from '@shared/hooks/useApiState';
import { SalesOrderItem } from '@shared/api/models/SalesOrder/SalesOrderItem';
import SiteSalesOrderGetBySiteIdQuery from '@settings/api/queries/SalesOrder/SiteSalesOrderGetBySiteIdQuery';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import SiteSalesOrderBulkCreateSalesOrderItemsCommand from '@settings/api/queries/SalesOrder/SiteSalesOrderBulkCreateSalesOrderItemsCommand';
import { useApi } from '@shared/hooks/useApi';
import { transparentize } from 'polished';
import SiteSalesOrderCreateCommand from '@settings/api/queries/SalesOrder/SiteSalesOrderCreateCommand';
import { groupBy, isEmpty } from 'lodash';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { Select } from '@shared/components/atoms/Select/Select';
import { Title } from '@shared/components/atoms/Title/Title';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { alphabeticalSort } from '@shared/utils/StringUtils';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';

export type SalesOrderFormValues = {
  deviceType: string;
  devicesOrdered: number;
  devicesToBeInstalled: number;
  spares: number;
};

export type Option = {
  label: string;
  value: string;
}

type DeviceOption = {
  label: string;
  options: {
    label: string;
    value: string;
    deviceType: DeviceType;
  }[];
}[];

const SalesOrderCreateForm = () => {
  const navigate = useNavigate();
  const { site, salesOrder, refreshSalesOrder } = useSiteContext();
  const { execute } = useApi();
  const { t } = useTranslation();
  const { register, handleSubmit, reset, formState: { errors }, clearErrors, setValue, control } = useForm<SalesOrderFormValues>();
  const { allDeviceConfigs, getDisplayString } = useDeviceConfigContext();

  const [devicesOrdered, setDevicesOrdered] = useState<number | null>(0);
  const [devicesToBeInstalled, setDevicesToBeInstalled] = useState<number | null>(0);
  const [options, setOptions] = useState<DeviceOption | undefined>();
  const [spares, setSpares] = useState<number>(0);
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option | null>();
  const [devicesOrderedIsFocused, setDevicesOrderedIsFocused] = useState(false);
  const [devicesToBeInstalledIsFocused, setDevicesToBeInstalledIsFocused] = useState(false);

  const deviceModelOptions = useMemo(() => (
    Object.entries(groupBy(allDeviceConfigs, x => x.manufacturer))
      .sort((a, b) => alphabeticalSort(a[0], b[0]))
      .map((group) => ({
        label: group[0],
        options: group[1]
          .sort((a, b) => alphabeticalSort(a?.deviceModel, b?.deviceModel))
          .map(deviceModel => ({
            label: getDisplayString(deviceModel.deviceModel), value: deviceModel?.deviceModel, deviceType: deviceModel?.deviceType
          }))
      }))
  ), [allDeviceConfigs, getDisplayString]);

  const { data: allDeviceModels } = useApiState({
    query: new DevicesGetAllDeviceModels()
  }, []);

  useEffect(() => {
    setValue('spares', spares);
    if (spares === 0) {
      clearErrors('spares')
    }

    setValue('devicesOrdered', devicesOrdered ?? 0);
    setValue('devicesToBeInstalled', devicesToBeInstalled ?? 0);

    if (selectedDeviceType) {
      setValue('deviceType', selectedDeviceType.value);
      clearErrors('deviceType');
    }

    const deviceTypesOnSalesOrder = salesOrder?.salesOrderItems?.map(x => x.deviceType);

    const filteredDeviceModelOptions = deviceModelOptions.map(device => ({
      ...device,
      options: device.options.filter(option => !deviceTypesOnSalesOrder?.includes(option.value))
    })).filter(device => device.options.length > 0);

    setOptions(filteredDeviceModelOptions as unknown as DeviceOption);

    if (devicesOrdered && devicesToBeInstalled) {
      setSpares(devicesOrdered - devicesToBeInstalled);
    }

    if (!devicesOrdered || !devicesToBeInstalled) {
      setSpares(0);
    }

  }, [allDeviceModels, salesOrder?.salesOrderItems, devicesOrdered, devicesToBeInstalled, clearErrors, errors, setValue, spares, selectedDeviceType, deviceModelOptions]);

  const bulkCreateSalesOrderItems = async (salesOrderId: number, items: SalesOrderItem[]) => {
    await execute({
      query: new SiteSalesOrderBulkCreateSalesOrderItemsCommand(items, salesOrderId),
      successMessage: t('SalesOrder.Messages.CreateSalesOrderItemsSuccess', { ns: 'molecules' }),
      errorMessage: t('SalesOrder.Messages.CreateSalesOrderItemsFailure', { ns: 'molecules' })
    })
    refreshSalesOrder()
  }

  const createSalesOrder = async () => {
    return await execute({
      query: new SiteSalesOrderCreateCommand(site.id),
      successMessage: t('SalesOrder.Messages.CreateSalesOrderSuccess', { ns: 'molecules' }),
      errorMessage: t('SalesOrder.Messages.CreateSalesOrderFailure', { ns: 'molecules' })
    })
  };

  const onSubmit: SubmitHandler<SalesOrderFormValues> = async (data) => {
    if (salesOrder?.id) {
      const salesOrderItem: SalesOrderItem = {
        siteSalesOrderId: salesOrder.id,
        deviceType: data.deviceType,
        numberOfOrders: data.devicesOrdered,
        numberToBeInstalled: data.devicesToBeInstalled
      }

      await bulkCreateSalesOrderItems(salesOrderItem.siteSalesOrderId, [salesOrderItem])

      reset()
      navigate('./..')
    } else {
      await createSalesOrder()

      const newSalesorder = await execute({
        query: new SiteSalesOrderGetBySiteIdQuery(site.id)
      })

      if (newSalesorder?.id) {
        const salesOrderItem: SalesOrderItem = {
          siteSalesOrderId: newSalesorder.id,
          deviceType: data.deviceType,
          numberOfOrders: data.devicesOrdered,
          numberToBeInstalled: data.devicesToBeInstalled
        }

        await bulkCreateSalesOrderItems(salesOrderItem.siteSalesOrderId, [salesOrderItem])
      }

      reset()
      navigate('./..')
    }
  };

  return (
    <>
      <Title
        text={t('SalesOrder.AddOrder', { ns: 'molecules' })}
      />
      {errors.deviceType && errors.devicesOrdered && errors.devicesToBeInstalled &&
        <ErrorMessage>{t('SalesOrder.AllMandatoryFields', { ns: 'molecules' })}</ErrorMessage>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.DeviceType', { ns: 'molecules' })}*</Label>
        <Controller
          name="deviceType"
          control={control}
          defaultValue=""
          rules={{ required: t('SalesOrder.FieldRequired', { ns: 'molecules' }) }}
          render={() => (
            <Select
              options={options}
              onChange={(option) => {
                setSelectedDeviceType(option)
              }}
              value={selectedDeviceType}
              isSearchable={true}
              borderColor={errors?.deviceType?.message ? 'red' : 'inherit'}
            />
          )}
        />
        {!(errors.deviceType && errors.devicesOrdered && errors.devicesToBeInstalled) &&
          <ErrorMessage>{errors?.deviceType?.message}</ErrorMessage>
        }
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.DevicesOrdered', { ns: 'molecules' })}*</Label>
        <Input
          {...register('devicesOrdered', {
            required: t('SalesOrder.FieldRequired', { ns: 'molecules' }),
            setValueAs: (value: number) => Number(value),
            validate: (value) => value !== 0 || t('SalesOrder.FieldRequired', { ns: 'molecules' }),
          })}
          type={'number'}
          onChange={(input) => {
            setDevicesOrdered(stringToNumber(input.target.value) === null ? 0 : stringToNumber(input.target.value));
            if (devicesOrdered) {
              clearErrors('devicesOrdered')
            }
          }}
          style={errors?.devicesOrdered?.message || (devicesOrderedIsFocused && spares < 0) ? { borderColor: 'red' } : {}}
          placeholder='Value'
          onFocus={() => setDevicesOrderedIsFocused(true)}
          onBlur={() => setDevicesOrderedIsFocused(false)}
        />
        {!(errors.deviceType && errors.devicesOrdered && errors.devicesToBeInstalled) &&
          <ErrorMessage>{errors?.devicesOrdered?.message}</ErrorMessage>
        }
        {devicesOrderedIsFocused && spares < 0 &&
          <ErrorMessage>
            {t('SalesOrder.OrderedHasToBeMore', { ns: 'molecules' })}
          </ErrorMessage>
        }
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.DevicesToBeInstalled', { ns: 'molecules' })}*</Label>
        <Input
          {...register('devicesToBeInstalled', {
            required: t('SalesOrder.FieldRequired', { ns: 'molecules' }),
            setValueAs: (value: number) => Number(value),
            validate: (value) => value !== 0 || t('SalesOrder.FieldRequired', { ns: 'molecules' }),
          })}
          type={'number'}
          onChange={(input) => {
            setDevicesToBeInstalled(stringToNumber(input.target.value) === null ? 0 : stringToNumber(input.target.value));
            if (devicesToBeInstalled) {
              clearErrors('devicesToBeInstalled')
            }
          }}
          style={errors?.devicesToBeInstalled?.message || (devicesToBeInstalledIsFocused && spares < 0) ? { borderColor: 'red' } : {}}
          placeholder='Value'
          onFocus={() => setDevicesToBeInstalledIsFocused(true)}
          onBlur={() => setDevicesToBeInstalledIsFocused(false)}
        />
        {!(errors.deviceType && errors.devicesOrdered && errors.devicesToBeInstalled) &&
          <ErrorMessage>{errors?.devicesToBeInstalled?.message}</ErrorMessage>
        }
        {devicesToBeInstalledIsFocused && spares < 0 &&
          <ErrorMessage>
            {t('SalesOrder.ToBeInstalledHasToBeLess', { ns: 'molecules' })}
          </ErrorMessage>
        }
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.SpareDevices', { ns: 'molecules' })}</Label>
        <BorderlessInput
          {...register('spares', {
            validate: value => value >= 0 || t('SalesOrder.OrderedHasToBeMore', { ns: 'molecules' })
          })}
          type={'text'}
          value={spares}
          spares={spares}
          disabled
          onChange={(input) => {
            if (stringToNumber(input.target.value) === 0) {
              clearErrors('spares')
            }
          }}
        />
        {errors.spares && <ErrorMessage>{errors?.spares?.message}</ErrorMessage>}
        {spares < 0 && isEmpty(errors.spares) && !devicesOrderedIsFocused && !devicesToBeInstalledIsFocused &&
          <ErrorMessage>{t('SalesOrder.OrderedHasToBeMore', { ns: 'molecules' })}</ErrorMessage>
        }
        <ButtonWrapper>
          <Button
            secondary
            label={t('Cancel', { ns: 'common' })}
            onClick={() => navigate('./..')}
            style={{ width: '85px' }}
          />
          <CustomButton
            type={'submit'}
            disabled={spares < 0 || !isEmpty(errors)}
          >
            {t('Add', { ns: 'common' })}
          </CustomButton>
        </ButtonWrapper>
      </form>
    </>
  )
};

export default SalesOrderCreateForm;

const ButtonWrapper = styled.div` 
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
`;

const CustomButton = styled.button`
  width: 85px;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: '5px 10px';
  font-weight: 500;
  font-size: 14px;
  font-family: 'DM Sans';
  text-align: center;
  transition: all 150ms ease-out;
  cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${p => p.theme.palette.text.onPrimary};
  background-color: ${p => p.disabled ? transparentize(0.2, p.color ?? p.theme.palette.primary) : p.theme.palette.primary};
  border-color: ${p => p.theme.palette.primary};

  &:hover {
    box-shadow: 0px 6px 8px -4px ${p => transparentize(0.4, p.theme.palette.primary)};
  }
`;

const BorderlessInput = styled.input<{ spares: number }>`
  height: 38px;
  border: none;
  background-color: white;
  font-weight: 500;
  font-family: inherit;
  font-size: 14px;
  color: ${(props) => props.spares < 0 ? 'red' : 'black'};
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  margin-top: 10px;
  color: red;
`;