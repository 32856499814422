import { ReportEnergyConsumptionUsageDto } from '@dashboard/api/models/ReportEnergyConsumptionDto';
import { ReportMetadataDto } from '@dashboard/api/models/ReportMetadataDto';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import useEnergyConsumptionWidget from './hooks/useEnergyConsumptionWidget';
import EnergyConsumptionWidget from './EnergyConsumptionWidget';
import { ReportAggregateCategory } from '@dashboard/api/enums/ReportAggregateCategory';

type EnergyConsumptionWidgetWrapperProps = {
  reportDate: Date;
  data: ReportEnergyConsumptionUsageDto;
  inPrintMode: boolean;
  totalBuildingArea?: string;
  metadata: ReportMetadataDto[];
  numColumns: number;
  category: ReportAggregateCategory;
}

const EnergyConsumptionWidgetWrapper = ({ reportDate, data, inPrintMode, totalBuildingArea, metadata, numColumns, category }: EnergyConsumptionWidgetWrapperProps) => {
  const { config, hasUpwardsTrend, setUseTransform } = useEnergyConsumptionWidget({ reportDate, dto: data, metadata });

  if (!config) {
    return <Loading />;
  }

  return (
    <EnergyConsumptionWidget
      reportDate={reportDate}
      config={config}
      metadata={metadata}
      setUseTransform={setUseTransform}
      inPrintMode={inPrintMode}
      hasUpwardsTrend={hasUpwardsTrend}
      totalBuildingArea={totalBuildingArea}
      numColumns={numColumns}
      category={category}
    />
  );
};

export default EnergyConsumptionWidgetWrapper;
