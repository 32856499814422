import { useEffect, useState } from 'react';
import { env } from '@shared/env/env';
import styled, { useTheme } from 'styled-components';
import GoogleMap from 'google-maps-react-markers';
import { IMapMarker, MapMarker } from './MapMarker';
import { IMapOptions, MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM, MAP_DEFAULT_ZOOM_FOR_SINGLE_MARKER, getMapOptions } from './MapUtils';

// Set map bounds based on markers (if there are any)
const fitBounds = (map: google.maps.Map, markers: IMapMarker[]) => {
  const bounds = new google.maps.LatLngBounds();

  markers.forEach(marker => {
    bounds.extend({ lat: marker.lat, lng: marker.lng });
  });

  if (markers.length > 0) {
    map.fitBounds(bounds);
  }

  // Overwrite zoom for a single marker as zoom is too close otherwise (due to fitBounds)
  if (markers.length === 1) {
    map.setZoom(MAP_DEFAULT_ZOOM_FOR_SINGLE_MARKER);
  }
};

interface IMap {
  markers: IMapMarker[];
  options?: IMapOptions;
}

export const CustomMap = ({ markers, options }: IMap) => {
  const theme = useTheme();
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (map) {
      fitBounds(map, markers);
    }
  }, [map, markers]);

  useEffect(() => {
    if (map) {
      map.setOptions(getMapOptions(theme, options));
    }
  }, [map, theme, options]);

  const onGoogleApiLoaded = ({ map }: { map: google.maps.Map }) => {
    setMap(map);
  };

  return (
    <MapContainer>
      <GoogleMap
        apiKey={env.apiKeys.google}
        defaultCenter={MAP_DEFAULT_CENTER}
        defaultZoom={MAP_DEFAULT_ZOOM}
        options={getMapOptions(theme, options)}
        onGoogleApiLoaded={onGoogleApiLoaded}
      >
        {markers.map((marker) => (
          <MapMarker
            key={marker.id}
            lat={marker.lat}
            lng={marker.lng}
            markerId={marker.id}
            marker={marker}
            interactive={options?.interactive}
          />
        ))}
      </GoogleMap>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  height: 100%;
  width: 100%;

  .gm-style-cc {
    display: none;
  }

  img[alt="Google"] {
    opacity: 0.1;
  }
`;