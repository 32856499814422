import { DynamicReportDataDto } from '@dashboard/api/models/DynamicReportDataDto';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class GetDynamicReportData extends PostQuery<DynamicReportDataDto> {
  spaceIds: number[];
  metricTypes: MetricType[];
  fromDateTime: string;
  toDateTime: string;
  groupedByMetricType: boolean;
  gasCalorificValue: number;

  constructor(spaceIds: number[], metricTypes: MetricType[], fromDateTime: string, toDateTime: string, groupedByMetricType: boolean, gasCalorificValue = 38) {
    super();
    this.spaceIds = spaceIds;
    this.metricTypes = metricTypes;
    this.fromDateTime = fromDateTime;
    this.toDateTime = toDateTime;
    this.groupedByMetricType = groupedByMetricType;
    this.gasCalorificValue = gasCalorificValue;
  }

  public targetUrl(): string {
    return '/reports/GetDynamicReportData';
  }
}
