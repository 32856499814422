import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import EnergyWidgets from '@dashboard/components/molecules/EnergyWidgets/EnergyWidgets';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import SitePageOverviewV2 from './SitePage_Overview_V2';
import OccupancyWidget from '@dashboard/components/molecules/SiteOverviewV2/LiveUpdates/Occupancy/OccupancyWidget';

const SitePage_Overview = () => {
  const { t } = useTranslation(['molecules']);

  const { hasFeatureFlag } = useTenantContext();

  if (hasFeatureFlag(FeatureFlag.SiteOverviewV2)) {
    return <SitePageOverviewV2 />;
  }

  return (
    <PaddedContainer style={{ maxWidth: 'fit-content' }}>
      <Title
        text={t('ESG.SiteOverview', { ns: 'molecules' })}
        size='lg'
        wrapperStyle={{ paddingBottom: '20px' }}
      />

      <FlexColumn>
        <OccupancyWidget />
        <EnergyWidgets />
      </FlexColumn>
    </PaddedContainer>
  );
};

export default SitePage_Overview;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;