import { Chart, ChartTypeRegistry, TooltipModel } from 'chart.js';
import { DefaultTheme } from 'styled-components';
import { TTempGraphDataPoint, TTempGraphDataset } from './useTemperatureChart';
import dayjs from 'dayjs';
import { LocalisationType } from '@shared/localisation/localisation';
import { groupBy } from 'lodash';

export type TooltipContext = { chart: Chart; tooltip: TooltipModel<keyof ChartTypeRegistry> };

// Render line or dashed line
const addIconElement = (td: HTMLTableCellElement, dataset: TTempGraphDataset) => {
  const line = document.createElement('span');
  line.style.height = '3px';
  line.style.width = '16px';
  line.style.background = dataset.legend.color;
  line.style.borderRadius = '4px';
  line.style.display = 'inline-block';

  if (dataset.id.includes('previousYear')) {
    for (let i = 0; i < 3; i++) {
      td.style.display = 'flex';
      td.style.gap = '2px';
      const dash = document.createElement('span');
      dash.style.height = '3px';
      dash.style.width = '4px';
      dash.style.borderRadius = '4px';
      dash.style.background = dataset.legend.color;
      dash.style.display = 'inline-block';
      td.appendChild(dash);
    }
  } else {
    td.appendChild(line);
  }
};

const getOrCreateTooltip = (chart: Chart, theme: DefaultTheme) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.backgroundColor = theme.background.container;
    tooltipEl.style.border = `1px solid ${theme.action.divider}`;
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.boxShadow = `0 2px 12px -2px ${theme.shadow.dark}`;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.padding = '4px 10px 8px 10px';
    tooltipEl.style.height = 'max-content';
    tooltipEl.style.width = 'max-content';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transition = 'all 300ms ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: TooltipContext, theme: DefaultTheme, localisation: LocalisationType) => {
  // Context contains the tooltip details
  const { tooltip, chart } = context;
  const tooltipEl = getOrCreateTooltip(chart, theme);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  const tableRoot = tooltipEl.querySelector('table');

  if (!tableRoot) {
    return;
  }

  if (tooltip.body) {
    const tableBody = document.createElement('tbody');

    // Group data points by date to separate last years from current years
    const groupedByDate = groupBy(tooltip.dataPoints, x => (x.raw as TTempGraphDataPoint).date);

    Object.entries(groupedByDate).forEach(group => {
      // Render date label as a group sub heading
      const date = group[0];
      const tr = document.createElement('tr');
      const td = document.createElement('td');
      td.style.paddingTop = '6px';
      td.style.paddingBottom = '4px';
      td.style.fontSize = '14px';
      td.style.fontWeight = '500';
      td.style.color = theme.text.primary;
      const yearText = document.createTextNode(dayjs(date).format(localisation.dateFormats.date));

      td.appendChild(yearText);
      tr.appendChild(td);
      tableBody.appendChild(tr);

      // Create one line per datapoint/dataset
      group[1].forEach(dataPoint => {
        const tr = document.createElement('tr');
        const td = document.createElement('td');
        td.style.display = 'flex';
        td.style.alignItems = 'center';
        td.style.paddingTop = '2px';
        td.style.fontSize = '14px';
        td.style.fontWeight = '400';
        td.style.color = theme.text.primary;

        const dataset = dataPoint.dataset as unknown as TTempGraphDataset;
        const label = document.createElement('span');
        label.style.marginLeft = '6px';
        label.textContent = `${dataPoint.formattedValue}${dataset.dataUnit} - ${dataset.label}`;

        addIconElement(td, dataset);
        td.appendChild(label);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

    });

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY, offsetWidth } = chart.canvas;
  const canvasCenter = offsetWidth / 2;

  // Display the tooltip
  tooltipEl.style.opacity = '1';

  // Position the tooltip on the left or right side of the datapoint depending on its position on the chart
  if (tooltip.caretX > canvasCenter) {
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.left = positionX + tooltip.caretX - 10 + 'px';
    tooltipEl.style.transform = 'translate(-100%, -50%)';
  } else {
    tooltipEl.style.left = positionX + tooltip.caretX + 10 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.transform = 'translateY(-50%)';
  }
};