import styled from 'styled-components';

type PropTypes = {
  color?: string;
}

const DashedLine = ({ color }: PropTypes) => {
  return (
    <FlexRow>
      <Dash color={color} />
      <Dash color={color} />
      <Dash color={color} />
    </FlexRow>
  );
};

export default DashedLine;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Dash = styled.div`
  width: 4px;
  height: 3px;
  border-radius: 4px;
  background-color: ${p => p.color};
  flex-shrink: 0;
`;