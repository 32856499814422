import { differenceInMinutes } from 'date-fns';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: LastMotionTime
 */
const HeatmapConfigLastMotion: IHeatmapConfig = {
  accessor: (x: string) => {
    // Use (differenceInMinutes() / 60) to get decimal value (necessary for accurate comparison in displayFormat() function)
    return differenceInMinutes(new Date(), new Date(x)) / 60;
  },
  displayFormat: (x: number | string) => {
    if (x as number < 1) {
      return `<${1}`;
    }

    if (x as number > 72) {
      return `>${72}`;
    }

    return `${(x as number).toFixed(0)}`;
  },
  fallbackValue: {
    label: '>72',
    squareColor: '#7A7FFF',
    textColor: DefaultScaleColors.LIGHTTEXT,
  },

  scale: {
    points: [
      {
        threshold: 1,
        label: '1hour',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 6,
        label: '6',
        color: '#febd67',
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 12,
        label: '12',
        color: '#abdeff',
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 24,
        label: '24',
        color: '#65C2FF',
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 72,
        label: '72',
        color: '#7A7FFF',
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigLastMotion);