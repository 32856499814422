import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export class DeviceNoteUpdateCommand extends PatchQuery<DeviceNote> {
  contents: string;

  constructor(id: number, contents: string) {
    super(id);
    this.contents = contents;
  }

  public targetUrl(): string {
    return '/devicenotes';
  }
}
