import DeviceGatewayGetByBuildingIdQuery from '@settings/api/queries/Gateway/DeviceGatewayGetByBuildingIdQuery';
import { Building } from '@shared/api/models/Building/Building';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { Title } from '@shared/components/atoms/Title/Title';
import { InformationDialog } from '@shared/components/molecules/InformationDialog/InformationDialog';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useApiState } from '@shared/hooks/useApiState';
import { useModal } from '@shared/hooks/useModal';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useCsvExport from '@dashboard/hooks/useCsvExport';
import { Button } from '@shared/components/atoms/Button/Button';
import CsvGatewayDevicesGetQuery from '@dashboard/api/queries/exports/CsvGatewayDevicesGetQuery';

type PropTypes = {
  building: Building
}

type DeviceGatewayTable = {
  deviceId: number,
  deviceModel: string,
  deviceIdentifier: string,
  assetNumber: string,
  connectivity: string,
  friendlyName: string,
  locationData: string,
  space: string,
  floorName: string,
  buildingName: string
}

const Devices_Gateway = ({ building }: PropTypes) => {
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const { t } = useTranslation(['molecules']);
  const { handleCsvExport: exportDevices, loading: loadingDevicesExport } = useCsvExport();
  const { data: devices, loading } = useApiState({
    query: new DeviceGatewayGetByBuildingIdQuery(building.id)
  }, []);
  const [selectedDevice, setSelectedDevice] = useState<DeviceGatewayTable | null>(null);

  const tableColumns: ITableColumn<DeviceGatewayTable>[] = useMemo(() => ([
    {
      label: t('DeviceCard.Name', { ns: 'molecules' }),
      key: 'friendlyName',
      width: 2
    },
    {
      label: t('DeviceCard.DeviceModel', { ns: 'molecules' }),
      key: 'deviceModel',
      width: 2
    },
    {
      label: t('DeviceCard.DeviceIdentifier', { ns: 'molecules' }),
      key: 'deviceIdentifier',
      width: 2
    },
    {
      label: t('DeviceCard.AssetNo', { ns: 'molecules' }),
      key: 'assetNumber',
      width: 1
    },
    {
      label: t('DeviceCard.Building', { ns: 'molecules' }),
      key: 'buildingName',
      width: 1
    },
    {
      label: t('DeviceCard.Floor', { ns: 'molecules' }),
      key: 'floorName',
      width: 1
    },
    {
      label: t('DeviceCard.Space', { ns: 'molecules' }),
      key: 'space',
      width: 1
    },
    {
      label: t('DeviceCard.Connectivity', { ns: 'molecules' }),
      key: 'connectivity',
      width: 1
    }
  ]), [t]);

  const getGatewayRecords = useMemo(() => {
    if (devices) {
      return devices.map(x => ({ ...x, deviceModel: x.deviceGatewayModel.deviceModel, deviceId: x.id }));
    }
    return [];
  }, [devices]);

  const toggleGatewayModal = (row: DeviceGatewayTable) => {
    setSelectedDevice(row)
    toggleModal();
  };

  const handleDevicesExport = async () => {
    if (!building) {
      return;
    }

    const query = new CsvGatewayDevicesGetQuery(building.id);
    const fileName = `${building.name}_Gateway_Devices.csv`
    exportDevices(query, fileName);
  };

  return (
    <>
      <BackButton
        url='./..'
        label={t('BackToDevices', { ns: 'navigation' })}
      />

      <FlexRow>
        <Title text={t('DeviceCard.GatewayDevices', { ns: 'molecules' })} />

        <Button
          label={t('DeviceCard.ExportDevices', { ns: 'molecules' })}
          onClick={handleDevicesExport}
          loading={loadingDevicesExport}
          style={{ marginLeft: 'auto' }}
        />
      </FlexRow>

      <Table
        columns={tableColumns}
        records={getGatewayRecords ?? []}
        recordKey="deviceId"
        emptyMessage={t('DeviceCard.NoGatewayDevicesFound', { ns: 'molecules' })}
        defaultSortColumn="friendlyName"
        loading={loading}
        cardEffect
        fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 270}
        onRowClick={(row: DeviceGatewayTable) => toggleGatewayModal(row)}
      />

      <InformationDialog
        modalRef={modalRef}
        isOpen={modalIsOpen}
        hide={toggleModal}
        title={selectedDevice?.friendlyName ?? ''}
        content={
          <>
            <ContentGrid>
              <LabelValuePair
                label={t('DeviceCard.Model', { ns: 'molecules' })}
                value={selectedDevice?.deviceModel}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
              <LabelValuePair
                label={t('DeviceCard.DeviceId', { ns: 'molecules' })}
                value={selectedDevice?.deviceIdentifier}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />

              <LabelValuePair
                label={t('DeviceCard.AssetNo', { ns: 'molecules' })}
                value={selectedDevice?.assetNumber}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
            </ContentGrid>
            <ContentGrid>
              <LabelValuePair
                label={t('DeviceCard.Building', { ns: 'molecules' })}
                value={selectedDevice?.buildingName}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
              <LabelValuePair
                label={t('DeviceCard.Floor', { ns: 'molecules' })}
                value={selectedDevice?.floorName}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
              <LabelValuePair
                label={t('DeviceCard.Space', { ns: 'molecules' })}
                value={selectedDevice?.space}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
            </ContentGrid>
            <ContentGrid>
              <LabelValuePair
                label={t('DeviceCard.Connectivity', { ns: 'molecules' })}
                value={selectedDevice?.connectivity}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
              <LabelValuePair
                label={t('DeviceCard.Location', { ns: 'molecules' })}
                value={selectedDevice?.locationData}
                size="xs"
                valueStyle={{ fontWeight: 500 }}
              />
            </ContentGrid>

          </ >
        }
        button={{ label: t('Close', { ns: 'common' }) }}

      /></ >
  );
}

export default Devices_Gateway;

const FlexRow = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 5px 5px 0 0;
  padding: 15px 0;
`;