import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useModal } from '@shared/hooks/useModal';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type TableRecord = {
  description: string;
  offlineThreshold: string;
}

type PropTypes = {
  iconStyles?: React.CSSProperties;
}

const DeviceStatusInfoModal = ({ iconStyles }: PropTypes) => {
  const { t } = useTranslation();
  const { ref, isOpen, toggle } = useModal({});

  const tableColumns: ITableColumn<TableRecord>[] = useMemo(() => ([
    {
      label: t('SiteOverview.DeviceModel', { ns: 'molecules' }),
      key: 'description',
      width: 6
    },
    {
      label: t('SiteOverview.ConsideredOfflineAfter', { ns: 'molecules' }),
      key: 'offlineThreshold',
      width: 6
    }
  ]), [t]);

  const tableRecords = useMemo<TableRecord[]>(() => ([
    {
      description: t('SiteOverview.AxiomaWaterMeter', { ns: 'molecules' }),
      offlineThreshold: `10 ${t('Hours', { ns: 'common' })}`
    },
    {
      description: t('SiteOverview.ElvacoMeters', { ns: 'molecules' }),
      offlineThreshold: `72 ${t('Hours', { ns: 'common' })}`
    },
    {
      description: t('SiteOverview.GenericMeters', { ns: 'molecules' }),
      offlineThreshold: `72 ${t('Hours', { ns: 'common' })}`
    },
    {
      description: t('SiteOverview.AllOtherMetersAndDevices', { ns: 'molecules' }),
      offlineThreshold: `24 ${t('Hours', { ns: 'common' })}`
    }
  ]), [t]);

  return (
    <>
      <InfoIcon
        icon={regular('circle-info')}
        onClick={toggle}
        style={iconStyles}
      />

      <Modal
        ref={ref}
        isOpen={isOpen}
        hide={toggle}
        title={t('DeviceOfflineThresholds', { ns: 'common' })}
        bodyStyles={{ padding: 0 }}
      >
        <Table
          columns={tableColumns}
          records={tableRecords}
          recordKey={'description'}
          disableSorting
          disablePaging
          wrapBreakpoint={0}
        />
      </Modal>
    </>
  );
};

export default DeviceStatusInfoModal;

const InfoIcon = styled(FontAwesomeIcon) <{ margin?: string }>`
  width: 14px;
  height: 14px;
  margin: ${p => p.margin};
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;