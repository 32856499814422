import { Space } from '@shared/api/models/Space/Space';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class SpaceGetByIdQuery extends GetQuery<Space> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/spaces/getById/${this.id}`;
  }
}
