import { SiteOperatorDto } from '@shared/api/models/ResidentApp/SiteOperatorDto';
import { OperatorPostQuery } from '@shared/api/queries/ResidentApp/common/OperatorPostQuery';

export default class SiteOperatorResidentAppCreateCommand extends OperatorPostQuery<SiteOperatorDto> {
  siteId: number;

  constructor(siteId: number, ) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return '/SiteOperators/Create';
  }
}
