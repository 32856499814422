import { useCallback, useEffect, useRef } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { Chart, Filler, LineController, LinearScale, BarController, BarElement, PointElement, LineElement, ChartTypeRegistry, ChartData, ChartOptions, TooltipItem, } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { AbbreviatedToFullMonthName } from '@shared/utils/DateUtils';

Chart.register(LineController, BarElement, BarController, LinearScale, PointElement, LineElement);

export type DiagnosticDataset = {
  label: string;
  dataset: number[];
  dataUnit: string;
};

export interface IDiagnosticChart {
  /**
   * Chart datasets
   */
  datasets: DiagnosticDataset[];
  /**
   * Array of labels
   */
  labels?: string[];
  /**
   * Array of colors
   */
  colors?: string[];
}

const DiagnosticChart = ({ datasets, labels, colors }: IDiagnosticChart) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, number[], string> => {
    return {
      labels: labels,
      datasets: datasets.map((x) => ({
        label: x.label,
        data: x.dataset,
        dataUnit: x.dataUnit,
        backgroundColor: colors,
        borderColor: colors,
        categoryPercentage: 0.6,
        barPercentage: 0.75,
      })),
    };
  }, [datasets, labels, colors]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const chart = new Chart(context, {
        type: 'bar',
        data: constructData(),
        options: constructOptions(theme),
        plugins: [Filler],
      });
      return () => chart.destroy();
    }
  }, [theme, constructData]);

  return (
    <Wrapper>
      <canvas id="myChart" ref={canvasRef} />
    </Wrapper>
  );
};

export default DiagnosticChart;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const constructOptions = (theme: DefaultTheme): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        grid: {
          display: false,
          borderColor: transparentize(0.5, theme.palette.charts.gridLineColor),
          borderWidth: 1,
        },
        ticks: {
          maxTicksLimit: undefined,
          maxRotation: 0,
          padding: 0,
          color: theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: 10,
            weight: '400',
          },
        },
      },
      y: {
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.7, theme.palette.charts.gridLineColor),
        },
        ticks: {
          padding: 5,
          color: theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: 10,
            weight: '400',
          },
          callback: (value: string | number) => {
            return new Intl.NumberFormat('en-US', {
              maximumFractionDigits: 1,
              notation: 'compact',
              compactDisplay: 'short',
            }).format(Number(value));
          },
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
      bar: {
        borderWidth: 0,
        borderRadius: 5,
      },
      point: {
        radius: 2,
        hoverRadius: 2,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        displayColors: true,
        usePointStyle: true,
        boxHeight: 10,
        caretPadding: 12,
        caretSize: 7,
        backgroundColor: theme.palette.backgrounds.surface,
        cornerRadius: 3,
        padding: 6,
        borderColor: theme.palette.borders.medium,
        borderWidth: 1,
        titleMarginBottom: 3,
        titleColor: theme.palette.text.fair,
        titleFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        bodyColor: theme.palette.text.medium,
        bodyFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex;
        },
        callbacks: {
          title: (items: TooltipItem<'bar' | 'line'>[]) => {
            const label = items[0]?.label;
            return `${AbbreviatedToFullMonthName(label)}`;
          },
          label: (item: TooltipItem<'bar' | 'line'>) => {
            if (item.raw === 0) {
              return ' --';
            }

            const dataset = item.dataset as unknown as DiagnosticDataset;
            const unit = dataset.dataUnit;

            return ` ${item.raw as number} ${unit}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };
};
