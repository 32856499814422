import ExternalUserList from '@settings/components/organisms/UserManagement/ExternalUserList/ExternalUserList';
import UserCreate from '@settings/components/organisms/UserManagement/UserCreate/UserCreate';
import UserEdit from '@settings/components/organisms/UserManagement/UserEdit/UserEdit';
import UserList from '@settings/components/organisms/UserManagement/UserList/UserList';
import { Route } from '@shared/types/Route';

export const UserManagementRoutes: Route[] = [
  {
    path: 'users/*',
    element: UserList,
    analytics: { page: 'user_list', category: 'user_management' },
    link: {
      label: 'Users',
      path: 'users',
      analytics: { action: 'users_tab', category: 'user_management' }
    }
  },
  {
    path: 'externalUsers',
    element: ExternalUserList,
    analytics: { page: 'external_user_list', category: 'user_management' },
    link: {
      label: 'ExternalUsers',
      path: 'externalUsers',
      analytics: { action: 'external_users_tab', category: 'user_management' }
    }
  },
  {
    path: 'users/create',
    element: UserCreate,
    analytics: { page: 'user_create', category: 'user_management' },
  },
  {
    path: 'users/:userId',
    element: UserEdit,
    analytics: { page: 'user_edit', category: 'user_management' },
  }
]