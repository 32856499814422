import { useState, useEffect } from 'react';

/**
 * Hook returning a boolean indicating if the media-query was matched
 *
 * @param query
 * @example
 *   const onSmallScreen = useMediaQuery('(max-width: 992px)');
 */
export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener('change', listener);

    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}
