import { SpaceEnergyConsumption } from '@shared/api/models/FairUse/SpaceFairUseEnergyConsumption';
import { PostQuery } from '../../common/PostQuery';
import ExportCsvDto from '@dashboard/api/models/ExportCsvDto';

export class CsvExceedingSpacesGetQuery extends PostQuery<ExportCsvDto> {
  exceedingSpaces: SpaceEnergyConsumption[];

  constructor(exceedingSpaces: SpaceEnergyConsumption[]) {
    super();
    this.exceedingSpaces = exceedingSpaces;
  }

  public targetUrl(): string {
    return '/FairUse/CsvExceedingSpaces';
  }
}
