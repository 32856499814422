import { PulseCounterMetricOptions } from '@dashboard/configs/device/PulseCounter_MetricOptions';
import DeviceCreateCommand from '@settings/api/queries/Devices/DeviceCreateCommand';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { MetricType_Suffix } from '@shared/api/enums/MetricType/MetricType_Suffix';
import { ErrorMessage, Input, Label } from '@shared/components/atoms/Form/Form';
import { Select } from '@shared/components/atoms/Select/Select';
import { Card } from '@shared/components/molecules/Card/Card';
import { IDeviceConfig } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IDeviceConfig';
import { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

type MetricTypeOption = {
  label: string;
  value: MetricType;
}

type DeviceCreate_PulseCounterProps = {
  deviceConfig: IDeviceConfig;
  register: UseFormRegister<DeviceCreateCommand>;
  control: Control<DeviceCreateCommand>;
  errors: FieldErrors<DeviceCreateCommand>;
  getValues: UseFormGetValues<DeviceCreateCommand>;
}

const DeviceCreate_PulseCounter = ({ deviceConfig, register, control, errors, getValues }: DeviceCreate_PulseCounterProps) => {
  const { t } = useTranslation();
  const pulseMetricOptions = useMemo(() => PulseCounterMetricOptions.map(x => ({ label: t(x, { ns: 'enums' }), value: x })), [t]);

  if (!deviceConfig.isPulseCounter) {
    return null;
  }

  return (
    <Card
      cardTitle={t('DeviceCreate.ConfigPulseCounter', { ns: 'settingsDevice' })}
      centered
      maxWidth='400px'
    >
      {deviceConfig.pulseChannels?.map((channel, index) => {
        const selectedMetricType = getValues(`pulseAssignments.${index}.metricType`);

        return (
          <div className="container" key={`channel-${index}`}>
            <Input
              type='string'
              readOnly={true}
              {...register(`pulseAssignments.${index}.channel`, {
                value: channel
              })}
            />
            <br />
            <div className="row">
              <div className="col-md-12">
                <Label>{t('DeviceCreate.MetricType', { ns: 'settingsDevice' })}</Label>
                <Controller
                  control={control}
                  name={`pulseAssignments.${index}.metricType`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={pulseMetricOptions.find(x => x.value === value)}
                      options={pulseMetricOptions}
                      isSearchable={true}
                      onChange={(selected: SingleValue<MetricTypeOption | undefined>) => selected && onChange(selected.value)}
                    />
                  )}
                />
                <ErrorMessage>{errors.pulseAssignments?.[index]?.metricType?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>
                  {t('DeviceCreate.PulsesPerUnit', {
                    ns: 'settingsDevice',
                    unit: selectedMetricType ? MetricType_Suffix(selectedMetricType) : 'unit'
                  })}
                </Label>
                <Input
                  type='number'
                  min={0}
                  step='0.1'
                  {...register(`pulseAssignments.${index}.pulsesPerUnit`, {
                    valueAsNumber: true,
                    validate: value => !getValues(`pulseAssignments.${index}.metricType`)
                      ? true
                      : (value && value > 0 || t('GreaterThanZero', { ns: 'validation' }))
                  })}
                />
                <ErrorMessage>{errors.pulseAssignments?.[index]?.pulsesPerUnit?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label>{t('DeviceCreate.InitialMeterReading', { ns: 'settingsDevice' })}</Label>
                <Input
                  type='number'
                  min={0}
                  step='0.1'
                  {...register(`pulseAssignments.${index}.initialValue`, {
                    valueAsNumber: true,
                    validate: value => {
                      if (value === undefined || isNaN(value)) {
                        return true;
                      }

                      return value > 0 || t('GreaterThanZero', { ns: 'validation' });
                    }
                  })}
                />
                <ErrorMessage>{errors.pulseAssignments?.[index]?.initialValue?.message}</ErrorMessage>
                <br />
              </div>
            </div>
          </div>
        );
      })}
    </Card>
  );
};

export default DeviceCreate_PulseCounter;