import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import SpacePage_Notes from './SpacePage_Notes';
import SpacePage_HeatingSchedules from './SpacePage_HeatingSchedules';
import { v4 as uuidv4 } from 'uuid';
import { Card } from '@shared/components/molecules/Card/Card';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { SiteLevelFeatureFlag } from '@shared/api/enums/FeatureFlag/SiteLevelFeatureFlag';

const SpacePage_Tabs = () => {
  const { t } = useTranslation();
  const { hasSiteFeature } = useSiteContext();
  const [selected, setSelected] = useState(0);
  const lineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});

  // Move the line when the selected tab changes
  useEffect(() => {
    const tab = tabRefs.current[selected];

    if (tab && lineRef.current) {
      lineRef.current.style.left = tab.offsetLeft + 'px';
      lineRef.current.style.width = tab.clientWidth + 'px';
    }
  }, [selected]);

  const tabs = useMemo(() => [
    {
      id: 0,
      label: t('Notes', { ns: 'common' }),
      component: SpacePage_Notes,
      analytics: { action: 'notes', category: 'space' }
    },
    {
      id: 1,
      label: t('HeatingSchedule', { ns: 'common' }),
      component: SpacePage_HeatingSchedules,
      analytics: { action: 'heating_schedules', category: 'space' },
      siteLevelFeatureFlag: SiteLevelFeatureFlag.HeatingSchedules
    }
  ], [t]);

  return (
    <Card noPadding>
      <Tabs>
        {tabs.map((tab, i) => {
          if (tab.siteLevelFeatureFlag && !hasSiteFeature(tab.siteLevelFeatureFlag)) {
            return false;
          }

          return (
            <Tab
              key={tab.label}
              ref={element => tabRefs.current[i] = element}
              isActive={selected === i}
              onClick={() => setSelected(i)}
            >
              {tab.label}
            </Tab>
          );
        })}

        <TabLine ref={lineRef} />
      </Tabs>

      {tabs?.map((tab, i) => (
        <Content
          key={uuidv4()}
          selected={selected === i}
        >
          <tab.component />
        </Content>
      ))}
    </Card>
  );
};

export default SpacePage_Tabs;

const Tabs = styled.div`
  position: relative;
  display: flex;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const Tab = styled.div<{ isActive: boolean }>`
  padding: 15px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;

  ${p => p.isActive && css`
    color: ${p => p.theme.text.primary};
  `}
`;

const TabLine = styled.div`
  height: 2px;
  background-color: ${p => p.theme.palette.primary};
  transition: left 300ms ease, width 300ms ease;

  position: absolute;
  bottom: 0px;
  left: 0;
`;

const Content = styled.div<{ selected: boolean }>`
  display: none;
  padding: 24px 32px;
  
  ${p => p.selected && css`
    display: flex;
    flex-direction: column;
  `}
`;