import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import styled from 'styled-components';
import { TimeRangeType } from '../TimeRangeSelect';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

type PropTypes = {
  timeRangeType: TimeRangeType;
  onPrevious: () => void;
  onNext: () => void;
}

const PreviousNextButtons = ({ timeRangeType, onPrevious, onNext }: PropTypes) => {
  const { trackAction } = useAnalytics();

  const handleBack = () => {
    onPrevious();
    trackAction('back', 'performance_dates', { time_type: timeRangeType });
  }

  const handleForward = () => {
    onNext();
    trackAction('forward', 'performance_dates', { time_type: timeRangeType });
  }

  return (
    <FlexRow>
      <Button
        circle
        tertiary
        label={<Chevron icon={solid('chevron-left')} />}
        style={{ padding: 4 }}
        onClick={handleBack}
      />
      <Button
        circle
        tertiary
        label={<Chevron icon={solid('chevron-right')} />}
        style={{ padding: 4 }}
        onClick={handleForward}
      />
    </FlexRow>
  );
};

export default PreviousNextButtons;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const Chevron = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  color: ${p => p.theme.action.active};
`;