import { MetricTypeWithSpaces, SpaceWithMetricTypes } from '@dashboard/api/models/DynamicReportDataDto';
import GetDynamicReportData from '@dashboard/api/queries/reports/GetDynamicReportData';
import RangePicker, { TimeRange } from '@dashboard/components/atoms/RangePicker/RangePicker';
import { getReportChartColor } from '@dashboard/utils/ChartUtils';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { HierarchyBuilding } from '@shared/api/models/Hierarchy/Hierarchy';
import { Button } from '@shared/components/atoms/Button/Button';
import { useApi } from '@shared/hooks/useApi';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { IDynamicReportData } from '../../../SitePage_Reporting';
import GroupingTypeSelect, { ReportGroupingType } from './GroupingTypeSelect';
import MetricSelect from './MetricSelect';
import SpaceSelect from './SpaceSelect';
import ConfigGroup from '@dashboard/components/molecules/EsgExplorer/Tabs/Explorer/ExplorerConfiguration/ConfigGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { t } from 'i18next';

const createMetricsData = (buildings: HierarchyBuilding[], metricTypes?: MetricTypeWithSpaces[]) => {
  //Get all unique spaceIds 
  const allSpacesIds = metricTypes?.flatMap(x => x.spaces).map(x => x.spaceId) ?? [];
  const uniqueSpaceIds = Array.from(new Set(allSpacesIds));

  // Map to list of metrics with a location object for each nested space
  let metrics = metricTypes?.map(metric => ({
    ...metric,
    spaces: metric.spaces.map(space => ({
      ...space,
      location: getLocation(space.spaceId, buildings),
      color: getReportChartColor(uniqueSpaceIds.indexOf(space.spaceId))
    }))
  }));

  // Sort list of metrics
  metrics = orderBy(metrics, x => x.metricType);

  // Sort list of nested spaces by spaceName
  metrics = metrics.map(metric => ({ ...metric, spaces: orderBy(metric.spaces, space => space.location.spaceName) }));
  return metrics;
}

const createSpacesData = (buildings: HierarchyBuilding[], spacesDtos?: SpaceWithMetricTypes[]) => {
  //Get all unique metricTypes 
  const allMetricTypes = spacesDtos?.flatMap(x => x.metricTypes).map(x => x.metricType) ?? [];
  const uniqueMetricTypes = Array.from(new Set(allMetricTypes));

  // Map to list of spaces with a location object
  let spaces = spacesDtos?.map(space => ({
    ...space,
    location: getLocation(space.spaceId, buildings),
    metricTypes: space.metricTypes.map(metricType => ({
      ...metricType,
      color: getReportChartColor(uniqueMetricTypes.indexOf(metricType.metricType))
    }))
  }));

  // Sort list of spaces
  spaces = orderBy(spaces, x => x.location.spaceName);

  // Sort list of nested metrics by metricType
  spaces = spaces.map(space => ({ ...space, metricTypes: orderBy(space.metricTypes, metric => metric.metricType) }));
  return spaces;
}

/**
 * Get the location of a space with the buildingName, floorName and spaceName
 */
const getLocation = (spaceId: number, buildings: HierarchyBuilding[]) => {
  const building = buildings.find(building => building.floors.find(floor => floor.spaces.find(space => space.id === spaceId)));
  const floor = building?.floors.find(floor => floor.spaces.find(space => space.id === spaceId));
  const space = floor?.spaces.find(x => x.id === spaceId);

  return {
    buildingName: building?.name ?? '',
    floorName: floor?.name ?? '',
    spaceName: space?.name ?? '',
  };
}

const isConfigValid = (config: IDynamicReportConfiguration) => {
  return config.spaces.length > 0 && 
  config.spaces.length <=4 && 
  config.metrics.length > 0 && 
  config.timeRange !== undefined
}

const isSpaceLimitReached = (config: IDynamicReportConfiguration) => {
  if (config.spaces.length <= 4){
    return (
      <UserMessage>
        {t('MaxSpacesMessage', { ns: 'common', })}
      </UserMessage>
    )
  }
  else {
    return (
      <Warning>
        <WarningIcon icon={solid('exclamation-triangle')} />
        {t('MaxSpacesMessage', { ns: 'common', })}
      </Warning>
    )
  }
}

const emptyConfig: IDynamicReportConfiguration = {
  spaces: [],
  metrics: [],
  grouping: ReportGroupingType.MetricType
};

export type IDynamicReportConfiguration = {
  spaces: number[];
  metrics: MetricType[];
  timeRange?: TimeRange;
  grouping: ReportGroupingType;
}

interface IReportConfiguration {
  siteId: number;
  buildings: HierarchyBuilding[];
  setReportData: (data: IDynamicReportData) => void;
  onLoadingReportChange: (isLoading: boolean) => void;
}

const ReportConfiguration = ({ siteId, buildings, setReportData, onLoadingReportChange }: IReportConfiguration) => {
  const theme = useTheme();
  const { execute } = useApi();
  const { t } = useTranslation(['organisms']);
  const { siteMetadata } = useSiteContext();
  const [config, setConfig] = useState<IDynamicReportConfiguration>(emptyConfig);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [resetPulse, setResetPulse] = useState(false);

  useEffect(() => {
    onLoadingReportChange(loadingReport);
  }, [loadingReport, onLoadingReportChange]);

  const createReport = async (config: IDynamicReportConfiguration, buildings: HierarchyBuilding[]) => {
    if (!config.timeRange) {
      return;
    }

    setLoadingReport(true);

    const groupedByMetricType = config.grouping === ReportGroupingType.MetricType;

    const dto = await execute({
      query: new GetDynamicReportData(config.spaces, config.metrics, config.timeRange.from.toJSON(),
        config.timeRange.to.toJSON(), groupedByMetricType, siteMetadata?.gasCalorificValue)
    });

    if (!dto) {
      return;
    }

    setReportData({
      config: config,
      metrics: createMetricsData(buildings, dto.metricTypes),
      spaces: createSpacesData(buildings, dto.spaces)
    });

    setLoadingReport(false);
  };

  const handleReset = () => {
    setConfig(emptyConfig);
    setResetPulse(prevState => !prevState);
  };

  return (
    <Column>
      <ConfigGroup
        title={t('Spaces', { ns: 'common' })}
      >
        <SpaceSelect
          buildings={buildings}
          onChange={spaces => setConfig(prevState => ({ ...prevState, spaces }))}
          resetPulse={resetPulse}
        />
        {isSpaceLimitReached(config)}
      </ConfigGroup>

      <ConfigGroup
        title={t('Metrics', { ns: 'common' })}
      >
        <MetricSelect
          siteId={siteId}
          value={config.metrics}
          onChange={metrics => setConfig(prevState => ({ ...prevState, metrics }))}
        />
      </ConfigGroup>

      <ConfigGroup
        title={t('TimeRange', { ns: 'common' })}
      >
        <RangePicker
          maxNumDays={31}
          value={config.timeRange && [config.timeRange.from, config.timeRange.to]}
          onChange={timeRange => setConfig(prevState => ({ ...prevState, timeRange }))}
        />
        <UserMessage>
          {t('MaxDaysMessage', { ns: 'common', })}
        </UserMessage>
      </ConfigGroup>

      <Divider />

      <ConfigGroup
        title={t('ChartGrouping', { ns: 'common' })}
      >
        <GroupingTypeSelect
          value={config.grouping}
          onChange={grouping => setConfig(prevState => ({ ...prevState, grouping }))}
        />
      </ConfigGroup>

      <Footer>
        <Button
          tertiary
          label={t('Reset', { ns: 'common' })}
          onClick={handleReset}
          disabled={loadingReport}
          color={theme.palette.red}
          style={{ width: '100%' }}
        />
        <Button
          label={t('Generate', { ns: 'common' })}
          onClick={() => createReport(config, buildings)}
          disabled={!isConfigValid(config)}
          loading={loadingReport}
          style={{ width: '100%' }}
          analytics={{ action: 'generate_report', category: 'reporting' }}
        />
      </Footer>
    </Column>
  );
};

export default ReportConfiguration;

const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: auto;
  padding: 10px 0 5px 0;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
  box-shadow: 0 -8px 10px -14px ${p => p.theme.palette.shadows.strong};
`;

const Divider = styled.div`
  border-top: 1px solid ${p => p.theme.action.divider};
`;

const UserMessage = styled.div <{ isVisible?: boolean }>`
  font-size: 12px;
  padding: 0 5px;
  margin-top: 8px;

  display: flex;
  align-items: center;

  :first-child {
    margin-top: 10px;
  }
`;

const Warning = styled.div <{ isVisible?: boolean }>`
  font-size: 12px;
  color: ${p => p.theme.palette.red} !important;
  padding: 0 5px;
  margin-top: 8px;

  display: flex;
  align-items: center;

  :first-child {
    margin-top: 10px;
  }
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.red};
  margin: -2px 8px 0 0;
`;