import SystemMessage from '@shared/components/atoms/SystemMessage/SystemMessage'
import { transparentize } from 'polished'
import styled, { useTheme } from 'styled-components'

type SetupInformationProps = {
  label: string;
}

const SetupInformation = ({ label }: SetupInformationProps) => {
  const theme = useTheme();

  const systemMessage = () => {
    return (
      <InformationText>
        {label}
      </InformationText>
    )
  }
  return (
    <SystemMessage
      messages={[systemMessage()]}
      hideIcon={true}
      styles={{
        border: `1px solid ${theme.palette.systemMessage.information}`,
        backgroundColor: transparentize(0.9, theme.palette.systemMessage.information)
      }}
    />
  )
}

export default SetupInformation;

const InformationText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${p => p.theme.palette.systemMessage.information};
`;
