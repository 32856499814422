import { Building } from '@shared/api/models/Building/Building';
import { ProtectedRoute } from '@shared/components/navigation/ProtectedRoute/ProtectedRoute';
import { Route as RouteType } from '@shared/types/Route';
import { Navigate, Route, Routes } from 'react-router-dom';

interface IBuildingEnergyMeters {
  building: Building;
  routes: RouteType[];
}

const BuildingEnergyMeters = ({ building, routes }: IBuildingEnergyMeters) => {

  return (
    <Routes>
      {routes?.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <ProtectedRoute
              route={route}
              customProps={{ building: building }}
            />
          }
        />
      ))}

      <Route path="*" element={<Navigate to={`${routes?.[0]?.path}`} replace />} />
    </Routes>
  );
};

export default BuildingEnergyMeters;