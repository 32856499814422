import styled from 'styled-components';

type PropTypes = {
  color: string;
}

const Line = ({ color }: PropTypes) => {
  return (
    <StyledLine color={color} />
  );
};

export default Line;

const StyledLine = styled.div<{ color: string }>`
  width: 16px;
  height: 3px;
  border-radius: 4px;
  background-color: ${p => p.color};
  flex-shrink: 0;
`;