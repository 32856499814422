import { useState } from 'react';
import styled, { css } from 'styled-components';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertModal_Devices from './AlertModal_Devices';
import { Alert } from '@shared/api/models/Alert/Alert';
import { Button } from '@shared/components/atoms/Button/Button';
import { useTranslation } from 'react-i18next';

export interface IAlertModalTab {
  alert: Alert;
  onMobile: boolean;
  closeModal: () => void;
}

type AlertModalTab = {
  label: string;
  component: ({ alert }: IAlertModalTab) => JSX.Element;
}

interface IAlertModalContent {
  alert: Alert;
  onMobile: boolean;
  closeModal: () => void;
  acknowledgeAlert: (alert: Alert) => void;
}

const AlertModalContent = ({ alert, onMobile, closeModal, acknowledgeAlert }: IAlertModalContent) => {
  const { t } = useTranslation(['commonApp']);
  const [activeTab, setActiveTab] = useState(0);

  const tabs: AlertModalTab[] = [
    {
      label: t('TopBar.Alerts.Devices', { ns: 'commonApp' }),
      component: AlertModal_Devices
    }
  ];

  return (
    <DetailsColumn $onMobile={onMobile}>
      <div>
        <FlexRow>
          <div>
            <RuleName>
              {alert.message.ruleName}
            </RuleName>
            <Description>
              {alert.message.description}
            </Description>
          </div>

          <RightAligned>
            <Button
              tertiary
              label={t('TopBar.Alerts.Acknowledge', { ns: 'commonApp' })}
              onClick={() => acknowledgeAlert(alert)}
            />
            <CloseButton icon={faXmark} onClick={closeModal} />
          </RightAligned>
        </FlexRow>

        <TabLabels>
          {tabs.map((tab, i) => (
            <TabLabel key={i} active={i === activeTab} onClick={() => setActiveTab(i)}>
              {tab.label}
            </TabLabel>
          ))}
        </TabLabels>
      </div>

      {tabs.filter((_, i) => i === activeTab).map((tab, i) => (
        <TabContainer key={i} $onMobile={onMobile}>
          {<tab.component alert={alert} onMobile={onMobile} closeModal={closeModal} />}
        </TabContainer>
      ))}
    </DetailsColumn>
  );
};

export default AlertModalContent;

const FlexRow = styled.div`
  display: flex;
  padding-top: 20px;
`;


const RuleName = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  
  white-space: wrap;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
  padding-left: 20px;
  flex-wrap: wrap;
`;

const RightAligned = styled.div`
  margin-left: auto;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
`;

const CloseButton = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  padding: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${p => p.theme.palette.primary};
  margin-top: 3px;
  margin-left: 10px;

  &:hover {
    background-color: ${p => p.theme.palette.topbar.iconHoverBackground};
  }
`;

const DetailsColumn = styled.div<{ $onMobile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${p => p.$onMobile && css`
    order: 1;
  `}
`;

const TabLabels = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  box-shadow: 0 4px 6px -4px ${p => p.theme.palette.shadows.medium};
`;

const TabLabel = styled.div<{ active: boolean }>`
  color: ${p => p.active ? p.theme.palette.primary : 'inherit'};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  cursor: pointer;

  display: flex;
  align-items: center;
`;

const TabContainer = styled.div<{ $onMobile: boolean }>`
  overflow: hidden auto;

  ${p => p.$onMobile && css`
    height: 200px;
    box-shadow: 0 -4px 6px -4px ${p => p.theme.palette.shadows.medium} inset;
    border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  `}
`;