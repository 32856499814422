import { SiteThresholdDto } from '@shared/api/models/ResidentApp/SiteThresholdDto';
import { OperatorPatchQuery } from '@shared/api/queries/ResidentApp/common/OperatorPatchQuery';

export default class SiteThresholdsResidentAppUpdateCommand extends OperatorPatchQuery<SiteThresholdDto[]> {
  siteId: number;
  siteThresholds?: SiteThresholdDto[];

  constructor(siteId: number, siteThresholds?: SiteThresholdDto[]) {
    super();
    this.siteId = siteId;
    this.siteThresholds = siteThresholds;
  }

  public targetUrl(): string {
    return '/SiteThresholds/Update';
  }
}
