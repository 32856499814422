import styled from 'styled-components';
import ChartLegend from './ChartLegend';
import useMonthlyChart from './useMonthlyChart';
import { useSize } from '@shared/hooks/useSize';
import { UtilityData } from '../../UtilitiesSummary';
import UnitToggle from '../UnitToggle';

export const getChartHeight = (width?: number) => {
  if (width === undefined || width > 800) {
    return '300px';
  }

  if (width > 600) {
    return '250px'
  }

  return '200px';
};

type PropTypes = {
  data: UtilityData;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const MonthlyChart = ({ data, conversions, onSetConversions }: PropTypes) => {
  const { canvasRef, datasets, handleLegendChange } = useMonthlyChart({ data });
  const { width } = useSize(canvasRef);

  return (
    <Wrapper>
      {data.hasConversion
        ? <UnitToggle
          metricType={data.metricType}
          conversions={conversions}
          onChange={onSetConversions}
        />
        : <YAxisLabel>{data.unit}</YAxisLabel>
      }

      <ChartContainer width={width}>
        <canvas id="utilityBarChart" ref={canvasRef} />
      </ChartContainer>

      <ChartLegend
        datasets={datasets}
        onChange={handleLegendChange}
        isGasWidget={data.isGasWidget}
      />
    </Wrapper>
  );
};

export default MonthlyChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChartContainer = styled.div<{ width?: number }>`
  position: relative;
  height: ${({ width }) => getChartHeight(width)};
  width: 100%;
  margin-bottom: 25px;
  margin-left: -10px;
  transition: height 200ms ease;
`;

const YAxisLabel = styled.div`
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
  margin: 0 0 7px 5px;
`;