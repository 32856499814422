import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { SiteLevelFeatureFlag } from '@shared/api/enums/FeatureFlag/SiteLevelFeatureFlag';
import { DeviceIssueDto } from '@shared/api/models/ClimateControl/Device/DeviceIssueDto';
import { SpaceClimateControlDetailsDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDetailsDto';
import { HeatingScheduleDto } from '@shared/api/models/HeatingSchedule/HeatingScheduleDto';
import { RouteLinkAnalytics } from '@shared/types/Route';
import { ReactNode } from 'react';

export enum TempControlTabType {
  Spaces = 'Spaces',
  DeviceIssues = 'DeviceIssues',
  HeatingSchedules = 'HeatingSchedules'
}

export type TableTab = {
  type: TempControlTabType;
  label: string;
  icon?: IconDefinition;
  iconColor?: string;
  loading?: boolean;
  data: SpaceClimateControlDetailsDto[] | DeviceIssueDto[] | HeatingScheduleDto[];
  analytics: RouteLinkAnalytics;
  component: ReactNode;
  siteLevelFeatureFlag?: SiteLevelFeatureFlag;
};