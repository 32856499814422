import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { env } from '@shared/env/env';

const MsalConfig: Configuration = {
  auth: {
    clientId: env.auth.clientId,
    authority: env.auth.authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    knownAuthorities: [env.auth.authority]
  }
};

export const authConfig = {
  msalInstance: new PublicClientApplication(MsalConfig),
  loginRequest: {
    scopes: ['openid', env.auth.scopes]
  },
  passwordResetRequest: {
    scopes: ['openid', env.auth.scopes],
    authority: env.auth.passwordReset
  },
  updateMfaRequest: {
    scopes: ['openid', env.auth.scopes],
    authority: env.auth.updateMfa
  },
  apiBaseUrl: env.app.apiUrl,
  operatorApiBaseUrl: env.app.operatorApiUrl
};