import { Title } from '@shared/components/atoms/Title/Title';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import Stepper, { Steps } from '@shared/components/molecules/Stepper/Stepper';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import PushNotificationsForm from './PushNotificationsForm';
import { Button } from '@shared/components/atoms/Button/Button';
import NotificationPreview from './NotificationPreview';
import ConfirmationBox from '@dashboard/components/molecules/ResidentApp/Common/ConfirmationBox';
import { useApiState } from '@shared/hooks/useApiState';
import { PushNotificationsResidentAppGetNoOfRecipientsBySiteId } from '@shared/api/queries/ResidentApp/PushNotifications/PushNotificationsResidentAppGetNoOfRecipientsBySiteId';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useApi } from '@shared/hooks/useApi';
import { PushNotificationResidentAppCreateCommand } from '@shared/api/queries/ResidentApp/PushNotifications/PushNotificationResidentAppCreateCommand';
import { BuildingHierarchiesGetBySiteId } from '@dashboard/api/queries/building/BuildingHierarchiesGetBySiteId';
import { HierarchyBuilding } from '@shared/api/models/Hierarchy/Hierarchy';

export enum RecipientType {
  All = 'allRecipients',
  SelectedSpaces = 'selectedRecipients',
} 

export type PushNotificationForm = {
  title: string;
  text: string;
  recipientType: RecipientType;
  spaces?: number[];
};

type PushNotificationsModalProps = {
  showModal: boolean;
  onCloseModal: () => void;
};

const PushNotificationsModal = ({showModal, onCloseModal}: PushNotificationsModalProps) => {
  const { t } =  useTranslation('molecules');
  const theme = useTheme();
  const { site } = useSiteContext();
  const [activeStep, setActiveStep] = useState(1);
  const [isConfirmationBoxChecked, setIsConfirmationBoxChecked] = useState(false);
  const [relevantBuildings, setRelevantBuildings] = useState<HierarchyBuilding[]>([]);

  const { execute, loading } = useApi();
  const { data: buildings } = useApiState({
    query: new BuildingHierarchiesGetBySiteId(site.id),
    initialState: [],
  }, [site.id]);

  const methods = useForm<PushNotificationForm>({
    defaultValues: {
      recipientType: RecipientType.All
    }
  })
  const { data: recipients } = useApiState({
    query: new PushNotificationsResidentAppGetNoOfRecipientsBySiteId(site.id),
    errorMessage: t('ResidentApp.UsersLoadFailed')
  }, []);

  const steps: Steps[] = [
    { step: 1, title: t('ResidentApp.Details') },
    { step: 2, title: t('ResidentApp.PreviewAndConfirm') }
  ];

  const isFormFieldsInvalidLength =
    methods.watch('title')?.length === 0 ||
    methods.watch('text')?.length === 0 ||
    methods.watch('title')?.length > 60 ||
    methods.watch('text')?.length > 150;

  const getIsButtonDisabled = (activeStep: number) => {
    if (activeStep === 1) {
      return isFormFieldsInvalidLength;
    } else if (activeStep === 2) {
      return !isConfirmationBoxChecked || loading;
    }
    return false;
  }

  const onSubmitNotification = async (data: PushNotificationForm) => {
    if (activeStep === 1) {
      // set error if recipient type is selected spaces and no spaces are selected
      if (data.recipientType === RecipientType.SelectedSpaces && (!data.spaces || data.spaces.length === 0)) {
        methods.setError('spaces', { type: 'manual', message: t('SpaceRequired', { ns: 'validation' }) });
        return;
      }

      setActiveStep(activeStep + 1);
      return;
    }

    if (activeStep === 2) {
      await execute({
        query:
          new PushNotificationResidentAppCreateCommand(
            site.id,
            data.title,
            data.text,
            data.recipientType === RecipientType.All ? undefined : data.spaces
          ),
        successMessage: t('NotificationSent', { ns: 'status' }),
        pendingMessage: t('SendingNotification', { ns: 'status' }),
        errorMessage: t('AnErrorOccurred', { ns: 'status' }),
      })

      setIsConfirmationBoxChecked(false);
      onCloseModal();
      setActiveStep(1);
      methods.reset();
    }
  };

  useEffect(() => {
    const buildingsWithSpaces = buildings?.map(building => ({
      ...building,
      floors: building.floors.filter(floor => floor.spaces.length > 0)
    })).filter(building => building.floors.length > 0);

    setRelevantBuildings(buildingsWithSpaces);
  }, [buildings]);

  return (
    <Modal isOpen={showModal} plainModal>
      <Content>
        <Title
          text={t('ResidentApp.PushNotificationTitle')}
          size="sm"
          wrapperStyle={{
            padding: '0 20px 20px 20px',
            borderBottom: `1px solid ${theme.action.divider}`,
          }}
        />
        <FormContent>
          <Stepper steps={steps} activeStep={activeStep} style={{ justifyContent: 'flex-start' }} />
          <FormProvider {...methods}>
            {activeStep === 1 && <PushNotificationsForm methods={methods} buildings={relevantBuildings} />}
            {activeStep === 2 && (
              <Preview>
                <NotificationPreview title={methods.watch('title')} text={methods.watch('text')} />
                <ConfirmationBox
                  warningTitle={t('ResidentApp.NotificationAreYouSureTitle')}
                  warningDescription={t('ResidentApp.NotificationAreYouSureMessage')}
                  checkboxLabel={t('ResidentApp.NotificationCheckboxLabel', {
                    count:
                      methods.getValues('recipientType') === RecipientType.SelectedSpaces
                        ? methods.getValues('spaces')?.length
                        : (recipients?.noOfRecipients ?? 0),
                  })}
                  onCheckboxChange={(isChecked) => setIsConfirmationBoxChecked(isChecked)}
                  isChecked={isConfirmationBoxChecked}
                />
              </Preview>
            )}
            <Buttons hasBackButton={activeStep > 1}>
              {activeStep > 1 && (
                <Button
                  label={t('Back', { ns: 'common' })}
                  onClick={() => {
                    setIsConfirmationBoxChecked(false);
                    setActiveStep(activeStep - 1);
                  }}
                  secondary
                  disabled={loading}
                />
              )}
              <RightButtons>
                <Button
                  label={t('Cancel', { ns: 'common' })}
                  onClick={() => {
                    setIsConfirmationBoxChecked(false);
                    setActiveStep(1);
                    onCloseModal();
                    methods.reset();
                  }}
                  tertiary
                  color={theme.palette.systemMessage.error}
                  disabled={loading}
                />
                <Button
                  label={
                    activeStep === 1
                      ? t('Next', { ns: 'common' })
                      : t('ResidentApp.SendPushNotification')
                  }
                  onClick={methods.handleSubmit(onSubmitNotification)}
                  disabled={getIsButtonDisabled(activeStep)}
                />
              </RightButtons>
            </Buttons>
          </FormProvider>
        </FormContent>
      </Content>
    </Modal>
  );
};

export default PushNotificationsModal;

const Content = styled.div`
  padding: 20px 0;
`;

const FormContent = styled.div`
  padding: 20px 20px 0 20px;
`;

const Buttons = styled.div<{hasBackButton: boolean}>`
  display: flex;
  justify-content: ${(props) => props.hasBackButton ? 'space-between' : 'flex-end'};
  gap: 10px;
  margin-top: 24px;
`;

const RightButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Preview = styled.div`
  display: grid;
  gap: 24px;
  margin-top: 24px;
`;