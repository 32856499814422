import DeviceCard from '@dashboard/components/molecules/DeviceCard/DeviceCard';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { useTranslation } from 'react-i18next';
import { CommonSidebar } from '../../shared/CommonSidebar/CommonSidebar';
import { useLayoutContext } from '@src/contexts/LayoutContext';
import { BuildingHierarchyLevel, useBuildingContext } from '../BuildingPage/BuildingProvider';

type PropTypes = {
  device: DeviceWithMetrics,
}

const Sidebar = ({ device }: PropTypes) => {
  const { t } = useTranslation();
  const { isFullScreen } = useLayoutContext();
  const { hierarchyLevel } = useBuildingContext();

  return (
    <CommonSidebar isHidden={isFullScreen || hierarchyLevel !== BuildingHierarchyLevel.Device}>
      <BackButton
        label={t('BackToSpace', { ns: 'navigation' })}
        url='..'
      />

      <DeviceCard
        device={device}
        inSidebar
      />
    </CommonSidebar>
  );
}

export default Sidebar;