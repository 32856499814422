import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { SpaceClimateControlDetailsDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDetailsDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import SpaceDetailsDeviceCharts from './Charts/SpaceDetailsDeviceCharts';

type PropTypes = {
  spaceDetails?: SpaceClimateControlDetailsDto;
  unit: string;
  onClose: () => void;
  onDeleteException: () => void;
  onCreateException: (spaceId: number) => void;
  onEditException: (spaceId: number) => void;
}

const SpaceDetailsModalContent = ({ spaceDetails, unit, onClose, onDeleteException, onCreateException, onEditException }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { toLocale } = useLocalisation();
  const navigate = useNavigate();

  if (!spaceDetails) {
    return null;
  }

  return (
    <>
      <Container>
        <Header>
          <SpaceName>{spaceDetails.spaceName}</SpaceName>
          <CloseIcon
            icon={solid('xmark')}
            onClick={onClose}
          />
        </Header>
        <SpaceLocation>{`${spaceDetails.floorName}, ${spaceDetails.buildingName}`}</SpaceLocation>
        <TempDetails>
          <Detail>
            <Label>{t('ClimateControl.Minimum', { ns: 'molecules' })}</Label>
            <Value>
              {toLocale(MetricType.Temperature, spaceDetails.minTemp, { round: 1 })} {unit}
            </Value>
          </Detail>
          <Detail>
            <Label>{t('ClimateControl.Maximum', { ns: 'molecules' })}</Label>
            <Value>
              {toLocale(MetricType.Temperature, spaceDetails.maxTemp, { round: 1 })} {unit}
            </Value>
          </Detail>
          <Detail>
            <Label>{t('DeviceTarget', { ns: 'common' })}</Label>
            <Value>
              {spaceDetails.targetTemp ?
                `${toLocale(MetricType.Temperature, spaceDetails.targetTemp, { round: 1 })} ${unit}` :
                '-'
              }
            </Value>
          </Detail>
          <Detail>
            <Label>{t('DeviceActual', { ns: 'common' })}</Label>
            <Value>
              {spaceDetails.deviceTemp ?
                `${toLocale(MetricType.Temperature, spaceDetails.deviceTemp, { round: 1 })} ${unit}` :
                '-'
              }
            </Value>
          </Detail>
          <Detail>
            <Label>{t('Status', { ns: 'common' })}</Label>
            <Value>{spaceDetails.deviceStatus}</Value>
          </Detail>
          <Detail>
            <Label>{t('Exception', { ns: 'common' })}</Label>
            <Value>{spaceDetails.isAnException ? t('Yes', { ns: 'common' }) : t('No', { ns: 'common' })}</Value>
          </Detail>
        </TempDetails>
        <ActionButtons>
          {spaceDetails.isAnException ?
            <>
              <Button
                secondary
                label={t('ClimateControl.EditException', { ns: 'molecules' })}
                style={{ width: '100%', textWrap: 'nowrap' }}
                onClick={() => onEditException(spaceDetails.spaceId)}
              />
              <Button
                secondary
                color={theme.palette.red}
                label={t('ClimateControl.DeleteException', { ns: 'molecules' })}
                style={{ width: '100%', textWrap: 'nowrap' }}
                onClick={() => onDeleteException()}
              />
            </> :
            <Button
              secondary
              label={t('ClimateControl.CreateException', { ns: 'molecules' })}
              style={{ width: '100%', textWrap: 'nowrap' }}
              onClick={() => onCreateException(spaceDetails.spaceId)}
            />
          }
          {spaceDetails &&
            <Button
              secondary
              label={t('ClimateControl.GoToSpace', { ns: 'molecules' })}
              style={{ width: '100%', textWrap: 'nowrap' }}
              onClick={() => navigate(`./../building/${spaceDetails.buildingId}/floor/${spaceDetails.floorId}/space/${spaceDetails.spaceId}`)}
            />
          }
        </ActionButtons>
      </Container>

      <SpaceDetailsDeviceCharts
        spaceDetails={spaceDetails}
      />
    </>
  )
}

export default SpaceDetailsModalContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const SpaceName = styled.div`
  color: ${p => p.theme.text.primary};
  font-size: 20px;
  font-weight: 500;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
`;

const SpaceLocation = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-size: 14px;
  font-weight: 400;
`;

const TempDetails = styled.div`
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin: 5px 0;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const Label = styled.span`
  color: ${p => p.theme.palette.text.fair};
  font-size: 12px;
  font-weight: 400;
`;

const Value = styled.span`
  color: ${p => p.theme.text.primary};
  font-size: 14px;
  font-weight: 400;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;

  @media (max-width: 375px) {
    flex-wrap: wrap;
  }
`;
