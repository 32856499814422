import styled, { css, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons';
import { IFileDropzone } from './FileDropzone.types';

export const FileDropzone = ({ fileUri, fileName, mainText, dimText, isCircle, onDelete, getRootProps, getInputProps, containerStyle, imageStyle, readOnly }: IFileDropzone) => {
  const theme = useTheme();

  const containsImage = !!fileUri
  const containsFile = !!fileName

  return (
    <ImageContainer containsImage={containsImage} isCircle={isCircle} style={containerStyle} readOnly={readOnly}>
      {!readOnly &&
        <>
          <DeleteButtonOverlay containsImage={containsImage || containsFile} onClick={onDelete} isCircle={isCircle}>
            &times;
          </DeleteButtonOverlay>
          <UploadOverlay containsImage={containsImage} isCircle={isCircle} {...getRootProps()}>
            <FileInput type="file" {...getInputProps()} />
            <FontAwesomeIcon icon={fileName ? faFile : faFileArrowUp} style={{ fontSize: '24px' }} color={theme.palette.primary} />
            {fileName &&
              <FileName>{fileName}</FileName>
            }
            {mainText &&
              <MainText>{mainText}</MainText>
            }
            {dimText &&
              <DimText>{dimText}</DimText>
            }
          </UploadOverlay>
        </>
      }
      {fileUri &&
        <Image src={`${fileUri}`} isCircle={isCircle} style={imageStyle} readOnly={readOnly} />
      }
    </ImageContainer>
  );
};

const ImageContainer = styled.div<{ containsImage: boolean, isCircle?: boolean, readOnly?: boolean }>`
  position: relative;
 
  text-align: center; 
  border: 1px dashed ${p => transparentize(0.5, p.theme.palette.primary)};
  border-radius: 4px;

  ${p => !p.containsImage && css`
    height: 100px;
    @media (min-width: 768px) {
      height: 200px;
    }
  `}

  ${p => p.isCircle && css`
    height: 110px !important;
    width: 110px !important;
    border-radius: 50%;
    background-color: ${p => p.theme.palette.backgrounds.surface};
  `}

  ${p => p.readOnly && css`
    border: none;
  `}
`;

const UploadOverlay = styled.div<{ containsImage: boolean, isCircle?: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  ${p => p.containsImage && css`
    opacity: 0;
  `}
  
  transition: opacity 150ms ease-out;

  ${ImageContainer}:hover & {
    opacity: 1;
    background-color: ${p => transparentize(0.1, p.theme.palette.backgrounds.surfaceStrong)};
  }

  ${p => p.isCircle && css`
    border-radius: 50%;
  `}
`;

const DeleteButtonOverlay = styled.div<{ containsImage: boolean, isCircle?: boolean }>`
  position: absolute;
  top: ${p => p.isCircle ? '3px' : '10px'};
  right: ${p => p.isCircle ? '3px' : '10px'};
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 23px;
  height: 23px;
  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 50%;
  background-color: ${p => transparentize(0, p.theme.palette.backgrounds.surfaceStrong)};
  font-size: 22px;

  color: ${p => p.theme.palette.primary};
  cursor: pointer;
  transition: opacity 150ms ease-out;

  &:hover {
    color: ${p => p.theme.palette.backgrounds.surfaceStrong};
    border-color: ${p => transparentize(0.9, p.theme.palette.primary)};
    background-color: ${p => transparentize(0.4, p.theme.palette.primary)};
  }

  opacity: 0;

  ${p => p.containsImage && css`
    ${ImageContainer}:hover & {
      opacity: 1;
    }
  `}
`;

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const FileName = styled.span`
  color: ${p => p.theme.palette.primary};
  font-weight: 500;
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
`;

const MainText = styled.span`
  color: ${p => p.theme.palette.primary};
  font-weight: 500;
  font-size: 14px;
`;

const DimText = styled.span`
  color: ${p => p.theme.palette.text.weak};
  font-weight: 500;
  font-size: 12px;
`;

const Image = styled.img<{ isCircle?: boolean, readOnly?: boolean }>`
  position: relative;
  width: 100%;
  
  padding: 10px;
  @media (min-width: 768px) {
    padding: 25px;
  }

  ${p => p.readOnly && css`
    padding: 0px !important;
  `}

  ${p => p.isCircle && css`
    width: 100%;
    height: 100%;
    padding: 0 !important;
    border-radius: 50%;
    object-fit: cover;
  `}
`;