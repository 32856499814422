import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInDays } from 'date-fns';
import { EsgExplorerConfiguration } from '@dashboard/api/models/EsgExplorerConfiguration';
import ConfigGroup from './ConfigGroup';
import { Building } from '@shared/api/models/Building/Building';
import FilterList from './Filters/FilterList';
import MetricSelect from './Selectors/MetricSelect';
import SpaceTypeSelect from './Selectors/SpaceTypeSelect';
import BuildingSelect from './Selectors/BuildingSelect';
import LettingStatusSelect from './Selectors/LettingStatusSelect';
import TimeRangeSelect from './Selectors/TimeRangeSelect';
import { useTranslation } from 'react-i18next';

interface IExplorerConfiguration {
  siteId: number;
  buildings: Building[];
  config: EsgExplorerConfiguration,
  onChange: (config: EsgExplorerConfiguration) => void;
}

const ExplorerConfiguration = ({ siteId, buildings, config, onChange }: IExplorerConfiguration) => {
  const { t } = useTranslation(['molecules']);
  const largeTimeRangeSelected = config.timeRange ? differenceInDays(new Date(config.timeRange.to), new Date(config.timeRange.from)) > 30 : false;
  const manyMetricsSelected = config.metricTypes.length > 9;

  return (
    <Column>
      <ConfigGroup
        title={t('ESG.TimeRange', { ns: 'molecules' })}
        tooltipText={t('ESG.ExclusiveOfEndDate', { ns: 'molecules' })}
      >
        <TimeRangeSelect
          config={config}
          onChange={onChange}
        />
      </ConfigGroup>

      <ConfigGroup
        title={t('ESG.Spaces', { ns: 'molecules' })}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <BuildingSelect
            buildings={buildings}
            config={config}
            onChange={onChange}
          />

          <SpaceTypeSelect
            siteId={siteId}
            config={config}
            onChange={onChange}
          />

          <LettingStatusSelect
            config={config}
            onChange={onChange}
          />
        </div>
      </ConfigGroup>

      <ConfigGroup
        title={t('ESG.Metrics', { ns: 'molecules' })}
      >
        <MetricSelect
          siteId={siteId}
          config={config}
          onChange={onChange}
        />
      </ConfigGroup>

      {(largeTimeRangeSelected || manyMetricsSelected) &&
        <Caption>
          <WarningIcon icon={solid('exclamation-triangle')} />
          {t('ESG.RequestDurationWarning', { ns: 'molecules' })}
        </Caption>
      }

      <FilterList
        config={config}
        onChange={filters => onChange({ ...config, filters: filters })}
      />
    </Column>
  );
};

export default ExplorerConfiguration;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  height: max-content;
  min-width: 250px;
  flex-basis: 100%;
`;

const Caption = styled.div`
  margin-top: -5px;
  padding: 0px 7px;
  font-size: 12px;
  border-radius: 5px;
  color: ${p => p.theme.palette.text.weak};
`;

const WarningIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${p => p.theme.palette.orange};
  margin-right: 4px;
`;

export const EsgExplorerConfigLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.light};
  margin-bottom: 3px;
  margin-left: 3px;
`;