import { callDelete, IAuthConfig } from '@shared/utils/ConnectApiUtils';
import { BaseQuery } from './BaseQuery';

export abstract class GenericDeleteQuery<TDto> extends BaseQuery<TDto> {
  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.apiBaseUrl);
    const response = await callDelete(apiUrl, this, authConfig, controller);

    return response.data;
  }
}
