import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { useApi } from '@shared/hooks/useApi';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import { valuesDiffer } from '@shared/utils/ObjectUtils';
import { User } from '@shared/api/models/User/User';
import UserProfileUpdateCommand from '@dashboard/api/queries/userProfile/UserProfileUpdateCommand';
import UserProfileProfileImageUploadCommand from '@dashboard/api/queries/userProfile/UserProfileProfileImageUploadCommand';
import UserProfileProfileImageDeleteCommand from '@dashboard/api/queries/userProfile/UserProfileProfileImageDeleteCommand';
import { BlobFileUploadArea } from '@shared/components/molecules/BlobFileUploadArea/BlobFileUploadArea';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { Button } from '@shared/components/atoms/Button/Button';
import { Card } from '@shared/components/molecules/Card/Card';
import ConsentPreferencesContent from '@shared/components/molecules/ConsentPreferencesContent/ConsentPreferencesContent';
import UpdatePassword from './UpdatePassword';
import MfaSettings from './MfaSettings';
import LocalisationPreferencesContent from '@shared/components/molecules/LocalistionPreferencesContent/LocalisationPreferencesContent';

type FormValues = {
  fullName: string,
  displayName?: string,
  phoneNumber: string
}

const ProfilePage = () => {
  const { t } = useTranslation(['common']);
  const { user, setUser } = useUserContext();
  const { execute } = useApi();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValues>();
  const { file, fileName, fileHasChanged, fileToBeDeleted, handleFileChange, handleFileDelete } = useFileHandler(user?.profileImageId);
  const formValues = watch();

  useEffect(() => {
    const hasBeenModified = valuesDiffer<FormValues, User>(formValues, user);
    setHasUnsavedChanges(hasBeenModified || fileHasChanged || fileToBeDeleted);
  }, [user, formValues, fileHasChanged, fileToBeDeleted]);

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const modifiedUser = cloneDeep(user);
    modifiedUser.fullName = data.fullName;
    modifiedUser.phoneNumber = data.phoneNumber;
    modifiedUser.displayName = data.displayName;

    const updateResult = await updateUser(modifiedUser);
    const updateImageResult = await updateProfileImage(modifiedUser);

    if (!updateResult || !updateImageResult) {
      return;
    }

    setSavingInProgress(false);
    setUser(modifiedUser);
  }

  const updateUser = async (modifiedUser: User) => {
    if (valuesDiffer(modifiedUser, user)) {
      return await execute({
        query: new UserProfileUpdateCommand(modifiedUser.fullName, modifiedUser.phoneNumber, modifiedUser.displayName),
        successMessage: t('ProfilePageUserSuccessMessage', { ns: 'common' }),
        errorMessage: t('ProfilePageUserErrorMessage', { ns: 'common' })
      });
    } else {
      return true;
    }
  };

  const updateProfileImage = async (user: User) => {
    let isMounted = true;

    if (fileHasChanged && file) {
      const result = await execute({
        query: new UserProfileProfileImageUploadCommand(file),
        successMessage: t('ProfilePageImageSuccessMessage', { ns: 'common' }),
        errorMessage: t('ProfilePageImageErrorMessage', { ns: 'common' })
      });
      user.profileImageId = result?.blobName;
      isMounted = result !== undefined;
    } else if (fileToBeDeleted && user.profileImageId) {
      const result = await execute({
        query: new UserProfileProfileImageDeleteCommand(user.profileImageId),
        successMessage: t('ProfilePageImageDeleteSuccess', { ns: 'common' }),
        errorMessage: t('ProfilePageImageDeleteError', { ns: 'common' })
      });
      user.profileImageId = undefined;
      isMounted = result !== undefined;
    }

    return isMounted;
  };

  return (
    <FullWidthContainer>
      <StyledCardTop
        noPadding
        centered
        maxWidth='700px'
      >
        <ProfileImageContainer>
          <BlobFileUploadArea
            blobName={fileName}
            blobContainer={BlobStorageContainerType.Shared}
            mainText={t('ProfilePageProfileImage', { ns: 'common' })}
            acceptedTypes={['image/*']}
            onFileChange={handleFileChange}
            onFileDelete={handleFileDelete}
            isCircle
          />
        </ProfileImageContainer>
      </StyledCardTop>
      <StyledCardBottom
        centered
        maxWidth='700px'
      >
        <Form>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Label>{t('ProfilePageForm.FullName', { ns: 'common' })}</Label>
                <Input {...register('fullName', { required: t('ProfilePageForm.Required', { ns: 'common' }) })} defaultValue={user.fullName} />
                <ErrorMessage>{errors.fullName?.message}</ErrorMessage>
              </div>
              <div className="col-md-6">
                <Label>{t('ProfilePageForm.DisplayName', { ns: 'common' })}</Label>
                <Input {...register('displayName', { required: t('ProfilePageForm.Required', { ns: 'common' }) })} defaultValue={user.displayName} />
                <ErrorMessage>{errors.displayName?.message}</ErrorMessage>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Label>{t('ProfilePageForm.Email', { ns: 'common' })}</Label>
                <Input
                  type="email"
                  defaultValue={user.email}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <Label>{t('ProfilePageForm.PhoneNumber', { ns: 'common' })}</Label>
                <Input
                  {...register('phoneNumber', {
                    required: t('ProfilePageForm.Required', { ns: 'common' }),
                    pattern: { value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i, message: t('ProfilePageForm.PhoneNumberMessage', { ns: 'common' }) },
                    setValueAs: value => nullIfEmptyString(value)
                  })}
                  defaultValue={user.phoneNumber}
                />
                <ErrorMessage>{errors.phoneNumber?.message}</ErrorMessage>
              </div>
            </div>
          </div>
        </Form>

        <Button
          label={t('ProfilePageForm.Save', { ns: 'common' })}
          onClick={handleSubmit(onSave)}
          disabled={!hasUnsavedChanges || !isEmpty(errors)}
          loading={savingInProgress}
          style={{ margin: '32px auto 0 auto' }}
        />

        <UpdatePassword userId={user.id} />

        <MfaSettings user={user} />
      </StyledCardBottom>

      <LocalisationCard
        noPadding
        centered
        maxWidth='700px'
      >
        <LocalisationPreferencesContent />
      </LocalisationCard>

      <Card
        noPadding
        centered
        maxWidth='700px'
      >
        <ConsentPreferencesContent />
      </Card>
    </FullWidthContainer>
  );
};

export default ProfilePage;

const FullWidthContainer = styled.div`
  width: 100%;
  background-color: ${p => p.theme.palette.backgrounds.background};
  padding: 40px 0;
`;

const StyledCardTop = styled(Card)`
  border-top: 4px solid ${p => p.theme.palette.primary} !important;
  border-radius: 3px 3px 0 0 !important;
  border-bottom: none;
  margin-bottom: 0 !important;
`;

const StyledCardBottom = styled(Card)`
  border-radius: 0 0 3px 3px !important;
  margin-top: 0px;
  margin-bottom: 40px;
  padding-top: 55px;
`;

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;

  height: 110px;
  background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  padding-top: 55px;
`;

const LocalisationCard = styled(Card)`
  margin-bottom: 40px;
`;