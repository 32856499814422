import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { TRVControlMode } from '@shared/api/enums/TRVControlMode/TRVControlMode';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import { HierarchyBuilding, HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import SpaceLookup from '@shared/components/molecules/SpaceLookup/SpaceLookup';
import { ExceptionForm } from '../types/ExceptionForm';
import ExceptionsForm from './ExceptionsForm';

type CreateExceptionProps = {
  buildings: HierarchyBuilding[];
  siteClimateControl: SiteClimateControlDto;
  selectedSpace?: HierarchySpace;
  onCreateException: () => void;
  onCancel: () => void;
}

const CreateException = ({ buildings, siteClimateControl, selectedSpace, onCreateException, onCancel }: CreateExceptionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { toLocale } = useLocalisation();
  const { setValue, reset, watch, clearErrors, formState: { errors } } = useFormContext<ExceptionForm>();
  const endDate = watch('endDate');

  useEffect(() => {
    reset({
      minTemp: toLocale(MetricType.Temperature, siteClimateControl.minTemp, { round: 0 }),
      maxTemp: toLocale(MetricType.Temperature, siteClimateControl.maxTemp, { round: 0 }),
      targetTemp: toLocale(MetricType.Temperature, siteClimateControl.targetTemp, { round: 0 }),
      controlMode: TRVControlMode.TRV,
      externalSensorModeEnabled: siteClimateControl.externalSensorModeEnabled,
      endDate: undefined
    });

    if (selectedSpace) {
      setValue('spaceIds', [selectedSpace.id]);
    }
  }, [reset, selectedSpace, setValue, siteClimateControl, toLocale]);

  return (
    <Container>
      <Title
        text={t('ClimateControl.CreateException', { ns: 'molecules' })}
      />
      <StyledFlexColumn>
        {selectedSpace ?
          <LabelContainer >
            <SelectedLabel>{selectedSpace?.name}</SelectedLabel>
          </LabelContainer> :
          <>
            <StyledFlexColumn style={{ gap: 5 }}>
              <SpaceLookup
                buildings={buildings}
                onChange={spaces => {
                  clearErrors('spaceIds')
                  setValue('spaceIds', spaces)
                }}
                resetPulse={true}
              />
              {errors.spaceIds &&
                <span style={{
                  color: theme.error.main,
                  fontSize: 14
                }}>
                  {errors.spaceIds.message}
                </span>
              }
            </StyledFlexColumn>
          </>
        }
        <ExceptionsForm />
      </StyledFlexColumn>
      <ButtonContainer>
        <Button
          tertiary
          label={t('Cancel', { ns: 'common' })}
          onClick={onCancel}
          color={theme.palette.red}
        />
        <Button
          disabled={endDate ? dayjs().isAfter(endDate) : false}
          label={t('Create', { ns: 'common' })}
          onClick={onCreateException}
        />
      </ButtonContainer>
    </Container>
  )
}

export default CreateException

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 24px;
`;

const StyledFlexColumn = styled(FlexColumn)`
  gap: 30px;
  padding-bottom: 10px;
  max-width: 400px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const LabelContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 5px;
  padding: 9px 54px 9px 10px;
  background-color: ${p => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.action.outlineBorder};
  border-radius: 4px;
  box-shadow: ${p => p.theme.palette.forms.input.boxShadow};
`;

const SelectedLabel = styled.div`
  font-size: 12px;
  padding: 2px 9px;
  border-radius: 14px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};
  max-width: 200px;
`;