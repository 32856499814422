import { useTheme } from 'styled-components';
import { SiteFairUseSeasonalityAdjustment } from '@shared/api/models/FairUse/SiteFairUseSeasonalityAdjustment';
import NumberInput from '@shared/components/atoms/NumberInput/NumberInput';

type SeasonalityPercentageProps = {
  seasonalityAdjustment: SiteFairUseSeasonalityAdjustment,
  disabled: boolean;
  onValueChange: (updatedValue: number) => void
}

const SeasonalityPercentage = ({ seasonalityAdjustment, disabled, onValueChange }: SeasonalityPercentageProps) => {
  const theme = useTheme();

  return (
    <NumberInput
      value={seasonalityAdjustment.adjustment}
      unit={'%'}
      onChange={(value: number) => onValueChange(value)}
      disabled={disabled}
      inputStyle={{ height: '40px', width: '100%', borderRadius: '0', fontWeight: '400', color: theme.palette.text.fair, textAlign: 'center', padding: 0 }}
      arrowStyle={{ top: '5px' }}
      unitStyle={{ top: '10px', fontWeight: '400', color: theme.palette.text.fair }}
    />
  )
}

export default SeasonalityPercentage