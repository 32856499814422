import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import DeviceBulkImport from './DeviceBulkImport';
import { useTranslation } from 'react-i18next';
import { DevicesGetByBuildingIdQuery } from '@dashboard/api/queries/devices/DevicesGetByBuildingIdQuery';
import { Building } from '@shared/api/models/Building/Building';
import { DeviceDetails } from '@shared/api/models/Device/DeviceDetails';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { BreadcrumbHeight, TopBarHeight } from '@src/constants/LayoutConstants';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApiState } from '@shared/hooks/useApiState';
import { includesCI } from '@shared/utils/StringUtils';
import { Table } from '@shared/components/molecules/Table/Table';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';

interface IBuildingDeviceList {
  building: Building;
}

const BuildingDeviceList = ({ building }: IBuildingDeviceList) => {
  const { t } = useTranslation(['settingsAsset']);
  const navigate = useNavigate();
  const { getDisplayString } = useDeviceConfigContext();
  const [searchString, setSearchString] = useState<string>('');
  const { data: devices, loading, execute: refreshData } = useApiState({
    query: new DevicesGetByBuildingIdQuery(building.id),
    initialState: [],
  }, []);

  const tableColumns: ITableColumn<DeviceDetails>[] = useMemo(() => ([
    {
      label: t('Buildings.Devices.DeviceList.FriendlyName', { ns: 'settingsAsset' }),
      key: 'friendlyName',
    },
    {
      label: t('Buildings.Devices.DeviceList.DeviceModel', { ns: 'settingsAsset' }),
      key: 'deviceModel',
      displayFormat: record => getDisplayString(record.deviceModel),
    },
    {
      label: t('Buildings.Devices.DeviceList.DeviceIdentifier', { ns: 'settingsAsset' }),
      key: 'deviceIdentifier',
    },
    {
      label: t('Buildings.Devices.DeviceList.Space', { ns: 'settingsAsset' }),
      key: 'spaceName',
    }
  ]), [getDisplayString, t]);

  const searchFilter = useCallback((x: DeviceDetails) => {
    return includesCI(x.friendlyName, searchString)
      || includesCI(getDisplayString(x.deviceModel), searchString)
      || includesCI(x.deviceIdentifier, searchString)
      || includesCI(x.spaceName, searchString)
  }, [searchString, getDisplayString]);

  return (
    <>
      <BackButton
        url='./..'
        label={t('BackToDevices', { ns: 'navigation' })}
      />

      <FlexRow>
        <Heading>
          Devices <ThemeColored>({devices.length})</ThemeColored>
        </Heading>

        <div style={{ marginLeft: 'auto' }} />

        <DeviceBulkImport
          buildingId={building.id}
          onComplete={() => { refreshData() }}
        />

        <SearchField
          placeholder={t('Buildings.Devices.DeviceList.SearchDevices', { ns: 'settingsAsset' })}
          onSearchChange={setSearchString}
          width='240px'
        />
      </FlexRow>

      <Table
        columns={tableColumns}
        records={devices}
        recordKey={'deviceId'}
        defaultSortColumn="friendlyName"
        emptyMessage={t('Buildings.Devices.DeviceList.NoDevices', { ns: 'settingsAsset' })}
        highlightString={searchString}
        filters={[searchFilter]}
        cardEffect
        fullHeightSubtractor={TopBarHeight + BreadcrumbHeight + 370}
        loading={loading}
        onRowClick={(row: DeviceDetails) => {
          if (row.deviceModel != 'HikvisionPeopleCounting') {
            navigate(`./../../../floor/${row.floorId}/space/${row.spaceId}/device/${row.deviceId}`)
          }
        }}
      />
    </>
  );
}

export default BuildingDeviceList;

const FlexRow = styled.div` 
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;