import { BlobName } from '@shared/api/models/Blob/BlobName';
import { PostFormDataQuery } from '@shared/api/queries/common/PostFormDataQuery';

export default class TenantLogoUploadCommand extends PostFormDataQuery<BlobName> {

  constructor(file: File, tenantId: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('tenantId', tenantId);

    super(formData);
  }

  public targetUrl(): string {
    return '/tenants/logo';
  }
}
