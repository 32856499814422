import { RefObject } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Alert } from '@shared/api/models/Alert/Alert';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import AlertModalSidebar from './AlertModalSidebar/AlertModalSidebar';
import AlertModalContent from './AlertModalContent/AlertModalContent';

interface IAlertModal {
  modalRef: RefObject<HTMLDivElement>;
  isOpen: boolean;
  closeModal: () => void;
  alert: Alert;
  onMobile: boolean;
  acknowledgeAlert: (alert: Alert) => void
}

const AlertModal = ({ modalRef, isOpen, closeModal, alert, onMobile, acknowledgeAlert }: IAlertModal) => {
  const theme = useTheme();

  return (
    <Modal
      ref={modalRef}
      isOpen={isOpen}
      plainModal
      width="800px"
      modalStyles={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
      dialogStyles={{ boxShadow: `0px 2px 12px 1px ${theme.palette.shadows.strong}` }}
    >
      <Wrapper $onMobile={onMobile}>
        <Content $onMobile={onMobile}>

          <AlertModalSidebar
            alert={alert}
            onMobile={onMobile}
            closeModal={closeModal}
          />

          <AlertModalContent
            alert={alert}
            onMobile={onMobile}
            closeModal={closeModal}
            acknowledgeAlert={acknowledgeAlert}
          />

        </Content>
      </Wrapper>
    </Modal>
  );
};

export default AlertModal;

const Wrapper = styled.div<{ $onMobile: boolean }>`
  z-index: 1;
  white-space: wrap;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 4px;
  height: 580px;

  ${p => p.$onMobile && css`
    height: max-content;
  `}
`;

const Content = styled.div<{ $onMobile: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;

  ${p => p.$onMobile && css`
    flex-direction: column;
  `}
`;

