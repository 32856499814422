import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { Chart, DoughnutController, ArcElement, ChartData, ChartOptions, TooltipOptions } from 'chart.js';
import styled, { useTheme } from 'styled-components';
import DoughnutChartLegend, { TDoughnutChartLegend } from './DoughnutChartLegend';
import { _DeepPartialObject } from 'chart.js/types/utils';

Chart.register(DoughnutController, ArcElement);

export type DoughnutChartDataset = {
  data: number[],
  colors: string[],
  labels?: string[],
}

export interface IDoughnutChartProps {
  dataset: DoughnutChartDataset;
  cutout?: number | string;
  arcBorderWidth?: number
  innerValue?: ReactNode,
  innerLabel?: ReactNode;
  innerSubLabel?: string | ReactNode;
  size?: string;
  wrapperStyles?: React.CSSProperties;
  innerWrapperStyles?: React.CSSProperties;
  innerValueStyles?: React.CSSProperties;
  legend?: TDoughnutChartLegend;
  tooltipOptions?: _DeepPartialObject<TooltipOptions<'doughnut'>>;
}

const DoughnutChart = ({ dataset, cutout, arcBorderWidth, innerValue, innerLabel, innerSubLabel, size, wrapperStyles, innerWrapperStyles, innerValueStyles, legend, tooltipOptions }: IDoughnutChartProps) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const constructData = useCallback((): ChartData<'doughnut', number[], unknown> => {
    return {
      datasets: [{
        data: dataset.data,
        backgroundColor: dataset.colors
      }],
      labels: dataset.labels
    }
  }, [dataset]);

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const options: ChartOptions<'doughnut'> = {
        cutout: cutout ?? '93%',
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          arc: {
            borderWidth: arcBorderWidth ?? 0,
            borderColor: theme.palette.backgrounds.surface
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            ...tooltipOptions
          }
        }
      }
      const chart = new Chart(context, {
        type: 'doughnut',
        data: constructData(),
        options: options
      });

      return () => chart.destroy();
    }
  }, [constructData, cutout, arcBorderWidth, theme, tooltipOptions]);

  return (
    <FlexRow style={wrapperStyles}>
      <div style={{ position: 'relative', height: size ?? '100%', width: size ?? '100%' }}>
        <div style={{ position: 'relative', height: '100%', width: '100%', zIndex: 1 }}>
          <canvas id="myChart" ref={canvasRef} />
        </div>

        <DoughnutDescriptor style={innerWrapperStyles}>
          {innerValue &&
            <DoughnutInnerValue style={innerValueStyles}>{innerValue}</DoughnutInnerValue>
          }

          {innerLabel &&
            <DoughnutInnerLabel>{innerLabel}</DoughnutInnerLabel>
          }

          {innerSubLabel &&
            <DoughnutInnerSubLabel>{innerSubLabel}</DoughnutInnerSubLabel>
          }
        </DoughnutDescriptor>
      </div>

      <DoughnutChartLegend
        legend={legend}
        dataset={dataset}
      />
    </FlexRow>
  );
};

export default DoughnutChart;

const FlexRow = styled.div`
  display: flex;
  gap: 20px;
`;

const DoughnutDescriptor = styled.div`
  position: absolute;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const DoughnutInnerValue = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  color: ${p => p.theme.text.primary};
`;

const DoughnutInnerLabel = styled.span`
  line-height: 14px;
  color: ${p => p.theme.text.secondary};
`;

const DoughnutInnerSubLabel = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${p => p.theme.text.secondary};
  white-space: nowrap;
`;