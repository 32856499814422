import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertRuleTrigger } from '@shared/api/models/AlertRule/AlertRule';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import AlertRuleCard from '../AlertRuleCard';
import DelayConfiguration from './DelayConfiguration';
import Trigger from './Trigger';

interface ITriggerConfiguration {
  enabled?: boolean;
  triggers: AlertRuleTrigger[];
  onChange: (triggers: AlertRuleTrigger[]) => void;
  delay: number;
  onDelayChange: (delay: number) => void;
}

const TriggerConfiguration = ({ enabled, triggers, onChange, delay, onDelayChange }: ITriggerConfiguration) => {
  const { t } = useTranslation(['settingsAlerting']);
  const theme = useTheme();

  return (
    <AlertRuleCard
      title={t('TriggerConfiguration.Conditions', { ns: 'settingsAlerting' })}
      open={true}
      icon={regular('bell')}
      enabled={enabled}
      helperMessage={<span>{t('TriggerConfiguration.Example', { ns: 'settingsAlerting' })}</span>}
    >

      {triggers.map((trigger, i) => (
        <Trigger
          key={i}
          trigger={trigger}
          onChange={trigger => onChange(triggers.map((x, j) => i === j ? trigger : x))}
          remove={() => {
            const clone = [...triggers];
            clone.splice(i, 1);
            onChange(clone)
          }}
          showAndLabel={triggers[i + 1] !== undefined}
        />
      ))}

      <div style={{ width: 'max-content', marginTop: '22px' }}>
        <Tooltip
          content={t('TriggerConfiguration.AddTrigger', { ns: 'settingsAlerting' })}
          placement={TooltipPlacement.Right}
          fontSize='12px'
        >
          <Circle
            color={theme.palette.primary}
            onClick={() => onChange([...triggers, {}])}>
            <FontAwesomeIcon icon={solid('plus')} />
          </Circle>
        </Tooltip>
      </div>

      <DelayConfiguration
        delay={delay}
        onChange={delay => onDelayChange(delay)}
      />
    </AlertRuleCard>
  );
};

export default TriggerConfiguration;

export const Circle = styled.div<{ color: string }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${p => p.color};
  
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${p => p.color};

  &:hover {
    color: ${p => p.theme.palette.text.onPrimary};
    background-color:${p => p.color};
  }
`;