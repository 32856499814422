import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DeviceGetByIdQuery } from '@dashboard/api/queries/devices/DeviceGetByIdQuery';
import SitesGetAllWithHierarchyQuery from '@dashboard/api/queries/site/SitesGetAllWithHierarchyQuery';
import DeviceMoveCommand from '@settings/api/queries/Devices/DeviceMoveCommand';
import { Device } from '@shared/api/models/Device/Device';
import { HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';
import { CascaderSingleSelect } from '@shared/components/atoms/CascaderSingleSelect/CascaderSingleSelect';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import { RouterPrompt } from '@shared/components/navigation/RouterPrompt/RouterPrompt';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { valuesDiffer } from '@shared/utils/ObjectUtils';
import { Card } from '@shared/components/molecules/Card/Card';
import { ErrorMessage, Input, Label } from '@shared/components/atoms/Form/Form';
import { useModal } from '@shared/hooks/useModal';
import { INodeGroup } from '@shared/components/atoms/CascaderSingleSelect/CascaderSingleSelect.types';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { Button } from '@shared/components/atoms/Button/Button';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';

type FormValues = {
  friendlyName: string,
  spaceId?: number,
  deleteMeasurements: boolean,
  newSpaceName?: string
}

const DeviceMove = () => {
  const { t } = useTranslation(['settingsDevice']);
  const theme = useTheme();
  const { execute } = useApi();
  const navigate = useNavigate();
  const { deviceId } = useParams<{ deviceId: string }>();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [executePostSaveAction, setExecutePostSaveAction] = useState(false);
  const [spaceSelectOptions, setSpaceSelectOptions] = useState<INodeGroup<HierarchySpace>>();
  const { isOpen: moveDeviceIsOpen, toggle: toggleDeviceMoveDialog, ref: cancelDeviceMoveRef } = useModal({});
  const { register, handleSubmit, setValue, watch, control, formState: { errors } } = useForm<FormValues>();
  const formValues = watch();

  const { data: device, setData: setDevice } = useApiState({
    query: deviceId === undefined ? undefined : new DeviceGetByIdQuery(parseInt(deviceId))
  }, [deviceId]);
  const { data: siteHierarchy } = useApiState({
    query: new SitesGetAllWithHierarchyQuery(),
    initialState: []
  }, []);

  useEffect(() => {
    if (device) {
      const hasBeenModified = valuesDiffer<FormValues, Device>(formValues, device);
      setHasUnsavedChanges(hasBeenModified);
    }
  }, [device, formValues]);

  /**
   * Execute after saving, redirect back to the device
   */
  useEffect(() => {
    if (executePostSaveAction && !hasUnsavedChanges) {
      navigate('./..');
    }
  }, [executePostSaveAction, hasUnsavedChanges, deviceId, navigate]);

  useEffect(() => {
    const options: INodeGroup<HierarchySpace> = {
      header: t('DeviceMove.Sites', { ns: 'settingsDevice' }),
      nodes: orderBy(siteHierarchy, x => x.name.toLocaleLowerCase()).map((site) => ({
        label: `${site.name}`,
        childGroup: {
          header: t('DeviceMove.Buildings', { ns: 'settingsDevice' }),
          nodes: orderBy(site.buildings, x => x.name.toLocaleLowerCase()).map((building) => ({
            label: `${building.name}`,
            childGroup: {
              header: t('DeviceMove.Floors', { ns: 'settingsDevice' }),
              nodes: orderBy(building.floors, x => x.floorNumber).map((floor) => ({
                label: `${floor.name}`,
                childGroup: {
                  header: t('DeviceMove.Spaces', { ns: 'settingsDevice' }),
                  nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map((space) => ({
                    label: `${space.name}`,
                    value: space,
                    selectable: true,
                    selectedByDefault: space.id === device?.spaceId
                  }))
                }
              })),
            },
          })),
        },
      })),
    };

    setSpaceSelectOptions(options);
  }, [siteHierarchy, device, t])

  if (!device) {
    return null;
  }

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    if (data.spaceId === undefined || data.spaceId === device.spaceId) {
      setSavingInProgress(false);
      toggleDeviceMoveDialog();
      return;
    }

    if (data.deleteMeasurements === undefined) {
      data.deleteMeasurements = false;
    }

    const modifiedDevice = cloneDeep(device);
    modifiedDevice.friendlyName = data.friendlyName === undefined ? device.friendlyName : data.friendlyName;
    modifiedDevice.spaceId = data.spaceId;

    if (!valuesDiffer(modifiedDevice, device)) {
      return;
    }

    const result = await execute({
      query: new DeviceMoveCommand(device.id, modifiedDevice.friendlyName, modifiedDevice.spaceId, data.deleteMeasurements),
      successMessage: t('DeviceMove.ChangesSaved', { ns: 'settingsDevice' }),
      errorMessage: t('DeviceMove.SavingFailed', { ns: 'settingsDevice' })
    });

    if (result === undefined) {
      return;
    }

    setDevice(modifiedDevice);
    setSavingInProgress(false);
    setExecutePostSaveAction(true);
  }

  const updateSpace = async (space: HierarchySpace | undefined) => {
    if (!space) {
      setValue('newSpaceName', undefined);
      setValue('spaceId', undefined)
      return;
    }

    setValue('newSpaceName', space.name);
    setValue('spaceId', space.id)
  }

  const MoveDeviceDialogContent = (
    <MoveDeviceDialogWrapper>
      <FlexColumn>
        <DialogLabel>Device Name:</DialogLabel>
        <Row>
          <RowLabel>{device.friendlyName}</RowLabel>
          <ArrowIcon icon={solid('arrow-right')} />
          <NewRowLabel>{formValues.friendlyName}</NewRowLabel>
        </Row>
      </FlexColumn>

      <FlexColumn>
        <DialogLabel>{t('DeviceMove.Space', { ns: 'settingsDevice' })}:</DialogLabel>
        <Row>
          <RowLabel>{device.spaceName}</RowLabel>
          <ArrowIcon icon={solid('arrow-right')} />
          <NewRowLabel>{formValues.spaceId === undefined ? t('DeviceMove.NotSet', { ns: 'settingsDevice' }) : formValues.newSpaceName}</NewRowLabel>
        </Row>
      </FlexColumn>

      <FlexColumn>
        <DialogLabel>{t('DeviceMove.Measurements', { ns: 'settingsDevice' })}:</DialogLabel>
        <Measurements>
          {t('DeviceMove.MeasurementsText', { ns: 'settingsDevice' })} <RetainDeleteLabel delete={formValues.deleteMeasurements}>{formValues.deleteMeasurements ? t('DeviceMove.Deleted', { ns: 'settingsDevice' }) : t('DeviceMove.Retained', { ns: 'settingsDevice' })}</RetainDeleteLabel>
        </Measurements>
      </FlexColumn>
    </MoveDeviceDialogWrapper>
  );

  return (
    <PaddedContainer centered>
      <RouterPrompt when={hasUnsavedChanges} />

      <Container>
        <BackButton
          label={t('BackToDevice', { ns: 'navigation' })}
          url='./..'
        />

        <Card cardTitle={t('DeviceMove.MoveDevice', { ns: 'settingsDevice' })}>
          <DescriptionText>{t('DeviceMove.MoveDeviceText', { ns: 'settingsDevice' })}</DescriptionText>

          <FormContainer>
            <FlexRow>
              <Column>
                <Label>{t('DeviceMove.FriendlyName', { ns: 'settingsDevice' })}</Label>
                <Input
                  {...register('friendlyName', {
                    required: t('DeviceMove.RequiredField', { ns: 'settingsDevice' }),
                  })}
                  defaultValue={device.friendlyName}
                />
                <ErrorMessage>{errors.friendlyName?.message}</ErrorMessage>
              </Column>
            </FlexRow>
            <FlexRow>
              <Column style={{ minWidth: '180px' }}>
                <Label>{t('DeviceMove.SpaceName', { ns: 'settingsDevice' })}</Label>
                {spaceSelectOptions &&
                  <CascaderSingleSelect
                    group={spaceSelectOptions}
                    onChange={(space) => updateSpace(space)}
                    confirmButton={t('Apply', { ns: 'common' })}
                    cancelButton={t('Cancel', { ns: 'common' })}
                  />
                }
                <ErrorMessage>{errors.spaceId?.message}</ErrorMessage>
              </Column>
            </FlexRow>
            <FlexRow>
              <Column>
                <Label>{t('DeviceMove.Measurements', { ns: 'settingsDevice' })}</Label>
                <MeasurementsChoiceFlexRow>
                  <Controller
                    control={control}
                    name="deleteMeasurements"
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={(state) => onChange(state)}
                        colorChecked={theme.palette.red}
                        colorUnchecked={theme.palette.primary}
                        styles={{ marginRight: '6px' }}
                      />
                    )}
                  />
                  <RetainDeleteLabel delete={formValues.deleteMeasurements} style={{ fontSize: '14px' }}>
                    {formValues.deleteMeasurements ? t('DeviceMove.Delete', { ns: 'settingsDevice' }) : t('DeviceMove.Retain', { ns: 'settingsDevice' })}
                  </RetainDeleteLabel>
                </MeasurementsChoiceFlexRow>
              </Column>
            </FlexRow>
          </FormContainer>
        </Card>

        <Card>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <Button
              label={t('DeviceMove.Save', { ns: 'settingsDevice' })}
              onClick={toggleDeviceMoveDialog}
              disabled={!hasUnsavedChanges || !isEmpty(errors) || formValues.spaceId === undefined}
              loading={savingInProgress}
            />
            <Link to='./..'>
              <Button
                tertiary
                color={theme.palette.red}
                label={t('Cancel', { ns: 'common' })}
              />
            </Link>
          </FlexRow>

          <InteractiveDialog
            modalRef={cancelDeviceMoveRef}
            isOpen={moveDeviceIsOpen}
            hide={toggleDeviceMoveDialog}
            title={t('DeviceMove.MoveDevice', { ns: 'settingsDevice' })}
            content={MoveDeviceDialogContent}
            confirmButton={{
              label: t('DeviceMove.Confirm', { ns: 'settingsDevice' }),
              onClick: handleSubmit(onSave),
              loading: savingInProgress
            }}
            cancelButton={{
              label: t('Cancel', { ns: 'common' }),
              onClick: toggleDeviceMoveDialog
            }}
          />
        </Card>
      </Container>
    </PaddedContainer>
  );
};

export default DeviceMove;

const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  width: 100%;
`;

const MoveDeviceDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  box-shadow: 0 6px 4px -2px ${p => p.theme.palette.shadows.medium}, 0 2px 18px 0px ${p => p.theme.palette.shadows.medium};
  border-radius: 25px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.primary};
  font-size: 16px;
`;

const RowLabel = styled.div`
  color: ${p => p.theme.palette.text.weak};
  font-size: 16px;
  font-weight: 400;
`;

const NewRowLabel = styled.div`
  color: ${p => p.theme.palette.text.medium};
  font-size: 16px;
  font-weight: 500;
`;

const Measurements = styled.div`
  text-align: center;
  color: ${p => p.theme.palette.text.fair};
  font-size: 16px;
  font-weight: 400;
  max-width: 300px;
`;

const MeasurementsChoiceFlexRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

const RetainDeleteLabel = styled.span<{ delete: boolean }>`
  font-size: 16px;
  font-weight: 500;
  color: ${p => p.delete ? p.theme.palette.red : p.theme.palette.text.fair};
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const DialogLabel = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-size: 18px;
  font-weight: 500;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  padding-bottom: 20px;
`;