import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class SpaceClimateControlGetBySpaceQuery extends GetQuery<SpaceClimateControlDto> {
  spaceId: number;

  constructor(spaceId: number) {
    super();
    this.spaceId = spaceId;
  }

  public targetUrl(): string {
    return `/ClimateControl/space/GetBySpace/${this.spaceId}`;
  }
}
