import { ResidentCsvObject } from './ResidentApp_ManageUsers';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Table } from '@shared/components/molecules/Table/Table';
import { FailedUserDto } from '@shared/api/models/ResidentApp/FailedUserDto';
import { Title } from '@shared/components/atoms/Title/Title';
import styled, { useTheme } from 'styled-components';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import { useApi } from '@shared/hooks/useApi';
import UsersResidentAppBulkCreateCommand from '@shared/api/queries/ResidentApp/Users/UsersResidentAppBulkCreateCommand';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { UserDto } from '@shared/api/models/ResidentApp/UserDto';

type CsvPreviewProps = {
  isModalOpen: boolean;
  modalRef: React.RefObject<HTMLDivElement>;
  toggleModal: () => void;
  csvData: ResidentCsvObject[];
  csvFile?: File;
  onRefresh: () => void;
  toggleCsvUploadModal: () => void;
};

const CsvPreview = ({isModalOpen, modalRef, toggleModal, csvData, csvFile, onRefresh, toggleCsvUploadModal}: CsvPreviewProps) => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const { site } = useSiteContext();
  const { localisation } = useLocalisation();
  const { execute: executeBulkUploadUsers, loading: isBulkUploadUsersLoading, error } = useApi();
  const [failedUsers, setFailedUsers] = useState<FailedUserDto[]>();
  const [createdUsers, setCreatedUsers] = useState<UserDto[]>();

  useEffect(() => {
    if(isModalOpen) {
      setFailedUsers(undefined);
      setCreatedUsers(undefined);
    }
  }, [isModalOpen]);


  const handleUploadCsv = async () => {
    if(!csvFile) return;
    const response = await executeBulkUploadUsers({
      query: new UsersResidentAppBulkCreateCommand(csvFile, site.id),
      errorMessage: t('UsersBulkUploadFailed', {ns: 'status'}),
      pendingMessage: t('UsersBulkUploadPending', {ns: 'status'})
    });

    if(response?.failedUsers && response.failedUsers.length > 0) {
      setFailedUsers(response.failedUsers);
    }

    if(response?.createdUsers && response.createdUsers.length > 0) {
      setCreatedUsers(response.createdUsers);
    }

    if(!error && (!response?.failedUsers || response.failedUsers.length === 0)) {
      toast.success(t('UsersBulkUploadSuccess', {ns: 'status'}));
      toggleModal();
      onRefresh();
    }
  };

  const handleToggleCsvModal = () => {
    toggleModal();
    toggleCsvUploadModal();
  };

  const handleCloseModal = () => {
    setFailedUsers(undefined);
    toggleModal();
    onRefresh();
  };

  const generateFailedReasonList = (record: FailedUserDto) => {
    const reasons = [];
    if (record.emailExists) {
      reasons.push(t('ResidentApp.EmailExists'));
    }
    if (record.tenancyDatesInvalid) {
      reasons.push(t('ResidentApp.TenancyDatesInvalid'));
    }
    if (record.buildingExists === false) {
      reasons.push(t('ResidentApp.BuildingDoesntExist'));
    }
    if (record.spaceExists === false) {
      reasons.push(t('ResidentApp.SpaceDoesntExist'));
    }
    if (record.spaceAlreadyOccupied) {
      reasons.push(t('ResidentApp.SpaceOccupied'));
    }
    if (record.missingUserProperties) {
      reasons.push(t('ResidentApp.UserMissingProperties'));
    }

    if (reasons.length === 0) {
      return '-';
    }

    return <List>{reasons.map((reason, index) => <li key={index}>{reason}</li>)}</List>;
  };

  const defaultColumns = [
    {
      label: t('ResidentApp.BuildingName'),
      key: 'buildingName',
    },
    {
      label: t('ResidentApp.SpaceName'),
      key: 'spaceName',
    },
    {
      label: t('ResidentApp.Email'),
      key: 'email',
      width: 3
    },
    {
      label: t('ResidentApp.FirstName'),
      key: 'firstName',
    },
    {
      label: t('ResidentApp.LastName'),
      key: 'lastName',
    },
    {
      label: t('ResidentApp.StartTenancy'),
      key: 'startTenancy',
    },
    {
      label: t('ResidentApp.EndTenancy'),
      key: 'endTenancy',
    }
  ];

  const previewColumns = [
    {
      label: '',
      key: 'key',
      fixedWidth: '80px',
    },
    ...defaultColumns,
  ];

  const failedUserColumns: ITableColumn<FailedUserDto>[] = (
    [
      ...defaultColumns,
      {
        label: t('ResidentApp.FailedReason'),
        key: 'failedReason',
        width: 2,
        customElement: (record) => generateFailedReasonList(record)
      }
    ]
  );

  const getFailedUsersRecords = (failedUsers: FailedUserDto[]) => (
    failedUsers.map((user) => ({
      ...user,
      startTenancy: format(new Date(user.startTenancy), localisation.dateFormats.slashFormatDisplay),
      endTenancy: format(new Date(user.endTenancy), localisation.dateFormats.slashFormatDisplay),
    }))
  );

  const getCreatedUsersRecords = (createdUsers: UserDto[]) => (
    createdUsers.map((user) => ({
      ...user,
      startTenancy: format(new Date(user.startTenancy), localisation.dateFormats.slashFormatDisplay),
      endTenancy: format(new Date(user.endTenancy), localisation.dateFormats.slashFormatDisplay),
    }))
  );

  return (
    <InteractiveDialog
      width="fitContent"
      modalRef={modalRef}
      isOpen={isModalOpen}
      hide={handleCloseModal}
      title={t('ResidentApp.PreviewCsv')}
      confirmButton={{
        label: failedUsers ? t('ResidentApp.UploadCsv') : t('Upload', { ns: 'common' }),
        onClick: failedUsers ? handleToggleCsvModal : handleUploadCsv,
        disabled: isBulkUploadUsersLoading,
      }}
      cancelButton={{
        label: t('Cancel', { ns: 'common' }),
        onClick: handleCloseModal,
        disabled: isBulkUploadUsersLoading,
      }}
      content={
        <>
          {failedUsers && failedUsers.length > 0 ? (
            <>
              <Container>
                <div>
                  <Title
                    text={t('ResidentApp.FailedToUploadUsers')}
                    size="sm"
                    style={{ color: theme.palette.systemMessage.error }}
                  />
                  <small>{t('ResidentApp.FailedToUploadUsersDescription')}</small>
                </div>

                <Table
                  columns={failedUserColumns}
                  recordKey="email"
                  records={getFailedUsersRecords(failedUsers)}
                  minHeight="0"
                  cardEffect
                />
              </Container>
              {createdUsers && createdUsers.length > 0 && (
                <Container>
                  <Title
                    text={t('ResidentApp.SuccessfullyCreatedUsers')}
                    size="sm"
                    style={{ color: theme.palette.systemMessage.success }}
                  />
                  <Table
                    columns={defaultColumns}
                    recordKey="email"
                    records={getCreatedUsersRecords(createdUsers)}
                    minHeight="0"
                    cardEffect
                  />
                </Container>
              )}
            </>
          ) : (
            <Table columns={previewColumns} recordKey="key" records={csvData} cardEffect />
          )}
        </>
      }
    /> 
  );
};

export default CsvPreview;

const Container = styled.div`
  display: grid;
  gap: 20px;
`;

const List = styled.ul`
  padding-left: 0;
`;