import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import DistributionMeter from './DistributionMeter';
import MeterDetailsModal from './MeterDetailsModal';
import MeterMultiSelectModal from './MeterMultiSelectModal';
import { TopologyCard, TopologyCardContent, TopologyCardHeader, TopologyCardHeaderIcon, TopologyCardMenu, TopologyCardMenuItem, TopologyCardRelativeWrapper } from './shared';
import SubMeterGroup from './SubMeterGroup';
import { EnergyMeterTopologyLevel } from '@shared/api/enums/EnergyMeterTopologyLevel/EnergyMeterTopologyLevel';
import { EnergyMeterTopologyDto } from '@shared/api/models/EnergyMeter/EnergyMeterTopologyDto';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useModal } from '@shared/hooks/useModal';

interface IMainMeter {
  meter: EnergyMeterTopologyDto;
  meters: EnergyMeterTopologyDto[];
  addDistributionMeter: (meterId: number, parentMeter: EnergyMeterTopologyDto) => void;
  updateMeters: (meters: EnergyMeterTopologyDto[], parentMeter: EnergyMeterTopologyDto, level: EnergyMeterTopologyLevel) => void;
  isBillingmeter: boolean;
}

const MainMeter = ({ meter, meters, addDistributionMeter, updateMeters, isBillingmeter }: IMainMeter) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const { getDisplayString } = useDeviceConfigContext();
  const { isOpen, toggle, ref } = useModal({});
  const { isOpen: detailModalIsOpen, toggle: toggleDetailModal, ref: detailModalRef } = useModal({});
  const [subMeters, setSubMeters] = useState<EnergyMeterTopologyDto[] | undefined>();
  const [distributionMeters, setDistributionMeters] = useState<EnergyMeterTopologyDto[]>([]);
  const arrowRefId = `main-${meter.id}`;

  useEffect(() => {
    const childMeters = meters.filter(x => x.parentMeterId === meter.id);
    const subMeters = childMeters.filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Sub);
    const distributionMeters = childMeters.filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Distribution);

    setDistributionMeters(distributionMeters);
    setSubMeters(subMeters.length > 0 ? subMeters : undefined);
  }, [meters, meter]);

  const addSubMeterGroup = useCallback(() => {
    setSubMeters([]);
  }, []);

  const getDistributionMeterOptions = useCallback(() => {
    return meters.filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Distribution);
  }, [meters]);

  return (
    <>
      <FlexColumn>
        <Wrapper>
          <TopologyCardRelativeWrapper>
            <TopologyCard id={arrowRefId}>
              <TopologyCardHeader color={isBillingmeter ? theme.palette.green : theme.palette.red} onClick={toggleDetailModal}>
                <span>{isBillingmeter ? t('Buildings.EnergyMeters.MainMeter.BillingMeter', { ns: 'settingsAsset' }) : t('Buildings.EnergyMeters.MainMeter.MainMeter', { ns: 'settingsAsset' })}</span>
                <TopologyCardHeaderIcon icon={solid('circle-info')} />
              </TopologyCardHeader>

              <TopologyCardContent>
                <Label style={{ fontWeight: 500 }}>
                  {meter.meterNumber}
                </Label>
                <Label style={{ color: theme.palette.text.fair }}>
                  {meter.deviceFriendlyName}
                </Label>
                <Label style={{ color: theme.palette.text.fair }}>
                  {getDisplayString(meter.deviceModel)}
                </Label>
                <Label style={{ color: theme.palette.text.weak }}>
                  {meter.spaceName}
                </Label>
              </TopologyCardContent>
            </TopologyCard>

            <TopologyCardMenu>
              <TopologyCardMenuItem onClick={toggle}>
                <FontAwesomeIcon icon={solid('pen-to-square')} color={theme.palette.primary} />
                {t('Buildings.EnergyMeters.MainMeter.DistributionMeters', { ns: 'settingsAsset' })}
              </TopologyCardMenuItem>
              {subMeters === undefined &&
                <TopologyCardMenuItem onClick={addSubMeterGroup}>
                  <FontAwesomeIcon icon={solid('plus')} color={theme.palette.primary} />
                  {t('Buildings.EnergyMeters.MainMeter.AddSubMeters', { ns: 'settingsAsset' })}
                </TopologyCardMenuItem>
              }
            </TopologyCardMenu>
          </TopologyCardRelativeWrapper>
        </Wrapper>

        <MeterMultiSelectModal
          ref={ref}
          isOpen={isOpen}
          toggle={toggle}
          selected={distributionMeters}
          options={getDistributionMeterOptions()}
          parentMeter={meter}
          onSelectChange={(meters: EnergyMeterTopologyDto[]) => updateMeters(meters, meter, EnergyMeterTopologyLevel.Distribution)}
        />

        <FlexRow style={{ justifyContent: 'center' }}>
          {subMeters &&
            <SubMeterGroup
              meters={meters}
              subMeters={subMeters}
              parentMeter={meter}
              onChange={(meters: EnergyMeterTopologyDto[]) => updateMeters(meters, meter, EnergyMeterTopologyLevel.Sub)}
              arrowRefId={`${arrowRefId}-sub`}
              parentArrowRefId={arrowRefId}
            />
          }

          {distributionMeters.map((meter, i) => (
            <DistributionMeter
              key={i}
              meter={meter}
              meters={meters}
              addDistributionMeter={addDistributionMeter}
              parentArrowRefId={arrowRefId}
              updateMeters={updateMeters}
            />
          ))}
        </FlexRow>
      </FlexColumn>

      <MeterDetailsModal
        meter={meter}
        isOpen={detailModalIsOpen}
        modalRef={detailModalRef}
        toggle={toggleDetailModal}
        color={isBillingmeter ? theme.palette.green : theme.palette.red}
        titlePrefix={`${t('Buildings.EnergyMeters.MainMeter.MainMeter', { ns: 'settingsAsset' })}:`}
      />
    </>
  );
};

export default MainMeter;

const FlexRow = styled.div`
  display: flex;
  gap: 50px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 13px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.medium};
`;