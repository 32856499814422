import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { Card } from '@shared/components/molecules/Card/Card';
import DeviceCard from '@dashboard/components/molecules/DeviceCard/DeviceCard';
import { useSpaceContext } from '@src/components/pages/SpacePage/SpaceProvider';
import NoData from '@shared/components/atoms/NoData/NoData';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const masonryBreakpointColumns = {
  default: 3,
  1600: 2,
  900: 1
};

const SpacePage_Devices = () => {
  const { t } = useTranslation();
  const { devices } = useSpaceContext();

  if (devices?.length === 0) {
    return (
      <NoData
        label={t('NoDevicesFound', { ns: 'status' })}
        icon={regular('sensor-on')}
        styles={{ margin: '40px auto' }}
      />
    );
  }

  return (
    <Masonry
      breakpointCols={masonryBreakpointColumns}
      className="space-page-devices-masonry-grid"
      columnClassName="space-page-devices-masonry-grid_column"
    >
      {devices?.map((device) => (
        <Card noMargin noPadding autoWidth key={device.id}>
          <DeviceCard
            device={device}
            width="350px"
          />
        </Card>
      ))}
    </Masonry>
  );
};

export default SpacePage_Devices;