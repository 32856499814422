import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThresholdSeason } from '@shared/api/models/ResidentApp/enums/ThresholdSeason';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { round } from '@shared/utils/NumberUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

type SpaceTypeTargetTableItem = {
  id: number;
  spaceType: string;
  target?: number | string;
}

type SpaceTypeTargetTableProps = {
  percentage?: number;
  season?: ThresholdSeason;
}

const TargetKwhPill = ({isGrey}: {isGrey: boolean}) => {
  const theme = useTheme();

  return (
    <TargetPill isGrey={isGrey}>
      <FontAwesomeIcon icon={solid('trophy')} color={isGrey ? theme.text.disabled : theme.text.contrast} />
      <span>Target kWh</span>
    </TargetPill>
  )
}

const SpaceTypeTargetTable = ({percentage, season}: SpaceTypeTargetTableProps) => {
  const { t } = useTranslation('molecules');
  const { siteOperator } = useSiteContext();
  const isValidPercentage = percentage && percentage > 0 && percentage <= 100 && season;

  const getSpaceTypeTargets = (): SpaceTypeTargetTableItem[] => {
    if (!siteOperator?.spaceTypeThresholds) {
      return [];
    }

    return siteOperator.spaceTypeThresholds
      .filter((threshold) => threshold.thresholdSeason === season) // Only include matching season
      .map((threshold) => {
        const spaceType = threshold.spaceType
          ? t(threshold.spaceType, {ns: 'enums'})
          : t('ResidentApp.ClusterBedroomCount', {count: threshold.bedroomCount});

        return { id: threshold.id, spaceType, target: !isValidPercentage ? '' : round((threshold.seasonTarget / 100) * percentage, 1) };
      });
  }

  const tableColumns: ITableColumn<SpaceTypeTargetTableItem>[] = [
    {
      label: t('SpaceType', {ns: 'common'}),
      key: 'spaceType',
    },
    {
      label: <TargetKwhPill isGrey={!isValidPercentage} />,
      key: 'target',
      centerAlign: true,
    }
  ];

  return (
    <Container>
      {(!isValidPercentage) && <GreyOverlay aria-hidden />}
      <Table
        columns={tableColumns}
        records={getSpaceTypeTargets()}
        recordKey={''}
        disablePaging
        cardEffect
        disableSorting
        minHeight="0px"
      />
    </Container>
  );
};

export default SpaceTypeTargetTable;

const TargetPill = styled.div<{isGrey: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 16px;
  background-color: ${({theme, isGrey}) => isGrey ? theme.grey.light : theme.complimentary.purple.dark};
  border-radius: 40px;
  color: ${({theme, isGrey}) => isGrey ? 'inherit' : theme.text.contrast};
`;

const Container = styled.div`
  position: relative;
`;

const GreyOverlay = styled.div`
  position: absolute;
  inset: 60px 0 0 0;
  background-color: ${({theme}) => theme.action.disabledBackground};
  opacity: 0.5;
  z-index: 1;
`;