import styled from 'styled-components';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { IVoltageColorThreshold } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IDetailsCardConfig';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { stringToFloat } from '@shared/utils/NumberUtils';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { getPowerIcon } from '@dashboard/configs/device/Device_Voltage_Icons';
import { Icon } from '@shared/components/atoms/Icon/Icon';
import { getSignalIcon, getSignalIconColor } from '@dashboard/configs/device/Device_Signal_Icons_Colors';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';

export const getPowerIconColor = (voltage: number | null, thresholds?: IVoltageColorThreshold[]): string => {
  const defaultColor = '#A3A9C2';

  if (voltage === null || !thresholds || thresholds.length === 0) {
    return defaultColor;
  }

  const lastThreshold = thresholds[thresholds.length - 1];

  if (voltage >= lastThreshold.threshold) {
    return lastThreshold.color;
  }

  return thresholds.find(x => voltage < x.threshold)?.color ?? defaultColor;
};

type PropTypes = {
  device: DeviceWithMetrics;
  inSidebar?: boolean;
}

const DeviceCardFooter = ({ device, inSidebar }: PropTypes) => {
  const { getDeviceConfig } = useDeviceConfigContext();
  const deviceConfig = getDeviceConfig(device.deviceModel);
  const { t } = useTranslation(['molecules']);

  const batteryVoltageString = device.metrics.find(x => x.metricType === MetricType.BatteryVoltage)?.value;
  const batteryVoltage = batteryVoltageString ? stringToFloat(batteryVoltageString, 1) : null;
  const rssiString = device.metrics.find(x => x.metricType === MetricType.RSSI)?.value;
  const snrString = device.metrics.find(x => x.metricType === MetricType.SNR)?.value;
  const signalString = device.metrics.find(x => x.metricType === MetricType.SignalStrength)?.value;
  const signal = signalString ? parseFloat(signalString) : null;
  const powerIconColor = getPowerIconColor(batteryVoltage, deviceConfig?.ui.detailsCard.powerIcon?.voltageColorTresholds);

  return (
    <>
      {(batteryVoltage || signal || rssiString || snrString) &&
        <Footer inSidebar={inSidebar}>
          {batteryVoltage &&
            <FlexRow>
              <Icon
                icon={getPowerIcon(batteryVoltage)}
                squareColor={transparentize(0.9, powerIconColor)}
                iconFillColor={powerIconColor}
                iconSize="22px"
                squareSize="36px"
                hideBorder
              />
              <LabelValuePair
                label={t('DeviceCard.Power', { ns: 'molecules' })}
                value={batteryVoltage}
                suffix='V'
                size="sm"
                smallGap
                style={{ paddingLeft: '9px' }}
              />
            </FlexRow>
          }

          <FlexRow>
            {signal &&
              <Icon
                icon={getSignalIcon(signal)}
                squareColor={transparentize(0.9, getSignalIconColor(signal))}
                iconFillColor={getSignalIconColor(signal)}
                iconSize="22px"
                squareSize="36px"
                hideBorder
              />
            }
            <FlexColumn style={{ paddingLeft: '9px' }}>
              {rssiString &&
                <LabelValuePair
                  label={`${t('DeviceCard.RSSI', { ns: 'molecules' })}:`}
                  value={`${rssiString}`}
                  suffix="dBm"
                  size="sm"
                  inline
                />
              }
              {snrString &&
                <LabelValuePair
                  label={`${t('DeviceCard.SNR', { ns: 'molecules' })}:`}
                  value={`${snrString}`}
                  suffix="db"
                  size="sm"
                  inline
                  style={{ marginTop: '-4px' }}
                />
              }
            </FlexColumn>
          </FlexRow>
        </Footer>
      }
    </>
  );
};

export default DeviceCardFooter;

const Footer = styled.div<{ inSidebar?: boolean }>`
  padding: ${p => p.inSidebar ? '12px 0' : '16px 24px'};
  border-top: 1px solid ${p => p.theme.action.divider};
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;