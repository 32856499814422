import styled from 'styled-components';
import TemplateListActions from './TemplateListActions';
import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import EsgExplorerRuleDto from '@dashboard/api/models/EsgExplorerRuleDto';

interface ITemplateListItem {
  template: EsgExplorerTemplateDto;
  siteId: number;
  refreshTemplates: () => void;
  refreshRules: () => void;
  setSelectedTemplate: (template?: EsgExplorerTemplateDto) => void;
  rules: EsgExplorerRuleDto[];
}

const TemplateListItem = ({ template, siteId, refreshTemplates, refreshRules, setSelectedTemplate, rules }: ITemplateListItem) => {

  return (
    <ListItem onClick={() => setSelectedTemplate(template)}>
      <Wrapper>
        <Label>
          {template.name}
        </Label>

        {template.createdByName &&
          <SecondRow>
            {template.createdByName}
          </SecondRow>
        }
      </Wrapper>

      <div style={{ marginLeft: 'auto' }} />

      <TemplateListActions
        siteId={siteId}
        template={template}
        refreshTemplates={refreshTemplates}
        refreshRules={refreshRules}
        rules={rules}
      />
    </ListItem>
  );
};

export default TemplateListItem;

const ListItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  padding: 8px 15px;
  cursor: pointer;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};

  display: flex;

  &:nth-child(even) {
    background-color: ${p => p.theme.palette.tables.evenRow};
  }
  
  &:nth-child(odd) {
    background-color: ${p => p.theme.palette.tables.unevenRow};
  }

  &:hover {
    background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  }
`;

const Wrapper = styled.div`
  width: 280px;
`;

const Label = styled.div`
  padding-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SecondRow = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;