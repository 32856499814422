import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    returnNull: false,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true
    }
  });

export const i18nLoadNamespaces = () => {
  i18n.loadNamespaces([
    'assets',
    'common',
    'commonApp',
    'currencies',
    'deviceConfigs',
    'enums',
    'exceptionMessages',
    'hooks',
    'main',
    'molecules',
    'navigation',
    'organisms',
    'providers',
    'routes',
    'settingsAlerting',
    'settingsAsset',
    'settingsDevice',
    'settingsFeatures',
    'settingsPeopleCounting',
    'settingsServiceIntegrations',
    'settingsTenant',
    'settingsTenantUser',
    'settingsUser',
    'status',
    'validation',
    'residentAppTermsConditions'
  ]);
};

export default i18n;