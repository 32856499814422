import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { transparentize } from 'polished';
import EsgExplorerRulePeriod, { EsgExplorerRulePeriodOptions } from '@dashboard/api/enums/EsgExplorerRulePeriod';
import { useApi } from '@shared/hooks/useApi';
import EsgExplorerRuleCreateCommand from '@dashboard/api/queries/esgExplorer/EsgExplorerRuleCreateCommand';
import { ErrorMessage, Input, Label } from '@shared/components/atoms/Form/Form';
import { Select } from '@shared/components/atoms/Select/Select';
import { SingleValue } from 'react-select';
import { EsgTemplateSelectOption } from '../AutomationTab';
import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { getEsgExplorerScheduleMessage } from './RuleModal';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { useCallback, useEffect, useState } from 'react';
import { validateEmail } from '@shared/utils/StringUtils';

type FormValues = {
  name: string,
  period: EsgExplorerRulePeriod,
  templateId: number,
  enabled: boolean
}

interface ICreateRule {
  siteId: number;
  templates: EsgExplorerTemplateDto[];
  refreshRules: () => void;
  hideModal: () => void
}

const CreateRule = ({ siteId, templates, refreshRules, hideModal }: ICreateRule) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [emailsAreInvalid, setEmailsAreInvalid] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const { register, handleSubmit, reset, control, formState: { errors }, watch } = useForm<FormValues>({
    defaultValues: {
      name: '',
      period: undefined,
      templateId: undefined,
      enabled: true
    }
  });
  const formFields = watch();
  const templateOptions = templates.map(x => ({ label: x.name, value: x }));

  useEffect(() => {
    setEmailsAreInvalid(emails.some(x => !validateEmail(x)));
  }, [emails])

  const updateEmail = useCallback((updatedEmail: string, index: number) => {
    const clone = [...emails];
    clone[index] = updatedEmail;
    setEmails(clone);
  }, [emails, setEmails]);

  const removeEmail = useCallback((index: number) => {
    const clone = [...emails];
    clone.splice(index, 1);
    setEmails(clone);
  }, [emails, setEmails]);

  const addEmail = useCallback(() => {
    const clone = [...emails];
    clone.push('');
    setEmails(clone);
  }, [emails, setEmails]);

  const onSave: SubmitHandler<FormValues> = async data => {
    await execute({
      query: new EsgExplorerRuleCreateCommand(siteId, data.templateId, data.name, data.period, emails, data.enabled),
      successMessage: t('RuleCreateSuccess', { ns: 'status' }),
      errorMessage: t('RuleCreateError', { ns: 'status' })
    });

    reset();
    refreshRules();
    hideModal();
  }

  return (
    <>
      <Container>
        <Column>
          <Title>
            {t('ESG.Rule', { ns: 'molecules' })}
          </Title>
          <Divider />
          <RuleRow>
            <div>
              <Label>Name</Label>
              <Input {...register('name', { required: t('Required', { ns: 'validation' }) })} />
              <ErrorMessage>{errors.name?.message}</ErrorMessage>
            </div>
            <div
              data-cy={'ESGExplorer_ScheduleSelectField'}
            >
              <Label>Schedule</Label>
              <Controller
                control={control}
                name="period"
                rules={{ required: t('Required', { ns: 'validation' }) }}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    value={EsgExplorerRulePeriodOptions.find(x => x.value === value)}
                    isSearchable={false}
                    onChange={(e: any) => onChange(e.value)} // eslint-disable-line @typescript-eslint/no-explicit-any
                    options={EsgExplorerRulePeriodOptions}
                    innerRef={ref}
                    menuPlacement="bottom"
                  />
                )}
              />
              <ErrorMessage>{errors.period?.message}</ErrorMessage>
            </div>
            <div
              data-cy={'ESGExplorer_TemplateSelectField'}
            >
              <Label>Template</Label>
              <Controller
                control={control}
                name="templateId"
                rules={{ required: t('Required', { ns: 'validation' }) }}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    value={templateOptions.find((x: EsgTemplateSelectOption) => x.value.id === value)}
                    isSearchable={false}
                    onChange={(option: SingleValue<EsgTemplateSelectOption>) => option && onChange(option.value.id)}
                    options={templateOptions}
                    innerRef={ref}
                    menuPlacement="bottom"
                    menuHeight={150}
                  />
                )}
              />
              <ErrorMessage>{errors.templateId?.message}</ErrorMessage>
            </div>
          </RuleRow>
          <FlexRow>
            {formFields.period &&
              <ScheduleMessage>
                <ScheduleMessageIcon icon={regular('calendar-range')} />
                {getEsgExplorerScheduleMessage(formFields.period, t)}
              </ScheduleMessage>
            }
          </FlexRow>
        </Column>

        <Column>
          <Title>
            {t('ESG.Emails', { ns: 'molecules' })}
          </Title>
          <Divider />
          {emails.map((email, i) => (
            <FlexRow key={i}>
              <Tooltip
                content={<TooltipContent>{t('RemoveEmail', { ns: 'common' })}</TooltipContent>}
                placement={TooltipPlacement.Right}
                hideTriangle
              >
                <CircleIcon
                  data-cy={'ESGExplorer_DeleteEmailIcon'}
                  icon={solid('xmark')}
                  color={theme.palette.red}
                  onClick={() => removeEmail(i)}
                />
              </Tooltip>

              <div style={{ flexGrow: 1, position: 'relative' }}>
                <Input
                  type="email"
                  value={email}
                  onInput={(e) => updateEmail(e.currentTarget.value.trim(), i)}

                />

                {!validateEmail(email) &&
                  <HelperMessage style={{ margin: '1px 4px' }}>
                    {t('Email', { ns: 'validation' })}
                  </HelperMessage>
                }
              </div>
            </FlexRow>
          ))}

          <FlexRow style={{ marginTop: '7px' }}>
            <Tooltip
              content={<TooltipContent>{t('AddEmail', { ns: 'common' })}</TooltipContent>}
              placement={TooltipPlacement.Right}
              hideTriangle
            >
              <CircleIcon
                data-cy={'ESGExplorer_AddEmailIcon'}
                icon={solid('plus')}
                color={theme.palette.primary}
                onClick={addEmail}
              />
            </Tooltip>
          </FlexRow>
        </Column>

        <FlexRow style={{ padding: '0' }}>
          <Button
            tertiary
            label={t('Cancel', { ns: 'common' })}
            style={{ marginLeft: 'auto', }}
            color={theme.palette.red}
            onClick={() => {
              reset();
              hideModal();
            }}
          />
          <Button
            label={t('Save', { ns: 'common' })}
            onClick={handleSubmit(onSave)}
            disabled={emailsAreInvalid}
          />
        </FlexRow>
      </Container >
    </>
  );
};

export default CreateRule;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px; 
  border-radius: 5px;
  border: 1px solid ${p => transparentize(0.2, p.theme.palette.borders.medium)};
  box-shadow: 0 1px 3px 0px ${p => p.theme.palette.shadows.medium};
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
`;

const RuleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 15px;

  > * {
    width: 100%
  };

  @media (min-width: 900px) {
    flex-direction: row;
  };
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
`;

const ScheduleMessage = styled.div`
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  padding-left: 3px;
`;

const TooltipContent = styled.div`
  font-size: 14px;
  padding: 5px 3px;
`;

const HelperMessage = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
  position: absolute;
  top: 39px;
`;

const ScheduleMessageIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 8px;
  color: ${p => transparentize(0.2, p.theme.palette.primary)};
`;

const CircleIcon = styled(FontAwesomeIcon) <{ color: string }>`
  width: 12px;
  height: 12px;

  padding: 4px;
  border-radius: 50%;
  border: 1px solid ${p => p.color};
  color: ${p => p.color};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.text.onPrimary};
    background-color: ${p => p.color};
  };
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
`;