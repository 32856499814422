import { DefaultTheme, useTheme } from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Chart, ChartData, ChartDataset, ChartTypeRegistry } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { Metric } from '@shared/api/models/Metric/Metric';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { TFunction } from 'i18next';
import { ChartDataUnit, getChartDataUnit } from '@dashboard/utils/ChartUtils';
import { LocalisationFunction, useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { constructChartOptions } from './chartOptions';
import { transparentize } from 'polished';

export type TDataset = ChartDataset<'line', { x: number; y: string; }[]> & { dataUnit: ChartDataUnit, metricType: MetricType };

const sortingArray = [MetricType.TemperatureTarget, MetricType.Temperature, MetricType.PIR];

const getMetricOptions = (metric: MetricType, theme: DefaultTheme, t: TFunction) => {
  switch (metric) {
    case MetricType.TemperatureTarget:
      return {
        label: t(`ClimateControl.SpaceDetailsModal.${metric}`, { ns: 'molecules' }),
        borderDash: [5, 5],
        borderWidth: 2,
        borderColor: theme.info.main,
      };

    case MetricType.Temperature:
      return {
        label: t(`ClimateControl.SpaceDetailsModal.${metric}`, { ns: 'molecules' }),
        fill: true,
        borderColor: theme.primary.main,
        backgroundColor: transparentize(0.88, theme.primary.main),
      };

    case MetricType.PIR:
      return {
        label: t(`ClimateControl.SpaceDetailsModal.${metric}`, { ns: 'molecules' }),
        fill: true,
        borderColor: theme.text.primary,
        backgroundColor: transparentize(0.88, theme.text.primary),
        yAxisID: 'y1'
      };

    default:
      return {};
  }
}

const formatValue = (x: string, unitType: ChartDataUnit, t: TFunction): string => {
  const modifiedValue = unitType.modifier ? unitType.modifier(x, t) : x
  return modifiedValue.toString();
};

const formatMetric = (item: { measuredOn: string | number | Date; metricType: MetricType; value: string; }, unitType: ChartDataUnit, toLocale: LocalisationFunction, t: TFunction) => ({
  x: (new Date(item.measuredOn)).valueOf(),
  y: formatValue(
    toLocale(item.metricType, parseFloat(item.value)).toString(),
    unitType,
    t
  )
});

type PropTypes = {
  metrics: Metric[];
};

const useMonthlyChart = ({ metrics }: PropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { toLocale, getUnit, getUnitLabel, localisation } = useLocalisation();
  const [datasets, setDatasets] = useState<TDataset[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, { x: number; y: string; }[], string> => {
    const metricsGroupedByType = groupBy(metrics, x => x.metricType);
    const sortedByMetricType = Object.entries(metricsGroupedByType)
      .sort((a, b) => sortingArray.indexOf(a[0] as MetricType) - sortingArray.indexOf(b[0] as MetricType));

    const datasets: TDataset[] = sortedByMetricType.map(x => {
      const metricType = x[0] as MetricType;

      const unitType = getChartDataUnit(metricType);
      unitType.suffix = getUnit(metricType);
      unitType.label = getUnitLabel(metricType);

      const data = x[1].map(metric => formatMetric(metric, unitType, toLocale, t));

      return {
        data: data,
        dataUnit: unitType,
        metricType: metricType,
        ...getMetricOptions(metricType, theme, t)
      };
    });

    setDatasets(datasets);

    return {
      datasets: datasets,
    }
  }, [metrics, theme, getUnit, getUnitLabel, toLocale, t]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');

    if (context) {
      const data = constructData();

      if (data.datasets.length === 0) {
        return;
      }

      const chart = new Chart(context, {
        type: 'line',
        data: data,
        options: constructChartOptions(theme, localisation)
      });

      return () => chart.destroy();
    }
  }, [constructData, theme, localisation]);

  return {
    canvasRef,
    datasets
  };
};

export default useMonthlyChart;