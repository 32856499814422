import { ClimateControlBuildingSummariesGetQuery } from '@shared/api/queries/ClimateControl/BuildingSummary/ClimateControlBuildingSummariesGetQuery';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { Title } from '@shared/components/atoms/Title/Title';
import { useApiState } from '@shared/hooks/useApiState';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BuildingListItem from './BuildingListItem';
import { Card } from '@shared/components/molecules/Card/Card';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

const BuildingList = () => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const { data: buildings, loading } = useApiState({
    query: new ClimateControlBuildingSummariesGetQuery(site.id),
    initialState: []
  }, [site]);

  return (
    <Container>
      <Card>
        <Title text={t('Buildings', { ns: 'assets' })} />

        {loading &&
          <Loading size='25px' style={{ marginTop: '40px' }} />
        }

        {!loading &&
          <List>
            {buildings.map((building) => (
              <BuildingListItem
                key={building.buildingName}
                building={building}
              />
            ))}
          </List>
        }
      </Card>
    </Container>
  );
};

export default BuildingList;

const Container = styled.div`
  width: 100%;

  @media (min-width: 1050px) {
    width: calc(50% - 15px);
  }
`;

const List = styled.div`
  margin-top: 10px;
`;