import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SearchBarInput from './SearchBarInput';
import { usePopover } from '@shared/hooks/usePopover';
import SearchBarDropdown from './SearchBarDropdown';
import { useCallback, useEffect, useState } from 'react';
import { useApi } from '@shared/hooks/useApi';
import SpacesGetBySiteAndNameQuery from '@shared/api/queries/Spaces/SpacesGetBySiteAndNameQuery';
import { useSiteContext } from '../SiteProvider';
import { SpaceSearchDto } from '@shared/api/models/Space/SpaceSearchDto';
import DevicesGetBySiteAndIdentifierQuery from '@shared/api/queries/Devices/DevicesGetBySiteAndIdentifierQuery';
import { DeviceSearchDto } from '@shared/api/models/Device/DeviceSearchDto';
import { alphabeticalSort } from '@shared/utils/StringUtils';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

export const SearchBarMinCharacters = 2;
export const isValidSearch = (searchString: string) => searchString.length >= SearchBarMinCharacters;

const SearchBar = () => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const { trackAction } = useAnalytics();
  const { ref, visible, setVisible } = usePopover({ disableCloseOnClickOutside: false });
  const [searchString, setSearchString] = useState<string>('');
  const [spaces, setSpaces] = useState<SpaceSearchDto[]>([]);
  const [loadingSpaces, setLoadingSpaces] = useState(false);
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [devices, setDevices] = useState<DeviceSearchDto[]>([]);
  const { execute: refreshSpaces } = useApi();
  const { execute: refreshDevices } = useApi();

  const fetchSpaces = useCallback(async (siteId: number, searchString: string) => {
    const spaces = await refreshSpaces({ query: new SpacesGetBySiteAndNameQuery(siteId, searchString) });
    spaces && setSpaces(spaces.sort((a, b) => alphabeticalSort(a.spaceName, b.spaceName)));
    setLoadingSpaces(false);
  }, [refreshSpaces]);

  const fetchDevices = useCallback(async (siteId: number, searchString: string) => {
    const devices = await refreshDevices({ query: new DevicesGetBySiteAndIdentifierQuery(siteId, searchString) });
    devices && setDevices(devices.sort((a, b) => alphabeticalSort(a.deviceIdentifier, b.deviceIdentifier)));
    setLoadingDevices(false);
  }, [refreshDevices]);

  useEffect(() => {
    if (!isValidSearch(searchString)) {
      return;
    }

    setLoadingSpaces(true);
    setLoadingDevices(true);
    trackAction('space_device_search', 'site');

    const timer = setTimeout(() => {
      fetchSpaces(site.id, searchString);
      fetchDevices(site.id, searchString);
    }, 800);

    return () => clearTimeout(timer);
  }, [site, searchString, fetchSpaces, fetchDevices, trackAction]);

  const reset = useCallback(() => {
    setSpaces([]);
    setDevices([]);
  }, []);

  const handleClear = useCallback(() => {
    setSearchString('');
    setVisible(false);
    reset();
  }, [reset, setVisible]);

  useEffect(() => {
    if (!isValidSearch(searchString)) {
      reset();
    }
  }, [searchString, reset]);

  return (
    <Wrapper ref={ref}>
      <SearchBarInput
        placeholder={t('SiteSearchBarPlaceholder', { ns: 'common' })}
        searchString={searchString}
        onChange={setSearchString}
        onClick={() => setVisible(true)}
        onClear={handleClear}
      />

      <SearchBarDropdown
        open={visible}
        parentRef={ref}
        spaces={spaces}
        devices={devices}
        loadingSpaces={loadingSpaces}
        loadingDevices={loadingDevices}
        searchString={searchString}
        onClear={handleClear}
      />
    </Wrapper>
  );
};

export default SearchBar;

const Wrapper = styled.div`
  position: relative;
`;