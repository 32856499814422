import styled, { useTheme } from 'styled-components';
import { TooltipItem } from 'chart.js';
import OccupancyChart, { OccupancyDataset } from './OccupancyChart';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { ReportOccupancyHistogramDto } from '@dashboard/api/models/ReportOccupancyHistogramDto';
import { rgbaToRgb } from '@dashboard/utils/ColorUtils';

interface IOccupancyHistogramWidget {
  data: ReportOccupancyHistogramDto[];
  inPrintMode: boolean;
}

const OccupancyHistogramWidget = ({ data, inPrintMode }: IOccupancyHistogramWidget) => {
  const { t } = useTranslation(['molecules']);
  const theme = useTheme();
  const color = inPrintMode ? theme.palette.printStyles.chartColor : theme.palette.esgReport.chartColor;

  const datasets: OccupancyDataset[] = [
    {
      label: t('ESG.Occupancy', { ns: 'molecules' }),
      gradientColors: [`${rgbaToRgb(transparentize(0.4, color))}`, color],
      dataset: data.map(x => x.numberOfSpaces)
    }];

  const labels = data.map(x => x.numberOfDays.toString());

  return (
    <Card>
      <Title>
        {t('ESG.OccupancyHistogram', { ns: 'molecules' })}
      </Title>
      <Wrapper>
        <OccupancyChart
          datasets={datasets}
          labels={labels}
          yAxisLabel={t('ESG.NumberOfSpaces', { ns: 'molecules' })}
          xAxisLabel={t('ESG.NumberOfDays', { ns: 'molecules' })}
          inPrintMode={inPrintMode}
          printDimensions={{ width: 172, height: 40 }}
          xAxisTicksCallback={(_, index) => {
            return labels[index];
          }}
          tooltipCallbacks={
            {
              title: (items: TooltipItem<'bar' | 'line'>[]) => {
                const label = items[0]?.label;
                return `${t('ESG.OccupiedFor', { ns: 'molecules' })} ${label} ${t('ESG.Days', { ns: 'molecules' })}`;
              },
              label: (item: TooltipItem<'bar' | 'line'>) => {
                const suffix = item.raw === 1 ? t('ESG.Space', { ns: 'molecules' }) : t('ESG.Spaces', { ns: 'molecules' });
                return `${item.formattedValue} ${suffix}`;
              }
            }
          }
        />
      </Wrapper>
    </Card>
  );
};

export default OccupancyHistogramWidget;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: max-content;
  padding: 20px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 10px;
  box-shadow: 0px 3px 8px ${p => p.theme.palette.esgReport.cardShadow};

  @media print {
    float: left;
    margin-bottom: 10px;
    padding: 10px;
    page-break-inside: avoid;
    box-shadow: 0 0 4px 1px ${p => p.theme.palette.esgReport.cardShadow}, 0 0 1px 0px ${p => p.theme.palette.esgReport.cardShadow};
    border-radius: 5px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;

  @media print {
    font-size: 9pt;
    padding-bottom: 10px;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;

const Wrapper = styled.div`
  height: 200px;

  @media print {
    height: max-content;
  }
`;