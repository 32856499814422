import { useTheme } from 'styled-components';
import { getAbbreviatedMonthName } from '@shared/utils/DateUtils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Chart, ChartData, ChartTypeRegistry } from 'chart.js';
import { constructChartOptions } from './chartOptions';
import { darken, lighten } from 'polished';
import { useTranslation } from 'react-i18next';
import { LineBarChartType } from '@shared/components/molecules/Charts/Chart.types';
import { orderBy } from 'lodash';
import { UtilityData } from '../../UtilitiesSummary';

export enum ConsumptionChartStack {
  ThisYear = 'ThisYear',
  LastYear = 'LastYear',
  Target = 'Target',
  Total = 'Total'
}

export type ConsumptionChartDataset = {
  id: string;
  label: string;
  year: number;
  dataUnit: string;
  data: number[];
  type: LineBarChartType;
  stack: ConsumptionChartStack;
  legend: {
    color: string,
    group: ConsumptionChartStack,
    order: number
  },
  isGasWidget: boolean;
}

type PropTypes = {
  data: UtilityData;
};

const useMonthlyChart = ({ data }: PropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart<keyof ChartTypeRegistry, number[], string>>();
  const [datasets, setDatasets] = useState<ConsumptionChartDataset[]>([]);

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, number[], string> => {
    const year = (new Date).getFullYear();
    const numCategories = data.meterCategories.length;
    const lightenFactor = numCategories < 4 ? 0.2 : 0.13;
    const currentYearColor = darken(numCategories > 1 ? 0.1 : 0, theme.palette.primary);
    const prevYearColor = theme.palette.secondary;
    const targetColor = darken(0.3, theme.palette.primary);

    const targetDataset = {
      id: `${ConsumptionChartStack.Target}`,
      label: t('Target', { ns: 'common' }),
      year: year,
      dataUnit: data.unit,
      data: data.monthlyTargets,
      type: LineBarChartType.Line,
      stack: ConsumptionChartStack.Target,
      borderColor: targetColor,
      legend: {
        color: targetColor,
        group: ConsumptionChartStack.ThisYear,
        order: 2
      },
      isGasWidget: data.isGasWidget
    };

    const totalThisYearDataset = {
      id: `${ConsumptionChartStack.Total}`,
      label: t('Total', { ns: 'common' }),
      year: year,
      dataUnit: data.unit,
      data: data.monthlyTotalsThisYear,
      type: LineBarChartType.Line,
      stack: ConsumptionChartStack.Total,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      hoverBackgroundColor: 'transparent',
      borderWidth: { top: 2 },
      legend: {
        color: targetColor,
        group: ConsumptionChartStack.Total,
        order: 2
      },
      isGasWidget: data.isGasWidget,
      hidden: true
    };

    const totalLastYearDataset = {
      id: `${ConsumptionChartStack.Total}-PreviousYear`,
      label: t('Total', { ns: 'common' }),
      year: year - 1,
      dataUnit: data.unit,
      data: data.monthlyTotalsLastYear,
      type: LineBarChartType.Line,
      stack: ConsumptionChartStack.Total,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      hoverBackgroundColor: 'transparent',
      borderWidth: { top: 2 },
      legend: {
        color: targetColor,
        group: ConsumptionChartStack.Total,
        order: 2
      },
      isGasWidget: data.isGasWidget,
      hidden: true
    };

    // Create two datasets (current and previous year) for each meter category
    const meterCategoryDatasets = orderBy(data.meterCategories, x => x.meterCategory).flatMap((category, i) => {
      const color1 = lighten(lightenFactor * i, currentYearColor);
      const color2 = lighten(lightenFactor * i, prevYearColor);

      const currentYearDataset = {
        id: `${category.meterCategory}-${ConsumptionChartStack.ThisYear}`,
        label: category.meterCategory ? t(category.meterCategory, { ns: 'enums' }) : t('SiteOverview.ThisYear', { ns: 'molecules' }),
        year: year,
        dataUnit: data.unit,
        data: category.currentYearTotals,
        type: LineBarChartType.Bar,
        stack: ConsumptionChartStack.ThisYear,
        borderColor: theme.palette.text.onPrimary,
        borderWidth: { top: 2 },
        backgroundColor: color1,
        hoverBackgroundColor: color1,
        legend: {
          color: color1,
          group: ConsumptionChartStack.ThisYear,
          order: 1
        },
        isGasWidget: data.isGasWidget
      };

      const previousYearDataset = {
        id: `${category.meterCategory}-${ConsumptionChartStack.LastYear}`,
        label: category.meterCategory ? t(category.meterCategory, { ns: 'enums' }) : t('SiteOverview.LastYear', { ns: 'molecules' }),
        year: year - 1,
        dataUnit: data.unit,
        data: category.previousYearTotals,
        type: LineBarChartType.Bar,
        stack: ConsumptionChartStack.LastYear,
        borderColor: theme.palette.text.onPrimary,
        borderWidth: { top: 2 },
        backgroundColor: color2,
        hoverBackgroundColor: color2,
        legend: {
          color: color2,
          group: ConsumptionChartStack.LastYear,
          order: 1
        },
        isGasWidget: data.isGasWidget
      };

      return [currentYearDataset, previousYearDataset];
    });

    const sorted = orderBy(meterCategoryDatasets, x => x.stack);
    sorted.splice(data.meterCategories.length, 0, totalLastYearDataset);
    sorted.push(totalThisYearDataset);

    const datasets = [targetDataset, ...sorted];
    setDatasets(datasets);

    return {
      labels: data.monthlyTargets.map((_, i) => getAbbreviatedMonthName(i + 1)),
      datasets: datasets.map(x => (
        {
          ...x,
          categoryPercentage: 0.75,
          barPercentage: 0.9
        }
      )),
    }
  }, [data, theme, t]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');

    if (context) {
      const data = constructData();

      const chart = new Chart(context, {
        type: 'bar',
        data: data,
        options: constructChartOptions(theme, data.datasets as unknown as ConsumptionChartDataset[])
      });

      setChart(chart);
      return () => chart.destroy();
    }
  }, [constructData, theme]);

  /**
   * Show/hide datasets from the chart as they are selected/deselected in the legend component.
   */
  const handleLegendChange = (hiddenDatasets: { [key: string]: string }) => {
    if (chart) {
      chart.data.datasets.forEach((dataset) => {
        dataset.hidden = !!hiddenDatasets[(dataset as unknown as ConsumptionChartDataset).id];
      });

      chart.update();
    }
  };

  return {
    canvasRef,
    datasets,
    handleLegendChange
  };
};

export default useMonthlyChart;