import styled, { css, useTheme } from 'styled-components';
import { Control, Controller, FieldArrayWithId, FormState, Path, UseFieldArrayUpdate, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SpaceTypeSelect from './SpaceTypeSelect';
import SpaceTypeOptions from './SpaceTypeOptions';
import { ClusterFormValues, SpacesFormValues } from './BuildingSpaceList';
import { useModal } from '@shared/hooks/useModal';
import { ErrorMessage, Input } from '@shared/components/atoms/Form/Form';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { SpaceType_Icon } from '@shared/api/enums/SpaceType/SpaceType_Icon';
import { SpaceType_Color } from '@shared/api/enums/SpaceType/SpaceType_Color';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { Button } from '@shared/components/atoms/Button/Button';
import { Icon } from '@shared/components/atoms/Icon/Icon';
import { useCallback } from 'react';
import { transparentize } from 'polished';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { isCommonSpaceType } from '../SpaceUtils';
import { ReactComponent as SharerMultipleSvg } from '@shared/assets/icons/spaceTypes/sharerMultiple.svg';

type PropTypes = {
  formValues: ClusterFormValues,
  field: FieldArrayWithId<SpacesFormValues, 'spacesFieldArray', 'generated-id'>
  index: number,
  formState: FormState<SpacesFormValues>,
  register: UseFormRegister<SpacesFormValues>,
  update: UseFieldArrayUpdate<SpacesFormValues, 'spacesFieldArray'> | UseFieldArrayUpdate<SpacesFormValues, 'clusters'> | UseFieldArrayUpdate<SpacesFormValues, `clusters.${number}.spaces`>;
  remove: (index: number) => void,
  control: Control<SpacesFormValues>,
  editMode: boolean;
  isModified: boolean;
  isCluster: boolean;
  clusterIndex?: number;
}

const SpaceEditRow = ({ formValues, field, index, formState, register, update, remove, control, editMode, isModified, isCluster, clusterIndex }: PropTypes) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const { errors } = formState;
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});

  /**
   * Mark the space for deletion and hide the deletion dialog.
   */
  const handleRemoveConfirm = useCallback(() => {
    update(index, { ...formValues, toBeDeleted: true });
    toggleDeleteDialog()
  }, [formValues, index, update, toggleDeleteDialog]);

  /**
   * Remove a newly created space from the list.
   * For existing spaces, show the deletion dialog.
   */
  const handleRemove = useCallback(() => {
    if (formValues.toBeCreated) {
      remove(index);
      return;
    }

    toggleDeleteDialog()
  }, [formValues, index, remove, toggleDeleteDialog]);

  /**
   * Restore a space that was previously marked for deletion
   */
  const handleRestore = useCallback(() => {
    const restored = { ...formValues };
    delete restored.toBeDeleted;
    update(index, restored);
  }, [formValues, index, update]);

  const getRegisterPath = (pathValue: string, isCluster: boolean, index: number, clusterIndex?: number): string => {
    if (isCluster) {
      return clusterIndex !== undefined ? `clusters.${clusterIndex}.spaces.${index}.${pathValue}` : `clusters.${index}.${pathValue}`;
    }
    return `spacesFieldArray.${index}.${pathValue}`;
  }

  return (
    <FlexRow
      editMode={editMode}
      toBeCreated={formValues.toBeCreated}
      toBeDeleted={formValues.toBeDeleted}
      isModified={isModified}
      isClusterSpace={clusterIndex !== undefined}
    >
      <Column style={{ width: '50px' }}>
        {formValues.spaceType ?
          <Icon
            icon={SpaceType_Icon(formValues.spaceType)}
            squareColor={SpaceType_Color(formValues.spaceType)}
            iconColor='#fff'
            iconSize='23px'
          /> :
          <Icon
            icon={<SharerMultipleSvg />}
            squareColor={'#3B6D97'}
            iconColor='#fff'
            iconSize='23px'
          />
        }
      </Column>

      <Column width={25}>
        <Input
          {...register(getRegisterPath(isCluster && clusterIndex === undefined ? 'clusterName' : 'name', isCluster, index, clusterIndex) as Path<SpacesFormValues>, {
            required: t('Buildings.Spaces.SpaceEditRow.Required', { ns: 'settingsAsset' })
          })}
          defaultValue={field.name}
          placeholder={t('Buildings.Spaces.SpaceEditRow.SpaceName', { ns: 'settingsAsset' })}
          readOnly={!editMode}
        />
        <ErrorMessage>{errors?.spacesFieldArray?.[index]?.name?.message}</ErrorMessage>
      </Column>

      <Column width={25}>
        <Controller
          control={control}
          name={getRegisterPath('spaceType', isCluster, index, clusterIndex) as Path<SpacesFormValues>}
          rules={{
            required: {
              value: formValues.spaceType ? true : false,
              message: t('Buildings.Spaces.SpaceEditRow.Required', { ns: 'settingsAsset' })
            }
          }}
          render={({ field: { onChange } }) => {
            if (formValues.spaceType) {
              return <SpaceTypeSelect
                options={isCluster && clusterIndex !== undefined ?
                  { ...SpaceTypeOptions, groups: [{ label: 'Apartment', options: [SpaceType.Bedroom, SpaceType.LKD] }] } :
                  SpaceTypeOptions}
                placeholder={t('Buildings.Spaces.SpaceEditRow.Select', { ns: 'settingsAsset' })}
                onChange={(e) => {
                  onChange(e?.value);
                }}
                value={formValues.spaceType}
                readOnly={isCluster && clusterIndex === undefined || !editMode}
              />
            } else {
              return <Input
                style={{ paddingLeft: editMode ? '10px' : '0' }}
                readOnly={isCluster && clusterIndex === undefined || !editMode}
                value={'Cluster'}
              />;
            }
          }}
        />
        <ErrorMessage>{errors?.spacesFieldArray?.[index]?.spaceType?.message}</ErrorMessage>
      </Column>

      <Column width={15}>
        {formValues.spaceType && isCommonSpaceType(formValues.spaceType) &&
          <>
            <Input
              {...register(getRegisterPath('maxOccupancy', isCluster, index, clusterIndex) as Path<SpacesFormValues>, {
                required: t('Buildings.Spaces.SpaceEditRow.Required', { ns: 'settingsAsset' }),
                setValueAs: (value) => stringToNumber(value)
              })}
              defaultValue={formValues.maxOccupancy}
              type="number"
              placeholder={t('Buildings.Spaces.SpaceEditRow.Occupancy', { ns: 'settingsAsset' })}
              style={{ textAlign: editMode ? 'right' : 'left', paddingRight: '5px' }}
              readOnly={isCluster && clusterIndex === undefined || !editMode}
            />
            <ErrorMessage>{errors?.spacesFieldArray?.[index]?.maxOccupancy?.message}</ErrorMessage>
          </>
        }
      </Column>

      <Column width={15}>
        <Input
          {...register(getRegisterPath('area', isCluster, index, clusterIndex) as Path<SpacesFormValues>, {
            required: false,
            setValueAs: value => stringToNumber(value)
          })}
          defaultValue={field.area}
          type="number"
          placeholder={t('Buildings.Spaces.SpaceEditRow.Area', { ns: 'settingsAsset' })}
          style={{ textAlign: editMode ? 'right' : 'left', paddingRight: '5px' }}
          readOnly={isCluster && clusterIndex === undefined || !editMode}
        />
      </Column>

      <Column inlineOnMobile>
        {formValues.spaceType &&
          <Controller
            control={control}
            name={getRegisterPath('occupied', isCluster, index, clusterIndex) as Path<SpacesFormValues>}
            render={({ field: { onChange } }) => (
              <Switch
                checked={!!formValues && formValues.occupied}
                onChange={onChange}
                styles={{ marginLeft: '2px', marginTop: '10px' }}
                disabled={!editMode}
              />
            )}
          />
        }
      </Column>

      <Column inlineOnMobile style={{ marginLeft: 'auto' }}>
        {editMode &&
          <>
            <WarningDialog
              modalRef={deleteDialogRef}
              isOpen={deleteDialogIsOpen}
              sectionOne={isCluster && clusterIndex == undefined ?
                t('Buildings.Spaces.SpaceEditRow.DeleteDialog.ClusterParagraphOne', { ns: 'settingsAsset' }) :
                t('Buildings.Spaces.SpaceEditRow.DeleteDialog.ParagraphOne', { ns: 'settingsAsset' })
              }
              sectionTwo={isCluster && clusterIndex == undefined ?
                t('Buildings.Spaces.SpaceEditRow.DeleteDialog.ClusterParagraphTwo', { ns: 'settingsAsset' }) :
                t('Buildings.Spaces.SpaceEditRow.DeleteDialog.ParagraphTwo', { ns: 'settingsAsset' })
              }
              sectionThree={t('Buildings.Spaces.SpaceEditRow.DeleteDialog.ParagraphThree', { ns: 'settingsAsset' })}
              confirmationCheckbox={t('Buildings.Spaces.SpaceEditRow.DeleteDialog.ConfirmationCheckboxText', { ns: 'settingsAsset' })}
              onCancel={toggleDeleteDialog}
              onConfirm={handleRemoveConfirm}
            />

            {formValues.toBeDeleted
              ? <Button
                tertiary
                circle
                onClick={handleRestore}
                label={<FontAwesomeIcon icon={solid('rotate-left')} style={{ width: '18px', height: '18px' }} />}
                color={theme.palette.green}
                style={{ marginLeft: '5px' }}
                tooltip={{
                  content: t('Buildings.Spaces.SpaceEditRow.Undo', { ns: 'settingsAsset' }),
                  placement: TooltipPlacement.Left
                }}
              />
              : <Button
                tertiary
                circle
                onClick={handleRemove}
                label={<FontAwesomeIcon icon={solid('trash-xmark')} style={{ width: '18px', height: '18px' }} />}
                color={theme.palette.red}
                style={{ marginLeft: '5px' }}
              />
            }
          </>
        }
      </Column>
    </FlexRow>
  );
}

export default SpaceEditRow;

const FlexRow = styled.div<{ editMode: boolean, toBeCreated?: boolean, toBeDeleted?: boolean, isModified: boolean, isClusterSpace: boolean }>`
  display: flex;
  flex-wrap: wrap;

  margin: 0 25px 2px;
  padding: 6px 15px;
  border-radius: 5px;
  border: 1px dashed transparent;

  ${p => p.toBeCreated && css`
    border-color: ${p => p.theme.palette.green};
    background-color: ${p => transparentize(0.9, p.theme.palette.green)};
  `}

  ${p => p.toBeDeleted && css`
    border-color: ${p => p.theme.palette.red};
    background-color: ${p => transparentize(0.9, p.theme.palette.red)};
  `}

  ${p => p.isModified && css`
    border-color: ${p => p.theme.palette.orange};
    background-color: ${p => transparentize(0.9, p.theme.palette.orange)};
  `}

  ${p => !p.editMode && css`
    &:nth-child(even) {
      background-color: ${p => p.theme.palette.tables.evenRow};
    }
    
    &:nth-child(odd) {
      background-color: ${p => p.theme.palette.tables.unevenRow};
    }
  `}

  ${p => p.isClusterSpace && css`
    background-color: ${p => transparentize(0.9, p.theme.palette.text.weak)};
  `}

`;

const Column = styled.div<{ width?: number, inlineOnMobile?: boolean }>`
  padding: 5px 0;    
  width: 100%;

  ${p => p.inlineOnMobile && css`
    width: auto;
  `}
  
  @media (min-width: 1300px) {
    width: ${p => p.width !== undefined ? `${p.width}%` : 'auto'};
    padding: 0;

    &:not(:first-child) {
      padding-left: 10px;
    }
  
    &:not(:last-child) {
      padding-right: 10px;
    }
  }
`;