import DeviceNoteAttachmentDto from '@dashboard/api/models/DeviceNoteAttachmentDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class DeviceNoteAttachmentDownloadCommand extends GetQuery<DeviceNoteAttachmentDto> {
  attachmentId: number;

  constructor(attachmentId: number) {
    super();
    this.attachmentId = attachmentId;
  }

  public targetUrl(): string {
    return `/devicenotes/downloadattachment/${this.attachmentId}`;
  }
}
