import { format, subYears } from 'date-fns';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MonthlyConsumptionChart, { MonthlyConsumptionDataset } from './MonthlyConsumptionChart';
import { ReportHistoricSeriesDto } from '@dashboard/api/models/ReportEnergyConsumptionDto';
import { getAbbreviatedMonthName } from '@shared/utils/DateUtils';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { getMetricTypeFromEnergyMeterType } from './helpers/ModifierHelpers';

interface IMonthlyConsumptionChartWrapper {
  reportDate: Date;
  historicSeriesData: ReportHistoricSeriesDto[];
  color: string;
  dataUnit: string;
  inPrintMode: boolean;
  energyMeterType: EnergyMeterType;
}

const MonthlyConsumptionChartWrapper = ({ reportDate, historicSeriesData, dataUnit, color, inPrintMode, energyMeterType }: IMonthlyConsumptionChartWrapper) => {
  const { t } = useTranslation(['molecules']);
  const { toLocale } = useLocalisation();

  const currentYearDataset: MonthlyConsumptionDataset = {
    label: format(reportDate, 'yyyy'),
    color: color,
    dataUnit: dataUnit,
    dataset: historicSeriesData.map(x => toLocale(getMetricTypeFromEnergyMeterType(energyMeterType), x.currentYearTotal ?? NaN) ?? NaN)
  };

  const previousYearDataset: MonthlyConsumptionDataset = {
    label: format(subYears(reportDate, 1), 'yyyy'),
    color: transparentize(0.75, color),
    dataUnit: dataUnit,
    dataset: historicSeriesData.map(x => toLocale(getMetricTypeFromEnergyMeterType(energyMeterType), x.previousYearTotal ?? NaN) ?? NaN)
  };

  const datasets = [previousYearDataset, currentYearDataset];

  const labels = historicSeriesData.map(x => getAbbreviatedMonthName(x.month));

  return (
    <div>
      <FlexRow>
        <Label>
          {t('ESG.MonthOnMonth', { ns: 'molecules' })}
        </Label>

        {!inPrintMode &&
          <Legend>
            <LegendItem>
              <div>{currentYearDataset.label}</div>
              <Circle color={currentYearDataset.color} />
            </LegendItem>
            <LegendItem>
              <div>{previousYearDataset.label}</div>
              <Circle color={previousYearDataset.color} />
            </LegendItem>
          </Legend>
        }
      </FlexRow>
      <Wrapper>
        <MonthlyConsumptionChart
          datasets={datasets}
          labels={labels}
          inPrintMode={inPrintMode}
        />
      </Wrapper>
    </div>
  );
};

export default MonthlyConsumptionChartWrapper;

const Label = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${p => p.theme.palette.text.fair};
  padding-bottom: 5px;

  @media print { 
    font-weight: 500;
    color: ${p => p.theme.palette.printStyles.text.fair};
    padding-bottom: 2px;
  }
`;

const Wrapper = styled.div`
  height: 130px;
  padding: 10px 14px 0 10px;
  overflow: hidden;

  @media print { 
    height: 20mm;
    padding: 0px 10px 0 20px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 19px 0px 16px;

  @media print { 
    padding: 0px 10px 0px 9px;
  }
`;

const Legend = styled.div`
  margin-left: auto;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  color: ${p => p.theme.palette.text.fair};

  display: flex;
  flex-direction: column;
  gap: 4px;

  @media print { 
    font-weight: 500;
    color: ${p => p.theme.palette.printStyles.text.fair};
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
`;

const Circle = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${p => p.color};

  @media print { 
    width: 10px;
    height: 10px;
  }
`;