import { DevicesGetByBuildingIdDeviceTypeQuery } from '@dashboard/api/queries/devices/DevicesGetByBuildingIdDeviceTypeQuery';
import CsvDevicesGetQuery from '@dashboard/api/queries/exports/CsvDevicesGetQuery';
import CsvDevicesWithMetricsGetByBuildingAndMetricTypesQuery from '@dashboard/api/queries/exports/CsvDevicesWithMetricsGetByBuildingAndMetricTypesQuery';
import { TimeRange } from '@dashboard/components/atoms/RangePicker/RangePicker';
import useCsvExport from '@dashboard/hooks/useCsvExport';
import { GetMetricValue } from '@dashboard/utils/MetricUtils';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Building } from '@shared/api/models/Building/Building';
import { DeviceDetails } from '@shared/api/models/Device/DeviceDetails';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApiState } from '@shared/hooks/useApiState';
import { ThemeColored } from '@src/components/shared/ThemeColoredSpan/ThemeColoredSpan';
import { BreadcrumbHeight, TopBarHeight } from '@src/constants/LayoutConstants';
import { format, formatDistance } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import ButtonWithRangePicker from '../ButtonWithRangePicker/ButtonWithRangePicker';

type PropTypes = {
  building: Building
}

const Devices_ClimateControl = ({ building }: PropTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getDisplayString } = useDeviceConfigContext();
  const { buildingId } = useParams<{ buildingId: string }>();
  const { handleCsvExport: exportDevices, loading: loadingDevicesExport } = useCsvExport();
  const { handleCsvExport: exportMeasurements, loading: loadingMeasurementsExport } = useCsvExport();
  const { transform: devices, loading } = useApiState({
    query: buildingId === undefined ? undefined : new DevicesGetByBuildingIdDeviceTypeQuery(parseInt(buildingId), DeviceType.TRV),
    transformFunc: dto => dto?.devices ?? [],
    initialTransformState: []
  }, [buildingId]);

  const tableColumns: ITableColumn<DeviceDetails>[] = useMemo(() => ([
    {
      label: t('DeviceCard.Name', { ns: 'molecules' }),
      key: 'friendlyName',
      width: 2
    },
    {
      label: t('DeviceCard.DeviceModel', { ns: 'molecules' }),
      key: 'deviceModel',
      width: 2,
      displayFormat: ({ deviceModel }) => getDisplayString(deviceModel),
    },
    {
      label: t('DeviceCard.DeviceIdentifier', { ns: 'molecules' }),
      key: 'deviceIdentifier',
      width: 2,
    },
    {
      label: t('DeviceCard.Space', { ns: 'molecules' }),
      key: 'spaceName',
      width: 2
    },
    {
      label: t('DeviceCard.LastMeasuredOn', { ns: 'molecules' }),
      key: 'lastMeasuredOn',
      width: 2,
      sortFormat: device => device.lastMeasuredOn && new Date(device.lastMeasuredOn),
      displayFormat: device => device.lastMeasuredOn ? format(new Date(device.lastMeasuredOn), 'dd MMM yyyy') : t('DeviceCard.Never', { ns: 'molecules' }),
      displaySuffix: device => device.lastMeasuredOn ? `(${formatDistance(new Date(device.lastMeasuredOn), new Date(), { addSuffix: true })})` : '',
    },
    {
      label: t('DeviceCard.Battery', { ns: 'molecules' }),
      key: 'battery',
      width: 1,
      sortFormat: (device: DeviceDetails) => {
        const value = GetMetricValue(device, MetricType.BatteryVoltage);
        return value && parseFloat(value);
      },
      displayFormat: (device: DeviceDetails) => {
        const value = GetMetricValue(device, MetricType.BatteryVoltage);
        return value ? `${parseFloat(value).toFixed(2)}v` : '';
      },
      rightAlign: true,
    },
    {
      label: t('DeviceCard.Signal', { ns: 'molecules' }),
      key: 'signal',
      width: 1,
      sortFormat: (device: DeviceDetails) => {
        const value = GetMetricValue(device, MetricType.SignalStrength);
        return value && parseFloat(value);
      },
      displayFormat: (device: DeviceDetails) => {
        const value = GetMetricValue(device, MetricType.SignalStrength);
        return value ? `${parseFloat(value).toFixed(0)}%` : ''
      },
      rightAlign: true,
    }
  ]), [getDisplayString, t]);

  const handleDevicesExport = async () => {
    if (buildingId === undefined) {
      return;
    }

    const query = new CsvDevicesGetQuery(parseInt(buildingId), DeviceType.TRV);
    const fileName = `${building.name}${t('DeviceCard.FileNameTRVDevices', { ns: 'molecules' })}.csv`
    exportDevices(query, fileName);
  };

  const handleMeasurementsExport = async (timeRange: TimeRange) => {
    if (buildingId === undefined) {
      return;
    }

    const query = new CsvDevicesWithMetricsGetByBuildingAndMetricTypesQuery(parseInt(buildingId), [MetricType.Temperature], timeRange.from.toJSON(), timeRange.to.toJSON());
    const fileName = `${building.name}${t('DeviceCard.FileNameTRVMeasurements', { ns: 'molecules' })}${format(timeRange.from, 'yyyyMMdd')}-${format(timeRange.to, 'yyyyMMdd')}.csv`;
    exportMeasurements(query, fileName);
  };

  return (
    <>
      <BackButton
        url='./..'
        label={t('BackToDevices', { ns: 'navigation' })}
      />

      <FlexRow>
        <Title text={<>{t('ClimateControl.TemperatureControl', { ns: 'molecules' })} <ThemeColored>({devices.length})</ThemeColored></>} />

        <Button
          label={t('DeviceCard.ExportDevices', { ns: 'molecules' })}
          onClick={handleDevicesExport}
          loading={loadingDevicesExport}
          style={{ marginLeft: 'auto' }}
        />
        <ButtonWithRangePicker
          label={t('DeviceCard.ExportMeasurements', { ns: 'molecules' })}
          onRangeChange={handleMeasurementsExport}
          loading={loadingMeasurementsExport}
        />
      </FlexRow>

      <Table
        columns={tableColumns}
        records={devices}
        recordKey="deviceId"
        emptyMessage={t('DeviceCard.NoDevicesFound', { ns: 'molecules' })}
        defaultSortColumn="friendlyName"
        onRowClick={(row: DeviceDetails) => { navigate(`./../../../floor/${row.floorId}/space/${row.spaceId}/device/${row.deviceId}`) }}
        loading={loading}
        cardEffect
        fullHeightSubtractor={TopBarHeight + BreadcrumbHeight + 380}
      />
    </>
  );
}

export default Devices_ClimateControl;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;