import styled from 'styled-components';

type PropTypes = {
  color: string;
}

const DottedLine = ({ color }: PropTypes) => {
  return (
    <Line color={color}>
      <Dot color={color} />
    </Line>
  );
};

export default DottedLine;

const Line = styled.div<{ color: string }>`
  position: relative;
  width: 16px;
  height: 3px;
  border-radius: 4px;
  background-color: ${p => p.color};
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 4px;
  background-color: ${p => p.color};
  flex-shrink: 0;
`;