import { EsgExplorerConfiguration } from '@dashboard/api/models/EsgExplorerConfiguration';
import RangePicker from '@dashboard/components/atoms/RangePicker/RangePicker';

interface ITimeRangeSelect {
  config: EsgExplorerConfiguration;
  onChange: (config: EsgExplorerConfiguration) => void;
}

const TimeRangeSelect = ({ config, onChange }: ITimeRangeSelect) => {
  return (
    <RangePicker
      value={config.timeRange ? [new Date(config.timeRange.from), new Date(config.timeRange.to)] : undefined}
      onChange={timeRange => timeRange &&
        onChange({
          ...config,
          timeRange: {
            from: timeRange.from.toISOString(),
            to: timeRange.to.toISOString()
          }
        })}
      allowClear={false}
      style={{ width: '100%' }}
    />
  );
};

export default TimeRangeSelect;