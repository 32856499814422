import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import CardWithSideBorder from '@dashboard/components/molecules/ResidentApp/Common/CardWithSideBorder';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ThresholdEditTitle from './ThresholdEditTitle';
import { SpaceTypeThresholdDto } from '@shared/api/models/ResidentApp/SpaceTypeThresholdDto';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { mapFormToSpaceTypeThresholdDtos, mapToSpaceTypeThresholdForm, mapToSpaceTypeThresholds } from '../ResidentAppUtils';
import { FormProvider, useForm } from 'react-hook-form';
import ThresholdEditableTable from './ThresholdEditableTable';
import { SiteThresholdForm } from './TemperatureThresholdForm';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import SpaceTypeThresholdsResidentAppUpdateCommand from '@shared/api/queries/ResidentApp/SpaceTypeThresholds/SpaceTypeThresholdsResidentAppUpdateCommand';
import { useApi } from '@shared/hooks/useApi';

export type SpaceTypeThresholdForm = {
  thresholds: {
    spaceType?: SpaceType;
    bedroomCount?: number;
    seasonOne: {id: number, targetValue: number};
    seasonTwo: {id: number, targetValue: number};
  }[];
};

type ElectricityThresholdFormProps = {
  spaceTypeThresholds: SpaceTypeThresholdDto[];
}

const ElectricityThresholdForm = ({spaceTypeThresholds}: ElectricityThresholdFormProps) => {
  const { t } = useTranslation('molecules');
  const { siteOperator, refreshSiteOperator } = useSiteContext();
  const {execute, loading, error} = useApi();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const thresholds = mapToSpaceTypeThresholds(spaceTypeThresholds);

  const methods = useForm<SpaceTypeThresholdForm|SiteThresholdForm>({
    defaultValues: mapToSpaceTypeThresholdForm(spaceTypeThresholds),
  });

  const handleUpdateThreshold = async (data: SpaceTypeThresholdForm | SiteThresholdForm) => {
    if (!siteOperator) {
      return;
    }

    await execute({
      query: new SpaceTypeThresholdsResidentAppUpdateCommand(siteOperator.siteId, mapFormToSpaceTypeThresholdDtos(data, siteOperator.siteId, siteOperator.id)),
      successMessage: t('ThresholdsUpdated', { ns: 'status' }),
      errorMessage: t('ThresholdsUpdateError', { ns: 'status' }),
      pendingMessage: t('ThresholdsUpdating', { ns: 'status' }),
    });

    if (!error) {
      // reset edit mode
      setIsEditMode(false);
      refreshSiteOperator();
    }
  }

  return (
    <CardWithSideBorder>
      <ThresholdEditTitle
        metricType={MetricType.ElectricityKwh}
        descriptionText={t('ResidentApp.ElectricityEnterThresholdText', { ns: 'molecules' })}
        isEditMode={isEditMode}
        setIsEditMode={(isEditMode) => setIsEditMode(isEditMode)}
      />

      <FormProvider {...methods}>
        <ThresholdEditableTable
          isEditMode={isEditMode}
          methods={methods}
          thresholds={thresholds}
          onCancel={() => setIsEditMode(false)}
          onSave={methods.handleSubmit(handleUpdateThreshold)}
          metricType={MetricType.ElectricityKwh}
          loading={loading}
        />
      </FormProvider>
    </CardWithSideBorder>
  );
}

export default ElectricityThresholdForm;