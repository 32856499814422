import { useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import UserPasswordResetCommand from '@settings/api/queries/Users/UserPasswordResetCommand';
import { Button } from '@shared/components/atoms/Button/Button';

type PropTypes = {
  userId: number
}

const ResetPassword = ({ userId }: PropTypes) => {
  const { t } = useTranslation(['settingsUser']);
  const theme = useTheme();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { execute } = useApi();

  const resetPassword = async () => {
    setSavingInProgress(true);

    const passwordDto = await execute({
      query: new UserPasswordResetCommand(userId),
      successMessage: t('ResetPassword.PasswordReset', { ns: 'settingsUser' }),
      errorMessage: t('ResetPassword.PasswordResetFailed', { ns: 'settingsUser' })
    });

    if (passwordDto === undefined) {
      return;
    }

    setSavingInProgress(false);
  }

  return (
    <Button
      tertiary
      label={t('ResetPassword.ResetPassword', { ns: 'settingsUser' })}
      onClick={resetPassword}
      color={theme.palette.red}
      loading={savingInProgress}
    />
  );
};

export default ResetPassword;