import { transparentize } from 'polished';
import styled, { useTheme } from 'styled-components';
import RowValue from './RowValue';
import EsgMetricType from '@dashboard/api/enums/EsgMetricType/EsgMetricType';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { EsgExplorerMetricsSpaceDto } from '@dashboard/api/models/EsgExplorerMetricsDto';
import { useTranslation } from 'react-i18next';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';

interface IRow {
  space: EsgExplorerMetricsSpaceDto;
  sortKey: string;
  maxValues?: { metricType: EsgMetricType; maxValue: number; minValue: number; }[];
  showConditionalFormatting: boolean;
  showClusterInfo?: boolean;
}

const Row = ({ space, sortKey, maxValues, showConditionalFormatting, showClusterInfo }: IRow) => {
  const { t } = useTranslation();
  const { toLocale } = useLocalisation()
  const theme = useTheme();

  return (
    <FlexRow>
      <Col highlight={sortKey === 'buildingName'}>
        <Field>
          {space.buildingName}
        </Field>
      </Col>
      <Col highlight={sortKey === 'spaceName'}>
        <Field>
          {space.spaceName}
        </Field>
      </Col>
      <Col highlight={sortKey === 'spaceType'}>
        <Field>
          {SpaceType_DisplayString(space.spaceType)}
        </Field>
      </Col>
      {showClusterInfo &&
        <>
          <Col highlight={sortKey === 'clusterName'}>
            <Field>
              {space.clusterName}
            </Field>
          </Col>

          <Col highlight={sortKey === 'bedroomsInCluster'}>
            <Field>
              {space.bedroomsInCluster}
            </Field>
          </Col>
        </>
      }
      <Col highlight={sortKey === 'occupied'} style={{ width: '100px' }}>
        <Field style={{ display: 'flex', alignItems: 'center' }}>
          <ColoredCircle color={space.occupied ? theme.palette.green : theme.palette.red} />

          {space.occupied ? t('ESG.Let', { ns: 'molecules' }) : t('ESG.Unlet', { ns: 'molecules' })}
        </Field>
      </Col>
      <Col highlight={sortKey === 'area'} style={{ width: '100px' }}>
        <Field>
          {toLocale(AltUnits.Area, space.spaceArea ?? 0, { round: 2 })}
        </Field>
      </Col>

      {space.esgMeasurements.map((metric, j) => (
        <Col key={j} highlight={sortKey === metric.esgMetricType}>
          <RowValue
            metricType={metric.esgMetricType}
            value={metric.value}
            maxValues={maxValues}
            showConditionalFormatting={showConditionalFormatting}
          />
        </Col>
      ))}
    </FlexRow>
  );
};

export default Row;

const FlexRow = styled.div`
  display: flex;
  align-items: stretch;
  width: max-content;
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};

  &:nth-child(odd) {
    background-color: ${p => p.theme.palette.tables.evenRow};
  }

  &:nth-child(even) {
    background-color: ${p => p.theme.palette.tables.unevenRow};
  }
`

const Col = styled.div<{ highlight: boolean }>`
  width: 130px;
  flex-shrink: 0;
  background-color: ${p => p.highlight ? p.theme.palette.tables.backgroundHighlight : 'unset'};
  border-width: 0 1px 1px 0;
  border-right: 1px solid ${p => transparentize(0.5, p.theme.palette.borders.weak)};

  &:last-child {
    border-right: none;
  }
`;

const Field = styled.div`
  position: relative;
  padding: 12px 10px;

  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColoredCircle = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  outline: 2px solid ${p => transparentize(0.5, p.color)};
  background-color: ${p => transparentize(0, p.color)};
  margin-right: 7px;
`;