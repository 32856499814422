import DevicesByStatusList from '@dashboard/components/molecules/SiteOverviewV2/LiveUpdates/Devices/DevicesByStatusList';
import SpacesByTemperatureList from '@dashboard/components/molecules/SiteOverviewV2/LiveUpdates/Temperature/SpacesByTemperatureList';
import UnoccupiedSpacesList from '@dashboard/components/molecules/SiteOverviewV2/LiveUpdates/UnoccupiedSpaces/UnoccupiedSpacesList';
import SitePage_Overview from '@dashboard/components/organisms/SitePage/SitePage_Overview';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Route } from '@shared/types/Route';

export const SiteOverviewRoutes: Route[] = [
  {
    path: 'overview',
    element: SitePage_Overview,
    analytics: { page: 'overview', category: 'overview' },
    link: {
      label: 'Overview',
      path: 'overview',
      icon: regular('house'),
      analytics: { action: 'overview', category: 'feature_navigation' }
    }
  },
  {
    path: 'overview/devices-status/:status',
    analytics: { page: 'devices_status', category: 'overview' },
    element: DevicesByStatusList
  },
  {
    path: 'overview/spaces/:status',
    analytics: { page: 'temperature_widget_spaces', category: 'overview' },
    element: SpacesByTemperatureList
  },
  {
    path: 'overview/unoccupied-spaces/:status',
    analytics: { page: 'unoccupied_widget_spaces', category: 'overview' },
    element: UnoccupiedSpacesList
  }
];