import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import styled, { useTheme } from 'styled-components';

interface IDeviceNoteAttachment {
  fileName: string,
  onDelete: () => void,
  onDownload: () => void
}

const DeviceNoteAttachment = ({ fileName, onDelete, onDownload }: IDeviceNoteAttachment) => {
  const theme = useTheme();

  return (
    <AttachmentContainer>
      <FontAwesomeIcon icon={regular('file')} style={{ fontSize: '18px' }} color={theme.palette.primary} />

      <FileName>
        {fileName}
      </FileName>

      <ActionsContainer>
        <Button
          tertiary
          circle
          label={<FontAwesomeIcon icon={solid('file-arrow-down')} style={{ width: '17px', height: '17px' }} />}
          onClick={onDownload}
          style={{ padding: '7px' }}
        />
        <Button
          tertiary
          circle
          label={<FontAwesomeIcon icon={solid('trash-xmark')} style={{ width: '17px', height: '17px' }} />}
          onClick={onDelete}
          color={theme.palette.red}
          style={{ padding: '7px' }}
        />
      </ActionsContainer>
    </AttachmentContainer>
  );
}

export default DeviceNoteAttachment;

const AttachmentContainer = styled.div`
	display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px 5px 15px;
  box-shadow: 0 1px 0px 0px ${p => p.theme.palette.shadows.medium};
	border: 1px solid ${p => p.theme.palette.borders.weak};
  border-radius: 10px;
`;

const ActionsContainer = styled.div`
	display: flex;
	margin-left: auto;
`;

const FileName = styled.div`
	font-size: 14px;

	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;