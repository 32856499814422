import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { IProtected } from './Protected.types';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useCallback } from 'react';


export const Protected = (props: IProtected): JSX.Element | null => {
  const { hasPermission, hasRole } = useUserContext();
  const { hasFeatureFlag } = useTenantContext();

  const isAuthorised = useCallback(() => {
    return hasPermission(props.requirePermission) && hasRole(props.requireRole);
  }, [props, hasPermission, hasRole]);

  const isFeatureEnabled = useCallback(() => {
    return hasFeatureFlag(props.requireFeatureFlag);
  }, [props, hasFeatureFlag]);


  if (isAuthorised() && isFeatureEnabled()) {
    return props.children ?? null;
  }

  return props.onUnauthorized ?? null;
};

Protected.defaultProps = {
  onUnauthorized: null
};
