import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import { TRVControlMode } from '@shared/api/enums/TRVControlMode/TRVControlMode';
import { Checkbox } from '@shared/components/atoms/Checkbox/Checkbox';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import ClimateControlSlider from '../../ClimateControlSlider';
import { climateControlSliderGradientSteps } from '../../ClimateControlUtils';
import { ExceptionForm } from '../types/ExceptionForm';

const ExceptionsForm = () => {
  const { t } = useTranslation();
  const { localisation } = useLocalisation();
  const { setValue, watch, clearErrors, formState: { errors } } = useFormContext<ExceptionForm>();
  const theme = useTheme();
  const minTemp = watch('minTemp');
  const maxTemp = watch('maxTemp');
  const targetTemp = watch('targetTemp');
  const endDate = watch('endDate');
  const showEndDate = watch('showEndDate');
  const controlMode = watch('controlMode');
  const externalSensorModeEnabled = watch('externalSensorModeEnabled');

  return (
    <>
      <ClimateControlSlider
        minTemp={minTemp}
        maxTemp={maxTemp}
        targetTemp={targetTemp}
        minTempFormId={'minTemp'}
        maxTempFormId={'maxTemp'}
        targetTempFormId={'targetTemp'}
        climateControlSliderGradientSteps={climateControlSliderGradientSteps}
      />
      {externalSensorModeEnabled &&
        <StyledFlexColumn>
          <CheckboxWrapper>
            <Checkbox
              checked={controlMode === TRVControlMode.External}
              onChange={checked => setValue('controlMode', checked ? TRVControlMode.External : TRVControlMode.TRV)}
              label={t('ClimateControl.ExceptionExternalSensor', { ns: 'molecules' })}
              wrapperStyle={{ alignItems: 'flex-start' }}
              labelStyle={{ fontSize: 12, fontWeight: 400 }}
            />
          </CheckboxWrapper>
          <CheckboxSubLabel style={{ paddingLeft: 25 }}>
            {t('ClimateControl.ExceptionExternalSensorDesc', { ns: 'molecules' })}
          </CheckboxSubLabel>
        </StyledFlexColumn>
      }
      <StyledFlexColumn>
        <CheckboxWrapper>
          <Checkbox
            checked={!!showEndDate}
            onChange={checked => {
              setValue('showEndDate', checked);
              !checked && setValue('endDate', undefined)
            }}
            label={t('ClimateControl.SetEndDate', { ns: 'molecules' })}
            labelStyle={{ fontSize: 12, fontWeight: 400 }}
          />
        </CheckboxWrapper>
        <CheckboxSubLabel>
          {t('ClimateControl.EndDateHelpText', { ns: 'molecules' })}
        </CheckboxSubLabel>

        {showEndDate &&
          <StyledFlexColumn style={{ gap: 5 }}>
            <DatePicker
              value={endDate}
              format={localisation.dateFormats.datepickerAlternative}
              minDate={dayjs().add(1, 'day')}
              onChange={(date) => {
                if (date) {
                  clearErrors('endDate');
                  setValue('endDate', date)
                } else {
                  setValue('endDate', undefined)
                }
              }}
            />
            {errors.endDate &&
              <span style={{
                color: theme.error.main,
                fontSize: 12
              }}>
                {t('RequiredField', { ns: 'validation' })}
              </span>
            }
          </StyledFlexColumn>
        }
      </StyledFlexColumn>
    </>
  )
}

export default ExceptionsForm;

const StyledFlexColumn = styled(FlexColumn)`
  gap: 10px;
  max-width: 400px;
  position: relative;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const CheckboxSubLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
`;