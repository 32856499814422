/* eslint-disable  @typescript-eslint/no-explicit-any */

import { AccountInfo, AuthenticationResult, IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export const SelectedTenantKey = 'CurrentTenantId';

export interface IAuthConfig {
  msalInstance: IPublicClientApplication;
  loginRequest: RedirectRequest;
  passwordResetRequest: RedirectRequest;
  apiBaseUrl: string;
  operatorApiBaseUrl: string;
}

export type IdTokenClaims = {
  role: string;
  permissions: string[];
  tenants: string[];
};

export const getActiveAccount = (msalInstance: IPublicClientApplication): AccountInfo => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  return account;
};

export const getClaims = (msalInstance: IPublicClientApplication): IdTokenClaims => {
  const account = getActiveAccount(msalInstance);
  return account.idTokenClaims as IdTokenClaims;
};

export const currentSelectedTenant = (msalInstance: IPublicClientApplication): string => {
  const claims = getClaims(msalInstance);
  const selectedTenant = localStorage.getItem(SelectedTenantKey);

  if (selectedTenant == null || claims.tenants.indexOf(selectedTenant) === -1) {
    const currentTenantId = claims.tenants[0];

    localStorage.setItem(SelectedTenantKey, currentTenantId);
    
    return currentTenantId;
  }

  return selectedTenant;
};

const acquireTokenSilent = async (authConfig: IAuthConfig): Promise<AuthenticationResult | null> => {
  const account = getActiveAccount(authConfig.msalInstance);

  try {
    const response = await authConfig.msalInstance.acquireTokenSilent({
      ...authConfig.loginRequest,
      account: account
    });

    return response;
  } catch (err) {
    console.log(err);
    await authConfig.msalInstance.acquireTokenRedirect(authConfig.loginRequest);
  }

  return null;
};

export async function createConfig(method: Method, authConfig: IAuthConfig, controller?: AbortController, contentType?: string): Promise<AxiosRequestConfig> {
  const tenantId = currentSelectedTenant(authConfig.msalInstance);
  const response = await acquireTokenSilent(authConfig);

  const options: AxiosRequestConfig = {
    signal: controller?.signal,
    method: method,
    headers: {
      TenantId: tenantId,
      Authorization: `Bearer ${response?.accessToken}`,
      'Content-Type': contentType ?? 'application/json'
    }
  };

  return options;
}

export async function callGet(targetUrl: string, authConfig: IAuthConfig, controller?: AbortController): Promise<AxiosResponse> {
  const config = await createConfig('GET', authConfig, controller);

  return await axios.get(targetUrl, config);
}

export async function callPost(targetUrl: string, data: any, authConfig: IAuthConfig, controller?: AbortController): Promise<AxiosResponse> {
  const config = await createConfig('POST', authConfig, controller);

  return await axios.post(targetUrl, data, config);
}

export async function callPatch(targetUrl: string, data: any, authConfig: IAuthConfig, controller?: AbortController): Promise<AxiosResponse> {
  const config = await createConfig('PATCH', authConfig, controller);

  return await axios.patch(targetUrl, data, config);
}

export async function callDelete(targetUrl: string, data: any, authConfig: IAuthConfig, controller?: AbortController): Promise<AxiosResponse> {
  const config = await createConfig('DELETE', authConfig, controller);
  config.data = data;

  return await axios.delete(targetUrl, config);
}

export async function callPostFormData(targetUrl: string, formData: FormData, authConfig: IAuthConfig, controller?: AbortController): Promise<AxiosResponse> {
  const config = await createConfig('POST', authConfig, controller, 'multipart/form-data');

  return await axios.post(targetUrl, formData, config);
}
