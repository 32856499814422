import { EsgExplorerConfiguration } from '@dashboard/api/models/EsgExplorerConfiguration';
import { Building } from '@shared/api/models/Building/Building';
import { CheckboxSelect } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect';
import { ICheckboxSelectGroup } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect.types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IBuildingSelect {
  buildings: Building[];
  config: EsgExplorerConfiguration;
  onChange: (config: EsgExplorerConfiguration) => void;
}

const BuildingSelect = ({ buildings, config, onChange }: IBuildingSelect) => {
  const { t } = useTranslation(['molecules']);
  const [buildingOptions, setBuildingOptions] = useState<ICheckboxSelectGroup<number>[]>([]);

  useEffect(() => {
    setBuildingOptions([{
      options: buildings.map(building => ({
        label: building.name,
        value: building.id,
        selected: config.buildingIds.includes(building.id)
      }))
    }]);
  }, [config, buildings]);

  return (
    <CheckboxSelect
      options={buildingOptions}
      onChange={(values) =>
        onChange({
          ...config,
          buildingIds: values
        })
      }
      placeholder={t('ESG.Buildings', { ns: 'molecules' })}
    />
  );
};

export default BuildingSelect;