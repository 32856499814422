import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApiState } from '@shared/hooks/useApiState';
import { getWeatherObject } from '@shared/utils/WeatherUtils';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import PerformanceCard from './PerformanceCard';
import SkeletonLoader from './SkeletonLoader';
import CurrentWeatherGetBySiteIdQuery from '@shared/api/queries/CurrentWeather/CurrentWeatherGetBySiteIdQuery';

type PropTypes = {
  siteId: number;
}

const PerformanceCardWeather = ({ siteId }: PropTypes) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { toLocale, getUnit } = useLocalisation();
  const { data, loading } = useApiState({
    query: new CurrentWeatherGetBySiteIdQuery(siteId)
  }, [siteId]);

  const unit = getUnit(MetricType.Temperature);
  const weatherObject = getWeatherObject(t, theme, data?.weatherCode);

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <PerformanceCard
      label={t('ClimateControl.PerformanceCards.Weather', { ns: 'molecules', weather: weatherObject?.description })}
      unit={unit}
      value={data?.temperature && toLocale(MetricType.Temperature, data.temperature.value, { round: 1 })}
      error={!data}
      errorMessage={t('ClimateControl.PerformanceCards.WeatherError', { ns: 'molecules' })}
      icon={weatherObject?.icon}
      iconColor={weatherObject?.colors}
    />
  )
}

export default PerformanceCardWeather;