import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { useFileHandler } from '@shared/hooks/useFileHandler';
import DatabaseConnectionsGetAllQuery from '@settings/api/queries/DatabaseConnections/DatabaseConnectionsGetAllQuery';
import TenantCreateCommand from '@settings/api/queries/Tenants/TenantCreateCommand';
import TenantLogoUploadCommand from '@settings/api/queries/Tenants/TenantLogoUploadCommand';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import { BlobFileUploadArea } from '@shared/components/molecules/BlobFileUploadArea/BlobFileUploadArea';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { Select } from '@shared/components/atoms/Select/Select';
import { Button } from '@shared/components/atoms/Button/Button';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';

type FormValues = {
  name: string,
  email: string,
  databaseConnection: { label: string, value: number },
  operatorLogoId: string
}

const TenantCreate = () => {
  const { t } = useTranslation(['settingsTenant']);
  const navigate = useNavigate();
  const { execute } = useApi();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { file, fileName, fileHasChanged, handleFileChange, handleFileDelete } = useFileHandler()
  const { register, handleSubmit, control, formState: { errors, isDirty } } = useForm<FormValues>();
  const { transform: databaseOptions } = useApiState({
    query: new DatabaseConnectionsGetAllQuery(),
    transformFunc: dbConnections => dbConnections.map(x => ({ label: x.name, value: x.id })),
    initialState: []
  }, []);

  const onSave = async (formValues: FormValues) => {
    setSavingInProgress(true);

    const tenantDto = await execute({
      query: new TenantCreateCommand(formValues.name, formValues.email, formValues.databaseConnection.value),
      successMessage: t('Create.TenantCreated', { ns: 'settingsTenant' }),
      errorMessage: t('Create.TenantCreateFailed', { ns: 'settingsTenant' })
    });

    if (tenantDto && fileHasChanged && file) {
      await execute({
        query: new TenantLogoUploadCommand(file, tenantDto.tenantId),
        errorMessage: t('Create.UploadLogoFailed', { ns: 'settingsTenant' })
      });
    }

    navigate('..');
  };

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('BackToTenants', { ns: 'navigation' })}
          url='./..'
        />

        <Card cardTitle={t('Create.CreateTenant', { ns: 'settingsTenant' })}>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <Label>{t('Create.Name', { ns: 'settingsTenant' })}</Label>
                  <Input
                    {...register('name', {
                      required: t('Create.RequiredField', { ns: 'settingsTenant' }),
                      minLength: { value: 3, message: t('Create.ValidationMin', { ns: 'settingsTenant' }) }
                    })}
                  />
                  <ErrorMessage>{errors.name?.message}</ErrorMessage>
                </div>
                <div className="col-md-6">
                  <Label>{t('Create.ContactEmail', { ns: 'settingsTenant' })}</Label>
                  <Input
                    {...register('email', {
                      required: t('Create.RequiredField', { ns: 'settingsTenant' }),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t('Create.EmailValidation', { ns: 'settingsTenant' })
                      }
                    })}
                  />
                  <ErrorMessage>{errors.email?.message}</ErrorMessage>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Label>{t('Create.Logo', { ns: 'settingsTenant' })}</Label>
                  <BlobFileUploadArea
                    blobName={fileName}
                    mainText={t('Create.UploadImage', { ns: 'settingsTenant' })}
                    dimText={'JPEG, PNG, SVG'}
                    acceptedTypes={['image/*']}
                    onFileChange={handleFileChange}
                    onFileDelete={handleFileDelete}
                    blobContainer={BlobStorageContainerType.Shared}
                    containerStyle={{ width: '200px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    imageStyle={{ width: 'auto', maxWidth: '200px', height: 'auto', maxHeight: '100px', padding: '10px' }}
                  />
                </div>
                <div className="col-md-6">
                  <Label>{t('DatabaseConnection', { ns: 'common' })}</Label>
                  <Controller
                    control={control}
                    name="databaseConnection"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        isSearchable={false}
                        onChange={onChange}
                        options={databaseOptions}
                        innerRef={ref}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Form>
        </Card>
        <Card>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <Button
                  label={t('Create.Save', { ns: 'settingsTenant' })}
                  onClick={handleSubmit(onSave)}
                  disabled={!isDirty || !isEmpty(errors)}
                  loading={savingInProgress}
                />
              </div>
              <Caption>
                <WarningIcon icon={solid('exclamation-triangle')} />
                {t('Create.WarningText', { ns: 'settingsTenant' })}
              </Caption>
            </div>
          </div>
        </Card>
      </Container>
    </PaddedContainer>
  );
};

export default TenantCreate;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;

const Caption = styled.div`
  font-size: 12px;
  padding: 20px 0 0 15px;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
  color: ${p => p.theme.palette.orange};
`;