import styled from 'styled-components';
import useCumulativeChart from './useCumulativeChart';
import ChartLegend from './Legend/ChartLegend';
import { useSize } from '@shared/hooks/useSize';
import { getChartHeight } from '../MonthlyChart/MonthlyChart';
import { UtilityData } from '../../UtilitiesSummary';
import UnitToggle from '../UnitToggle';

type PropTypes = {
  data: UtilityData;
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const CumulativeChart = ({ data, conversions, onSetConversions }: PropTypes) => {
  const { canvasRef, datasets, handleLegendChange } = useCumulativeChart({ data });
  const { width } = useSize(canvasRef);

  return (
    <Wrapper>
      {data.hasConversion
        ? <UnitToggle
          metricType={data.metricType}
          conversions={conversions}
          onChange={onSetConversions}
        />
        : <YAxisLabel>{data.unit}</YAxisLabel>
      }

      <ChartContainer width={width}>
        <canvas id="utilityCumulativeChart" ref={canvasRef} />
      </ChartContainer>

      <ChartLegend
        datasets={datasets}
        onChange={handleLegendChange}
      />
    </Wrapper>
  );
};

export default CumulativeChart;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ChartContainer = styled.div<{ width?: number }>`
  position: relative;
  height: ${({ width }) => getChartHeight(width)};
  width: 100%;
  margin-bottom: 25px;
  margin-left: -10px;
  transition: height 300ms ease;
`;

const YAxisLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.secondary};
`;