import { UseFormReturn } from 'react-hook-form';
import { PushNotificationForm, RecipientType } from './PushNotificationsModal';
import styled from 'styled-components';
import LabelWithCharacterCount from '@dashboard/components/molecules/ResidentApp/Common/LabelWithCharacterCount';
import InputFormField from '@dashboard/components/molecules/ResidentApp/Common/InputFormField';
import { TextArea } from '@shared/components/atoms/Form/Form';
import { useTranslation } from 'react-i18next';
import ResidentAppFormErrorMessage from '@dashboard/components/molecules/ResidentApp/Common/ResidentAppFormErrorMessage';
import Label from '@dashboard/components/molecules/ResidentApp/Common/Label';
import RadioInputFormField from '@dashboard/components/molecules/ResidentApp/Common/RadioInputFormField';
import { HierarchyBuilding } from '@shared/api/models/Hierarchy/Hierarchy';
import SpaceLookup from '@shared/components/molecules/SpaceLookup/SpaceLookup';

type PushNotificationsFormProps = {
  methods: UseFormReturn<PushNotificationForm>;
  buildings?: HierarchyBuilding[];
}

const PushNotificationsForm = ({methods, buildings}: PushNotificationsFormProps) => {
  const { t } = useTranslation('molecules');
  
  const onUpdateSpaces = (spaceIds: number[]) => {
    // rempve errpr if spaces are selected
    if (spaceIds.length > 0) {
      methods.clearErrors('spaces');
    }

    methods.setValue('spaces', spaceIds);
  };

  return (
    <NotificationsForm>
      <RecipientsContainer>
        <Label text={t('ResidentApp.Recipients')} />
        <div>
          <RadioInputFormField
            name="recipientType"
            id="allRecipients"
            label={t('All', {ns: 'common'})}
          />
        </div>
        <RadioInputFormField
          name="recipientType"
          id="selectedRecipients"
          label={t('ResidentApp.SelectedSpaces')}
        />
      </RecipientsContainer>

      {methods.watch('recipientType') === RecipientType.SelectedSpaces && buildings && (
        <div>
          <Label text={t('ResidentApp.SelectSpaces')} marginBottom />
          <SpaceLookup
            buildings={buildings}
            onChange={(spaceIds) => onUpdateSpaces(spaceIds)}
            resetPulse={true}
            placeHolder={t('ResidentApp.SearchForASpace')}
          />
          {methods.formState.errors.spaces && (
            <ResidentAppFormErrorMessage
              message={methods.formState.errors.spaces?.message}
            />
          )}
        </div>
      )}
      <div>
        <LabelWithCharacterCount
          inputName="title"
          label={t('ResidentApp.NotificationTitle')}
          currentCharacterCount={methods.watch('title')?.length ?? 0}
          characterLimit={60}
        />
        <InputFormField 
          inputName='title'
          maxLength={60}
          isRequired
          error={!!methods.formState.errors.title}
        />
        {methods.formState.errors.title && (
          <ResidentAppFormErrorMessage
            message={methods.formState.errors.title?.message}
          />
        )}
      </div>
      <div>
        <LabelWithCharacterCount
          inputName="text"
          label={t('ResidentApp.NotificationText')}
          currentCharacterCount={methods.watch('text')?.length ?? 0}
          characterLimit={150}
        />
        <TextArea
          id="text"
          rows={5}
          {...methods.register('text', {
            maxLength: {
              value: 150,
              message: t('InvalidLength', { ns: 'validation', maxLength: 150 }),
            },
            required: t('ChallengeDescriptionRequired', { ns: 'validation' }),
          })}
        />
      </div>
    </NotificationsForm>
  )
};

export default PushNotificationsForm;

const NotificationsForm = styled.form`
  margin-top: 24px;
  display: grid;
  gap: 24px;
`;

const RecipientsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;