import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feature } from '@shared/api/models/Feature/Feature';
import { Tenant } from '@shared/api/models/Tenant/Tenant';
import { TenantFeatureCreateCommand } from '@shared/api/queries/Features/TenantFeatureCreateCommand';
import { TenantFeatureDeleteCommand } from '@shared/api/queries/Features/TenantFeatureDeleteCommand';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { Card } from '@shared/components/molecules/Card/Card';
import { useApi } from '@shared/hooks/useApi';
import { transparentize } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { featureIsEnabled } from '../FeatureList/FeatureTenantListItem';
import { useTranslation } from 'react-i18next';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useModal } from '@shared/hooks/useModal';

interface ITenantFeatureListItem {
  feature: Feature;
  tenant: Tenant;
  refresh: () => void;
}

const TenantFeatureListItem = ({ feature, tenant, refresh }: ITenantFeatureListItem) => {
  const theme = useTheme();
  const { t } = useTranslation(['settingsFeatures']);
  const { execute, loading } = useApi();
  const [isChecked, setIsChecked] = useState<boolean>(featureIsEnabled(tenant, feature));
  const { isOpen: dialogIsOpen, toggle, ref } = useModal({});

  useEffect(() => {
    setIsChecked(featureIsEnabled(tenant, feature));
  }, [tenant, feature]);

  const handleToggle = useCallback(async (isChecked: boolean) => {
    setIsChecked(!isChecked);

    if (!isChecked && !featureIsEnabled(tenant, feature)) {
      await execute({
        query: new TenantFeatureCreateCommand(tenant.tenantId, feature.id),
        successMessage: t('FeatureEnabled', { ns: 'settingsFeatures' })
      });
    } else {
      await execute({
        query: new TenantFeatureDeleteCommand(tenant.tenantId, feature.id),
        successMessage: t('FeatureDisabled', { ns: 'settingsFeatures' })
      });
    }

    refresh();
  }, [tenant, feature, execute, refresh, t]);

  return (
    <Wrapper>
      <WarningDialog
        modalRef={ref}
        isOpen={dialogIsOpen}
        sectionOne={!isChecked ?
          t('EnableFeatureForTenant', { ns: 'settingsFeatures', feature: feature.name, tenant: tenant.name }) :
          t('DisableFeatureForTenant', { ns: 'settingsFeatures', feature: feature.name, tenant: tenant.name })
        }
        sectionTwo={t('VisibleToCustomers', { ns: 'settingsFeatures' })}
        confirmButtonColor={!isChecked ? theme.palette.primary : theme.palette.red}
        confirmButton={!isChecked ? t('Enable', { ns: 'settingsFeatures' }) : t('Disable', { ns: 'settingsFeatures' })}
        onCancel={toggle}
        onConfirm={() => {
          handleToggle(isChecked);
          toggle()
        }}
      />

      <Card noPadding noMargin>
        <FlexRow>
          <FlagIcon icon={solid('flag')} />

          <Name>
            {feature.name}
          </Name>

          <Description>
            {feature.description}
          </Description>

          <div style={{ marginLeft: 'auto' }}>
            <Switch
              checked={isChecked}
              onChange={toggle}
              colorChecked='#43c3a1'
              labelChecked={<TickIcon icon={solid('check')} />}
              isLoading={loading}
            />
          </div>
        </FlexRow>
      </Card>
    </Wrapper>
  );
};

export default TenantFeatureListItem;

const Wrapper = styled.div`
  padding: 0 15px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 12px;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  padding: 12px 0;

  @media (min-width: 600px) {
    flex-shrink: 0;
    width: 150px;
  }

  @media (min-width: 900px) {
    width: 250px;
  }
`;

const Description = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.fair};
  padding: 12px 0;
`;

const TickIcon = styled(FontAwesomeIcon)`
  margin-right: 2px;
`;

const FlagIcon = styled(FontAwesomeIcon)`
  width: 10px;
  height: 10px;
  padding: 6px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.fair};
  cursor: pointer;
  transition: all 150ms ease;
  flex-shrink: 0;

  color: ${p => transparentize(0.3, p.theme.palette.primary)};
  background-color: ${p => transparentize(0.3, p.theme.palette.buttons.hoverBackground)};
`;