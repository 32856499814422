import { EsgExplorerFilter, EsgExplorerFilterType } from '@dashboard/api/models/EsgExplorerConfiguration';
import { Select } from '@shared/components/atoms/Select/Select';
import { lowerCase, upperFirst } from 'lodash';
import FilterTypeConfig from './FilterTypeConfig';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { TOutlierConfig } from './OutlierWidget';

type PropTypes = {
  config: TOutlierConfig;
  onChange: (filter: EsgExplorerFilter) => void;
}

const Filter = ({ config, onChange }: PropTypes) => {
  const { trackAction } = useAnalytics();
  const filter = config.filter;

  return (
    <>
      {filter &&
        <div style={{ width: 220 }}>
          <Select
            value={filter.type ? { label: upperFirst(lowerCase(filter.type)), value: filter.type } : null}
            options={Object.values(EsgExplorerFilterType).map(x => ({ label: upperFirst(lowerCase(x)), value: x }))}
            onChange={option => {
              if (!option) {
                return;
              }

              onChange({ metric: filter.metric, type: option.value });
              trackAction('filter_select', 'overview_outliers', { metric: option.value, time_span: config.timeRangeType, filter: config.filter?.type });
            }}
            menuHeight={250}
          />
        </div>
      }

      {filter?.type &&
        <FilterTypeConfig
          filter={filter}
          onChange={filter => onChange(filter)}
        />
      }
    </>
  );
};

export default Filter;