import { SiteMetadataDto, SiteMetadataProperties } from '@shared/api/models/SiteMetadataV2/SiteMetadataDto';
import { PostQuery } from '../common/PostQuery';

export default class SiteMetadataV2CreateOrUpdateCommand extends PostQuery<SiteMetadataDto> {
  siteId: number;
  metadata: SiteMetadataProperties;

  constructor(siteId: number, metadata: SiteMetadataProperties) {
    super();
    this.siteId = siteId;
    this.metadata = metadata;
  }

  public targetUrl(): string {
    return '/siteMetadataV2';
  }
}
