import { RefObject, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import styled, { css, useTheme } from 'styled-components';
import { UseFieldArrayAppend, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FloorsGetallQuery from '@dashboard/api/queries/floor/FloorsGetAllQuery';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { Building } from '@shared/api/models/Building/Building';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { Space } from '@shared/api/models/Space/Space';
import { Card } from '@shared/components/molecules/Card/Card';
import { Button } from '@shared/components/atoms/Button/Button';
import { RouterPrompt } from '@shared/components/navigation/RouterPrompt/RouterPrompt';
import { toast } from 'react-toastify';
import { Floor } from '@shared/api/models/Floor/Floor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { useModal } from '@shared/hooks/useModal';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import ClusterEditRow from './ClusterEditRow';
import SpaceEditRow from './SpaceEditRow';
import BulkSpaceCreateCommand from '@settings/api/queries/Spaces/BulkSpaceCreateCommand';
import { Cluster } from '@shared/api/models/Space/Cluster';
import SpacesGetAllWithClustersQuery from '@dashboard/api/queries/space/SpacesGetAllWithClusterQuery';
import SpaceBulkUpdateCommand from '@settings/api/queries/Spaces/SpaceBulkUpdateCommand';
import SpaceBulkDeleteCommand from '@settings/api/queries/Spaces/SpaceBulkDeleteCommand';
import { getClustersAndSpacesByRequestType, isModified, sortClusters, sortSpaces } from '../SpaceUtils';
import { ClustersAndSpaces } from '@shared/api/models/Space/ClustersAndSpaces';
import { Label } from '@shared/components/atoms/Form/Form';

export type SpaceFormValues = {
  id?: number,
  name: string,
  occupied: boolean,
  toBeDeleted?: boolean,
  toBeCreated?: boolean,
  spaceType?: SpaceType,
  maxOccupancy?: number,
  area?: number
};

export type ClusterFormValues = SpaceFormValues & {
  spaces?: SpaceFormValues[];
  primarySpaceId?: number;
  clusterName?: string;
};

export type SpacesFormValues = {
  spacesFieldArray: SpaceFormValues[];
  clusters: ClusterFormValues[]
};

export enum SpaceRequestType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

interface IBuildingSpaceList {
  building: Building;
}

const BuildingSpaceList = ({ building }: IBuildingSpaceList) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const [editMode, setEditMode] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [floor, setFloor] = useState<Floor>();
  const { isOpen: isClusterModalOpen, toggle: toggleClusterModal, ref: enableClusterModal } = useModal({ disableCloseOnClickOutside: true });

  /**
   * Get a list of all floors
   */
  const { data: floors, loading: loadingFloors } = useApiState(
    {
      query: new FloorsGetallQuery(building.id),
      initialState: [],
    },
    [building]
  );

  /**
   * Get all spaces for the selected floor
   */
  const { data: clusterAndSpaces, loading: loadingSpaces, execute: fetchSpaces } = useApiState({
    query: floor && new SpacesGetAllWithClustersQuery(floor.id)
  }, [floors, floor]);

  const { register, handleSubmit, control, formState, reset, setValue } = useForm<SpacesFormValues>();
  const { isDirty, errors } = formState;

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'spacesFieldArray',
    keyName: 'generated-id'
  });

  const formValues = useWatch({
    control,
    name: 'spacesFieldArray'
  });

  const { fields: clustersFields, append: addCluster, update: updateCluster, remove: removeCluster } = useFieldArray({
    control,
    name: 'clusters',
    keyName: 'generated-id'
  });

  const clustersFormValues = useWatch({
    control,
    name: 'clusters'
  });

  /**
   * Select first floor by default
   */
  useEffect(() => {
    if (floors.length > 0) {
      setFloor(floors[0]);
    }
  }, [floors]);

  /**
   * Set default values for form fields
   */
  useEffect(() => {
    setEditMode(false);
    reset({
      spacesFieldArray: sortSpaces(clusterAndSpaces?.spaces.map((space: Space) => ({ id: space.id, name: space.name, maxOccupancy: space.maxOccupancy, area: space.area, spaceType: space.spaceType, occupied: space.occupied })) ?? []),
      clusters: sortClusters(clusterAndSpaces?.clusters.map((cluster: Cluster) => ({ id: cluster.id, clusterName: cluster.clusterName, spaces: cluster.spaces })) ?? [])
    });
  }, [reset, clusterAndSpaces]);

  /**
   * Add a new space if no other space exists
  */
  useEffect(() => {
    if (floor && clusterAndSpaces?.spaces.length === 0 && clusterAndSpaces?.clusters.length === 0) {
      append({ name: '', spaceType: SpaceType.OneBed, occupied: true, toBeCreated: true });
      setEditMode(true);
    }
  }, [floor, clusterAndSpaces, append]);

  const exitEditMode = () => {
    setEditMode(false);
    reset();
  };

  const handleSave = async (spacesFormValues: SpaceFormValues[] | ClusterFormValues[]) => {
    setSavingInProgress(true);

    const promises: Promise<ClustersAndSpaces | undefined>[] = [];

    // Create a new space if the property 'toBeCreated' is true
    const spacesAndClustersToBeCreated = getClustersAndSpacesByRequestType(SpaceRequestType.Create, spacesFormValues, building, floor);

    if ((spacesAndClustersToBeCreated.spaces.length > 0) || spacesAndClustersToBeCreated.clusters.length > 0) {
      promises.push(createSpace(spacesAndClustersToBeCreated.spaces, spacesAndClustersToBeCreated.clusters));
    }

    // Delete space if the property 'toBeDeleted' is true
    const spacesAndClustersToBeDeleted = getClustersAndSpacesByRequestType(SpaceRequestType.Delete, spacesFormValues, building, floor);
    const spaceIdsToBeDeleted = spacesAndClustersToBeDeleted.spaces.map((space: Space) => space.id);
    const clusterIdsToBeDeleted = spacesAndClustersToBeDeleted.clusters.map((cluster: Cluster) => cluster.id);

    if (spaceIdsToBeDeleted.length > 0 || clusterIdsToBeDeleted.length > 0) {
      promises.push(deleteSpace(spaceIdsToBeDeleted, clusterIdsToBeDeleted));
    }

    // Update space if space already exists and form fields have been modified
    const spacesAndClustersToBeUpdated = getClustersAndSpacesByRequestType(SpaceRequestType.Update, spacesFormValues, building, floor, clusterAndSpaces);

    if (spacesAndClustersToBeUpdated.spaces.length > 0 || spacesAndClustersToBeUpdated.clusters.length > 0) {
      promises.push(updateSpace(spacesAndClustersToBeUpdated.spaces, spacesAndClustersToBeUpdated.clusters));
    }

    await Promise.all(promises);
    fetchSpaces();

    setEditMode(false);
    setSavingInProgress(false);
    toast.success(t('Buildings.Spaces.SpaceManagement.ChangesSaved', { ns: 'settingsAsset' }));
  };

  const createSpace = async (spaceFormValues: Space[], cluster?: Cluster[]): Promise<ClustersAndSpaces | undefined> => {
    if (!floor) {
      return;
    }

    return await execute({
      query: new BulkSpaceCreateCommand(spaceFormValues, building.id, floor.floorNumber, cluster),
      errorMessage: t('Buildings.Spaces.SpaceManagement.CreateFailed', { ns: 'settingsAsset' }),
    })
  };

  const updateSpace = async (spaceFormValues: Space[], cluster?: Cluster[]): Promise<ClustersAndSpaces | undefined> => {
    return await execute({
      query: new SpaceBulkUpdateCommand(spaceFormValues, cluster),
      errorMessage: t('Buildings.Spaces.SpaceManagement.UpdateFailed', { ns: 'settingsAsset' }),
    });
  };

  const deleteSpace = async (spaceIds: number[], clusterIds: number[]): Promise<ClustersAndSpaces | undefined> => {
    return await execute({
      query: new SpaceBulkDeleteCommand(spaceIds, clusterIds),
      errorMessage: t('Buildings.Spaces.SpaceManagement.DeleteFailed', { ns: 'settingsAsset' }),
    });
  };

  if (!loadingFloors && floors.length === 0) {
    return (
      <NoFloorsMessage>
        You must first create a floor
        <Link to={'../floors'}>
          <ArrowIcon icon={solid('arrow-right')} />
        </Link>
      </NoFloorsMessage>
    );
  }

  return (
    <>
      {floor &&
        <>
          <RouterPrompt when={editMode} />

          <div style={{ display: 'flex', gap: '30px' }}>
            <Card cardTitle={t('Buildings.Spaces.SpaceManagement.Floor', { ns: 'settingsAsset' })} style={{ width: '78px', height: 'max-content', marginTop: '52px' }}>
              <FloorSelector>
                {floors.map((x) => (
                  <IdentifierSquare
                    key={x.id}
                    onClick={() => setFloor(x)}
                    selected={x.id === floor.id}
                  >
                    {`${x.floorNumber}`}
                  </IdentifierSquare>
                ))}
              </FloorSelector>
            </Card>

            <div style={{ width: '100%' }}>
              <TopRow>
                {editMode &&
                  <>
                    <Button
                      tertiary
                      label={t('Cancel', { ns: 'common' })}
                      onClick={() => exitEditMode()}
                      color={theme.palette.red}
                    />

                    <Button
                      tertiary
                      label={t('Buildings.Spaces.SpaceManagement.AddSpace', { ns: 'settingsAsset' })}
                      onClick={() => append({ name: '', spaceType: SpaceType.OneBed, occupied: true, toBeCreated: true })}
                    />

                    <Button
                      tertiary
                      label={t('Buildings.Spaces.SpaceManagement.AddCluster', { ns: 'settingsAsset' })}
                      onClick={() => toggleClusterModal()}
                    />

                    <Button
                      label={t('Buildings.Spaces.SpaceManagement.Save', { ns: 'settingsAsset' })}
                      onClick={handleSubmit(() => handleSave(clustersFormValues ? [...formValues, ...clustersFormValues] : formValues))}
                      disabled={!isDirty || !isEmpty(errors)}
                      loading={savingInProgress}
                    />
                  </>
                }

                {!editMode &&
                  <Button
                    label={t('Buildings.Spaces.SpaceManagement.Edit', { ns: 'settingsAsset' })}
                    onClick={() => setEditMode(true)}
                  />
                }
              </TopRow>

              <Card cardTitle={t('Buildings.Spaces.SpaceManagement.Spaces', { ns: 'settingsAsset' })} noPadding>
                <FlexRow style={{ padding: '20px 0' }}>
                  <FloorDetails>
                    <FloorName>{floor.name}</FloorName>
                    <FlexRow style={{ flexWrap: 'wrap', padding: '0 40px 20px 40px' }}>
                      <Column style={{ width: '50px' }} />
                      <Column width={25}>
                        <Label>{t('Buildings.Spaces.SpaceManagement.SpaceName', { ns: 'settingsAsset' })}</Label>
                      </Column>
                      <Column width={25}>
                        <Label>{t('Buildings.Spaces.SpaceManagement.SpaceType', { ns: 'settingsAsset' })}</Label>
                      </Column>
                      <Column width={15}>
                        <Label>{t('Buildings.Spaces.SpaceManagement.MaxOccupancy', { ns: 'settingsAsset' })}</Label>
                      </Column>
                      <Column width={15}>
                        <Label>{t('Buildings.Spaces.SpaceManagement.Area', { ns: 'settingsAsset' })}</Label>
                      </Column>
                      <Column>
                        <Label>{t('Buildings.Spaces.SpaceManagement.Occupied', { ns: 'settingsAsset' })}</Label>
                      </Column>
                      <Column>
                        <div style={{ width: '37px' }}></div>
                      </Column>
                    </FlexRow>
                    <form>
                      <div>
                        {loadingSpaces && <Loading />}
                        {!loadingSpaces && formValues.length > 0 && fields.map((field, index) => formValues[index] && (
                          <SpaceEditRow
                            formValues={formValues[index]}
                            key={field.id}
                            field={field}
                            index={index}
                            formState={formState}
                            register={register}
                            update={update}
                            remove={remove}
                            control={control}
                            editMode={editMode}
                            isModified={isModified(clusterAndSpaces?.spaces ?? [], formValues[index])}
                            isCluster={false}
                          />
                        ))}
                        {!loadingSpaces && clustersFormValues?.length > 0 &&
                          clustersFields.map((field, index) => clustersFormValues[index] && field.spaces && field.spaces?.length > 0 && (
                            <ClusterEditRow
                              clusterFormValues={clustersFormValues[index]}
                              key={field.id}
                              field={field}
                              clusterIndex={index}
                              formState={formState}
                              register={register}
                              update={updateCluster}
                              remove={removeCluster}
                              control={control}
                              editMode={editMode}
                              isClusterModified={isModified(clusterAndSpaces?.spaces ?? [], clustersFormValues[index], clusterAndSpaces?.clusters)}
                              originalClusterSpaces={clusterAndSpaces?.clusters[index]?.spaces ?? []}
                              setValue={setValue}
                            />
                          ))}
                      </div>
                    </form>
                  </FloorDetails>
                </FlexRow>
              </Card >
            </div>
          </div>
        </>
      }
      <AddClusterModal
        addCluster={addCluster}
        enableClusterModal={enableClusterModal}
        isClusterModalOpen={isClusterModalOpen}
        toggleClusterModal={toggleClusterModal}
      />
    </>
  );
};

export default BuildingSpaceList;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-bottom: 20px;
`;

const FlexRow = styled.div`
  display: flex;
`;

const FloorSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
`;

const FloorDetails = styled.div`
  width: 100%;
`;

const FloorName = styled.div`
  margin-bottom: 25px;
  padding-top: 5px;
  font-weight: 500;
  padding: 0px 40px;
`;

const IdentifierSquare = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  width: 40px;
  height: 32px;
  border: 1px solid ${(p) => p.theme.palette.borders.medium};
  border-radius: 3px;

  font-weight: 500;
  font-size: 14px;
  color: ${(p) => p.theme.palette.text.weak};
  cursor: pointer;

  ${p => p.selected && css`
    color: ${p => p.theme.palette.text.onPrimary};
    border-color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.primary};
    box-shadow: 0 7px 10px -4px rgba(0, 0, 0, 0.2);
  `}
`;

const Column = styled.div<{ width?: number }>`
  padding: 2px 0;    
  width: 100%;
  
  @media (min-width: 1300px) {
    width: ${p => p.width !== undefined ? `${p.width}%` : 'auto'};
    padding: 0;

    &:not(:first-child) {
      padding-left: 10px;
    }
  
    &:not(:last-child) {
      padding-right: 10px;
    }
  }
`;

export const NoFloorsMessage = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  width: max-content;
  margin: 40px auto 0;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  padding: 4px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.onPrimary};
  background-color: ${p => p.theme.palette.primary};
  cursor: pointer;

  transition: all 150ms ease;

  &:hover {
    box-shadow: 0px 2px 4px 0px ${p => p.theme.palette.shadows.strong};
  }
`;

//#region AddClusterModal component
type AddClusterModalProps = {
  addCluster: UseFieldArrayAppend<SpacesFormValues, 'clusters'>;
  isClusterModalOpen: boolean;
  toggleClusterModal: () => void;
  enableClusterModal: RefObject<HTMLDivElement>;
};

const AddClusterModal = ({ addCluster, isClusterModalOpen, toggleClusterModal, enableClusterModal }: AddClusterModalProps) => {
  const [clusterName, setClusterName] = useState<string>('');
  const [clusterBedroomNumber, setClusterBedroomNumber] = useState<number>(0);
  const { t } = useTranslation();

  return (
    <InteractiveDialog
      modalRef={enableClusterModal}
      isOpen={isClusterModalOpen}
      hide={toggleClusterModal}
      title={'Add Cluster'}
      confirmButton={{
        label: t('Save', { ns: 'common' }),
        onClick: (() => {
          const spaceFormValues: SpaceFormValues[] = []
          for (let index = 0; index < clusterBedroomNumber; index++) {
            spaceFormValues.push({ name: '', spaceType: SpaceType.Bedroom, occupied: true, toBeCreated: true })
          }

          addCluster({ name: clusterName, occupied: true, toBeCreated: true, spaces: spaceFormValues, maxOccupancy: 0 })

          setClusterName('')
          setClusterBedroomNumber(0)
          toggleClusterModal()
        }),
        disabled: clusterBedroomNumber === 0 || clusterName === ''
      }}
      cancelButton={{
        label: t('Cancel', { ns: 'common' }),
        onClick: (() => {
          toggleClusterModal()
          setClusterName('')
          setClusterBedroomNumber(0)
        }),
      }}
      content={
        <>
          <InputContainer>
            <Label style={{ width: '35%', flex: '35%' }}>{t('Buildings.Spaces.SpaceManagement.ClusterName', { ns: 'settingsAsset' })}</Label>
            <Input value={clusterName} onChange={(e) => setClusterName(e.target.value)} />
          </InputContainer>
          <InputContainer>
            <Label style={{ width: '35%', flex: '35%' }}>{t('Buildings.Spaces.SpaceManagement.NumberOfBedrooms', { ns: 'settingsAsset' })}</Label>
            <Input type='number' min={0} value={clusterBedroomNumber} onChange={(e) => setClusterBedroomNumber(parseInt(e.target.value))} />
          </InputContainer>
        </>
      }
    />
  );
}

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

export const Input = styled.input`
  display: block;
  height: 38px;
  padding-left: 10px;
  width: 65%;
  flex: 65%;
   
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  color: ${(p) => p.theme.palette.text.medium};

  box-sizing: border-box;
  background-color: ${(p) => p.theme.palette.forms.input.background};
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  border-radius: 5px;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};

  -moz-appearance: textfield;
  appearance: textfield;
  
  ${p => !p.disabled && !p.readOnly && css`
    &:hover {
      -moz-appearance: unset;
    }
  `}

  &:focus {
    outline: none;

    ${(p) => !p.disabled && css`
      box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
      transition: all 150ms ease;
    `}
  }
`;
//#endregion