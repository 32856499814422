import EsgReportDto from '@dashboard/api/models/EsgReportDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class ReportGetBydIdQuery extends GetQuery<EsgReportDto> {
  reportId: number;

  constructor(reportId: number) {
    super();
    this.reportId = reportId;
  }

  public targetUrl(): string {
    return `/reports/getById/${this.reportId}`;
  }
}
