import SitePage_Reporting from '@dashboard/components/organisms/SitePage/SitePage_Reporting';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Route } from '@shared/types/Route';

export const ReportingRoutes: Route[] = [
  {
    path: 'reporting',
    element: SitePage_Reporting,
    analytics: { page: 'reporting', category: 'reporting' },
    link: {
      label: 'Reporting',
      path: 'reporting',
      icon: regular('chart-simple'),
      analytics: { action: 'reporting', category: 'feature_navigation' }
    }
  },
];