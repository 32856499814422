import '@shared/styles/App.less';
import { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { InteractionType } from '@azure/msal-browser';
import { MsalProvider, useMsalAuthentication } from '@azure/msal-react';
import { faDiamondTurnRight } from '@fortawesome/pro-solid-svg-icons';
import { ConfigProvider as AntdConfigProvider, theme as antdTheme } from 'antd';
import { authConfig } from './shared/configs/authentication/authConfig';
import { CustomThemeProvider, useThemeContext } from '@shared/contexts/ThemeContext/ThemeContext';
import { GlobalStyle } from '@shared/theme/GlobalStyle';
import { ToastContainer } from '@shared/components/atoms/ToastContainer/ToastContainer';
import { ErrorBoundary } from '@shared/components/navigation/ErrorBoundary/ErrorBoundary';
import { LoadingFullPage } from '@shared/components/molecules/LoadingFullPage/LoadingFullPage';
import { UserProvider } from '@shared/contexts/UserContext/UserContext';
import { TenantProvider } from '@shared/contexts/TenantContext/TenantContext';
import { DeviceConfigProvider } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { TopBar } from '@shared/components/molecules/TopBar/TopBar';
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { ProtectedRoute } from '@shared/components/navigation/ProtectedRoute/ProtectedRoute';
import { i18nLoadNamespaces } from '@shared/configs/i18n/i18n';
import ConsentBanner from '@shared/components/molecules/ConsentBanner/ConsentBanner';
import routes from './routes/routes';
import { LayoutProvider } from './contexts/LayoutContext';
import { AnalyticsProvider } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { LocalisationProvider } from '@shared/contexts/LocalisationContext/LocalisationContext';

export const App = () => {
  return (
    <CustomThemeProvider>
      <ThemedApp />
    </CustomThemeProvider>
  );
};

const ThemedApp = () => {
  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastContainer />
      <ErrorBoundary>
        <MsalProvider instance={authConfig.msalInstance}>
          <AuthedApp />
        </MsalProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const AuthedApp = () => {
  const { isDarkTheme, theme } = useThemeContext();
  const { login, error } = useMsalAuthentication(
    InteractionType.Redirect,
    authConfig.loginRequest
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path='*'
        element={
          <LayoutProvider>
            <TopBar />
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      route={route}
                    />
                  }
                />
              ))}

              <Route path="*" element={<Navigate to={`${routes[0]?.path}`} replace />} />
            </Routes>
          </LayoutProvider>
        }
      />
    )
  );

  useEffect(() => {
    if (error?.errorMessage) {
      switch (true) {
        case error.errorMessage.indexOf('AADB2C90118') > -1: // Forgot Password
          console.log('forgot password login request');
          login(InteractionType.Redirect, authConfig.passwordResetRequest);
          return;
        case error.errorMessage.indexOf('AADB2C90088') > -1: // Token Expired after Password Reset
          console.log('token expired after password reset login request');
          login(InteractionType.Redirect, authConfig.loginRequest);
          return;
        default:
          console.log('default login request');
          login(InteractionType.Redirect, authConfig.loginRequest);
          return;
      }
    }
  }, [error, login]);

  const accounts = authConfig.msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    authConfig.msalInstance.setActiveAccount(accounts[0]);
  } else {
    return (
      <LoadingFullPage
        message='Redirecting'
        icon={faDiamondTurnRight}
        iconPosTop="4px"
      />
    );
  }

  i18nLoadNamespaces();

  return (
    <AntdConfigProvider
      theme={{
        algorithm: isDarkTheme ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm,
        token: { colorPrimary: theme.palette.primary }
      }}
    >
      <UserProvider>
        <LocalisationProvider>
          <ErrorBoundary>
            <TenantProvider>
              <AnalyticsProvider>
                <DeviceConfigProvider>
                  <Container>
                    <RouterProvider router={router} />
                  </Container>
                </DeviceConfigProvider>
              </AnalyticsProvider>
            </TenantProvider>
          </ErrorBoundary>
        </LocalisationProvider>
      </UserProvider>
      <ConsentBanner />
    </AntdConfigProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;