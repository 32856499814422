import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRVControlMode } from '@shared/api/enums/TRVControlMode/TRVControlMode';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import SiteClimateControlUpdateCommand from '@shared/api/queries/ClimateControl/Site/SiteClimateControlUpdateCommand';
import SpaceClimateControlUpdateCommand from '@shared/api/queries/ClimateControl/Space/SpaceClimateControlUpdateCommand';
import { ComparisonOperator, ISliderThumb, SliderThumbStyle } from '@shared/components/atoms/Slider/Slider.types';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { useApi } from '@shared/hooks/useApi';
import { TFunction } from 'i18next';
import { isMatch, isUndefined } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClimateControlWidgetSavingBanner from '../ClimateControlWidgetSavingBanner';
import { ClimateControlSlider } from './ClimateControlSlider';
import { ClimateControlTable } from './ClimateControlTable';

export const CLIMATE_CONTROL_MIN_TEMPERATURE = 5;
export const CLIMATE_CONTROL_MAX_TEMPERATURE = 29;

enum ThumbKey {
  MinTemp = 'MinTemp',
  MaxTemp = 'MaxTemp',
  Target = 'Target'
}

export const createSliderThumbs = (t: TFunction, siteClimateControl: SiteClimateControlDto, spaceClimateControl?: SpaceClimateControlDto): ISliderThumb[] => {
  return [
    {
      key: ThumbKey.Target,
      value: spaceClimateControl?.targetTemp ?? siteClimateControl.targetTemp,
      label: t('ClimateControl.Target', { ns: 'molecules' }),
      unit: '°C',
      limits: [
        {
          limit: ThumbKey.MinTemp,
          operator: ComparisonOperator.GreaterThanOrEqualTo
        },
        {
          limit: ThumbKey.MaxTemp,
          operator: ComparisonOperator.LessThanOrEqualTo
        }
      ]
    },
    {
      key: ThumbKey.MinTemp,
      value: spaceClimateControl?.minTemp ?? siteClimateControl.minTemp,
      label: t('ClimateControl.RangeMin', { ns: 'molecules' }),
      unit: '°C',
      style: SliderThumbStyle.Line,
      limits: [
        {
          limit: CLIMATE_CONTROL_MIN_TEMPERATURE,
          operator: ComparisonOperator.GreaterThanOrEqualTo
        },
        {
          limit: ThumbKey.MaxTemp,
          operator: ComparisonOperator.LessThan
        },
        {
          limit: ThumbKey.Target,
          operator: ComparisonOperator.LessThanOrEqualTo
        },
      ]
    },
    {
      key: ThumbKey.MaxTemp,
      value: spaceClimateControl?.maxTemp ?? siteClimateControl.maxTemp,
      label: t('ClimateControl.RangeMax', { ns: 'molecules' }),
      unit: '°C',
      style: SliderThumbStyle.Line,
      limits: [
        {
          limit: ThumbKey.MinTemp,
          operator: ComparisonOperator.GreaterThan
        },
        {
          limit: ThumbKey.Target,
          operator: ComparisonOperator.GreaterThanOrEqualTo
        },
        {
          limit: CLIMATE_CONTROL_MAX_TEMPERATURE,
          operator: ComparisonOperator.LessThanOrEqualTo
        }
      ]
    }
  ];
};

interface ClimateControlConfig {
  thumbs: ISliderThumb[];
  controlMode: TRVControlMode;
}

type ClimateControlSettingsWidgetProps = {
  siteClimateControl: SiteClimateControlDto;
  spaceClimateControl?: SpaceClimateControlDto;
  refresh: () => void;
  onHasUnsavedChanges: (state: boolean) => void;
  isToggleDisabled?: boolean;
  showMissingDeviceWarning?: boolean;
}

export const ClimateControlSettingsWidget = ({ siteClimateControl, spaceClimateControl, refresh, onHasUnsavedChanges, isToggleDisabled, showMissingDeviceWarning }: ClimateControlSettingsWidgetProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const [initialConfig, setInitialConfig] = useState<ClimateControlConfig>();
  const [config, setConfig] = useState<ClimateControlConfig>();
  const [highlightedThumb, setHighlightedThumb] = useState<number>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    const config: ClimateControlConfig = {
      thumbs: createSliderThumbs(t, siteClimateControl, spaceClimateControl),
      controlMode: spaceClimateControl?.controlMode ?? siteClimateControl.controlMode
    };

    setInitialConfig(config);
    setConfig(config);
  }, [siteClimateControl, spaceClimateControl, t]);

  const handleReset = useCallback(() => {
    setConfig(initialConfig);
  }, [initialConfig]);

  const handleSave = useCallback(async () => {
    if (!config) {
      return;
    }

    const minTemp = config.thumbs.find(x => x.key === ThumbKey.MinTemp)?.value;
    const maxTemp = config.thumbs.find(x => x.key === ThumbKey.MaxTemp)?.value;
    const targetTemp = config.thumbs.find(x => x.key === ThumbKey.Target)?.value;

    if (spaceClimateControl) {
      const modified = {
        ...spaceClimateControl,
        minTemp: minTemp,
        maxTemp: maxTemp,
        targetTemp: targetTemp,
        controlMode: config?.controlMode
      };

      await execute({
        query: new SpaceClimateControlUpdateCommand(modified),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
        pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      });
    } else if (!isUndefined(minTemp) && !isUndefined(maxTemp) && !isUndefined(targetTemp)) {
      const modified: SiteClimateControlDto = {
        ...siteClimateControl,
        minTemp: minTemp,
        maxTemp: maxTemp,
        targetTemp: targetTemp,
        controlMode: config.controlMode
      };

      await execute({
        query: new SiteClimateControlUpdateCommand(modified),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
        pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      });
    }

    refresh();
  }, [config, siteClimateControl, spaceClimateControl, refresh, execute, t]);

  const handleChange = useCallback((thumbs: ISliderThumb[], controlMode: TRVControlMode) => {
    const newConfig = {
      thumbs: thumbs,
      controlMode: controlMode
    }

    const hasBeenModified = !!initialConfig && !isMatch(newConfig, initialConfig);
    setHasUnsavedChanges(hasBeenModified);
    onHasUnsavedChanges(hasBeenModified);
    setConfig(newConfig);
  }, [initialConfig, onHasUnsavedChanges]);

  if (!config) {
    return null;
  }

  return (
    <FlexColumn>
      <ClimateControlSlider
        thumbs={config.thumbs}
        onChange={thumbs => handleChange(thumbs, config.controlMode)}
        highlightedThumb={highlightedThumb}
        setHighlightedThumb={setHighlightedThumb}
      />

      <ClimateControlTable
        thumbs={config.thumbs}
        onChange={thumbs => handleChange(thumbs, config.controlMode)}
        setHighlightedThumb={setHighlightedThumb}
      />

      <FlexRow>
        {t('ClimateControl.ExternalTemperatureSensor', { ns: 'molecules' })}

        <InfoIconWrapper>
          <Tooltip
            content={
              <TooltipContent>
                {t('ClimateControl.ExternalSensorToggleTooltip', { ns: 'molecules' })}
              </TooltipContent>
            }
          >
            <InfoIcon
              icon={regular('circle-info')}
            />
          </Tooltip>
        </InfoIconWrapper>

        <Switch
          checked={config.controlMode === TRVControlMode.External}
          onChange={checked => handleChange(config.thumbs, checked ? TRVControlMode.External : TRVControlMode.TRV)}
          disabled={isToggleDisabled}
          disabledTooltip={
            <TooltipContent style={{ width: '170px' }}>
              {t('ClimateControl.ExternalSensorToggleDisabledTooltip', { ns: 'molecules' })}
            </TooltipContent>
          }
        />
      </FlexRow>

      {showMissingDeviceWarning &&
        <NoExternalSensorWarning>
          <NoExternalSensorIcon icon={solid('exclamation-triangle')} />
          {t('ClimateControl.NoExternalSensorWarning', { ns: 'molecules' })}
        </NoExternalSensorWarning>
      }

      <ClimateControlWidgetSavingBanner
        onSave={handleSave}
        onCancel={handleReset}
        isVisible={hasUnsavedChanges}
      />
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  margin-top: 20px;
  margin-left: 3px;
  font-size: 14px;
  color: ${p => p.theme.text.secondary};

  display: flex;
  align-items: center;
`;

const InfoIconWrapper = styled.div`
  margin: 0 15px 0 5px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;

const TooltipContent = styled.div`
  width: 255px;
  padding: 10px;
  font-size: 12px;
`;

const NoExternalSensorWarning = styled.div<{ isVisible?: boolean }>`
  font-size: 14px;
  color: ${p => p.theme.palette.red};
  padding: 0 5px;
  margin-top: 15px;

  display: flex;
  align-items: center;
`;

const NoExternalSensorIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: ${p => p.theme.palette.red};
  margin: 0 8px 0 0;
`;