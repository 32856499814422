import styled from 'styled-components';
import ReportCoverPage from './ReportCoverPage';
import ReportOverviewPage from './ReportOverviewPage';
import OccupancyWidgets from './occupancyWidgets/OccupancyWidgets';
import EnergyConsumptionWidgets from './energyConsumptionWidget/EnergyConsumptionWidgets';
import SpaceTypePerformanceWidgets from './spaceTypePerformance/SpaceTypePerformanceWidgets';
import ReportGlossary from './ReportGlossary';
import EsgReportDto from '@dashboard/api/models/EsgReportDto';
import { Building } from '@shared/api/models/Building/Building';
import { Site } from '@shared/api/models/Site/Site';
import { useEsgReportPageHeaders } from './useEsgReportPageHeaders';

type EsgReportProps = {
  site: Site;
  building?: Building;
  report: EsgReportDto;
  inPrintMode: boolean;
}

const EsgReport = ({ site, building, report, inPrintMode }: EsgReportProps) => {
  const { pageHeaders, indexRanges } = useEsgReportPageHeaders(report, site, building);

  return (
    <PrintContainer>
      {/* Cover Page */}
      <ReportCoverPage
        site={site}
        building={building}
        reportDate={report.reportDate}
      />

      <PrintContent>
        {/* Overview page */}
        <ReportOverviewPage
          report={report}
          pageHeaders={pageHeaders.slice(indexRanges[0].start, indexRanges[0].end)}
          site={!building ? site : undefined}
        />

        {/* Energy Performance section */}
        <EnergyConsumptionWidgets
          report={report}
          inPrintMode={inPrintMode}
          pageHeaders={pageHeaders.slice(indexRanges[1].start, indexRanges[1].end)}
        />

        {/* Space Type Performance section */}
        <SpaceTypePerformanceWidgets
          report={report}
          inPrintMode={inPrintMode}
          pageHeaders={pageHeaders.slice(indexRanges[2].start, indexRanges[2].end)}
          isSiteLevel={!building}
        />

        {/* Occupancy section */}
        <OccupancyWidgets
          report={report}
          inPrintMode={inPrintMode}
          pageHeaders={pageHeaders.slice(indexRanges[3].start, indexRanges[3].end)}
          histogramPageHeaders={pageHeaders.slice(indexRanges[4].start, indexRanges[4].end)}
        />

        {/* Glossary */}
        <ReportGlossary
          pageHeaders={pageHeaders.slice(indexRanges[5].start, indexRanges[5].end)}
        />
      </PrintContent>
    </PrintContainer>
  );
};

export default EsgReport;

const PrintContainer = styled.div`
  @media print {
    @page {
      size: portrait;
      margin: 0mm;
    }

    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
`;

const PrintContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media print {
    display: block;
    width: 180mm; // 210mm - 30mm (2 x (15mm margin))
    margin: 0mm 15mm;
    color: ${p => p.theme.palette.printStyles.text.medium};
  }
`;