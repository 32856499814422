import { useEffect, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { Chart, RadarController, LineElement, PointElement, RadialLinearScale, Filler, TooltipItem, Tooltip } from 'chart.js';
import { transparentize } from 'polished';
Chart.register(RadarController, LineElement, PointElement, RadialLinearScale, Filler, Tooltip);

type RadarChartProps = {
  labels: string[],
  dataset: (number | undefined)[],
  tooltipEnabled?: boolean,
  tooltipLabels: string[],
  tooltipAfterLabels?: string[],
  color?: string,
  min?: number,
  max?: number,
  stepSize?: number
}

const RadarChart = ({ labels, dataset, tooltipEnabled = false, tooltipLabels, tooltipAfterLabels, color, min = 0, max = 10, stepSize = 2 }: RadarChartProps) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const data = useMemo(() => ({
    labels: labels,
    datasets: [
      {
        label: '',
        data: dataset,
        borderColor: transparentize(0.2, color ?? theme.palette.primary),
        backgroundColor: transparentize(0.4, color ?? theme.palette.primary),
        borderWidth: 1,
        fill: true,
      },
    ],
  }), [labels, dataset, theme, color]);

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (!context) {
      return;
    }

    const chart = new Chart(context, {
      type: 'radar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        layout: {
          padding: {
            left: 10,
            right: 5,
          },
        },
        scales: {
          r: {
            angleLines: {
              display: false,
            },
            grid: {
              color: theme.palette.charts.gridLineColor,
            },
            pointLabels: {
              color: theme.palette.text.weak,
              font: {
                family: 'DM Sans',
                size: 12,
                weight: '400',
              }
            },
            ticks: {
              display: false,
              stepSize: stepSize
            },
            beginAtZero: true,
            min: min,
            max: max
          }
        },
        elements: {
          line: {
            tension: 0.1,
          },
          point: {
            radius: 0,
            hoverRadius: 4,
            hitRadius: 200,
            hoverBorderColor: theme.palette.primary,
            hoverBackgroundColor: '#fff',
            hoverBorderWidth: 3,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: tooltipEnabled,
            mode: 'nearest',
            intersect: false,
            displayColors: false,
            caretPadding: 10,
            caretSize: 7,
            titleColor: theme.palette.charts.tooltipText,
            bodyColor: theme.palette.charts.tooltipText,
            backgroundColor: theme.palette.charts.tooltipBackground,
            cornerRadius: 3,
            padding: 12,
            titleFont: {
              family: theme.fontFamily,
              weight: '500',
              size: 14,
            },
            bodyFont: {
              family: theme.fontFamily,
              size: 13,
            },
            callbacks: {
              title: (items: TooltipItem<'radar'>[]) => items[0].label ?? '',
              label: (item: TooltipItem<'radar'>) => {
                const tooltipText = tooltipLabels[item.dataIndex] ?? '';
                return tooltipLabels ? tooltipText : `Value: ${item.raw}`;
              },
              afterLabel: (item: TooltipItem<'radar'>) => {
                if (!tooltipAfterLabels) {
                  return '';
                }
                return tooltipAfterLabels[item.dataIndex] ?? '';
              },
            },
          }
        }
      }
    });

    return () => chart.destroy();
    
  }, [data, min, max, stepSize, theme, tooltipEnabled, tooltipLabels, tooltipAfterLabels]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <canvas id="myChart" ref={canvasRef} />
    </div>
  );

}

export default RadarChart;