import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SpaceClimateControlDetailsDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDetailsDto';
import { SpaceClimateControlDeviceMetricsGetQuery } from '@shared/api/queries/ClimateControl/Space/SpaceClimateControlDeviceMetricsGetQuery';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import { useApiState } from '@shared/hooks/useApiState';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import SpaceDetailsDeviceChart from './SpaceDetailsDeviceChart';
import { sortBy } from 'lodash';
import NoData from '@shared/components/atoms/NoData/NoData';

const sortingArray = ['UtopiMultiSensor', 'UtopiSmartPanelHeater', 'MClimateVickiTRV'];

type PropTypes = {
  spaceDetails: SpaceClimateControlDetailsDto;
}

const SpaceDetailsDeviceCharts = ({ spaceDetails }: PropTypes) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const { transform: devices, loading } = useApiState({
    query: new SpaceClimateControlDeviceMetricsGetQuery(spaceDetails.spaceId),
    transformFunc: devices => sortBy(devices, x => sortingArray.indexOf(x.deviceModel))
  }, [spaceDetails]);

  if (loading) {
    return (
      <Placeholder>
        <LoadingWidget
          icon={solid('line-chart')}
          label={t('Loading', { ns: 'status' })}
          iconStyles={{ height: 24 }}
        />
      </Placeholder>
    );
  }

  if (devices?.length === 0) {
    return (
      <Placeholder>
        <NoData
          label={t('DataUnavailable', { ns: 'status' })}
          icon={solid('line-chart')}
        />
      </Placeholder>
    );
  }

  return (
    <Container>
      <Tabs>
        {devices?.map((device, i) => (
          <Tab
            key={device.id}
            active={tabIndex === i}
            onClick={() => setTabIndex(i)}
          >
            {t(`${device.deviceModel}.ShortDisplayString`, { ns: 'deviceConfigs' })}
          </Tab>
        ))}
      </Tabs>

      {devices?.[tabIndex] &&
        <SpaceDetailsDeviceChart device={devices[tabIndex]} />
      }
    </Container>
  );
};

export default SpaceDetailsDeviceCharts;

const Container = styled.div`
  padding: 24px;
`;

const Tabs = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 24px;
`;

const Tab = styled.div<{ active: boolean }>`
  font-size: 14px;
  font-weight: 500;
  padding: 8px 10px;
  border-radius: 4px;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  transition: all 150ms ease;
  
  ${p => p.active && css`
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.background};    
  `}
`;

const Placeholder = styled.div`
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;