import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IInternalNode, IInternalNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import { updateNodeAndChildren } from '@shared/components/atoms/CascaderMultiSelect/CascaderUtils';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { includesCI } from '@shared/utils/StringUtils';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Checkbox from './Checkbox';

interface ICascadeGroup<TValue> {
  group: IInternalNodeGroup<TValue>;
  depth: number;
  onChange: (updatedGroup: IInternalNodeGroup<TValue>) => void;
  disabled?: boolean;
  activeGroupId?: string;
  setActiveGroupId: (id?: string) => void;
  parentGroup?: IInternalNodeGroup<TValue>;
  showSearch?: boolean;
}

const CascadeLevel = <TValue,>({ group, depth, onChange, disabled, activeGroupId, setActiveGroupId, parentGroup, showSearch = false }: ICascadeGroup<TValue>) => {
  const { t } = useTranslation();
  const [activeNodeId, setActiveNodeId] = useState<string>();
  const [searchString, setSearchString] = useState<string>('');
  const maxDepth = 10;
  const disableSelection = disabled || group.nodes.filter(x => x.selected || x.partial).length === group.selectLimit;

  const searchFilter = useCallback((x: string) => {
    return includesCI(x, searchString);
  }, [searchString]);

  /**
   * Called when a node label/row is clicked
   */
  const handleNodeClick = (node: IInternalNode<TValue>, hasChildren: boolean) => {
    if (hasChildren) {
      setActiveNodeId(node.id);
      setActiveGroupId(node.childGroup?.id);
    } else {
      handleNodeSelect(node);
    }
  };

  /**
   * Called when a node label/row is selected
   */
  const handleNodeSelect = (node: IInternalNode<TValue>) => {
    if (node.selected || node.partial || !disableSelection) {
      const modifiedNode = updateNodeAndChildren(node, !node.selected);
      handleNodeChange(modifiedNode);
    }
  };

  /**
   * Called when a node/checkbox was selected, replacing the updated node
   */
  const handleNodeChange = (updatedNode: IInternalNode<TValue>) => {
    const groupCopy = cloneDeep(group);
    groupCopy.nodes = group.nodes.map(node => node.id === updatedNode.id ? updatedNode : node);
    onChange(groupCopy);
  };

  /**
   * Recursively called (from updated node/nodeGroup upwards to root nodeGroup), replacing the updated nodeGroup
   */
  const handleGroupChange = (updatedGroup: IInternalNodeGroup<TValue>) => {
    const groupCopy = cloneDeep(group);

    groupCopy.nodes = group.nodes.map(node => {
      if (node.childGroup?.id !== updatedGroup.id) {
        return node;
      }

      // Replace updated child group
      node.childGroup = updatedGroup;

      // Check if all child nodes are selected (use negated Array.some() instead of Array.every() for performance reasons)
      const selected = !node.childGroup.nodes.some(x => !x.selected);

      // Check if the node is partially selected (at least one but not all child nodes are selected or partially selected)
      const partial = selected ? false : node.childGroup.nodes.some(x => x.selected || x.partial);

      return {
        ...node,
        selected: selected,
        partial: partial,
      };
    })

    onChange(groupCopy);
  };

  if (group.hide || group.nodes.length === 0 || depth >= maxDepth) {
    return null;
  }

  return (
    <>
      <FlexColumn activeGroup={activeGroupId === group.id}>
        <Header hasBackRow={parentGroup !== undefined}>
          {parentGroup &&
            <>
              <BackButton onClick={() => setActiveGroupId(parentGroup?.id)}>
                <ChevronIcon icon={solid('chevron-left')} />
                {t('Back', { ns: 'navigation' })}
              </BackButton>
              {showSearch &&
                <SearchField
                  style={{ width: '60%' }}
                  placeholder={t('Search', { ns: 'common' })}
                  onSearchChange={setSearchString}
                />
              }
            </>
          }
          <HeaderLabel hasSearch={showSearch}>
            {group.header}
          </HeaderLabel>
          {!parentGroup && showSearch &&
            <SearchField
              style={{ width: '60%' }}
              placeholder={t('Search', { ns: 'common' })}
              onSearchChange={setSearchString}
            />
          }
        </Header>
        <NodeList>
          {group.nodes.filter(x => searchFilter(x.label)).map((node) => {
            const hasChildren = node.childGroup !== undefined && node.childGroup.nodes.length > 0 && !node.childGroup.hide;

            return (
              <NodeRow
                key={node.id}
                onClick={() => handleNodeClick(node, hasChildren)}
              >
                <Checkbox
                  node={node}
                  onSelect={handleNodeSelect}
                  disabled={disableSelection}
                />
                <LabelRow>
                  <Label>
                    {node.label}
                  </Label>
                  <RightAligned>
                    <NumNodes>
                      {node.childGroup?.nodes && node.childGroup.nodes.length > 0 && `(${node.childGroup.nodes.length})`}
                    </NumNodes>

                    {!node.childGroup?.hide && node.childGroup?.nodes && node.childGroup.nodes.length > 0 &&
                      <Icon icon={solid('chevron-right')} />
                    }
                  </RightAligned>
                </LabelRow>
              </NodeRow>
            );
          })}
        </NodeList>
      </FlexColumn>

      {group.nodes.filter(node => node.id === activeNodeId).map((childNode) => childNode.childGroup && (
        <CascadeLevel
          key={childNode.id}
          group={childNode.childGroup}
          depth={depth + 1}
          onChange={handleGroupChange}
          disabled={!childNode.partial && disableSelection}
          activeGroupId={activeGroupId}
          setActiveGroupId={setActiveGroupId}
          parentGroup={group}
          showSearch={showSearch}
        />
      ))}
    </>
  );
}

export default CascadeLevel;

const FlexColumn = styled.div<{ activeGroup: boolean }>`
  display: flex;
  flex-direction: column;
  width: 0px;
  height: 0px;
  overflow: hidden;
  transition: width 150ms ease;

  ${p => p.activeGroup && css`
    width: 100%;
    height: 100%;
  `}
`;

const NodeList = styled.div`
  overflow: hidden auto;
  max-height: 200px;
`;

const Header = styled.div<{ hasBackRow: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; 
  gap: 5px;

  width: 100%;
  padding: 16px 15px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const HeaderLabel = styled.div<{ hasSearch: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};

  width: ${p => p.hasSearch ? '35%' : '100%'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.primary};
  padding-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const NodeRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
  border-left: 3px solid transparent;

  &:hover {
    background-color: ${p => p.theme.palette.select.hover};
    border-left-color: ${p => p.theme.primary.main};
  }
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const RightAligned = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NumNodes = styled.div`
  margin-left: 5px;
  font-size: 12px;
  color: ${p => p.theme.text.secondary};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  color: ${p => p.theme.text.secondary};
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

const BackButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
  padding: 5px 10px 5px 0;
  border-radius: 4px;
  transition: all 150ms ease;
  width: 35%;

  &:hover {
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;