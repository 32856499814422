import { Building } from '@shared/api/models/Building/Building';
import { DeleteBlobQuery } from '@shared/api/queries/common/DeleteBlobQuery';

export default class BuildingImageDeleteCommand extends DeleteBlobQuery<Building> {
  buildingId: number;

  constructor(blobName: string, buildingId: number) {
    super(blobName);
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return '/buildings/image';
  }
}
