import { Feature } from '@shared/api/models/Feature/Feature';
import { Tenant } from '@shared/api/models/Tenant/Tenant';
import TenantListItem from './TenantListItem';

interface ITenantList {
  tenants: Tenant[];
  features: Feature[];
  refresh: () => void;
  searchString: string;
}

const TenantList = ({ tenants, features, refresh, searchString }: ITenantList) => {
  return (
    <>
      {tenants.map((tenant, i) => (
        <TenantListItem
          key={i}
          tenant={tenant}
          features={features}
          refresh={refresh}
          searchString={searchString}
        />
      ))}
    </>
  );
};

export default TenantList;