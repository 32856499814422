import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertRule } from '@shared/api/models/AlertRule/AlertRule';
import { PopoverPlacement } from '@shared/components/atoms/Popover/Popover.types';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useApi } from '@shared/hooks/useApi';
import { usePopover } from '@shared/hooks/usePopover';
import { useModal } from '@shared/hooks/useModal';
import AlertRuleCreateCommand from '@settings/api/queries/AlertRules/AlertRuleCreateCommand';
import { Popover } from '@shared/components/atoms/Popover/Popover';
import AlertRuleDeleteCommand from '@settings/api/queries/AlertRules/AlertRuleDeleteCommand';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';

interface IAlertListActions {
  rule: AlertRule | undefined;
  refreshRules: () => void;
}

const AlertListActionsV2 = ({ rule, refreshRules }: IAlertListActions) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['settingsAlerting']);
  const theme = useTheme();
  const { execute } = useApi();
  const { site } = useSiteContext();
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});
  const { visible, toggle, setVisible, ref } = usePopover({});

  if (rule === undefined) {
    return <></>
  }

  const cloneRule = async (rule: AlertRule | undefined) => {
    toggle();

    if (rule === undefined) {
      return <></>
    }

    const clone = cloneDeep(rule);
    clone.name = `${t('AlertingRuleActions.CopyOf', { ns: 'settingsAlerting' })} ${rule.name}`;
    await execute({
      query: new AlertRuleCreateCommand(site.id, clone),
      successMessage: t('AlertingRuleActions.RuleSaved', { ns: 'settingsAlerting' })
    });

    refreshRules();
  };

  const deleteRule = async (rule: AlertRule | undefined) => {
    if (rule === undefined || !rule.id) {
      return;
    }

    toggle();

    await execute({
      query: new AlertRuleDeleteCommand(rule.id),
      successMessage: `${t('AlertingRuleActions.Rule', { ns: 'settingsAlerting' })} "${rule.name}" ${t('AlertingRuleActions.Deleted', { ns: 'settingsAlerting' })}`
    });

    refreshRules();

    // Hide Tooltip and Modal
    visible && setVisible(false);
    deleteDialogIsOpen && toggleDeleteDialog();
  };

  const popoverContent = (
    <PopoverContent onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
      <Action onClick={() => cloneRule(rule)}>
        <ActionIcon icon={regular('clone')} color={theme.palette.primary} />
        {t('AlertingRuleActions.Duplicate', { ns: 'settingsAlerting' })}
      </Action>
      <Action onClick={() => navigate(`../alerts/ruleslist/rules/${rule.id}`)}>
        <ActionIcon icon={regular('edit')} color={theme.palette.primary} />
        {'Edit'}
      </Action>
      <Action onClick={toggleDeleteDialog}>
        <ActionIcon icon={solid('trash-xmark')} color={theme.palette.red} />
        {t('AlertingRuleActions.Delete', { ns: 'settingsAlerting' })}
      </Action>
    </PopoverContent>
  );

  return (
    <>
      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('AlertingRuleActions.DeleteConfirmParagraphOne', { ns: 'settingsAlerting' })}
        sectionTwo={t('AlertingRuleActions.DeleteConfirmParagraphTwo', { ns: 'settingsAlerting' })}
        confirmButton={t('AlertingRuleActions.Confirm', { ns: 'settingsAlerting' })}
        onCancel={toggleDeleteDialog}
        onConfirm={() => deleteRule(rule)}
      />

      {rule &&
        <Popover
          ref={ref}
          visible={visible}
          popoverContent={popoverContent}
          placement={PopoverPlacement.Left}
          offsetY={30}
          offsetX={5}
        >
          <MenuIcon icon={solid('ellipsis')} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggle();
          }} />
        </Popover>
      }
    </>
  );
};

export default AlertListActionsV2;

const MenuIcon = styled(FontAwesomeIcon)`
  margin-top: 5px;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  padding: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &:hover {
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;

const PopoverContent = styled.div`
  width: 120px;
  padding: 5px 0;
  cursor: pointer;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 5px 12px;

  &:hover {
    background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  }
`;

const ActionIcon = styled(FontAwesomeIcon) <{ color: string }>`
  font-size: 14px;
  color: ${p => p.color};
  margin-right: 8px;
  width: 15px;
`;