import { UserRole } from './UserRole';

export const UserRolePermissions: { [key in UserRole]: string } = {
  [UserRole.SuperAdministrator]:
    'Site Read, Site Write, Building Read, Building Write, Floor Read, Floor Write, Space Read, Space Write, User Read, User Write, Device Read, Device Write, Role Read, View Settings, View Heatmap, Super Administrator',
  [UserRole.Administrator]:
    'Site Read, Site Write, Building Read, Building Write, Floor Read, Floor Write, Space Read, Space Write, User Read, User Write, Device Read, Device Write, Role Read, View Settings, View Heatmap',
  [UserRole.SiteManager]:
    'Site Read, Building Read, Floor Read, Space Read, User Read, Device Read, View Heatmap',
  [UserRole.Operator]:
    'Site Read, Site Write, Building Read, Building Write, Floor Read, Floor Write, Space Read, Space Write, Device Read, Device Write, View Settings, View Heatmap',
  [UserRole.Investor]: 'Site Read, Building Read, Floor Read, Space Read, Device Read, View Heatmap',
  [UserRole.Installer]:
    'Site Read, Building Read, Floor Read, Space Read, Device Read, Device Write, View Heatmap, View Settings'
};