import { User } from '@shared/api/models/User/User';
import { DeleteBlobQuery } from '@shared/api/queries/common/DeleteBlobQuery';

export default class UserProfileImageDeleteCommand extends DeleteBlobQuery<User> {
  userId: number;

  constructor(blobName: string, userId: number) {
    super(blobName);
    this.userId = userId;
  }

  public targetUrl(): string {
    return '/users/profileImage';
  }
}
