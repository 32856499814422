import { EsgExplorerMetricsDto } from '@dashboard/api/models/EsgExplorerMetricsDto';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import OutlierTable from './OutlierTable';
import NoData from '@shared/components/atoms/NoData/NoData';
import { TOutlierConfig } from './OutlierWidget';

type PropTypes = {
  config: TOutlierConfig;
  loading: boolean;
  data?: EsgExplorerMetricsDto;
}

const OutlierResults = ({ config, loading, data }: PropTypes) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <LoadingWidget
        icon={regular('table')}
        label={t('LoadingData', { ns: 'status' })}
        styles={{ height: 220, justifyContent: 'center' }}
        iconStyles={{ width: 30 }}
      />
    );
  }

  if (!config.metric) {
    return (
      <NoData
        large
        styles={{ height: 320, justifyContent: 'center' }}
        icon={duotone('poll-people')}
        label={t('NoMetricSelected', { ns: 'status' })}
        subLabel={t('SelectMetricToSeeOutliers', { ns: 'status' })}
      />
    );
  }

  if (!data || data.spaces.length === 0) {
    return (
      <NoData
        large
        styles={{ height: 320, justifyContent: 'center' }}
        icon={duotone('poll-people')}
        label={t('NoDataFound', { ns: 'status' })}
        subLabel={t('AdjustTimeRangeOrFilter', { ns: 'status' })}
      />
    );
  }

  return (
    <OutlierTable
      config={config}
      data={data}
    />
  );
};

export default OutlierResults;