import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ICascaderMultiSelectNodeGroup, IInternalNode, IInternalNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import { createNodeTree } from '@shared/components/atoms/CascaderMultiSelect/CascaderUtils';

/**
 * Returns an array of all the selected child values
 */
export const getSelectedValues = <TValue,>(group?: IInternalNodeGroup<TValue>,): { values: TValue[], hasSelectedNodes?: boolean } => {
  let hasSelectedNodes = false;

  if (!group) {
    return { values: [], hasSelectedNodes };
  }

  const combineValues = (values: TValue[], node: IInternalNode<TValue>) => {
    return node.selected && node.value ? [...values, node.value] : values;
  }

  let values: TValue[] = [];

  group.nodes.forEach(node => {
    const { values: selectedItems, hasSelectedNodes: childHasSelectedNodes } = getSelectedValues(node.childGroup);

    if (!hasSelectedNodes) {
      hasSelectedNodes = childHasSelectedNodes || node.selected === true;
    }

    values = combineValues(values, node);
    values = [...values, ...selectedItems];
  });

  return { values, hasSelectedNodes };
};

type PropTypes<TValue> = {
  group: ICascaderMultiSelectNodeGroup<TValue>;
  resetPulse?: number;
}

const useLocationFilter = <TValue,>({ group, resetPulse }: PropTypes<TValue>) => {
  const [origialNodeGroup, setOriginalNodeGroup] = useState<IInternalNodeGroup<TValue> | undefined>();
  const [nodeGroup, setNodeGroup] = useState<IInternalNodeGroup<TValue> | undefined>();
  const [selected, setSelected] = useState<TValue[]>();

  /**
   * Set nodeGroup on first render or when the "group" prop changes
   */
  useEffect(() => {
    const nodeTree = createNodeTree('', cloneDeep(group));
    setNodeGroup(cloneDeep(nodeTree));
    setOriginalNodeGroup(cloneDeep(nodeTree));
  }, [group]);

  useEffect(() => {
    if (resetPulse) {
      setSelected(undefined);
      setNodeGroup(origialNodeGroup);
    }
  }, [resetPulse, origialNodeGroup]);

  const handleSelect = (modifiedNodeGroup: IInternalNodeGroup<TValue>) => {
    setNodeGroup(modifiedNodeGroup);

    // Recursively look for all selected nodes and pass selected values to parent component.
    const { values, hasSelectedNodes } = getSelectedValues(modifiedNodeGroup);
    setSelected(hasSelectedNodes ? values : undefined);
  };

  return {
    nodeGroup,
    selected,
    handleSelect
  };
};

export default useLocationFilter;