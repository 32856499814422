import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import styled, { useTheme } from 'styled-components';
import { useApi } from '@shared/hooks/useApi';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import { Select } from '@shared/components/atoms/Select/Select';
import { Card } from '@shared/components/molecules/Card/Card';
import { Button } from '@shared/components/atoms/Button/Button';
import { RouterPrompt } from '@shared/components/navigation/RouterPrompt/RouterPrompt';
import { Label } from '@shared/components/atoms/Form/Form';
import { Device } from '@shared/api/models/Device/Device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApiState } from '@shared/hooks/useApiState';
import { useModal } from '@shared/hooks/useModal';
import DevicesOfSameModelInSpaceGetByDeviceIdQuery from '@settings/api/queries/Devices/DevicesOfSameModelInSpaceGetByDeviceId';
import DeviceMergeCommand from '@settings/api/queries/Devices/DeviceMergeCommand';
import { OptionProps, components } from 'react-select';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';

interface DeviceOption {
  label: string;
  value: Device;
  createdOn: string;
}

type FormValues = {
  oldDevice: Device;
  newDevice: Device;
}

const Option = (props: OptionProps<DeviceOption>) => {
  const device = props.data.value;

  return (
    <components.Option {...props} >
      <OptionTitle>
        {device.friendlyName}
      </OptionTitle>
      <OptionRow>
        {device.deviceIdentifier}
      </OptionRow>
      <OptionRow>
        {props.data.createdOn}
      </OptionRow>
    </components.Option>
  );
};

const DeviceMerge = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { deviceId } = useParams<{ deviceId: string }>();
  const [existingDeviceId, setExistingDeviceId] = useState<number>();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedAreSame, setSelectedAreSame] = useState(false);
  const [executePostSaveAction, setExecutePostSaveAction] = useState(false);
  const { handleSubmit, control, watch, formState: { errors } } = useForm<FormValues>();
  const formValues = watch();
  const { isOpen: mergeDeviceIsOpen, toggle: toggleDeviceMergeDialog, ref: mergeDeviceRef } = useModal({});
  const navigate = useNavigate();
  const { data: devices } = useApiState({
    query: deviceId === undefined ? undefined : new DevicesOfSameModelInSpaceGetByDeviceIdQuery(parseInt(deviceId)),
  }, [deviceId])
  const { execute } = useApi();

  useEffect(() => {
    if (devices) {
      const hasBeenModified = formValues.newDevice !== undefined && formValues.oldDevice !== undefined
      setHasUnsavedChanges(hasBeenModified);
    }
  }, [devices, formValues]);

  useEffect(() => {
    if (devices) {
      const selectedAreSame = hasUnsavedChanges && formValues.newDevice.id === formValues.oldDevice.id
      setSelectedAreSame(selectedAreSame);
    }
  }, [devices, formValues, hasUnsavedChanges]);

  /**
   * Execute after saving, redirect back to the device list
   */
  useEffect(() => {
    if (executePostSaveAction && !hasUnsavedChanges && existingDeviceId) {
      navigate('./..');
    }
  }, [executePostSaveAction, navigate, existingDeviceId, hasUnsavedChanges]);

  if (!devices) {
    return null;
  }

  const deviceOptions: DeviceOption[] = devices.map(device => (
    {
      value: device,
      label: `${device.friendlyName}`,
      createdOn: `${t('DeviceMerge.CreatedOn', { ns: 'settingsDevice' })}: ${format(new Date(device.createdOn), 'dd MMM yyyy')}`
    }))

  const onSave: SubmitHandler<FormValues> = async data => {
    setSavingInProgress(true);

    const result = await execute({
      query: new DeviceMergeCommand(data.oldDevice.id, data.newDevice.id, data.newDevice.deviceIdentifier),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    if (result === undefined) {
      return;
    }

    setExistingDeviceId(result.id);
    setHasUnsavedChanges(false);
    setSavingInProgress(false);
    setExecutePostSaveAction(true);
  }

  const MergeDeviceDialogContent = (
    <MergeDeviceDialogWrapper>
      <DialogLabel>{t('DeviceMerge.ConfirmMergeLabel', { ns: 'settingsDevice' })}</DialogLabel>

      {hasUnsavedChanges &&
        <Row>
          <RowLabel>{formValues.oldDevice.friendlyName} <br /> {t('DeviceMerge.CreatedOn', { ns: 'settingsDevice' })}: {format(new Date(formValues.oldDevice.createdOn), 'dd MMM yyyy')}</RowLabel>
          <ArrowIcon icon={solid('plus')} />
          <RowLabel>{formValues.newDevice.friendlyName} <br /> {t('DeviceMerge.CreatedOn', { ns: 'settingsDevice' })}: {format(new Date(formValues.newDevice.createdOn), 'dd MMM yyyy')}</RowLabel>
        </Row>
      }
    </MergeDeviceDialogWrapper>
  );

  return (
    <PaddedContainer centered>
      <RouterPrompt when={hasUnsavedChanges} />

      <Container>
        <BackButton
          label={t('BackToDevice', { ns: 'navigation' })}
          url='./..'
        />

        <Card cardTitle={t('DeviceMerge.MergeDevice', { ns: 'settingsDevice' })}>
          <DescriptionText>{t('DeviceMerge.MergeDeviceText', { ns: 'settingsDevice' })}</DescriptionText>
          <DescriptionText>{t('DeviceMerge.MergeDeviceSubText', { ns: 'settingsDevice' })}</DescriptionText>

          {selectedAreSame &&
            <ErrorText>{t('DeviceMerge.SameDeviceError', { ns: 'settingsDevice' })}</ErrorText>
          }

          <Label>{t('DeviceMerge.OldDevice', { ns: 'settingsDevice' })}</Label>
          <Controller
            control={control}
            name='oldDevice'
            rules={{ required: t('DeviceMerge.RequiredField', { ns: 'settingsDevice' }) }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={deviceOptions.find(device => device.value === value)}
                onChange={selected => selected && onChange(selected.value)}
                options={deviceOptions}
                components={{ Option: Option }}
                isMulti={false}
              />
            )}
          />

          <div style={{ paddingTop: '20px' }} />

          <Label>{t('DeviceMerge.NewDevice', { ns: 'settingsDevice' })}</Label>
          <Controller
            control={control}
            name='newDevice'
            rules={{ required: t('DeviceMerge.RequiredField', { ns: 'settingsDevice' }) }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={deviceOptions.find(device => device.value === value)}
                onChange={selected => selected && onChange(selected.value)}
                options={deviceOptions}
                components={{ Option: Option }}
                isMulti={false}
              />
            )}
          />
        </Card>

        <Card>
          <FlexRow>
            <Button
              label={t('Save', { ns: 'common' })}
              onClick={toggleDeviceMergeDialog}
              disabled={!hasUnsavedChanges || !isEmpty(errors) || selectedAreSame}
              loading={savingInProgress}
            />
            <Link to='./..'>
              <Button
                tertiary
                color={theme.palette.red}
                label={t('Cancel', { ns: 'common' })}
              />
            </Link>
          </FlexRow>

          <InteractiveDialog
            modalRef={mergeDeviceRef}
            isOpen={mergeDeviceIsOpen}
            hide={toggleDeviceMergeDialog}
            title={t('DeviceMerge.MergeDevice', { ns: 'settingsDevice' })}
            content={MergeDeviceDialogContent}
            confirmButton={{
              label: t('Confirm', { ns: 'common' }),
              onClick: handleSubmit(onSave),
              loading: savingInProgress
            }}
            cancelButton={{
              label: t('Cancel', { ns: 'common' }),
              onClick: toggleDeviceMergeDialog
            }}
          />

        </Card>
      </Container>
    </PaddedContainer>
  )
};

export default DeviceMerge;

const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

const FlexRow = styled.span`
  display: flex;
  justify-content: space-between;
`;

const DescriptionText = styled.div`
  font-size: 14px;
  padding-bottom: 20px;
`;

const MergeDeviceDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  box-shadow: 0 6px 4px -2px ${p => p.theme.palette.shadows.medium}, 0 2px 18px 0px ${p => p.theme.palette.shadows.medium};
  border-radius: 25px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.primary};
  font-size: 16px;
`;

const RowLabel = styled.div`
  color: ${p => p.theme.palette.text.weak};
  font-size: 16px;
  font-weight: 400;
`;

const DialogLabel = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-size: 18px;
  font-weight: 500;
`;

const OptionTitle = styled.div`
  color: ${p => p.theme.palette.text.medium};
  font-size: 16px;
  font-weight: 500;
`;

const OptionRow = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-size: 14px;
`;

const ErrorText = styled.div`
  color: ${p => p.theme.palette.red};
  font-size: 14px;
  font-weight: 500;
`;