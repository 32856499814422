import { ReportEnergyConsumptionUsageDto } from '@dashboard/api/models/ReportEnergyConsumptionDto';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { EnergyMeterType_Color } from '@shared/api/enums/EnergyMeterType/EnergyMeterType_Color';
import { EnergyMeterType_Icon } from '@shared/api/enums/EnergyMeterType/EnergyMeterType_Icon';
import { applyEnergyConsumptionGasModifier } from './GasModifier';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { ReportMetadataDto } from '@dashboard/api/models/ReportMetadataDto';
import ReportMetricType from '@dashboard/api/enums/ReportMetricType';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';
import { LocalisationFunction } from '@shared/contexts/LocalisationContext/LocalisationContext';

export type EnergyConsumptionWidgetConfig = {
  icon: IconDefinition | null;
  color: string;
  unitSuffix: string;
  data: ReportEnergyConsumptionUsageDto;
  toggleLabels?: string[];
  transform?: EnergyConsumptionWidgetConfig;
};

export const getMetricTypeFromEnergyMeterType = (meterType: EnergyMeterType): MetricType | AltUnits => {
  switch (meterType) {
    case EnergyMeterType.Electricity:
      return MetricType.ElectricityKwh;
    case EnergyMeterType.Gas:
      return MetricType.GasVolume;
    case EnergyMeterType.Water:
      return MetricType.WaterVolume;
    case EnergyMeterType.Heating:
      return MetricType.HeatingKwh;
    case EnergyMeterType.Cooling:
      return MetricType.CoolingKwh;
    default:
      return AltUnits.Default;
  }
}

// Creates a config used by the EnergyConsumptionWidget. Additionally it applies a modifier function for unit conversion to certain EnergyMeterTypes
export const createEnergyConsumptionWidgetConfig = (dto: ReportEnergyConsumptionUsageDto, metadata: ReportMetadataDto[], getUnit: (metric: MetricType | AltUnits) => string, fromLocale: LocalisationFunction): { config: EnergyConsumptionWidgetConfig, transform: EnergyConsumptionWidgetConfig | undefined } => {
  const config: EnergyConsumptionWidgetConfig = {
    icon: EnergyMeterType_Icon(dto.energyType),
    color: EnergyMeterType_Color(dto.energyType),
    unitSuffix: getUnit(getMetricTypeFromEnergyMeterType(dto.energyType)),
    data: dto
  };

  switch (dto.energyType) {
    case EnergyMeterType.Gas: {
      const gasCalorificValue = metadata.find(x => x.metricType === ReportMetricType.GasCalorificValue)?.value;

      if (!gasCalorificValue) {
        break;
      }

      config.toggleLabels = [getUnit(getMetricTypeFromEnergyMeterType(dto.energyType)), 'kWh'];

      const transform = {
        ...config,
        unitSuffix: 'kWh',
        data: applyEnergyConsumptionGasModifier(dto, parseFloat(gasCalorificValue), fromLocale)
      };

      return {
        config: { ...config, transform: transform },
        transform: { ...transform, transform: config }
      };
    }
    default:
      break;
  }

  return { config: config, transform: undefined };
}

// Generic helper function to apply a modifier function to all utility consumption values in a ReportEnergyConsumptionUsageDto
export const applyModifierToEnergyConsumptionUsageDto = (dto: ReportEnergyConsumptionUsageDto, modifier: (value: number) => number): ReportEnergyConsumptionUsageDto => ({
  ...dto,
  averageUsage: modifier(dto.averageUsage),
  minimumUsage: modifier(dto.minimumUsage),
  maximumUsage: modifier(dto.maximumUsage),
  totalUsage: modifier(dto.totalUsage),
  seriesData: dto.seriesData.map(x => ({
    ...x,
    value: modifier(x.value)
  })),
  historicSeriesData: dto.historicSeriesData.map(x => ({
    ...x,
    currentYearTotal: x.currentYearTotal && modifier(x.currentYearTotal),
    previousYearTotal: x.previousYearTotal && modifier(x.previousYearTotal)
  }))
});