import { MetricType } from './MetricType';

/**
 * Get suffix
 */
export const MetricType_Suffix = (metricType: MetricType): string => {
  return metricTypeToSuffix[metricType] ?? '';
};

/**
 * Map MetricType to the appropriate typed value
 */
const metricTypeToSuffix: { [key in MetricType]: string } = Object.freeze({
  /** Common */
  [MetricType.BatteryVoltage]: 'V',
  [MetricType.BatteryPercentage]: '%',
  [MetricType.RSSI]: '',
  [MetricType.SNR]: '',
  [MetricType.SignalStrength]: '%',
  [MetricType.DataRate]: '',
  [MetricType.DeviceLastMeasuredOn]: '',

  /** Environmental */
  [MetricType.CO2]: 'ppm',
  [MetricType.OutdoorHumidity]: '%',
  [MetricType.Humidity]: '%',
  [MetricType.Light]: 'lux',
  [MetricType.LightLevel]: '',
  [MetricType.Noise]: 'dB',
  [MetricType.NoiseRaw]: 'dB',
  [MetricType.PIR]: '',
  [MetricType.OutdoorTemperature]: '°C',
  [MetricType.Temperature]: '°C',
  [MetricType.MotionMeasuredOn]: '',
  [MetricType.VOC]: '',
  [MetricType.Pressure]: 'mbar',
  [MetricType.CO]: 'ppm',
  [MetricType.O2]: '%',
  [MetricType.Pollutants]: 'kOhm',
  [MetricType.Particulates2_5]: 'µg/m³',
  [MetricType.Particulates10]: 'µg/m³',
  [MetricType.H2S]: 'ppm',
  [MetricType.NO]: 'ppm',
  [MetricType.NO2]: 'µg/m³',
  [MetricType.IAQ]: '',
  [MetricType.HCHO]: 'mg/m³',
  [MetricType.O3]: 'ppm',
  [MetricType.WaterLeak]: '',
  [MetricType.Emissions]: 'kgCO₂',

  /** Wellness */
  [MetricType.CO2Score]: '',
  [MetricType.HumidityScore]: '',
  [MetricType.LightScore]: '',
  [MetricType.NoiseScore]: '',
  [MetricType.TemperatureScore]: '',
  [MetricType.WellnessScore]: '',

  /** Energy Monitoring */
  [MetricType.ElectricityKwh]: 'kWh',
  [MetricType.ElectricityKwhDelta]: 'kWh',
  [MetricType.ElectricityKwhCost]: '',
  [MetricType.GasVolume]: 'm³',
  [MetricType.GasVolumeDelta]: 'm³',
  [MetricType.GasKwh]: 'kWh',
  [MetricType.GasKwhDelta]: 'kWh',
  [MetricType.GasVolumeCost]: '',
  [MetricType.HeatingKwh]: 'kWh',
  [MetricType.HeatingKwhDelta]: 'kWh',
  [MetricType.HeatingKwhCost]: '',
  [MetricType.WaterVolume]: 'm³',
  [MetricType.WaterVolumeDelta]: 'm³',
  [MetricType.WaterVolumeCost]: '',
  [MetricType.CarbonIntensityElectricity]: 'kWh',
  [MetricType.CoolingKwh]: 'kWh',
  [MetricType.CoolingKwhDelta]: 'kWh',
  [MetricType.CoolingKwhCost]: 'kWh',
  [MetricType.CarbonIntensityCooling]: 'kWh',

  /** Three phase meters */
  [MetricType.ElectricityGeneratedKwh]: 'kWh',
  [MetricType.ElectricityGeneratedKwhDelta]: 'kWh',
  [MetricType.CarbonIntensityElectricitySaved]: 'kWh',

  [MetricType.ElectricityL1Voltage]: 'V',
  [MetricType.ElectricityL2Voltage]: 'V',
  [MetricType.ElectricityL3Voltage]: 'V',
  [MetricType.ElectricityL1Current]: 'A',
  [MetricType.ElectricityL2Current]: 'A',
  [MetricType.ElectricityL3Current]: 'A',

  [MetricType.ElectricityL1PowerFactor]: '',
  [MetricType.ElectricityL2PowerFactor]: '',
  [MetricType.ElectricityL3PowerFactor]: '',

  [MetricType.ElectricityL1ActivePower]: '',
  [MetricType.ElectricityL2ActivePower]: '',
  [MetricType.ElectricityL3ActivePower]: '',
  [MetricType.ElectricityTotalActivePower]: '',

  [MetricType.ElectricityMaxTotalPowerDemand]: 'kW',

  [MetricType.ElectricityMaxL1CurrentDemand]: 'A',
  [MetricType.ElectricityMaxL2CurrentDemand]: 'A',
  [MetricType.ElectricityMaxL3CurrentDemand]: 'A',
  [MetricType.ElectricityMaxNCurrentDemand]: 'A',

  [MetricType.ElectricityTotalKwh]: 'kWh',
  [MetricType.ElectricityL1ImportKwh]: 'kWh',
  [MetricType.ElectricityL2ImportKwh]: 'kWh',
  [MetricType.ElectricityL3ImportKwh]: 'kWh',

  [MetricType.ElectricityL1ExportKwh]: 'kWh',
  [MetricType.ElectricityL2ExportKwh]: 'kWh',
  [MetricType.ElectricityL3ExportKwh]: 'kWh',

  [MetricType.ElectricityL1ToL2Voltage]: 'V',
  [MetricType.ElectricityL2ToL3Voltage]: 'V',
  [MetricType.ElectricityL3ToL1Voltage]: 'V',

  [MetricType.ElectricityL1TotalKwh]: 'kWh',
  [MetricType.ElectricityL2TotalKwh]: 'kWh',
  [MetricType.ElectricityL3TotalKwh]: 'kWh',

  [MetricType.ElectricityGeneratedL1KwhDelta]: 'kWh',
  [MetricType.ElectricityGeneratedL2KwhDelta]: 'kWh',
  [MetricType.ElectricityGeneratedL3KwhDelta]: 'kWh',

  [MetricType.ElectricityConsumedL1KwhDelta]: 'kWh',
  [MetricType.ElectricityConsumedL2KwhDelta]: 'kWh',
  [MetricType.ElectricityConsumedL3KwhDelta]: 'kWh',

  /** Occupancy */
  [MetricType.IsolatedPeopleCount]: '',
  [MetricType.EnterCount]: '',
  [MetricType.ExitCount]: '',
  [MetricType.CalculatedOccupancy]: '',

  /** Relay */
  [MetricType.RelayOutputStatus]: '',

  /** Switch */
  [MetricType.ContactSwitch]: '',

  /** Wavetrend */
  [MetricType.Sensor1Temp]: '°C',
  [MetricType.Sensor2Temp]: '°C',
  [MetricType.Sensor3Temp]: '°C',
  [MetricType.Sensor1MinTemp]: '°C',
  [MetricType.Sensor1MaxTemp]: '°C',
  [MetricType.Sensor1FlowCount]: '',
  [MetricType.Sensor1Compliant]: '',
  [MetricType.Sensor1Error]: '',
  [MetricType.Sensor2MinTemp]: '°C',
  [MetricType.Sensor2MaxTemp]: '°C',
  [MetricType.Sensor2FlowCount]: '',
  [MetricType.Sensor2Compliant]: '',
  [MetricType.Sensor2Error]: '',
  [MetricType.Sensor3MinTemp]: '°C',
  [MetricType.Sensor3MaxTemp]: '°C',
  [MetricType.Sensor3FlowCount]: '',
  [MetricType.Sensor3Compliant]: '',
  [MetricType.Sensor3Error]: '',
  [MetricType.SensorScald]: '',
  [MetricType.SensorScaldTemp]: '°C',
  [MetricType.SensorFreeze]: '',
  [MetricType.SensorFreezeTemp]: '°C',
  [MetricType.ErrorMessage]: '',

  /** Weather */
  [MetricType.WindSpeedAverage]: 'm/s',
  [MetricType.WindDirectionAverage]: 'degrees',

  /** IoTSens Sound Monitor */
  [MetricType.Percentile_P1]: 'dBA',
  [MetricType.Percentile_P10]: 'dBA',
  [MetricType.Percentile_P50]: 'dBA',
  [MetricType.Percentile_P90]: 'dBA',
  [MetricType.Percentile_P99]: 'dBA',
  [MetricType.DBA_Min]: 'dBA',
  [MetricType.LAEQ]: 'dBA',
  [MetricType.DBA_Max]: 'dBA',

  /**External Metrics */
  [MetricType.CarbonIntensityForecast]: 'gCO₂/kWh',

  /** Axioma Water Meter */
  [MetricType.WaterMeterAlarm]: '',

  /**Vutility HotDrop */
  [MetricType.AmpHourAccumulation]: 'Ah',
  [MetricType.AverageAmps]: 'A',
  [MetricType.MaximumAmps]: 'A',
  [MetricType.MinimumAmps]: 'A',
  [MetricType.CapacitorVoltage]: 'V',

  /**Utopi Eastron SDM230 */
  [MetricType.ElectricityMaxImportPowerDemand]: 'kW',
  [MetricType.ElectricityMaxExportPowerDemand]: 'kW',
  [MetricType.ElectricityExportKwhDelta]: 'kWh',
  [MetricType.ElectricityExportKwh]: 'kWh',

  /** MClimate Vicki TRV */
  [MetricType.TemperatureTarget]: '°C',
  [MetricType.MinTemperature]: '°C',
  [MetricType.MaxTemperature]: '°C',
  [MetricType.ManualTargetTemperatureUpdate]: '°C',
  [MetricType.WindowPosition]: '',
  [MetricType.ChildLock]: '',
  [MetricType.MotorRange]: '',
  [MetricType.MotorPosition]: '',
  [MetricType.HighMotorConsumption]: '',
  [MetricType.LowMotorConsumption]: '',
  [MetricType.BrokenSensor]: '',
  [MetricType.CalibrationFailed]: '',
  [MetricType.AttachedBackplate]: '',
  [MetricType.PerceiveAsOnline]: '',
  [MetricType.OperationalMode]: '',
  [MetricType.KeepAliveTime]: '',

  /**Axioma Qalcasonic E3 */
  [MetricType.HeatingTemperature]: '°C',
  [MetricType.HeatingPower]: 'kW',
  [MetricType.HeatingVolume]: 'm³',
  [MetricType.CoolingTemperature]: '°C'
});