import { TRVControlMode } from '@shared/api/enums/TRVControlMode/TRVControlMode';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import TemperatureMonitoringDeviceUpdateCommand from '@shared/api/queries/ClimateControl/Space/TemperatureMonitoringDeviceUpdateCommand';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApi } from '@shared/hooks/useApi';
import { useModal } from '@shared/hooks/useModal';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useSpaceContext } from '@src/components/pages/SpacePage/SpaceProvider';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

interface IDeviceCardClimateControl {
  device: DeviceWithMetrics;
  inSidebar?: boolean;
}

const DeviceCardClimateControl = ({ device, inSidebar }: IDeviceCardClimateControl) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isOpen: dialogIsOpen, toggle: toggleDialog, ref: dialogRef } = useModal({});
  const { getDeviceConfig } = useDeviceConfigContext();
  const deviceConfig = getDeviceConfig(device.deviceModel);
  const { siteClimateControl } = useSiteContext();
  const { spaceClimateControl, refreshSpaceClimateControl } = useSpaceContext();
  const { execute, loading } = useApi();

  const isClimateControlledAnInExternalMode = spaceClimateControl && (spaceClimateControl.controlMode ?? siteClimateControl?.controlMode) === TRVControlMode.External;
  const isCurrentExternalSensor = spaceClimateControl?.temperatureMonitoringDeviceId === device.id;

  const handleSetExternalSensor = async () => {
    if (!spaceClimateControl) {
      return;
    }

    await execute({
      query: new TemperatureMonitoringDeviceUpdateCommand(spaceClimateControl.id, device.id),
      successMessage: t('SetAsExternalSensorSuccess', { ns: 'status' }),
      errorMessage: t('SetAsExternalSensorError', { ns: 'status' }),
      pendingMessage: t('SetAsExternalSensorPending', { ns: 'status' }),
    });

    toggleDialog();
    refreshSpaceClimateControl();
  };

  // Hide if Space-/SiteClimateControl is not in External mode, or when the device is not a temperature monitoring device
  if (!isClimateControlledAnInExternalMode || !deviceConfig?.isTemperatureMonitoringDevice) {
    return null;
  }

  return (
    <>
      <WarningDialog
        modalRef={dialogRef}
        isOpen={dialogIsOpen}
        sectionOne={t('ClimateControl.ChangeExternalSensorDialog.SectionOne', { ns: 'molecules' })}
        sectionTwo={t('ClimateControl.ChangeExternalSensorDialog.SectionTwo', { ns: 'molecules' })}
        confirmButton={t('Confirm', { ns: 'common' })}
        confirmButtonColor={theme.palette.primary}
        onCancel={toggleDialog}
        onConfirm={handleSetExternalSensor}
        isLoading={loading}
      />

      <Wrapper inSidebar={inSidebar}>
        {isCurrentExternalSensor &&
          <div>
            <LabelValuePair
              label={t('ClimateControl.TemperatureControl', { ns: 'molecules' })}
              value={t('ClimateControl.ExternalTemperatureSensor', { ns: 'molecules' })}
              size="xs"
              valueStyle={{ fontWeight: 500 }}
            />
          </div>
        }

        {!isCurrentExternalSensor &&
          <div>
            <LabelValuePair
              label={t('ClimateControl.TemperatureControl', { ns: 'molecules' })}
              value=''
            />
            <SetAsString onClick={toggleDialog}>
              {t('ClimateControl.SetAsExternalSensor', { ns: 'molecules' })}
            </SetAsString>
          </div>
        }
      </Wrapper>
    </>
  );
};

export default DeviceCardClimateControl;

const Wrapper = styled.div<{ inSidebar?: boolean }>`
  padding: ${p => p.inSidebar ? '12px 0 ' : '16px 24px'};
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

const SetAsString = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.primary};
`;