import FairUse_ExceedingSpaces from '@dashboard/components/molecules/FairUse/ExceedingSpaces/FairUse_ExceedingSpaces';
import FairUse from '@dashboard/components/molecules/FairUse/FairUse';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Route } from '@shared/types/Route';

export const FairUseRoutes: Route[] = [
  {
    path: 'fair-use',
    element: FairUse,
    analytics: { page: 'fair_use', category: 'fair_use' },
    link: {
      label: 'FairUse',
      path: 'fair-use',
      icon: regular('handshake'),
      analytics: { action: 'fair_use', category: 'feature_navigation' }
    }
  },
  {
    path: 'fair-use/exceeding-spaces/:policyId',
    element: FairUse_ExceedingSpaces,
    analytics: { page: 'exceeding_spaces', category: 'fair_use' }
  },
];