import { stringToFloat } from '@shared/utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: MotorPosition
 */
const HeatmapConfigMotorPosition: IHeatmapConfig = {
  accessor: (x: string) => stringToFloat(x, 0),
  displayFormat: (x) => x,

  scale: {
    categoryScale: true,
    points: [
      {
        threshold: 0,
        label: '0',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 0,
        comparator: 'GreaterThan',
        label: '>0',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigMotorPosition);