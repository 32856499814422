import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { Route } from '@shared/types/Route';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useSiteContext } from '../SiteProvider';

type PropTypes = {
  route: Route;
  lastItem: boolean;
  isCollapsed: boolean;
}

const NavItem = ({ route, lastItem, isCollapsed }: PropTypes) => {
  const { t } = useTranslation();
  const { unreadAlertCount, loadingUnreadAlerts } = useSiteContext();

  if (!route.link) {
    return null;
  }

  return (
    <Link
      link={route.link}
      $lastItem={lastItem}
    >
      {route.link.icon &&
        <Icon icon={route.link.icon} />
      }

      {route.link.label &&
        <Label isHidden={isCollapsed}>
          {t(route.link.label, { ns: 'routes' })}
        </Label>
      }

      {route.link.label === 'Alerts' && loadingUnreadAlerts &&
        <Loading
          size='15px'
        />
      }

      {route.link.label === 'Alerts' && !loadingUnreadAlerts && unreadAlertCount > 0 &&
        <ThemeColoredSpan>
          {unreadAlertCount >= 99 ? '\u00A0(99+)' : `\u00A0(${unreadAlertCount})`}
        </ThemeColoredSpan>
      }
    </Link>
  );
};

export default NavItem;

const Link = styled(ProtectedLink) <{ $lastItem: boolean }>`
  height: 44px;
  padding: 0 16px 0 13px;
  border-left: 3px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  &:hover,
  &.active {
    border-left-color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};

    svg {
      color: ${p => p.theme.primary.main};
    }
  }

  ${p => p.$lastItem && css`
    margin-top: auto;
    border-top: 1px solid ${p => p.theme.action.divider};
  `}
`;

const Label = styled.div<{ isHidden: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  white-space: nowrap;
  transition: all 150ms ease;

  ${p => p.isHidden && css`
    visibility: hidden;
    opacity: 0;
  `}
`;

const Icon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  padding-right: 10px;
  color: ${p => p.theme.action.active};
  flex-shrink: 0;
`;

const ThemeColoredSpan = styled.span`
  color: ${p => p.theme.palette.primary};
  font-size: 12px;
`;