import { ListWidgetItem } from './ListWidget';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { IListWidgetMetric } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import SpaceUtilitySum from '@dashboard/api/models/SpaceUtilitySum';
import { Metric } from '@shared/api/models/Metric/Metric';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

const configs: IListWidgetMetric[] = [
  {
    metricType: MetricType.ElectricityKwhDelta,
    label: 'Electricity',
    icon: {
      name: 'faBolt',
      color: '#ffb500'
    }
  },
  {
    metricType: MetricType.GasVolumeDelta,
    label: 'Gas',
    icon: {
      name: 'faFire',
      color: '#00C851'
    },
  },
  {
    metricType: MetricType.HeatingKwhDelta,
    label: 'Heating',
    icon: {
      name: 'faFireFlameCurved',
      color: '#ff821b'
    }
  },
  {
    metricType: MetricType.WaterVolumeDelta,
    label: 'Water',
    icon: {
      name: 'faDroplet',
      color: '#1b98ff'
    }
  }
];


interface IDeltaWidget {
  utilitySums?: SpaceUtilitySum[];
  display?: boolean;
}

const DeltaWidget = ({ utilitySums, display }: IDeltaWidget) => {
  if (!display || utilitySums?.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>
        Rolling Consumption
      </Title>

      <Row>
        {utilitySums?.map((sum, i) => {
          const config = configs.find(x => x.metricType === sum.metricType);
          const metric: Metric = { metricType: sum.metricType, value: sum.value.toString(), measuredOn: new Date().toISOString() }

          if (!config) {
            return;
          }

          return (
            <ListWidgetItem
              key={i}
              config={config}
              metrics={[metric]}
            />
          );
        })}
      </Row>
    </Wrapper>
  );
};

export default DeltaWidget;

const Wrapper = styled.div`
  padding: 15px 20px;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => transparentize(0.3, p.theme.palette.text.medium)};
  padding-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
`;