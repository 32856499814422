import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import TabsBar from './Tabs/TabsBar';
import { isEmpty } from 'lodash';
import ExplorerTab from './Tabs/Explorer/ExplorerTab';
import { isFilterComplete } from './Tabs/Explorer/ExplorerConfiguration/Filters/Filter';
import TemplatesTab from './Tabs/Templates/TemplatesTab';
import { EsgExplorerConfiguration, EsgExplorerFilter } from '@dashboard/api/models/EsgExplorerConfiguration';
import { Building } from '@shared/api/models/Building/Building';
import { useApiState } from '@shared/hooks/useApiState';
import EsgExplorerRulesGetQuery from '@dashboard/api/queries/esgExplorer/EsgExplorerRulesGetQuery';
import EsgExplorerTemplatesGetQuery from '@dashboard/api/queries/esgExplorer/EsgExplorerTemplatesGetQuery';
import { Site } from '@shared/api/models/Site/Site';
import AutomationTab from './Tabs/Automation/AutomationTab';
import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import { useLayoutContext } from '@src/contexts/LayoutContext';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

const hasIncompleteTriggers = (filters: EsgExplorerFilter[]) => {
  return filters.some(filter => !isFilterComplete(filter));
};

export const isCongfigValid = (config?: EsgExplorerConfiguration) => {
  if (!config) {
    return false;
  }

  return !isEmpty(config.spaceTypes)
    && !isEmpty(config.lettingStatuses)
    && !isEmpty(config.metricTypes)
    && config.timeRange !== undefined
    && !hasIncompleteTriggers(config.filters);
};

interface IEsgExplorerMenu {
  site: Site;
  buildings: Building[];
  onGenerate: (template: EsgExplorerTemplateDto) => void;
  loading: boolean;
}

const EsgExplorerMenu = ({ site, buildings, loading, onGenerate }: IEsgExplorerMenu) => {
  const { isFullScreen } = useLayoutContext();
  const { trackAction } = useAnalytics();
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState('create');

  // Load rules
  const { data: rules, execute: refreshRules, loading: loadingRules } = useApiState({
    query: new EsgExplorerRulesGetQuery(site.id),
    initialState: []
  }, []);

  // Load templates
  const { data: templates, execute: refreshTemplates } = useApiState({
    query: new EsgExplorerTemplatesGetQuery(site.id),
    initialState: []
  }, []);

  const handleGenerate = useCallback((template: EsgExplorerTemplateDto) => {
    onGenerate(template);
    trackAction('generate_report', 'esg_explorer', { esg_explorer_drawer: activeTabKey });
  }, [onGenerate, trackAction, activeTabKey]);

  const handleTabChange = (index: number, tabKey: string) => {
    setActiveTab(index);
    setActiveTabKey(tabKey)
    trackAction(tabKey, 'esg_explorer');
  };

  return (
    <Sidebar isHidden={isFullScreen}>
      <TabsBar
        activeTab={activeTab}
        setActiveTab={handleTabChange}
      />

      <Container>
        <ExplorerTab
          open={activeTab === 0}
          siteId={site.id}
          buildings={buildings}
          refreshTemplates={refreshTemplates}
          onGenerate={handleGenerate}
          loading={loading}
        />

        <TemplatesTab
          open={activeTab === 1}
          templates={templates}
          siteId={site.id}
          buildings={buildings}
          refreshTemplates={refreshTemplates}
          refreshRules={refreshRules}
          rules={rules}
          onGenerate={handleGenerate}
          loading={loading}
        />

        <AutomationTab
          open={activeTab === 2}
          site={site}
          rules={rules}
          refreshRules={refreshRules}
          loading={loadingRules}
          templates={templates}
        />
      </Container>
    </Sidebar>
  );
};

export default EsgExplorerMenu;

const Sidebar = styled.div<{ isHidden: boolean }>`
  width: 350px;
  height: 100%;
  background-color: ${p => p.theme.background.container};
  border-right: 1px solid ${p => p.theme.action.divider};
  box-shadow: -1px 0px 8px 0px ${p => p.theme.shadow.primary};
  flex-shrink: 0;

  ${p => p.isHidden && css`
    display: none;
  `}
`;

const Container = styled.div`
  height: calc(100% - 56px);
  overflow: auto;
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  padding: 10px 0 10px 0;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
  box-shadow: 0 -8px 10px -14px ${p => p.theme.palette.shadows.strong};
  background-color: ${p => p.theme.palette.backgrounds.surface};
`;