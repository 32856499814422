import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ClimateControlFallbackConfig } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import CustomNumberInput from './CustomNumberInput';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { CLIMATE_CONTROL_MAX_TEMPERATURE, CLIMATE_CONTROL_MIN_TEMPERATURE } from '../ClimateControlSettingsWidget/ClimateControlSettingsWidget';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

const NO_MOTION_MIN_THRESHOLD = 1;
const NO_MOTION_MAX_THRESHOLD = 72;

type NoMotionConfigurationProps = {
  config: ClimateControlFallbackConfig;
  onChange: (config: ClimateControlFallbackConfig) => void;
}

const NoMotionConfiguration = ({ config, onChange }: NoMotionConfigurationProps) => {
  const { t } = useTranslation();
  const { fromLocale, toLocale, getUnit } = useLocalisation();

  const isDisabled = !config.noMotionRulesEnabled;

  const hoursLabelThreshold1 = config.noMotionThreshold1 === 1
    ? t('ClimateControl.Hour', { ns: 'molecules' })
    : t('ClimateControl.Hours', { ns: 'molecules' });

  return (
    <>
      <FlexRow>
        <StyledLabel>
          {t('ClimateControl.NoMotionInSpace', { ns: 'molecules' })}
        </StyledLabel>

        <Tooltip
          content={
            <TooltipContent>
              {t('ClimateControl.NoMotionInSpaceTooltip', { ns: 'molecules' })}
            </TooltipContent>
          }
        >
          <InfoIcon
            icon={regular('circle-info')}
          />
        </Tooltip>

        <div style={{ margin: '0 2px 0 auto' }}>
          <Switch
            checked={config.noMotionRulesEnabled}
            onChange={checked => onChange({ ...config, noMotionRulesEnabled: checked })}
          />
        </div>
      </FlexRow>

      <TableWrapper disabled={isDisabled}>
        <TableRow disabled={isDisabled}>
          <Col>
            {t('ClimateControl.After', { ns: 'molecules' })}
            <CustomNumberInput
              min={NO_MOTION_MIN_THRESHOLD}
              max={config.noMotionThreshold2 - 1}
              value={config.noMotionThreshold1}
              onChange={value => onChange({ ...config, noMotionThreshold1: value })}
              disabled={isDisabled}
            />
            <Unit>{hoursLabelThreshold1}</Unit> {t('ClimateControl.SetTemperatureTo', { ns: 'molecules' })}
            <CustomNumberInput
              min={toLocale(MetricType.Temperature, CLIMATE_CONTROL_MIN_TEMPERATURE)}
              max={toLocale(MetricType.Temperature, CLIMATE_CONTROL_MAX_TEMPERATURE)}
              value={toLocale(MetricType.Temperature, config.noMotionThreshold1Temp, { round: 0 })}
              onChange={value => onChange({ ...config, noMotionThreshold1Temp: fromLocale(MetricType.Temperature, value) })}
              disabled={isDisabled}
            />
            <Unit style={{ marginLeft: '-22px' }}>{getUnit(MetricType.Temperature)}</Unit>
          </Col>
        </TableRow>

        <TableRow disabled={isDisabled}>
          <Col>
            {t('ClimateControl.After', { ns: 'molecules' })}
            <CustomNumberInput
              min={config.noMotionThreshold1 + 1}
              max={NO_MOTION_MAX_THRESHOLD}
              value={config.noMotionThreshold2}
              onChange={value => onChange({ ...config, noMotionThreshold2: value })}
              disabled={isDisabled}
            />
            <Unit>{t('ClimateControl.Hours', { ns: 'molecules' })}</Unit> {t('ClimateControl.SetTemperatureTo', { ns: 'molecules' })}
            <CustomNumberInput
              min={toLocale(MetricType.Temperature, CLIMATE_CONTROL_MIN_TEMPERATURE)}
              max={toLocale(MetricType.Temperature, CLIMATE_CONTROL_MAX_TEMPERATURE)}
              value={toLocale(MetricType.Temperature, config.noMotionThreshold2Temp, { round: 0 })}
              onChange={value => onChange({ ...config, noMotionThreshold2Temp: fromLocale(MetricType.Temperature, value) })}
              disabled={isDisabled}
            />
            <Unit style={{ marginLeft: '-22px' }}>{getUnit(MetricType.Temperature)}</Unit>
          </Col>
        </TableRow>
      </TableWrapper>
    </>
  );
};

export default NoMotionConfiguration;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 5px 0;
`;

const StyledLabel = styled.div`
  font-size: 14px;
  color: ${p => p.theme.text.secondary};
  margin-right: 5px;
`;

const TooltipContent = styled.div`
  width: 280px;
  padding: 10px;
  font-size: 12px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;

const TableWrapper = styled.div<{ disabled: boolean }>`
  border-radius: 5px;
  border: 1px solid ${p => p.theme.action.divider};
  box-shadow: 0 1px 3px 0px ${p => p.theme.palette.shadows.medium};

  display: flex;
  flex-direction: column;
  font-size: 14px;

  ${p => p.disabled && css`
    opacity: 0.7;
  `}
`;

const TableRow = styled.div<{ disabled: boolean }>`
  padding: 7px 15px;
  display: flex;
  color: ${p => p.theme.palette.text.fair};
  user-select: none;

  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  &:nth-child(odd) {
    border-bottom: 1px solid ${p => p.theme.action.divider};
  }
`;

const Col = styled.div`
  display: flex;
  align-items: center;
`;

const Unit = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-right: 7px;
  margin-left: -20px;
`;