import { IGradientStep } from '@dashboard/types/GradientStep';
import { getGradientColorByValue } from '@dashboard/utils/ColorUtils';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useSize } from '@shared/hooks/useSize';
import { isBetween, isOdd } from '@shared/utils/NumberUtils';
import { cloneDeep } from 'lodash';
import { transparentize } from 'polished';
import React, { useEffect, useRef } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { ComparisonOperator, ISliderThumb, ISliderThumbLimit, SliderThumbStyle } from './Slider.types';

const hasCloseNeighbour = (thumb: ISliderThumb, thumbs: ISliderThumb[], distance = 1) => {
  return thumbs.some(x => x.key !== thumb.key && x.value !== thumb.value && isBetween(x.value, thumb.value - distance, thumb.value + distance));
};

const isWithinLimits = (newValue: number, thumbs: ISliderThumb[], limits?: ISliderThumbLimit[]) => {
  if (!limits) {
    return true
  }

  let isWithinLimits = true;

  for (const { limit, operator } of limits) {
    const limitValue = typeof limit === 'number'
      ? limit
      : thumbs.find(x => x.key === limit)?.value;

    if (limitValue === undefined) {
      continue;
    }

    switch (operator) {
      case ComparisonOperator.LessThan:
        isWithinLimits = newValue < limitValue;
        break;
      case ComparisonOperator.LessThanOrEqualTo:
        isWithinLimits = newValue <= limitValue;
        break;
      case ComparisonOperator.GreaterThan:
        isWithinLimits = newValue > limitValue;
        break;
      case ComparisonOperator.GreaterThanOrEqualTo:
        isWithinLimits = newValue >= limitValue;
        break;
    }

    if (!isWithinLimits) {
      return false;
    }
  }

  return isWithinLimits;
}

const getXOffset = (value: number, min: number, max: number, thumbWidth: number, sliderWidth?: number) => {
  if (!sliderWidth) {
    return 0;
  }

  const thumbHalfWidth = thumbWidth / 2;

  const offset = ((value - min) / (max - min) * ((sliderWidth - thumbHalfWidth) - thumbHalfWidth)) + thumbHalfWidth;
  return offset;
};

export const handleSliderChange = (newValue: number, index: number, thumbs: ISliderThumb[], gradientSteps: IGradientStep[], callback: (thumbs: ISliderThumb[]) => void) => {
  const copy = cloneDeep(thumbs);
  const modified = { ...copy[index] };

  if (!isWithinLimits(newValue, thumbs, modified.limits)) {
    return;
  }

  copy[index] = {
    ...modified,
    value: newValue,
    color: modified.useAutoColor ? getGradientColorByValue(gradientSteps, newValue) : modified.color
  };

  callback(copy);
};

interface ISliderProps {
  metricType: MetricType;
  min: number;
  max: number;
  thumbs: ISliderThumb[];
  gradientSteps: IGradientStep[];
  onChange: (thumbs: ISliderThumb[]) => void;
  highlightedThumb?: number;
  setHighlightedThumb?: (i?: number) => void;
  closeNeighbourDistance?: number;
}

const Slider = ({ metricType, min, max, thumbs, gradientSteps, onChange, highlightedThumb, setHighlightedThumb, closeNeighbourDistance }: ISliderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useSize(ref);
  const { fromLocale, toLocale, getUnit } = useLocalisation();

  /**
   * Initialise thumb colors to correspond with gradient
   */
  useEffect(() => {
    // Only initialise on first render or when a new instance of the thumbs array is passed in (useAutoColor is only undefined before initialisation)
    if (thumbs.some(x => x.useAutoColor !== undefined)) {
      return;
    }

    const copy = cloneDeep(thumbs);
    const coloredThumbs = copy.map(thumb => ({
      ...thumb,
      color: thumb.color ?? getGradientColorByValue(gradientSteps, thumb.value),
      useAutoColor: !thumb.color
    }));

    onChange(coloredThumbs);
  }, [thumbs, gradientSteps, onChange]);

  return (
    <Control ref={ref}>
      {thumbs.map((thumb, i) => {
        const xOffset = getXOffset(thumb.value, min, max, 17, width);
        const hasNeighbour = hasCloseNeighbour(thumb, thumbs, closeNeighbourDistance);
        const value = toLocale(metricType, thumb.value, { round: 1 });
        const unit = getUnit(metricType);

        return (
          <React.Fragment key={thumb.key}>
            <StyledInput
              type="range"
              min={toLocale(metricType, min)}
              max={toLocale(metricType, max)}
              value={value}
              color={thumb.color}
              gradient={gradientSteps.map(x => x.color)}
              thumbStyle={thumb.style ?? SliderThumbStyle.OutlineDot}
              onInput={(e) => !thumb.isDisabled && handleSliderChange(fromLocale(metricType, parseFloat(e.currentTarget.value)), i, thumbs, gradientSteps, onChange)}
              onMouseEnter={() => setHighlightedThumb && setHighlightedThumb(i)}
              onMouseLeave={() => setHighlightedThumb && setHighlightedThumb(undefined)}
              isDisabled={thumb.isDisabled}
            />

            {highlightedThumb === undefined &&
              <ValueLabel
                color={thumb.color}
                xOffset={xOffset}
                small={hasNeighbour}
                isOdd={isOdd(value)}
              >
                {value}{unit}
              </ValueLabel>
            }

            <Tooltip xOffset={xOffset} isVisible={highlightedThumb === i}>
              {thumb.label}

              <TooltipValue color={thumb.color}>
                {value}{unit}
              </TooltipValue>
            </Tooltip>
          </React.Fragment>
        );
      })}
    </Control>
  );
};

export default Slider;

const Control = styled.div`
  position: relative;
`;

const baseThumb = ({ color, thumbStyle, theme, isDisabled }: { color?: string, thumbStyle: SliderThumbStyle, theme: DefaultTheme, isDisabled?: boolean }) => css`
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  pointer-events: all;
  position: relative;
  z-index: 1;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${color ?? theme.palette.primary};
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;

  ${!isDisabled && css`
    &:hover {
      z-index: 2;
      box-shadow: ${transparentize(0.75, color ?? theme.palette.primary)} 0px 0px 0px 6px;
    }
  `}

  ${thumbStyle === SliderThumbStyle.OutlineDot && css`
    background-color: ${theme.palette.text.onPrimary};
    border: 5px solid ${color ?? theme.palette.primary};

    ${isDisabled && css`
      width: 11px;
      height: 11px;
      border-width: 3px;
    `}
  `}

  ${thumbStyle === SliderThumbStyle.Line && css`
    width: 5px;
    height: 25px;
    border-radius: 4px;
    border: none;
  `}
`;

const StyledInput = styled.input<{ gradient: string[], color?: string, thumbStyle: SliderThumbStyle, isDisabled?: boolean }>`
  -webkit-appearance: none; 
  appearance: none;
  height: 7px;
  width: 100%;
  margin: 0px;
  border-radius: 5px;
  position: absolute;
  background: ${p => `linear-gradient(to right, ${p.gradient})`};
  pointer-events: none;

  &::-webkit-slider-thumb {
    ${p => baseThumb({ color: p.color, thumbStyle: p.thumbStyle, theme: p.theme, isDisabled: p.isDisabled })};
  }

  &::-moz-range-thumb {
    ${p => baseThumb({ color: p.color, thumbStyle: p.thumbStyle, theme: p.theme, isDisabled: p.isDisabled })};
    border-width: 6px;
  }
`;

const Tooltip = styled.div.attrs<{ xOffset: number, isVisible: boolean }>(p => ({
  style: {
    opacity: p.isVisible ? 1 : 0,
    visibility: p.isVisible ? 'visible' : 'hidden',
    left: `${p.xOffset}px`
  }
})) <{ xOffset: number, isVisible: boolean }>`
  position: absolute;
  top: -80px;
  transform: translateX(-50%);
  width: 100px;
  height: 60px;
  padding: 7px 10px;

  background-color: ${p => p.theme.background.container};
  border: 1px solid ${p => p.theme.action.divider};
  box-shadow: 0 1px 5px -1px ${p => p.theme.palette.shadows.medium};
  border-radius: 3px;

  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
`;

const TooltipValue = styled.div<{ color?: string }>`
  font-size: 18px;
  font-weight: 400;
  color: ${p => p.color};
  padding-top: 3px;
`;

const ValueLabel = styled.div.attrs<{ xOffset: number, color?: string, small: boolean, isOdd: boolean }>(p => ({
  style: {
    left: `${p.xOffset}px`,
    color: p.color,
    top: p.small
      ? p.isOdd ? '-55px' : '-35px'
      : '-40px',
    fontSize: p.small ? '16px' : '20px'
  }
})) <{ xOffset: number, color?: string, small: boolean, isOdd: boolean }>`
  position: absolute;
  transform: translateX(-50%);
  font-weight: 400;
  transition: all 150ms ease;
`;