/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Dispatch, SetStateAction } from 'react';
import { useAsyncState } from './useAsyncState';
import { BaseQuery } from '@shared/api/queries/common/BaseQuery';
import { authConfig } from '@shared/configs/authentication/authConfig';

interface PropTypes<TData, TTransform> {
  /**
   * Query/Command
   */
  query?: BaseQuery<TData>;
  /**
   * Transform function to transform the result to another type
   */
  transformFunc?: (data: TData) => TTransform;
  /**
   * Initial data
   */
  initialState?: TData;
  /**
   * Initial transform data
   */
  initialTransformState?: TTransform;
  /**
   * Toast success message
   */
  successMessage?: string;
  /**
   * Toast error message
   */
  errorMessage?: string;
  /**
   * Execute API call immediately (default: true)
   */
  immediate?: boolean;
}

/**
 * The return type of `useApiState`.
 */
export type UseAsyncStateHookReturn<TData, TTransform, TSetData extends TData = TData> = {
  execute: () => Promise<void>;
  loading: boolean;
  data: TData;
  transform: TTransform;
  error: any;
  setData: Dispatch<SetStateAction<TSetData>>;
  setTransform: Dispatch<SetStateAction<TTransform>>;
};

/**
 * An overloaded function type for `useApiState`.
 * See detailed explanation: https://stackoverflow.com/questions/70396217/how-to-conditionally-set-generic-type-of-usestate-or-remove-type-undefined-fr
 */
export interface UseApiStateHook {
  // The "without initialState and initialTransformState" signature
  <TData, TTransform>({ ...args }: PropTypes<TData, TTransform> & { initialState?: undefined } & { initialTransformState?: undefined }, dependencies: any[]): UseAsyncStateHookReturn<TData | undefined, TTransform | undefined, TData>;
  // The "without initialState" signature adds `& { initialState?: undefined }` to `ProptTypes<TData>` to make
  // `initialState` optional, and adds `| undefined` to the type of the `data` member of the returned object.
  <TData, TTransform>({ ...args }: PropTypes<TData, TTransform> & { initialState?: undefined }, dependencies: any[]): UseAsyncStateHookReturn<TData | undefined, TTransform, TData>;
  // The "without initialTransformState" signature
  <TData, TTransform>({ ...args }: PropTypes<TData, TTransform> & { initialTransformState?: undefined }, dependencies: any[]): UseAsyncStateHookReturn<TData, TTransform | undefined, TData>;
  // The "with initialState and initialTransformState" signature just uses `ProptTypes<TData>`, so `initialState` is required and is type `TData`,
  // and the type of `data` in the returned object doesn't have `undefined`, it's just `TData`.
  <TData, TTransform>({ ...args }: PropTypes<TData, TTransform>, dependencies: any[]): UseAsyncStateHookReturn<TData, TTransform>;
}

/**
 * A hook to simplify and combine useState/useEffect statements relying on data from the API.
 *
 * @example
 *   const { data: sites, transform: siteAssets, loading: loadingSites } = useApiState({
 *      query: new SitesGetAllQuery(),
 *      transformFunc: (sites) => sites.map(x => new Asset(x.id, x.name, x.address, x.siteImageId)),
 *      initialState: [],
 *      initialTransformState: []
 *      errorMessage: "Failed to load sites"
 *   }, []);
 */
export const useApiState: UseApiStateHook = <TData, TTransform>({ query, transformFunc, initialState, initialTransformState, successMessage, errorMessage, immediate = true }: PropTypes<TData, TTransform>, dependencies: any[]) => {
  const { data, transform, execute, loading, error, setData, setTransform } = useAsyncState({
    asyncFunc: query && (async () => {
      return await query.execute(authConfig);
    }),
    transformFunc: transformFunc,
    initialState: initialState,
    initialTransformState: initialTransformState,
    successMessage: successMessage,
    errorMessage: errorMessage,
    immediate: immediate
  }, [...dependencies]);

  return {
    execute,
    loading,
    data,
    transform,
    error,
    setData,
    setTransform
  };
};
