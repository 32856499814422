import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EnergyMeterTopologyLevel } from '@shared/api/enums/EnergyMeterTopologyLevel/EnergyMeterTopologyLevel';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { EnergyMeterTopologyDto } from '@shared/api/models/EnergyMeter/EnergyMeterTopologyDto';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { Modal } from '@shared/components/molecules/Modal/Modal';

interface IMeterDetailsModal {
  meter: EnergyMeterTopologyDto;
  isOpen: boolean;
  toggle: () => void;
  modalRef: RefObject<HTMLDivElement>;
  color: string;
  titlePrefix: string;
}

const MeterDetailsModal = ({ meter, isOpen, toggle, modalRef, color, titlePrefix }: IMeterDetailsModal) => {
  const { t } = useTranslation(['settingsAsset']);
  const { getDisplayString } = useDeviceConfigContext();

  return (
    <Modal
      ref={modalRef}
      hide={toggle}
      isOpen={isOpen}
      title={`${titlePrefix} #${meter.meterNumber}`}
      titleColor={'#fff'}
      titleBackgroundColor={color}
      closeIconHoverBackground='#00000045'
      width="400px"
    >
      <Column>
        <GroupHeading>
          {t('Buildings.EnergyMeters.MeterDetailsModal.Meter', { ns: 'settingsAsset' })}
        </GroupHeading>
        <Group>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Meter', { ns: 'settingsAsset' })} #:</Label>
            <Value>{meter.meterNumber}</Value>
          </FlexRow>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Type', { ns: 'settingsAsset' })}:</Label>
            <Value>{meter.meterType}</Value>
          </FlexRow>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.TopologyLevel', { ns: 'settingsAsset' })}:</Label>
            <Value>{meter.meterTopologyLevel}</Value>
          </FlexRow>
          {meter.meterTopologyLevel === EnergyMeterTopologyLevel.Sub &&
            <FlexRow>
              <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Category', { ns: 'settingsAsset' })}:</Label>
              <Value>{meter.meterCategory}</Value>
            </FlexRow>
          }
        </Group>

        <GroupHeading style={{ marginTop: '20px' }}>
          {t('Buildings.EnergyMeters.MeterDetailsModal.Device', { ns: 'settingsAsset' })}
        </GroupHeading>
        <Group>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Name', { ns: 'settingsAsset' })}:</Label>
            <Value>{meter.deviceFriendlyName}</Value>
          </FlexRow>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Model', { ns: 'settingsAsset' })}:</Label>
            <Value>{getDisplayString(meter.deviceModel)}</Value>
          </FlexRow>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Manufacturer', { ns: 'settingsAsset' })}:</Label>
            <Value>{meter.deviceManufacturer}</Value>
          </FlexRow>
        </Group>

        <GroupHeading style={{ marginTop: '20px' }}>
          {t('Buildings.EnergyMeters.MeterDetailsModal.Location', { ns: 'settingsAsset' })}
        </GroupHeading>
        <Group style={{ marginBottom: '15px' }}>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Floor', { ns: 'settingsAsset' })} #:</Label>
            <Value>{meter.floorNumber}</Value>
          </FlexRow>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.Space', { ns: 'settingsAsset' })}:</Label>
            <Value>{meter.spaceName}</Value>
          </FlexRow>
          <FlexRow>
            <Label>{t('Buildings.EnergyMeters.MeterDetailsModal.SpaceType', { ns: 'settingsAsset' })}:</Label>
            <Value>{SpaceType_DisplayString(meter.spaceType)}</Value>
          </FlexRow>
        </Group>

      </Column>
    </Modal>
  );
};

export default MeterDetailsModal;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 20px;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  width: 175px;
  color: ${p => p.theme.palette.text.medium};
  text-align: right;
  font-weight: 500;
`;

const Value = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  width: 175px;
  color: ${p => p.theme.palette.text.fair};
`;

const GroupHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  align-self: flex-start;
  margin: 0 0 2px 25px;
`;

const Group = styled.div`
  width: 350px;
  padding: 10px;
  border: 1px solid ${p => p.theme.palette.borders.weak};
  box-shadow: 0 3px 7px -1px ${p => p.theme.palette.shadows.medium};;
  border-radius: 5px;
`;