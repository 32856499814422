import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { AbbreviatedToFullMonthName, getAbbreviatedMonthName } from '@shared/utils/DateUtils';
import { numberWithCommas } from '@shared/utils/NumberUtils';
import { BarController, BarElement, Chart, ChartData, ChartOptions, ChartTypeRegistry, Filler, Legend, TooltipItem } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';
import { transparentize } from 'polished';
import { useCallback, useEffect, useRef } from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components';

Chart.register(BarElement, BarController, Legend);

export type MonthlyConsumptionDataset = {
  dataset: number[],
  dataUnit: string,
  color: string,
  label: string
};

export type SeasonalityChartProps = {
  datasets: MonthlyConsumptionDataset[];
  labels: string[];
  startDate: Date;
}

const SeasonalityChart = ({ datasets, labels, startDate }: SeasonalityChartProps) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { trackAction } = useAnalytics();

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, number[], string> => {
    return {
      labels: labels,
      datasets: datasets.map((dataset: MonthlyConsumptionDataset) => (
        {
          data: dataset.dataset,
          dataUnit: dataset.dataUnit,
          backgroundColor: dataset.color,
          borderColor: dataset.color,
          categoryPercentage: datasets.length === 1 ? 0.4 : 0.7,
          barPercentage: datasets.length === 1 ? 1 : 0.9,
          label: dataset.label,
          borderWidth: 0.5
        }
      )),
    }
  }, [datasets, labels]);

  const legendCallback = useCallback(() => {
    trackAction('target_actual_toggle', 'fair_use');
  }, [trackAction]);

  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const chart = new Chart(context, {
        type: 'bar',
        data: constructData(),
        options: constructOptions(theme, labels, startDate, datasets.length > 1, legendCallback),
        plugins: [Filler]
      });

      return () => chart.destroy();
    }
  }, [theme, constructData, labels, startDate, datasets.length, legendCallback]);

  return (
    <Wrapper>
      <canvas id="myChart" ref={canvasRef} />
    </Wrapper>
  );
}

export default SeasonalityChart;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const constructOptions = (theme: DefaultTheme, labels: string[], startDate: Date, showLegend: boolean, legendCallback: () => void): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  const yLabel = 'MWh';
  const xLabel = labels.length > 0 && labels[0] === getAbbreviatedMonthName(1) ? new Date(startDate).getFullYear() : `${new Date(startDate).getFullYear()}/${new Date(startDate).getFullYear() + 1}`;
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          align: 'end',
          text: xLabel.toString(),
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '500',
          }
        },
        grid: {
          display: false,
          borderColor: transparentize(0.5, theme.palette.charts.gridLineColor),
          borderWidth: 1,
        },
        ticks: {
          display: true,
          maxRotation: 0,
          padding: 0,
          color: theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: 14,
            weight: '500',
          },
        },
      },
      y: {
        title: {
          display: true,
          align: 'end',
          text: yLabel,
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '500',
          }
        },
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.7, theme.palette.charts.gridLineColor),
        },
        ticks: {
          padding: 0,
          font: {
            family: 'DM Sans',
            size: 14,
            weight: '500',
          },
          callback: (value: string | number) => {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(Number(value));
          }
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 5,
      }
    },
    plugins: {
      legend: {
        display: showLegend,
        title: {
          padding: 0,
          color: theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: 14,
            weight: '500',
          },
        },
        align: 'end',
        labels: {
          boxHeight: 16,
          boxWidth: 16,
          padding: 30
        },
        onClick: function (e, legendItem, lengend) {
          legendCallback()
          Chart.defaults.plugins.legend.onClick.call(this, e, legendItem, lengend);
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        displayColors: true,
        usePointStyle: true,
        boxHeight: 10,
        caretPadding: 12,
        caretSize: 7,
        backgroundColor: theme.palette.backgrounds.surface,
        cornerRadius: 3,
        padding: 6,
        borderColor: theme.palette.borders.medium,
        borderWidth: 1,
        titleMarginBottom: 3,
        titleColor: theme.palette.text.fair,
        titleFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        bodyColor: theme.palette.text.medium,
        bodyFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex
        },
        callbacks: {
          title: (items: TooltipItem<'bar'>[]) => {
            const label = items[0]?.label;
            return `${AbbreviatedToFullMonthName(label)}`;
          },
          label: (item: TooltipItem<'bar'>) => {
            if (isNaN(item.raw as number)) {
              return ' --'
            }
            return ` ${numberWithCommas(item.raw as number, 0)} ${yLabel}`;
          }
        },
      }
    }
  };
}