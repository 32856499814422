import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

type PropTypes = {
  visible: boolean;
  close: () => void;
}

const FullScreenOverlay = ({ visible, close }: PropTypes) => {
  const { t } = useTranslation();

  const handleEscapeKey = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      close();
    }
  }, [close]);

  useEffect(() => {
    if (visible) document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey, visible]);

  return (
    <Overlay visible={visible}>
      {t('FullScreenModeMessage', { ns: 'common' })}

      <Divider />

      <ExitButton onClick={close}>
        {t('Exit', { ns: 'common' })}
      </ExitButton>
    </Overlay>
  );
};

export default FullScreenOverlay;

const Overlay = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 30px;    
  right: 30px;
  z-index: 89;
  border-radius: 30px;
  background-color: #6f748c;
  color: ${p => p.theme.text.contrast};
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.12);
  height: 38px;
  padding-left: 18px;
  font-size: 12px;
  display: flex;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  ${p => p.visible && css`
    visibility: visible;
    opacity: 1;
    transition: opacity 300ms ease;
  `}
`;

const Divider = styled.div`
  width: 1px;
  height: 28px;
  background-color: #626781;
  margin-left: 14px;
`;

const ExitButton = styled.div`
  height: 38px;
  padding: 0 18px 0 14px;
  border-radius: 0 30px 30px 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.primary.main};
  }
`;