import { Route as RouteType } from '@shared/types/Route';
import { useLayoutContext } from '@src/contexts/LayoutContext';
import styled, { css } from 'styled-components';
import NavItem from './NavItem';

type PropTypes = {
  routes?: RouteType[];
}

const Sidebar = ({ routes }: PropTypes) => {
  const { isFullScreen, isSidebarCollapsed } = useLayoutContext();

  return (
    <Container isCollapsed={isSidebarCollapsed} isHidden={isFullScreen}>
      {routes?.map((route, i) => route.link && (
        <NavItem
          key={route.link.label}
          route={route}
          lastItem={routes.length - 1 === i}
          isCollapsed={isSidebarCollapsed}
        />
      ))}
    </Container>
  );
};

export default Sidebar;

const Container = styled.div<{ isCollapsed: boolean, isHidden: boolean }>`
  background-color: ${p => p.theme.background.container};
  border-right: 1px solid ${p => p.theme.action.divider};
  box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: auto;
  width: 184px;
  transition: width 300ms ease;
  z-index: 1;

  ${p => p.isCollapsed && css`
    width: 48px;
  `}

  ${p => p.isHidden && css`
    display: none;
  `}

  @media print {
    display: none;
  }
`;