import { useEffect, useState } from 'react';
import { NEW_UPLOAD } from './useFileHandler';
import { BlobGetSasUriQuery } from '@shared/api/queries/Blob/BlobGetSasUriQuery';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { useApi } from './useApi';

export const useFileFromBlobStorage = (blobName?: string | null, container?: BlobStorageContainerType, customContainerName?: string) => {
  const { execute, loading } = useApi();
  const [blobUri, setBlobUri] = useState<string>();

  useEffect(() => {
    const getSasUri = async (blob: string) => {
      const result = await execute({
        query: new BlobGetSasUriQuery(blob, container, customContainerName)
      });
      result && setBlobUri(result.blobUri);
    };

    if (blobName === NEW_UPLOAD) {
      return;
    }

    if (!blobName) {
      setBlobUri(undefined);
      return;
    }

    if (blobName) {
      getSasUri(blobName);
    }
  }, [blobName, container, customContainerName, execute, setBlobUri]);

  return { blobUri, setBlobUri, loading };
};
