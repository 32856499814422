import { SiteOperatorDto } from '@shared/api/models/ResidentApp/SiteOperatorDto';
import { OperatorDeleteQuery } from '../common/OperatorDeleteQuery';

export class SiteOperatorResidentAppDeleteCommand extends OperatorDeleteQuery<SiteOperatorDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return '/SiteOperators'
  }
}