import PeopleCountingGetBySiteIdQuery from '@dashboard/api/queries/peopleCounting/PeopleCountingGetBySiteIdQuery';
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApiState } from '@shared/hooks/useApiState';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { orderBy } from 'lodash';
import { transparentize } from 'polished';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import WidgetCard from '../../WidgetCard';
import OccupancyModal from './OccupancyModal';
import SpaceOccupancy from './SpaceOccupancy';

const OccupancyWidget = () => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const [index, setIndex] = useState(0);

  const { transform: spaces, loading } = useApiState({
    query: new PeopleCountingGetBySiteIdQuery(site.id),
    transformFunc: spaces => orderBy(spaces, [x => x.buildingName.toLowerCase(), x => x.spaceName.toLowerCase()])
  }, [site]);

  if (spaces?.length === 0) {
    return null;
  }

  const numSpaces = spaces?.length ?? 0;
  const isFirstItem = index === 0;
  const isLastItem = spaces && index === numSpaces - 1;
  const currentSpace = spaces?.[index];

  return (
    <WidgetCard
      icon={solid('people-roof')}
      title={t('Occupancy', { ns: 'molecules' })}
      loading={{
        state: loading,
        icon: duotone('people-roof'),
        label: t('Loading', { ns: 'status' })
      }}
      titleElement={<OccupancyModal spaces={spaces} />}
    >
      <Container>
        {spaces && spaces.length > 1 &&
          <CarouselControls>
            <Chevron
              icon={solid('chevron-left')}
              onClick={() => setIndex(prevState => isFirstItem ? numSpaces - 1 : prevState - 1)}
            />
            <CarouselCounter>
              {index + 1}/{spaces?.length}
            </CarouselCounter>
            <Chevron
              icon={solid('chevron-right')}
              onClick={() => setIndex(prevState => isLastItem ? 0 : prevState + 1)}
            />
          </CarouselControls>
        }

        <FlexRow>
          <Carousel>
            {currentSpace &&
              <SpaceOccupancy
                key={currentSpace.spaceId}
                space={currentSpace}
              />
            }
          </Carousel>
        </FlexRow>
      </Container>
    </WidgetCard>
  )
}

export default OccupancyWidget;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 250px;
`;

const Carousel = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CarouselControls = styled.div`
  position: absolute;
  top: -36px;
  right: 24px;
  display: flex;
  align-items: center;
`;

const Chevron = styled(FontAwesomeIcon) <{ disabled?: boolean }>`
  width: 14px;
  height: 14px;
  color: ${p => transparentize(0.5, p.theme.action.disabled)};
  padding: 4px;
  border-radius: 50%;
  
  ${p => !p.disabled && css`
    color: ${p => p.theme.action.active};
    cursor: pointer;

    &:hover {
      background-color: ${p => p.theme.primary.hover};
    }
  `}
`;

const CarouselCounter = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => transparentize(0.2, p.theme.text.secondary)};
`;