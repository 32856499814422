import { transparentize } from 'polished';
import styled, { css, useTheme } from 'styled-components';
import { IButton } from './Button.types';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

export const Button = (props: IButton) => {
  const theme = useTheme();
  const { trackAction } = useAnalytics();

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.analytics) {
      trackAction(props.analytics.action, props.analytics.category);
    }

    if (!props.disabled && !props.loading && props.onClick) {
      props.onClick(e);
    }
  };

  const ButtonElement = (
    <StyledButton
      data-cy={props.cypress_id || ''}
      primary={!props.secondary && !props.tertiary}
      secondary={props.secondary}
      tertiary={props.tertiary}
      color={props.color}
      labelColor={props.labelColor}
      hoverBackgroundColor={props.hoverBackgroundColor}
      isLoading={props.loading}
      circle={props.circle}
      disabled={props.disabled || props.loading}
      small={props.small}
      onClick={handleOnClick}
      style={props.style}
    >
      {props.label}

      {props.loading &&
        <IconContainer
          secondary={props.secondary}
          tertiary={props.tertiary}
          color={props.color}
          style={{ marginLeft: '10px' }}
        >
          <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
        </IconContainer>
      }
    </StyledButton>
  );

  return (
    <>
      {!props.tooltip &&
        ButtonElement
      }

      {props.tooltip &&
        <div style={{ position: 'relative' }}>
          <Tooltip
            hideTriangle
            distance={3}
            fontSize='14px'
            tooltipColor={transparentize(0.3, theme.palette.tooltip.background.primary)}
            {...props.tooltip}
          >
            {ButtonElement}
          </Tooltip>
        </div>
      }
    </>
  );
};

const StyledButton = styled.div<{ primary: boolean, secondary?: boolean, tertiary?: boolean, color?: string, labelColor?: string, hoverBackgroundColor?: string; isLoading?: boolean, circle?: boolean, disabled?: boolean, small?: boolean }>`
  // Common styles
  width: max-content;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: ${p => p.small ? '5px 10px' : '8px 16px'};
  font-weight: 500;
  font-size: 14px;
  font-family: 'DM Sans';
  text-align: center;
  transition: all 150ms ease-out;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  // Primary styles
  ${p => p.primary && css`
    color: ${p.labelColor ?? p.theme.palette.text.onPrimary};
    background-color: ${p.color ?? p.theme.palette.primary};
    border-color: ${p.color ?? p.theme.palette.primary};

    ${(!p.disabled && !p.isLoading) && css`
      &:hover {
        box-shadow: 0px 6px 8px -4px ${transparentize(0.4, p.color ?? p.theme.palette.primary)};
      }
    `}

    ${(p.disabled || p.isLoading) && css`
      cursor: not-allowed;
      background-color: ${transparentize(0.3, p.color ?? p.theme.palette.primary)};
      border-color: transparent;
    `}
  `}
  
  // Secondary styles
  ${p => p.secondary && css`
    color: ${p.color ?? p.theme.palette.primary};
    background-color: transparent;
    border-color: ${p.color ?? p.theme.palette.primary};

    ${!p.disabled && css`
      &:hover {
        color: ${p.theme.palette.text.onPrimary};
        background-color: ${p.color ?? p.theme.palette.primary};
        box-shadow: 0px 6px 8px -4px ${transparentize(0.4, p.color ?? p.theme.palette.primary)};
      }
    `};

    ${p.disabled && css`
      cursor: not-allowed;
      color: ${transparentize(0.2, p.color ?? p.theme.palette.primary)};
      border-color: ${transparentize(0.2, p.color ?? p.theme.palette.primary)};
    `}
  `}

  // Tertiary styles
  ${p => p.tertiary && css`
    color: ${p.color ?? p.theme.palette.primary};

    &:hover {
      background-color: ${p.hoverBackgroundColor ?? p.theme.palette.buttons.hoverBackground};
      border-color: ${p.hoverBackgroundColor ?? p.theme.palette.buttons.hoverBackground};
    }

    ${p.disabled && css`
      cursor: not-allowed;
      color: ${transparentize(0.2, p.color ?? p.theme.palette.primary)};
    `}
  `}
  
  // Circle styles
  ${p => p.circle && css`
    padding: 9px;
    border-radius: 50%;
  `}
`;

const IconContainer = styled.div<{ color?: string, secondary?: boolean, tertiary?: boolean }>`
  display:  inline-block;
  text-align: left;
  margin-left: 10px;
  line-height: 14px;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: ${p => p.color ?? p.theme.palette.text.onPrimary};
    
      // Tertiary styles
      ${p => (p.secondary || p.tertiary) && css`
        fill: ${p.color ?? p.theme.palette.primary};
      `}
    }
  }
`;