import { OperatorPrizeWinnerDto } from '@shared/api/models/ResidentApp/OperatorPrizeWinnerDto';
import { OperatorDeleteQuery } from '@shared/api/queries/ResidentApp/common/OperatorDeleteQuery';

export class PrizeWinnersResidentAppDeleteCommand extends OperatorDeleteQuery<OperatorPrizeWinnerDto> {
  prizeWinnerId: number;

  constructor(prizeWinnerId: number) {
    super();
    this.prizeWinnerId = prizeWinnerId;
  }

  public targetUrl(): string {
    return '/PrizeWinners';
  }
}
