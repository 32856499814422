import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import AlertRuleCard from '../AlertRuleCard';
import { AlertRuleFlexChild, AlertRuleFlexRow, AlertRuleLabel, HelperMessage } from '../AlertRuleConfiguration';
import { AlertRuleAction, AlertRuleActionType, DefaultActionRuleSchedule } from '@shared/api/models/AlertRule/AlertRule';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { validateEmail } from '@shared/utils/StringUtils';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { Select } from '@shared/components/atoms/Select/Select';
import { Input } from '@shared/components/atoms/Form/Form';

interface IActionConfiguration {
  enabled?: boolean;
  actions: AlertRuleAction[],
  onChange: (actions: AlertRuleAction[]) => void;
}

const ActionConfiguration = ({ enabled, actions, onChange }: IActionConfiguration) => {
  const { t } = useTranslation(['settingsAlerting']);
  const theme = useTheme();

  return (
    <AlertRuleCard
      title={t('ActionConfiguration.Title', { ns: 'settingsAlerting' })}
      enabled={enabled}
      open={true}
      icon={regular('share-nodes')}
    >

      {actions.map((action, i) => (
        <Action
          key={i}
          action={action}
          onChange={action => onChange(actions.map((x, j) => i === j ? action : x))}
          remove={() => {
            const clone = [...actions];
            clone.splice(i, 1);
            onChange(clone)
          }}
        />
      ))}

      <div style={{ width: 'max-content', marginTop: '30px' }}>
        <Tooltip
          content={t('ActionConfiguration.Tooltip', { ns: 'settingsAlerting' })}
          placement={TooltipPlacement.Right}
          fontSize='12px'
        >
          <Circle
            color={theme.palette.primary}
            onClick={() => onChange([...actions, {}])}>
            <FontAwesomeIcon icon={solid('plus')} />
          </Circle>
        </Tooltip>
      </div>
    </AlertRuleCard>
  );
};

export default ActionConfiguration;

const Circle = styled.div<{ color: string }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid ${p => p.color};
  
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${p => p.color};

  &:hover {
    color: ${p => p.theme.palette.text.onPrimary};
    background-color:${p => p.color};
  }
`;

interface IAction {
  action: AlertRuleAction;
  onChange: (action: AlertRuleAction) => void;
  remove: () => void;
}

const Action = ({ action, onChange, remove }: IAction) => {
  const { t } = useTranslation(['settingsAlerting']);
  const theme = useTheme();
  const [helperMessage, setHelperMessage] = useState<string>();

  const getLabel = (type: AlertRuleActionType) => {
    switch (type) {
      case AlertRuleActionType.SignalR:
        return t('ActionConfiguration.InAppNotification', { ns: 'settingsAlerting' })
      case AlertRuleActionType.EmailDigest:
        return t('ActionConfiguration.EmailDigest', { ns: 'settingsAlerting' })
      case AlertRuleActionType.Email:
        return t('ActionConfiguration.EmailAction', { ns: 'settingsAlerting' })
      case AlertRuleActionType.Webhook:
        return t('ActionConfiguration.Webhook', { ns: 'settingsAlerting' })
      default:
        break;
    }
  }

  return (
    <AlertRuleFlexRow style={{ marginBottom: '12px' }}>
      <div style={{ marginTop: '30px', marginRight: '5px' }}>
        <Tooltip
          content={t('ActionConfiguration.RemoveAction', { ns: 'settingsAlerting' })}
          placement={TooltipPlacement.Right}
          fontSize='12px'
        >
          <Circle
            color={theme.palette.red}
            onClick={remove}
          >
            <FontAwesomeIcon icon={solid('xmark')} />
          </Circle>
        </Tooltip>
      </div>

      <AlertRuleFlexChild>
        <AlertRuleLabel>{t('ActionConfiguration.Action', { ns: 'settingsAlerting' })}</AlertRuleLabel>
        <Select
          value={action.type && { label: getLabel(action.type), value: action.type }}
          options={Object.values(AlertRuleActionType)
            .map(x => ({ label: getLabel(x), value: x }))}
          onChange={selected => {
            if (selected) {
              setHelperMessage('');
              onChange({
                type: selected.value,
                schedule:
                  (selected.value === AlertRuleActionType.EmailDigest
                    ? DefaultActionRuleSchedule
                    : null),
                target: null
              });
            }
          }}
        />
      </AlertRuleFlexChild>

      {action.type && action.type !== AlertRuleActionType.SignalR &&
        <AlertRuleFlexChild style={{ maxWidth: 'unset' }}>
          <AlertRuleLabel>{action.type === AlertRuleActionType.Email || action.type === AlertRuleActionType.EmailDigest ? t('ActionConfiguration.Email', { ns: 'settingsAlerting' }) : t('ActionConfiguration.Target', { ns: 'settingsAlerting' })}</AlertRuleLabel>
          <Input
            type="text"
            autoComplete='new-off' // random value to prevent auto-filling in modern browsers.
            value={action.target ?? ''}
            onInput={(e) => {
              onChange({ ...action, target: e.currentTarget.value });

              if (action.type === AlertRuleActionType.Email || action.type == AlertRuleActionType.EmailDigest) {
                if (!validateEmail(e.currentTarget.value)) {
                  setHelperMessage(t('ActionConfiguration.EmailHelper', { ns: 'settingsAlerting' }));
                } else {
                  setHelperMessage('');
                }
              }
            }}
          />

          <HelperMessage style={{ margin: '1px 4px' }}>
            {helperMessage}
          </HelperMessage>
        </AlertRuleFlexChild>
      }

    </AlertRuleFlexRow>
  );
}
