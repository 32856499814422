import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Route as RouteType } from '@shared/types/Route';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Title } from '@shared/components/atoms/Title/Title';
import TabBar from '@src/components/shared/TabBar/TabBar';

type PropTypes = {
  routes?: RouteType[];
}

const Header = ({ routes }: PropTypes) => {
  const { t } = useTranslation();

  return (
    <Container>
      <BackButton
        label={t('BackToBuilding', { ns: 'navigation' })}
        url='./..'
      />

      <Title
        text={t('BuildingInformation', { ns: 'common' })}
        style={{ padding: '4px 0 15px 0' }}
      />

      <TabBar
        routes={routes}
      />
    </Container>
  );
};

export default Header;

const Container = styled.div`
  padding: 20px 40px 0 40px;
  background-color: ${p => p.theme.background.container};
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;