export enum DeviceType {
  Environmental = 'Environmental',
  Metering = 'Metering',
  Monitoring = 'Monitoring',
  PeopleCounting = 'PeopleCounting',
  PulseCounter = 'PulseCounter',
  Relay = 'Relay',
  Switch = 'Switch',
  SmartPanelHeater = 'SmartPanelHeater',
  TRV = 'TRV',
  Unknown = 'Unknown',
  VirtualPeopleCounting = 'VirtualPeopleCounting',
  HeatingCooling = 'HeatingCooling'
}