import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

type PropTypes = {
  label?: string;
  icon?: IconDefinition;
  iconColor?: string;
  subLabel?: string;
  styles?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
  subLabelStyles?: React.CSSProperties;
  iconStyles?: React.CSSProperties;
  large?: boolean;
}

const NoData = ({ label, icon, iconColor, subLabel, styles, labelStyles, subLabelStyles, iconStyles, large }: PropTypes) => {

  return (
    <FlexColumn style={styles}>
      <Icon
        icon={icon ?? regular('empty-set')}
        color={iconColor}
        $large={large}
        style={iconStyles}
      />

      {label &&
        <Label large={large} style={labelStyles}>
          {label}
        </Label>
      }

      {subLabel &&
        <Sublabel large={large} style={subLabelStyles}>
          {subLabel}
        </Sublabel>
      }
    </FlexColumn>
  );
};

export default NoData;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon) <{ color?: string, $large?: boolean }>`
  font-size: ${p => p.$large ? '32px' : '24px'};
  color: ${p => p.color ?? p.theme.palette.primary};
`;

const Label = styled.div<{ large?: boolean }>`
  font-size: ${p => p.large ? '16px' : '14px'};
  line-height: 20px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  text-align: center;
  margin-top: 20px;
`;

const Sublabel = styled.div<{ large?: boolean }>`
  font-size: ${p => p.large ? '14px' : '12px'};
  line-height: 20px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  text-align: center;
  margin-top: 15px;
`;