import styled, { useTheme } from 'styled-components'
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { DeviceNoteCreateCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteCreateCommand';
import { Button } from '@shared/components/atoms/Button/Button';
import { TextArea } from '@shared/components/atoms/Form/Form';
import { DeviceNoteSource } from '@dashboard/api/enums/DeviceNoteSource';

interface PropTypes {
  deviceId: number;
  refreshNotes: () => void;
  onCancel: () => void;
}

const SingleDeviceNoteCreate = ({ deviceId, refreshNotes, onCancel }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute, loading } = useApi();
  const [content, setContent] = useState<string>('');

  const onSave = async () => {
    await execute({ query: new DeviceNoteCreateCommand(deviceId, content, DeviceNoteSource.Web) });
    refreshNotes();
    onCancel();
  };

  return (
    <Item>
      <StyledTextArea
        onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => setContent(e.currentTarget.value)}
        placeholder={t('DeviceCard.AddNote', { ns: 'molecules' })}
        value={content}
      />

      <FlexRow>
        <Button
          tertiary
          label={t('Cancel', { ns: 'common' })}
          onClick={onCancel}
          color={theme.palette.red}
          disabled={loading}
        />
        <Button
          label={t('Save', { ns: 'common' })}
          onClick={onSave}
          disabled={loading || !content}
        />
      </FlexRow>
    </Item>
  );
}

export default SingleDeviceNoteCreate;

const Item = styled.div`
  margin-bottom: 10px;
`;

const StyledTextArea = styled(TextArea)`
  background-color: transparent;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  height: 100px;
  box-shadow: 0 1px 2px 0 ${p => p.theme.shadow.primary};

  &::placeholder {
    color: ${p => p.theme.text.light};
    font-weight: 400;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 8px;
`;