import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DailyConsumptionChart, { DailyConsumptionDataset } from './DailyConsumptionChart';
import { ReportEnergyUsageSeriesDto } from '@dashboard/api/models/ReportEnergyConsumptionDto';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { getMetricTypeFromEnergyMeterType } from './helpers/ModifierHelpers';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';

interface IDailyConsumptionChart {
  color: string;
  seriesData: ReportEnergyUsageSeriesDto[];
  dataUnit: string;
  inPrintMode: boolean;
  energyMeterType: EnergyMeterType;
}

const DailyConsumptionChartWrapper = ({ seriesData, dataUnit, color, inPrintMode, energyMeterType }: IDailyConsumptionChart) => {
  const { t } = useTranslation(['molecules']);
  const { localisation, toLocale } = useLocalisation();
  const datasets: DailyConsumptionDataset[] = [
    {
      label: t('ESG.DailySums', { ns: 'molecules' }),
      color: color,
      dataUnit: dataUnit,
      dataset: seriesData.map(x => toLocale(getMetricTypeFromEnergyMeterType(energyMeterType), x.value)),
    }
  ]

  const labels = seriesData.map(x => format(new Date(x.measuredOn), `${localisation?.dateFormats.weekdayName}`));

  return (
    <Wrapper>
      <DailyConsumptionChart
        datasets={datasets}
        labels={labels}
        inPrintMode={inPrintMode}
      />
    </Wrapper>
  );
};

export default DailyConsumptionChartWrapper;

const Wrapper = styled.div`
  height: 120px;
  overflow: hidden;
  
  @media print { 
    height: 25mm;
  }
`;