import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlerRuleScheduleTimeOfDay, AlertRuleSchedule, AlertRuleScheduleDay, AlertRuleScheduleType } from '@shared/api/models/AlertRule/AlertRule';
import { Select } from '@shared/components/atoms/Select/Select';
import { getDayName } from '@shared/utils/DateUtils';
import { lowerCase, upperFirst } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AlertRuleCard from '../AlertRuleCard';
import { AlertRuleLabel, HelperMessage } from '../AlertRuleConfiguration';
import TimeOptions from './ScheduleTimeOptions';

const timeToHrsMins = (timeOfDay: AlerRuleScheduleTimeOfDay) => {
  return String(timeOfDay.hour).padStart(2, '0') + ':' + String(timeOfDay.minute).padStart(2, '0');
};

const afterFromTimeFilter = (timeOptions: { label: string; value: AlerRuleScheduleTimeOfDay; }[], fromTime: AlerRuleScheduleTimeOfDay) => {
  return timeOptions.filter(x => x.value.hour === fromTime.hour
    ? x.value.minute > fromTime.minute
    : x.value.hour >= fromTime.hour
  );
}

interface IScheduleConfiguration {
  enabled?: boolean;
  schedule?: AlertRuleSchedule;
  onChange: (schedule?: AlertRuleSchedule) => void
}

const ScheduleConfiguration = ({ enabled, schedule, onChange }: IScheduleConfiguration) => {
  const { t } = useTranslation(['settingsAlerting']);
  const [helperMessage, setHelperMessage] = useState<string>(t('ScheduleConfiguration.HelperMessage', { ns: 'settingsAlerting' }));

  const openCard = () => {
    // If unset, set schedule to default (Every day)
    if (!schedule?.type) {
      updateSchedule(AlertRuleScheduleType.EveryDay);
    }
  }

  const updateSchedule = (type: AlertRuleScheduleType) => {
    const message = t('ScheduleConfiguration.HelperMessage', { ns: 'settingsAlerting' });

    const numDays =
      (type === AlertRuleScheduleType.Weekdays && 5)
      || (type === AlertRuleScheduleType.Weekends && 2)
      || 7;

    const dayOffset = type === AlertRuleScheduleType.Weekends ? 6 : 1;

    const days: AlertRuleScheduleDay[] = [...Array(numDays)].map((_, i) => ({ day: i + dayOffset, from: { hour: 8, minute: 0 }, to: { hour: 17, minute: 0 }, enabled: true }));

    setHelperMessage(message);
    onChange({ type: type, days: days });
  };

  return (
    <AlertRuleCard
      title={t('ScheduleConfiguration.Schedule', { ns: 'settingsAlerting' })}
      optional
      onOpenOptional={openCard}
      onCloseOptional={() => onChange(undefined)}
      open={schedule?.type !== undefined}
      icon={regular('clock')}
      enabled={enabled}
      helperMessage={<span>{t('ScheduleConfiguration.Example', { ns: 'settingsAlerting' })}</span>}
    >
      {schedule && schedule.type &&
        <>
          <FlexRow>
            <div style={{ flexBasis: '33%' }}>
              <AlertRuleLabel>{t('ScheduleConfiguration.AllowAlerts', { ns: 'settingsAlerting' })}</AlertRuleLabel>
              <Select
                value={schedule.type && { label: upperFirst(lowerCase(schedule.type)), value: schedule.type }}
                options={Object.entries(AlertRuleScheduleType).map(x => ({ label: upperFirst(lowerCase(x[0])), value: x[1] }))}
                onChange={selected => selected && updateSchedule(selected.value)}
              />
            </div>

            {schedule.type !== AlertRuleScheduleType.Custom &&
              <>
                <div>
                  <AlertRuleLabel>{t('ScheduleConfiguration.From', { ns: 'settingsAlerting' })}</AlertRuleLabel>
                  <Select
                    value={{ label: timeToHrsMins(schedule.days[0].from), value: schedule.days[0].from }}
                    options={TimeOptions}
                    onChange={selected => selected && onChange({ ...schedule, days: schedule.days.map(x => ({ ...x, from: selected.value })) })}
                  />
                </div>
                <div>
                  <AlertRuleLabel>{t('ScheduleConfiguration.To', { ns: 'settingsAlerting' })}</AlertRuleLabel>
                  <Select
                    value={{ label: timeToHrsMins(schedule.days[0].to), value: schedule.days[0].to }}
                    options={afterFromTimeFilter(TimeOptions, schedule.days[0].from)}
                    onChange={selected => selected && onChange({ ...schedule, days: schedule.days.map(x => ({ ...x, to: selected.value })) })}
                  />
                </div>
              </>
            }
          </FlexRow>

          <CustomDaysConfiguration
            schedule={schedule}
            onChange={(days) => {
              onChange({ type: schedule.type, days: days })
            }}
          />

          <HelperMessage>
            {helperMessage}
          </HelperMessage>
        </>
      }
    </AlertRuleCard>
  );
};

export default ScheduleConfiguration;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  > div {
    flex-grow: 1;
    min-width: 160px;
    max-width: 33%;
  }
`;

interface ICustomDaysConfiguration {
  schedule: AlertRuleSchedule;
  onChange: (days: AlertRuleScheduleDay[]) => void;
}

const CustomDaysConfiguration = ({ schedule, onChange }: ICustomDaysConfiguration) => {
  if (schedule.type !== AlertRuleScheduleType.Custom) {
    return null;
  }

  return (
    <>
      {schedule.days.map((day, i) => (
        <FlexRow key={i} style={{ alignItems: 'center', marginTop: '8px', height: '38px', flexWrap: 'nowrap' }}>
          <DayName enabled={day.enabled}>
            <span>
              {getDayName(day.day)}
            </span>
            <div style={{ marginLeft: 'auto' }} onClick={() => onChange(schedule.days.map((x, j) => ({
              ...x,
              enabled: i === j ? !x.enabled : x.enabled
            })))}>
              <CustomDaySelect day={day} />
            </div>
          </DayName>

          <div style={{ flexBasis: '30%', opacity: day.enabled ? 1 : 0.4 }}>
            <Select
              isDisabled={!day.enabled}
              value={{ label: timeToHrsMins(day.from), value: day.from }}
              options={TimeOptions}
              onChange={selected => selected && onChange(schedule.days.map((x, j) => ({
                ...x,
                from: i === j ? selected.value : x.from
              })))}
            />
          </div>
          <div style={{ flexBasis: '30%', opacity: day.enabled ? 1 : 0.4 }}>
            <Select
              isDisabled={!day.enabled}
              value={{ label: timeToHrsMins(day.to), value: day.to }}
              options={afterFromTimeFilter(TimeOptions, day.from)}
              onChange={selected => selected && onChange(schedule.days.map((x, j) => ({
                ...x,
                to: i === j ? selected.value : x.to
              })))} />
          </div>

        </FlexRow>
      ))}
    </>
  );
}

const DayName = styled.div<{ enabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.enabled ? p.theme.palette.text.fair : p.theme.palette.text.weak};
  flex-basis: 33%;
  padding-left: 11px;

  display: flex;
`;

interface ICustomDaySelect {
  day: AlertRuleScheduleDay;
}

const CustomDaySelect = ({ day }: ICustomDaySelect) => {
  return (
    <Circle enabled={day.enabled}>
      <FontAwesomeIcon icon={solid('check')} />
    </Circle>
  )
}

const Circle = styled.div<{ enabled: boolean }>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color:${p => p.enabled ? p.theme.palette.primary : 'transparent'};
  cursor: pointer;
  border: 1px solid ${p => p.enabled ? p.theme.palette.primary : p.theme.palette.borders.strong};
  
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${p => p.theme.palette.text.onPrimary};
`;