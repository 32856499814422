import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { IInternalNode } from '../CascaderSingleSelect.types';

interface ICheckbox<TValue> {
  node: IInternalNode<TValue>;
  selected: boolean;
  onSelect: (node: IInternalNode<TValue>) => void;
  childOfSelected?: boolean;
}

export const Checkbox = <TValue,>({ node, selected, onSelect, childOfSelected }: ICheckbox<TValue>) => {
  const handleSelect = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (!node.disabled) {
      onSelect(node);
    }
  };

  if (!node.selectable) {
    return null;
  }

  return (
    <StyledCheckbox
      selected={selected}
      disabled={node.disabled}
      onClick={e => handleSelect(e)}
    >
      {selected &&
        <CheckIcon icon={solid('check')} />
      }
      {childOfSelected &&
        <CheckIcon icon={solid('check')} $dark />
      }
    </StyledCheckbox>
  )
}

const CheckIcon = styled(FontAwesomeIcon) <{ $dark?: boolean }>`
  font-size: 11px;
  color: ${p => p.$dark ? p.theme.palette.primary : p.theme.palette.text.onPrimary};
`;

const StyledCheckbox = styled.div<{ selected: boolean, disabled?: boolean }>`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border: 1px solid ${p => p.theme.palette.cascader.checkboxBorder};
  background-color: ${p => p.theme.palette.cascader.checkboxBackground};
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: border-color 150ms ease;

  &:hover {
    border-color: ${p => transparentize(0.3, p.theme.palette.primary)};
  }

  ${p => p.disabled && css`
    background-color: ${p => transparentize(0.85, p.theme.palette.cascader.checkboxBorder)};
    border-color: ${p => transparentize(0.5, p.theme.palette.cascader.checkboxBorder)};
    cursor: not-allowed;

    &:hover {
      border-color: ${p => transparentize(0.5, p.theme.palette.cascader.checkboxBorder)};
    }
  `}

  ${p => p.selected && css`
    border-color: ${p.theme.palette.primary};
    background-color: ${p.theme.palette.primary};
  `}
`;

//#endregion