import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@shared/components/atoms/Button/Button';
import { UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import InputFormField from '@dashboard/components/molecules/ResidentApp/Common/InputFormField';
import LabelWithCharacterCount from '@dashboard/components/molecules/ResidentApp/Common/LabelWithCharacterCount';
import { CreateChallengeForm } from './ResidentApp_CreateChallenge';
import ResidentAppFormErrorMessage from '@dashboard/components/molecules/ResidentApp/Common/ResidentAppFormErrorMessage';
import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import IconWithText from '@dashboard/components/molecules/ResidentApp/Common/IconWithText';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { getOrdinalNumber } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';

type PrizeInfoContentProps = {
  methods?: UseFormReturn<CreateChallengeForm>;
  prizes?: OperatorPrizeDto[];
  onEdit?: () => void;
  isEditDisabled?: boolean;
  disableDelete?: boolean;
} 

const PrizeInfoContent = ({methods, prizes, onEdit, isEditDisabled, disableDelete}: PrizeInfoContentProps) => {
  const { t } = useTranslation(['molecules', 'common']);
  const { localisation } = useLocalisation();
  const theme = useTheme();
  const initialMethods = useForm<CreateChallengeForm>();
  const { fields, append, remove } = useFieldArray({
    control: methods? methods.control : initialMethods.control,
    name: 'prizes',
  })

  return (
    <div>
      <TitleWithButton
        title={t('ResidentApp.PrizeInfo')}
        button={
          prizes &&
          onEdit && (
            <Button
              label={
                <IconWithText
                  icon={regular('pencil')}
                  color={theme.palette.text.fair}
                  text={t('Edit', {ns: 'common'})}
                />
              }
              tertiary
              onClick={onEdit}
              disabled={isEditDisabled}
            />
          )
        }
      />
      <Description>{t('ResidentApp.PrizeInfoDescription')}</Description>

      <Content>
        {methods &&
          fields.map((item, index) => (
            <PrizeInput key={item.id}>
              <PrizeName>
                <LabelWithCharacterCount
                  inputName={`prizes.${index}.prizeName`}
                  label={
                    index === 0
                      ? t('ResidentApp.MainPrize')
                      : t('ResidentApp.OrdinalNumberPrize', {
                        ordinalNumber: getOrdinalNumber(index + 1),
                      })
                  }
                  characterLimit={50}
                  currentCharacterCount={methods.watch(`prizes.${index}.prizeName`)?.length ?? 0}
                />
                <>
                  <InputFormField
                    inputName={`prizes.${index}.prizeName`}
                    placeholder={t('ResidentApp.PrizeName')}
                    maxLength={50}
                    isRequired
                    requiredErrorMessage={
                      index === 0
                        ? t('MainPrizeRequired', {ns: 'validation'})
                        : t('RequiredField', {ns: 'validation'})
                    }
                    error={!!(methods?.formState.errors.prizes ?? [])[index]?.prizeName}
                  />
                  {methods.formState.errors.prizes && methods.formState.errors.prizes[index] && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.prizes[index]?.prizeName?.message}
                    />
                  )}
                </>
              </PrizeName>
              <PrizeValue>
                <InputFormField
                  inputName={`prizes.${index}.prizeValue`}
                  placeholder={t('ResidentApp.ValueCurrency', { currency: localisation.currency })}
                  inputType="number"
                  isRequired
                  requiredErrorMessage={t('ValueRequired', {ns: 'validation'})}
                  min={'0'}
                  error={!!(methods?.formState.errors.prizes ?? [])[index]?.prizeValue}
                />
                {index > 0 && !disableDelete && (
                  <RemoveItem onClick={() => remove(index)}>
                    <Icon icon={solid('xmark')} />
                  </RemoveItem>
                )}
                {methods?.formState.errors.prizes && methods.formState.errors.prizes[index] && (
                  <ResidentAppFormErrorMessage
                    message={methods.formState.errors.prizes[index]?.prizeValue?.message}
                  />
                )}
              </PrizeValue>
            </PrizeInput>
          ))}
        {prizes &&
          onEdit &&
          prizes.map((prize, index) => (
            <PrizeName key={prize.id}>
              <Label>
                {index === 0
                  ? t('ResidentApp.MainPrize')
                  : t('ResidentApp.OrdinalNumberPrize', {
                    ordinalNumber: getOrdinalNumber(index + 1),
                  })}
              </Label>
              <Text>{`${prize.prizeName} - ${localisation.currency}${prize.prizeValue}`}</Text>
            </PrizeName>
          ))}
        {methods && (
          <Button
            label={
              <IconWithText
                icon={solid('plus')}
                color={theme.palette.text.fair}
                text={t('ResidentApp.AddPrize')}
              />
            }
            onClick={() => append({ prizeName: '', prizeValue: undefined })}
            style={{ padding: '10px 0' }}
            tertiary
          />
        )}
      </Content>
    </div>
  );
}

export default PrizeInfoContent;

const Description = styled.p`
  margin: 10px 0 0 0;
  font-size: 14px;
  color: ${(p) => p.theme.palette.text.fair};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 25px;
  max-width: 760px;
`;

const PrizeInput = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  position: relative;
`;

const PrizeName = styled.div`
  width: 70%;
`;

const PrizeValue = styled.div`
  padding-top: 25px;
`;

const RemoveItem = styled.button`
  position: absolute;
  right: 15px;
  top: 25px;
  bottom: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(p) => p.theme.palette.text.fair};
  font-size: 12px;
`;

const Text = styled.p`
  margin: 8px 0 0 0;
  font-size: 14px;
  font-weight: 500;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${(p) => p.theme.palette.forms.label.color};
  display: block;
`;