import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { SpacesFairUseEnergyConsumption } from '@shared/api/models/FairUse/SpaceFairUseEnergyConsumption';
import { PostQuery } from '../../common/PostQuery';

export class SiteFairUseExceedingSpacesGetQuery extends PostQuery<SpacesFairUseEnergyConsumption> {
  policyId: number;
  siteId: number;
  seasonalityAdjustmentIds: number[];
  meterType: EnergyMeterType;

  constructor(policyId: number, siteId: number, seasonalityAdjustmentIds: number[], meterType: EnergyMeterType) {
    super();
    this.policyId = policyId;
    this.siteId = siteId;
    this.seasonalityAdjustmentIds = seasonalityAdjustmentIds;
    this.meterType = meterType;
  }

  public targetUrl(): string {
    return '/FairUse/ExceedingSpaces';
  }
}
