import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IMetricPerformanceMeterLegend {
  mean: number;
  min: number;
  max: number;
  metricType: MetricType | AltUnits;
}

const MetricPerformanceMeterLegend = ({ mean, min, max, metricType }: IMetricPerformanceMeterLegend) => {
  const { t } = useTranslation(['molecules']);
  const { toLocale } = useLocalisation();
  return (
    <Wrapper>
      <Label>
        {t('ESG.Min', { ns: 'molecules' })} {toLocale(metricType, min, { round: 0 })},
        {t('ESG.Avg', { ns: 'molecules' })} {toLocale(metricType, mean, { round: 0 })},
        {t('ESG.Max', { ns: 'molecules' })} {toLocale(metricType, max, { round: 0 })}
      </Label>
    </Wrapper>
  );
};

export default MetricPerformanceMeterLegend;

const Wrapper = styled.div`
  display: none;
  margin-top: 3px;

  @media print {
    display: flex;
  }
`;

const Label = styled.span`
  font-size: 8px;
  font-weight: 500;
  color: ${p => p.theme.palette.printStyles.text.weak};
`;