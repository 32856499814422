import ExportCsvDto from '@dashboard/api/models/ExportCsvDto';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class CsvMeasurementsGetByDeviceQuery extends PostQuery<ExportCsvDto> {
  deviceIdentifier: string;
  startDate: Date;
  endDate: Date;

  constructor(deviceIdentifier: string, startDate: Date, endDate: Date) {
    super();
    this.deviceIdentifier = deviceIdentifier
    this.startDate = startDate
    this.endDate = endDate;
  }

  public targetUrl(): string {
    return '/exports/CsvMeasurementsGetByDevice';
  }
}