import HistoricData from '@dashboard/components/molecules/HistoricData/HistoricData';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';

type PropTypes = {
  device: DeviceWithMetrics;
  isExternalAggregateDevice?: boolean;
};

const DevicePage_HistoricData = ({ device, isExternalAggregateDevice }: PropTypes) => {
  return <HistoricData device={device} isExternalAggregateDevice={isExternalAggregateDevice} />;
};

export default DevicePage_HistoricData;
