import { Switch } from '@shared/components/atoms/Switch/Switch';
import { ISwitch } from '@shared/components/atoms/Switch/Switch.types';
import styled from 'styled-components';

export type CookieTableRecord = {
  name: string;
  provider: string;
  expiration: string;
  description: string;
}

type ConsentPreferenceItemProps = {
  title: string;
  description: string;
  switchOption: ISwitch;
}

const ConsentPreferenceItem = ({ title, description, switchOption }: ConsentPreferenceItemProps) => {
  return (
    <div>
      <PreferenceTitle>{title}</PreferenceTitle>
      <SwitchItem>
        <SwitchLabel>{description}</SwitchLabel>
        <Switch {...switchOption} />
      </SwitchItem>
    </div>
  )
}

export default ConsentPreferenceItem;

const PreferenceTitle = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 10px;
`;

const SwitchItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`;

const SwitchLabel = styled.label`
  font-size: 14px;
  margin: 0;
  color: ${({ theme }) => theme.palette.forms.label.color};
`;