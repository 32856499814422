import { ClustersAndSpaces } from '@shared/api/models/Space/ClustersAndSpaces';
import { GenericDeleteQuery } from '@shared/api/queries/common/GenericDeleteQuery';

export default class SpaceBulkDeleteCommand extends GenericDeleteQuery<ClustersAndSpaces> {
  spaceIds: number[];
  clusterIds: number[];

  constructor(spaceIds: number[], clusterIds: number[]) {
    super();
    this.spaceIds = spaceIds;
    this.clusterIds = clusterIds;
  }

  public targetUrl(): string {
    return '/spaces/bulkDeleteClustersAndSpaces';
  }
}
