import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { IInternalNode } from '../CascaderMultiSelect.types';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

interface ICheckbox<TValue> {
  node: IInternalNode<TValue>;
  onSelect: (node: IInternalNode<TValue>) => void;
  disabled: boolean;
}

/**
 * Checkbox component
 */
const Checkbox = <TValue,>({ node, onSelect, disabled }: ICheckbox<TValue>) => {
  const handleSelect = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onSelect(node);
  };

  return (
    <StyledCheckbox
      selected={node.selected}
      partial={node.partial}
      disabled={disabled}
      onClick={e => handleSelect(e)}
    >
      {!node.selected && node.partial &&
        <PartialSelect />
      }
      {node.selected &&
        <CheckIcon icon={faCheck} />
      }
    </StyledCheckbox>
  )
}

export default Checkbox;

const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 11px;
  color: ${p => p.theme.palette.text.onPrimary};
`;

const PartialSelect = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${p => transparentize(0.1, p.theme.palette.primary)};
  border-radius: 1px;
`;

const StyledCheckbox = styled.div<{ selected?: boolean, partial?: boolean, disabled: boolean }>`
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  border: 1px solid ${p => p.theme.palette.cascader.checkboxBorder};
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  ${p => !p.selected && !p.disabled && css`
    &:hover {
      border-color: ${p => transparentize(0.3, p.theme.palette.primary)};
    }
  `}

  transition: border-color 150ms ease;

  ${p => !p.selected && !p.partial && p.disabled && css`
    background-color: ${p => transparentize(0.85, p.theme.palette.cascader.checkboxBorder)};
    border-color: ${p => transparentize(0.5, p.theme.palette.cascader.checkboxBorder)};
    cursor: not-allowed;
  `}

  ${p => p.selected && css`
    border-color: ${p.theme.palette.primary};
    background-color: ${p.theme.palette.primary};
  `}
`;