import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../Tooltip/Tooltip';
import { ILabelTooltip } from './LabelTooltip.types';
import styled from 'styled-components';
import { Label } from '../Form/Form';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useModal } from '@shared/hooks/useModal';
import { InformationDialog } from '@shared/components/molecules/InformationDialog/InformationDialog';

export const LabelTooltip = ({ label, tooltip, tooltipPlacement, modal }: ILabelTooltip) => {
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: modalRef } = useModal({});

  return (
    <>
      <InformationDialog
        modalRef={modalRef}
        isOpen={modalIsOpen}
        hide={toggleModal}
        title={label}
        content={tooltip}
        button={{ label: 'Close' }}
      />
      <div style={{ display: 'flex' }}>
        <Label>{label}</Label>

        <IconWrapper>
          {modal
            ? <InfoIcon
              icon={regular('question-circle')}
              onClick={toggleModal} />
            : <Tooltip
              content={<span style={{ fontSize: '14px' }}>{tooltip}</span>}
              placement={tooltipPlacement}
              hideTriangle={true}
              tooltipColor='#000000a9'
              alignWithTarget
            >
              <InfoIcon
                icon={regular('question-circle')}
              />
            </Tooltip>
          }
        </IconWrapper>
      </div>
    </>
  );
};

const InfoIcon = styled(FontAwesomeIcon)`
  width: 14px;
  height: 14px;
  padding: 5px;
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;

  &:hover {
    color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.buttons.hoverBackground};
  }
`;

const IconWrapper = styled.div`
  margin-top: -9px;
`;
