import styled from 'styled-components';
import { ITitle } from './Title.types';
import { transparentize } from 'polished';

export const Title = ({ text, subtitle, size, style, wrapperStyle, betaLabel, automationId }: ITitle) => {
  return (
    <div data-cy={automationId} style={wrapperStyle}>
      <StyledTitle size={size} style={style}>
        {text} {betaLabel && <BetaLabel>Beta</BetaLabel>}
      </StyledTitle>
      {subtitle &&
        <Subtitle style={subtitle.style}>
          {subtitle.text}
        </Subtitle>
      }
    </div>
  );
};

const StyledTitle = styled.div<{ size?: 'xs' | 'sm' | 'md' | 'lg' }>`
  display: block;
  font-weight: 500;
  color: ${p => p.theme.text.primary};

  user-select: none;

  font-size: ${p =>
    (p.size === 'xs' && '14px') ||
    (p.size === 'sm' && '18px') ||
    (p.size === 'lg' && '24px') ||
    ('20px')};
`;

const Subtitle = styled.div`
  display: block;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
  font-size: 14px;
  padding-top: 3px;
`;

const BetaLabel = styled.span`
  color: ${p => transparentize(0.55, p.theme.palette.primary)};
`;