/* eslint-disable  @typescript-eslint/no-explicit-any */

import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

interface PropTypes<TData, TTransform> {
  /**
   * Promise like async function
   */
  asyncFunc?: () => Promise<TData>;
  /**
   * Transform function to transform the result to another type
   */
  transformFunc?: (data: TData) => TTransform;
  /**
   * Initial data
   */
  initialState?: TData;
  /**
   * Initial transform data
   */
  initialTransformState?: TTransform;
  /**
   * Toast success message
   */
  successMessage?: string;
  /**
   * Toast error message
   */
  errorMessage?: string;
  /**
   * Execute function immediately (default: true)
   */
  immediate?: boolean;
}

/**
 * The return type of `useAsyncState`.
 */
type UseAsyncStateHookReturn<
  TData,
  TTransform,
  TSetData extends TData = TData
> = {
  execute: () => Promise<void>;
  loading: boolean;
  data: TData;
  transform: TTransform;
  error: any;
  setData: Dispatch<SetStateAction<TSetData>>;
  setTransform: Dispatch<SetStateAction<TTransform>>;
};

/**
 * An overloaded function type for `useAsyncState`.
 * See detailed explanation: https://stackoverflow.com/questions/70396217/how-to-conditionally-set-generic-type-of-usestate-or-remove-type-undefined-fr
 */
interface UseAsyncStateHook {
  // The "without initialState and initialTransformState" signature
  <TData, TTransform>(
    {
      ...args
    }: PropTypes<TData, TTransform> & { initialState?: undefined } & {
      initialTransformState?: undefined;
    },
    dependencies: any[]
  ): UseAsyncStateHookReturn<TData | undefined, TTransform | undefined, TData>;
  // The "without initialState" signature adds `& { initialState?: undefined }` to `ProptTypes<TData>` to make
  // `initialState` optional, and adds `| undefined` to the type of the `data` member of the returned object.
  <TData, TTransform>(
    { ...args }: PropTypes<TData, TTransform> & { initialState?: undefined },
    dependencies: any[]
  ): UseAsyncStateHookReturn<TData | undefined, TTransform, TData>;
  // The "without initialTransformState" signature
  <TData, TTransform>(
    {
      ...args
    }: PropTypes<TData, TTransform> & { initialTransformState?: undefined },
    dependencies: any[]
  ): UseAsyncStateHookReturn<TData, TTransform | undefined, TData>;
  // The "with initialState and initialTransformState" signature just uses `ProptTypes<TData>`, so `initialState` is required and is type `TData`,
  // and the type of `data` in the returned object doesn't have `undefined`, it's just `TData`.
  <TData, TTransform>(
    { ...args }: PropTypes<TData, TTransform>,
    dependencies: any[]
  ): UseAsyncStateHookReturn<TData, TTransform>;
}

/**
 * A hook to simplify and combine useState/useEffect statements relying on data which is fetched async.
 *
 * @example
 *   const { data: sites, transform: siteAssets, loading: loadingSites } = useAsyncState({
 *      asyncFunc: async () => await query.execute(),
 *      transformFunc: (sites) => sites.map(x => new Asset(x.id, x.name, x.address, x.siteImageId)),
 *      initialState: [],
 *      initialTransformState: []
 *      errorMessage: "Failed to load sites"
 *   }, []);
 */
export const useAsyncState: UseAsyncStateHook = <TData, TTransform>({
  asyncFunc,
  transformFunc,
  initialState,
  initialTransformState,
  successMessage,
  errorMessage,
  immediate = true
}: PropTypes<TData, TTransform>, dependencies: any[]) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState(initialState);
  const [transform, setTransform] = useState(initialTransformState);
  const [error, setError] = useState<any>(null);
  const isMounted = useRef<boolean>(true);

  const execute = useCallback(async () => {
    if (!asyncFunc) {
      return;
    }

    setLoading(true);

    try {
      const result = await asyncFunc();

      if (!isMounted.current) {
        return;
      }

      if (transformFunc) {
        setTransform(transformFunc(result));
      }

      setLoading(false);
      setData(result);
      successMessage && toast.success(successMessage);
    } catch (err) {
      if (!isMounted.current) {
        return;
      }

      setLoading(false);
      setError(err);
      errorMessage && toast.error(errorMessage);
      console.log(err);
    }
  }, [asyncFunc, transformFunc, successMessage, errorMessage]);

  useEffect(() => {
    isMounted.current = true;

    if (immediate) {
      execute();
    }
    return () => {
      isMounted.current = false;
    };
  }, [...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    execute,
    loading,
    data,
    transform,
    error,
    setData,
    setTransform
  };
};
