import { useCallback, useEffect, useRef } from 'react';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { transparentize } from 'polished';
import { Chart, Filler, LineController, LinearScale, BarController, BarElement, PointElement, LineElement, ChartTypeRegistry, ChartData, ChartOptions, TooltipItem, ScriptableContext, TooltipModel, TooltipCallbacks, Tick } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';

Chart.register(LineController, BarElement, BarController, LinearScale, PointElement, LineElement);

export type ChartAxisTicksCallback = (tickValue: string | number, index: number, ticks: Tick[]) => string | number | null | undefined;
export type ChartTooltipCallbacks = _DeepPartialObject<_DeepPartialObject<TooltipCallbacks<keyof ChartTypeRegistry, TooltipModel<keyof ChartTypeRegistry>, TooltipItem<keyof ChartTypeRegistry>>>> | undefined;

export type OccupancyDataset = {
  label: string,
  dataset: number[],
  gradientColors: string[],
};

export interface IOccupancyChart {
  /**
   * Chart datasets
   */
  datasets: OccupancyDataset[];
  /**
   * Array of labels
   */
  labels?: string[];
  /**
   * X-axis label
   */
  xAxisLabel?: string;
  /**
   * Y-axis label
   */
  yAxisLabel?: string;
  /**
   * X-axis ticks callback
   */
  xAxisTicksCallback?: ChartAxisTicksCallback;
  /**
   * Tooltip callbacks
   */
  tooltipCallbacks?: ChartTooltipCallbacks;
  /**
   * Is true when the page is about to open the print dialog, rerender graphs in printable dimensions.
   */
  inPrintMode: boolean;
  /**
   * Dimensions of the chart (in mm) in print mode
   */
  printDimensions: { width: number, height: number };
}

const OccupancyChart = ({ datasets, labels, xAxisLabel, yAxisLabel, tooltipCallbacks, xAxisTicksCallback, inPrintMode, printDimensions }: IOccupancyChart) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const constructData = useCallback((): ChartData<keyof ChartTypeRegistry, number[], string> => {
    return {
      labels: labels,
      datasets: datasets.map(x => (
        {
          label: x.label,
          data: x.dataset,
          backgroundColor: (scriptableContext: ScriptableContext<keyof ChartTypeRegistry>) => createGradient(scriptableContext, x.gradientColors),
          categoryPercentage: 0.6,
          barPercentage: 0.75,
        }
      )),
    }
  }, [datasets, labels]);

  /**
   * Create the chart component and attach it to the canvas element (referenced by the ref 'canvasRef').
   */
  useEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      const chart = new Chart(context, {
        type: 'bar',
        data: constructData(),
        options: constructOptions(theme, inPrintMode, xAxisLabel, yAxisLabel, tooltipCallbacks, xAxisTicksCallback),
        plugins: [Filler],
      });

      return () => chart.destroy();
    }
  }, [theme, xAxisLabel, yAxisLabel, tooltipCallbacks, xAxisTicksCallback, inPrintMode, constructData]);

  return (
    <Wrapper inPrintMode={inPrintMode} printDimensions={printDimensions}>
      <canvas id="myChart" ref={canvasRef} />
    </Wrapper>
  );
};

export default OccupancyChart;

const Wrapper = styled.div<{ inPrintMode: boolean, printDimensions: { width: number, height: number } }>`
  position: relative;
  height: 100%;
  width: 100%;

  ${p => p.inPrintMode && css`
    width: ${p.printDimensions.width}mm;
    height: ${p.printDimensions.height}mm;
  `}
`;

const constructOptions = (theme: DefaultTheme, inPrintMode: boolean, xAxisLabel?: string, yAxisLabel?: string, tooltipCallbacks?: ChartTooltipCallbacks, xAxisTicksCallback?: ChartAxisTicksCallback): _DeepPartialObject<ChartOptions<keyof ChartTypeRegistry>> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        title: {
          display: !!xAxisLabel,
          text: xAxisLabel,
          color: inPrintMode ? theme.palette.printStyles.text.fair : theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: inPrintMode ? 8 : 10,
            weight: '500',
          },
        },
        grid: {
          display: false,
          borderColor: transparentize(0.5, theme.palette.charts.gridLineColor),
          borderWidth: 1,
        },
        ticks: {
          maxRotation: 0,
          padding: 0,
          color: inPrintMode ? theme.palette.printStyles.text.fair : theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: inPrintMode ? 8 : 10,
            weight: '400',
          },
          callback: xAxisTicksCallback,
        },
      },
      y: {
        title: {
          display: !!yAxisLabel,
          text: yAxisLabel,
          color: inPrintMode ? theme.palette.printStyles.text.fair : theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: inPrintMode ? 8 : 10,
            weight: '500',
          },
        },
        position: 'left',
        suggestedMin: 0,
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: transparentize(0.7, theme.palette.charts.gridLineColor),
        },
        ticks: {
          padding: 5,
          color: inPrintMode ? theme.palette.printStyles.text.fair : theme.palette.text.fair,
          font: {
            family: 'DM Sans',
            size: inPrintMode ? 8 : 10,
            weight: '400',
          },
          callback: (value: string | number) => {
            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 1, notation: 'compact', compactDisplay: 'short' }).format(Number(value));
          }
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
      bar: {
        borderWidth: 0,
        borderRadius: 5,
      },
      point: {
        radius: 2,
        hoverRadius: 2,
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        displayColors: false,
        caretPadding: 2,
        caretSize: 7,
        backgroundColor: theme.palette.backgrounds.surface,
        cornerRadius: 3,
        padding: 6,
        borderColor: theme.palette.borders.medium,
        borderWidth: 1,
        titleMarginBottom: 3,
        titleColor: theme.palette.text.fair,
        titleFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        bodyColor: theme.palette.text.medium,
        bodyFont: {
          family: theme.fontFamily,
          weight: '500',
          size: 12,
        },
        callbacks: tooltipCallbacks,
      },
      legend: {
        display: false,
      },
    }
  };
}

const createGradient = (scriptableContext: ScriptableContext<keyof ChartTypeRegistry>, colors: string[]): (CanvasGradient | string) => {
  const chart: Chart = scriptableContext.chart;
  const { ctx, chartArea } = chart;

  if (!ctx || !chartArea) {
    return colors[0];
  }

  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

    const stepSize = 1 / colors.length;
    for (let index = 0; index < colors.length; index++) {
      const color = colors[index];
      if (index === colors.length - 1) {
        gradient.addColorStop(1, color);
      } else {
        gradient.addColorStop(index * stepSize, color);
      }
    }
  }

  return gradient ?? colors[0];
}