import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import HeatmapQuery from '@dashboard/api/queries/metrics/HeatmapQuery';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import HeatmapScale from '@dashboard/components/molecules/Heatmap/HeatmapScale';
import Heatmap from '@dashboard/components/molecules/Heatmap/Heatmap';
import { useApi } from '@shared/hooks/useApi';
import HeatmapMetricSelect from '@dashboard/components/molecules/Heatmap/Selects/MetricSelect';
import DeviceModelSelect from '@dashboard/components/molecules/Heatmap/Selects/DeviceModelSelect';
import { DeviceModelWithMetricTypes } from '@dashboard/api/models/DeviceModelWithMetricTypes';
import HeatmapLegend from '@dashboard/components/molecules/Heatmap/HeatmapLegend';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { IHeatmapConfig, getHeatmapMetricConfig } from '@dashboard/configs/heatmap/HeatmapConfig';
import { HeatmapDefaultDeviceModel, HeatmapDefaultMetricType } from '@dashboard/configs/heatmap/HeatmapDefaultSettings';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { HeatmapSpaceDto } from '@dashboard/api/models/HeatmapSpaceDto';
import HeatmapRefetchQuery from '@dashboard/api/queries/metrics/HeatmapRefetchQuery';
import { unionBy } from 'lodash';
import BuildingSelect from '@dashboard/components/molecules/Heatmap/Selects/BuildingSelect';
import { BuildingWithFloors } from '@shared/api/models/Building/BuildingWithFloors';
import { Label } from '@shared/components/atoms/Form/Form';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';

export type HeatmapConfig = {
  building?: BuildingWithFloors;
  model?: DeviceModelWithMetricTypes;
  metricType?: MetricType;
};

export type HeatmapData = {
  spaces: HeatmapSpaceDto[];
  config: HeatmapConfig;
};

const BuildingPage_Heatmap = () => {
  const { t } = useTranslation();
  const { execute, loading } = useApi();
  const { execute: refetch, loading: loadingRefetch } = useApi();
  const { toLocale, getUnit } = useLocalisation();
  const [loadingModels, setLoadingModels] = useState(false);
  const [config, setConfig] = useState<HeatmapConfig>({});
  const [data, setData] = useState<HeatmapData>();
  const [heatmapMetricConfig, setHeatmapMetricConfig] = useState<IHeatmapConfig>();

  useEffect(() => {
    if (config?.metricType) {
      setHeatmapMetricConfig(getHeatmapMetricConfig(config.metricType, toLocale, getUnit));
    }
  }, [config, getUnit, toLocale]);

  const refetchData = useCallback(async (spaces: HeatmapSpaceDto[], config: HeatmapConfig) => {
    if (!config.model || !config.metricType) {
      return;
    }

    const spacesToRefetch = spaces.filter(x => x.fetchOlderMeasurement);

    if (spacesToRefetch.length === 0) {
      return;
    }

    const refetchedSpaces = await refetch({
      query: new HeatmapRefetchQuery(spacesToRefetch, config.model.deviceModel, config.metricType)
    });

    if (refetchedSpaces) {
      const merged = unionBy(refetchedSpaces, spaces, 'spaceId');
      setData({ spaces: merged, config });
    }
  }, [refetch]);

  // Fetch data once a Building, DeviceModel and MetricType are selected
  useEffect(() => {
    const fetchData = async () => {
      if (!config.building || !config.model || !config.metricType) {
        return;
      }

      const spaces = await execute({
        query: new HeatmapQuery(config.building.floors.map(x => x.id), config.model.deviceModel, config.metricType)
      });

      if (spaces) {
        setData({ spaces, config });
        refetchData(spaces, config);
      } else {
        setData(undefined);
      }
    };

    fetchData();
  }, [config, execute, refetchData]);

  const handleBuildingChange = useCallback((building: BuildingWithFloors) => {
    setData(undefined);
    setConfig({ building });
  }, []);

  const handleDeviceModelChange = useCallback((model: DeviceModelWithMetricTypes) => {
    setConfig(prev => ({ building: prev.building, model }));
  }, []);

  const handleMetricChange = useCallback((metricType: MetricType) => {
    setConfig(prev => ({ ...prev, metricType }))
  }, []);

  return (
    <ContentWrapper>
      <PaddedContainer>
        <Title
          text={t('Building.Heatmap', { ns: 'organisms' })}
          size='lg'
          wrapperStyle={{ paddingBottom: '20px' }}
        />

        <Card noPadding style={{ minWidth: 'max-content' }}>
          <HeaderRow>
            <FlexRow>
              <SelectContainer>
                <Label>{t('Building', { ns: 'common' })}</Label>
                <BuildingSelect
                  onChange={handleBuildingChange}
                />
              </SelectContainer>

              {config.building &&
                <SelectContainer>
                  <Label>{t('DeviceModel', { ns: 'common' })}</Label>
                  <DeviceModelSelect
                    building={config.building}
                    onChange={handleDeviceModelChange}
                    onLoading={setLoadingModels}
                    defaultModel={HeatmapDefaultDeviceModel}
                  />
                </SelectContainer>
              }

              {config.building && config.model &&
                <SelectContainer>
                  <Label>{t('Metric', { ns: 'common' })}</Label>
                  <HeatmapMetricSelect
                    building={config.building}
                    deviceModel={config.model}
                    onChange={handleMetricChange}
                    defaultMetricType={HeatmapDefaultMetricType}
                  />
                </SelectContainer>
              }
            </FlexRow>

            <FlexRow>
              <HeatmapLegend
                data={data}
                placement={heatmapMetricConfig ? TooltipPlacement.Bottom : TooltipPlacement.BottomRight}
              />

              <HeatmapScale
                config={heatmapMetricConfig}
                width={320}
                styles={{ padding: '27px 0 27px 16px' }}
              />
            </FlexRow>
          </HeaderRow>

          <Heatmap
            data={data}
            loading={loading || loadingModels}
            loadingRefetch={loadingRefetch}
          />
        </Card>
      </PaddedContainer>
    </ContentWrapper>
  );
};

export default BuildingPage_Heatmap;

const ContentWrapper = styled.div`
  flex-grow: 1;
  height: ${`calc(100vh - ${TopBarHeight}px - ${HorizontalNavbarHeight}px)`};
  overflow: auto;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const HeaderRow = styled(FlexRow)`
  display: flex;
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  justify-content: space-between;
  padding: 0 16px;
`;

const SelectContainer = styled.div`
  width: 200px;
  margin: 16px 0;
`;