import Diagnostics from '@dashboard/components/molecules/Diagnostics/Diagnostics';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';

type PropTypes = {
  device: DeviceWithMetrics;
};

const DevicePage_Diagnostics = ({ device }: PropTypes) => {
  return (
    <Diagnostics device={device} />
  );
};

export default DevicePage_Diagnostics;
