import { HierarchyBuilding } from '@shared/api/models/Hierarchy/Hierarchy';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class BuildingHierarchiesWithDevicesGetBySiteIdQuery extends GetQuery<HierarchyBuilding[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/buildings/BuildingHierarchiesGetBySiteId/${this.siteId}`;
  }
}
