import React, { ReactNode, useCallback, useContext, useMemo } from 'react';
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { User } from '@shared/api/models/User/User';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { UserProfileGetQuery } from '@shared/api/queries/UserProfile/UserProfileGetQuery';
import { getActiveAccount, getClaims, IdTokenClaims } from '@shared/utils/ConnectApiUtils';
import { Permission } from '@shared/api/enums/Permission/Permission';
import { UserRole } from '@shared/api/enums/UserRole/UserRole';
import { useApiState } from '@shared/hooks/useApiState';
import { LoadingFullPage } from '@shared/components/molecules/LoadingFullPage/LoadingFullPage';
import { useTranslation } from 'react-i18next';

interface IUserContext {
  user: User;
  setUser: (user: User) => void;
  account: AccountInfo;
  claims: IdTokenClaims;
  hasPermission: (permission?: Permission) => boolean;
  hasRole: (permission?: UserRole) => boolean;
  isSuperAdmin: boolean;
}

export const UserContext = React.createContext({} as IUserContext);
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation();
  const { instance: msalInstance } = useMsal();
  const account = getActiveAccount(msalInstance);
  const claims = getClaims(msalInstance);

  const { data: user, loading, setData } = useApiState({
    query: new UserProfileGetQuery(),
    errorMessage: t('UserLoadError', { ns: 'commonApp' })
  }, []);

  const hasPermission = useCallback((permission?: Permission): boolean => {
    return !permission || claims.permissions.includes(permission);
  }, [claims.permissions]);

  const hasRole = useCallback((role?: UserRole): boolean => {
    return !role || claims.role === role;
  }, [claims.role]);

  const isSuperAdmin = useMemo(() => hasPermission(Permission.SuperAdministrator), [hasPermission]);

  const userContextProviderValue = useMemo(() => {
    // if user doesn't exist then set temporary initial user to resolve type error (but will not render as will return null if user is null)
    const initialUser = user ?? { id: 0, fullName: '', email: '', phoneNumber: '', roleId: 0, userSitesIds: [], mfaEnabled: false, mfaMethod: null };

    return {
      user: initialUser,
      setUser: (user: User) => setData(user),
      account,
      claims,
      hasPermission,
      hasRole,
      isSuperAdmin
    }
  }, [account, claims, hasPermission, hasRole, setData, user, isSuperAdmin])

  if (loading) {
    return <LoadingFullPage message={t('Launching', { ns: 'commonApp' })} icon={faRocketLaunch} iconPosTop='8px' />;
  }

  if (!user) {
    return null;
  }

  return (
    <UserContext.Provider
      value={userContextProviderValue}
    >
      {children}
    </UserContext.Provider>
  );
};
