import { DevicesMetricTypesGetBySiteIdQuery } from '@dashboard/api/queries/devices/DevicesMetricTypesGetBySiteIdQuery';
import { useApiState } from '@shared/hooks/useApiState';

const useSiteMetricTypes = (siteId: number) => {
  const { data: siteMetricTypes, loading } = useApiState({
    query: new DevicesMetricTypesGetBySiteIdQuery(siteId),
    errorMessage: 'Failed to load MetricTypes'
  }, []);

  return { loading, siteMetricTypes };
}

export default useSiteMetricTypes;