import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import ConsumptionWidget from './ConsumptionWidget';
import { ConsumptionChartView } from './ChartViewSelect';
import { UtilityData } from './UtilitiesSummary';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { useMediaQuery } from '@shared/hooks/useMediaQuery';
import { Select } from '@shared/components/atoms/Select/Select';

export const metricTypeToUtility = {
  [MetricType.ElectricityKwhDelta.toString()]: 'electricity',
  [MetricType.GasVolumeDelta.toString()]: 'gas',
  [MetricType.HeatingKwhDelta.toString()]: 'heating',
  [MetricType.CoolingKwhDelta.toString()]: 'cooling',
  [MetricType.WaterVolumeDelta.toString()]: 'water',
};

const utilities = {
  [MetricType.ElectricityKwhDelta.toString()]: { defintion: solid('bolt'), width: 13 },
  [MetricType.GasVolumeDelta.toString()]: { defintion: solid('fire'), width: 15 },
  [MetricType.HeatingKwhDelta.toString()]: { defintion: solid('fire-flame-curved'), width: 13 },
  [MetricType.CoolingKwhDelta.toString()]: { defintion: solid('snowflake'), width: 13 },
  [MetricType.WaterVolumeDelta.toString()]: { defintion: regular('droplet'), width: 13 },
};

type UtilityTabsProps = {
  data: UtilityData[];
  conversions: { [key: string]: string };
  onSetConversions: (conversions: { [key: string]: string }) => void;
};

const UtilityTabs = ({ data, conversions, onSetConversions }: UtilityTabsProps) => {
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const [selected, setSelected] = useState<number>(0);
  const mediumScreen = useMediaQuery('(max-width: 900px)');
  const [view, setView] = useState<ConsumptionChartView>(ConsumptionChartView.Monthly);

  const handleTabChange = useCallback((index: number, utility: string) => {
    trackAction(`${utility}_summary`, 'performance_utilities');
    setSelected(index);
  }, [trackAction]);

  if (data.length === 0) {
    return null;
  }

  const tabSelectOptions = data.map((dto, i) => ({
    metricType: dto.metricType,
    label: t(`SiteOverview.${dto.metricType}`, { ns: 'molecules' }),
    value: i
  }));

  return (
    <>
      <Tabs>
        {mediumScreen
          ?
          <StyledSelect>
            <Select
              options={tabSelectOptions}
              value={tabSelectOptions.find(x => x.value === selected)}
              onChange={selected => selected && handleTabChange(selected.value, metricTypeToUtility[selected.metricType])}
            />
          </StyledSelect>
          :
          <>
            {data.map((dto, i) => {
              const utility = utilities[dto.metricType];

              return (
                <Tab
                  key={dto.metricType}
                  ref={element => tabRefs.current[i] = element}
                  selected={selected === i}
                  onClick={() => handleTabChange(i, metricTypeToUtility[dto.metricType])}
                  metricType={dto.metricType}
                >
                  {utility &&
                    <Icon icon={utility.defintion} $width={utility.width} />
                  }
                  <Label>{t(`SiteOverview.${dto.metricType}`, { ns: 'molecules' })}</Label>
                </Tab>
              );
            })}
          </>
        }
      </Tabs>

      {data.map((dto, i) => (
        <Content
          key={dto.metricType}
          selected={selected === i}
        >
          <ConsumptionWidget
            metricType={dto.metricType}
            data={dto}
            view={view}
            onViewChange={setView}
            conversions={conversions}
            onSetConversions={onSetConversions}
          />
        </Content>
      ))}
    </>
  );
};

export default UtilityTabs;

const Tabs = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

const Icon = styled(FontAwesomeIcon) <{ $width?: number }>`
  width: ${p => p.$width ?? 17}px;
  height: 17px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  user-select: none;
`;

const Tab = styled.div<{ selected: boolean, metricType: MetricType }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 11px;
  transition: all 150ms ease;
  color: ${p => p.theme.text.secondary};
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: ${p => p.theme.primary.background};

    ${Label} {
      color: ${p => p.theme.primary.main};
    }

    ${Icon} {
      color: ${p => p.theme.primary.main};
    }
  }

  ${p => p.selected && css`
    background-color: ${p => p.theme.primary.background};

    ${Label} {
      color: ${p => p.theme.primary.main};
    }

    ${Icon} {
      color: ${p => p.theme.primary.main};
    }
  `}
`;

const Content = styled.div<{ selected: boolean }>`
  display: none;

  ${p => p.selected && css`
    display: flex;
  `}
`;

const StyledSelect = styled.div`
  width: 150px;
`;