import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { InternalNode, InternalNodeGroup } from '../SearchableDropdownSelect.types';
import CascadeLevel from './CascadeLevel';

type DropdownProps<TValue> = {
  open: boolean;
  searchString?: string;
  selectedLabels?: string[];
  group?: InternalNodeGroup<TValue>;
  width?: number;
  allNodes: InternalNode<TValue>[];
  handleRemoveSpace: (node: InternalNode<TValue>) => void;
  handleSelect: (node: InternalNode<TValue>) => void;
}

const Dropdown = <TValue,>({ open, searchString, selectedLabels, group, width, allNodes, handleRemoveSpace, handleSelect }: DropdownProps<TValue>) => {
  const [activeGroupId, setActiveGroupId] = useState<string>();

  useEffect(() => {
    setActiveGroupId(group?.id);
  }, [open, group?.id, searchString])

  return (
    <DropdownWrapper show={open} width={width}>
      {selectedLabels && selectedLabels.length > 0 &&
        <LabelContainer>
          {selectedLabels.map(label => (
            <SelectedLabel key={uuidv4()}>
              {label}
              <ClearIcon icon={faXmark} onClick={() => {
                const node = allNodes?.find(x => x.label === label);
                if (node) {
                  handleRemoveSpace(node);
                }
              }} />
            </SelectedLabel>
          ))}
        </LabelContainer>
      }
      <FlexRow>
        {group &&
          <CascadeLevel
            group={group}
            depth={0}
            searchString={searchString}
            activeGroupId={activeGroupId}
            setActiveGroupId={setActiveGroupId}
            allNodes={allNodes}
            handleSelect={handleSelect}
          />
        }
      </FlexRow>
    </DropdownWrapper>
  );
};

export default Dropdown;

const DropdownWrapper = styled.div<{ show?: boolean, vertical?: boolean, yOffset?: number, width?: number }>`
  position: absolute;
  display: ${p => p.show ? 'flex' : 'none'};
  flex-direction: column;
  z-index: 12;
  top: ${p => (p.yOffset ?? 38) + 6}px;
  left: 0;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 1px 2px 0 ${p => p.theme.palette.dropdown.shadowWeak}, 0px 1px 3px 1px ${p => p.theme.palette.dropdown.shadowStrong};
  border-radius: 4px;
  width: ${p => p.width ? `${p.width}px` : '100%'};
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
`;

const SelectedLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  padding: 2px 9px;
  border-radius: 14px;
  color: ${p => p.theme.palette.text.onSecondary};
  background-color: ${p => p.theme.palette.secondary};

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 5px;
  width: 100%;
  padding: 16px 15px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  border-bottom: 1px solid ${p => p.theme.action.divider};
`;

const ClearIcon = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  color: ${p => p.theme.palette.text.onSecondary};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.primary.main};
  }
`;