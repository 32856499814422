
import { Route } from '@shared/types/Route';
import { Permission } from '@shared/api/enums/Permission/Permission';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import SiteEdit from '@settings/components/organisms/SiteManagement/SitePage/Edit/SiteEdit';
import SiteMetadataEdit from '@settings/components/organisms/SiteManagement/SitePage/Metadata/SiteMetadataEdit';
import SiteSettings_Features from '@settings/components/organisms/SiteManagement/SitePage/Features/SiteSettings_Features';
import DeviceList from '@settings/components/organisms/DeviceManagement/DeviceList';
import SiteBuildingList from '@settings/components/organisms/SiteManagement/SitePage/BuildingList/SiteBuildingList';
import BuildingCreate from '@settings/components/organisms/BuildingManagement/BuildingCreate/BuildingCreate';
import PeopleCountingZoneList from '@settings/components/organisms/PeopleCountingManagement/PeopleCountingZoneList/PeopleCountingZoneList';
import PeopleCountingZoneAssign from '@settings/components/organisms/PeopleCountingManagement/PeopleCountingZoneDevices/PeopleCountingZoneAssign';
import PeopleCountingZoneCreate from '@settings/components/organisms/PeopleCountingManagement/PeopleCountingZoneCreate/PeopleCountingZoneCreate';
import DeviceCreate from '@settings/components/organisms/DeviceManagement/DeviceCreate';
import DeviceEdit from '@settings/components/organisms/DeviceManagement/DeviceEdit';
import DeviceReplace from '@settings/components/organisms/DeviceManagement/DeviceReplace';
import DeviceMerge from '@settings/components/organisms/DeviceManagement/DeviceMerge';
import DeviceMove from '@settings/components/organisms/DeviceManagement/DeviceMove';
import UtilityConsumptionList from '@settings/components/organisms/UtilityConsumption/UtilityConsumptionList';
import UtilityConsumptionCreate from '@settings/components/organisms/UtilityConsumption/UtilityConsumptionCreate';
import UtilityConsumptionEdit from '@settings/components/organisms/UtilityConsumption/UtilityConsumptionEdit';
import SiteManagement from '@src/components/pages/SiteSettings/SiteManagement/SiteManagement';
import SiteSettingsPage from '@src/components/pages/SiteSettings/SiteSettingsPage';
import GatewayEdit from '@settings/components/organisms/DeviceManagement/GatewayEdit';
import SalesOrderManagement from '@settings/components/organisms/SalesOrderManagement/SalesOrderManagement';
import SalesOrderCreate from '@settings/components/organisms/SalesOrderManagement/SalesOrderCreate';
import SalesOrderEdit from '@settings/components/organisms/SalesOrderManagement/SalesOrderEdit';

export const SiteManagementRoutes: Route[] = [
  {
    path: 'settings/*',
    element: SiteSettingsPage,
    permission: Permission.SiteWrite,
    link: {
      label: 'SiteSettings',
      path: 'settings',
      icon: regular('cog'),
      analytics: { action: 'site_settings', category: 'feature_navigation' },
      permission: Permission.SiteWrite
    },
    children: [
      {
        path: 'site/*',
        element: SiteManagement,
        link: {
          label: 'SiteInformation',
          path: 'site',
          icon: regular('buildings'),
          analytics: { action: 'site_information', category: 'feature_navigation' }
        },
        children: [
          {
            path: '',
            element: SiteEdit,
            analytics: { page: 'site', category: 'site_settings' },
            link: {
              label: 'Site',
              path: '',
              end: true,
              analytics: { action: 'site_tab', category: 'site_settings' }
            }
          },
          {
            path: 'metadata',
            element: SiteMetadataEdit,
            analytics: { page: 'metadata', category: 'site_settings' },
            link: {
              label: 'Metadata',
              path: 'metadata',
              analytics: { action: 'metadata_tab', category: 'site_settings' }
            }
          },
          {
            path: 'features',
            element: SiteSettings_Features,
            analytics: { page: 'features', category: 'site_settings' },
            link: {
              label: 'Features',
              path: 'features',
              analytics: { action: 'features_tab', category: 'site_settings' }
            }
          },
          {
            path: 'buildings',
            element: SiteBuildingList,
            analytics: { page: 'buildings', category: 'site_settings' },
            link: {
              label: 'Buildings',
              path: 'buildings',
              analytics: { action: 'buildings_tab', category: 'site_settings' }
            }
          },
          {
            path: 'buildings/create',
            element: BuildingCreate,
            analytics: { page: 'building_create', category: 'site_settings' },
          },
        ]
      },
      {
        path: 'devices',
        element: DeviceList,
        analytics: { page: 'device_management', category: 'site_settings' },
        link: {
          label: 'DeviceManagement',
          path: 'devices',
          icon: regular('album'),
          analytics: { action: 'device_management', category: 'site_settings' }
        },
      },
      {
        path: 'devices/create',
        element: DeviceCreate,
        analytics: { page: 'device_create', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId',
        element: DeviceEdit,
        analytics: { page: 'device_edit', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId/replace',
        element: DeviceReplace,
        analytics: { page: 'device_replace', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId/merge',
        element: DeviceMerge,
        analytics: { page: 'device_merge', category: 'site_settings' },
      },
      {
        path: 'devices/:deviceId/move',
        element: DeviceMove,
        analytics: { page: 'device_move', category: 'site_settings' },
      },
      {
        path: 'devices/gateway/:deviceId',
        element: GatewayEdit,
        analytics: { page: 'gateway_edit', category: 'site_settings' },
      },
      {
        path: 'utility-management',
        element: UtilityConsumptionList,
        analytics: { page: 'utility_bill_management', category: 'site_settings' },
        link: {
          label: 'UtilityManagement',
          path: 'utility-management',
          icon: regular('bolt'),
          analytics: { action: 'utility_bill_management', category: 'site_settings' },
          featureFlag: FeatureFlag.UtilityConsumption,
          permission: Permission.SuperAdministrator
        },
        featureFlag: FeatureFlag.UtilityConsumption,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'utility-management/create',
        element: UtilityConsumptionCreate,
        analytics: { page: 'utility_bill_create', category: 'site_settings' },
        featureFlag: FeatureFlag.UtilityConsumption,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'utility-management/:billId',
        element: UtilityConsumptionEdit,
        analytics: { page: 'utility_bill_edit', category: 'site_settings' },
        featureFlag: FeatureFlag.UtilityConsumption,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'people-counting',
        element: PeopleCountingZoneList,
        analytics: { page: 'people_counting', category: 'site_settings' },
        link: {
          label: 'PeopleCounting',
          path: 'people-counting',
          icon: regular('people-roof'),
          analytics: { action: 'people_counting', category: 'site_settings' }
        },
      },
      {
        path: 'people-counting/assign/:virtualDeviceId',
        element: PeopleCountingZoneAssign,
        analytics: { page: 'people_counting_assign', category: 'site_settings' },
      },
      {
        path: 'people-counting/create',
        element: PeopleCountingZoneCreate,
        analytics: { page: 'people_counting_create', category: 'site_settings' },
      },
      {
        path: 'sales-order',
        element: SalesOrderManagement,
        analytics: { page: 'sales_order', category: 'site_settings' },
        link: {
          label: 'SalesOrder',
          path: 'sales-order',
          icon: regular('file-invoice'),
          analytics: { action: 'sales_order', category: 'site_settings' },
          permission: Permission.SuperAdministrator
        },
        permission: Permission.SuperAdministrator
      },
      {
        path: 'sales-order/new',
        element: SalesOrderCreate,
        permission: Permission.SuperAdministrator
      },
      {
        path: 'sales-order/:salesOrderItemId',
        element: SalesOrderEdit,
        permission: Permission.SuperAdministrator
      }
    ]
  }
];
