import CarbonGenerationMixGetQuery from '@dashboard/api/queries/metrics/CarbonGenerationMixGetQuery';
import DoughnutChart, { DoughnutChartDataset } from '@dashboard/components/atoms/charts/DoughnutChart';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useApiState } from '@shared/hooks/useApiState';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useTranslation } from 'react-i18next';
import WidgetCard from '../../WidgetCard';
import { useTheme } from 'styled-components';
import { round } from '@shared/utils/NumberUtils';
import { useEffect, useState } from 'react';
import GenerationMixModal from './GenerationMixModal';
import NoData from '@shared/components/atoms/NoData/NoData';

/**
 * Carbon generation mix fuels
 */
enum GenerationMixFuel {
  biomass = 'biomass',
  coal = 'coal',
  imports = 'imports',
  gas = 'gas',
  nuclear = 'nuclear',
  other = 'other',
  hydro = 'hydro',
  solar = 'solar',
  wind = 'wind',
  peat = 'peat',
  otherRenewables = 'otherRenewables',
  waste = 'waste'
}

/**
 * Map generation mix fuel type to a chart color
 */
const GenerationMixColors = {
  [GenerationMixFuel.biomass]: '#0d8100',
  [GenerationMixFuel.coal]: '#fe6148',
  [GenerationMixFuel.imports]: '#6b7e88',
  [GenerationMixFuel.gas]: '#fe9a3e',
  [GenerationMixFuel.nuclear]: '#68b421',
  [GenerationMixFuel.other]: '#aabfca',
  [GenerationMixFuel.hydro]: '#005bbd',
  [GenerationMixFuel.solar]: '#fed234',
  [GenerationMixFuel.wind]: '#00A2FF',
  [GenerationMixFuel.peat]: '#97591f',
  [GenerationMixFuel.otherRenewables]: '#00c8cf',
  [GenerationMixFuel.waste]: '#f1c500'
};

const GenerationMixWidget = () => {
  const { site } = useSiteContext();
  const theme = useTheme();
  const { t } = useTranslation(['molecules']);
  const [fuelsDataset, setFuelsDataset] = useState<DoughnutChartDataset>();
  const [topFuelsDataset, setTopFuelsDataset] = useState<DoughnutChartDataset>();
  const { data, loading } = useApiState({
    query: new CarbonGenerationMixGetQuery(site.id)
  }, [site]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const fuels = data.generationMix.filter(x => x.percentage > 0).sort((a, b) => b.percentage - a.percentage);
    const dataset = {
      data: fuels.map(x => x.percentage),
      colors: fuels.map(x => GenerationMixColors[x.fuel as GenerationMixFuel]),
      labels: fuels.map(x => `${x.percentage}% ${t(`EnergyWidgets.${x.fuel}`, { ns: 'molecules' })}`)
    };

    setFuelsDataset(dataset);

    // If there are more than 5 fuels, create dataset with top four fuels only, otherwise show all
    if (fuels.length < 6) {
      setTopFuelsDataset(dataset);
      return;
    }

    // Create dataset with top four fuels only
    const topFuels = fuels.slice(0, 4);
    const remainingPercentage = round(100 - topFuels.reduce((acc, obj) => acc + obj.percentage, 0), 1);
    topFuels.push({ fuel: 'other', percentage: remainingPercentage });

    setTopFuelsDataset({
      data: topFuels.map(x => x.percentage),
      colors: topFuels.map(x => GenerationMixColors[x.fuel as GenerationMixFuel]),
      labels: topFuels.map(x => `${x.percentage}% ${t(`EnergyWidgets.${x.fuel}`, { ns: 'molecules' })}`)
    });
  }, [data, t]);

  if (!data && !loading) {
    return null;
  }

  return (
    <WidgetCard
      icon={regular('bolt')}
      title={t('EnergyWidgets.CarbonIntensity', { ns: 'molecules' })}
      titleElement={<GenerationMixModal intensityForecast={data?.intensityForecast} dataset={fuelsDataset} />}
      loading={{
        state: loading,
        icon: duotone('bolt'),
        label: t('EnergyWidgets.LoadingGenerationMix', { ns: 'molecules' })
      }}
    >
      <>
        {!data &&
          <NoData
            subLabel={t('DataUnavailable', { ns: 'status' })}
            styles={{ paddingTop: 40 }}
            iconColor={theme.primary.light}
          />
        }

        {data && topFuelsDataset &&
          <DoughnutChart
            dataset={topFuelsDataset}
            size="120px"
            cutout="83%"
            arcBorderWidth={2}
            innerValue={data.intensityForecast}
            innerSubLabel={<>gCO&#8322;/kWh</>}
            legend={{}}
          />
        }
      </>
    </WidgetCard>
  )
}

export default GenerationMixWidget;