export enum AltUnits {
  Area = 'Area',
  CO2WeightArea = 'Weight/Area',
  CO2WeightIntensity = 'Weight/Usage',
  CO2SubWeightArea = 'Sub Weight/Area',
  Weight = 'Weight',
  CarbonIntensityForecast = 'Carbon Intensity Forecast', // this is in Kilograms 
  CarbonIntensityVolume = 'Carbon Intensity Volume',
  EnergySqmDailyAverage = 'Electricity Daily Average by Area',
  GasSqmDailyAverageVolume = 'Gas Volume Daily Average by Area',
  WaterSqmDailyAverage = 'Water Daily Average by Area',
  VariancePercentage = 'Water Variance Percentage',
  Default = 'Default'
}