import { stringToFloat } from '@shared/utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: BatteryVoltage
 */
const HeatmapConfigBatteryVoltage: IHeatmapConfig = {
  accessor: (x: string) => {
    return stringToFloat(x, 1);
  },

  scale: {
    points: [
      {
        threshold: 1,
        label: '0V',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 2.5,
        label: '1.7',
        color: DefaultScaleColors.ORANGE,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 3.4,
        label: '3.4',
        color: DefaultScaleColors.YELLOW,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 5,
        label: '5',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      }
    ]
  }
};

export default Object.freeze(HeatmapConfigBatteryVoltage);