import { Route as RouteType } from '@shared/types/Route';
import { CommonSidebar } from '../../../shared/CommonSidebar/CommonSidebar';
import styled from 'styled-components';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { useTranslation } from 'react-i18next';
import { Title } from '@shared/components/atoms/Title/Title';
import { groupBy } from 'lodash';

type PropTypes = {
  routes?: RouteType[];
}

const Sidebar = ({ routes }: PropTypes) => {
  const { t } = useTranslation();

  const links = routes?.filter(x => x.link).map(x => x.link);
  const categories = groupBy(links, x => x?.category);

  return (
    <CommonSidebar>
      <Title
        text={t('Settings', { ns: 'common' })}
        style={{ paddingBottom: 5 }}
      />

      {Object.entries(categories).map(category => (
        <Category key={category[0]}>
          <CategoryName>
            {t(category[0], { ns: 'routes' })}
          </CategoryName>

          <List>
            {category[1]?.map(link => link && (
              <NavItem
                key={link.path}
                link={link}
              >
                {link.label &&
                  t(link.label, { ns: 'routes' })
                }
              </NavItem>
            ))}
          </List>
        </Category>
      ))}
    </CommonSidebar>
  );
};

export default Sidebar;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 15px;
`;

const CategoryName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-bottom: 5px;
  padding-left: 1px;
`;

const NavItem = styled(ProtectedLink)`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary} !important;
  border-radius: 8px;
  padding: 9px 12px;

  &:hover {
    color: ${p => p.theme.text.primary} !important;
    background-color: ${p => p.theme.primary.hover};
  }

  &.active {
    font-weight: 500;
    color: ${p => p.theme.text.primary} !important;
    background-color: ${p => p.theme.primary.hover};
  }
`;