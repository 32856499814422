import SystemMessage, { SystemMessageType } from '@shared/components/atoms/SystemMessage/SystemMessage';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

const UtilitiesHaveMovedBanner = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const systemMessage = (
    <FlexRow>
      {t('SiteOverview.UtilitiesMovedDescription', { ns: 'molecules' })}

      <StyledLink
        link={{
          path: './../performance',
          analytics: { action: 'go_to_site_performance', category: 'overview' }
        }}
      >
        {t('GoToSitePerformance', { ns: 'navigation' })}
      </StyledLink>
    </FlexRow>
  );

  return (
    <SystemMessage
      hideIcon={false}
      messages={[systemMessage]}
      type={SystemMessageType.Information}
      dissmisable
      dissmisPermanentlyKey='utilities-have-moved-banner'
      styles={{
        borderWidth: 1,
        backgroundColor: transparentize(0.9, theme.palette.systemMessage.information),
        color: theme.text.secondary
      }}
    />
  );
};

export default UtilitiesHaveMovedBanner;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledLink = styled(ProtectedLink)`
  font-weight: 500;
  color: ${p => p.theme.primary.main} !important;
  border-radius: 4px;
  padding: 4px 8px;

  &:hover {
    background-color: ${p => p.theme.primary.selected};
    transition: all 150ms ease;
  }
`;