import { OperatorPostFormDataQuery } from '@shared/api/queries/ResidentApp/common/OperatorPostFormDataQuery';
import { BlobUploadDto } from '@shared/api/models/ResidentApp/BlobUploadDto';

export class ChallengesResidentAppUploadImageCommand extends OperatorPostFormDataQuery<BlobUploadDto> {
  constructor(challengeId: number, file: File) {
    const formData = new FormData();
    formData.append('challengeId', challengeId.toString());
    formData.append('file', file);
    super(formData);
  }

  public targetUrl(): string {
    return '/Challenges/Upload';
  }
}
