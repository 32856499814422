import { ReactNode, useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { Button } from '@shared/components/atoms/Button/Button';
import { ExternalUserSummary } from '@shared/api/models/User/ExternalUserSummary';
import { Site } from '@shared/api/models/Site/Site';
import ExternalUserSitesUpdateCommand from '@settings/api/queries/UserSites/ExternalUserSitesUpdateCommand';
import Filter, { FilterOption } from '@src/components/shared/Filters/Filter/Filter';

export type UserSiteSelectOption = {
  label: ReactNode,
  value: string
}

type PropTypes = {
  user: ExternalUserSummary,
  sites: Site[],
  refreshUsers: () => void
}

const UserSiteSelectWrapper = ({ user, sites, refreshUsers }: PropTypes) => {
  const { t } = useTranslation(['settingsUser']);
  const { execute } = useApi();
  const theme = useTheme();
  const [options, setOptions] = useState<FilterOption<Site>[]>([]);
  const [selectedUserSites, setSelectedUserSites] = useState<Site[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const resetOptions = useCallback(async () => {
    const options: FilterOption<Site>[] = sortBy(sites, x => x.name).map((site) => ({
      label: site.name,
      displayElement: <LabelWrapper>{site.name}</LabelWrapper>,
      value: site,
      selected: user.userSiteIds.some(x => x === site.id)
    }));

    setOptions(options);
  }, [user, sites]);

  useEffect(() => {
    resetOptions();
  }, [resetOptions]);

  const handleChange = useCallback((selectedUserSites: Site[]) => {
    setIsDirty(true);
    setSelectedUserSites(selectedUserSites);
  }, []);

  const handleDiscard = () => {
    resetOptions();
    setIsDirty(false);
  };

  const handleSave = useCallback(async () => {
    setIsDirty(false);

    const toAdd = selectedUserSites?.filter(x => !user.userSiteIds?.some(a => a === x.id)).map(x => x.id);
    const toRemove = user.userSiteIds?.filter(x => !selectedUserSites?.some(a => a.id === x)).map(x => x) ?? [];

    await execute({
      query: new ExternalUserSitesUpdateCommand(user.id, toAdd, toRemove),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    refreshUsers();
  }, [user, selectedUserSites, execute, refreshUsers, t])

  return (
    <FlexRow>
      <div>
        <Filter
          label={t('Sites', { ns: 'common' })}
          options={options}
          onChange={handleChange}
          styles={{ marginLeft: -16 }}
        />
      </div>

      {isDirty &&
        <>
          <Button
            tertiary
            label={t('Discard', { ns: 'common' })}
            onClick={handleDiscard}
            color={theme.palette.red}
            style={{ padding: 8 }}
          />
          <Button
            tertiary
            label={t('Save', { ns: 'common' })}
            onClick={handleSave}
            style={{ padding: 8 }}
          />
        </>
      }
    </FlexRow>
  );
};

export default UserSiteSelectWrapper;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
`;