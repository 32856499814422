import PeopleCountingZone from '@settings/api/models/PeopleCounting/PeopleCountingZone';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class PeopleCountingZonesGetBySiteIdQuery extends GetQuery<PeopleCountingZone[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/peoplecounting/ZonesGetBySiteId/${this.siteId}`;
  }
}