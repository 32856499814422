import { HierarchyBuilding, HierarchyFloor, HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';
import { ICascaderMultiSelectNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import SearchableDropdownSelect from '@shared/components/atoms/SearchableDropdownSelect/SearchableDropdownSelect';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SpaceLookupProps = {
  buildings: HierarchyBuilding[];
  onChange: (spaceIds: number[]) => void;
  resetPulse: boolean;
  placeHolder?: string;
}

const SpaceLookup = ({ buildings, onChange, resetPulse, placeHolder }: SpaceLookupProps) => {
  const { t } = useTranslation(['molecules']);
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<number>>({ nodes: [] });

  useEffect(() => {
    const mapSpacesToNodes = (spaces: HierarchySpace[]) => orderBy(spaces, x => x.name.toLocaleLowerCase()).map(space => ({
      label: `${space.name}`,
      value: space.id
    }));

    const mapFloorsToNodes = (floors: HierarchyFloor[]) => orderBy(floors, x => x.floorNumber).map(floor => ({
      label: `${floor.name}`,
      childGroup: {
        header: 'Spaces',
        nodes: mapSpacesToNodes(floor.spaces)
      }
    }));

    const mapBuildingsToNodes = (buildings: HierarchyBuilding[]) => orderBy(buildings, x => x.name.toLocaleLowerCase()).map(building => ({
      label: `${building.name}`,
      childGroup: {
        header: 'Floors',
        nodes: mapFloorsToNodes(building.floors)
      }
    }));

    const options: ICascaderMultiSelectNodeGroup<number> = {
      header: 'Buildings',
      nodes: mapBuildingsToNodes(buildings),
    };

    setCascaderOptions(options);
  }, [buildings]);

  const handleChange = (spaceIds?: number[]) => {
    onChange(spaceIds ?? []);
  }

  return (
    <SearchableDropdownSelect
      group={cascaderOptions}
      onChange={handleChange}
      placeholder={placeHolder ?? t('Select', { ns: 'common' })}
      includeChildValues
      resetPulse={resetPulse}
    />
  );
}

export default SpaceLookup
