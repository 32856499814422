import { transparentize } from 'polished';
import styled from 'styled-components';
import { ISwitch, SwitchSize } from './Switch.types';
import { Tooltip } from '../Tooltip/Tooltip';

export const Switch = ({ checked, onChange, disabled, disabledTooltip, isLoading, size, labelChecked, labelUnchecked, colorChecked, colorUnchecked, styles }: ISwitch) => {
  return (
    <Tooltip
      content={disabled && disabledTooltip}
    >
      <StyledSwitch
        onClick={(e) => {
          e.stopPropagation();
          !disabled && !isLoading && onChange(!checked);
        }}
        checked={checked}
        size={size}
        style={styles}
        disabled={disabled}
        isLoading={isLoading}
        colorChecked={colorChecked}
        colorUnchecked={colorUnchecked}
      >
        <StyledDot checked={checked} size={size} />
        <Text checked={checked} size={size}>
          {checked ? labelChecked : labelUnchecked}
        </Text>
      </StyledSwitch>
    </Tooltip>
  );
};

const StyledSwitch = styled.div<{ checked?: boolean, size?: SwitchSize, disabled?: boolean, isLoading?: boolean; colorChecked?: string, colorUnchecked?: string }>`
  background-color: ${p => p.checked
    ? p.colorChecked ?? p.theme.palette.forms.switch.background.checked
    : p.colorUnchecked ?? p.theme.palette.forms.switch.background.unchecked};

  box-shadow: 0px 5px 4px -3px ${p => p.theme.palette.forms.input.focusShadow}, inset 0px 0px 7px -2px ${p => p.theme.palette.forms.switch.innerShadow};
  border-radius: 20px;
  cursor: ${p => (p.disabled || p.isLoading) ? 'not-allowed' : 'pointer'};
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;

  width: ${p =>
    (p.size === 'sm' && '30px')
    || (p.size === 'lg' && '45px')
    || (p.size === 'xl' && '55px')
    || '35px'};
  height: ${p =>
    (p.size === 'sm' && '14px')
    || (p.size === 'lg' && '22px')
    || (p.size === 'xl' && '22px')
    || '17px'};
`

const StyledDot = styled.div<{ checked?: boolean, size?: SwitchSize }>`
  transition: all 0.2s linear;
  background-color: ${p => p.theme.palette.text.onPrimary};
  border-radius: 50%;
  flex-shrink: 0;

  box-shadow: ${p => p.checked
    ? `-3px 0px 8px -3px ${p.theme.palette.forms.switch.dotShadow.checked}`
    : `3px 0px 8px -3px ${p.theme.palette.forms.switch.dotShadow.unchecked}`};
  
  width: ${p =>
    (p.size === 'sm' && '11px')
    || (p.size === 'lg' && '18px')
    || (p.size === 'xl' && '18px')
    || '13px'};
  height: ${p =>
    (p.size === 'sm' && '11px')
    || (p.size === 'lg' && '18px')
    || (p.size === 'xl' && '18px')
    || '13px'};
  transform: ${p => !p.checked
    ? 'translateX()'
    : (p.size === 'sm' && 'translateX(15px)')
    || (p.size === 'lg' && 'translateX(23px)')
    || (p.size === 'xl' && 'translateX(33px)')
    || 'translateX(18px)'};
`;

const Text = styled.div<{ checked?: boolean, size?: SwitchSize }>`
  color: ${p => transparentize(0.2, p.theme.palette.text.onPrimary)};
  transition: all 0.2s linear;

  font-size: ${p =>
    (p.size === 'sm' && '6px')
    || (p.size === 'lg' && '10px')
    || (p.size === 'xl' && '12px')
    || '9px'};

  margin-top: ${p =>
    (p.size === 'sm' && '1px')
    || (p.size === 'lg' && '1px')
    || (p.size === 'xl' && '1px')
    || '2px'};

  transform: ${p => !p.checked
    ? 'translateX(-0px)'
    : (p.size === 'sm' && 'translateX(-12px)')
    || (p.size === 'lg' && 'translateX(-23px)')
    || (p.size === 'xl' && 'translateX(-31px)')
    || 'translateX(-16px)'};
`;