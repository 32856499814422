import { SpaceConsentDto } from '@shared/api/models/Space/SpaceConsentDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class SpaceConsentGetBySpaceIdQuery extends GetQuery<SpaceConsentDto> {
  spaceId: number;

  constructor(spaceId: number) {
    super();
    this.spaceId = spaceId;
  }

  public targetUrl(): string {
    return `/spaces/Consent?spaceId=${this.spaceId}`;
  }
}
