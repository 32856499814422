import { IInternalNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CascadeLevel from './CascadeLevel';

interface ICascaderModal<TValue> {
  open: boolean;
  group?: IInternalNodeGroup<TValue>;
  width?: number;
  showSearch?: boolean;
  position?: 'left' | 'right';
  onChange: (group: IInternalNodeGroup<TValue>) => void;
}

const Dropdown = <TValue,>({ open, group, width, onChange, showSearch = false, position = 'left' }: ICascaderModal<TValue>) => {
  const [activeGroupId, setActiveGroupId] = useState<string>();

  useEffect(() => {
    setActiveGroupId(group?.id);
  }, [open, group?.id])

  return (
    <DropdownWrapper show={open} width={width} position={position}>
      <FlexRow>
        {group &&
          <CascadeLevel
            group={group}
            depth={0}
            onChange={onChange}
            activeGroupId={activeGroupId}
            setActiveGroupId={setActiveGroupId}
            showSearch={showSearch}
          />
        }
      </FlexRow>
    </DropdownWrapper>
  );
};

export default Dropdown;

const DropdownWrapper = styled.div<{ show?: boolean, vertical?: boolean, yOffset?: number, width?: number, position?: 'left' | 'right' }>`
  position: absolute;
  display: ${p => p.show ? 'flex' : 'none'};
  z-index: 12;
  top: ${p => (p.yOffset ?? 38) + 6}px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 6px 12px 0 ${p => p.theme.palette.dropdown.shadowWeak}, 0 3px 6px -4px ${p => p.theme.palette.dropdown.shadowStrong}, 0 9px 25px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: ${p => p.width ? `${p.width}px` : '100%'};

  ${(p) => p.position === 'left' && css`
    left: 0;
  `};

  ${(p) => p.position === 'right' && css`
    right: 0;
  `};
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
`;