import data from  '../data/currencies.json'
import { TFunction } from 'i18next';

export type Currency = {
    code: string;
    name: string;
}

export const getCurrencies = (): Currency[] => Object.entries(data).map(([code, name]) => ({ code, name }));

//Locale is hardcoded as 'en-UK' currently so all symbols will display left of the value. 
//Locale can be changed to a variable in the future when locale based formatting is required. 
//'GBP' has been added as a fallback if no currency code is provided. 
export const currencyFormat = ( currencyCode?: string ) => {
  let locale = 'en-GB';

  //Temp fix to have $ show instead of US$ for USD.  
  if (currencyCode === 'USD'){
    locale = 'en-US';
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode ?? 'GBP',
    maximumFractionDigits: 2
  })
}

export const currencyDecimalName = (code: string, t: TFunction): string => {
  switch(code){
    case 'GBP': 
      return t('GBPDecimal', { ns: 'common' });
    case 'EUR': 
      return t('EURDecimal', { ns: 'common' });
    case 'USD': 
      return t('USDDecimal', { ns: 'common' });
    default: 
      return t('GBPDecimal', { ns: 'common' });
  }
}