import { ResidentAppFeatureType } from '@shared/api/enums/ResidentAppFeatureType/ResidentAppFeatureType';
import { SiteMetricTypeDto } from '@shared/api/models/ResidentApp/SiteMetricTypeDto';
import { OperatorPostQuery } from '@shared/api/queries/ResidentApp/common/OperatorPostQuery';

export default class SiteMetricTypeResidentAppCreateCommand extends OperatorPostQuery<SiteMetricTypeDto> {
  siteOperatorId: number;
  metricType: ResidentAppFeatureType;

  constructor(siteOperatorId: number, metricType: ResidentAppFeatureType) {
    super();
    this.siteOperatorId = siteOperatorId;
    this.metricType = metricType;
  }

  public targetUrl(): string {
    return '/SiteMetricTypes'
  }
}