import { useEffect, useState } from 'react';

export const NEW_UPLOAD = 'NEW_UPLOAD';

export function useFileHandler(initialFileName?: string | undefined) {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>();
  const [fileHasChanged, setFileHasChanged] = useState(false);
  const [fileToBeDeleted, setfileToBeDeleted] = useState(false);

  useEffect(() => {
    setFileName(initialFileName);
    setFile(null);
    setFileHasChanged(false);
    setfileToBeDeleted(false);
  }, [initialFileName]);

  const handleFileChange = (file: File) => {
    setFileName(NEW_UPLOAD);
    setFile(file);
    setFileHasChanged(true);
    setfileToBeDeleted(false);
  };

  const handleFileDelete = () => {
    setFileName(undefined);
    setFile(null);
    setFileHasChanged(false);
    setfileToBeDeleted(true);
  };

  const resetFile = () => {
    setFileName(initialFileName);
    setFile(null);
    setFileHasChanged(false);
    setfileToBeDeleted(false);
  };

  return {
    file,
    fileName,
    fileHasChanged,
    fileToBeDeleted,
    handleFileChange,
    handleFileDelete,
    resetFile
  };
}
