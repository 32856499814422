import { Building } from '@shared/api/models/Building/Building';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class BuildingsGetBySiteIdQuery extends GetQuery<Building[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/buildings/site/${this.siteId}`;
  }
}
