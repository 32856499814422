import SitesGetAllWithHierarchyQuery from '@dashboard/api/queries/site/SitesGetAllWithHierarchyQuery';
import PeopleCountingZoneCreateCommand from '@settings/api/queries/PeopleCounting/PeopleCountingZoneCreateCommand';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Button } from '@shared/components/atoms/Button/Button';
import { CascaderSingleSelect } from '@shared/components/atoms/CascaderSingleSelect/CascaderSingleSelect';
import { INodeGroup } from '@shared/components/atoms/CascaderSingleSelect/CascaderSingleSelect.types';
import { Form, Label } from '@shared/components/atoms/Form/Form';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { createDataObject } from '@shared/utils/ObjectUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PeopleCountingZoneCreate = () => {
  const { site } = useSiteContext();
  const { t } = useTranslation(['settingsPeopleCounting']);
  const { getDeviceConfig } = useDeviceConfigContext();
  const { handleSubmit, reset, } = useForm<PeopleCountingZoneCreateCommand>();
  const navigate = useNavigate();
  const { execute, loading: savingInProgress } = useApi();
  const { data: sites } = useApiState(
    {
      query: new SitesGetAllWithHierarchyQuery(),
      initialState: [],
      errorMessage: t('ZoneCreate.UnableToRetrieveZones', { ns: 'settingsPeopleCounting' })
    },
    []
  );

  const [spaceId, setSpaceId] = useState<number>();
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [options, setOptions] = useState<INodeGroup<number>>();

  useEffect(() => {
    spaceId !== undefined ? setButtonEnabled(true) : setButtonEnabled(false);
  }, [spaceId])

  useEffect(() => {
    const GetCascadeItems: INodeGroup<number> = {
      header: t('ZoneCreate.Sites', { ns: 'settingsPeopleCounting' }),
      nodes: orderBy(sites, x => x.name.toLocaleLowerCase()).filter(x => x.id === site.id)
        .map((site) => ({
          label: `${site.name}`,
          childGroup: {
            header: t('ZoneCreate.Buildings', { ns: 'settingsPeopleCounting' }),
            nodes: orderBy(site.buildings, x => x.name.toLocaleLowerCase()).map((building) => ({
              label: `${building.name}`,
              childGroup: {
                header: t('ZoneCreate.Floors', { ns: 'settingsPeopleCounting' }),
                nodes: orderBy(building.floors, x => x.floorNumber).map((floor) => ({
                  label: `${floor.name}`,
                  childGroup: {
                    header: t('ZoneCreate.Spaces', { ns: 'settingsPeopleCounting' }),
                    nodes: orderBy(floor.spaces, space => space.name.toLocaleLowerCase())
                      .filter(space => space.devices.some(device => getDeviceConfig(device.deviceModel)?.deviceType === DeviceType.VirtualPeopleCounting) ? false : true)
                      .map(space => ({
                        label: `${space.name}`,
                        value: space.id,
                        selectable: true
                      }))
                  }
                })),
              },
            })),
          },
        })),
    };
    setOptions(GetCascadeItems);
  }, [sites, getDeviceConfig, t, site.id])

  const onClear = () => {
    reset();
  };

  const onSave: SubmitHandler<PeopleCountingZoneCreateCommand> = async data => {

    data.spaceId = spaceId;

    const result = await execute({
      query: createDataObject(PeopleCountingZoneCreateCommand, data),
      successMessage: t('ZoneCreate.ZoneCreated', { ns: 'settingsPeopleCounting' }),
      errorMessage: t('ZoneCreate.ZoneCreateFailed', { ns: 'settingsPeopleCounting' }),
    });

    if (result === undefined) {
      return;
    }

    onClear();
    navigate('./..');
  };

  return (
    <PaddedContainer centered>
      <Container>
        <BackButton
          label={t('Back', { ns: 'navigation' })}
          url='./..'
        />

        <Form>
          <Card cardTitle={t('ZoneCreate.AddPeopleCountingZone', { ns: 'settingsPeopleCounting' })}
            centered
            maxWidth='700px'
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Label>{t('ZoneCreate.PeopleCountingZone', { ns: 'settingsPeopleCounting' })}</Label>
                  {options && (
                    <CascaderSingleSelect
                      group={options}
                      onChange={setSpaceId}
                      placeholder={t('ZoneCreate.SelectSpace', { ns: 'settingsPeopleCounting' })}
                      confirmButton={t('Apply', { ns: 'common' })}
                      cancelButton={t('Cancel', { ns: 'common' })}
                      fieldStyles={{ width: '300px' }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Form>
        <Card>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <Button
                  label={t('ZoneCreate.AddZone', { ns: 'settingsPeopleCounting' })}
                  onClick={handleSubmit(onSave)}
                  disabled={!buttonEnabled}
                  loading={savingInProgress}
                />
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </PaddedContainer>
  );
};

export default PeopleCountingZoneCreate;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
`;