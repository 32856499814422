import { SiteTemperatureChartDto } from '@dashboard/api/models/SiteTemperatureChartDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class SiteDailyTemperaturesGetQuery extends GetQuery<SiteTemperatureChartDto> {
  siteId: number;
  fromDate: string;
  toDate: string;

  constructor(siteId: number, fromDate: string, toDate: string) {
    super();
    this.siteId = siteId;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }

  public targetUrl(): string {
    return `/sites/GetDailyTemperatures?siteId=${this.siteId}&fromDate=${this.fromDate}&toDate=${this.toDate}`;
  }
}