import { isEqual, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import SitesGetAllQuery from '@settings/api/queries/Sites/SitesGetAllQuery';
import UserSitesUpdateCommand from '@settings/api/queries/UserSites/UserSitesUpdateCommand';
import { User } from '@shared/api/models/User/User';
import { Button } from '@shared/components/atoms/Button/Button';
import { Card } from '@shared/components/molecules/Card/Card';
import { CheckboxSelect } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect';
import { ICheckboxSelectGroup, ICheckboxSelectOption } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect.types';
import { Site } from '@shared/api/models/Site/Site';

type PropTypes = {
  user: User;
  refreshUser: () => void;
}

const UserEditSites = ({ user, refreshUser }: PropTypes) => {
  const { t } = useTranslation();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [options, setOptions] = useState<ICheckboxSelectGroup<Site>[]>([]);
  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const { execute, loading } = useApi();
  const { data: sites, loading: loadingSites } = useApiState({
    query: new SitesGetAllQuery(),
    initialState: []
  }, []);

  useEffect(() => {
    const options: ICheckboxSelectOption<Site>[] = sortBy(sites, x => x.name).map(x => ({
      label: x.name,
      value: x,
      selected: user.userSitesIds.includes(x.id)
    }));

    setOptions([{ options: options }]);
    setSelectedSites(user.userSitesIds);
  }, [sites, user]);

  useEffect(() => {
    const hasBeenModified = !isEqual(sortBy(user.userSitesIds), sortBy(selectedSites));
    setHasUnsavedChanges(hasBeenModified);
  }, [selectedSites, user.userSitesIds]);

  const saveChanges = async () => {
    const sitesToRemove = sites.map(x => x.id).filter(x => user.userSitesIds.includes(x) && !selectedSites.includes(x));
    const sitesToAdd = sites.map(x => x.id).filter(x => !user.userSitesIds.includes(x) && selectedSites.includes(x));

    console.log('Add', sitesToAdd, 'Remove', sitesToRemove);

    await execute({
      query: new UserSitesUpdateCommand(user.id, sitesToRemove, sitesToAdd),
      successMessage: t('EditingSites.ChangesSaved', { ns: 'settingsUser' }),
      errorMessage: t('EditingSites.SavingFailed', { ns: 'settingsUser' })
    });

    refreshUser();
  };

  return (
    <Card centered maxWidth='700px'>
      <div className="row" style={{ marginBottom: '18px' }}>
        <div className="col-6">
          <Title>{t('EditingSites.SiteAccess', { ns: 'settingsUser' })}</Title>

          <CheckboxSelect
            options={options}
            onChange={selected => setSelectedSites(selected.map(x => x.id))}
            placeholder={t('Select', { ns: 'common' })}
            loading={loadingSites}
            alignAbove
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Button
            label={t('EditingSites.Save', { ns: 'settingsUser' })}
            onClick={saveChanges}
            disabled={!hasUnsavedChanges}
            loading={loading}
          />
        </div>
      </div>
    </Card>
  );
};

export default UserEditSites;

const Title = styled.div`
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};
  margin-bottom: 12px;
`;