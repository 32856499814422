import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReportSectionHeading from '../ReportSectionHeading';
import SpaceTypePerformanceWidget from './SpaceTypePerformanceWidget';
import EsgReportDto from '@dashboard/api/models/EsgReportDto';
import { ReportSpaceTypePerformanceDto } from '@dashboard/api/models/ReportSpaceTypePerformanceDto';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { TFunction } from 'i18next';
import { MAX_NUMBER_OF_SPACE_TYPE_PERFORMANCE_WIDGETS_ON_PAGE } from '../useEsgReportPageHeaders';

const SpaceTypeSort = (a: ReportSpaceTypePerformanceDto, b: ReportSpaceTypePerformanceDto): number => {
  const labelA = SpaceType_DisplayString(a.spaceType);
  const labelB = SpaceType_DisplayString(b.spaceType);

  switch (true) {
    case labelA > labelB:
      return 1;
    case labelA < labelB:
      return -1;
    default:
      return 0;
  }
};

/**
 * Returns an array of paged SpaceTypePerformance widgets, adding spacing for new pages in print mode. 
 */
const renderWidgets = (report: EsgReportDto, inPrintMode: boolean, pageHeaders: JSX.Element[], t: TFunction, isSiteLevel?: boolean): JSX.Element[] => {
  const elements: JSX.Element[] = [];

  const spaceTypesSorted = [...report.spaceTypesPerformanceMetrics].sort(SpaceTypeSort);
  const numWidgetsOnPage = inPrintMode ? MAX_NUMBER_OF_SPACE_TYPE_PERFORMANCE_WIDGETS_ON_PAGE : spaceTypesSorted.length;
  const numPages = Math.ceil(spaceTypesSorted.length / numWidgetsOnPage);

  for (let i = 0; i < numPages; i++) {
    // Add a page header
    elements.push(pageHeaders[i]);

    // Add section heading on first page of this widget group
    if (i === 0) {
      elements.push(<ReportSectionHeading label={t('ESG.SpacePerformance', { ns: 'molecules' })} key='section-heading' />);
    }

    const startIndex = i * numWidgetsOnPage;

    elements.push(
      <FlexRow key={`page-${i}`}>
        {spaceTypesSorted.slice(startIndex, startIndex + numWidgetsOnPage).map((spaceType, j) => {
          return (
            <SpaceTypePerformanceWidget
              key={j}
              data={spaceType}
              inPrintMode={inPrintMode}
              isSiteLevel={isSiteLevel}
            />
          );
        })}
      </FlexRow>
    );
  }

  return elements;
}

interface ISpaceTypePerformanceWidgets {
  report: EsgReportDto;
  inPrintMode: boolean;
  pageHeaders: JSX.Element[];
  isSiteLevel?: boolean;
}

const SpaceTypePerformanceWidgets = ({ report, inPrintMode, pageHeaders, isSiteLevel }: ISpaceTypePerformanceWidgets) => {
  const { t } = useTranslation();
  const showSpaceTypePerformanceWidgets = report.spaceTypesPerformanceMetrics.length > 0;

  if (!showSpaceTypePerformanceWidgets) {
    return null;
  }

  return (
    <div>
      {renderWidgets(report, inPrintMode, pageHeaders, t, isSiteLevel)}
    </div>
  );
};

export default SpaceTypePerformanceWidgets;

const FlexRow = styled.div`
  display: flex;
  gap: 35px;
  flex-wrap: wrap;

  @media print {
    display: block;
  }
`;