import { SiteFairUseConfiguration } from '@shared/api/models/FairUse/SiteFairUseConfiguration';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class SiteFairUseConfigurationGetBySiteIdQuery extends GetQuery<SiteFairUseConfiguration> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/FairUse/configuration/GetBySiteId/${this.siteId}`;
  }
}