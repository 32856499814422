import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { OperatorPatchQuery } from '@shared/api/queries/ResidentApp/common/OperatorPatchQuery';

export class ChallengesResidentAppCancelCommand extends OperatorPatchQuery<OperatorChallengeDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return '/Challenges/Cancel';
  }
}
