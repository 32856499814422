export type NewFloor = {
  name: string,
  floorNumber: number,
  shortCode?: string,
  floorplanId?: string,
  spacesCount?: number
};

export const instanceOfNewFloor = (object: object): object is NewFloor => {
  return !('id' in object);
}