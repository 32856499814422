import { Building } from '@shared/api/models/Building/Building';
import { MonthlyWeighting } from '@shared/api/models/Building/BuildingMetadata';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class BuildingUpdateCommand extends PatchQuery<Building> {
  name: string;
  email?: string;
  phoneNumber?: string;
  useSiteAddress: boolean

  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  country: string;
  postCode?: string;
  timeZone: string;

  assetArea?: number;

  gresb?: number;
  fitwel?: number;
  breeam?: number;
  epc?: string;

  electricityPricePerKwh?: number;
  gasPricePerM3?: number;
  heatingPricePerKwh?: number;
  waterPricePerM3?: number;
  wasteWaterPricePerM3?: number;

  targetCarbon?: number;
  baselineCarbon?: number;

  electricityCarbonIntensity?: number;
  gasCarbonIntensity?: number;
  heatingCarbonIntensity?: number;
  waterCarbonIntensity?: number;
  wasteWaterCarbonIntensity?: number;
  wasteWaterProportion?: number;

  electricityConsumptionTarget?: number;
  gasConsumptionTarget?: number;
  heatingConsumptionTarget?: number;
  waterConsumptionTarget?: number;

  electricityConsumptionTargetMonthlyWeighting?: MonthlyWeighting;
  gasConsumptionTargetMonthlyWeighting?: MonthlyWeighting;
  heatingConsumptionTargetMonthlyWeighting?: MonthlyWeighting;
  waterConsumptionTargetMonthlyWeighting?: MonthlyWeighting;

  constructor(building: Building) {
    super(building.id);
    this.name = building.name;
    this.email = building.email;
    this.phoneNumber = building.phoneNumber;
    this.useSiteAddress = building.useSiteAddress;
    this.assetArea = building.assetArea;

    this.addressLine1 = building.address.addressLine1;
    this.addressLine2 = building.address.addressLine2;
    this.city = building.address.city;
    this.region = building.address.region;
    this.country = building.address.country;
    this.postCode = building.address.postCode;
    this.timeZone = building.address.timeZone ?? 'Etc/UTC';

    this.breeam = building.buildingMetadata?.breeam;
    this.epc = building.buildingMetadata?.epc;
    this.fitwel = building.buildingMetadata?.fitwel;
    this.gresb = building.buildingMetadata?.gresb;

    this.electricityConsumptionTarget = building.buildingMetadata?.electricityConsumptionTarget;
    this.gasConsumptionTarget = building.buildingMetadata?.gasConsumptionTarget;
    this.heatingConsumptionTarget = building.buildingMetadata?.heatingConsumptionTarget;
    this.waterConsumptionTarget = building.buildingMetadata?.waterConsumptionTarget;

    this.electricityConsumptionTargetMonthlyWeighting = building.buildingMetadata?.electricityConsumptionTargetMonthlyWeighting;
    this.gasConsumptionTargetMonthlyWeighting = building.buildingMetadata?.gasConsumptionTargetMonthlyWeighting;
    this.heatingConsumptionTargetMonthlyWeighting = building.buildingMetadata?.heatingConsumptionTargetMonthlyWeighting;
    this.waterConsumptionTargetMonthlyWeighting = building.buildingMetadata?.waterConsumptionTargetMonthlyWeighting;

    this.electricityPricePerKwh = building.buildingMetadata?.electricityPricePerKwh;
    this.gasPricePerM3 = building.buildingMetadata?.gasPricePerM3;
    this.heatingPricePerKwh = building.buildingMetadata?.heatingPricePerKwh;
    this.waterPricePerM3 = building.buildingMetadata?.waterPricePerM3;

    this.targetCarbon = building.buildingMetadata?.targetCarbon;
    this.baselineCarbon = building.buildingMetadata?.baselineCarbon;
    this.electricityCarbonIntensity = building.buildingMetadata?.electricityCarbonIntensity;
    this.gasCarbonIntensity = building.buildingMetadata?.gasCarbonIntensity;
    this.heatingCarbonIntensity = building.buildingMetadata?.heatingCarbonIntensity;
    this.waterCarbonIntensity = building.buildingMetadata?.waterCarbonIntensity;
    this.wasteWaterCarbonIntensity = building.buildingMetadata?.wasteWaterCarbonIntensity;
    this.wasteWaterProportion = building.buildingMetadata?.wasteWaterProportion;
    this.wasteWaterPricePerM3 = building.buildingMetadata?.wasteWaterPricePerM3;
  }

  public targetUrl(): string {
    return '/buildings';
  }
}
