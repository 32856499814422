import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

const HeatmapConfigChildLock: IHeatmapConfig = {
  accessor: (x: string) => x,
  displayFormat: () => '',

  scale: {
    categoryScale: true,
    points: [
      {
        threshold: 'Locked',
        label: 'Locked',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 'Unlocked',
        label: 'Unlocked',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigChildLock);