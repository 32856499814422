import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import FlexRow from '@dashboard/components/atoms/FlexRow/FlexRow';
import { Input } from '@shared/components/atoms/Form/Form';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TemperatureRangeForm } from '../types/TemperatureRangeForm';
import { ClimateControlConstants } from '../../ClimateControlUtils';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

type UnoccupiedStepDownProps = {
  rangeMinTemp: number;
  rangeMaxTemp: number;
  unit: string;
}

const UnoccupiedStepDown = ({ rangeMinTemp, rangeMaxTemp, unit }: UnoccupiedStepDownProps) => {
  const { t } = useTranslation();
  const { trackAction } = useAnalytics();
  const { register, watch, setValue } = useFormContext<TemperatureRangeForm>();
  const noMotionRulesEnabled = watch('fallbackSettings.noMotionRulesEnabled');
  const noMotionThreshold1 = watch('fallbackSettings.noMotionThreshold1');
  const noMotionThreshold2 = watch('fallbackSettings.noMotionThreshold2');
  const noMotionThreshold1Temp = watch('fallbackSettings.noMotionThreshold1Temp');
  const noMotionThreshold2Temp = watch('fallbackSettings.noMotionThreshold2Temp');
  const hoursLabel = noMotionThreshold1 === 1
    ? t('ClimateControl.Hour', { ns: 'molecules' })
    : t('ClimateControl.Hours', { ns: 'molecules' });

  const handleToggleChange = (checked: boolean) => {
    setValue('fallbackSettings.noMotionRulesEnabled', checked);
    trackAction(checked ? 'enable_step_down_mode' : 'disable_step_down_mode', 'climate_control');
  };

  return (
    <Card>
      <StyledFlexColumn>
        <StyledFlexRow>
          <Title
            text={t('ClimateControl.UnoccupiedStepDown', { ns: 'molecules' })}
          />
          <Switch
            checked={noMotionRulesEnabled}
            onChange={handleToggleChange}
          />
        </StyledFlexRow>
        <StyledLabel>
          {t('ClimateControl.UnoccupiedStepDownDescription', { ns: 'molecules' })}
        </StyledLabel>

        <StyledFlexColumn style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}>
          <StyledFlexRow disabled={!noMotionRulesEnabled}>
            <ContentWrapper>
              {t('ClimateControl.NoMotionFor', { ns: 'molecules' })}
              <StyledNumberInput
                disabled={!noMotionRulesEnabled}
                {...register('fallbackSettings.noMotionThreshold1', {
                  valueAsNumber: true,
                  onChange: (e) => {
                    if (e.target.value >= noMotionThreshold2) {
                      setValue('fallbackSettings.noMotionThreshold1', noMotionThreshold2)
                    } else if (e.target.value < ClimateControlConstants.HourlyThresholdMin) {
                      setValue('fallbackSettings.noMotionThreshold1', ClimateControlConstants.HourlyThresholdMin)
                    } else {
                      setValue('fallbackSettings.noMotionThreshold1', e.target.value)
                    }
                  }
                })}
                type='number'
              />
            </ContentWrapper>
            <ContentWrapper>
              {hoursLabel}, {t('ClimateControl.SetTemperatureTo', { ns: 'molecules' })}
              <HeaderRow>
                <StyledNumberInput
                  disabled={!noMotionRulesEnabled}
                  {...register('fallbackSettings.noMotionThreshold1Temp', {
                    valueAsNumber: true,
                    onChange: (e) => {
                      // This is make sure noMotionThreshold1Temp can not be set lower than noMotionThreshold2Temp 
                      if (e.target.value < noMotionThreshold2Temp) {
                        setValue('fallbackSettings.noMotionThreshold2Temp', e.target.value);
                      }
                      if (e.target.value > rangeMaxTemp) {
                        setValue('fallbackSettings.noMotionThreshold1Temp', rangeMaxTemp)
                      } else if (e.target.value < rangeMinTemp) {
                        setValue('fallbackSettings.noMotionThreshold1Temp', rangeMinTemp)
                        setValue('fallbackSettings.noMotionThreshold2Temp', rangeMinTemp);
                      } else {
                        setValue('fallbackSettings.noMotionThreshold1Temp', e.target.value)
                      }
                    }
                  })}
                  type='number'
                />
                <Unit>{unit}</Unit>
              </HeaderRow>
            </ContentWrapper>
          </StyledFlexRow>
          <StyledFlexRow disabled={!noMotionRulesEnabled}>
            <ContentWrapper>
              {t('ClimateControl.NoMotionFor', { ns: 'molecules' })}
              <StyledNumberInput
                disabled={!noMotionRulesEnabled}
                {...register('fallbackSettings.noMotionThreshold2', {
                  valueAsNumber: true,
                  onChange: (e) => {
                    if (e.target.value > ClimateControlConstants.HourlyThresholdMax) {
                      setValue('fallbackSettings.noMotionThreshold2', ClimateControlConstants.HourlyThresholdMax)
                    } else if (e.target.value <= noMotionThreshold1) {
                      setValue('fallbackSettings.noMotionThreshold2', noMotionThreshold1)
                    } else {
                      setValue('fallbackSettings.noMotionThreshold2', e.target.value)
                    }
                  }
                })}
                type='number'
              />
            </ContentWrapper>
            <ContentWrapper>
              {t('ClimateControl.Hours', { ns: 'molecules' })}, {t('ClimateControl.SetTemperatureTo', { ns: 'molecules' })}
              <HeaderRow>
                <StyledNumberInput
                  disabled={!noMotionRulesEnabled}
                  {...register('fallbackSettings.noMotionThreshold2Temp', {
                    valueAsNumber: true,
                    onChange: (e) => {
                      if (e.target.value > noMotionThreshold1Temp) {
                        setValue('fallbackSettings.noMotionThreshold2Temp', noMotionThreshold1Temp)
                      } else if (e.target.value < rangeMinTemp) {
                        setValue('fallbackSettings.noMotionThreshold2Temp', rangeMinTemp)
                      } else {
                        setValue('fallbackSettings.noMotionThreshold2Temp', e.target.value)
                      }
                    }
                  })}
                  type='number'
                />
                <Unit>{unit}</Unit>
              </HeaderRow>
            </ContentWrapper>
          </StyledFlexRow>
        </StyledFlexColumn>
      </StyledFlexColumn>
    </Card>
  )
}

export default UnoccupiedStepDown

const StyledFlexColumn = styled(FlexColumn)`
  gap: 20px;
`;

const StyledFlexRow = styled(FlexRow) <{ disabled?: boolean }>`
  align-items: center;
  gap: 10px;
  opacity: ${p => p.disabled ? 0.5 : 1};
  transition: opacity .1s;
`;

const StyledLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${p => p.theme.text.secondary};
`;

const Unit = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
  padding-right: 7px;
`;

const HeaderRow = styled(FlexRow)`
  align-items: center;
  gap: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 375px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const StyledNumberInput = styled(Input)`
  width: 60px;
  height: 32px;
  border-radius: 4px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
  text-align: left;
  padding: 10px 0 10px 10px;
`;