import styled from 'styled-components';

export const OutOfDateCircle = styled.div<{ size: string }>`
  width: ${p => p.size};
  height: ${p => p.size};
  border-radius: 50%;
  background-color: ${p => p.theme.palette.red};
  border: 1px solid ${p => p.theme.palette.backgrounds.surface};

  position: absolute;
  top: -4px;
  right: -4px;
`;