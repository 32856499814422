import { ChallengesResidentAppGetAllBySiteQuery } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppGetAllBySiteQuery';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useApiState } from '@shared/hooks/useApiState';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import TableActionButton from '@dashboard/components/molecules/ResidentApp/Common/TableActionButton';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { ChallengesResidentAppCancelCommand } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppCancelCommand';
import { useApi } from '@shared/hooks/useApi';
import { ChallengeStatus } from '@shared/api/models/ResidentApp/enums/ChallengeStatus';
import { getDisplayChallengeStatus, isMetricEnabled } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import MetricTypePill from '../Common/MetricTypePill';

type ChallengeTableItem = {
  id: number;
  metricType?: MetricType;
  duration: string;
  prize: string;
  usersJoined: number;
  status: ChallengeStatus;
  displayStatus: string;
};

type ChallengeOverviewTableProps = {
  hasSiteOperator: boolean;
};

const ChallengeOverviewTable = ({hasSiteOperator}: ChallengeOverviewTableProps) => {
  const { t } = useTranslation(['molecules', 'common']);
  const { site, siteOperator } = useSiteContext();
  const navigate = useNavigate();
  const { localisation } = useLocalisation();
  const { execute, loading: cancelChallengeLoading, error } = useApi();
  const { data: challenges, loading: challengesLoading, execute: refresh } = useApiState(
    {
      query: new ChallengesResidentAppGetAllBySiteQuery(site.id),
      errorMessage: t('ResidentApp.ChallengesLoadFailed'),
    }, []);

  const onCancelChallenge = async (challengeId: number) => {
    await execute({
      query: new ChallengesResidentAppCancelCommand(challengeId),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' }),
      pendingMessage: t('ChangesSavePending', { ns: 'status' }),
    }); 

    if (!error) {
      refresh();
    }
  };

  // show types in table if more than one metric type is available
  const showMetricTypeColumn = (siteOperator?.siteMetricTypes?.length ?? 0) > 1;

  const tableColumns: ITableColumn<ChallengeTableItem>[] = [
    ...(showMetricTypeColumn ? [{
      label: t('ResidentApp.Type'),
      key: 'metricType',
      width: 2,
      customElement: (challengeItem: ChallengeTableItem) => challengeItem.metricType && (
        <MetricTypePill metricType={challengeItem.metricType} />
      )
    }] : []),
    {
      label: t('ResidentApp.ChallengeTitle'),
      key: 'title',
      width: 3
    },
    {
      label: t('ResidentApp.ChallengeDuration'),
      key: 'duration',
      width: 2
    },
    {
      label: t('Prize'),
      key: 'prize',
      width: 2
    },
    {
      label: t('ResidentApp.UsersJoined'),
      key: 'usersJoined',
      width: showMetricTypeColumn ? 1 : 2
    },
    {
      label: t('ResidentApp.Status'),
      key: 'displayStatus',
      width: showMetricTypeColumn ? 1 : 2
    },
    {
      customElement: (challengeItem) => (
        <TableActionButton
          primaryLabel={t('View', { ns: 'common' })}
          primaryAction={() => navigate(`./view-challenge/${challengeItem.id}`)}
          secondaryLabel={t('ResidentApp.CancelChallenge')}
          secondaryAction={() => onCancelChallenge(challengeItem.id)}
          isPrimaryDisabled={cancelChallengeLoading}
          isSecondaryDisabled={
            cancelChallengeLoading || challengeItem.status !== ChallengeStatus.Open
          }
          showWarningDialog={true}
          warningDialogTitle={t('ResidentApp.CancelChallengeWarningTitle', {status: challengeItem.displayStatus.toLowerCase()})}
          warningDialogMessage={t('ResidentApp.CancelChallengeWarningMessage')}
          warningDialogConfirmButtonLabel={t('ResidentApp.CancelChallenge')}
        />
      ),
      rightAlign: true,
      fixedWidth: '80px',
    },
  ];

  // sort challenges by start date
  const records = challenges && hasSiteOperator
    ? [...challenges]
      .filter((challenge) => siteOperator && isMetricEnabled(siteOperator, challenge.metricType))
      .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
      .map((challenge) => ({
        id: challenge.id,
        title: challenge.title,
        duration: format(new Date(challenge.startDate), localisation.dateFormats.slashFormatDisplay) + ' - ' + format(new Date(challenge.endDate), localisation.dateFormats.slashFormatDisplay),
        prize: challenge.prizes.length > 0 && challenge.prizes[0].prizeName ? challenge.prizes[0].prizeName : '-',
        usersJoined: challenge.userParticipationCount ?? 0,
        status: challenge.status,
        displayStatus: t(getDisplayChallengeStatus(challenge)),
        metricType: challenge.metricType,
      }))
    : [];

  return (
    <>
      {challengesLoading ? (
        <Loading message={t('ResidentApp.LoadingChallenges')}/>
      ) : (
        <Table
          columns={tableColumns}
          records={records}
          recordKey="id"
          cardEffect={true}
          emptyMessage={t('ResidentApp.NoChallengesCreated')}
          disableSorting={true}
        />
      )}
    </>
  );
};

export default ChallengeOverviewTable;
