import EsgMetricType from '@dashboard/api/enums/EsgMetricType/EsgMetricType';
import { EsgExplorerMetricsDto, EsgExplorerMetricsSpaceDto } from '@dashboard/api/models/EsgExplorerMetricsDto';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn, TableSortOrder } from '@shared/components/molecules/Table/Table.types';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { TOutlierConfig } from './OutlierWidget';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

type PropTypes = {
  config: TOutlierConfig;
  data: EsgExplorerMetricsDto;
}

const OutlierTable = ({ config, data }: PropTypes) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { trackAction } = useAnalytics();
  const theme = useTheme();
  const { siteHierarchy } = useSiteContext();
  const { toLocale, getUnit } = useLocalisation();
  const hasClusters = data.spaces.some(x => x.clusterName);

  const tableColumns: ITableColumn<EsgExplorerMetricsSpaceDto>[] = useMemo(() => ([
    {
      label: t('Space', { ns: 'common' }),
      key: 'spaceName'
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName'
    },
    {
      label: t('Type', { ns: 'molecules' }),
      key: 'spaceType',
      displayFormat: ({ spaceType }) => t(spaceType, { ns: 'enums' })
    },
    {
      label: t('ClusterName', { ns: 'common' }),
      key: 'clusterName',
      hidden: !hasClusters
    },
    {
      label: t('NoOfBedrooms', { ns: 'common' }),
      key: 'bedroomsInCluster',
      hidden: !hasClusters
    },
    {
      label: (
        <Trans i18nKey={`enums:TitleRowLabel.${config.metric}`}>
          <br />
          {{ unit: config.metric ? getUnit(EsgMetricType.toMetricType(config.metric)) : '' }}
          {{ areaUnit: getUnit(AltUnits.Area) }}
        </Trans>
      ),
      key: 'metric',
      displayFormat: ({ esgMeasurements }) => (esgMeasurements[0].value && config.metric) ? toLocale(EsgMetricType.toMetricType(config.metric), esgMeasurements[0].value, { round: 2 }).toString() : '',
      sortFormat: ({ esgMeasurements }) => (esgMeasurements[0].value && config.metric) ? toLocale(EsgMetricType.toMetricType(config.metric), esgMeasurements[0].value, { round: 2 }) : 0
    }
  ]), [t, toLocale, getUnit, config, hasClusters]);

  const onRowClick = useCallback((row: EsgExplorerMetricsSpaceDto) => {
    const floorId = siteHierarchy?.buildings.flatMap(x => x.floors).find(x => x.spaces.some(s => s.id === row.spaceId))?.id;

    if (floorId) {
      trackAction('view_space', 'overview_outliers', { metric: config.metric, time_span: config.timeRangeType, filter: config.filter?.type });
      navigate(`./../building/${row.buildingId}/floor/${floorId}/space/${row.spaceId}`);
    }
  }, [siteHierarchy, config, navigate, trackAction]);

  return (
    <Table
      card={{
        style: { boxShadow: `0px 4px 12px -3px ${theme.shadow.dark}` }
      }}
      removeDefaultStyling
      recordBorder
      columns={tableColumns}
      records={data.spaces}
      recordKey="spaceId"
      defaultSortColumn="metric"
      defaultSortOrder={TableSortOrder.DESC}
      onRowClick={onRowClick}
      minHeight='none'
      disablePaging={data.spaces.length <= 10}
    />
  );
};

export default OutlierTable;