import { AlertStatus, Alert } from '@shared/api/models/Alert/Alert';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class AlertUpdateCommand extends PatchQuery<Alert> {
  status: AlertStatus;

  constructor(id: number, status: AlertStatus) {
    super(id);
    this.status = status;
  }

  public targetUrl(): string {
    return '/alerting/UpdateAlert';
  }
}
