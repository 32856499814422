import { GenericDeleteQuery } from '../common/GenericDeleteQuery';

export class SiteFeatureDeleteCommand extends GenericDeleteQuery<unknown> {
  siteId: number;
  featureId: number;

  constructor(siteId: number, featureId: number) {
    super();
    this.siteId = siteId;
    this.featureId = featureId;
  }

  public targetUrl(): string {
    return '/features/DeleteSiteFeature';
  }
}
