import styled, { css } from 'styled-components';
import { Control, FieldArrayWithId, FormState, UseFieldArrayUpdate, UseFormRegister, UseFormSetValue, useFieldArray, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClusterFormValues, SpaceFormValues, SpacesFormValues } from './BuildingSpaceList';
import { useCallback, useEffect, useState } from 'react';
import SpaceEditRow from './SpaceEditRow';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { Space } from '@shared/api/models/Space/Space';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { transparentize } from 'polished';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { isModified } from '../SpaceUtils';

type PropTypes = {
  clusterFormValues: ClusterFormValues,
  field: FieldArrayWithId<SpacesFormValues, 'spacesFieldArray', 'generated-id'>
  clusterIndex: number,
  formState: FormState<SpacesFormValues>,
  register: UseFormRegister<SpacesFormValues>,
  update: UseFieldArrayUpdate<SpacesFormValues, 'clusters'>;
  remove: (index: number) => void,
  control: Control<SpacesFormValues>,
  editMode: boolean;
  isClusterModified: boolean;
  originalClusterSpaces: Space[];
  setValue: UseFormSetValue<SpacesFormValues>
}

const ClusterEditRow = ({ clusterFormValues, field, clusterIndex, formState, register, update, remove, control, editMode, isClusterModified, originalClusterSpaces, setValue }: PropTypes) => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  const { fields, remove: removeClusterSpace, update: updateClusterSpace } = useFieldArray({
    control,
    name: `clusters.${clusterIndex}.spaces`,
    keyName: 'generated-id'
  });

  const clusterSpacesFormValues = useWatch({
    control,
    name: `clusters.${clusterIndex}.spaces`
  });

  const updateClusterValues = useCallback(() => {
    const maxOccupancy = clusterSpacesFormValues?.reduce((acc, curr) => curr.maxOccupancy ? acc + curr.maxOccupancy : acc, 0);
    const area = clusterSpacesFormValues?.reduce((acc, curr) => curr.area ? acc + curr.area : acc, 0);
    setValue(`clusters.${clusterIndex}.maxOccupancy`, maxOccupancy);
    setValue(`clusters.${clusterIndex}.area`, area);
  }, [clusterIndex, clusterSpacesFormValues, setValue])

  useEffect(() => {
    updateClusterValues();
    if (clusterFormValues.toBeDeleted && !clusterSpacesFormValues?.every((x) => x.toBeDeleted)) {
      clusterSpacesFormValues?.forEach((_, i) => {
        setValue(`clusters.${clusterIndex}.spaces.${i}.toBeDeleted`, true);
      });
    }
  }, [clusterFormValues.toBeDeleted, clusterIndex, clusterSpacesFormValues, setValue, updateClusterValues]);

  return (
    <ClusterWrapper hasChanged={(clusterFormValues.toBeCreated ?? clusterFormValues.toBeDeleted ?? isClusterModified)}    >
      <ChevronIcon
        icon={isOpen ? faChevronUp : faChevronDown}
        onClick={() => setIsOpen(!isOpen)}
      />

      <SpaceEditRow
        formValues={clusterFormValues}
        key={clusterIndex}
        field={field}
        index={clusterIndex}
        formState={formState}
        register={register}
        update={update}
        remove={remove}
        control={control}
        editMode={editMode}
        isModified={isClusterModified}
        isCluster={true}
      />

      <Collapsible isOpen={isOpen} numItems={editMode ? fields.length + 1 : fields.length} >
        {clusterSpacesFormValues && clusterSpacesFormValues.length > 0 && fields.map((x, i) => (clusterSpacesFormValues[i]) && (
          <SpaceEditRow
            formValues={clusterSpacesFormValues[i]}
            key={x.id || i}
            field={x}
            index={i}
            formState={formState}
            register={register}
            update={updateClusterSpace}
            remove={removeClusterSpace}
            control={control}
            editMode={editMode}
            isModified={isModified(originalClusterSpaces, clusterSpacesFormValues[i])}
            isCluster={true}
            clusterIndex={clusterIndex}
          />
        ))}
        {editMode &&
          <FlexRow>
            <Label>
              {t('Buildings.Spaces.SpaceManagement.AddSpaceToCluster', { ns: 'settingsAsset' })}
            </Label>

            <AddExceptionButton
              icon={solid('plus')}
              onClick={() => {
                setValue(`clusters.${clusterIndex}.spaces`, [...clusterFormValues.spaces ? clusterFormValues.spaces : [],
                { spaceType: SpaceType.Bedroom, occupied: true, toBeCreated: true } as SpaceFormValues]);
              }}
            />
          </FlexRow>
        }
      </Collapsible>
    </ClusterWrapper>
  );
};

export default ClusterEditRow;

const ClusterWrapper = styled.div<{ hasChanged: boolean }>`
  position: relative;
  ${p => p.hasChanged && css`
  > svg{
    left: -5px;
  }
  `}
`;

const Collapsible = styled.div<{ isOpen: boolean; numItems: number }>`
  display: flex;
  flex-direction: column;
  height: 0;
  transition: all 300ms ease;
  overflow: hidden;
  
  ${(p) =>
    p.isOpen && css`
      height: ${`calc(${p.numItems} * 56px)`};
      overflow: visible;
    `}
`;

const ChevronIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 20px;
  left: 5px;
  color: ${p => p.theme.palette.text.weak};
  font-size: 12px;
  cursor: pointer;

  @media (min-width: 600px) {
    padding: 0 12px;
  }
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

export const AddExceptionButton = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${p => p.theme.palette.text.fair};
  padding: 8px;
  background: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 2px 4px 0 ${p => p.theme.palette.shadows.strong};
  border-radius: 6px;
  cursor: pointer;
`;

const FlexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 54px;
    margin: 0px 25px 2px;
    padding: 6px 15px;
    align-items: center;
    border-radius: 5px;
    border: 1px dashed transparent;
    gap: 15px;
    background-color: ${p => transparentize(0.9, p.theme.palette.text.weak)};
`;