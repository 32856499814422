import { OperatorPatchQuery } from '@shared/api/queries/ResidentApp/common/OperatorPatchQuery';
import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';

export class PrizesResidentAppBulkUpdateCommand extends OperatorPatchQuery<OperatorPrizeDto[]> {
  prizes: OperatorPrizeDto[];

  constructor(prizes: OperatorPrizeDto[]) {
    super();
    this.prizes = prizes;
  }

  public targetUrl(): string {
    return '/Prizes/BulkUpdate';
  }
}
