import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNumber } from '@shared/utils/NumberUtils';
import { useCallback } from 'react';
import styled from 'styled-components';

type CustomNumberInputProps = {
  value: number
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
}

const CustomNumberInput = ({ value, onChange, min, max, disabled }: CustomNumberInputProps) => {

  const addOne = useCallback(() => {
    if (max !== undefined && value >= max) {
      return;
    }

    onChange(value + 1);
  }, [value, max, onChange]);

  const subtractOne = useCallback(() => {
    if (min !== undefined && value <= min) {
      return;
    }

    onChange(value - 1);
  }, [value, min, onChange]);

  const handleChange = (stringValue: string) => {
    let numericValue = !isNumber(stringValue) ? 0 : parseInt(stringValue);

    if (min !== undefined && numericValue < min) {
      numericValue = min;
    }

    if (max !== undefined && numericValue > max) {
      numericValue = max;
    }

    onChange(numericValue);
  };

  if (disabled) {
    return (
      <ReadOnlyValue>
        {value}
      </ReadOnlyValue>
    );
  }

  return (
    <Wrapper>
      <StyledInput
        type='number'
        min={min}
        max={max}
        value={value}
        onChange={e => handleChange(e.currentTarget.value)}
        disabled={disabled}
      />

      <Arrows>
        <Arrow
          icon={solid('chevron-up')}
          onClick={() => addOne()}
        />
        <Arrow
          icon={solid('chevron-down')}
          onClick={() => subtractOne()}
        />
      </Arrows>
    </Wrapper>
  );
};

export default CustomNumberInput;

const Wrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  text-align: right;
  color: ${p => p.theme.text.primary};

  width: 46px;
  height: 28px;
  padding-right: 22px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: none;
  transition: all 150ms ease;

  ${Wrapper}:hover & {
    border-color: ${p => p.theme.palette.forms.input.border};
    box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
    background-color: ${p => p.theme.palette.backgrounds.surface};
  }
  
  &:focus {
    outline: none;
    border-color: ${p => p.theme.palette.forms.input.border};
    box-shadow: 0px 5px 10px -2px ${(p) => p.theme.palette.forms.input.focusShadow};
  }

  /* Hide native up/down arrows in Firefox */
  @-moz-document url-prefix() { 
    appearance: textfield;
  }

  /* Hide native up/down arrows in Chrome/Edge */
  &::-webkit-inner-spin-button {
    display: none;
  }
`;

const Arrows = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  height: 27px;
  display: none;
  flex-direction: column;
  border-radius: 4px;

  ${Wrapper}:hover & {
    display: flex;
    background-color: ${p => p.theme.palette.backgrounds.surface};
  }

  ${StyledInput}:focus ~ & {
    display: flex;
    background-color: ${p => p.theme.palette.backgrounds.surface};
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  font-size: 10px;
  color: ${p => p.theme.palette.text.weak};
  border: 1px solid ${p => p.theme.palette.forms.input.border};

  &:hover {
    color: ${p => p.theme.palette.text.onPrimary};
    background-color: ${p => p.theme.palette.primary};
  }

  &:first-child {
    padding: 3px 4px 0px 4px;
    border-radius: 0 4px 0 0;
    border-width: 1px 1px 0 0;
  }

  &:last-child {
    padding: 0px 4px 3px 4px;
    border-radius: 0 0 4px 0;
    border-width: 0 1px 1px 0;
  }
`;

const ReadOnlyValue = styled.div`
  width: 46px;
  height: 28px;
  padding-right: 23px;

  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  color: ${p => p.theme.text.primary};

  display: flex;
  align-items: center;
  justify-content: end;
`;