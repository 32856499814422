import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { SpaceWithDeviceMetrics } from '@shared/api/models/Space/SpaceWithDeviceMetrics';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class SpacesWithMetricsQuery extends PostQuery<SpaceWithDeviceMetrics[]> {
  floorId: number;
  metricTypes: readonly MetricType[];
  includeEnergyConsumptionAggregation?: boolean;

  constructor(floorId: number, metricTypes: readonly MetricType[], includeEnergyConsumptionAggregation: boolean) {
    super();
    this.floorId = floorId;
    this.metricTypes = metricTypes;
    this.includeEnergyConsumptionAggregation = includeEnergyConsumptionAggregation;
  }

  public targetUrl(): string {
    return '/metrics/spaces';
  }
}
