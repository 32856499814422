import { Metric } from '@shared/api/models/Metric/Metric';
import { IGradeConfig, IScoreWidgetConfig } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import { stringToFloat } from '@shared/utils/NumberUtils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const getGrade = (score: number, grades?: IGradeConfig[]): IGradeConfig | undefined => {
  if (!grades || grades.length === 0) {
    return;
  }

  const lastGrade = grades[grades.length - 1];

  if (score >= lastGrade.threshold) {
    return lastGrade
  }

  return grades.find(x => score < x.threshold);
};

interface IScoreWidget {
  metrics: Metric[];
  config?: IScoreWidgetConfig;
}

const ScoreWidget = ({ metrics, config }: IScoreWidget) => {
  const { t } = useTranslation(['deviceConfigs']);

  if (!config) {
    return null;
  }

  const metric = metrics.find(x => x.metricType === config.score.metricType);

  if (!metric) {
    return null;
  }

  const score = stringToFloat(metric.value, config.score.decimalPlaces ?? 0);
  const grade = getGrade(score, config.grades);

  return (
    <Wrapper>
      <ScoreSquare>
        <Score>
          {score}<MaxScoreSlash> /</MaxScoreSlash><MaxScore>{config.maxScore}</MaxScore>
        </Score>
        {grade &&
          <GradeLabel color={grade.color}>
            {t(grade.label, { ns: 'deviceConfigs' })}
          </GradeLabel>
        }
      </ScoreSquare>
      <ScoreTitle>
        {t(config.label, { ns: 'deviceConfigs' })}
      </ScoreTitle>
    </Wrapper>
  );
};

export default ScoreWidget;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
`;

const Score = styled.div`
  font-size: 38px;
  font-weight: 500;
`;

const MaxScoreSlash = styled.span`
  font-size: 18px;
  color: ${p => p.theme.palette.text.weak};
  font-weight: 400;
`;

const MaxScore = styled.span`
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  font-weight: 500;
`;

const GradeLabel = styled.div<{ color?: string }>`
  color: ${p => p.color};
  font-weight: 500;
  margin-top: -5px;
`;

const ScoreTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const ScoreSquare = styled.div`
  background-image: ${p => `linear-gradient(to top, ${p.theme.palette.backgrounds.surface}, ${p.theme.palette.backgrounds.surfaceStrong})`};
  border-radius: 25px;
  box-shadow: 0 10px 8px -4px  ${p => p.theme.palette.shadows.medium};
  width: 130px;
  height: 130px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;