import { OperatorGetQuery } from '@shared/api/queries/ResidentApp/common/OperatorGetQuery';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { BlobGetSasUriDto } from '@shared/api/models/ResidentApp/BlobGetSasUriDto';

export class BlobResidentAppGetUriQuery extends OperatorGetQuery<BlobGetSasUriDto> {
  blobName: string;
  blobStorageContainerType: BlobStorageContainerType;

  constructor(blobName: string, blobStorageContainerType: BlobStorageContainerType) {
    super();
    this.blobName = blobName;
    this.blobStorageContainerType = blobStorageContainerType;
  }

  public targetUrl(): string {
    return `/Blob/GetSasUri/${this.blobStorageContainerType}/${this.blobName}`;
  }
}
