import SeasonalityPercentage from './SeasonalityPercentage';
import { SiteFairUseSeasonalityAdjustment } from '@shared/api/models/FairUse/SiteFairUseSeasonalityAdjustment';
import { getAbbreviatedMonthName } from '@shared/utils/DateUtils';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { useTranslation } from 'react-i18next';
import { round } from '@shared/utils/NumberUtils';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { getRemainingPercentage } from '@dashboard/components/molecules/FairUse/FairUseUtils';
import { FairUseForm } from '@shared/api/models/FairUse/FairUseForm';

type SeasonalityTableProps = {
  seasonalityAdjustments: SiteFairUseSeasonalityAdjustment[];
  energyMeterType: EnergyMeterType;
  disabled: boolean;
  remainingPercentage: number;
  total: number;
}

const SeasonalityTable = ({ seasonalityAdjustments, energyMeterType, disabled, remainingPercentage, total }: SeasonalityTableProps) => {
  const { t } = useTranslation();
  const { register, setValue, getValues, watch, setError, clearErrors, formState: { errors } } = useFormContext<FairUseForm>();

  const handleChange = (seasonalityAdjustmentsIndex: number, updatedValue: number) => {
    if (updatedValue < 0) {
      setValue(`seasonalityAdjustments.${seasonalityAdjustmentsIndex}.adjustment`, 0);
    } else if (updatedValue <= remainingPercentage + getValues('seasonalityAdjustments')[seasonalityAdjustmentsIndex].adjustment) {
      setValue(`seasonalityAdjustments.${seasonalityAdjustmentsIndex}.adjustment`, updatedValue);
    }
    const updatedRemainingPercentage = getRemainingPercentage(getValues('seasonalityAdjustments'), energyMeterType);
    setValue('remainingPercentage', updatedRemainingPercentage);

    if (updatedRemainingPercentage === 0) {
      clearErrors('remainingPercentage');
    } else {
      setError('remainingPercentage', { type: 'custom', message: t('FairUsage.Validation.RemainingExceeding', { ns: 'molecules' }) });
    }
  }

  return (
    <SeasonalityTableWrapper>

      <FlexRow>
        <SeasonalityHeading></SeasonalityHeading>
        {seasonalityAdjustments.filter(x => x.meterType === energyMeterType).map((seasonalityAdjustment: SiteFairUseSeasonalityAdjustment) =>
          <SeasonalityHeading key={seasonalityAdjustment.id}>
            {getAbbreviatedMonthName(new Date(seasonalityAdjustment.start).getMonth() + 1)}
          </SeasonalityHeading>
        )}

        <SeasonalityHeading>
          {t('FairUsage.Remaining', { ns: 'molecules' })}
        </SeasonalityHeading>
      </FlexRow>

      <FlexRow>
        <SeasonalityHeading>
          %
        </SeasonalityHeading>

        {seasonalityAdjustments.filter(x => x.meterType === energyMeterType).map((seasonalityAdjustment: SiteFairUseSeasonalityAdjustment) =>
          <SeasonalityPercentageWrapper key={seasonalityAdjustment.id}>
            <SeasonalityPercentage
              seasonalityAdjustment={watch(`seasonalityAdjustments.${seasonalityAdjustments.indexOf(seasonalityAdjustment)}`)}
              disabled={disabled}
              onValueChange={(updatedValue) => handleChange(seasonalityAdjustments.indexOf(seasonalityAdjustment), updatedValue)}
            />
          </SeasonalityPercentageWrapper>
        )}
        <SeasonalityItem error={errors.remainingPercentage?.message ? true : false}>
          <RemainingPercentage
            {...register('remainingPercentage', {
              max: {
                value: 0,
                message: t('FairUsage.Validation.RemainingExceeding', { ns: 'molecules' })
              }
            })}
            disabled={errors.remainingPercentage ? false : true}
            readOnly
          />
          <Percentage>
            %
          </Percentage>
        </SeasonalityItem>

      </FlexRow>

      <FlexRow>
        <SeasonalityHeading>
          MWh
        </SeasonalityHeading>

        {seasonalityAdjustments.filter(x => x.meterType === energyMeterType).map((seasonalityAdjustment: SiteFairUseSeasonalityAdjustment) =>
          <SeasonalityValue key={seasonalityAdjustment.id}>
            {round(total * watch(`seasonalityAdjustments.${seasonalityAdjustments.indexOf(seasonalityAdjustment)}.adjustment`) / 100, 2)}
          </SeasonalityValue>
        )}

        <SeasonalityItem>
          {round(total * remainingPercentage / 100, 2)} MWh
        </SeasonalityItem>
      </FlexRow>
    </SeasonalityTableWrapper >
  )
};

export default SeasonalityTable

const FlexRow = styled.div`
display: flex;
flex-flow: column nowrap;
flex-grow: 1;

@media (min-width: 1200px) {
  flex-flow: row nowrap;
  flex-grow: 0;
  }
`;

const SeasonalityTableWrapper = styled.div`
  color: ${p => p.theme.palette.text.fair};
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0px 3px 8px ${(p) => p.theme.palette.shadows.medium};
  overflow: auto;
  max-width: 1000px;
  margin: 15px auto;
  @media (min-width: 1200px) {
    flex-flow: column nowrap;
  }
`;

const SeasonalityItem = styled.div <{ error?: boolean }>`
  font-size: 14px;
  padding: 10px;
  text-align: center;
  border: 1px solid ${p => p.theme.palette.borders.weak};
  background-color: ${(p) => p.theme.palette.seasonalityTable.itemBackground};
  flex: 0 0 40px;

  white-space: nowrap;
  overflow: hidden;

  ${p => p.error && css`
    border: 1px solid ${p => p.theme.palette.red};
  `}

  &:last-child {
    flex: 1 0 40px;
  }

  @media (min-width: 1200px) {
    flex: 1 0 60px;

    &:last-child {
    flex: 0 0 150px;
    }
  }
`;

const RemainingPercentage = styled.input`
  font-size: 14px;
  font-weight: 400;
  font-family: inherit;
  color: ${p => p.theme.palette.text.medium};

  border: none;
  background: none;
  width: 25%;
  text-align: center;

  &:focus {
    outline: none;
    border-color: ${p => p.theme.palette.forms.input.border};
    transition: border-color 150ms ease, box-shadow 150ms ease;
  }
`;

const Percentage = styled.span`
  border: none;
  background: none;
  text-align: center;
`;

const SeasonalityHeading = styled(SeasonalityItem)`
  background-color: ${(p) => p.theme.palette.forms.input.background};
  font-weight: 500;
`;

const SeasonalityPercentageWrapper = styled(SeasonalityItem)`
  padding: 0;
  position: relative;

  &:hover {
    background-color: ${p => p.theme.palette.tables.backgroundHighlight};
  }
`;

const SeasonalityValue = styled(SeasonalityItem)`
  padding: 10px 0 0 0;
  position: relative;

  &:hover {
    background-color: ${p => p.theme.palette.tables.backgroundHighlight};
  }
`;