import { ISwitch } from '@shared/components/atoms/Switch/Switch.types';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import ConsentPreferenceItem from './ConsentPreferenceItem';
import { Button } from '@shared/components/atoms/Button/Button';
import { toast } from 'react-toastify';
import { ConsentCookie, ConsentType, getCookieConsent, updateConsent } from '../ConsentBanner/ConsentBanner';
import { env } from '@shared/env/env';

type ConsentItem = {
  key: string;
  title: string;
  description: string;
  switchOption: ISwitch;
}

type ConsentPreferencesContentProps = {
  onUpdatePreferenceCallback?: () => void;
}

const ConsentPreferencesContent = ({ onUpdatePreferenceCallback }: ConsentPreferencesContentProps) => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();

  const [consentItemsChecked, setConsentItemsChecked] = useState<ConsentCookie>({
    analytical: getCookieConsent()?.analytical,
    functional: getCookieConsent()?.functional
  });

  const consentItems: ConsentItem[] = [
    {
      key: 'consent_necessary',
      title: t('ConsentPreferences.StrictlyNecessary'),
      description: t('ConsentPreferences.StrictlyNecessaryDescription'),
      switchOption: {
        checked: true,
        onChange: () => undefined,
        disabled: true,
        colorChecked: theme.palette.forms.switch.background.disabled
      },
    },
    {
      key: 'consent_functional',
      title: t('ConsentPreferences.Functional'),
      description: t('ConsentPreferences.FunctionalDescription'),
      switchOption: {
        checked: !!consentItemsChecked.functional,
        onChange: (checked) => setConsentItemsChecked({ ...consentItemsChecked, functional: checked }),
      },
    },
    {
      key: 'consent_analytical',
      title: t('ConsentPreferences.Analytical'),
      description: t('ConsentPreferences.AnalyticalDescription'),
      switchOption: {
        checked: !!consentItemsChecked.analytical,
        onChange: (checked) => setConsentItemsChecked({ ...consentItemsChecked, analytical: checked }),
      },
    }
  ]

  const handleUpdatePreferences = () => {
    updateConsent([
      { consentType: ConsentType.analytical, value: !!consentItemsChecked.analytical },
      { consentType: ConsentType.functional, value: !!consentItemsChecked.functional },
    ]);

    onUpdatePreferenceCallback && onUpdatePreferenceCallback();
    toast.success(t('ConsentPreferences.SuccessfullyUpdated'));
  }

  return (
    <ConsentPreferences>
      <Title>{t('ConsentPreferences.Title')}</Title>
      <Subtitle>
        <Trans i18nKey="molecules:ConsentPreferences.Subtitle">
          <Link
            className="link"
            href={env.externalLinks.privacyPolicy}
            target="_blank"
          />
        </Trans>
      </Subtitle>

      <PreferencesSettings>
        {consentItems.map((item) => (
          <ConsentPreferenceItem
            key={item.key}
            title={item.title}
            description={item.description}
            switchOption={item.switchOption}
          />
        ))}
      </PreferencesSettings>

      <Buttons>
        <Button
          label={t('ConsentPreferences.UpdatePreferences')}
          style={{ marginLeft: 'auto' }}
          onClick={handleUpdatePreferences}
        />
      </Buttons>
    </ConsentPreferences>
  );
}

export default ConsentPreferencesContent;

const ConsentPreferences = styled.div`
  border-radius: 4px;
  padding: 25px 20px;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const Subtitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 15px 0 20px;
`;

const Link = styled.a`
  &.link {
    color: ${({ theme }) => theme.palette.primary};
    text-decoration: underline;
  }
`;

const PreferencesSettings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const Buttons = styled.div`
  margin-top: 25px;
  width: 100%;
`;