
import { Route } from '@shared/types/Route';
import { Permission } from '@shared/api/enums/Permission/Permission';

import LandingPage from '@src/components/pages/LandingPage/LandingPage';
import SiteProvider from '@src/components/pages/SitePage/SiteProvider';
import SettingsPage from '@src/components/pages/SettingsPage/SettingsPage';
import ProfilePage from '@src/components/pages/ProfilePage/ProfilePage';
import UserManagementPage from '@src/components/pages/UserManagementPage/UserManagementPage';
import { BuildingManagementRoutes } from './features/BuildingManagementRoutes';
import { SiteManagementRoutes } from './SiteManagementRoutes';
import { BuildingRoutes } from './features/BuildingRoutes';
import { SettingsRoutes } from './SettingsRoutes';
import { AlertingRoutes } from './features/AlertingRoutes';
import { SiteOverviewRoutes } from './features/SiteOverviewRoutes';
import { SitePerformanceRoutes } from './features/SitePerformanceRoutes';
import { HeatmapRoutes } from './features/HeatmapRoutes';
import { ClimateControlRoutes } from './features/ClimateControlRoutes';
import { FairUseRoutes } from './features/FairUseRoutes';
import { EsgExplorerRoutes } from './features/EsgExplorerRoutes';
import { EsgReportRoutes } from './features/EsgReportRoutes';
import { ReportingRoutes } from './features/ReportingRoutes';
import { ResidentAppRoutes } from './features/ResidentAppRoutes';
import { UserManagementRoutes } from './UserManagementRoutes';
import SiteCreate from '@settings/components/organisms/SiteManagement/SiteCreate/SiteCreate';

const routes: Route[] = [
  {
    path: '',
    element: LandingPage,
    analytics: { page: 'landing', category: 'landing' },
  },
  {
    path: 'add-site',
    element: SiteCreate,
    analytics: { page: 'add_site', category: 'add_site' },
  },
  {
    path: 'profile',
    element: ProfilePage,
    analytics: { page: 'profile', category: 'profile' }
  },
  {
    path: 'site/:siteId/*',
    element: SiteProvider,
    permission: Permission.SiteRead,
    children: [
      ...SiteOverviewRoutes,
      ...SitePerformanceRoutes,
      ...AlertingRoutes,
      ...BuildingManagementRoutes,
      ...BuildingRoutes,
      ...HeatmapRoutes,
      ...ClimateControlRoutes,
      ...FairUseRoutes,
      ...ResidentAppRoutes,
      ...EsgReportRoutes,
      ...EsgExplorerRoutes,
      ...ReportingRoutes,
      ...SiteManagementRoutes
    ]
  },
  {
    path: 'user-management/*',
    element: UserManagementPage,
    permission: Permission.UserWrite,
    children: UserManagementRoutes
  },
  {
    path: 'settings/*',
    element: SettingsPage,
    permission: Permission.SuperAdministrator,
    children: SettingsRoutes
  },
];

export default routes;
