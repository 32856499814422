import SpaceUtilitySum from '@dashboard/api/models/SpaceUtilitySum';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { Metric } from '@shared/api/models/Metric/Metric';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { DeviceWidgetType, IWidgetConfig } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import { orderBy, sortBy } from 'lodash';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import DeviceWidget from './DeviceWidget';
import DeltaWidget from './Widgets/DeltaWidget';
import MeasuredOn from './Widgets/MeasuredOn';

const deviceSortOrder: DeviceType[] = [
  DeviceType.Environmental,
  DeviceType.PeopleCounting,
  DeviceType.VirtualPeopleCounting,
  DeviceType.Metering,
  DeviceType.Relay,
  DeviceType.Switch,
  DeviceType.Monitoring,
  DeviceType.Unknown,
];

interface IWidgetWithMetricsGroup {
  displayOrder: number;
  widgets: IWidgetWithMetrics[];
}

interface IWidgetWithMetrics {
  config: IWidgetConfig;
  metrics: Metric[];
  deviceLastMeasuredOn?: string;
}

interface IDeviceWidgets {
  devices: DeviceWithMetrics[];
  maxOccupancy?: number;
  utilitySums?: SpaceUtilitySum[];
  spaceColor: string;
  showDeltas?: boolean;
}

const DeviceWidgets = ({ devices, maxOccupancy, utilitySums, spaceColor, showDeltas }: IDeviceWidgets) => {
  const { getDeviceConfig, getWidgetMetricTypes } = useDeviceConfigContext();

  const deviceTypeSort = (a: DeviceWithMetrics, b: DeviceWithMetrics) => {
    const deviceTypeA = getDeviceConfig(a.deviceModel)?.deviceType;
    const deviceTypeB = getDeviceConfig(b.deviceModel)?.deviceType;

    if (!deviceTypeA || !deviceTypeB) {
      return 0;
    }

    return deviceSortOrder.indexOf(deviceTypeA) - deviceSortOrder.indexOf(deviceTypeB);
  };

  const filterMostRecentUtopiMultiSensors = (devices: DeviceWithMetrics[]): DeviceWithMetrics[] => {
    if (devices === undefined || devices.length === 0) {
      return devices
    }

    const multiSensors = devices.filter(device => device.deviceModel === 'UtopiMultiSensor');
    if (multiSensors.length === 0) {
      return devices;
    }

    const remainingDevices = devices.filter(device => device.deviceModel !== 'UtopiMultiSensor');
    const mostRecentMultiSensor = orderBy(multiSensors, x => x.lastMeasuredOn, ['desc'])[0];
    return [...remainingDevices, mostRecentMultiSensor];
  }

  const widgetGroups: { [key: string]: IWidgetWithMetricsGroup } = {};

  filterMostRecentUtopiMultiSensors(devices).sort(deviceTypeSort).forEach((device, i) => {
    const widgetConfigs = getDeviceConfig(device.deviceModel)?.ui.widgets;

    widgetConfigs?.forEach(widgetConfig => {
      const widgetMetricTypes = getWidgetMetricTypes(widgetConfig);
      const matchingMetrics = device.metrics.filter(metric => widgetMetricTypes.includes(metric.metricType));

      if (matchingMetrics.length === 0) {
        return;
      }

      const widgetWithMetrics: IWidgetWithMetrics = {
        config: widgetConfig,
        metrics: matchingMetrics,
        deviceLastMeasuredOn: device.lastMeasuredOn
      };

      const groupKey = widgetConfig.group?.name ?? uuidv4();

      if (widgetGroups[groupKey]) {
        widgetGroups[groupKey].widgets.push(widgetWithMetrics);
      } else {
        widgetGroups[groupKey] = { displayOrder: i, widgets: [widgetWithMetrics] };
      }
    });
  });

  return (
    <>
      <Wrapper>
        {Object.values(widgetGroups).map((group, i) => (
          <InnerWrapper key={i} order={group.displayOrder}>
            <Row>
              {sortBy(group.widgets, widget => widget.config.group?.sortOrder).map((widget, j) => (
                <Column
                  key={j}
                  numColumns={group.widgets.length}
                  minWidth={widget.config.minWidth}
                  isListWidget={widget.config.type === DeviceWidgetType.List}
                >
                  <DeviceWidget
                    maxOccupancy={maxOccupancy}
                    config={widget.config}
                    metrics={widget.metrics}
                    spaceColor={spaceColor}
                  />
                </Column>
              ))}
            </Row>
            {group.widgets[0].deviceLastMeasuredOn && <MeasuredOn lastMeasuredOn={group.widgets[0].deviceLastMeasuredOn} />}
          </InnerWrapper>
        ))}
      </Wrapper>

      <DeltaWidget
        utilitySums={utilitySums}
        display={showDeltas}
      />
    </>
  );
};

export default DeviceWidgets;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerWrapper = styled.div<{ order?: number }>`
  display: flex;
  flex-direction: column;
  order: ${p => p.order};
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  padding: 15px 20px;
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Column = styled.div<{ numColumns: number, isListWidget: boolean, minWidth?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => !p.isListWidget && css`
    width: 100%;
    
    @media (min-width: 600px) {
      width: ${`calc(100% / ${p.numColumns})`};
      min-width: ${p.minWidth ?? 'unset'};
    }
  `}
`;