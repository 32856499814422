import { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TenantWithLogoUri } from './TenantUserList';
import { useTranslation } from 'react-i18next';
import { UserWithTenants } from '@shared/api/models/User/UserWithTenants';
import { useApi } from '@shared/hooks/useApi';
import TenantUsersUpdateCommand from '@settings/api/queries/Tenants/TenantUsersUpdateCommand';
import { Button } from '@shared/components/atoms/Button/Button';
import Filter, { FilterOption } from '@src/components/shared/Filters/Filter/Filter';
import { sortBy } from 'lodash';

type PropTypes = {
  user: UserWithTenants,
  tenants: TenantWithLogoUri[],
  refreshUsers: () => void
}

const TenantUserSelectWrapper = ({ user, tenants, refreshUsers }: PropTypes) => {
  const { t } = useTranslation(['settingsTenantUser']);
  const { execute } = useApi();
  const theme = useTheme();
  const [options, setOptions] = useState<FilterOption<TenantWithLogoUri>[]>([]);
  const [selectedTenants, setSelectedTenants] = useState<TenantWithLogoUri[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const resetOptions = useCallback(async () => {
    const options: FilterOption<TenantWithLogoUri>[] = sortBy(tenants, x => x.name).map((tenant) => ({
      label: tenant.name,
      displayElement: <LabelWrapper key={tenant.tenantId}>{tenant.operatorLogoId && <><TenantLogo src={tenant.logoUri} />&nbsp;</>}<div>{tenant.name}</div></LabelWrapper>,
      value: tenant,
      selected: user.tenants.some(x => x.tenantId === tenant.tenantId)
    }));

    setOptions(options);
  }, [user, tenants]);

  useEffect(() => {
    resetOptions();
  }, [resetOptions]);

  const handleChange = useCallback((selectedTenants: TenantWithLogoUri[]) => {
    setIsDirty(true);
    setSelectedTenants(selectedTenants);
  }, []);

  const handleDiscard = () => {
    resetOptions();
    setIsDirty(false);
  };

  const handleSave = useCallback(async () => {
    setIsDirty(false);

    const toAdd = selectedTenants.filter(x => !user.tenants.some(a => a.tenantId === x.tenantId)).map(x => x.tenantId);
    const toRemove = user.tenants.filter(x => !selectedTenants.some(a => a.tenantId === x.tenantId)).map(x => x.tenantId);

    await execute({
      query: new TenantUsersUpdateCommand(user.id, toAdd, toRemove),
      successMessage: t('SelectWrapper.ChangesSaved', { ns: 'settingsTenantUser' }),
      errorMessage: t('SelectWrapper.SavingFailed', { ns: 'settingsTenantUser' })
    });

    refreshUsers();
  }, [user, selectedTenants, execute, refreshUsers, t])

  return (
    <FlexRow>
      <Filter
        label={t('Tenants', { ns: 'common' })}
        options={options}
        onChange={handleChange}
        styles={{ marginLeft: -8 }}
      />

      {isDirty &&
        <>
          <Button
            tertiary
            label={t('SelectWrapper.Discard', { ns: 'settingsTenantUser' })}
            onClick={handleDiscard}
            color={theme.palette.red}
            style={{ padding: 8 }}
          />
          <Button
            tertiary
            label={t('SelectWrapper.Save', { ns: 'settingsTenantUser' })}
            onClick={handleSave}
            style={{ padding: 8 }}
          />
        </>
      }
    </FlexRow>
  );
};

export default TenantUserSelectWrapper;

const TenantLogo = styled.img`
  width: 20px;
  height: auto;
  max-height: 20px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
`;