import { EnergyMeterType } from './EnergyMeterType';

/**
 * Get suffix
 */
export const EnergyMeterType_Suffix = (energyMeterType: EnergyMeterType): string => {
  return energyMeterTypeToSuffix[energyMeterType] ?? '';
}

/**
* Map EnergyMeterType to a suffix
*/
const energyMeterTypeToSuffix: { [key in EnergyMeterType]: string } = Object.freeze({
  [EnergyMeterType.Heating]: 'kWh',
  [EnergyMeterType.Electricity]: 'kWh',
  [EnergyMeterType.Gas]: 'm³',
  [EnergyMeterType.Water]: 'm³',
  [EnergyMeterType.Cooling]: 'kWh',
  [EnergyMeterType.HeatingCooling]: 'kWh'
});