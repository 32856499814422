import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { OperatorPatchQuery } from '@shared/api/queries/ResidentApp/common/OperatorPatchQuery';

export class ChallengesResidentAppConfirmWinnersCommand extends OperatorPatchQuery<OperatorChallengeDto> {
  id: number;
  winnersConfirmed: boolean;

  constructor(id: number, winnersConfirmed: boolean) {
    super();
    this.id = id;
    this.winnersConfirmed = winnersConfirmed;
  }

  public targetUrl(): string {
    return '/Challenges/ConfirmWinners';
  }
}
