export const exportCSV = (fileName: string, csvString: string) => {
  const a = document.createElement('a');
  a.textContent = 'download';
  a.download = fileName;
  a.href = 'data:text/csv;charset=utf-8,' + escape(csvString);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export type CsvColumn<TObject> = {
  key: string;
  header: string;
  modifier?: (object: TObject) => string;
  hidden?: boolean;
}

export const convertToCSV = <TObject>(columns: CsvColumn<TObject>[], items: TObject[]) => {
  const filteredCoumns = columns.filter(x => !x.hidden);

  const headers = filteredCoumns.map(x => x.header).join(',').concat('\n');

  const rows = items.map(row => {
    const rowColumns = filteredCoumns.map(c => {
      const value = row[c.key as keyof TObject];
      return c.modifier ? c.modifier(row) : value;
    });
    return rowColumns.join(',').concat('\n');
  }).join('');

  return headers.concat(rows);
};