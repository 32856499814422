import { useCallback, useEffect, useRef, useState } from 'react';

type PropTypes = {
  disableCloseOnClickOutside?: boolean;
};

export const usePopover = ({ disableCloseOnClickOutside }: PropTypes) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setVisible(prevState => !prevState);
  };

  const handleClick = useCallback((e: MouseEvent) => {
    if (!ref?.current?.contains(e.target as HTMLDivElement)) {
      setVisible(false);
    }
  }, []);

  const handleEscapeKey = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') setVisible(false);
  }, []);

  useEffect(() => {
    if (visible && !disableCloseOnClickOutside)
      document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick, visible, disableCloseOnClickOutside]);

  useEffect(() => {
    if (visible) document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey, visible]);

  return {
    visible,
    toggle,
    setVisible,
    ref
  };
};
