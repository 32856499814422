import { Locales } from '@shared/api/enums/Localisation/Locales';
import { UserPreference } from '@shared/api/models/User/UserPreference';
import { PatchQuery } from '@shared/api/queries/common/PatchQuery';

export default class UserPreferenceUpdateCommand extends PatchQuery<UserPreference> {
  userId: number;
  locale: Locales;

  constructor(userId: number, locale: Locales) {
    super();
    this.userId = userId;
    this.locale = locale;
  }

  public targetUrl(): string {
    return '/UserPreferences';
  }
}