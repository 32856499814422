import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { SalesOrderFormValues } from './SalesOrderCreateForm';
import styled from 'styled-components';
import { Button } from '@shared/components/atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { transparentize } from 'polished';
import { Select } from '@shared/components/atoms/Select/Select';
import { Input, Label } from '@shared/components/atoms/Form/Form';
import { useCallback, useEffect, useState } from 'react';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { SalesOrderItem } from '@shared/api/models/SalesOrder/SalesOrderItem';
import SiteSalesOrderBulkUpdateSalesOrderItemsCommand from '@settings/api/queries/SalesOrder/SiteSalesOrderBulkUpdateSalesOrderItemsCommand';
import { useApi } from '@shared/hooks/useApi';
import { Title } from '@shared/components/atoms/Title/Title';

type PropTypes = {
  itemId: string,
}

export type Option = {
  label: string;
  value: string;
}

const SalesOrderEditForm = ({ itemId }: PropTypes) => {
  const { t } = useTranslation();
  const { salesOrder, refreshSalesOrder } = useSiteContext();
  const navigate = useNavigate();
  const [spares, setSpares] = useState<number>(0);
  const { execute } = useApi();
  const [devicesOrderedIsFocused, setDevicesOrderedIsFocused] = useState(false);
  const [devicesToBeInstalledIsFocused, setDevicesToBeInstalledIsFocused] = useState(false);

  const bulkUpdateSalesOrderItems = async (items: SalesOrderItem[]) => {
    await execute({
      query: new SiteSalesOrderBulkUpdateSalesOrderItemsCommand(items),
      successMessage: t('SalesOrder.Messages.UpdateSalesOrderItemsSuccess', { ns: 'molecules' }),
      errorMessage: t('SalesOrder.Messages.UpdateSalesOrderItemsFailure', { ns: 'molecules' })
    })
    refreshSalesOrder()
  }

  const salesOrderItem = salesOrder?.salesOrderItems?.find(x => x.id === parseInt(itemId))

  const { handleSubmit, reset, formState: { errors }, clearErrors, setValue, control, getValues } = useForm<SalesOrderFormValues>({
    defaultValues: {
      devicesOrdered: salesOrderItem?.numberOfOrders ?? 0,
      devicesToBeInstalled: salesOrderItem?.numberToBeInstalled ?? 0,
    }
  });

  const calculateSpares = useCallback(() => {
    const values = getValues()
    if (values) {
      setSpares(values.devicesOrdered - values.devicesToBeInstalled)
    } else {
      setSpares(0)
    }
  }, [getValues])

  useEffect(() => {
    calculateSpares()

  }, [salesOrder?.salesOrderItems, clearErrors, errors, setValue, salesOrderItem, calculateSpares, getValues]);

  const onSubmit: SubmitHandler<SalesOrderFormValues> = async (data) => {
    if (salesOrderItem) {
      const updatedItem: SalesOrderItem = {
        id: salesOrderItem?.id,
        siteSalesOrderId: salesOrderItem?.siteSalesOrderId,
        deviceType: salesOrderItem?.deviceType,
        numberOfOrders: data.devicesOrdered,
        numberToBeInstalled: data.devicesToBeInstalled
      }

      bulkUpdateSalesOrderItems([updatedItem])
    }

    reset()
    navigate('./..')
  }

  return (
    <>
      <Title
        text={t('SalesOrder.EditOrder', { ns: 'molecules' })}
      />
      {errors.devicesOrdered && errors.devicesToBeInstalled &&
        <ErrorMessage>{t('SalesOrder.AllMandatoryFields', { ns: 'molecules' })}</ErrorMessage>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.DeviceType', { ns: 'molecules' })}</Label>
        <Select
          placeholder={`${salesOrderItem?.deviceType}`}
          isDisabled={true}
        />
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.DevicesOrdered', { ns: 'molecules' })}*</Label>
        <Controller
          name="devicesOrdered"
          control={control}
          rules={{
            required: t('SalesOrder.DevicesOrderedRequired', { ns: 'molecules' }),
            validate: (value) => value !== 0 || t('SalesOrder.FieldRequired', { ns: 'molecules' }),
          }}
          render={({ field }) => (
            <Input
              {...field}
              type="number"
              onChange={(input) => {
                const numberValue = stringToNumber(input.target.value) === null ? '' : stringToNumber(input.target.value)
                field.onChange(numberValue);
                calculateSpares()
              }}
              style={errors?.devicesOrdered?.message || (devicesOrderedIsFocused && spares < 0) ? { borderColor: 'red' } : {}}
              onFocus={() => setDevicesOrderedIsFocused(true)}
              onBlur={() => setDevicesOrderedIsFocused(false)}
            />
          )}
        />
        {!(errors.devicesOrdered && errors.devicesToBeInstalled) &&
          <ErrorMessage>{errors?.devicesOrdered?.message}</ErrorMessage>
        }
        {devicesOrderedIsFocused && spares < 0 &&
          <ErrorMessage>
            {t('SalesOrder.OrderedHasToBeMore', { ns: 'molecules' })}
          </ErrorMessage>
        }
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.DevicesToBeInstalled', { ns: 'molecules' })}*</Label>
        <Controller
          name="devicesToBeInstalled"
          control={control}
          rules={{
            required: t('SalesOrder.DevicesToBeInstalledRequired', { ns: 'molecules' }),
            validate: (value) => value !== 0 || t('SalesOrder.FieldRequired', { ns: 'molecules' }),
          }}
          render={({ field }) => (
            <Input
              {...field}
              type="number"
              onChange={(input) => {
                const numberValue = stringToNumber(input.target.value) === null ? '' : stringToNumber(input.target.value)
                field.onChange(numberValue);
                calculateSpares()
              }}
              style={errors?.devicesToBeInstalled?.message || (devicesToBeInstalledIsFocused && spares < 0) ? { borderColor: 'red' } : {}}
              onFocus={() => setDevicesToBeInstalledIsFocused(true)}
              onBlur={() => setDevicesToBeInstalledIsFocused(false)}
            />
          )}
        />
        {!(errors.devicesOrdered && errors.devicesToBeInstalled) &&
          <ErrorMessage>{errors?.devicesToBeInstalled?.message}</ErrorMessage>
        }
        {devicesToBeInstalledIsFocused && spares < 0 &&
          <ErrorMessage>
            {t('SalesOrder.ToBeInstalledHasToBeLess', { ns: 'molecules' })}
          </ErrorMessage>
        }
        <Label
          style={{ marginTop: '15px' }}
        >{t('SalesOrder.TableColumns.SpareDevices', { ns: 'molecules' })}</Label>
        {spares > 0 &&
          <Spares>
            {spares}
          </Spares>}
        {spares < 0 && <ErrorMessage>{spares}</ErrorMessage>}
        {spares < 0 && !devicesOrderedIsFocused && !devicesToBeInstalledIsFocused &&
          <ErrorMessage>{t('SalesOrder.OrderedHasToBeMore', { ns: 'molecules' })}</ErrorMessage>
        }
        <ButtonWrapper>
          <Button
            secondary
            label={t('Cancel', { ns: 'common' })}
            onClick={() => navigate('./..')}
            style={{ width: '85px' }}
          />
          <CustomButton
            type={'submit'}
            disabled={spares < 0}
          >
            {t('Save', { ns: 'common' })}
          </CustomButton>
        </ButtonWrapper>
      </form>
    </>
  )
}

export default SalesOrderEditForm

const ButtonWrapper = styled.div` 
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
`;

const CustomButton = styled.button`
  width: 85px;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: '5px 10px';
  font-weight: 500;
  font-size: 14px;
  font-family: 'DM Sans';
  text-align: center;
  transition: all 150ms ease-out;
  cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${p => p.theme.palette.text.onPrimary};
  background-color: ${p => p.disabled ? transparentize(0.2, p.color ?? p.theme.palette.primary) : p.theme.palette.primary};
  border-color: ${p => p.theme.palette.primary};

  &:hover {
    box-shadow: 0px 6px 8px -4px ${p => transparentize(0.4, p.theme.palette.primary)};
  }
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  margin-top: 10px;
  color: red;
`;

const Spares = styled.div`
  font-size: 14px;
  font-weight: 500;
`;