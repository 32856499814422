import { Location } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { IRouterPrompt } from './RouterPrompt.types';
import { useModal } from '@shared/hooks/useModal';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const RouterPrompt = ({ when: condition }: IRouterPrompt) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, setIsOpen, ref } = useModal({});
  const [nextLocation, setNextLocation] = useState<Location | null>(null);

  const shouldBlock = useCallback(() => {
    return condition === true;
  }, [condition]);

  const blocker = useBlocker(({ nextLocation }) => {
    setNextLocation(nextLocation);
    return shouldBlock();
  });

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsOpen(true);
    }
  }, [blocker, setIsOpen]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleConfirm = useCallback(() => {
    if (blocker.state === 'blocked' && nextLocation) {
      blocker.reset();
      setIsOpen(false);
      navigate(nextLocation.pathname);
    }
  }, [blocker, nextLocation, navigate, setIsOpen]);

  return (
    <WarningDialog
      modalRef={ref}
      isOpen={isOpen}
      sectionOne={t('UnsavedChangesWarning', { ns: 'navigation' })}
      cancelButton={t('Stay', { ns: 'navigation' })}
      confirmButton={t('Leave', { ns: 'navigation' })}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
};
