import { OperatorPostQuery } from '@shared/api/queries/ResidentApp/common/OperatorPostQuery';
import { OperatorUserDto } from '@shared/api/models/ResidentApp/OperatorUserDto';

export default class UsersResidentAppCreateCommand extends OperatorPostQuery<OperatorUserDto> {
  email: string;
  firstName: string;
  lastName: string;
  spaceId: number;
  siteId: number;
  moveInDate: string;
  moveOutDate: string;

  constructor(email: string, firstName: string, lastName: string, spaceId: number, siteId: number, moveInDate: string, moveOutDate: string) {
    super();
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.spaceId = spaceId;
    this.siteId = siteId;
    this.moveInDate = moveInDate;
    this.moveOutDate = moveOutDate;
  }

  public targetUrl(): string {
    return '/Users/Create';
  }
}
