import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FairUseForm } from '@shared/api/models/FairUse/FairUseForm'
import { useFormContext, useFieldArray, FieldArrayWithId } from 'react-hook-form'
import { SpaceTypeFairUsePolicy } from '@shared/api/models/FairUse/SpaceTypeFairUsePolicy'
import Exception from './Exception'
import AddException from './AddException'
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider'
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType'

type ExceptionProps = {
  editMode: boolean
  policyId: number
}

const ExceptionsTable = ({ editMode, policyId }: ExceptionProps) => {
  const { t } = useTranslation();
  const { site } = useSiteContext();
  const { control, watch, formState: { defaultValues } } = useFormContext<FairUseForm>();
  const energyMeterType = watch('energyMeterType');
  const { fields: exceptionsFields } = useFieldArray({
    control,
    name: 'exceptions'
  });
  const { fields: spaceTypePoliciesFields } = useFieldArray({
    control,
    name: 'spaceTypePolicies'
  });

  const getExceptionSpaceIds = (): number[] => {
    return exceptionsFields.flatMap(exceptionsField => exceptionsField.spaceIds)
  }

  const getExceptionClusterIds = (): (number | undefined)[] => {
    return exceptionsFields.flatMap(exceptionsField => exceptionsField.clusterId).filter(exceptionsFields => exceptionsFields !== null);
  }

  const getSpaceTypes = (): SpaceType[] => {
    return spaceTypePoliciesFields.map(spaceTypePoliciesField => spaceTypePoliciesField.spaceType)
  }

  const getSpaceTypePolicyIndex = (exceptionField: FieldArrayWithId<FairUseForm, 'exceptions', 'id'>): number => {
    const spaceTypePolicy = spaceTypePoliciesFields
      .filter((spaceTypePoliciesField: SpaceTypeFairUsePolicy) => spaceTypePoliciesField.meterType === energyMeterType)
      .find((spaceTypePoliciesField: SpaceTypeFairUsePolicy) => {
        if ((!exceptionField.spaceType) && exceptionField.clusterId !== null) {
          return spaceTypePoliciesField
        }
        if (spaceTypePoliciesField.spaceType === exceptionField.spaceType && !exceptionField.clusterId) {
          return spaceTypePoliciesField
        }
      });
    return spaceTypePoliciesFields.findIndex((spaceTypePoliciesField: SpaceTypeFairUsePolicy) => spaceTypePoliciesField.id === spaceTypePolicy?.id);
  };

  const sortExceptions = (exceptionsFields: FieldArrayWithId<FairUseForm, 'exceptions', 'id'>[]): FieldArrayWithId<FairUseForm, 'exceptions', 'id'>[] => {
    const filteredExceptionsFields = exceptionsFields.filter(exceptionField => exceptionField.meterType === energyMeterType);
    return filteredExceptionsFields.sort((currentRow, nextRow) => {
      if (!defaultValues?.exceptions) {
        return -1;
      }

      const newExceptions = exceptionsFields.filter(exception => !defaultValues?.exceptions?.find(defaultException => defaultException?.spaceIds === exception.spaceIds))

      if (newExceptions.find(exception => nextRow.spaceIds === exception.spaceIds)) {
        return 1;
      } else if (newExceptions.find(exception => currentRow.spaceIds === exception.spaceIds)) {
        return -1;
      }

      const compareBuildingName = currentRow.buildingName.localeCompare(nextRow.buildingName);
      const compareSpaceName = currentRow.name.localeCompare(nextRow.name);
      return compareBuildingName || compareSpaceName;
    });
  }

  return (
    <ExceptionsContainer>
      {(editMode || exceptionsFields.length > 0) &&
        <>
          <ExceptionsTitle>
            {t('FairUsage.Exceptions.ExceptionTitle', { ns: 'molecules' })}
          </ExceptionsTitle>

          {sortExceptions(exceptionsFields).map((exceptionField) => {
            const index = exceptionsFields.indexOf(exceptionField);
            const spaceTypePolicyIndex = getSpaceTypePolicyIndex(exceptionField);
            return (
              <Exception
                key={exceptionField.id}
                exception={watch(`exceptions.${index}`)}
                index={index}
                editMode={editMode}
                spaceTypeFairUsePolicy={watch(`spaceTypePolicies.${spaceTypePolicyIndex}`)}
              />
            )
          })}
        </>
      }

      {editMode &&
        <AddException
          siteId={site.id}
          exceptionSpaceIds={getExceptionSpaceIds()}
          exceptionClusterIds={getExceptionClusterIds()}
          energyMeterType={energyMeterType}
          policyId={policyId}
          spaceTypes={getSpaceTypes()}
          onChange={getExceptionSpaceIds}
        />
      }
    </ExceptionsContainer>
  )
}

export default ExceptionsTable;

const ExceptionsTitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  padding: 15px 0;
`;

const ExceptionsContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction:column;
`;