import SpaceOccupancyDto from '@dashboard/api/models/SpaceOccupancyDto';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { useModal } from '@shared/hooks/useModal';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SpaceOccupancy from './SpaceOccupancy';

type PropTypes = {
  spaces?: SpaceOccupancyDto[];
}

const OccupancyModal = ({ spaces }: PropTypes) => {
  const { t } = useTranslation();
  const { ref, isOpen, toggle } = useModal({});

  return (
    <>
      <InfoIcon
        icon={solid('up-right-and-down-left-from-center')}
        onClick={toggle}
      />

      <Modal
        ref={ref}
        isOpen={isOpen}
        hide={toggle}
        title={t('Occupancy', { ns: 'common' })}
      >
        <FlexRow>
          {spaces?.map(x => (
            <WidgetWrapper key={x.spaceId}>
              <SpaceOccupancy space={x} chartSize='100px' />
            </WidgetWrapper>
          ))}
        </FlexRow>
      </Modal>
    </>
  );
};

export default OccupancyModal;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const WidgetWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${p => p.theme.action.divider};
  padding: 10px 10px 10px 10px;
`;

const InfoIcon = styled(FontAwesomeIcon) <{ margin?: string }>`
  width: 13px;
  height: 13px;
  margin: ${p => p.margin};
  border-radius: 50%;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  transition: all 150ms ease;
  margin-left: auto;

  &:hover {
    color: ${p => p.theme.palette.primary};
  }
`;
