import { SiteOperatorDto } from '@shared/api/models/ResidentApp/SiteOperatorDto';
import { OperatorGetQuery } from '@shared/api/queries/ResidentApp/common/OperatorGetQuery';

export class SiteOperatorsResidentAppGetBySiteIdQuery extends OperatorGetQuery<SiteOperatorDto> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/SiteOperators/GetBySiteId/${this.siteId}`;
  }
}
