import { NavLink, NavLinkProps } from 'react-router-dom';
import { Protected } from '../Protected/Protected';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { RouteLink } from '@shared/types/Route';
import { GenericObject } from '@shared/utils/ObjectUtils';
import { useCallback } from 'react';

export interface IProtectedLink extends Omit<NavLinkProps, 'to'> {
  link: RouteLink;
  customProps?: GenericObject;
}

export const ProtectedLink = ({ link, customProps, onClick, ...linkProps }: IProtectedLink) => {
  const { trackAction } = useAnalytics();
  const isHidden = link.hideWhen?.(customProps);

  const handleAnalytics = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }

    if (link.analytics) {
      const { action, category, ...props } = link.analytics;
      trackAction(action, category, props);
    }
  }, [link.analytics, trackAction, onClick]);

  if (isHidden) {
    return null;
  }

  return (
    <Protected
      requirePermission={link.permission}
      requireFeatureFlag={link.featureFlag}
      onUnauthorized={null}
    >
      <NavLink
        to={link.path}
        end={link.end}
        {...linkProps}
        onClick={handleAnalytics}
      />
    </Protected>
  );
};
