import { stringToFloat } from '@shared/utils/NumberUtils';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: Signal Strength
 */
const HeatmapConfigSignalStrength: IHeatmapConfig = {
  accessor: (x: string) => {
    return stringToFloat(x, 0);
  },

  scale: {
    points: [
      {
        threshold: 10,
        label: '0%',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 35,
        label: '25',
        color: DefaultScaleColors.ORANGE,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 65,
        label: '50',
        color: DefaultScaleColors.YELLOW,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 85,
        label: '75',
        color: DefaultScaleColors.LIGHTGREEN,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 90,
        label: '100',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      }
    ]
  }
};

export default Object.freeze(HeatmapConfigSignalStrength);