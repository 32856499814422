import styled, { useTheme } from 'styled-components';
import { useCallback, useState } from 'react';
import UserListProfileImage from '../UserList/UserListProfileImage';
import { useTranslation } from 'react-i18next';
import { useApiState } from '@shared/hooks/useApiState';
import UsersGetAllExternalQuery from '@settings/api/queries/Users/UsersGetAllExternalQuery';
import { includesCI } from '@shared/utils/StringUtils';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { Table } from '@shared/components/molecules/Table/Table';
import { TopBarHeight } from '@shared/theme/designConstants';
import { Title } from '@shared/components/atoms/Title/Title';
import { ExternalUserSummary } from '@shared/api/models/User/ExternalUserSummary';
import SitesGetAllQuery from '@settings/api/queries/Sites/SitesGetAllQuery';
import UserSiteSelectWrapper from '../UserList/UserSiteSelectWrapper';

const ExternalUserList = () => {
  const { t } = useTranslation(['settingsUser']);
  const theme = useTheme();
  const [searchString, setSearchString] = useState<string>('');

  const { data: users, execute: refreshUsers, loading: loadingUsers } = useApiState({
    query: new UsersGetAllExternalQuery(),
    initialState: [],
  }, []);

  const { data: sites, loading: loadingSites } = useApiState({
    query: new SitesGetAllQuery(),
    initialState: []
  }, []);

  const searchFilter = useCallback((user: ExternalUserSummary) => {
    return includesCI(user.fullName, searchString)
      || includesCI(user.email, searchString)
      || includesCI(user.userRole, searchString);
  }, [searchString]);

  const tableColumns: ITableColumn<ExternalUserSummary>[] = [
    {
      label: t('List.Name', { ns: 'settingsUser' }),
      key: 'fullName',
      customElement: (user) => <><UserListProfileImage blobName={user.profileImageId} /> {user.fullName}</>,
    },
    {
      label: t('List.Email', { ns: 'settingsUser' }),
      key: 'email',
    },
    {
      label: t('List.Role', { ns: 'settingsUser' }),
      key: 'userRole',
      hideWithBreakpoint: true
    },
    {
      label: t('Sites', { ns: 'common' }),
      key: 'siteSelect',
      disableSort: true,
      customElement: (user) =>
        <UserSiteSelectWrapper
          user={user}
          sites={sites}
          refreshUsers={refreshUsers}
        />
    }
  ];

  return (
    <PaddedContainer>
      <FlexContainer>
        <Title text={<>{t('ExternalUsers', { ns: 'common' })} <ThemeColored>({users.length})</ThemeColored></>} />
        <RightAligned>
          <SearchField
            placeholder={t('List.SearchUsers', { ns: 'settingsUser' })}
            onSearchChange={setSearchString}
            style={{ background: theme.action.filled }}
          />
        </RightAligned>
      </FlexContainer>

      <Table
        columns={tableColumns}
        records={users}
        recordKey='id'
        defaultSortColumn="fullName"
        emptyMessage={t('NoUsersFound', { ns: 'status' })}
        highlightString={searchString}
        filters={[searchFilter]}
        cardEffect
        fullHeightSubtractor={TopBarHeight + 250}
        wrapBreakpoint={510}
        hideBreakpoint={850}
        loading={loadingUsers || loadingSites}
      />
    </PaddedContainer >
  );
}

export default ExternalUserList;

const FlexContainer = styled.div` 
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const RightAligned = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: auto;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;