import { AlerRuleScheduleTimeOfDay } from '@shared/api/models/AlertRule/AlertRule';

const TimeOptions: { label: string, value: AlerRuleScheduleTimeOfDay }[] = [
  { label: '00:00', value: { hour: 0, minute: 0 } },
  { label: '00:30', value: { hour: 0, minute: 30 } },
  { label: '01:00', value: { hour: 1, minute: 0 } },
  { label: '01:30', value: { hour: 1, minute: 30 } },
  { label: '02:00', value: { hour: 2, minute: 0 } },
  { label: '02:30', value: { hour: 2, minute: 30 } },
  { label: '03:00', value: { hour: 3, minute: 0 } },
  { label: '03:30', value: { hour: 3, minute: 30 } },
  { label: '04:00', value: { hour: 4, minute: 0 } },
  { label: '04:30', value: { hour: 4, minute: 30 } },
  { label: '05:00', value: { hour: 5, minute: 0 } },
  { label: '05:30', value: { hour: 5, minute: 30 } },
  { label: '06:00', value: { hour: 6, minute: 0 } },
  { label: '06:30', value: { hour: 6, minute: 30 } },
  { label: '07:00', value: { hour: 7, minute: 0 } },
  { label: '07:30', value: { hour: 7, minute: 30 } },
  { label: '08:00', value: { hour: 8, minute: 0 } },
  { label: '08:30', value: { hour: 8, minute: 30 } },
  { label: '09:00', value: { hour: 9, minute: 0 } },
  { label: '09:30', value: { hour: 9, minute: 30 } },
  { label: '10:00', value: { hour: 10, minute: 0 } },
  { label: '10:30', value: { hour: 10, minute: 30 } },
  { label: '11:00', value: { hour: 11, minute: 0 } },
  { label: '11:30', value: { hour: 11, minute: 30 } },
  { label: '12:00', value: { hour: 12, minute: 0 } },
  { label: '12:30', value: { hour: 12, minute: 30 } },
  { label: '13:00', value: { hour: 13, minute: 0 } },
  { label: '13:30', value: { hour: 13, minute: 30 } },
  { label: '14:00', value: { hour: 14, minute: 0 } },
  { label: '14:30', value: { hour: 14, minute: 30 } },
  { label: '15:00', value: { hour: 15, minute: 0 } },
  { label: '15:30', value: { hour: 15, minute: 30 } },
  { label: '16:00', value: { hour: 16, minute: 0 } },
  { label: '16:30', value: { hour: 16, minute: 30 } },
  { label: '17:00', value: { hour: 17, minute: 0 } },
  { label: '17:30', value: { hour: 17, minute: 30 } },
  { label: '18:00', value: { hour: 18, minute: 0 } },
  { label: '18:30', value: { hour: 18, minute: 30 } },
  { label: '19:00', value: { hour: 19, minute: 0 } },
  { label: '19:30', value: { hour: 19, minute: 30 } },
  { label: '20:00', value: { hour: 20, minute: 0 } },
  { label: '20:30', value: { hour: 20, minute: 30 } },
  { label: '21:00', value: { hour: 21, minute: 0 } },
  { label: '21:30', value: { hour: 21, minute: 30 } },
  { label: '22:00', value: { hour: 22, minute: 0 } },
  { label: '22:30', value: { hour: 22, minute: 30 } },
  { label: '23:00', value: { hour: 23, minute: 0 } },
  { label: '23:30', value: { hour: 23, minute: 30 } },
  { label: '24:00', value: { hour: 24, minute: 0 } }
];

export default TimeOptions;