import { Route as RouteType } from '@shared/types/Route';
import styled from 'styled-components';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { useTranslation } from 'react-i18next';
import { Title } from '@shared/components/atoms/Title/Title';
import { CommonSidebar } from '@src/components/shared/CommonSidebar/CommonSidebar';

type PropTypes = {
  routes?: RouteType[];
}

const Sidebar = ({ routes }: PropTypes) => {
  const { t } = useTranslation();

  return (
    <CommonSidebar>
      <Title
        text={t('UserManagement', { ns: 'common' })}
        style={{ paddingBottom: 20 }}
      />

      <List>
        {routes?.map(route => route.link && (
          <NavItem
            key={route.link.path}
            link={route.link}
          >
            {route.link.label &&
              t(route.link.label, { ns: 'routes' })
            }
          </NavItem>
        ))}
      </List>
    </CommonSidebar>
  );
};

export default Sidebar;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const NavItem = styled(ProtectedLink)`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary} !important;
  border-radius: 8px;
  padding: 9px 12px;

  &:hover {
    color: ${p => p.theme.text.primary} !important;
    background-color: ${p => p.theme.primary.hover};
  }

  &.active {
    font-weight: 500;
    color: ${p => p.theme.text.primary} !important;
    background-color: ${p => p.theme.primary.hover};
  }
`;