import { nullIfEmptyString } from '@shared/utils/StringUtils';
import { DatePicker } from 'antd';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { Button } from '@shared/components/atoms/Button/Button';
import { ErrorMessage, Form, Input, Label } from '@shared/components/atoms/Form/Form';
import RangePicker from '@dashboard/components/atoms/RangePicker/RangePicker';
import { t } from 'i18next';
import styled, { CSSProperties, useTheme } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { EnergyMeterBillDto } from '@shared/api/models/EnergyMeter/EnergyMeterBillDto';
import EnergyMeterBillHelperDataGetQuery from '@settings/api/queries/EnergyMeters/EnergyMeterBillHelperDataGetQuery';
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { EnergyMeterBillSiteDto } from '@shared/api/models/EnergyMeter/EnergyMeterBillSiteDto';
import { useApi } from '@shared/hooks/useApi';
import { FieldErrors, SubmitHandler, useFormContext } from 'react-hook-form';
import { EnergyMeterBillFormValues } from './UtilityConsumptionCreate'
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import EnergyMeterBillCreateCommand from '@settings/api/queries/EnergyMeters/EnergyMeterBillCreateCommand';
import { EnergyMeterBill } from '@shared/api/models/EnergyMeter/EnergyMeterBill';
import EnergyMeterBillUpdateCommand from '@settings/api/queries/EnergyMeters/UtilityBills/EnergyMeterBillUpdateCommand';
import { utilityTabs } from './BillingMeterUtilityTabs';
import dayjs, { Dayjs } from 'dayjs';
import { dateToUtcDate } from '@shared/utils/DateUtils';

type PropTypes = {
  energyMeterBill?: EnergyMeterBillDto,
  selectedUtility: EnergyMeterType,
  setSelected: (value: SetStateAction<number>) => void,
  setSelectedUtility: (value: SetStateAction<EnergyMeterType>) => void,
  setNextSelected: (value: SetStateAction<number>) => void,
  setNextSelectedUtility: (value: SetStateAction<EnergyMeterType>) => void,
  hasMeterReading: boolean
  setHasMeterReading: (value: SetStateAction<boolean>) => void,
  hasConsumption: boolean
  setHasConsumption: (value: SetStateAction<boolean>) => void,
  setHasMeterNumber: (value: SetStateAction<boolean>) => void,
}

const BillingMeterForm = (
  {
    energyMeterBill,
    selectedUtility,
    setSelected,
    setSelectedUtility,
    setNextSelected,
    setNextSelectedUtility,
    hasMeterReading,
    setHasMeterReading,
    hasConsumption,
    setHasConsumption,
    setHasMeterNumber,
  }: PropTypes) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { execute } = useApi();
  const { tenant } = useTenantContext();
  const [helperData, setHelperData] = useState<EnergyMeterBillSiteDto>();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { register, handleSubmit, setValue, setError, clearErrors, watch, formState: { errors } } = useFormContext<EnergyMeterBillFormValues>();
  const formValues = watch();
  const { RangePicker: RangePickerEdit } = DatePicker;

  const meterValidation = useCallback((meterType: EnergyMeterType, currentUtility: EnergyMeterType, meterNumber: string) => {
    if (meterNumber && currentUtility !== meterType) {
      setError('meterNumber', { message: `${t('UtilityConsumption.MeterTypeMismatchMessage', { ns: 'molecules' })}` });
    } else {
      clearErrors('meterNumber')
    }
  }, [clearErrors, setError]);

  const billingMeterValidation = useCallback((helperData: EnergyMeterBillSiteDto, meterNumber: string) => {
    if (helperData.energyMeter.isBillingMeter === false && meterNumber.length > 0) {
      setError('billingMeter', { message: `${t('UtilityConsumption.NonBillingMeterErrorMessage', { ns: 'molecules' })}` });
    } else {
      clearErrors('billingMeter')
    }
  }, [clearErrors, setError])

  const resetFields = () => {
    setSelected(0);
    setSelectedUtility(EnergyMeterType.Electricity);
    setNextSelected(0);
    setNextSelectedUtility(EnergyMeterType.Electricity)
    setHelperData(undefined);
  };

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().endOf('day')
  }

  const setConsumptionPeriodDates = useCallback((from: Date | undefined, to: Date | undefined) => {
    setValue('periodStartDate', from);
    setValue('periodEndDate', to);
  }, [setValue])

  const setHelperDataValues = useCallback((helperData: EnergyMeterBillSiteDto) => {
    setHasMeterNumber(true)
    setHelperData(helperData)
    setValue('location', `${helperData?.site.name}, ${helperData?.site.building?.name}, ${helperData?.site.building?.address.addressLine1}, ${helperData?.site.building?.address.city}, ${helperData?.site.building?.address.postCode}`);
    setValue('meterNumber', helperData.energyMeter.meterNumber);
  }, [setHasMeterNumber, setValue]);

  const fetchHelperData = useCallback(async (meterNumber: string) => {
    if (meterNumber.length > 0) {
      const helperData = await execute({ query: new EnergyMeterBillHelperDataGetQuery(meterNumber) });

      if (helperData) {
        setHelperDataValues(helperData);
        billingMeterValidation(helperData, meterNumber)

        if (selectedUtility) {
          meterValidation(helperData.energyMeter.meterType, selectedUtility, meterNumber);
        }
      } else {
        setHasMeterNumber && setHasMeterNumber(false)
        setValue('location', undefined)
        setError('meterNumber', { message: `${t('UtilityConsumption.MeterNotFoundMessage', { ns: 'molecules' })} ${tenant.name}` })
        clearErrors('billingMeter')
      }
    }
    else {
      clearErrors('meterNumber')
    }
  }, [clearErrors, execute, meterValidation, selectedUtility, setError, setHasMeterNumber, setHelperDataValues, setValue, tenant.name, billingMeterValidation]);

  useEffect(() => {
    if (energyMeterBill) {
      if (selectedUtility === undefined || selectedUtility === energyMeterBill.meterType) {
        setSelectedUtility(energyMeterBill.meterType);
        setSelected(utilityTabs.findIndex(x => x.utilityType === energyMeterBill.meterType))

        if (energyMeterBill.meterReading && energyMeterBill.measuredOn) {
          setValue('meterReading', energyMeterBill.meterReading);

          setHasMeterReading(true);
          setValue('measuredOn', new Date(energyMeterBill.measuredOn))
        } else if (energyMeterBill.consumption && energyMeterBill.periodStartDate && energyMeterBill.periodEndDate) {
          setValue('consumption', energyMeterBill.consumption)

          setHasConsumption(true)
          setConsumptionPeriodDates(energyMeterBill.periodStartDate, energyMeterBill.periodEndDate)
        }
      }

      setValue('meterNumber', energyMeterBill.meterNumber);

      fetchHelperData(energyMeterBill.meterNumber)
    }
  }, [energyMeterBill, fetchHelperData, selectedUtility, setConsumptionPeriodDates, setHasConsumption, setHasMeterReading, setSelected, setSelectedUtility, setValue])

  useEffect(() => {
    if (helperData?.energyMeter.meterType && selectedUtility) {
      meterValidation(helperData?.energyMeter.meterType, selectedUtility, formValues.meterNumber)
    }
    if (helperData) {
      billingMeterValidation(helperData, formValues.meterNumber)
    }
  }, [formValues.meterNumber, helperData?.energyMeter.meterType, meterValidation, selectedUtility, billingMeterValidation, helperData])

  const CreateOnSave: SubmitHandler<EnergyMeterBillFormValues> = async data => {
    if (!selectedUtility || !helperData) {
      return;
    }

    setSavingInProgress(true);

    const newEnergyMeterBill: EnergyMeterBill = {
      ...data,
      siteId: helperData.site.id,
      buildingId: helperData.site.building?.id,
      siteName: helperData.site.name,
      buildingName: helperData.site.building?.name,
      meterId: helperData.energyMeter.id,
      meterType: selectedUtility,
      measuredOn: data.measuredOn ? dateToUtcDate(dayjs(data.measuredOn)) : undefined
    };

    const energyMeterBillDto = await execute({
      query: new EnergyMeterBillCreateCommand(newEnergyMeterBill),
      successMessage: t('UtilityConsumption.BillAddedToast', { ns: 'molecules' }),
      errorMessage: t('UtilityConsumption.BillFailedToast', { ns: 'molecules' })
    });

    if (energyMeterBillDto === undefined) {
      setSavingInProgress(false);
      return;
    }

    resetFields();

    setSavingInProgress(false);

    navigate('./..');
  };

  const EditOnSave: SubmitHandler<EnergyMeterBillFormValues> = async data => {
    if (!selectedUtility || !helperData || !energyMeterBill) {
      return;
    }

    setSavingInProgress(true);

    const energyMeterBillDto = await execute({
      query: new EnergyMeterBillUpdateCommand(
        energyMeterBill.id,
        helperData.site.id,
        helperData.site.building?.id,
        helperData.site.name,
        helperData.site.building?.name,
        data.meterNumber,
        helperData.energyMeter.id,
        selectedUtility,
        data.meterReading,
        data.measuredOn ? dateToUtcDate(dayjs(data.measuredOn)) : undefined,
        data.consumption,
        data.periodStartDate,
        data.periodEndDate,
      ),
      successMessage: t('UtilityConsumption.BillEditedToast', { ns: 'molecules' }),
      errorMessage: t('UtilityConsumption.BillEditFailedToast', { ns: 'molecules' })
    });

    if (energyMeterBillDto === undefined) {
      setSavingInProgress(false);
      return;
    }

    setSavingInProgress(false);

    navigate('./..');
  };

  const customDatePickerStyles: CSSProperties = {
    height: '38px',
    backgroundColor: theme.palette.forms.input.background,
    border: `1px solid ${theme.palette.forms.input.border}`,
    borderRadius: '4px',
    boxShadow: theme.palette.forms.input.boxShadow
  };

  const hasMultipleFields = (obj: FieldErrors<EnergyMeterBillFormValues>) => {
    return Object.keys(obj).length > 1;
  }

  return (
    <Form>
      <div style={{ padding: '32px 32px 32px 24px' }} className="container">
        <div className="row">
          <div className="col-md-6">
            <Label>{t('UtilityConsumption.MeterNumber', { ns: 'molecules' })}</Label>
            <Input
              {...register('meterNumber')}
              onChange={(input) => {
                fetchHelperData(input.target.value)
              }}
            />
            <ErrorMessageWrapper>
              <ErrorMessage style={{ color: `${theme.palette.red}` }}>
                {errors.meterNumber?.message}
              </ErrorMessage>

              {hasMultipleFields(errors) &&
                <br></br>
              }

              {errors.billingMeter?.message &&
                <ErrorMessage
                  style={{ color: `${theme.palette.red}` }}>
                  {errors.billingMeter?.message}
                </ErrorMessage>
              }
            </ErrorMessageWrapper>
          </div>
        </div>
        {formValues.location &&
          <div className="row">
            <div className="col-md-10">
              <Label>{t('UtilityConsumption.Location', { ns: 'molecules' })}</Label>
              <Input
                disabled
                {...register('location')} />
            </div>
          </div>
        }
        <div className="row">
          <div className="col-md-6">
            <Label>{t('UtilityConsumption.MeterReading', { ns: 'molecules' })}</Label>
            <Input
              {...register('meterReading',
                {
                  setValueAs: (value: string) => nullIfEmptyString(value),
                  required: !hasConsumption ? t('UtilityConsumption.Errors.FieldsEmptyError', { ns: 'molecules' }) : false
                })}
              disabled={hasConsumption}
              style={errors.meterReading?.message ? { border: `1px solid ${theme.palette.red}` } : {}}
              onChange={(e) => {
                if (e.target.value === '') {
                  setHasMeterReading(false);
                } else {
                  setHasMeterReading(true);
                  clearErrors('meterReading')
                  clearErrors('consumption')
                }
              }}
              type={'number'}
            />
            <ErrorMessage
              style={{ color: `${theme.palette.red}` }}
            >
              {errors.meterReading?.message}
            </ErrorMessage>
            {!energyMeterBill && helperData?.bill?.meterReading && helperData?.bill.measuredOn && !errors.meterReading && hasMeterReading &&
              <HelperTextLabel>
                {t('UtilityConsumption.MeterReadingHelperText', {
                  ns: 'molecules',
                  date: format(new Date(helperData?.bill.measuredOn), 'dd MMM yyyy'),
                  reading: helperData?.bill.meterReading
                })}
              </HelperTextLabel>
            }
          </div>
          <div className="col-md-6">
            <Label>{t('UtilityConsumption.Consumption', { ns: 'molecules' })}</Label>
            <div style={{ position: 'relative' }}>
              <Input
                {...register('consumption',
                  {
                    setValueAs: (value: string) => nullIfEmptyString(value),
                    required: !hasMeterReading ? t('UtilityConsumption.Errors.FieldsEmptyError', { ns: 'molecules' }) : false
                  })}
                disabled={hasMeterReading}
                style={errors.consumption?.message ? { border: `1px solid ${theme.palette.red}` } : {}}
                onChange={(e) => {
                  if (e.target.value === '') {
                    setHasConsumption(false);
                  } else {
                    setHasConsumption(true);
                    clearErrors('consumption')
                    clearErrors('meterReading')
                  }
                }}
                type={'number'}
              />
              <UtilityLabel>
                {selectedUtility === EnergyMeterType.Water ? 'm³' : 'kWh'}
              </UtilityLabel>
              {!energyMeterBill && helperData?.bill?.consumption && helperData?.bill.periodStartDate && helperData?.bill.periodEndDate && !errors.consumption && hasConsumption &&
                <HelperTextLabel>
                  {t('UtilityConsumption.ConsumptionHelperText', {
                    ns: 'molecules',
                    startDate: format(new Date(helperData?.bill.periodStartDate), 'dd MMM yyyy'),
                    endDate: format(new Date(helperData?.bill.periodEndDate), 'dd MMM yyyy'),
                    reading: helperData?.bill.consumption
                  })}
                </HelperTextLabel>
              }
            </div>
          </div>
        </div>
        {hasMeterReading &&
          <div className="row">
            <div className="col-md-6">
              <Label>{t('UtilityConsumption.MeasuredOnDate', { ns: 'molecules' })}</Label>
              <DatePicker
                suffixIcon={null}
                style={{ ...customDatePickerStyles, width: '100%' }}
                onChange={(selectedDate) => {
                  const date = selectedDate?.toDate()
                  date?.setHours(0, 0, 0, 0)
                  setValue('measuredOn', date);
                }}
                placement='bottomLeft'
                defaultValue={energyMeterBill?.meterType !== selectedUtility ? undefined : dayjs(energyMeterBill?.measuredOn)}
                showTime={false}
                disabledDate={(current: Dayjs) => disabledDate(current)}
              />
            </div>
          </div>
        }
        {hasConsumption && !energyMeterBill &&
          <div className="row">
            <div className="col-md-10">
              <Label>{t('Period', { ns: 'molecules' })}</Label>
              <RangePicker
                onChange={(e) => setConsumptionPeriodDates(e?.from, e?.to)}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        }
        {hasConsumption && energyMeterBill &&
          <div className="row">
            <div className="col-md-10">
              <Label>{t('Period', { ns: 'molecules' })}</Label>
              <RangePickerEdit
                onChange={
                  (range: [start: Dayjs | null, end: Dayjs | null] | null) => {
                    if (range?.[0] && range?.[1]) {
                      setConsumptionPeriodDates(dateToUtcDate(range[0]), dateToUtcDate(range[1]))
                    }
                  }
                }
                allowClear={true}
                style={{ ...customDatePickerStyles, width: '100%' }}
                defaultValue={energyMeterBill?.periodStartDate && energyMeterBill.periodEndDate &&
                  [dayjs(energyMeterBill?.periodStartDate), dayjs(energyMeterBill?.periodEndDate)]
                }
                format="DD MMM YYYY"
                disabledDate={(current: Dayjs) => disabledDate(current)}
              />
            </div>
          </div>
        }
        <div style={{ display: 'flex', gap: '16px', justifyContent: 'end' }}>
          <Link to='./..'>
            <Button
              secondary
              label={t('Cancel', { ns: 'common' })}
            />
          </Link>

          <Button
            label={t('Save', { ns: 'common' })}
            onClick={!energyMeterBill ? handleSubmit(CreateOnSave) : handleSubmit(EditOnSave)}
            disabled={!isEmpty(errors)}
            loading={savingInProgress}
          />
        </div>
      </div>
    </Form >
  )
}

export default BillingMeterForm;

const HelperTextLabel = styled.p`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.palette.forms.label.color};
  margin-bottom: 4px;
  margin-left: 1px;
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const UtilityLabel = styled.label`
   position: absolute;
   top: 8px;
   right: 15px; 
`;