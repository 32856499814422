import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SpaceWithMetricsQuery from '@dashboard/api/queries/metrics/SpaceWithMetricsQuery';
import { SpaceWithDeviceMetrics } from '@shared/api/models/Space/SpaceWithDeviceMetrics';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApi } from '@shared/hooks/useApi';
import SpaceCard from '../../../SpaceCard/SpaceCard';
import { Modal } from '@shared/components/molecules/Modal/Modal';

interface IPropTypes extends React.ComponentPropsWithoutRef<'div'> {
  spaceId: number;
  linkPathToSpace: string;
  isOpen: boolean;
}

const HeatmapSpaceModal = React.forwardRef<HTMLDivElement, IPropTypes>(({ spaceId, linkPathToSpace, isOpen }, ref) => {
  const { t } = useTranslation(['molecules']);
  const { allWidgetMetricTypes } = useDeviceConfigContext();
  const [space, setSpace] = useState<SpaceWithDeviceMetrics>();
  const { execute, loading } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const spaceDto = await execute({
        query: new SpaceWithMetricsQuery(spaceId, allWidgetMetricTypes)
      })

      if (spaceDto) {
        setSpace(spaceDto);
      }
    }

    if (isOpen) {
      fetchData();
    }
  }, [spaceId, isOpen, allWidgetMetricTypes, execute])

  return (
    <Modal
      ref={ref}
      isOpen={isOpen}
      plainModal
      width="520px"
    >
      {loading &&
        <Loading message={t('Heatmap.LoadingSpaceData', { ns: 'molecules' })} style={{ padding: '40px' }} />
      }
      {!loading && space &&
        <SpaceCard
          space={space}
          linkPathToSpace={linkPathToSpace}
          linkAnalytics={{ action: 'enter_space', category: 'heatmap' }}
        />
      }
    </Modal>
  );
});

export default HeatmapSpaceModal;