import React from 'react';
import axios from 'axios';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Manufacturer } from '@shared/api/enums/Manufacturer/Manufacturer';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { DeviceModel } from '@shared/api/enums/DeviceModel/DeviceModel';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { LoadingFullPage } from '@shared/components/molecules/LoadingFullPage/LoadingFullPage';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { IDeviceConfig, IDeviceConfigDictionary } from './IDeviceConfig/IDeviceConfig';
import { DeviceWidgetType, IWidgetConfig } from './IDeviceConfig/IWidgetConfigs';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useApiState } from '@shared/hooks/useApiState';
import { BlobGetSasUriQuery } from '@shared/api/queries/Blob/BlobGetSasUriQuery';
import { useTranslation } from 'react-i18next';
import { env } from '@shared/env/env';

const getWidgetMetricTypes = (config: IWidgetConfig): MetricType[] => {
  switch (config.type) {
    case DeviceWidgetType.List:
      return config.listWidget?.metrics.map(m => m.metricType) ?? [];
    case DeviceWidgetType.Meter:
      return config.meterWidget ? [config.meterWidget?.metricType] : [];
    case DeviceWidgetType.Score:
      return config.scoreWidget ? [config.scoreWidget?.score.metricType] : [];
    case DeviceWidgetType.RadarChart: {
      const metricTypes: MetricType[] = [];
      config.radarChartWidget?.metrics.forEach(metric => {
        metricTypes.push(metric.metricType);

        if (metric.secondTooltipValue?.metricType) {
          metricTypes.push(metric.secondTooltipValue.metricType);
        }
      });
      return metricTypes;
    }
    case DeviceWidgetType.Multisensor: {
      return config.multisensorWidget?.metrics ?? [];
    }
    default:
      return [];
  }
};

const getAllWidgetMetricTypes = (configs: IDeviceConfig[]): MetricType[] => {
  const allMetricTypes = configs.flatMap(config => config.ui.widgets?.flatMap(widget => getWidgetMetricTypes(widget)) ?? []);
  const unique = allMetricTypes.filter((value, index, array) => array.indexOf(value) === index);
  return unique;
};


interface IDeviceConfigProvider {
  children?: ReactNode;
}

interface IDeviceConfigContext {
  getDeviceConfig: (deviceModel: string) => IDeviceConfig | undefined;
  getDeviceManufacturer: (deviceModel: string) => Manufacturer;
  getDeviceType: (deviceModel: string) => DeviceType;
  getDisplayString: (deviceModel: string) => DeviceModel;
  getDeviceConfigsFiltered: (predicate: (x: IDeviceConfig) => boolean) => IDeviceConfig[];
  getDeviceModelsFiltered: (predicate: (x: IDeviceConfig) => boolean) => DeviceModel[];
  allDeviceConfigs: IDeviceConfig[];
  allWidgetMetricTypes: MetricType[];
  getWidgetMetricTypes: (config: IWidgetConfig) => MetricType[];
}

export const DeviceConfigContext = React.createContext({} as IDeviceConfigContext);
export const useDeviceConfigContext = () => useContext(DeviceConfigContext);

export const DeviceConfigProvider = ({ children }: IDeviceConfigProvider) => {
  const { t } = useTranslation();
  const [loadingConfigFile, setLoadingConfigFile] = useState(false);
  const [deviceConfigs, setDeviceConfigs] = useState<IDeviceConfigDictionary>();
  const { data: blobUri, loading: loadingBlobUri } = useApiState({
    query: new BlobGetSasUriQuery(env.deviceConfigs.filename, BlobStorageContainerType.Custom, env.deviceConfigs.blobContainer),
    errorMessage: t('DeviceConfigsLoadError', { ns: 'commonApp' })
  }, []);

  useEffect(() => {
    const execute = async () => {
      setLoadingConfigFile(true);

      if (!blobUri) {
        return;
      }

      try {
        const res = await axios.get(blobUri.blobUri);
        setDeviceConfigs(res.data);
      } catch (err) {
        console.log('Failed to load device configs', err);
      } finally {
        setLoadingConfigFile(false);
      }
    }

    execute();
  }, [blobUri]);

  if (loadingBlobUri || loadingConfigFile) {
    return <LoadingFullPage message={t('Launching', { ns: 'commonApp' })} icon={faRocketLaunch} iconPosTop='8px' />;
  }

  if (!deviceConfigs) {
    return null;
  }

  const getDeviceConfig = (deviceModel: DeviceModel): IDeviceConfig | undefined => {
    return deviceConfigs[deviceModel];
  };

  const getDeviceManufacturer = (deviceModel: DeviceModel): Manufacturer => {
    return deviceConfigs[deviceModel]?.manufacturer ?? t('Unknown', { ns: 'commonApp' });
  };

  const getDeviceType = (deviceModel: DeviceModel): DeviceType => {
    return deviceConfigs[deviceModel]?.deviceType ?? DeviceType.Unknown;
  };

  const getDisplayString = (deviceModel: DeviceModel): string => {
    return deviceConfigs[deviceModel]?.displayString ?? t('Unknown', { ns: 'commonApp' });
  };

  const getDeviceConfigsFiltered = (predicate: (x: IDeviceConfig) => boolean): IDeviceConfig[] => {
    return Object.values(deviceConfigs).filter(x => predicate(x));
  };

  const getDeviceModelsFiltered = (predicate: (x: IDeviceConfig) => boolean): DeviceModel[] => {
    return Object.values(deviceConfigs).filter(x => predicate(x)).map(x => x.deviceModel);
  };

  const allDeviceConfigs = Object.values(deviceConfigs);

  const allWidgetMetricTypes = getAllWidgetMetricTypes(allDeviceConfigs);

  return (
    <DeviceConfigContext.Provider
      value={{
        getDeviceConfig,
        getDeviceManufacturer,
        getDeviceType,
        getDisplayString,
        getDeviceConfigsFiltered,
        getDeviceModelsFiltered,
        allDeviceConfigs,
        allWidgetMetricTypes,
        getWidgetMetricTypes
      }}
    >
      {children}
    </DeviceConfigContext.Provider>
  );
};