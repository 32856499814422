import { BuildingHierarchiesGetBySiteId } from '@dashboard/api/queries/building/BuildingHierarchiesGetBySiteId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResidentialSpacesWithMeterTypesGetBySiteIdQuery from '@settings/api/queries/Spaces/ResidentialSpacesWithMeterTypesGetBySiteIdQuery';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { FairUseForm } from '@shared/api/models/FairUse/FairUseForm';
import { SpaceFairUsePolicy } from '@shared/api/models/FairUse/SpaceFairUsePolicy';
import { HierarchyBuilding, HierarchyFloor, HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';
import { Space } from '@shared/api/models/Space/Space';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ICascaderMultiSelectNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import { CascaderMultiSelect } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect';
import { Cluster } from '@shared/api/models/Space/Cluster';

type AddExceptionProps = {
  siteId: number;
  policyId: number;
  exceptionSpaceIds: number[];
  exceptionClusterIds: (number | undefined)[];
  energyMeterType: EnergyMeterType;
  spaceTypes: SpaceType[];
  onChange: (spaceIds: HierarchySpace[]) => void;
}

const AddException = ({ siteId, policyId, exceptionSpaceIds, exceptionClusterIds, energyMeterType, spaceTypes, onChange }: AddExceptionProps) => {
  const { t } = useTranslation();
  const { loading } = useApi();
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<HierarchySpace> | undefined>({ nodes: [] });
  const [spaceIds] = useState<number[]>([]);
  const { getValues, setValue } = useFormContext<FairUseForm>();

  const { data: buildings } = useApiState({
    query: new BuildingHierarchiesGetBySiteId(siteId),
    initialState: [],
  }, [siteId]);

  const { data: residentialSpacesWithMeters } = useApiState({
    query: new ResidentialSpacesWithMeterTypesGetBySiteIdQuery(siteId, energyMeterType),
  }, [siteId, energyMeterType]);

  const getSpaces = useCallback((floor: HierarchyFloor, spaces?: Space[]): HierarchySpace[] => {
    if (spaces) {
      const spaceIds = spaces.map((space: Space) => space.id);
      return floor.spaces.filter((space: HierarchySpace) => spaceIds?.includes(space.id) && !exceptionSpaceIds.includes(space.id));
    }
    return [];
  }, [exceptionSpaceIds])

  const getClusters = useCallback((floor: HierarchyFloor, clusters?: Cluster[]): HierarchySpace[] => {
    if (clusters) {
      return clusters
        .filter((cluster: Cluster) => cluster.floorId === floor.id && !exceptionClusterIds.includes(cluster.id))
        .map((cluster: Cluster) => {
          const clusterSpaces = getSpaces(floor, cluster.spaces);
          return {
            id: cluster.id,
            name: cluster.clusterName,
            spaces: clusterSpaces,
            clusterId: cluster.id,
            devices: [],
          }
        });
    }
    return [];
  }, [exceptionClusterIds, getSpaces])

  useEffect(() => {
    const relevantBuildings = buildings.reduce((filteredBuildings, building) => {
      const floors = building.floors.reduce((filteredFloors, floor) => {
        const cluster = getClusters(floor, residentialSpacesWithMeters?.clusters);
        const spaces = getSpaces(floor, residentialSpacesWithMeters?.spaces)

        if (spaces.length > 0) {
          spaces.forEach(space => spaceIds.push(space.id))
          filteredFloors.push({ ...floor, spaces: spaces });
        }

        if (cluster.length > 0) {
          filteredFloors.push({ ...floor, spaces: [...spaces, ...cluster] });
        }

        return filteredFloors;
      }, [] as HierarchyFloor[]);

      if (floors.length > 0) {
        filteredBuildings.push({ ...building, floors: floors })
      }

      return filteredBuildings;
    }, [] as HierarchyBuilding[]);

    const options: ICascaderMultiSelectNodeGroup<HierarchySpace> | undefined = {
      header: t('Buildings', { ns: 'assets' }),
      nodes: orderBy(relevantBuildings, x => x.name.toLocaleLowerCase()).map((building) => ({
        label: building.name,
        childGroup: {
          header: t('Floors', { ns: 'assets' }),
          nodes: orderBy(building.floors, x => x.floorNumber).map((floor) => ({
            label: floor.name,
            childGroup: {
              header: t('Spaces', { ns: 'assets' }),
              nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map((space) => ({
                label: space.clusterId ? `${space.name} - Cluster` : space.name,
                value: space,
                selectable: true
              }))
            }
          })),
        },
      })),
    };

    setCascaderOptions(options);
  }, [buildings, spaceTypes, residentialSpacesWithMeters, t, spaceIds, getClusters, getSpaces])

  const addException = async (spaceIds: number[], spaceName: string, spaceType?: SpaceType, clusterId?: number,) => {
    const building = buildings.find((building: HierarchyBuilding) =>
      building.floors.find((floor: HierarchyFloor) =>
        floor.spaces.find((space: HierarchySpace) => spaceIds.includes(space.id))));

    const newSpaceFairUsePolicy: SpaceFairUsePolicy = {
      allowance: 0,
      policyId: policyId,
      meterType: energyMeterType,
      exempt: false,
      id: 0,
      spaceIds: spaceIds,
      name: spaceName,
      spaceType: spaceType,
      clusterId: clusterId,
      buildingName: building?.name ?? ''
    }

    if (newSpaceFairUsePolicy) {
      const exceptions = getValues('exceptions');

      if (exceptions) {
        setValue('exceptions', [newSpaceFairUsePolicy, ...exceptions]);
      } else {
        setValue('exceptions', [newSpaceFairUsePolicy]);
      }
    }
  };

  const handleChange = (spaces?: HierarchySpace[]) => {
    onChange(spaces ?? []);
    spaces?.forEach((space: HierarchySpace) => {
      const spacesIds = space.spaces?.map((space: HierarchySpace) => space.id) ?? [space.id];
      addException(spacesIds, space.name, space.spaceType, space.clusterId);
    })
  }

  return (
    <>
      <FlexRow>
        {!loading && cascaderOptions && cascaderOptions.nodes.length > 0 &&
          <>
            <Label>
              {t('FairUsage.Exceptions.AddSpaces', { ns: 'molecules' })}
            </Label>

            <CascaderMultiSelect
              showAddButton={true}
              group={cascaderOptions}
              onChange={handleChange}
              placeholder={t('DynamicReport.SelectSpaces', { ns: 'molecules' })}
              confirmButton={t('Apply', { ns: 'common' })}
              cancelButton={t('Cancel', { ns: 'common' })}
              includeChildValues
              hideChildGroupLabel
              resetPulse={true}
            />
          </>
        }
      </FlexRow>
    </>
  );
};

export default AddException;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
  padding: 10px 0;
`;

export const AddExceptionButton = styled(FontAwesomeIcon)`
  width: 12px;
  height: 12px;
  color: ${p => p.theme.palette.text.fair};
  padding: 8px;
  background: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 2px 4px 0 ${p => p.theme.palette.shadows.strong};
  border-radius: 6px;
  cursor: pointer;
`;