import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const TopologyCard = styled.div`
  position: relative;
  height: max-content;
  border-radius: 9px 9px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 1px 8px 1px ${p => p.theme.palette.shadows.medium};
  box-shadow: ${p => p.theme.palette.shadows.medium} 0px 1px 3px 1px, ${p => p.theme.palette.shadows.medium} 0px 1px 8px 1px;
  cursor: default;
`;

export const TopologyCardRelativeWrapper = styled.div`
  position: relative;
`;

export const TopologyCardHeader = styled.div<{ color: string }>`
  width: 100%;
  height: 25px;
  border-radius: 9px 9px 0 0;
  position: relative;
  z-index: 1;
  cursor: pointer;

  font-size: 12px;
  line-height: 12px;
  font-weight: 500;

  background-color: ${p => p.color};
  color: ${p => p.theme.palette.text.onPrimary};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

export const TopologyCardHeaderIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #ffffffd8;

  ${TopologyCardHeader}:hover & {
    color: #fff;
  }
`;

export const TopologyCardHeaderSlidingText = styled.div`
  width: 0px;
  overflow: hidden;
  transition: width 300ms ease;
  text-align: right;
  font-weight: 400;

  ${TopologyCardHeader}:hover & {
    width: 50px;
    transition: width 1000ms ease;
  }
`;

export const TopologyCardContent = styled.div`
  align-self: center;
  width: 160px;
  height: 70px;
  padding: 6px 8px;

  display: flex;
  flex-direction: column;
  gap: 2px;

  div {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TopologyCardMenu = styled.div`
  height: 0px;

  ${TopologyCardRelativeWrapper}:hover & {
    height: 51px;
  }

  transition: all 300ms ease;

  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  cursor: default;

  overflow: hidden;

  border-radius: 3px 3px 7px 7px;
  background-color: ${p => p.theme.palette.backgrounds.surface};
  box-shadow: 0 -5px 8px -2px ${p => p.theme.palette.shadows.medium};
`;

export const TopologyCardMenuItem = styled.div<{ hoverColor?: string }>`
  font-size: 12px;
  font-weight: 500;
  padding: 3px 6px;
  color: ${p => p.theme.palette.text.fair};
  border-bottom: 1px solid ${p => p.theme.palette.borders.weak};
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    color: ${p => p.theme.palette.text.onPrimary};
    background-color: ${p => p.hoverColor ?? p.theme.palette.primary};

    svg {
      color: ${p => p.theme.palette.text.onPrimary};
    }
  }
`;