import { HierarchyBuilding } from '@shared/api/models/Hierarchy/Hierarchy';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class BuildingHierarchiesGetBySiteId extends GetQuery<HierarchyBuilding[]> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return `/reports/BuildingHierarchiesGetBySiteId/${this.id}`;
  }
}
