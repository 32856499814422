import { LettingStatus } from '@shared/api/enums/LettingStatus/LettingStatus';
import { SpaceConsentDto } from '@shared/api/models/Space/SpaceConsentDto';
import { PostQuery } from '../common/PostQuery';

export default class SpaceConsentCreateOrUpdateCommand extends PostQuery<SpaceConsentDto> {
  tenantName: string;
  mpxn: string;
  spaceId: number;
  lettingStatus: LettingStatus;
  consentGranted: boolean;
  changedBy: string;
  startDate: string | null;
  endDate: string | null;

  constructor(tenantName: string, mpxn: string, spaceId: number, lettingStatus: LettingStatus, consentGranted: boolean, changedBy: string, startDate: string | null, endDate: string | null) {
    super();
    this.tenantName = tenantName;
    this.mpxn = mpxn;
    this.spaceId = spaceId;
    this.lettingStatus = lettingStatus;
    this.consentGranted = consentGranted;
    this.changedBy = changedBy;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public targetUrl(): string {
    return '/spaces/CreateOrUpdateConsent';
  }
}
