import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { DeviceNoteDeleteCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteDeleteCommand';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserRole } from '@shared/api/enums/UserRole/UserRole';
import { Popover } from '@shared/components/atoms/Popover/Popover';
import { PopoverPlacement } from '@shared/components/atoms/Popover/Popover.types';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { useApi } from '@shared/hooks/useApi';
import { useModal } from '@shared/hooks/useModal';
import { usePopover } from '@shared/hooks/usePopover';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import DeviceNoteAttachmentModal from './DeviceNoteAttachmentModal';

type PropTypes = {
  note: DeviceNote;
  refreshNotes: () => void;
  setEditMode: (state: boolean) => void;
}

const NoteActions = ({ note, refreshNotes, setEditMode }: PropTypes) => {
  const { t } = useTranslation();
  const { hasRole } = useUserContext();
  const { execute } = useApi();
  const { visible, toggle, ref } = usePopover({});
  const theme = useTheme();
  const { isOpen: attachmentDialogIsOpen, toggle: toggleAttatchmentDialog, ref: attachmentDialogRef } = useModal({});
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});

  const handleEdit = async () => {
    setEditMode(true);
    toggle();
  };

  const handleDelete = async () => {
    await execute({ query: new DeviceNoteDeleteCommand(note.id) });
    refreshNotes();
  };

  const PopoverContent = (
    <ActionMenu>
      <ActionLabel onClick={handleEdit}>
        {t('Edit', { ns: 'common' })}
      </ActionLabel>

      <ActionLabel onClick={toggleDeleteDialog}>
        {t('Delete', { ns: 'common' })}
      </ActionLabel>
    </ActionMenu>
  );

  return (
    <>
      <FlexRow>
        {note.attachments.length > 0 &&
          <Count>
            ({note.attachments.length})
          </Count>
        }
        <MenuIcon
          icon={solid('paperclip')}
          onClick={() => toggleAttatchmentDialog()}
        />

        {(note.createdByCurrentUser || hasRole(UserRole.SuperAdministrator)) &&
          <Popover
            ref={ref}
            visible={visible}
            popoverContent={PopoverContent}
            placement={PopoverPlacement.BottomRight}
            hideTriangle={true}
            offsetY={-8}
            offsetX={-58}
            containerStyles={{
              boxShadow: `0px 1px 3px 1px ${theme.palette.shadows.extraLight}`,
            }}
          >
            <MenuIcon
              open={visible}
              icon={solid('ellipsis-vertical')}
              onClick={() => toggle()}
            />
          </Popover >
        }
      </FlexRow>

      <DeviceNoteAttachmentModal
        modalRef={attachmentDialogRef}
        open={attachmentDialogIsOpen}
        hide={toggleAttatchmentDialog}
        note={note}
        refreshNotes={refreshNotes}
      />

      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('DeviceCard.DeleteNoteConfirm', { ns: 'molecules' })}
        confirmButton={t('DeviceCard.Delete', { ns: 'molecules' })}
        onCancel={toggleDeleteDialog}
        onConfirm={handleDelete}
      />
    </>
  )
}

export default NoteActions;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const ActionMenu = styled.div`
  width: 150px;
`;

const ActionLabel = styled.div`
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.hover};
  }
`;

const MenuIcon = styled(FontAwesomeIcon) <{ open?: boolean }>`
  color: ${p => p.open ? p.theme.primary.main : p.theme.action.active};
  cursor: pointer;
  padding: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &:hover{
    color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;

const Count = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.action.active};
  margin-right: -4px;
  padding-top: 2px;
`;