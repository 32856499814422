export enum Permission {
  SiteRead = 'SiteRead',
  SiteWrite = 'SiteWrite',

  BuildingRead = 'BuildingRead',
  BuildingWrite = 'BuildingWrite',

  FloorRead = 'FloorRead',
  FloorWrite = 'FloorWrite',

  SpaceRead = 'SpaceRead',
  SpaceWrite = 'SpaceWrite',

  UserRead = 'UserRead',
  UserWrite = 'UserWrite',

  RoleRead = 'RoleRead',

  ViewHeatmap = 'ViewHeatmap',
  ViewSettings = 'ViewSettings',
  ViewResidentApp = 'ViewResidentApp',

  SuperAdministrator = 'SuperAdministrator'
}
