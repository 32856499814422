import { SalesOrderItem } from '@shared/api/models/SalesOrder/SalesOrderItem';
import { useApi } from '@shared/hooks/useApi';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { useModal } from '@shared/hooks/useModal';
import SiteSalesOrderBulkDeleteSalesOrderItemsCommand from '@settings/api/queries/SalesOrder/SiteSalesOrderBulkDeleteSalesOrderItemsCommand';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { usePopover } from '@shared/hooks/usePopover';
import { Popover } from '@shared/components/atoms/Popover/Popover';
import { PopoverPlacement } from '@shared/components/atoms/Popover/Popover.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ISalesOrderItemActions {
  salesOrderItem: SalesOrderItem | undefined;
  refreshItems: () => void;
}

const SalesOrderItemActions = ({ salesOrderItem, refreshItems }: ISalesOrderItemActions) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const { isOpen: deleteDialogIsOpen, toggle: toggleDeleteDialog, ref: deleteDialogRef } = useModal({});
  const { visible, toggle, setVisible, ref } = usePopover({});

  if (salesOrderItem === undefined) {
    return <></>
  }

  const deleteItem = async (item: SalesOrderItem | undefined) => {
    if (!item?.id) {
      return;
    }

    toggle();

    await execute({
      query: new SiteSalesOrderBulkDeleteSalesOrderItemsCommand(item.siteSalesOrderId, [item.id]),
      successMessage: `${t('SalesOrder.Messages.DeleteSalesOrderItemsSuccess', { ns: 'molecules' })}`,
      errorMessage: `${t('SalesOrder.Messages.DeleteSalesOrderItemsFailure', { ns: 'molecules' })}`
    });

    refreshItems();

    visible && setVisible(false);
    deleteDialogIsOpen && toggleDeleteDialog();
  };

  const popoverContent = (
    <PopoverContent onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
      <Action onClick={() => navigate(`../sales-order/${salesOrderItem.id}`)}>
        <ActionIcon icon={regular('edit')} color={theme.palette.primary} />
        {t('AlertingRuleActions.Edit', { ns: 'settingsAlerting' })}
      </Action>
      <Action onClick={toggleDeleteDialog}>
        <ActionIcon icon={solid('trash-xmark')} color={theme.palette.red} />
        {t('AlertingRuleActions.Delete', { ns: 'settingsAlerting' })}
      </Action>
    </PopoverContent>
  );

  return (
    <>
      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('SalesOrder.UpdatedDeleteDialog.ParagraphOne', { ns: 'molecules', deviceType: salesOrderItem.deviceType })}
        sectionTwo={t('SalesOrder.UpdatedDeleteDialog.ParagraphTwo', { ns: 'molecules', deviceType: salesOrderItem.deviceType })}
        sectionThree={t('SalesOrder.UpdatedDeleteDialog.ParagraphThree', { ns: 'molecules' })}
        confirmButton={t('SalesOrder.UpdatedDeleteDialog.Delete', { ns: 'molecules' })}
        onCancel={toggleDeleteDialog}
        onConfirm={() => deleteItem(salesOrderItem)}
      />

      {salesOrderItem &&
        <Popover
          ref={ref}
          visible={visible}
          popoverContent={popoverContent}
          placement={PopoverPlacement.Left}
          offsetY={0}
          offsetX={3}
        >
          <MenuIcon icon={solid('ellipsis')} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggle();
          }} />
        </Popover>
      }
    </>
  );
}

export default SalesOrderItemActions;

const PopoverContent = styled.div`
  width: 120px;
  padding: 5px 0;
  cursor: pointer;
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 5px 12px;

  &:hover {
    background-color: ${p => p.theme.palette.backgrounds.surfaceStrong};
  }
`;

const ActionIcon = styled(FontAwesomeIcon) <{ color: string }>`
  font-size: 14px;
  color: ${p => p.color};
  margin-right: 8px;
  width: 15px;
`;

const MenuIcon = styled(FontAwesomeIcon)`
  margin-top: 5px;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;
  padding: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &:hover {
    background-color: ${p => p.theme.palette.borders.medium};
  }
`;