import { Card } from '@shared/components/molecules/Card/Card';
import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

type CardWithSideBorderProps = {
  children: ReactNode;
}

const CardWithSideBorder = ({children}: CardWithSideBorderProps) => {
  const theme = useTheme();
  
  return (
    <Card style={{backgroundColor: theme.action.outlineBorder, borderLeft: `4px solid ${theme.primary.main}`}}>
      {children}
    </Card>
  )
}

export default CardWithSideBorder;