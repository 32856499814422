import SitePage_ESG from '@dashboard/components/organisms/SitePage/SitePage_ESG';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Route } from '@shared/types/Route';

export const EsgReportRoutes: Route[] = [
  {
    path: 'esg',
    element: SitePage_ESG,
    analytics: { page: 'esg_report', category: 'esg_report' },
    link: {
      label: 'ESG',
      path: 'esg',
      icon: regular('chart-bar'),
      analytics: { action: 'esg_report', category: 'feature_navigation' }
    }
  },
];