import { addHours } from 'date-fns';
import { useSiteContext } from './SiteProvider';
import { useTranslation } from 'react-i18next';
import { getWeatherObject, WeatherIconColors } from '@shared/utils/WeatherUtils';
import styled, { useTheme } from 'styled-components';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WeatherWidget = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { toLocale, getUnit } = useLocalisation();
  const { weather } = useSiteContext();

  if (!weather?.temperature?.timestamp || new Date(weather.temperature.timestamp) < addHours(new Date(), -24)) {
    return null;
  }

  const weatherObject = getWeatherObject(t, theme, weather.weatherCode);

  return (
    <Wrapper>
      {weatherObject &&
        <Description>
          {weatherObject.description},&nbsp;
        </Description>
      }

      {toLocale(MetricType.Temperature, weather.temperature.value, { round: 1 })}
      {getUnit(MetricType.Temperature)}

      {weatherObject &&
        <Icon
          icon={weatherObject.icon}
          colors={weatherObject.colors}
        />
      }
    </Wrapper>
  );
};

export default WeatherWidget;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.text.primary};
`;

const Description = styled.span`
  display: none;

  @media (min-width: 500px) {
    display: inline-block;
  }
`;

const Icon = styled(FontAwesomeIcon) <{ colors: WeatherIconColors }>`
  width: 16px;
  height: 16px;
  margin-left: 8px;

  .fa-primary {
    color: ${p => p.colors.primary};
  }
  .fa-secondary {
    color: ${p => p.colors.secondary};
  }
`;