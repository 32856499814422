import { ClimateControlStatus } from '@shared/api/enums/ClimateControlStatus/ClimateControlStatus';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClimateControlV2 from '../ClimateControlV2/ClimateControlV2';
import BuildingList from './Buildings/BuildingList';
import ClimateControlPreview from './ClimateControlPreview';
import ClimateControlSettings from './ClimateControlSettings/ClimateControlSettings';

const ClimateControl = () => {
  const { t } = useTranslation();
  const { hasFeatureFlag } = useTenantContext();
  const { siteClimateControl } = useSiteContext();
  const climateControlV2Enabled = hasFeatureFlag(FeatureFlag.ClimateControlV2);

  if (!siteClimateControl || siteClimateControl.status === ClimateControlStatus.Disabled) {
    return <ClimateControlPreview />;
  }

  if (climateControlV2Enabled) {
    return <ClimateControlV2 />
  }

  return (
    <PaddedContainer>
      <Title
        size='lg'
        text={t('ClimateControl.TemperatureControl', { ns: 'molecules' })}
        style={{ marginBottom: '20px' }}
      />

      <FlexRow>
        <ClimateControlSettings />
        <BuildingList />
      </FlexRow>
    </PaddedContainer>
  );
};

export default ClimateControl;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;