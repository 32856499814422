import { EnergyMeterType } from './EnergyMeterType';
import { faBoltLightning, faFan, faFire, faFireFlameCurved, faSnowflake, faTint, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

/**
  * Get icon
  */
export const EnergyMeterType_Icon = (energyMeterType: EnergyMeterType): IconDefinition | null => {
  return energyMeterTypeToIcon[energyMeterType] ?? null;
}

/**
 * Map EnergyMeterType to an icon
 */
const energyMeterTypeToIcon: { [key in EnergyMeterType]: IconDefinition } = Object.freeze({
  [EnergyMeterType.Heating]: faFireFlameCurved,
  [EnergyMeterType.Electricity]: faBoltLightning,
  [EnergyMeterType.Gas]: faFire,
  [EnergyMeterType.Water]: faTint,
  [EnergyMeterType.Cooling]: faSnowflake,
  [EnergyMeterType.HeatingCooling]: faFan
});
