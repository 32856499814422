import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ITooltip, TooltipPlacement } from './Tooltip.types';

export const Tooltip: React.FC<ITooltip> = ({
  content,
  placement = TooltipPlacement.Top,
  distance = 7,
  fixedPosition,
  fontSize,
  fontColor,
  tooltipColor,
  alignWithTarget,
  forceVisible,
  hideTriangle,
  secondary,
  styles,
  children
}) => {
  const targetDivRef = useRef<HTMLDivElement>(null);
  const [xOffset, setXOffset] = useState<number>(0);
  const [yOffset, setYOffset] = useState<number>(0);

  useEffect(() => {
    const target = targetDivRef.current;

    if (!target) {
      return;
    }

    setXOffset(target.offsetWidth);
    setYOffset(target.offsetHeight);
  }, []);

  return (
    <Wrapper>
      <Trigger ref={targetDivRef}>{children}</Trigger>

      {content && content !== ' ' && (
        <PositioningContainer
          placement={placement}
          xOffset={fixedPosition?.x ?? xOffset}
          yOffset={fixedPosition?.y ?? yOffset}
          distance={distance}
          alignWithTarget={alignWithTarget}
          tooltipColor={tooltipColor}
          forceVisible={forceVisible}
          secondary={secondary}
        >
          <TriangleWrapper placement={placement} hide={hideTriangle}>
            <Triangle placement={placement} color={tooltipColor} secondary={secondary} />
          </TriangleWrapper>
          <Content fontColor={fontColor} fontSize={fontSize} style={styles}>
            {content}
          </Content>
        </PositioningContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Content = styled.div<{ fontColor?: string; fontSize?: string }>`
  padding: 2px 7px;
  width: max-content;
  font-size: ${(p) => (p.fontSize ? p.fontSize : 'inherit')};
  color: ${(p) => p.fontColor ?? p.theme.palette.tooltip.textColor};
  position: relative;
  z-index: 11;
`;

const Trigger = styled.div``;

const TriangleWrapper = styled.div<{
  placement?: TooltipPlacement;
  hide?: boolean;
}>`
  display: ${(p) => (p.hide ? 'none' : 'block')};
  width: 100%;
  height: 100%;
  pointer-events: none;

  position: absolute;
  top: 0px;
  left: 0px;

  transform: ${(p) =>
    (p.placement === TooltipPlacement.Bottom &&
      'translate(calc(-50% + 8px), -5px)') ||
    (p.placement === TooltipPlacement.BottomRight && 'translate(-15%, -5px)') ||
    (p.placement === TooltipPlacement.BottomLeft &&
      'translate(calc(-85% + 16px), -5px)') ||
    (p.placement === TooltipPlacement.Top &&
      'translate(calc(-50% + 8px), calc(100% + -11px))') ||
    (p.placement === TooltipPlacement.TopRight &&
      'translate(-15%, calc(100% + -11px))') ||
    (p.placement === TooltipPlacement.TopLeft &&
      'translate(calc(-85% + 16px), calc(100% + -11px))') ||
    (p.placement === TooltipPlacement.Right &&
      'translate(calc(-100% + 11px), calc(50% - 8px))') ||
    (p.placement === TooltipPlacement.Left &&
      'translate(5px, calc(50% - 8px))')};
`;

/*
 * This element is the visible triangle
 */
const Triangle = styled.div<{ placement: TooltipPlacement; color?: string, secondary?: boolean }>`
  width: 16px;
  height: 16px;
  background: ${(p) => p.color ?? (p.secondary ? p.theme.palette.tooltip.background.secondary : p.theme.palette.tooltip.background.primary)};
  transform: rotate(45deg);
  border-radius: 4px;
  position: absolute;
  top: 0px;
  right: 0px;
  pointer-events: none;
`;

const PositioningContainer = styled.div<{
  placement: TooltipPlacement;
  forceVisible?: boolean;
  xOffset: number;
  yOffset: number;
  distance: number;
  tooltipColor?: string;
  alignWithTarget?: boolean;
  secondary?: boolean;
}>`
  opacity: ${(p) => (p.forceVisible ? 1 : 0)};
  visibility: ${(p) => (p.forceVisible ? 'visible' : 'hidden')};
  z-index: 10;

  ${Trigger}:hover ~ & {
    // Syntax to target sibling element
    opacity: 1;
    visibility: visible;
  }

  transition: all 300ms ease;
  border-radius: 4px;
  box-shadow: 0 0 10px 1px ${(p) => p.theme.palette.tooltip.shadow};
  background-color: ${(p) => p.tooltipColor ?? (p.secondary ? p.theme.palette.tooltip.background.secondary : p.theme.palette.tooltip.background.primary)};

  position: absolute;
  top: 0px;
  left: 0px;

  transform: ${({ placement, xOffset, yOffset, distance }) =>
    (placement === TooltipPlacement.Bottom && `translate(calc(-50% + ${xOffset / 2}px), ${yOffset + distance + 5}px)`) ||
    (placement === TooltipPlacement.BottomRight && `translate(calc(-85% + ${xOffset / 2 + 8}px), ${yOffset + distance + 5}px)`) ||
    (placement === TooltipPlacement.BottomLeft && `translate(calc(-15% + ${xOffset / 2 - 8}px), ${yOffset + distance + 5}px)`) ||
    (placement === TooltipPlacement.Top && `translate(calc(-50% + ${xOffset / 2}px), calc(-100% - ${distance + 5}px))`) ||
    (placement === TooltipPlacement.TopRight && `translate(calc(-85% + ${xOffset / 2 + 8}px), calc(-100% - ${distance + 5}px))`) ||
    (placement === TooltipPlacement.TopLeft && `translate(calc(-15% + ${xOffset / 2 - 8}px), calc(-100% - ${distance + 5}px))`) ||
    (placement === TooltipPlacement.Right && `translate(${xOffset + distance + 5}px, calc(-50% + ${yOffset / 2}px))`) ||
    (placement === TooltipPlacement.Left && `translate(calc(-100% - ${distance + 5}px), calc(-50% + ${yOffset / 2}px))`)};

  ${(p) => p.alignWithTarget && css`
        transform: ${(p.placement === TooltipPlacement.Bottom && `translate(calc(-50% + ${p.xOffset / 2}px), ${p.yOffset + p.distance + 5}px)`) ||
    (p.placement === TooltipPlacement.BottomRight && `translate(calc(-100% + ${p.xOffset}px), ${p.yOffset + p.distance + 5}px)`) ||
    (p.placement === TooltipPlacement.BottomLeft && `translate(0, ${p.yOffset + p.distance + 5}px)`) ||
    (p.placement === TooltipPlacement.Top && `translate(calc(-50% + ${p.xOffset / 2}px), calc(-100% - ${p.distance + 5}px))`) ||
    (p.placement === TooltipPlacement.TopLeft && `translate(0, calc(-100% - ${p.distance + 5}px))`) ||
    (p.placement === TooltipPlacement.TopRight && `translate(calc(-100% + ${p.xOffset}px), calc(-100% - ${p.distance + 5}px))`)};
    `}

  @media print {
    display: none;
  }
`;
