import { Floor } from '@shared/api/models/Floor/Floor';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class FloorsGetallQuery extends GetQuery<Floor[]> {
  buildingId: number;

  constructor(buildingId: number) {
    super();
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return `/floors/${this.buildingId}`;
  }
}
