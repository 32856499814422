import { Suspense, useCallback, useEffect, useState } from 'react';
import { Xwrapper } from 'react-xarrows';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { components, OptionProps, SingleValueProps } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollDrag from './ScrollDrag';
import MainMeter from './treeViewElements/MainMeter';
import { EnergyMeterTopologyLevel } from '@shared/api/enums/EnergyMeterTopologyLevel/EnergyMeterTopologyLevel';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { EnergyMeterType_Color } from '@shared/api/enums/EnergyMeterType/EnergyMeterType_Color';
import { EnergyMeterType_Icon } from '@shared/api/enums/EnergyMeterType/EnergyMeterType_Icon';
import { Building } from '@shared/api/models/Building/Building';
import { EnergyMeterTopologyDto } from '@shared/api/models/EnergyMeter/EnergyMeterTopologyDto';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { Button } from '@shared/components/atoms/Button/Button';
import { Select } from '@shared/components/atoms/Select/Select';
import EnergyMetersGetByBuildingIdQuery from '@settings/api/queries/EnergyMeters/EnergyMetersGetByBuildingIdQuery';
import EnergyMetersBulkUpdateCommand from '@settings/api/queries/EnergyMeters/EnergyMetersBulkUpdateCommand';

interface EnergyMeterTypeOption {
  label: EnergyMeterType;
  value: EnergyMeterType;
}

const meterTypeOptions = [
  { label: EnergyMeterType.Electricity, value: EnergyMeterType.Electricity },
  { label: EnergyMeterType.Gas, value: EnergyMeterType.Gas },
  { label: EnergyMeterType.Heating, value: EnergyMeterType.Heating },
  { label: EnergyMeterType.Water, value: EnergyMeterType.Water },
];

const Option = (props: OptionProps<EnergyMeterTypeOption>) => {
  const icon = EnergyMeterType_Icon(props.data.value);

  return (
    <components.Option {...props} >
      {icon &&
        <MeterTypeIcon
          icon={icon}
          color={EnergyMeterType_Color(props.data.value)}
        />
      }
      {props.data.value}
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps<EnergyMeterTypeOption>) => {
  const icon = EnergyMeterType_Icon(props.data.value);

  return (
    <components.SingleValue {...props} >
      {icon &&
        <MeterTypeIcon
          icon={icon}
          color={EnergyMeterType_Color(props.data.value)}
        />
      }
      {props.data.value}
    </components.SingleValue>
  );
};


interface IEnergyMeterTopology {
  building: Building;
}

const EnergyMeterTopology = ({ building }: IEnergyMeterTopology) => {
  const { t } = useTranslation(['settingsAsset']);
  const theme = useTheme();
  const { execute } = useApi();
  const [selectedMeterType, setSelectedMeterType] = useState<EnergyMeterType>(EnergyMeterType.Electricity);
  const [changesMade, setChangesMade] = useState(false);
  const [modifiedMeters, setModifiedMeters] = useState<EnergyMeterTopologyDto[]>([]);
  const { data: meters, loading, execute: refreshMeters } = useApiState({
    query: new EnergyMetersGetByBuildingIdQuery(building.id),
    initialState: [],
  }, [building]);
  const metersOfSelectedType = modifiedMeters.filter(x => x.meterType === selectedMeterType);
  const hasMainMeter = metersOfSelectedType.some((x) => x.meterTopologyLevel === EnergyMeterTopologyLevel.Main);

  useEffect(() => {
    setModifiedMeters(meters);
  }, [meters]);

  const hasBeenModified = useCallback((modified: EnergyMeterTopologyDto) => {
    const meter = meters.find(x => x.id === modified.id);
    return meter && meter.parentMeterId !== modified.parentMeterId;
  }, [meters]);

  const handleReset = useCallback(() => {
    setModifiedMeters(meters);
    setChangesMade(false);
  }, [meters]);

  const handleSave = useCallback(async () => {
    const modifiedDtos = modifiedMeters.filter(hasBeenModified);

    if (modifiedDtos.length === 0) {
      return;
    }

    const success = await execute({
      query: new EnergyMetersBulkUpdateCommand(modifiedDtos),
      pendingMessage: t('Buildings.EnergyMeters.EnergyMeterTopology.SavingChanges', { ns: 'settingsAsset' }),
      successMessage: t('Buildings.EnergyMeters.EnergyMeterTopology.ChangesSaved', { ns: 'settingsAsset' }),
      errorMessage: t('Buildings.EnergyMeters.EnergyMeterTopology.SavingFailed', { ns: 'settingsAsset' })
    });

    if (success) {
      refreshMeters();
      setChangesMade(false);
    }
  }, [execute, modifiedMeters, refreshMeters, hasBeenModified, t]);

  const addDistributionMeter = useCallback((meterId: number, parentMeter: EnergyMeterTopologyDto) => {
    const modified = modifiedMeters.map(x => x.id !== meterId
      ? x
      : ({ ...x, parentMeterId: parentMeter.id, parentMeter: parentMeter })
    );

    setModifiedMeters(modified);
    setChangesMade(true);
  }, [modifiedMeters]);

  const updateMeters = useCallback((selected: EnergyMeterTopologyDto[], parentMeter: EnergyMeterTopologyDto, level: EnergyMeterTopologyLevel) => {
    let modified = modifiedMeters.map(x => selected.some(m => m.id === x.id)
      ? ({ ...x, parentMeterId: parentMeter.id, parentMeter: parentMeter })
      : x
    );

    // Remove parentMeterId from meters which were deselected (Is true if (1) same parentId, (2) of the same MeterTopologyLevel, and (3) not longer in the selected list)
    modified = modified.map(x => x.parentMeterId === parentMeter.id && x.meterTopologyLevel === level && !selected.some(m => m.id === x.id)
      ? ({ ...x, parentMeterId: undefined, parentMeter: undefined })
      : x
    );

    setModifiedMeters(modified);
    setChangesMade(true);
  }, [modifiedMeters]);

  return (
    <Suspense>
      <ButtonRow>
        <SelectWrapper>
          <Select
            options={meterTypeOptions}
            onChange={x => x && setSelectedMeterType(x.value)}
            defaultValue={meterTypeOptions[0]}
            components={{ Option, SingleValue }}
            isSearchable={false}
            isMulti={false}
          />
        </SelectWrapper>

        <div style={{ marginLeft: 'auto' }} />

        <Button
          tertiary
          label={t('Buildings.EnergyMeters.EnergyMeterTopology.Reset', { ns: 'settingsAsset' })}
          onClick={handleReset}
          disabled={!changesMade}
          color={theme.palette.red}
        />

        <Button
          label={t('Buildings.EnergyMeters.EnergyMeterTopology.Save', { ns: 'settingsAsset' })}
          onClick={handleSave}
          disabled={!changesMade}
          loading={false}
        />
      </ButtonRow>

      {loading &&
        <Loading />
      }

      {!loading && meters.length === 0 &&
        <EmptyMessage>{t('Buildings.EnergyMeters.EnergyMeterTopology.NoMeters', { ns: 'settingsAsset' })}</EmptyMessage>
      }

      {!loading && meters.length > 0 && !hasMainMeter &&
        <EmptyMessage>{t('Buildings.EnergyMeters.EnergyMeterTopology.NoMainMeter', { ns: 'settingsAsset' })}</EmptyMessage>
      }

      {!loading && meters.length !== 0 && hasMainMeter &&
        <ScrollDrag>
          <FlexRow>
            <Xwrapper>
              {metersOfSelectedType
                .filter(x => x.meterTopologyLevel === EnergyMeterTopologyLevel.Main)
                .map((mainMeter) => {
                  return (
                    <Wrapper key={mainMeter.id}>
                      <MainMeter
                        meter={mainMeter}
                        meters={metersOfSelectedType}
                        addDistributionMeter={addDistributionMeter}
                        updateMeters={updateMeters}
                        isBillingmeter={mainMeter.isBillingMeter}
                      />
                    </Wrapper>
                  );
                })}
            </Xwrapper>
          </FlexRow>
        </ScrollDrag>
      }
    </Suspense>
  );
};

export default EnergyMeterTopology;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 5px;
`;

const FlexRow = styled.div`
  width: 100%;
  align-items: start;
  display: flex;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const MeterTypeIcon = styled(FontAwesomeIcon)`
  height: 14px;
  width: 14px;
  margin-right: 8px;
`;

const Wrapper = styled.div`
  display: grid; 
  position: relative;
  border: 1px dashed ${p => p.theme.palette.borders.medium};
  border-radius: 9px;
  padding: 30px;
  margin: 30px;
`;

const EmptyMessage = styled.div`
  font-weight: 500;
  color: ${(p) => p.theme.palette.text.weak};
  text-align: center;
  padding: 50px 10px;
`;