//#region Enum

enum EsgExplorerRulePeriod {
  Week = 'Week',
  Month = 'Month',
  Quarter = 'Quarter'
}

export default EsgExplorerRulePeriod;

//#endregion

//#region Mapping accessor functions

namespace EsgExplorerRulePeriod { // eslint-disable-line
  /**
   * Get user-friendly display string
   */
  export const getDisplayString = (period: EsgExplorerRulePeriod): string => {
    return periodToDisplayString[period] ?? period;
  }
}

//#endregion

//#region Mappings

/**
 * Map DeviceType to a user-friendly display string
 */
const periodToDisplayString: { [key in EsgExplorerRulePeriod]: string } = Object.freeze({
  [EsgExplorerRulePeriod.Week]: 'Weekly',
  [EsgExplorerRulePeriod.Month]: 'Monthly',
  [EsgExplorerRulePeriod.Quarter]: 'Quarterly'
});

//#endregion

export const EsgExplorerRulePeriodOptions = [
  { label: 'Weekly', value: EsgExplorerRulePeriod.Week },
  { label: 'Monthly', value: EsgExplorerRulePeriod.Month },
  { label: 'Quarterly', value: EsgExplorerRulePeriod.Quarter }
];