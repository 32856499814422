import { callPostFormData, IAuthConfig } from '@shared/utils/ConnectApiUtils';
import { BaseQuery } from '@shared/api/queries/common/BaseQuery';

export abstract class OperatorPostFormDataQuery<TDto> extends BaseQuery<TDto> {
  formData: FormData;

  constructor(formData: FormData) {
    super();
    this.formData = formData;
  }

  public async execute(authConfig: IAuthConfig, controller?: AbortController): Promise<TDto> {
    const apiUrl = this.getApiUrl(authConfig.operatorApiBaseUrl);
    const response = await callPostFormData(apiUrl, this.formData, authConfig, controller);

    return response.data;
  }
}
