import styled, { css, useTheme } from 'styled-components';
import ExplorerConfiguration from './ExplorerConfiguration/ExplorerConfiguration';
import { useState } from 'react';
import EsgExplorerTemplateDto from '@dashboard/api/models/EsgExplorerTemplateDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { Footer, isCongfigValid } from '@dashboard/components/molecules/EsgExplorer/EsgExplorerMenu';
import { Building } from '@shared/api/models/Building/Building';
import SaveToTemplate from './SaveToTemplate';
import { useTranslation } from 'react-i18next';

const createEmptyTemplate = (siteId: number): EsgExplorerTemplateDto => ({
  name: '',
  createdByCurrentUser: true,
  private: true,
  configuration: {
    siteId: siteId,
    buildingIds: [],
    spaceTypes: [],
    lettingStatuses: [],
    metricTypes: [],
    timeRange: undefined,
    filters: []
  }
});

interface IExplorerTab {
  open: boolean;
  siteId: number;
  buildings: Building[];
  refreshTemplates: () => void;
  onGenerate: (config: EsgExplorerTemplateDto) => void;
  loading: boolean;
}

const ExplorerTab = ({ open, siteId, buildings, refreshTemplates, onGenerate, loading }: IExplorerTab) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [template, setTemplate] = useState<EsgExplorerTemplateDto>(createEmptyTemplate(siteId));
  const [inSavingState, setInSavingState] = useState(false);

  const handleGenerate = () => {
    onGenerate(template);
  };

  const handleReset = () => {
    setTemplate(createEmptyTemplate(siteId));
  };

  return (
    <Wrapper isHidden={!open}>
      <ScrollContainer>
        <ExplorerConfiguration
          siteId={siteId}
          buildings={buildings}
          config={template.configuration}
          onChange={(config) => setTemplate({
            ...template,
            configuration: config
          })}
        />

        <Spacer />

        <Footer>
          <Button
            tertiary
            cypress_id={'ESGExplorer_Explorer_GenerateDataButton'}
            label={t('Reset', { ns: 'common' })}
            onClick={handleReset}
            style={{ width: '100%' }}
            color={theme.palette.red}
          />

          <SaveToTemplate
            siteId={siteId}
            template={template}
            isEnabled={isCongfigValid(template.configuration)}
            inSavingState={inSavingState}
            setInSavingState={setInSavingState}
            refreshTemplates={refreshTemplates}
          />

          <Button
            cypress_id={'ESGExplorer_Explorer_GenerateDataButton'}
            label={t('Generate', { ns: 'common' })}
            onClick={handleGenerate}
            disabled={!isCongfigValid(template.configuration) || loading}
            style={{ width: '100%' }}
          />
        </Footer>
      </ScrollContainer>
    </Wrapper>
  );
};

export default ExplorerTab;

const Wrapper = styled.div<{ isHidden: boolean }>`
  height: 100%;
  overflow: hidden;

  ${p => p.isHidden && css`
    display: none;
  `}
`;

const ScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 15px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  height: 40px;
  flex-shrink: 0;
`;