import { MetricType } from '../MetricType/MetricType';

export enum TimeGranularity {
  Hourly = 'Hourly',
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export const getTimeGranularityMetrics = (): MetricType[] => {
  return [
    MetricType.ElectricityKwhDelta,
    MetricType.GasVolumeDelta,
    MetricType.HeatingKwhDelta,
    MetricType.WaterVolumeDelta,
    MetricType.ElectricityGeneratedKwhDelta,
    MetricType.ElectricityL1ImportKwh,
    MetricType.ElectricityL2ImportKwh,
    MetricType.ElectricityL3ImportKwh,
    MetricType.ElectricityL1ExportKwh,
    MetricType.ElectricityL2ExportKwh,
    MetricType.ElectricityL3ExportKwh,
    MetricType.ElectricityL1ActivePower,
    MetricType.ElectricityL2ActivePower,
    MetricType.ElectricityL3ActivePower
  ];
};
