import { IGradientStep } from '@dashboard/types/GradientStep';
import { parseToRgb, rgbToColorString } from 'polished';
import { RgbaColor } from 'polished/lib/types/color';

/**
 * Convert rgba color ro rgb color, removing the alpha channel through color composition using the specified background color.
 */
export const rgbaToRgb = (rgbaColor: string, bgColor = '#fff') => {
  const rgba = parseToRgb(rgbaColor) as RgbaColor; // eslint-disable-line @typescript-eslint/no-explicit-any
  const bg = parseToRgb(bgColor);

  const flattenedColor = {
    red: Math.round(rgba.alpha * rgba.red + (1 - rgba.alpha) * bg.red),
    green: Math.round(rgba.alpha * rgba.green + (1 - rgba.alpha) * bg.green),
    blue: Math.round(rgba.alpha * rgba.blue + (1 - rgba.alpha) * bg.blue),
  }

  return rgbToColorString(flattenedColor)
}

export const getGradientColorByValue = (steps: IGradientStep[], value: number) => {
  let colorRange: number[] = [];

  let index = 0;
  for (const step of steps) {
    // Return the first gradient color if the value <= first gradient value
    if (index === 0 && value <= step.value) {
      return step.color;
    }

    // Return the last gradient color if the value >= last gradient value
    if (index === steps.length - 1 && value >= step.value) {
      return step.color;
    }

    if (value <= step.value) {
      colorRange = [index - 1, index];
      break;
    }

    index++;
  }

  //Get the two closest colors
  const firstcolor = steps[colorRange[0]].color;
  const secondcolor = steps[colorRange[1]].color;

  //Calculate ratio between the two closest colors
  const firstcolor_x = (steps[colorRange[0]].value / 100);
  const secondcolor_x = (steps[colorRange[1]].value / 100) - firstcolor_x;
  const slider_x = (value / 100) - firstcolor_x;
  const ratio = slider_x / secondcolor_x

  //Get the color with pickHex(thx, less.js's mix function!)
  const hexCode = pickHex(secondcolor, firstcolor, ratio);

  return hexCode;
};

//#region Internal helper methods

/**
 * Convert a number to a hex component string
 */
function numberToHexComponent(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

/**
 * Convert an rgb array to a hex string
 */
const rgbToHex = (rgb: number[]) => {
  return '#' + numberToHexComponent(rgb[0]) + numberToHexComponent(rgb[1]) + numberToHexComponent(rgb[2]);
}

/**
 * Pick the hex color between two colors given a ratio
 */
const pickHex = (hexColor1: string, hexColor2: string, ratio: number): string | undefined => {
  const color1 = hexToRgb(hexColor1);
  const color2 = hexToRgb(hexColor2);

  if (!color1 || !color2) {
    return;
  }

  const w1 = ratio;
  const w2 = 1 - w1;
  const rgb = [Math.round(color1.r * w1 + color2.r * w2), Math.round(color1.g * w1 + color2.g * w2), Math.round(color1.b * w1 + color2.b * w2)];

  return rgbToHex(rgb);
}

/**
 * Convert a hex string to an rgb object
 */
export const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

//#endregion