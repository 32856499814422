import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import ExceedingSpacesTable from './ExceedingSpacesTable';
import { Card } from '@shared/components/molecules/Card/Card';
import { useApiState } from '@shared/hooks/useApiState';
import { SiteFairUseExceedingSpacesGetQuery } from '@shared/api/queries/FairUse/ExceedingSpaces/SiteFairUseExceedingSpacesGetQuery';
import { useParams } from 'react-router-dom';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { SpacesFairUseEnergyConsumption } from '@shared/api/models/FairUse/SpaceFairUseEnergyConsumption';
import { SiteFairUsePolicyGetByIdQuery } from '@shared/api/queries/FairUse/SitePolicy/SiteFairUsePolicyGetByIdQuery';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useApi } from '@shared/hooks/useApi';
import { SiteFairUseSeasonalityAdjustment } from '@shared/api/models/FairUse/SiteFairUseSeasonalityAdjustment';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { Button } from '@shared/components/atoms/Button/Button';
import { CsvExceedingSpacesGetQuery } from '@shared/api/queries/FairUse/ExceedingSpaces/CsvExceedingSpacesGetQuery';
import useCsvExport from '@dashboard/hooks/useCsvExport';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import NoResultsError from '@dashboard/components/atoms/Errors/NoResultsError';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

const FairUse_ExceedingSpaces = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { site } = useSiteContext();
  const { tenant } = useTenantContext();
  const { execute, loading } = useApi();
  const { policyId } = useParams<{ policyId: string }>();
  const { trackAction } = useAnalytics();
  const { RangePicker } = DatePicker;
  const { handleCsvExport: exportCsv } = useCsvExport();
  const [spacesFairUseEnergyConsumption, setSpacesFairUseEnergyConsumption] = useState<SpacesFairUseEnergyConsumption>();

  const { data: siteFairUsePolicy, loading: loadingPolicy } = useApiState({
    query: policyId === undefined ? undefined : new SiteFairUsePolicyGetByIdQuery(parseInt(policyId)),
    errorMessage: t('FairUsage.FairUseLoadFailed', { ns: 'molecules' })
  }, []);

  const getSpaceEnergyConsumption = useCallback(async (fromDate?: Dayjs | null, toDate?: Dayjs | null): Promise<void> => {
    if (siteFairUsePolicy && fromDate && toDate) {
      const selectedMonths = Array.from(
        { length: toDate.diff(fromDate, 'month') + 1 },
        (_, index) => fromDate.get('month') + index > 11 ? fromDate.get('month') + index - 12 : fromDate.get('month') + index);

      const seasonalityAdjustmentIds = siteFairUsePolicy?.seasonalityAdjustments
        .filter((seasonalityAdjustment: SiteFairUseSeasonalityAdjustment) => seasonalityAdjustment.meterType === EnergyMeterType.Electricity &&
          selectedMonths.includes(dayjs(seasonalityAdjustment.start).get('month')))
        .map((seasonalityAdjustment: SiteFairUseSeasonalityAdjustment) => seasonalityAdjustment.id);

      const response = await execute({
        query: new SiteFairUseExceedingSpacesGetQuery(siteFairUsePolicy.id, site.id, seasonalityAdjustmentIds, EnergyMeterType.Electricity),
        errorMessage: t('FairUsage.FairUseLoadFailed', { ns: 'molecules' })
      });

      setSpacesFairUseEnergyConsumption(response);
    } else {
      setSpacesFairUseEnergyConsumption(undefined);
    }
  }, [execute, site.id, siteFairUsePolicy, t]);

  const getReportingPeriod = useCallback((): [Dayjs | null | undefined, Dayjs | null | undefined] | undefined => {
    if (dayjs(siteFairUsePolicy?.start).isAfter(dayjs()) || dayjs(siteFairUsePolicy?.start).isSame(dayjs(), 'month')) {
      return undefined;
    }

    let endDate = dayjs(siteFairUsePolicy?.start).add(11, 'months')

    if (dayjs().isBefore(dayjs(siteFairUsePolicy?.start).add(12, 'months'))) {
      endDate = dayjs().subtract(1, 'months')
    }

    return [endDate, endDate]
  }, [siteFairUsePolicy?.start]);

  useEffect(() => {
    const reportingPeriod = getReportingPeriod();
    if (reportingPeriod) {
      getSpaceEnergyConsumption(reportingPeriod[0], reportingPeriod[0])
    }
  }, [getSpaceEnergyConsumption, getReportingPeriod, t]);

  const exportExceedingSpaces = (): void => {
    if (spacesFairUseEnergyConsumption) {
      const query = new CsvExceedingSpacesGetQuery(spacesFairUseEnergyConsumption?.exceedingSpaces);
      const startDate = dayjs(spacesFairUseEnergyConsumption?.fromDate).format('YYYYMMDD');
      const endDate = dayjs(spacesFairUseEnergyConsumption?.toDate).subtract(1, 'day').format('YYYYMMDD');
      const fileName = `${tenant.name.replace(' ', '_')}_${site.name.replace(' ', '_')}_${t('FairUsage.FairUse', { ns: 'molecules' }).replace(' ', '_')}_${startDate}_${endDate}.csv`;
      exportCsv(query, fileName);
      trackAction('export_csv', 'fair_use');
    }
  };

  const disabledDate = (current: Dayjs): boolean => {
    const startDate = dayjs(siteFairUsePolicy?.start);
    const endDate = dayjs().isBefore(dayjs(siteFairUsePolicy?.start).add(12, 'months')) ? dayjs().add(-1, 'months') : dayjs(siteFairUsePolicy?.start).add(12, 'months');
    return (startDate && current < startDate) || current > endDate;
  }

  const getExceedingSpacesTable = ((): ReactElement => {
    if (!getReportingPeriod) {
      return <NoResultsError noResultsError={{
        errorIcon: solid('calendar-clock'),
        errorTitle: t('FairUsage.ExceedingSpaces.NoDataTitle', { ns: 'molecules' }),
        errorSubtitle: t('FairUsage.ExceedingSpaces.NoDataSubtitle', { ns: 'molecules' })
      }} />
    } else if (!spacesFairUseEnergyConsumption) {
      return <NoResultsError noResultsError={{
        errorIcon: solid('calendar-xmark'),
        errorTitle: t('FairUsage.ExceedingSpaces.NoDataTitle', { ns: 'molecules' }),
        errorSubtitle: t('FairUsage.ExceedingSpaces.NoPeriodSelected', { ns: 'molecules' })
      }} />
    } else if (spacesFairUseEnergyConsumption?.exceedingSpaces.length === 0) {
      return <NoResultsError noResultsError={{
        errorIcon: solid('party-horn'),
        errorTitle: t('FairUsage.ExceedingSpaces.NoExceedingSpacesTitle', { ns: 'molecules' }),
        errorSubtitle: t('FairUsage.ExceedingSpaces.NoExceedingSpacesSubtitle', { ns: 'molecules' })
      }} />
    } else {
      return <ExceedingSpacesTable spaceEnergyConsumptions={spacesFairUseEnergyConsumption?.exceedingSpaces} />
    }
  })

  return (
    <PaddedContainer>
      <FlexRow>
        <span>
          <Title
            text={t('FairUsage.FairUse', { ns: 'molecules' })}
            size='lg'
            wrapperStyle={{ paddingBottom: '20px' }}
            betaLabel={true}
          />
          <BackButton
            label={t('Back', { ns: 'common' })}
            url='./../..'
          />
        </span>

        {(spacesFairUseEnergyConsumption?.exceedingSpaces && spacesFairUseEnergyConsumption?.exceedingSpaces.length > 0) &&
          <Button
            tertiary
            label={t('ExportCSV', { ns: 'common' })}
            onClick={() => exportExceedingSpaces()}
            color={theme.palette.primary}
          />
        }

      </FlexRow>
      <FlexRow>
        <ExceedingSpacesContainer noPadding hideSemiCircle={true}>
          <FairUseTitle>
            {t('FairUsage.ExceedingSpaces.ExceedingSpaces', { ns: 'molecules' })}
          </FairUseTitle>

          {siteFairUsePolicy &&
            <RangePickerContainer>
              <span>{t('FairUsage.ExceedingSpaces.ExceededByPeriod', { ns: 'molecules' })}: </span>
              <RangePicker
                format="MMM YYYY"
                onChange={(timeRange: [Dayjs | null, Dayjs | null] | null) => {
                  getSpaceEnergyConsumption(timeRange?.[0], timeRange?.[1])
                  trackAction('exceeding_spaces_period_change', 'fair_use', { period: `${timeRange?.[0]?.format('MMM YYYY')} - ${timeRange?.[1]?.format('MMM YYYY')}` });
                }}
                onClick={() => trackAction('exceeding_spaces_period_click', 'fair_use')}
                picker="month"
                allowClear={true}
                disabledDate={disabledDate}
                style={{ width: '350px' }}
                defaultValue={getReportingPeriod()}
                disabled={loadingPolicy || loading}
              />
            </RangePickerContainer>
          }

          {(loadingPolicy || loading) &&
            <Loading
              fullWidthCentered
              message={t('FairUsage.ExceedingSpaces.Loading', { ns: 'molecules' })}
              style={{ height: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            />
          }

          {(!loadingPolicy && !loading) &&
            getExceedingSpacesTable()
          }

        </ExceedingSpacesContainer>
      </FlexRow>
    </PaddedContainer>
  )
}

export default FairUse_ExceedingSpaces

const FlexRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const ExceedingSpacesContainer = styled(Card)`
  flex: 0 0 100%; 
  max-width: 100%;
  padding: 30px;
`;

const FairUseTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding: 15px 0;
`;

const RangePickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;  
  &:nth-child(2) > div > div > input {
    text-align: center;
  }
`;