import React, { ReactNode, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import FullScreenOverlay from './FullScreenOverlay';
import { useLocation } from 'react-router-dom';

interface ILayoutContext {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  isSidebarCollapsed: boolean;
  toggleSidebar: () => void;
}

export const LayoutContext = React.createContext({} as ILayoutContext);
export const useLayoutContext = () => useContext(LayoutContext);

export const LayoutProvider = ({ children }: { children?: ReactNode }) => {
  const location = useLocation();
  const [path, setPath] = useState<string>();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  // Exit full screen mode when the location changes (the user navigates to a different page)
  useEffect(() => {
    if (isFullScreen && location.pathname !== path) {
      setIsFullScreen(false);
    }
  }, [isFullScreen, path, location]);

  // Toggle full screen mode and set the current location path to detect a user navigating away to exit full screen mode
  const handleToggle = useCallback(() => {
    if (!isFullScreen) {
      setPath(location.pathname);
    }

    setIsFullScreen(!isFullScreen);
  }, [isFullScreen, location.pathname]);

  const LayoutContextValue = useMemo(() => (
    {
      isFullScreen,
      toggleFullScreen: handleToggle,
      isSidebarCollapsed,
      toggleSidebar: () => setIsSidebarCollapsed(prevState => !prevState),
    }
  ), [isSidebarCollapsed, isFullScreen, handleToggle]);

  return (
    <LayoutContext.Provider
      value={LayoutContextValue}
    >
      <FullScreenOverlay
        visible={isFullScreen}
        close={() => setIsFullScreen(false)}
      />

      {children}
    </LayoutContext.Provider>
  );
};