import { AxiosError } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast, ToastOptions } from 'react-toastify';

type ApiException = {
  status?: number;
  message?: string;
}

interface ExecutePropTypes<TData> {
  /**
   * Promise like async function
   */
  asyncFunc: () => Promise<TData>;
  /**
   * Toast success message
   */
  successMessage?: string;
  /**
   * Toast error message
   */
  errorMessage?: string;
  /**
   * Toast pending message
   */
  pendingMessage?: string;
  /**
   * Display the error message returned from the API if present (if set to true, custom errorMessage will not be displayed)
   */
  displayApiError?: boolean;
  /**
   * Toast options
   */
  toastOptions?: ToastOptions;
}

/**
 * A hook to help with executing queries/commands.
 *
 * @example
 *   const { execute, loading } = useAsync();
 *
 *   const result = await execute({
 *     asyncFunc: async () => await query.execute(),
 *     errorMessage: "CSV Export failed",
 *   })
 */
export const useAsync = () => {
  const { t } = useTranslation('exceptionMessages');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiException>();
  const isMounted = useRef<boolean>(true);
  const controller = useRef<AbortController>(new AbortController());

  const execute = useCallback(async <TData>({ asyncFunc, successMessage, errorMessage, pendingMessage, displayApiError, toastOptions }: ExecutePropTypes<TData>) => {
    setLoading(true);

    // Reset AbortController
    controller.current = new AbortController();

    try {
      let result;

      if (pendingMessage) {
        result = await toast.promise(asyncFunc, {
          pending: pendingMessage,
          success: successMessage,
          error: errorMessage
        }, toastOptions);
      } else {
        result = await asyncFunc();
        successMessage && toast.success(successMessage, toastOptions);
      }

      if (!isMounted.current) {
        return;
      }

      setLoading(false);
      return result;
    } catch (err) {
      const axiosError = err as AxiosError<string>;
      const apiException = { status: axiosError.response?.status, message: axiosError.response?.data ? t(axiosError.response?.data) : undefined };

      if (displayApiError && apiException.message) {
        toast.error(apiException.message, toastOptions);
      } else if (errorMessage && !pendingMessage) {
        toast.error(errorMessage, toastOptions);
      }

      if (!isMounted.current) {
        return;
      }

      setError(apiException);
      setLoading(false);
      console.log(err);
    }
  }, [t]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    execute,
    loading,
    error,
    controller: controller
  };
};
