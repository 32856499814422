import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DeviceBulkCreateCommand from '@settings/api/queries/Devices/DeviceBulkCreateCommand';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { BlobGetSasUriQuery } from '@shared/api/queries/Blob/BlobGetSasUriQuery';
import { FileDropzone } from '@shared/components/molecules/FileDropzone/FileDropzone';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import { useApi } from '@shared/hooks/useApi';
import { downloadFile } from '@shared/utils/DownloadUtils';
import { Button } from '@shared/components/atoms/Button/Button';
import { useModal } from '@shared/hooks/useModal';

type PropTypes = {
  buildingId: number,
  onComplete: () => void,
}

const DeviceBulkImport = ({ buildingId, onComplete }: PropTypes) => {
  const { t } = useTranslation(['settingsAsset']);
  const { isOpen: modalIsOpen, toggle: toggleModal, ref: refModal } = useModal({});
  const [csvFile, setCsvFile] = useState<File>();
  const [submitCsvEnabled, setSubmitCsvEnabled] = useState<boolean>(false);
  const { execute } = useApi();

  const handleFiles = (files: File[]) => {
    if (files[0]) {
      setCsvFile(files[0]);
      setSubmitCsvEnabled(true);
    }
    else {
      setCsvFile(undefined);
      setSubmitCsvEnabled(false);
    }
  };

  const handleSubmitFile = async () => {
    if (!csvFile) {
      setSubmitCsvEnabled(false);
      return;
    }

    await execute({
      query: new DeviceBulkCreateCommand(
        csvFile,
        buildingId
      ),
      errorMessage: t('Buildings.Devices.DeviceBulkImport.ErrorMessage', { ns: 'settingsAsset' }),
      successMessage: t('Buildings.Devices.DeviceBulkImport.SuccessMessage', { ns: 'settingsAsset' })
    });

    toggleModal();
    setCsvFile(undefined);
    setSubmitCsvEnabled(false);
    onComplete();
  }

  const onClickDownloadTemplate = async () => {
    const blobUri = await execute({
      query: new BlobGetSasUriQuery(
        'Device&MeterScheduleBulkImport.xlsx',
        BlobStorageContainerType.Shared
      )
    });

    if (blobUri) {
      downloadFile(blobUri, 'UtopiConnect-Device&MeterScheduleBulkImportTemplate.xlsx');
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: handleFiles,
    accept: '.csv'
  });

  return (
    <>
      <Button
        tertiary
        label={t('Buildings.Devices.DeviceBulkImport.ImportDeviceSchedule', { ns: 'settingsAsset' })}
        onClick={toggleModal}
      />

      <InteractiveDialog
        modalRef={refModal}
        isOpen={modalIsOpen}
        hide={toggleModal}
        title={t('Buildings.Devices.DeviceBulkImport.ImportDeviceSchedule', { ns: 'settingsAsset' })}
        confirmButton={{
          label: t('Buildings.Devices.DeviceBulkImport.Import', { ns: 'settingsAsset' }),
          onClick: handleSubmitFile,
          disabled: !submitCsvEnabled,
        }}
        cancelButton={{
          label: t('Cancel', { ns: 'common' }),
          onClick: toggleModal
        }}
        content={
          <>
            <SubHeader>
              {t('Buildings.Devices.DeviceBulkImport.DownloadHeaderText', { ns: 'settingsAsset' })}
            </SubHeader>
            <p>
              {t('Buildings.Devices.DeviceBulkImport.DownloadDescriptionText', { ns: 'settingsAsset' })}
            </p>

            <ModalFlexRow>
              <Button
                label={t('Buildings.Devices.DeviceBulkImport.Download', { ns: 'settingsAsset' })}
                onClick={onClickDownloadTemplate}
              />
            </ModalFlexRow>

            <SubHeader>
              {t('Buildings.Devices.DeviceBulkImport.UploadHeaderText', { ns: 'settingsAsset' })}
            </SubHeader>
            <p>
              {t('Buildings.Devices.DeviceBulkImport.UploadDescriptionText', { ns: 'settingsAsset' })}
            </p>

            <FileDropzone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              fileName={csvFile?.name}
              dimText={csvFile ? '' : t('Buildings.Devices.DeviceBulkImport.ImportCSVFile', { ns: 'settingsAsset' })}
              containerStyle={{ height: '100px', width: 'fill' }}
            />
          </>
        }
      />
    </>
  );
}

export default DeviceBulkImport;

const ModalFlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const SubHeader = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${p => p.theme.palette.text.fair};
  padding-bottom: 5px;
`;