import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { TemperatureRangeForm } from '../types/TemperatureRangeForm';
import ExternalSensor from './ExternalSensor';
import SiteTemperature from './SiteTemperature';
import UnoccupiedStepDown from './UnoccupiedStepDown';

type TemperatureRangesProps = {
  siteClimateControl: SiteClimateControlDto;
}

const TemperatureRanges = ({ siteClimateControl }: TemperatureRangesProps) => {
  const { reset, watch } = useFormContext<TemperatureRangeForm>();
  const { toLocale, getUnit } = useLocalisation();
  const unit = getUnit(MetricType.Temperature);
  const rangeMinTemp = watch('temperatureRangeSettings.minTemp');
  const rangeMaxTemp = watch('temperatureRangeSettings.maxTemp');

  useEffect(() => {
    const defaultFormValues: TemperatureRangeForm = {
      fallbackSettings: {
        noMotionRulesEnabled: siteClimateControl.noMotionRulesEnabled,
        noMotionThreshold1: siteClimateControl.noMotionThreshold1,
        noMotionThreshold1Temp: toLocale(MetricType.Temperature, siteClimateControl.noMotionThreshold1Temp, { round: 0 }),
        noMotionThreshold2: siteClimateControl.noMotionThreshold2,
        noMotionThreshold2Temp: toLocale(MetricType.Temperature, siteClimateControl.noMotionThreshold2Temp, { round: 0 }),
        externalSensorModeEnabled: siteClimateControl.externalSensorModeEnabled,
        controlMode: siteClimateControl.controlMode,
        offlineSensorThreshold: siteClimateControl.offlineSensorThreshold,
        offlineSensorTemp: toLocale(MetricType.Temperature, siteClimateControl.offlineSensorTemp, { round: 0 }),
      },
      temperatureRangeSettings: {
        minTemp: toLocale(MetricType.Temperature, siteClimateControl.minTemp, { round: 0 }),
        maxTemp: toLocale(MetricType.Temperature, siteClimateControl.maxTemp, { round: 0 }),
        targetTemp: toLocale(MetricType.Temperature, siteClimateControl.targetTemp, { round: 0 })
      }
    }
    reset(defaultFormValues);
  }, [reset, siteClimateControl, toLocale]);

  return (
    <Wrapper>
      <SiteTemperature />

      <UnoccupiedStepDown
        rangeMinTemp={rangeMinTemp}
        rangeMaxTemp={rangeMaxTemp}
        unit={unit}
      />

      {siteClimateControl.externalSensorModeEnabled &&
        <ExternalSensor
          rangeMinTemp={rangeMinTemp}
          rangeMaxTemp={rangeMaxTemp}
          unit={unit}
        />
      }
    </Wrapper>
  )
}

export default TemperatureRanges

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

