import { useEffect, useState } from 'react';
import { createNodeTree, getSelectedLabelsAndValues } from './CascaderUtils';
import { ICascaderMultiSelectNodeGroup, IInternalNodeGroup } from './CascaderMultiSelect.types';
import { cloneDeep } from 'lodash';
import { useModal } from '@shared/hooks/useModal';
import { useHasMinimumHeight } from '@shared/hooks/useHasMinimumHeight';

interface IuseCascaderMultiSelect<TValue> {
  group: ICascaderMultiSelectNodeGroup<TValue>;
  onChange: (values?: TValue[]) => void;
  includeChildValues?: boolean;
  maxFieldHeight?: number;
  resetPulse?: boolean;
}

const useCascaderMultiSelect = <TValue,>({ group, onChange, includeChildValues, maxFieldHeight = 75, resetPulse }: IuseCascaderMultiSelect<TValue>) => {
  const { isOpen: isModalOpen, toggle: toggleModal, ref: modalRef } = useModal({});
  const { ref: selectFieldRef, hasMinimumHeight } = useHasMinimumHeight(maxFieldHeight);
  const [appliedNodeGroup, setAppliedNodeGroup] = useState<IInternalNodeGroup<TValue> | undefined>();
  const [transientNodeGroup, setTransientNodeGroup] = useState<IInternalNodeGroup<TValue> | undefined>();
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  /**
   * Set applied and transient nodeGroups on first render or when the "group" prop changes
   */
  useEffect(() => {
    const nodeTree = createNodeTree('', cloneDeep(group));
    setAppliedNodeGroup(nodeTree);
    setTransientNodeGroup(cloneDeep(nodeTree));

    const { labels } = getSelectedLabelsAndValues(nodeTree, false, includeChildValues);
    setSelectedLabels(labels);
  }, [group, includeChildValues, resetPulse]);

  /**
   * Reset nodeGroup when modal is closed (via the Cancel button or a click outside of the Modal)
   */
  useEffect(() => {
    if (!isModalOpen) {
      setTransientNodeGroup(cloneDeep(appliedNodeGroup));
    }
  }, [isModalOpen, appliedNodeGroup]);

  const handleSelect = (modifiedNodeGroup: IInternalNodeGroup<TValue>) => {
    setTransientNodeGroup(modifiedNodeGroup);
  };

  const handleApply = (modifiedNodeGroup: IInternalNodeGroup<TValue>) => {
    // Make transientNodeGroup the appliedNodeGroup
    setAppliedNodeGroup(modifiedNodeGroup);

    // Recursively look for all selected nodes, set labels for display in select field, and pass selected values to parent component.
    const { labels, values } = getSelectedLabelsAndValues(modifiedNodeGroup, false, includeChildValues);
    setSelectedLabels(labels);
    onChange(labels.length > 0 ? values : undefined);

    // Close the modal
    toggleModal();
  };

  return {
    isModalOpen,
    toggleModal,
    modalRef,
    selectFieldRef,
    hasMinimumHeight,
    transientNodeGroup,
    handleSelect,
    handleApply,
    selectedLabels
  };
};

export default useCascaderMultiSelect;