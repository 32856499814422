import { MonthlyWeighting } from '@shared/api/models/Building/BuildingMetadata';
import { SiteMetadata } from '@shared/api/models/SiteMetadata/SiteMetadata';
import { GenericPatchQuery } from '@shared/api/queries/common/GenericPatchQuery';

export default class SiteMetadataUpdateCommand extends GenericPatchQuery<SiteMetadata> {

  id: string;
  siteId: number;

  currency?: string;

  electricityPricePerKwh?: number;
  heatingPricePerKwh?: number;
  waterPricePerM3?: number;
  wasteWaterPricePerM3?: number;
  gasPricePerM3?: number;
  gasCalorificValue?: number;

  targetCarbon?: number;
  baselineCarbon?: number;

  electricityCarbonIntensity?: number;
  heatingCarbonIntensity?: number;
  coolingCarbonIntensity?: number;
  waterCarbonIntensity?: number;
  wasteWaterCarbonIntensity?: number;
  wasteWaterProportion?: number;
  gasCarbonIntensity?: number;

  electricityConsumptionTarget?: number;
  heatingConsumptionTarget?: number;
  coolingConsumptionTarget?: number;
  waterConsumptionTarget?: number;
  gasConsumptionTarget?: number;

  electricityConsumptionTargetMonthlyWeighting?: MonthlyWeighting;
  heatingConsumptionTargetMonthlyWeighting?: MonthlyWeighting;
  coolingConsumptionTargetMonthlyWeighting?: MonthlyWeighting;
  waterConsumptionTargetMonthlyWeighting?: MonthlyWeighting;
  gasConsumptionTargetMonthlyWeighting?: MonthlyWeighting;

  gresb?: number;
  fitwel?: number;
  breeam?: number;
  epc?: string;
  liveDate?: Date

  minTemperature?: number;
  maxTemperature?: number;

  constructor(siteMetadata: SiteMetadata) {
    super();

    this.id = siteMetadata.id;
    this.siteId = siteMetadata.siteId;

    this.currency = siteMetadata.currency;

    this.electricityConsumptionTarget = siteMetadata.electricityConsumptionTarget;
    this.heatingConsumptionTarget = siteMetadata.heatingConsumptionTarget;
    this.coolingConsumptionTarget = siteMetadata.coolingConsumptionTarget;
    this.waterConsumptionTarget = siteMetadata.waterConsumptionTarget;
    this.gasConsumptionTarget = siteMetadata.gasConsumptionTarget;

    this.electricityConsumptionTargetMonthlyWeighting = siteMetadata.electricityConsumptionTargetMonthlyWeighting;
    this.heatingConsumptionTargetMonthlyWeighting = siteMetadata.heatingConsumptionTargetMonthlyWeighting;
    this.coolingConsumptionTargetMonthlyWeighting = siteMetadata.coolingConsumptionTargetMonthlyWeighting;
    this.waterConsumptionTargetMonthlyWeighting = siteMetadata.waterConsumptionTargetMonthlyWeighting;
    this.gasConsumptionTargetMonthlyWeighting = siteMetadata.gasConsumptionTargetMonthlyWeighting;

    this.electricityPricePerKwh = siteMetadata.electricityPricePerKwh;
    this.heatingPricePerKwh = siteMetadata.heatingPricePerKwh;
    this.waterPricePerM3 = siteMetadata.waterPricePerM3;

    this.targetCarbon = siteMetadata.targetCarbon;
    this.baselineCarbon = siteMetadata.baselineCarbon;
    this.electricityCarbonIntensity = siteMetadata.electricityCarbonIntensity;
    this.heatingCarbonIntensity = siteMetadata.heatingCarbonIntensity;
    this.coolingCarbonIntensity = siteMetadata.coolingCarbonIntensity;
    this.waterCarbonIntensity = siteMetadata.waterCarbonIntensity;
    this.wasteWaterCarbonIntensity = siteMetadata.wasteWaterCarbonIntensity;
    this.gasCarbonIntensity = siteMetadata.gasCarbonIntensity;
    this.wasteWaterProportion = siteMetadata.wasteWaterProportion;
    this.wasteWaterPricePerM3 = siteMetadata.wasteWaterPricePerM3;
    this.gasPricePerM3 = siteMetadata.gasPricePerM3;
    this.gasCalorificValue = siteMetadata.gasCalorificValue;

    this.gresb = siteMetadata.gresb;
    this.fitwel = siteMetadata.fitwel;
    this.breeam = siteMetadata.breeam;
    this.epc = siteMetadata.epc;
    this.liveDate = siteMetadata.liveDate;

    this.minTemperature = siteMetadata.minTemperature;
    this.maxTemperature = siteMetadata.maxTemperature;
  }

  public targetUrl(): string {
    return '/siteMetadata';
  }
}
