import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lightTheme } from '@shared/theme/lightTheme';
import { transparentize } from 'polished';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useSpaceContext } from '@src/components/pages/SpacePage/SpaceProvider';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { useTranslation } from 'react-i18next';
import { TRVControlMode } from '@shared/api/enums/TRVControlMode/TRVControlMode';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TFunction } from 'i18next';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';

const OfflineModeControlMode = TRVControlMode.TRV;

const getControlModeLabel = (t: TFunction, controlMode?: TRVControlMode): string => {
  if (!controlMode) {
    return '--';
  }

  const controlModeOn = t('On', { ns: 'common' }).toUpperCase();
  const controlModeOff = t('Off', { ns: 'common' }).toUpperCase();

  return controlMode === TRVControlMode.External ? controlModeOn : controlModeOff;
};

interface IDeviceCardTrvConfig {
  device: DeviceWithMetrics;
  inSidebar?: boolean;
}

export const DeviceCardTrvConfig = ({ device, inSidebar }: IDeviceCardTrvConfig) => {
  const { t } = useTranslation();
  const { siteClimateControl } = useSiteContext();
  const { spaceClimateControl } = useSpaceContext();
  const { getUnit, toLocale } = useLocalisation();

  if (!siteClimateControl || !spaceClimateControl || !device.trvConfig) {
    return null;
  }

  const expectedMinTemp = spaceClimateControl.minTemp ?? siteClimateControl.minTemp;
  const expectedMaxTemp = spaceClimateControl.maxTemp ?? siteClimateControl.maxTemp;
  const expectedTargetTemp = spaceClimateControl.targetTemp ?? siteClimateControl.targetTemp;
  const expectedControlMode = spaceClimateControl.offlineMode
    ? OfflineModeControlMode
    : spaceClimateControl.controlMode ?? siteClimateControl.controlMode;

  const tableData = [
    {
      ref: 'target',
      key: t('ClimateControl.Target', { ns: 'molecules' }),
      unit: getUnit(MetricType.Temperature),
      actual: device.trvConfig.targetTemp && toLocale(MetricType.Temperature, device.trvConfig.targetTemp, { round: 1 }),
      expected: toLocale(MetricType.Temperature, expectedTargetTemp, { round: 1 }),
      configMatches: true,
    },
    {
      ref: 'min',
      key: t('ClimateControl.RangeMin', { ns: 'molecules' }),
      unit: getUnit(MetricType.Temperature),
      actual: device.trvConfig.minTemp && toLocale(MetricType.Temperature, device.trvConfig.minTemp, { round: 1 }),
      expected: toLocale(MetricType.Temperature, expectedMinTemp, { round: 1 }),
      configMatches: expectedMinTemp === device.trvConfig.minTemp,
    },
    {
      ref: 'max',
      key: t('ClimateControl.RangeMax', { ns: 'molecules' }),
      unit: getUnit(MetricType.Temperature),
      actual: device.trvConfig.maxTemp && toLocale(MetricType.Temperature, device.trvConfig.maxTemp, { round: 1 }),
      expected: toLocale(MetricType.Temperature, expectedMaxTemp, { round: 1 }),
      configMatches: expectedMaxTemp === device.trvConfig.maxTemp,
    },
    {
      ref: 'controlMode',
      key: t('ClimateControl.ExternalSensor', { ns: 'molecules' }),
      actual: getControlModeLabel(t, device.trvConfig.controlMode),
      expected: getControlModeLabel(t, expectedControlMode),
      configMatches: expectedControlMode === device.trvConfig.controlMode
    }
  ];

  return (
    <Wrapper inSidebar={inSidebar}>
      <Title>
        {t('ClimateControl.TrvConfigHeader', { ns: 'molecules' })}
      </Title>

      <Table>
        {tableData.map((row) => (
          <Row
            key={row.ref}
            errorValue={!row.configMatches}
          >
            <Col>
              <Icon icon={row.configMatches ? solid('circle-check') : solid('circle-xmark')} style={{ color: row.configMatches ? lightTheme.palette.green : lightTheme.palette.red }} />
              {row.key}
            </Col>
            <Col style={{ marginLeft: 'auto' }}>
              <ExpectedValue hidden={row.configMatches}>
                ({row.expected}{row.unit})
              </ExpectedValue>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <Value errorValue={!row.configMatches}>
                {row.actual ?? '--'}{row.actual && row.unit}
              </Value>
            </Col>
          </Row>
        ))}
      </Table>

      {spaceClimateControl.offlineMode &&
        <OfflineModeWarning>
          <OfflineModeWarningIcon icon={solid('exclamation-triangle')} />
          {t('ClimateControl.OfflineModeWarning', {
            ns: 'molecules',
            hours: siteClimateControl.offlineSensorThreshold,
            temperature: siteClimateControl.offlineSensorTemp
          })}
        </OfflineModeWarning>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div<{ inSidebar?: boolean }>`
  font-size: 14px;
  padding: ${p => p.inSidebar ? '12px 0' : '16px 24px'};
  border-top: 1px solid ${p => p.theme.palette.borders.weak};
`;

const Table = styled.div`
  margin-top: 6px;
  border-radius: 5px;
  border: 1px solid ${p => transparentize(0.2, p.theme.palette.borders.medium)};
  box-shadow: 0 1px 3px 0px ${p => p.theme.palette.shadows.medium};

  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ errorValue?: boolean }>`
  padding: 12px 15px;
  display: flex;
  color: ${p => p.theme.palette.text.fair};

  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }

  &:nth-child(odd) {
    background-color: ${p => p.theme.palette.tables.evenRow};

    ${p => p.errorValue && css`
      background-color: ${p => transparentize(0.9, p.theme.palette.red)};
    `}
  }
  
  &:nth-child(even) {
    background-color: ${p => p.theme.palette.tables.unevenRow};

    ${p => p.errorValue && css`
      background-color: ${p => transparentize(0.9, p.theme.palette.red)};
    `}
  }
`;

const Col = styled.div<{ configMatches?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

const Value = styled.div<{ errorValue?: boolean }>`
  color: ${p => p.theme.palette.text.medium};
  font-weight: 500;

  ${p => p.errorValue && css`
    color: ${p => p.theme.palette.red};
  `}
`;

const ExpectedValue = styled.div`
  color: ${p => p.theme.palette.text.weak};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  margin-right: 10px;
`;

const Title = styled.div`
  color: ${p => p.theme.palette.text.weak};
  margin-left: 1px;
`;

export const OfflineModeWarning = styled.div<{ isVisible?: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  padding: 18px 15px;
  display: flex;
  margin-top: 20px;

  border-radius: 5px;
  background-color: ${p => transparentize(0.9, p.theme.palette.orange)};
`;

export const OfflineModeWarningIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${p => p.theme.palette.orange};
  margin: 2px 13px 0 0;
`;