import UnoccupiedSpaceDto from '@dashboard/api/models/UnoccupiedSpaceDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class UnoccupiedSpacesGetQuery extends GetQuery<UnoccupiedSpaceDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/metrics/UnoccupiedSpaces?siteId=${this.siteId}`;
  }
} 