import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: CalibrationFailed
 */
const HeatmapConfigCalibrationFailed: IHeatmapConfig = {
  accessor: (x: string) => x,
  displayFormat: () => '',

  scale: {
    categoryScale: true,
    points: [
      {
        threshold: 'False',
        label: 'Succeeded',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 'True',
        label: 'Failed',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigCalibrationFailed);