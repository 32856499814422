import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: RelayOutputStatus
 */
const HeatmapConfigRelay: IHeatmapConfig = {
  accessor: (x: string) => x,
  displayFormat: (x) => x === 'true' ? 'ON' : 'OFF',

  scale: {
    categoryScale: true,
    points: [
      {
        threshold: 'false',
        label: 'OFF',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 'true',
        label: 'ON',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigRelay);