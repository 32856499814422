import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { Card } from '@shared/components/molecules/Card/Card';
import { transparentize } from 'polished';
import styled from 'styled-components';

type SiteFeatureProps = {
  name: string;
  description: string;
  icon?: IconDefinition;
  isEnabled: boolean;
  onChange: (state: boolean) => void;
  isLoading?: boolean;
}

const SiteFeature = ({ name, description, icon, isEnabled, onChange, isLoading }: SiteFeatureProps) => {
  return (
    <Card noPadding>
      <Header>
        <Icon icon={icon ?? solid('flag')} />

        <Name>
          {name}
        </Name>

        <Description>
          {description}
        </Description>

        <div style={{ marginLeft: 'auto' }}>
          <Switch
            checked={isEnabled}
            onChange={onChange}
            colorChecked='#43c3a1'
            labelChecked={<TickIcon icon={solid('check')} />}
            isLoading={isLoading}
          />
        </div>
      </Header>
    </Card>
  );
};

export default SiteFeature;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  border-left: 5px solid ${p => p.theme.palette.primary};
  border-radius: 5px 0 0 5px;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.medium};

  width: 130px;
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  padding: 7px;
  border-radius: 50%;
  color: ${p => transparentize(0.1, p.theme.palette.primary)};
  background-color: ${p => transparentize(0.3, p.theme.palette.buttons.hoverBackground)};
  flex-shrink: 0;
`;

const TickIcon = styled(FontAwesomeIcon)`
  margin-right: 2px;
`;