
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserSummary } from '@shared/api/models/User/UserSummary';
import { Button } from '@shared/components/atoms/Button/Button';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useModal } from '@shared/hooks/useModal';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface IUserListDeleteDialog {
  user: UserSummary,
  onDelete: (userId: number) => void,
  deleteInProgress: boolean
}

const UserListDeleteDialog = ({ user, deleteInProgress, onDelete }: IUserListDeleteDialog) => {
  const { t } = useTranslation(['settingsUser']);
  const theme = useTheme();
  const { isOpen: deleteDialogIsOpen, toggle: deleteDialogToggle, ref: deleteDialogRef } = useModal({});

  return (
    <>
      <WarningDialog
        modalRef={deleteDialogRef}
        isOpen={deleteDialogIsOpen}
        sectionOne={t('ListDeleteDialog.DeleteUserText', { ns: 'settingsUser' })}
        sectionTwo={user.email}
        confirmButton={t('ListDeleteDialog.DeleteUser', { ns: 'settingsUser' })}
        onCancel={deleteDialogToggle}
        onConfirm={() => onDelete(user.id)}
        isLoading={deleteInProgress}
      />

      <Button
        tertiary
        circle
        onClick={deleteDialogToggle}
        label={<FontAwesomeIcon icon={solid('trash-xmark')} style={{ width: '18px', height: '18px' }} />}
        color={theme.palette.red}
        style={{ marginLeft: '5px' }}
      />
    </>
  );
};

export default UserListDeleteDialog;