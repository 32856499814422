import React from 'react';
import TitleColumn from './TitleColumn';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { EsgExplorerMetricsSpaceDto } from '@dashboard/api/models/EsgExplorerMetricsDto';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import EsgMetricType from '@dashboard/api/enums/EsgMetricType/EsgMetricType';
import { AltUnits } from '@shared/contexts/LocalisationContext/AltUnits';

interface ITitleRow {
  rowRef: React.RefObject<HTMLDivElement>;
  spaces?: EsgExplorerMetricsSpaceDto[];
  sortKey: string;
  sortOrder: 'desc' | 'asc';
  showClusterInfo?: boolean;
  handleSort: (newKey: string) => void
}

const TitleRow = ({ rowRef, spaces, sortKey, sortOrder, handleSort, showClusterInfo }: ITitleRow) => {
  const { t } = useTranslation();
  const { localisation } = useLocalisation();
  const { getUnit } = useLocalisation();

  return (
    <Row ref={rowRef}>
      {spaces && spaces.length > 0 &&
        <>
          <TitleColumn
            label='Building'
            columnSortKey="buildingName"
            currentSortKey={sortKey}
            sortOrder={sortOrder}
            updateSortKey={handleSort}
          />
          <TitleColumn
            label={t('ESG.Space', { ns: 'molecules' })}
            columnSortKey="spaceName"
            currentSortKey={sortKey}
            sortOrder={sortOrder}
            updateSortKey={handleSort}
          />
          <TitleColumn
            label={t('ESG.Type', { ns: 'molecules' })}
            columnSortKey="spaceType"
            currentSortKey={sortKey}
            sortOrder={sortOrder}
            updateSortKey={handleSort}
          />
          <TitleColumn
            label={t('Buildings.Spaces.SpaceManagement.ClusterName', { ns: 'settingsAsset' })}
            columnSortKey="clusterName"
            currentSortKey={sortKey}
            sortOrder={sortOrder}
            updateSortKey={handleSort}
            hide={!showClusterInfo}
          />
          <TitleColumn
            label={t('Buildings.Spaces.SpaceManagement.NumberOfBedrooms', { ns: 'settingsAsset' })}
            columnSortKey="bedroomsInCluster"
            currentSortKey={sortKey}
            sortOrder={sortOrder}
            updateSortKey={handleSort}
            hide={!showClusterInfo}
          />
          <TitleColumn
            label={t('ESG.Status', { ns: 'molecules' })}
            columnSortKey="occupied"
            currentSortKey={sortKey}
            sortOrder={sortOrder}
            updateSortKey={handleSort}
            style={{ width: '100px' }}
          />
          <TitleColumn
            label={localisation.metrics[AltUnits.Area].unitLabel}
            columnSortKey="spaceArea"
            currentSortKey={sortKey}
            sortOrder={sortOrder}
            updateSortKey={handleSort}
            style={{ width: '100px' }}
          />
          {spaces[0].esgMeasurements.map((metric, i) => (
            <TitleColumn
              key={i}
              label={
                <Trans i18nKey={`enums:TitleRowLabel.${metric.esgMetricType}`}>
                  <br />
                  {{ unit: getUnit(EsgMetricType.toMetricType(metric.esgMetricType)) }}
                  {{ areaUnit: getUnit(AltUnits.Area) }}
                </Trans>
              }
              columnSortKey={metric.esgMetricType}
              currentSortKey={sortKey}
              sortOrder={sortOrder}
              updateSortKey={handleSort}
            />
          ))}
        </>
      }
    </Row>
  );
};

export default TitleRow;

const Row = styled.div`
  position: relative;
  display: flex;
  border-radius: 5px 5px 0 0;
  background-color: ${p => p.theme.palette.tables.evenRow};
  border-bottom: 1px solid ${p => p.theme.palette.borders.medium};
  z-index: 2;
`;