import React, { RefObject, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import DeviceNoteAttachmentComponent from './DeviceNoteAttachment';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { useApi } from '@shared/hooks/useApi';
import { DeviceNoteAttachmentCreateCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteAttachmentCreateCommand';
import { DeviceNoteAttachmentDeleteCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteAttachmentDeleteCommand';
import { DeviceNoteAttachmentDownloadCommand } from '@dashboard/api/queries/deviceNotes/DeviceNoteAttachmentDownloadCommand';
import { BlobUri } from '@shared/api/models/Blob/BlobUri';
import { Modal } from '@shared/components/molecules/Modal/Modal';
import { FileDropzone } from '@shared/components/molecules/FileDropzone/FileDropzone';
import { alphabeticalSort } from '@shared/utils/StringUtils';
import { Button } from '@shared/components/atoms/Button/Button';
import DeviceNoteAttachment from '@dashboard/api/models/DeviceNoteAttachment';

interface IDeviceNoteAttachmentModal extends React.ComponentPropsWithoutRef<'div'> {
  modalRef: RefObject<HTMLDivElement>;
  open: boolean,
  hide: () => void;
  note: DeviceNote;
  refreshNotes: () => void;
}

const DeviceNoteAttachmentModal = ({ modalRef, open, hide, note, refreshNotes }: IDeviceNoteAttachmentModal) => {
  const { t } = useTranslation(['molecules']);
  const theme = useTheme();
  const { execute, loading } = useApi();
  const [file, setFile] = useState<File>();
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

  const sortedNotes = [...note.attachments].sort((a, b) => alphabeticalSort(a.fileName, b.fileName));

  const handleAttachments = (files: File[]) => {
    if (!files[0]) {
      setFile(undefined);
      setSubmitEnabled(false);
    }

    setFile(files[0]);
    setSubmitEnabled(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: handleAttachments,
    accept: '.txt, .pdf, .csv, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png, .pst, .eml, .msg'
  });


  const handleAttachmentUpload = async () => {
    if (note && file) {
      const response = await execute({
        query: new DeviceNoteAttachmentCreateCommand(note.id, file),
        errorMessage: t('DeviceCard.AttachmentUploadFailed', { ns: 'molecules' }),
        successMessage: t('DeviceCard.AttachmentUploadSuccess', { ns: 'molecules' }),
      });

      if (!response) {
        return;
      }

      setFile(undefined);
      setSubmitEnabled(false);
      refreshNotes();
    }
  };

  const handleAttachmentDelete = async (attachmentId: number) => {
    await execute({
      query: new DeviceNoteAttachmentDeleteCommand(attachmentId),
      successMessage: t('DeviceCard.AttachmentDeleted', { ns: 'molecules' }),
      errorMessage: t('DeviceCard.AttachmentDeleteFailed', { ns: 'molecules' }),
    });

    refreshNotes();
  }

  const downloadAttachment = (encodedFileString: BlobUri, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = `data:application/octet-stream;charset=utf-16le;base64,${encodedFileString}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  const handleAttachmentDownload = async (attachment: DeviceNoteAttachment) => {
    const response = await execute({
      query: new DeviceNoteAttachmentDownloadCommand(attachment.id)
    });

    if (!response) {
      return;
    }

    downloadAttachment(response.encodedFileString, response.fileName);
  }

  return (
    <Modal
      ref={modalRef}
      isOpen={open}
      title={t('DeviceCard.Attachments', { ns: 'molecules' })}
      hide={hide}
      width="500px"
    >
      {sortedNotes.length > 0 &&
        <AttachmentList>
          {sortedNotes.map(attachment => (
            <DeviceNoteAttachmentComponent key={attachment.id} fileName={attachment.fileName} onDelete={() => handleAttachmentDelete(attachment.id)} onDownload={() => handleAttachmentDownload(attachment)} />
          ))}
        </AttachmentList>
      }

      <Label>
        {t('DeviceCard.UploadNewAttachment', { ns: 'molecules' })}
      </Label>

      <DeviceNoteAttachmentModalContent>
        <FileDropzone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          onDelete={() => setFile(undefined)}
          fileName={file?.name}
          dimText={file ? '' : t('DeviceCard.UploadFile', { ns: 'molecules' })}
          containerStyle={{ height: '70px', boxShadow: `0 1px 3px 1px ${theme.palette.shadows.medium}` }}
        />

        <FlexRow>
          <Button
            tertiary
            label={t('Cancel', { ns: 'common' })}
            onClick={hide}
            color={theme.palette.red}
          />
          <Button
            tertiary
            label={t('DeviceCard.Upload', { ns: 'molecules' })}
            onClick={handleAttachmentUpload}
            disabled={!submitEnabled}
            loading={loading}
          />
        </FlexRow>
      </DeviceNoteAttachmentModalContent>
    </Modal>
  );
};

export default DeviceNoteAttachmentModal;

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
`;

const DeviceNoteAttachmentModalContent = styled.div`
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
`;

const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  max-height: 250px;
  overflow: auto;
  padding-bottom: 30px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.text.fair};
  padding-bottom: 7px;
`;