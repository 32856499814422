import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

const HeatmapConfigWindowPosition: IHeatmapConfig = {
  accessor: (x: string) => x,
  displayFormat: () => '',

  scale: {
    categoryScale: true,
    points: [
      {
        threshold: 'Open',
        label: 'Open',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 'Closed',
        label: 'Closed',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigWindowPosition);