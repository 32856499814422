import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import RangePicker, { TimeRange } from '@dashboard/components/atoms/RangePicker/RangePicker';
import { usePopover } from '@shared/hooks/usePopover';
import { Label } from '@shared/components/atoms/Form/Form';
import { Button } from '@shared/components/atoms/Button/Button';
import { Popover } from '@shared/components/atoms/Popover/Popover';
import { PopoverPlacement } from '@shared/components/atoms/Popover/Popover.types';
import PeopleCountingZone from '@settings/api/models/PeopleCounting/PeopleCountingZone';
import { ICheckboxSelectGroup } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect.types';
import { CheckboxSelect } from '@shared/components/atoms/CheckboxSelect/CheckboxSelect';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type PropTypes = {
  onChange: (timeRange: TimeRange, peopleCountingZoneIds: number[]) => void,
  peopleCountingZones: PeopleCountingZone[],
  loading?: boolean,
}

const ButtonWithPeopleCountZoneAndDateSelector = ({ onChange, peopleCountingZones, loading }: PropTypes) => {
  const theme = useTheme();
  const { visible, toggle, ref } = usePopover({ disableCloseOnClickOutside: true });
  const [timeRange, setTimeRange] = useState<TimeRange>();
  const [peopleCountingZoneIds, setPeopleCountingZoneIds] = useState<number[]>();
  const [zoneOptions, setZoneOptions] = useState<ICheckboxSelectGroup<number>[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setZoneOptions([{
      options: peopleCountingZones.map(zone => ({
        label: `${zone.virtualDeviceName} (${zone.site}/${zone.building})`,
        value: zone.virtualDeviceId,
        selected: peopleCountingZoneIds?.includes(zone.virtualDeviceId)
      }))
    }]);
  }, [peopleCountingZoneIds, peopleCountingZones]);

  const popoverContent = (
    <PopoverContent>
      <div>
        <Label>{t('Management.PeopleCountingZones', { ns: 'settingsPeopleCounting' })}</Label>
        <CheckboxSelect
          options={zoneOptions}
          onChange={setPeopleCountingZoneIds}
          placeholder={t('Management.SelectZones', { ns: 'settingsPeopleCounting' })}
        />
      </div>
      <div>
        <Label>{t('TimeRange', { ns: 'molecules' })}</Label>
        <RangePicker
          onChange={setTimeRange}
          style={{ width: '100%' }}
        />
      </div>
      <div>
        <HelperMessage>
          <WarningIcon icon={solid('exclamation-triangle')} />
          {t('Management.ExportWarningMessage', { ns: 'settingsPeopleCounting' })}
        </HelperMessage>
      </div>
      
      <FlexRow>
        <Button
          tertiary
          label={t('Cancel', { ns: 'molecules' })}
          onClick={toggle}
          color={theme.palette.text.weak}
        />
        <Button
          label={t('Export', { ns: 'molecules' })}
          disabled={!timeRange || !peopleCountingZoneIds}
          onClick={() => {
            if (timeRange && peopleCountingZoneIds) {
              onChange(timeRange, peopleCountingZoneIds);
              toggle();
              setTimeRange(undefined)
              setPeopleCountingZoneIds(undefined)
            }
          }}
          style={{ marginLeft: 'auto' }}
        />
      </FlexRow>
    </PopoverContent>
  );

  return (
    <Popover
      ref={ref}
      visible={visible}
      popoverContent={popoverContent}
      placement={PopoverPlacement.BottomRight}
      alignWithTarget
    >
      <Button
        label={t('Management.ExportEnterExitCounts', { ns: 'settingsPeopleCounting' })}
        loading={loading}
        onClick={toggle}
      />
    </Popover>
  );
};

export default ButtonWithPeopleCountZoneAndDateSelector;

const FlexRow = styled.div`
  display: flex;
  margin-top: 10px;
`;

const PopoverContent = styled.div`
  width: 300px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HelperMessage = styled.div`
  font-size: 12px;
  color: ${p => p.theme.palette.text.weak};
  margin: 8px 0 0 6px;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.orange};
  margin-right: 4px;
`;