import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Floor } from '@shared/api/models/Floor/Floor';
import { TopBarHeight, HorizontalNavbarHeight } from '@shared/theme/designConstants';
import { Card } from '@shared/components/molecules/Card/Card';

type PropTypes = {
  floors: Floor[],
  selectedFloorIndex: number,
  onFloorChange: (index: number) => void
}

const FloorSelectorSidebar = ({ floors, selectedFloorIndex, onFloorChange }: PropTypes) => {
  const { t } = useTranslation(['settingsAsset']);

  return (
    <Card
      cardTitle={t('Buildings.Floorplan.FloorplanSelector.Floor', { ns: 'settingsAsset' })}
      noPadding
      style={{
        width: '78px',
        marginTop: '52px',
        height: 'max-content',
        flexShrink: 0
      }}
    >
      <FloorSelector>
        {floors.map((floor, i) => (
          <IdentifierSquare key={i} selected={i === selectedFloorIndex} onClick={() => onFloorChange(i)}>
            {`${floor.floorNumber}`}
          </IdentifierSquare>
        ))}
      </FloorSelector>
    </Card>
  );
};

export default FloorSelectorSidebar;

const FloorSelector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px 0;

  height: max-content;
  min-height: 275px;
  max-height: ${`calc(100vh - ${TopBarHeight}px - ${HorizontalNavbarHeight}px - 200px)`};
  overflow: auto;
`;

const IdentifierSquare = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 40px;
  height: 32px;
  border: 1px solid ${p => p.theme.palette.borders.medium};
  border-radius: 3px;
  flex-shrink: 0;

  font-weight: 500;
  font-size: 14px;
  color: ${p => p.theme.palette.text.weak};
  cursor: pointer;

  ${p => p.selected && css`
    color: ${p => p.theme.palette.text.onPrimary};
    border-color: ${p => p.theme.palette.primary};
    background-color: ${p => p.theme.palette.primary};
    box-shadow: 0 7px 10px -4px ${p => p.theme.palette.shadows.medium};
  `}
`;