import ClimateControlHeatmapDto from '@shared/api/models/ClimateControl/Heatmap/ClimateControlHeatmapDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class ClimateControlGetHeatmapQuery extends GetQuery<ClimateControlHeatmapDto> {
  buildingId: string;

  constructor(buildingId: string) {
    super();
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return `/ClimateControl/Heatmap/${this.buildingId}`;
  }
}
