import { EnergyMeterTopologyDto } from '@shared/api/models/EnergyMeter/EnergyMeterTopologyDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class EnergyMetersGetByBuildingIdQuery extends GetQuery<EnergyMeterTopologyDto[]> {
  buildingId: number;

  constructor(buildingId: number) {
    super();
    this.buildingId = buildingId;
  }

  public targetUrl(): string {
    return `/energyMeters/GetByBuildingId/${this.buildingId}`;
  }
}
