import { ClimateControlFallbackConfig, SiteClimateControlDto } from '@shared/api/models/ClimateControl/Site/SiteClimateControlDto';
import SiteClimateControlUpdateFallbackSettingsCommand from '@shared/api/queries/ClimateControl/Site/SiteClimateControlUpdateFallbackSettingsCommand';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import { useApi } from '@shared/hooks/useApi';
import { isMatch } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClimateControlWidgetSavingBanner from '../ClimateControlWidgetSavingBanner';
import NoMotionConfiguration from './NoMotionConfiguration';
import OfflineSensorConfiguration from './OfflineSensorConfiguration';

type FallbackSettingsProps = {
  siteClimateControl: SiteClimateControlDto;
  refresh: () => void;
  onChange: (hasChanges: boolean) => void;
}

const FallbackSettings = ({ siteClimateControl, refresh, onChange }: FallbackSettingsProps) => {
  const { t } = useTranslation();
  const { execute } = useApi();
  const [initialConfig, setInitialConfig] = useState<ClimateControlFallbackConfig>();
  const [config, setConfig] = useState<ClimateControlFallbackConfig>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Setup initialConfig and config on first render
  useEffect(() => {
    const initialConfig: ClimateControlFallbackConfig = {
      noMotionRulesEnabled: siteClimateControl.noMotionRulesEnabled,
      noMotionThreshold1: siteClimateControl.noMotionThreshold1,
      noMotionThreshold1Temp: siteClimateControl.noMotionThreshold1Temp,
      noMotionThreshold2: siteClimateControl.noMotionThreshold2,
      noMotionThreshold2Temp: siteClimateControl.noMotionThreshold2Temp,
      externalSensorModeEnabled: siteClimateControl.externalSensorModeEnabled,
      controlMode: siteClimateControl.controlMode,
      offlineSensorThreshold: siteClimateControl.offlineSensorThreshold,
      offlineSensorTemp: siteClimateControl.offlineSensorTemp
    };

    setInitialConfig(initialConfig);
    setConfig({ ...initialConfig });
  }, [siteClimateControl]);

  // Check if the config has been modified whenever it is updated
  useEffect(() => {
    const hasBeenModified = !!config && !!initialConfig && !isMatch(config, initialConfig);

    onChange(hasBeenModified);
    setHasUnsavedChanges(hasBeenModified);
  }, [config, initialConfig, onChange]);

  // Reset the config to the initial values
  const handleReset = useCallback(() => {
    setConfig(initialConfig);
  }, [initialConfig]);

  // Save config
  const handleSave = useCallback(async () => {
    if (!config) {
      return
    }

    await execute({
      query: new SiteClimateControlUpdateFallbackSettingsCommand(siteClimateControl.id, config),
      successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    refresh();
  }, [siteClimateControl, config, execute, refresh, t]);

  if (!config) {
    return <Loading />;
  }

  return (
    <Card>
      <Title text={t('ClimateControl.FallbackTemperature', { ns: 'molecules' })} />

      <OfflineSensorConfiguration
        config={config}
        onChange={setConfig}
      />

      <NoMotionConfiguration
        config={config}
        onChange={setConfig}
      />

      <ClimateControlWidgetSavingBanner
        onSave={handleSave}
        onCancel={handleReset}
        isVisible={hasUnsavedChanges}
      />
    </Card>
  );
};

export default FallbackSettings;