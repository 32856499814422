import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const FloorplanNoData = () => {
  const { t } = useTranslation();

  return (
    <FlexColumn>
      <Icon
        icon={duotone('grid-horizontal')}
      />

      <Label>
        {t('NoData', { ns: 'status' })}
      </Label>

      <HelpText>
        <Trans i18nKey='molecules:Floorplan.NoDataHelperText'>
          <br />
        </Trans>
      </HelpText>
    </FlexColumn>
  );
};

export default FloorplanNoData;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 45px 32px 50px 32px;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 58px;
  height: 58px;
  color: ${p => p.theme.palette.orange};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${p => p.theme.palette.text.medium};
`;

const HelpText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
  text-align: center;
`;