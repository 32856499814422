import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RolesGetAllQuery from '@settings/api/queries/Roles/RolesGetAllQuery';
import UserDeleteCommand from '@settings/api/queries/Users/UserDeleteCommand';
import UserUpdateCommand from '@settings/api/queries/Users/UserUpdateCommand';
import UsersGetAllQuery from '@settings/api/queries/Users/UsersGetAllQuery';
import { UserSummary } from '@shared/api/models/User/UserSummary';
import { Button } from '@shared/components/atoms/Button/Button';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { Switch } from '@shared/components/atoms/Switch/Switch';
import { Title } from '@shared/components/atoms/Title/Title';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { useApi } from '@shared/hooks/useApi';
import { useApiState } from '@shared/hooks/useApiState';
import { TopBarHeight } from '@shared/theme/designConstants';
import { includesCI } from '@shared/utils/StringUtils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import UserListDeleteDialog from './UserListDeleteDialog';
import UserListProfileImage from './UserListProfileImage';
import UserListRoleSelect from './UserListRoleSelect';

const UserList = () => {
  const { t } = useTranslation(['settingsUser']);
  const theme = useTheme();
  const navigate = useNavigate();
  const { isSuperAdmin } = useUserContext();
  const { execute, loading: deleteInProgress } = useApi();
  const [searchString, setSearchString] = useState<string>('');

  const { data: users, execute: refreshUsers, loading: loadingUsers } = useApiState({
    query: new UsersGetAllQuery(),
    initialState: [],
  }, []);

  const { data: allRoles, loading: loadingRoles } = useApiState({
    query: new RolesGetAllQuery(),
    initialState: []
  }, []);

  const searchFilter = useCallback((user: UserSummary) => {
    const roleName = allRoles.find(x => x.id === user.roleId)?.name;

    return includesCI(user.fullName, searchString)
      || includesCI(user.email, searchString)
      || (roleName ? includesCI(roleName, searchString) : false)
  }, [allRoles, searchString]);

  const deleteUser = async (userId: number) => {
    await execute({
      query: new UserDeleteCommand(userId),
      successMessage: t('List.UserDeleted', { ns: 'settingsUser' }),
      errorMessage: t('List.UserDeleteFailed', { ns: 'settingsUser' })
    });

    refreshUsers();
  }

  const updateMfa = async (user: UserSummary) => {
    const isEnabled = !user.mfaEnabled;

    await execute({
      query: new UserUpdateCommand(user.id, user.fullName, user.phoneNumber, isEnabled, user.mfaMethod, user.displayName),
      successMessage: isEnabled ? t('MfaEnabled', { ns: 'status' }) : t('MfaDisabled', { ns: 'status' }),
      errorMessage: t('ChangesSaveError', { ns: 'status' })
    });

    refreshUsers();
  };

  const tableColumns: ITableColumn<UserSummary>[] = [
    {
      width: 3,
      label: t('List.Name', { ns: 'settingsUser' }),
      key: 'fullName',
      customElement: (user) => <NameCol onClick={() => navigate(`${user.id}`)}><UserListProfileImage blobName={user.profileImageId} /> {user.fullName}</NameCol>,
    },
    {
      width: 3,
      label: t('List.Email', { ns: 'settingsUser' }),
      key: 'email',
    },
    {
      label: t('List.Role', { ns: 'settingsUser' }),
      key: 'roleId',
      fixedWidth: '225px',
      hideWithBreakpoint: true,
      sortFormat: (user) => allRoles.find(x => x.id === user.roleId)?.displayName,
      customElement: (user) => <UserListRoleSelect user={user} roles={allRoles} refreshUsers={refreshUsers} />
    },
    {
      label: t('MFA', { ns: '' }),
      key: 'mfaEnabled',
      fixedWidth: '70px',
      disableSort: true,
      hideWithBreakpoint: true,
      hidden: !isSuperAdmin,
      customElement: (user) => <Switch checked={user.mfaEnabled} onChange={() => updateMfa(user)} />
    },
    {
      key: 'actions',
      rightAlign: true,
      customElement: (user) => (
        <>
          <NavLink to={`${user.id}`}>
            <Button
              tertiary
              circle
              label={<FontAwesomeIcon icon={solid('edit')} style={{ width: '18px', height: '18px' }} />}
            />
          </NavLink>
          <UserListDeleteDialog
            user={user}
            onDelete={deleteUser}
            deleteInProgress={deleteInProgress}
          />
        </>
      ),
    },
  ];

  return (
    <PaddedContainer>
      <FlexContainer>
        <Title text={<>{t('Users', { ns: 'common' })} <ThemeColored>({users.length})</ThemeColored></>} />

        <RightAligned>
          <SearchField
            placeholder={t('List.SearchUsers', { ns: 'settingsUser' })}
            onSearchChange={setSearchString}
            style={{ background: theme.action.filled }}
          />
          <Link to={'create'}>
            <Button
              tertiary
              circle
              label={<FontAwesomeIcon icon={solid('plus')} style={{ width: '18px', height: '18px' }} />}
            />
          </Link>
        </RightAligned>
      </FlexContainer>

      <Table
        columns={tableColumns}
        records={users}
        recordKey='id'
        defaultSortColumn="fullName"
        onRowClick={(row: UserSummary) => { navigate(`${row.id}`) }}
        emptyMessage={t('NoUsersFound', { ns: 'status' })}
        highlightString={searchString}
        filters={[searchFilter]}
        cardEffect
        fullHeightSubtractor={TopBarHeight + 250}
        wrapBreakpoint={510}
        hideBreakpoint={850}
        loading={loadingUsers || loadingRoles}
      />
    </PaddedContainer >
  );
}

export default UserList;

const FlexContainer = styled.div` 
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const RightAligned = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: auto;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;

const NameCol = styled.div` 
  display: flex;
  align-items: center;
`;
