import React, { ReactNode, useContext, useEffect, useMemo } from 'react';
import { useTenantContext } from '../TenantContext/TenantContext';
import { useUserContext } from '../UserContext/UserContext';
import mixpanel from 'mixpanel-browser';
import { GenericObject } from '@shared/utils/ObjectUtils';
import { getCookieConsent } from '@shared/components/molecules/ConsentBanner/ConsentBanner';
import { env } from '@shared/env/env';

const IS_INITIALISED = !!env.analytics.mixpanelToken;
const DEBUG_MODE_ENABLED = env.analytics.debugModeEnabled;

mixpanel.init(env.analytics.mixpanelToken, { track_pageview: false, opt_out_tracking_by_default: true, debug: DEBUG_MODE_ENABLED });

const register = (props: GenericObject) => {
  if (IS_INITIALISED) {
    mixpanel.register(props);
  }
};

const track = (props: GenericObject, event_name: AnalyticsEvent) => {
  if (IS_INITIALISED) {
    mixpanel.track(event_name, { ...props });
  }
};

const trackAction = (name: string, category: string, props?: GenericObject) => {
  track({ button: name, button_category: category, ...props }, AnalyticsEvent.ButtonClick);
};

const trackPage = (name: string, category: string, props?: GenericObject) => {
  track({ page_name: name, page_category: category, ...props }, AnalyticsEvent.PageView);
  register({ page_name: name, page_category: category });
};

export enum AnalyticsEvent {
  ButtonClick = 'button_clicked',
  PageView = 'page_viewed'
}

interface IAnalyticsContext {
  trackAction: (button: string, category: string, props?: GenericObject) => void;
  trackPage: (page: string, category: string, props?: GenericObject) => void;
  register: (props: GenericObject) => void;
}

export const AnalyticsContext = React.createContext({} as IAnalyticsContext);
export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children }: { children?: ReactNode }) => {
  const { tenant } = useTenantContext();
  const { user, claims, account, isSuperAdmin } = useUserContext();

  // Disable tracking when not consented to by the user, or for super-admins unless debug mode is enabled
  const optOut = !getCookieConsent()?.analytical || (isSuperAdmin && !DEBUG_MODE_ENABLED);

  useEffect(() => {
    if (!IS_INITIALISED) {
      return;
    }

    if (optOut) {
      mixpanel.opt_out_tracking();
      return;
    }

    // Opt in to tracking
    mixpanel.opt_in_tracking();

    // Identify as the logged-in user and create profile (if it doesn't exist already)
    mixpanel.identify(account.localAccountId);
    mixpanel.people.set({ role: claims.role });

    // Set current tenant and user_role as a super property
    mixpanel.register({ tenant: tenant.name, tenantId: tenant.tenantId, user_role: claims.role });

    return () => mixpanel.reset();
  }, [account.localAccountId, claims.role, tenant, user, optOut]);

  const contextValue = useMemo(() => ({
    trackAction,
    trackPage,
    register
  }), []);

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};
