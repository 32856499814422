import { useCallback, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import styled, { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Device } from '@shared/api/models/Device/Device';
import { ICascaderMultiSelectNodeGroup } from '@shared/components/atoms/CascaderMultiSelect/CascaderMultiSelect.types';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { Table } from '@shared/components/molecules/Table/Table';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApiState } from '@shared/hooks/useApiState';
import { HorizontalNavbarHeight, TopBarHeight } from '@shared/theme/designConstants';
import { includesCI } from '@shared/utils/StringUtils';
import { Title } from '@shared/components/atoms/Title/Title';
import DeviceModelFilter from '@src/components/shared/Filters/DeviceModelFilter';
import DeviceTypeFilter from '@src/components/shared/Filters/DeviceTypeFilter';
import { LocationFilter } from '@src/components/shared/Filters/LocationFilter/LocationFilter';
import { HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';
import SitesGetAllWithHierarchyQuery from '@settings/api/queries/Sites/SitesGetAllWithHierarchyQuery';
import { Button } from '@shared/components/atoms/Button/Button';

type DeviceWithHierarchy = Device & {
  siteId: number;
  siteName: string;
  buildingName: string;
}

const DeviceListTenantLevel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { getDisplayString, getDeviceType } = useDeviceConfigContext();
  const [cascaderOptions, setCascaderOptions] = useState<ICascaderMultiSelectNodeGroup<HierarchySpace>>();
  const [searchString, setSearchString] = useState<string>('');
  const [devices, setDevices] = useState<DeviceWithHierarchy[]>([]);
  const [resetPulse, setResetPulse] = useState<number>(0);
  const [tableRecordCount, setTableRecordCount] = useState<number>();
  const [spaceFilter, setSpaceFilter] = useState<HierarchySpace[] | undefined>([]);
  const [modelFilter, setModelFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const { data: sites, loading } = useApiState({
    query: new SitesGetAllWithHierarchyQuery(),
    initialState: [],
  }, []);

  const hasFilters = useMemo(() => (
    modelFilter.length > 0 || typeFilter.length > 0 || spaceFilter
  ), [modelFilter, typeFilter, spaceFilter]);

  const tableColumns: ITableColumn<Device>[] = useMemo(() => ([
    {
      label: t('DeviceManagement.FriendlyName', { ns: 'settingsDevice' }),
      key: 'friendlyName'
    },
    {
      label: t('DeviceManagement.DeviceModel', { ns: 'settingsDevice' }),
      key: 'deviceModel',
      displayFormat: ({ deviceModel }) => getDisplayString(deviceModel)
    },
    {
      label: t('DeviceManagement.DeviceIdentifier', { ns: 'settingsDevice' }),
      key: 'deviceIdentifier'
    },
    {
      label: t('Site', { ns: 'settingsDevice' }),
      key: 'siteName'
    },
    {
      label: t('Building', { ns: 'common' }),
      key: 'buildingName'
    },
    {
      label: t('Space', { ns: 'common' }),
      key: 'spaceName'
    }
  ]), [getDisplayString, t]);

  useEffect(() => {
    const options: ICascaderMultiSelectNodeGroup<HierarchySpace> = {
      header: t('DeviceManagement.Sites', { ns: 'settingsDevice' }),
      nodes: orderBy(sites, x => x.name.toLocaleLowerCase()).map(site => ({
        label: `${site.name}`,
        childGroup: {
          header: t('DeviceManagement.Buildings', { ns: 'settingsDevice' }),
          nodes: orderBy(site.buildings, x => x.name.toLocaleLowerCase()).map(building => ({
            label: `${building.name}`,
            childGroup: {
              header: t('DeviceManagement.Floors', { ns: 'settingsDevice' }),
              nodes: orderBy(building.floors, x => x.floorNumber).map(floor => ({
                label: `${floor.name}`,
                childGroup: {
                  header: t('DeviceManagement.Spaces', { ns: 'settingsDevice' }),
                  nodes: orderBy(floor.spaces, x => x.name.toLocaleLowerCase()).map(space => ({
                    label: `${space.name}`,
                    value: space
                  }))
                }
              }))
            }
          }))
        }
      }))
    }

    const devices: DeviceWithHierarchy[] = sites.flatMap(site =>
      site.buildings.flatMap(b =>
        b.floors.flatMap(f =>
          f.spaces.flatMap(s =>
            s.devices.map(d =>
              ({ ...d, spaceName: s.name, buildingName: b.name, siteId: site.id, siteName: site.name })
            )
          )
        )
      )
    );

    setDevices(devices);
    setCascaderOptions(options);
  }, [sites, t]);

  const searchFilter = useCallback((x: Device) => {
    return includesCI(x.friendlyName, searchString)
      || includesCI(x.deviceModel, searchString)
      || includesCI(x.deviceIdentifier, searchString)
      || includesCI(x.spaceName, searchString)
  }, [searchString]);

  const locationFilter = useCallback((x: Device) => {
    return (spaceFilter && x.spaceId) ? spaceFilter.map(x => x.id).includes(x.spaceId) : true;
  }, [spaceFilter]);

  const deviceModelFilter = useCallback((x: Device) => {
    return modelFilter.length > 0 ? modelFilter.includes(x.deviceModel) : true;
  }, [modelFilter]);

  const deviceTypeFilter = useCallback((x: Device) => {
    return typeFilter.length > 0 ? typeFilter.includes(getDeviceType(x.deviceModel)) : true;
  }, [typeFilter, getDeviceType]);

  const deviceModelOptions = useMemo(() => (
    devices.map(x => x.deviceModel)
  ), [devices]);

  return (
    <PaddedContainer largePadding>
      <FlexContainer>
        <Title
          text={
            <>
              {t('Devices', { ns: 'common' })} {tableRecordCount !== undefined && <ThemeColored>({tableRecordCount})</ThemeColored>}
            </>
          }
        />

        <RightAligned>
          <FlexRow>
            {hasFilters &&
              <Button
                tertiary
                label='Clear filters'
                onClick={() => setResetPulse(prev => prev + 1)}
              />
            }

            <DeviceModelFilter
              deviceModels={deviceModelOptions}
              onChange={setModelFilter}
              resetPulse={resetPulse}
            />

            <DeviceTypeFilter
              devices={devices}
              onChange={setTypeFilter}
              resetPulse={resetPulse}
            />

            {cascaderOptions &&
              <LocationFilter
                label={t('Space', { ns: 'common' })}
                group={cascaderOptions}
                onChange={setSpaceFilter}
                resetPulse={resetPulse}
              />
            }

            <SearchField
              placeholder={t('DeviceManagement.SearchDevices', { ns: 'settingsDevice' })}
              onSearchChange={setSearchString}
              style={{ background: theme.action.filled }}
            />
          </FlexRow>
        </RightAligned>
      </FlexContainer>

      <Table
        columns={tableColumns}
        records={devices}
        recordKey="id"
        emptyMessage={t('DeviceManagement.NoDevicesFound', { ns: 'settingsDevice' })}
        defaultSortColumn="friendlyName"
        onRowClick={(device: DeviceWithHierarchy) => { navigate(`/site/${device.siteId}/settings/devices/${device.id}`) }}
        loading={loading}
        cardEffect
        highlightString={searchString}
        filters={[searchFilter, locationFilter, deviceModelFilter, deviceTypeFilter]}
        fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 200}
        onRecordCountChange={setTableRecordCount}
      />
    </PaddedContainer>
  );
}

export default DeviceListTenantLevel;

const FlexContainer = styled.div` 
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  row-gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const FlexRow = styled.div` 
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const RightAligned = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-left: auto;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;