import styled, { css } from 'styled-components';

export const Square = styled.div <{ size: string, color?: string }>`
  position: relative;
  width: ${p => p.size};
  height: ${p => p.size};
  border-radius: 3px;
  border: 1px solid ${p => p.theme.palette.borders.weak};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  ${p => p.color && css`
    background-color: ${p.color};
    border: none;
  `}
`;