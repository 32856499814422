import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { OperatorPatchQuery } from '@shared/api/queries/ResidentApp/common/OperatorPatchQuery';

export class ChallengesResidentAppUpdateInfoCommand extends OperatorPatchQuery<OperatorChallengeDto> {
  id: number;
  title: string;
  shortTitle: string;
  description: string;
  metricType: MetricType;
  targetValue: number;
  startDate: string;
  endDate: string;
  siteId: number;
  imageId: string;

  constructor(
    id: number,
    title: string,
    shortTitle: string,
    description: string,
    metricType: MetricType,
    targetValue: number,
    startDate: string,
    endDate: string,
    siteId: number,
    imageId: string
  ) {
    super();
    this.id = id;
    this.title = title;
    this.shortTitle = shortTitle;
    this.description = description;
    this.metricType = metricType;
    this.targetValue = targetValue;
    this.startDate = startDate;
    this.endDate = endDate;
    this.siteId = siteId;
    this.imageId = imageId;
  }

  public targetUrl(): string {
    return '/Challenges/Update';
  }
}
