import { Cluster } from '@shared/api/models/Space/Cluster';
import { ClustersAndSpaces } from '@shared/api/models/Space/ClustersAndSpaces';
import { Space } from '@shared/api/models/Space/Space';
import { PostQuery } from '@shared/api/queries/common/PostQuery';

export default class BulkSpaceCreateCommand extends PostQuery<ClustersAndSpaces> {
  spaces: Space[];
  buildingId: number;
  floorNumber: number;
  clusters?: Cluster[];

  constructor(spaces: Space[], buildingId: number, floorNumber: number, clusters?: Cluster[]) {
    super();
    this.spaces = spaces;
    this.buildingId = buildingId;
    this.floorNumber = floorNumber;
    this.clusters = clusters;
  }

  public targetUrl(): string {
    return '/spaces/bulkCreateClustersAndSpaces';
  }
}