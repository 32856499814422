import { OperatorDeleteQuery } from '@shared/api/queries/ResidentApp/common/OperatorDeleteQuery';
import { OperatorUserDto } from '@shared/api/models/ResidentApp/OperatorUserDto';

export default class UsersResidentAppUpdateCommand extends OperatorDeleteQuery<OperatorUserDto> {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }

  public targetUrl(): string {
    return '/Users/Delete';
  }
}
