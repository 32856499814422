import { SpaceClimateControlDetailsDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDetailsDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export class SpaceClimateControlDetailsGetBySiteQuery extends GetQuery<SpaceClimateControlDetailsDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/ClimateControl/spaces/details?siteId=${this.siteId}`;
  }
}
