import { BlobName } from '@shared/api/models/Blob/BlobName';
import { PostFormDataQuery } from '@shared/api/queries/common/PostFormDataQuery';

export default class FloorplanUploadCommand extends PostFormDataQuery<BlobName> {

  constructor(file: File, floorId: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('floorId', floorId.toString());

    super(formData);
  }

  public targetUrl(): string {
    return '/floors/floorplan';
  }
}
