import { useNavigate, useParams } from 'react-router';
import { format, formatDistance } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Building } from '@shared/api/models/Building/Building';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import useCsvExport from '@dashboard/hooks/useCsvExport';
import { IModalListSelectGroup } from '@shared/components/molecules/ModalListSelect/ModalListSelect.types';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { useApiState } from '@shared/hooks/useApiState';
import { DevicesGetByBuildingIdDeviceTypeQuery } from '@dashboard/api/queries/devices/DevicesGetByBuildingIdDeviceTypeQuery';
import { DeviceType } from '@shared/api/enums/DeviceType/DeviceType';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { DeviceDetails } from '@shared/api/models/Device/DeviceDetails';
import CsvDevicesWithMetricsGetByBuildingAndMetricTypesQuery from '@dashboard/api/queries/exports/CsvDevicesWithMetricsGetByBuildingAndMetricTypesQuery';
import { TimeRange } from '@dashboard/components/atoms/RangePicker/RangePicker';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import ButtonWithRangePicker from '../ButtonWithRangePicker/ButtonWithRangePicker';
import { Table } from '@shared/components/molecules/Table/Table';
import { BreadcrumbHeight, TopBarHeight } from '@src/constants/LayoutConstants';
import { alphabeticalSort } from '@shared/utils/StringUtils';
import CsvDevicesWithLastReadingGetQuery from '@dashboard/api/queries/exports/CsvDevicesWithLastReadingGetQuery';
import { Metric } from '@shared/api/models/Metric/Metric';
import { ThemeColored } from '@src/components/shared/ThemeColoredSpan/ThemeColoredSpan';

type PropTypes = {
  building: Building
}

const Devices_Metering = ({ building }: PropTypes) => {
  const { t } = useTranslation(['enums']);
  const navigate = useNavigate();
  const { getDisplayString } = useDeviceConfigContext();
  const { buildingId } = useParams<{ buildingId: string }>();
  const { handleCsvExport: exportDevices, loading: loadingDevicesExport } = useCsvExport();
  const { handleCsvExport: exportMeasurements, loading: loadingMeasurementsExport } = useCsvExport();
  const [selectedMetricTypes, setSelectedMetricTypes] = useState<MetricType[]>([])
  const [metricSelectOptions, setMetricSelectOptions] = useState<IModalListSelectGroup<MetricType>[]>([])
  const { data: devices, loading } = useApiState({
    query: buildingId === undefined ? undefined : new DevicesGetByBuildingIdDeviceTypeQuery(parseInt(buildingId), DeviceType.Metering),
    initialState: null
  }, [buildingId]);

  // TODO: [PP] Remove the filter that excludes "Cooling" metrics when these are fully integrated into the platform
  useEffect(() => {
    const metricSelectOptions: IModalListSelectGroup<MetricType>[] = [
      {
        subGroups: [{
          options: devices?.metricTypes
            .sort((a, b) => alphabeticalSort(a, b))
            .map(x => (
              {
                label: t(x, { ns: 'enums' }),
                value: x
              })) ?? []
        }]
      }
    ];

    setMetricSelectOptions(metricSelectOptions);
  }, [devices, t]);

  const getLastReading = useCallback((readings: Metric[]) => {
    let message = t('DeviceCard.NoRecentReading', { ns: 'molecules' });
    let suffix = '';

    const lastReading = readings.find(x => x.metricType === MetricType.HeatingKwh
      || x.metricType === MetricType.ElectricityKwh
      || x.metricType === MetricType.WaterVolume
      || x.metricType === MetricType.CoolingKwh
      || x.metricType === MetricType.GasVolume);

    if (lastReading !== undefined) {
      message = lastReading.value;
      suffix = lastReading.metricType === MetricType.WaterVolume || lastReading.metricType === MetricType.GasVolume ? 'm³' : 'kWh';
    }

    return {
      Message: message,
      MetricTypeSuffix: suffix
    };
  }, [t]);

  const tableColumns: ITableColumn<DeviceDetails>[] = useMemo(() => ([
    {
      label: t('DeviceCard.Name', { ns: 'molecules' }),
      key: 'friendlyName',
      width: 3
    },
    {
      label: t('DeviceCard.DeviceModel', { ns: 'molecules' }),
      key: 'deviceModel',
      width: 2,
      displayFormat: ({ deviceModel }) => getDisplayString(deviceModel),
    },
    {
      label: t('DeviceCard.DeviceIdentifier', { ns: 'molecules' }),
      key: 'deviceIdentifier',
      width: 2,
    },
    {
      label: t('DeviceCard.Space', { ns: 'molecules' }),
      key: 'spaceName',
      width: 1
    },
    {
      label: t('DeviceCard.LastMeasuredOn', { ns: 'molecules' }),
      key: 'lastMeasuredOn',
      width: 2,
      sortFormat: device => device.lastMeasuredOn && new Date(device.lastMeasuredOn),
      displayFormat: device => device.lastMeasuredOn ? format(new Date(device.lastMeasuredOn), 'dd MMM yyyy') : 'Never',
      displaySuffix: device => device.lastMeasuredOn ? `(${formatDistance(new Date(device.lastMeasuredOn), new Date(), { addSuffix: true })})` : '',
    },
    {
      label: t('DeviceCard.LastReading', { ns: 'molecules' }),
      key: 'metrics',
      width: 2,
      displayFormat: device => getLastReading(device.metrics).Message,
      displaySuffix: device => getLastReading(device.metrics).MetricTypeSuffix,
    }
  ]), [getDisplayString, getLastReading, t]);

  const handleDevicesExport = async () => {
    if (!buildingId) {
      return;
    }

    const query = new CsvDevicesWithLastReadingGetQuery(parseInt(buildingId), DeviceType.Metering);
    const fileName = `${building.name}_Metering_Devices.csv`
    exportDevices(query, fileName);
  };

  const handleMeasurementsExport = async (timeRange: TimeRange) => {
    if (!buildingId) {
      return;
    }

    const query = new CsvDevicesWithMetricsGetByBuildingAndMetricTypesQuery(parseInt(buildingId), selectedMetricTypes, timeRange.from.toJSON(), timeRange.to.toJSON());
    const fileName = `${building.name}_Metering_Measurements_${format(timeRange.from, 'yyyyMMdd')}-${format(timeRange.to, 'yyyyMMdd')}.csv`;
    exportMeasurements(query, fileName);

    setSelectedMetricTypes([]);
  };

  return (
    <>
      <BackButton
        url='./..'
        label={t('BackToDevices', { ns: 'navigation' })}
      />

      <FlexRow>
        <Title text={<>{t('DeviceCard.MeteringDevices', { ns: 'molecules' })} <ThemeColored>({devices?.devices.length})</ThemeColored></>} />

        <Button
          label={t('DeviceCard.ExportDevices', { ns: 'molecules' })}
          onClick={handleDevicesExport}
          loading={loadingDevicesExport}
          style={{ marginLeft: 'auto' }}
        />
        <ButtonWithRangePicker
          label={t('DeviceCard.ExportMeasurements', { ns: 'molecules' })}
          onRangeChange={handleMeasurementsExport}
          loading={loadingMeasurementsExport}
          metricSelectOptions={metricSelectOptions ?? []}
          onMetricSelectChange={setSelectedMetricTypes}
        />
      </FlexRow>

      <Table
        columns={tableColumns}
        records={devices?.devices ?? []}
        recordKey="deviceId"
        emptyMessage={t('DeviceCard.NoDevicesFound', { ns: 'molecules' })}
        defaultSortColumn="friendlyName"
        onRowClick={(row: DeviceDetails) => { navigate(`./../../../floor/${row.floorId}/space/${row.spaceId}/device/${row.deviceId}`) }}
        loading={loading}
        cardEffect
        fullHeightSubtractor={TopBarHeight + BreadcrumbHeight + 370}
      />
    </>
  );
}

export default Devices_Metering;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;