import { useSize } from '@shared/hooks/useSize';
import { useEffect, useRef, useState } from 'react';

export const useNumColumns = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { domRect: wrapperDomRect } = useSize(ref);
  const [numColumns, setNumColumns] = useState<number>(2);

  /**
   * Observe the width of the target element
   */
  useEffect(() => {
    if (wrapperDomRect) {
      const width = wrapperDomRect.width;

      if (width >= 1500) {
        setNumColumns(4);
      }

      if (width < 1500 && width >= 1100) {
        setNumColumns(3);
      }

      if (width < 1100 && width >= 700) {
        setNumColumns(2);
      }

      if (width < 700) {
        setNumColumns(1);
      }
    }
  }, [wrapperDomRect]);

  return {
    ref,
    numColumns
  };
};