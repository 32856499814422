import UnoccupiedSpaceDto from '@dashboard/api/models/UnoccupiedSpaceDto';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class UnoccupiedSpacesWithMetricsGetQuery extends GetQuery<UnoccupiedSpaceDto[]> {
  siteId: number;
  metricTypes?: MetricType[];

  constructor(siteId: number, metricTypes?: MetricType[]) {
    super();
    this.siteId = siteId;
    this.metricTypes = metricTypes;
  }

  public targetUrl(): string {
    const queryParams = this.metricTypes?.map(x => `&metricTypes=${x}`).join('') ?? '';
    return `/metrics/UnoccupiedSpacesWithMetrics?siteId=${this.siteId}${queryParams}`;
  }
} 