import Filter from './Filter/Filter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from '@shared/api/models/Device/Device';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { orderBy } from 'lodash';

type PropTypes = {
  devices: Device[];
  onChange: (selected: string[]) => void;
  resetPulse?: number;
}

const DeviceTypeFilter = ({ devices, onChange, resetPulse }: PropTypes) => {
  const { t } = useTranslation();
  const { getDeviceType } = useDeviceConfigContext();

  const uniqueTypes = useMemo(() => Array.from(new Set(devices.map(x => getDeviceType(x.deviceModel)))), [devices, getDeviceType]);
  const options = useMemo(() => orderBy(uniqueTypes, x => x).map(x => ({ label: t(`DeviceTypes.${x}`, { ns: 'enums' }), value: x })), [uniqueTypes, t]);

  return (
    <Filter
      label={t('DeviceType', { ns: 'common' })}
      options={options}
      onChange={onChange}
      resetPulse={resetPulse}
    />
  );
};

export default DeviceTypeFilter;