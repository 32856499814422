import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { TFunction } from 'i18next';
import { DefaultTheme } from 'styled-components';

export type WeatherIconColors = {
  primary: string;
  secondary: string;
}

export type WeatherObject = {
  description: string;
  icon: IconDefinition;
  colors: WeatherIconColors;
}

/**
 * Get weather icon
 */
export const getWeatherObject = (t: TFunction, theme: DefaultTheme, weatherCode?: string,): WeatherObject | undefined => {
  switch (weatherCode) {
    case 'Thunder':
      return { description: t('Weather.Thunder', { ns: 'enums' }), icon: duotone('cloud-bolt'), colors: { primary: theme.action.active, secondary: theme.complimentary.orange.dark } };
    case 'Drizzle':
      return { description: t('Weather.Drizzle', { ns: 'enums' }), icon: duotone('cloud-drizzle'), colors: { primary: theme.action.active, secondary: theme.complimentary.blue.dark } };
    case 'LightRain':
    case 'HeavyRain':
      return { description: t('Weather.Rain', { ns: 'enums' }), icon: duotone('cloud-rain'), colors: { primary: theme.action.active, secondary: theme.complimentary.blue.dark } };
    case 'LightSnow':
    case 'HeavySnow':
    case 'Sleet':
    case 'LightSnowShowerDay':
    case 'HeavySnowShowerDay':
      return { description: t('Weather.Snow', { ns: 'enums' }), icon: duotone('snowflakes'), colors: { primary: theme.complimentary.blue.dark, secondary: theme.complimentary.blue.dark } };
    case 'Fog':
      return { description: t('Weather.Fog', { ns: 'enums' }), icon: duotone('cloud-fog'), colors: { primary: theme.action.active, secondary: theme.action.active } };
    case 'ClearNight':
      return { description: t('Weather.Clear', { ns: 'enums' }), icon: duotone('sun'), colors: { primary: theme.complimentary.orange.dark, secondary: theme.complimentary.orange.dark } };
    case 'Cloudy':
      return { description: t('Weather.Cloudy', { ns: 'enums' }), icon: duotone('clouds'), colors: { primary: theme.action.active, secondary: theme.action.active } };
    case 'Overcast':
      return { description: t('Weather.Overcast', { ns: 'enums' }), icon: duotone('clouds'), colors: { primary: theme.action.active, secondary: theme.action.active } };
    default:
      return;
  }
};

/**
 * Get wind direction label based on degrees
 */
export const getWindDirectionLabel = (degrees: number) => {
  const segment = Math.round(degrees / 22.5) % 16;
  const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];

  return directions[segment];
}