import { DefaultTheme } from 'styled-components';

export interface IMapOptions {
  /**
   * When set to true, markers show tooltips when hovered and can be clicked.
   */
  interactive?: boolean,
  /**
   * auto: The default gestureHandling value auto switches between greedy and cooperative based upon whether the map is contained within an <iframe>.
   * none: Disable gestures on the map.
   * cooperative: Allowing the user to scroll the page normally, without zooming or panning the map. Users can zoom the map by clicking the zoom controls or pressing Ctrl.
   * greedy: Map reacts to all touch gestures and scroll events unlike cooperative.
   */
  gestureHandling?: 'auto' | 'none' | 'cooperative' | 'greedy',
}

/**
 * Default center of the map (used when no asset/marker is present), currently these coordinates are the center of the UK.
 */
export const MAP_DEFAULT_CENTER = {
  lat: 54.2579,
  lng: -4.1398
};

/**
 * Default zoom level (in conjunction with the MAP_DEFAULT_CENTER the map shows the whole of the UK).
 */
export const MAP_DEFAULT_ZOOM = 6;

/**
 * Default zoom level when there is only one asset/marker on the map.
 */
export const MAP_DEFAULT_ZOOM_FOR_SINGLE_MARKER = 11;

/**
 * Custom map styles
 */
export const getMapStyles = (theme: DefaultTheme): google.maps.MapTypeStyle[] => [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [
      { 'visibility': 'off' }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      { color: theme.palette.map.road },
      { 'visibility': 'on' }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      { color: theme.palette.map.transit },
      { 'visibility': 'on' }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      { color: theme.palette.map.landscape },
      { 'visibility': 'on' }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      { color: theme.palette.map.water },
      { 'visibility': 'on' }
    ]
  },
];

/**
 * Map options
 */
export const getMapOptions = (theme: DefaultTheme, options?: IMapOptions): google.maps.MapOptions => ({
  styles: getMapStyles(theme),
  mapTypeControl: false,
  gestureHandling: options?.gestureHandling,
  zoomControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  minZoom: 2,
  maxZoom: 16,
});