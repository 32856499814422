/**
 * Rounds a number to the specified precision (default: 0).
 */
export const round = (value: number, precision = 0): number => {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}

/**
 * Parses a string to a float and rounds it to the specified precision (if precision argument is provided).
 */
export const stringToFloat = (value: string, precision?: number): number => {
  const numeric = parseFloat(value);
  return precision === undefined ? numeric : round(numeric, precision);
}

/**
 * Parses a string to a float/percentage and rounds it to the specified precision (if precision argument is provided).
 */
export const stringToPercentage = (value: string, precision?: number): number => {
  let numeric = parseFloat(value);
  numeric = (numeric * 100);
  return precision === undefined ? numeric : round(numeric, precision);
}

/**
 * Display number with two decimal places (unless precision is specified) and a comma separator
 */
export const numberWithCommas = (x: number, precision?: number): string => {
  return x.toFixed(precision ?? 2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Parses a string to a number and returns null if input can not be parsed (NaN)
 */
export const stringToNumber = (x: string): number | null => {
  const numeric = parseFloat(x);
  return isNaN(numeric) ? null : numeric;
}

/**
 * Checks if a number is null or undefined
 */
export const isNullOrUndefined = (x?: string | number | null): boolean => {
  return x === null || x === undefined;
}

/**
 * Returns modulo (works for negative numbers as well)
 */
export const mod = (n: number, m: number) => {
  return ((n % m) + m) % m;
}

/**
 * Checks if a value is a number and returns it, if it is not a number it returns zero.
 */
export const numberOr0 = (n: unknown): number => {
  return isNaN(n as number) ? 0 : n as number
}

/**
 * Checks if a value is between two other values.
 */
export const isBetween = (n: number, min: number, max: number): boolean => {
  return n >= min && n <= max;
}

/**
 * Checks if a value is even.
 */
export const isEven = (n: number) => {
  return n % 2 == 0;
}

/**
 * Checks if a value is odd.
 */
export const isOdd = (n: number) => {
  return Math.abs(n % 2) == 1;
}

/**
 * Checks if a value is a number
 */
export const isNumber = (value?: string | number) => {
  return ((value != null) &&
    (value !== '') &&
    !isNaN(Number(value.toString())));
}