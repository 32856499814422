import { Metric } from '@shared/api/models/Metric/Metric';
import { IMultisensorWidgetConfig } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IWidgetConfigs';
import styled from 'styled-components';
import { CalculateScores } from '@dashboard/utils/WellnessScoreUtils';
import ScoreWidget from './ScoreWidget';
import RadarChartWidget from './RadarChartWidget';

interface IMultisensorWidget {
  metrics: Metric[];
  config?: IMultisensorWidgetConfig;
  spaceColor: string;
}

const MultisensorWidget = ({ metrics, config, spaceColor }: IMultisensorWidget) => {

  if (!config) {
    return null;
  }

  metrics.push(...CalculateScores(metrics))

  return (
    <FlexRow>
      <FlexColumn>
        <ScoreWidget
          metrics={metrics}
          config={config.scoreWidget}
        />
      </FlexColumn>
      <FlexColumn>
        <RadarChartWidget
          metrics={metrics}
          config={config.radarChartWidget}
          spaceColor={spaceColor}
        />
      </FlexColumn>
    </FlexRow>
  );
};

export default MultisensorWidget;

const FlexColumn = styled.div`
  width: 50%;
  `;
  
const FlexRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;