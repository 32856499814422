import { FlexColumn } from '@dashboard/components/atoms/FlexColumn/FlexColumn';
import { OutOfDateCircle } from '@dashboard/components/atoms/Square/OutOfDateCircle';
import { Square } from '@dashboard/components/atoms/Square/Square';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { MetricType_ValueDisplayString } from '@shared/api/enums/MetricType/MetricType_ValueDisplayString';
import { SpaceType_Color } from '@shared/api/enums/SpaceType/SpaceType_Color';
import { SpaceType_Icon } from '@shared/api/enums/SpaceType/SpaceType_Icon';
import { ClimateControlHeatmapFloorDto, ClimateControlHeatmapSpaceDto } from '@shared/api/models/ClimateControl/Heatmap/ClimateControlHeatmapDto'
import { Icon } from '@shared/components/atoms/Icon/Icon';
import { LabelValuePair } from '@shared/components/atoms/LabelValuePair/LabelValuePair';
import { Tooltip } from '@shared/components/atoms/Tooltip/Tooltip';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { addDays, addHours, formatDistance, isAfter } from 'date-fns';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

type PropTypes = {
  space: ClimateControlHeatmapSpaceDto;
  floor: ClimateControlHeatmapFloorDto;
  link: string;
  tooltipPlacement: TooltipPlacement
}

const ClimateControl_HeatmapSquare = ({ space, link, floor, tooltipPlacement }: PropTypes) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const lastMeasuredOn = space.deviceLastMeasuredOn && new Date(space.deviceLastMeasuredOn);
  const measuredInTheLastTwoHours = lastMeasuredOn ? isAfter(lastMeasuredOn, addHours(new Date(), - 2)) : false;
  const measuredInTheLastWeek = lastMeasuredOn ? isAfter(lastMeasuredOn, addDays(new Date(), - 7)) : false;

  const getSpaceColour = () => {
    if (space.hasTrvConfigIssues) {
      return theme.palette.climateControlHeatmap.orange
    }
    if (space.isInsideRange) {
      return theme.palette.climateControlHeatmap.green
    }
    else {
      return theme.palette.climateControlHeatmap.red
    }
  }

  const TooltipContent = (
    <TooltipWrapper>
      <TooltipHeader>
        <Icon
          icon={SpaceType_Icon(space.spaceType)}
          iconSize='20px'
          squareSize='32px'
          squareColor={SpaceType_Color(space.spaceType)}
          iconColor='#fff'
        />
        <FlexColumn>
          <SpaceName>
            {space.name}
          </SpaceName>
          <FloorName>
            {floor.name}
          </FloorName>
        </FlexColumn>
      </TooltipHeader>

      <Divider />

      <LabelValuePair
        label={t('ClimateControl.Temperature', { ns: 'molecules' })}
        value={space.temperature ? MetricType_ValueDisplayString({ metricType: MetricType.Temperature, value: space.temperature.toString() }) : '--'}
        size="xs"
        smallGap
      />

      <LabelValuePair
        label={t('ClimateControl.Heatmap.TargetTemp', { ns: 'molecules' })}
        value={space.targetTemp ? MetricType_ValueDisplayString({ metricType: MetricType.Temperature, value: space.targetTemp.toString() }) : '--'}
        size="xs"
        smallGap
        style={{ paddingTop: '10px' }}
      />

      <LabelValuePair
        label={t('Heatmap.LastReading', { ns: 'molecules' })}
        value={lastMeasuredOn ? formatDistance(lastMeasuredOn, new Date(), { addSuffix: true }) : '--'}
        size="xs"
        smallGap
        style={{ paddingTop: '10px' }}
      />
    </TooltipWrapper >
  );

  return (
    <Tooltip
      content={TooltipContent}
      tooltipColor={theme.palette.backgrounds.surface}
      placement={tooltipPlacement}
    >
      <Link key={space.id} to={link} style={{ cursor: 'pointer' }}>
        <Square size='36px'>
          {measuredInTheLastWeek ?
            <Icon
              icon={SpaceType_Icon(space.spaceType)}
              iconSize='23px'
              squareSize='36px'
              iconColor='#fff'
              squareColor={getSpaceColour()}
            />
            :
            <DeviceIssueIcon icon={regular('sensor-triangle-exclamation')} />
          }
          {!measuredInTheLastWeek ? <OutOfDateCircle size='11px' /> : !measuredInTheLastTwoHours && !space.hasTrvConfigIssues && <OutOfDateCircle size='11px' />}
        </Square>
      </Link>
    </Tooltip>
  )
}

export default ClimateControl_HeatmapSquare;

const DeviceIssueIcon = styled(FontAwesomeIcon)`
  color: ${p => transparentize(0.3, p.theme.palette.text.weak)};
  width: 23px;
  height: 23px;
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${p => p.theme.palette.borders.medium};
  margin: 10px 0;
`;

const TooltipWrapper = styled.div`
  padding: 10px 5px;
  max-width: 300px; 
`;

const TooltipHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
`;

const SpaceName = styled.span`
  font-size: 14px;
  line-height: 19px;
  color: ${p => p.theme.palette.text.medium};
`;

const FloorName = styled.span`
  font-size: 12px;
  line-height: 13px;
  color: ${p => p.theme.palette.text.weak};
`;