import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: PerceiveAsOnline
 */
const HeatmapConfigPerceiveAsOnline: IHeatmapConfig = {
  accessor: (x: string) => x,
  displayFormat: () => '',

  scale: {
    categoryScale: true,
    points: [
      {
        threshold: 'False',
        label: 'Offline',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 'True',
        label: 'Online',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigPerceiveAsOnline);