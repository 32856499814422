import SpaceTypesGetBySiteIdQuery from '@dashboard/api/queries/space/SpaceTypesGetBySiteIdQuery';
import { useApiState } from '@shared/hooks/useApiState';
import { useTranslation } from 'react-i18next';

const useSiteSpaceTypes = (siteId: number) => {
  const { t } = useTranslation(['hooks']);

  const { data: siteSpaceTypes, loading } = useApiState({
    query: new SpaceTypesGetBySiteIdQuery(siteId),
    errorMessage: t('LoadingSpaceTypesFailed', { ns: 'hooks' })
  }, []);

  return { loading, siteSpaceTypes };
}
export default useSiteSpaceTypes;