import styled, { css } from 'styled-components';
import { IPaddedContainer } from './PaddedContainer.types';

export const PaddedContainer = styled.div<IPaddedContainer>`
  padding: 30px 35px;

  ${p => p.centered && css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  @media (max-width: 600px) {
    min-width: 600px;
  }

  @media print {
    padding: 0;
  }
`;
