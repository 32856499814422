import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';
import { OperatorPostQuery } from '@shared/api/queries/ResidentApp/common/OperatorPostQuery';

export class PrizesResidentAppBulkCreateCommand extends OperatorPostQuery<OperatorPrizeDto[]> {
  challengeId: number;
  prizes: OperatorPrizeDto[];

  constructor(challengeId: number, prizes: OperatorPrizeDto[]) {
    super();
    this.challengeId = challengeId;
    this.prizes = prizes;
  }

  public targetUrl(): string {
    return '/Prizes/BulkCreate';
  }
}
