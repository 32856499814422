import styled from 'styled-components';
import Floorplan from './Floorplan';
import Marker from './Marker.types';
import { useFileFromBlobStorage } from '@shared/hooks/useFileFromBlobStorage';
import { Floor } from '@shared/api/models/Floor/Floor';
import { TopBarHeight, HorizontalNavbarHeight } from '@shared/theme/designConstants';
import { Card } from '@shared/components/molecules/Card/Card';

type PropTypes = {
  floor: Floor,
  markers: Marker[],
  showTooltips: boolean,
  onChange: (markers: Marker[]) => void
}

const FloorplanContainer = ({ floor, markers, showTooltips, onChange }: PropTypes) => {
  const { blobUri } = useFileFromBlobStorage(floor.floorplanId);

  return (
    <Card noMargin style={{ maxHeight: 'max-content' }}>
      <Wrapper>
        {blobUri &&
          <Floorplan
            blobUri={blobUri}
            markers={markers}
            showTooltips={showTooltips}
            onChange={onChange}
          />
        }
        {!blobUri &&
          <Centered>Floorplan unavailable</Centered>
        }
      </Wrapper>
    </Card>
  );
};

export default FloorplanContainer;

const Centered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.weak};
`;

const Wrapper = styled.div`
  flex-grow: 1;
  min-height: 300px;
  max-height: ${`calc(100vh - ${TopBarHeight}px - ${HorizontalNavbarHeight}px - 174px)`};
  overflow: auto;
`;