import { TenancyDto } from '@shared/api/models/ResidentApp/TenancyDto';
import { OperatorGetQuery } from '@shared/api/queries/ResidentApp/common/OperatorGetQuery';

export class UsersResidentAppGetAllBySiteQuery extends OperatorGetQuery<TenancyDto[]> {
  siteId: number;

  constructor(siteId: number) {
    super();
    this.siteId = siteId;
  }

  public targetUrl(): string {
    return `/Users/GetAllBySite/${this.siteId}`;
  }
}
