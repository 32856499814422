import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import AlertRulesPage from '@settings/components/organisms/AlertRulesManagement/AlertRulesPage';
import AlertRulesPageV2 from '@settings/components/organisms/AlertRulesManagement/AlertRulesPageV2';
import { Route } from '@shared/types/Route';
import AlertsPageV2 from '@src/components/pages/AlertsPage/AlertsPageV2';
import AlertsRulesListPage from '@src/components/pages/AlertsPage/AlertsRulesListPage';

export const AlertingRoutes: Route[] = [
  {
    path: 'alerts',
    element: AlertsPageV2,
    analytics: { page: 'alerts', category: 'alerts' },
    link: {
      label: 'Alerts',
      path: 'alerts',
      icon: regular('bell'),
      analytics: { action: 'alerts', category: 'feature_navigation' }
    }
  },
  {
    path: 'alerts/rules/*',
    element: AlertRulesPage,
    analytics: { page: 'manage_alerts', category: 'alerts' }
  },
  {
    path: 'alerts/ruleslist/rules/*',
    element: AlertRulesPageV2,
    analytics: { page: 'manage_alerts', category: 'alerts' }
  },
  {
    path: 'alerts/ruleslist',
    element: AlertsRulesListPage,
    analytics: { page: 'alerts_list', category: 'alerts' }
  },
];