import { differenceInMinutes } from 'date-fns';
import { IHeatmapConfig } from '../HeatmapConfig';
import DefaultScaleColors from '../HeatmapDefaultScaleColors';

/**
 * Heatmap config for metric: DeviceLastMeasuredOn
 */
const HeatmapConfigLastReadingTime: IHeatmapConfig = {
  accessor: (x: string) => {
    // Use (differenceInMinutes() / 60) to get decimal value (necessary for accurate comparison in displayFormat() function)
    return differenceInMinutes(new Date(), new Date(x)) / 60;
  },
  displayFormat: (x: number | string) => {
    if (x as number < 1) {
      return '<1';
    }

    if (x as number > 72) {
      return '>72';
    }

    return `${(x as number).toFixed(0)}`;
  },

  scale: {
    points: [
      {
        threshold: 2,
        label: '1hour',
        color: DefaultScaleColors.DARKGREEN,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
      {
        threshold: 24,
        label: '24',
        color: DefaultScaleColors.YELLOW,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 48,
        label: '48',
        color: DefaultScaleColors.ORANGE,
        textColor: DefaultScaleColors.DARKTEXT,
      },
      {
        threshold: 72,
        label: '72',
        color: DefaultScaleColors.RED,
        textColor: DefaultScaleColors.LIGHTTEXT,
      },
    ]
  }
};

export default Object.freeze(HeatmapConfigLastReadingTime);