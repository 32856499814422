import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import NumberInput from '@shared/components/atoms/NumberInput/NumberInput';
import { handleSliderChange } from '@shared/components/atoms/Slider/Slider';
import { ISliderThumb } from '@shared/components/atoms/Slider/Slider.types';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import styled, { css } from 'styled-components';
import { CLIMATE_CONTROL_MAX_TEMPERATURE, CLIMATE_CONTROL_MIN_TEMPERATURE } from './ClimateControlSettingsWidget';
import { climateControlSliderGradientSteps } from './ClimateControlSlider';

type ClimateControlTableProps = {
  thumbs: ISliderThumb[];
  onChange: (thumbs: ISliderThumb[]) => void;
  setHighlightedThumb: (index?: number) => void;
}

export const ClimateControlTable = ({ thumbs, onChange, setHighlightedThumb }: ClimateControlTableProps) => {
  const { fromLocale, toLocale, getUnit } = useLocalisation();

  return (
    <TableWrapper>
      {thumbs.map((thumb, i) => {
        return (
          <TableRow
            key={i}
            onMouseEnter={() => setHighlightedThumb(i)}
            onMouseLeave={() => setHighlightedThumb(undefined)}
          >
            <Col isDisabled={thumb.isDisabled}>
              {thumb.label}
            </Col>

            <Col style={{ marginLeft: 'auto' }}>
              <NumberInput
                min={CLIMATE_CONTROL_MIN_TEMPERATURE}
                max={CLIMATE_CONTROL_MAX_TEMPERATURE}
                value={toLocale(MetricType.Temperature, thumb.value, { round: 1 })}
                unit={getUnit(MetricType.Temperature)}
                onChange={value => handleSliderChange(fromLocale(MetricType.Temperature, value), i, thumbs, climateControlSliderGradientSteps, onChange)}
                disabled={thumb.isDisabled}
              />
            </Col>
          </TableRow>
        );
      })}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  margin-top: 40px;
  border-radius: 5px;
  border: 1px solid ${p => p.theme.action.divider};
  box-shadow: 0 1px 3px 0px ${p => p.theme.palette.shadows.medium};

  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const TableRow = styled.div`
  padding: 7px 15px;
  display: flex;
  color: ${p => p.theme.text.secondary};

  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  &:not(:first-child) {
    border-top: 1px solid ${p => p.theme.action.divider};
  }
`;

const Col = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  ${p => p.isDisabled && css`
    color: ${p => p.theme.palette.text.weak};
  `}
`;