import { ConsumptionType } from '@dashboard/api/enums/ConsumptionType';
import EnergyConsumptionDto from '@dashboard/api/models/EnergyConsumptionDto';
import { GetQuery } from '@shared/api/queries/common/GetQuery';

export default class SiteEnergyConsumptionQuery extends GetQuery<EnergyConsumptionDto[]> {
  siteId: number;
  consumptionType: ConsumptionType;

  constructor(siteId: number, consumptionType: ConsumptionType) {
    super();
    this.siteId = siteId;
    this.consumptionType = consumptionType;
  }

  public targetUrl(): string {
    return `/metrics/siteenergyconsumption/${this.consumptionType}/${this.siteId}`;
  }
} 