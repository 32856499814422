import DeviceStatusWidget from './Devices/DeviceStatusWidget';
import { Site } from '@shared/api/models/Site/Site';
import styled from 'styled-components';
import GenerationMixWidget from './GenerationMix/GenerationMixWidget';
import OccupancyWidget from './Occupancy/OccupancyWidget';
import TemperatureWidget from './Temperature/TemperatureWidget';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import UnoccupiedSpacesWidget from './UnoccupiedSpaces/UnoccupiedSpacesWidget';
import Carousel from './Carousel';

type LiveUpdatesProps = {
  site: Site;
}

const LiveUpdates = ({ site }: LiveUpdatesProps) => {
  const { hasFeatureFlag } = useTenantContext();

  return (
    <Carousel>
      <FlexRow>
        <DeviceStatusWidget site={site} />

        <OccupancyWidget />

        <TemperatureWidget />

        {hasFeatureFlag(FeatureFlag.UnoccupiedSpacesWidget) &&
          <UnoccupiedSpacesWidget />
        }

        <GenerationMixWidget />
      </FlexRow>
    </Carousel>
  );
};

export default LiveUpdates;

const FlexRow = styled.div`
  display: flex;
  gap: 20px;
`;