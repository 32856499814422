import { useState } from 'react';
import styled from 'styled-components';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import profileImagePlaceholder from '@settings/assets/images/profileImagePlaceholder.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useApi } from '@shared/hooks/useApi';
import { UserSummary } from '@shared/api/models/User/UserSummary';
import { Role } from '@shared/api/models/Role/Role';
import { useFileFromBlobStorage } from '@shared/hooks/useFileFromBlobStorage';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { useModal } from '@shared/hooks/useModal';
import UserSetRoleCommand from '@settings/api/queries/Users/UserSetRoleCommand';
import { UserRolePermissions } from '@shared/api/enums/UserRole/UserRolePermissions';
import { UserRole } from '@shared/api/enums/UserRole/UserRole';
import { InteractiveDialog } from '@shared/components/molecules/InteractiveDialog/InteractiveDialog';
import { Select } from '@shared/components/atoms/Select/Select';

interface IUserListRoleSelect {
  user: UserSummary;
  roles: Role[];
  refreshUsers: () => void;
}

const UserListRoleSelect = ({ user, roles, refreshUsers }: IUserListRoleSelect) => {
  const { t } = useTranslation(['settingsUser']);
  const { execute } = useApi();
  const { isOpen, toggle, ref } = useModal({});
  const { blobUri } = useFileFromBlobStorage(user.profileImageId, BlobStorageContainerType.Shared);
  const [selectedRoleId, setSelectedRoleId] = useState<number>();
  const selectedRole = roles.find(x => x.id === selectedRoleId);

  const onUserRoleChanged = async (e: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setSelectedRoleId(e.value);
    toggle();
  }

  const onConfirm = async () => {
    if (selectedRoleId === undefined) {
      return;
    }

    await execute({
      query: new UserSetRoleCommand(user.id, selectedRoleId),
      successMessage: t('ListRoleSelect.RoleUpdated', { ns: 'settingsUser' }),
      errorMessage: t('ListRoleSelect.RoleUpdateFailed', { ns: 'settingsUser' })
    });

    refreshUsers();
    toggle();
  }

  const onCancel = () => {
    setSelectedRoleId(undefined);
    toggle();
  }

  const getPermissions = (userRole: UserRole): string => {
    return UserRolePermissions[userRole];
  };

  const RoleDialogContent = (
    <RoleDialogWrapper>
      <FlexRow style={{ gap: '10px' }}>
        <UserImage src={blobUri ?? profileImagePlaceholder} />
        <DialogLabel>{user.fullName}</DialogLabel>
      </FlexRow>

      <RoleRow>
        <RoleLabel>{roles.find(x => x.id === user.roleId)?.displayName}</RoleLabel>
        <ArrowIcon icon={solid('arrow-right')} />
        <NewRoleLabel>{selectedRole?.displayName}</NewRoleLabel>
      </RoleRow>

      <FlexRow style={{ gap: '5px' }}>
        <DialogLabel style={{ fontSize: '14px' }}>{t('ListRoleSelect.Permissions', { ns: 'settingsUser' })}:</DialogLabel>
      </FlexRow>

      <Permissions>
        {selectedRole && getPermissions(selectedRole.name)}
      </Permissions>
    </RoleDialogWrapper>
  );

  return (
    <>
      <InteractiveDialog
        modalRef={ref}
        isOpen={isOpen}
        hide={toggle}
        title={t('ListRoleSelect.AssignRole', { ns: 'settingsUser' })}
        content={RoleDialogContent}
        confirmButton={{
          label: t('ListRoleSelect.Confirm', { ns: 'settingsUser' }),
          onClick: onConfirm,
        }}
        cancelButton={{
          label: t('Cancel', { ns: 'common' }),
          onClick: onCancel
        }}
      />

      <StyledSelect
        value={{ value: selectedRoleId ?? user.roleId, label: roles.find(x => x.id === (selectedRoleId ?? user.roleId))?.displayName }}
        isSearchable={false}
        onChange={(e) => onUserRoleChanged(e)}
        options={roles.map(r => ({ value: r.id, label: r.displayName }))}
      />
    </>
  );
};

export default UserListRoleSelect;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const RoleDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const RoleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  box-shadow: 0 6px 4px -2px ${p => p.theme.palette.shadows.medium}, 0 2px 18px 0px ${p => p.theme.palette.shadows.medium};
  border-radius: 25px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  color: ${p => p.theme.palette.primary};
  font-size: 16px;
`;

const DialogLabel = styled.div`
  color: ${p => p.theme.palette.text.fair};
  font-size: 16px;
  font-weight: 500;
`;

const RoleLabel = styled.div`
  color: ${p => p.theme.palette.text.weak};
  font-size: 18px;
  font-weight: 400;
`;

const NewRoleLabel = styled.div`
  color: ${p => p.theme.palette.text.medium};
  font-size: 18px;
  font-weight: 500;
`;

const Permissions = styled.div`
  text-align: center;
  color: ${p => p.theme.palette.text.fair};
  font-size: 14px;
  font-weight: 400;
  max-width: 300px;
  margin-top: -20px;
  margin-bottom: 20px;
`;