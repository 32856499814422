import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

type IconWithTextProps = {
  icon: IconProp;
  text: string;
  color?: string;
};

const IconWithText = ({icon, text, color}: IconWithTextProps) => {
  return (
    <IconText>
      <FontAwesomeIcon icon={icon} color={color} />
      <span>{text}</span>
    </IconText>
  )
}

export default IconWithText;

const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;