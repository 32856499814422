import React, { useContext, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { Space } from '@shared/api/models/Space/Space';
import { Route as RouteType } from '@shared/types/Route';
import { useApiState } from '@shared/hooks/useApiState';
import { DevicesWithLatestMetricsGetBySpaceIdQuery } from '@dashboard/api/queries/devices/DevicesWithLatestMetricsGetBySpaceIdQuery';
import { ProtectedRoute } from '@shared/components/navigation/ProtectedRoute/ProtectedRoute';
import { SpaceClimateControlGetBySpaceQuery } from '@shared/api/queries/ClimateControl/Space/SpaceClimateControlGetBySpaceQuery';
import { SpaceClimateControlDto } from '@shared/api/models/ClimateControl/Space/SpaceClimateControlDto';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import SpaceGetByIdQuery from '@dashboard/api/queries/space/SpaceGetByIdQuery';
import NoData from '@shared/components/atoms/NoData/NoData';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import { DeviceNotesGetNotesBySpaceId } from '@dashboard/api/queries/deviceNotes/DeviceNotesGetNotesBySpaceId';
import { DeviceNoteDetails } from '@dashboard/api/models/DeviceNoteDetails';

type PropTypes = {
  routes?: RouteType[]
}

interface ISpaceContext {
  space: Space;
  devices?: DeviceWithMetrics[];
  spaceClimateControl?: SpaceClimateControlDto;
  refreshSpaceClimateControl: () => void;
  showExternalAggregatedDeviceSummary?: boolean;
  deviceNotes: DeviceNoteDetails[];
}

export const SpaceContext = React.createContext({} as ISpaceContext);
export const useSpaceContext = () => useContext(SpaceContext);

const SpaceProvider = ({ routes }: PropTypes) => {
  const { t } = useTranslation();
  const { spaceId } = useParams<{ spaceId: string }>();

  const { data: space, loading: loadingSpace } = useApiState({
    query: spaceId === undefined ? undefined : new SpaceGetByIdQuery(parseInt(spaceId)),
  }, [spaceId]);

  const { data: devices, loading: loadingDevices } = useApiState({
    query: space && new DevicesWithLatestMetricsGetBySpaceIdQuery(space.id),
  }, [space]);

  const { data: spaceClimateControl, execute: refreshSpaceClimateControl } = useApiState({
    query: space && new SpaceClimateControlGetBySpaceQuery(space.id),
  }, [space]);

  const { data: deviceNotes } = useApiState({
    query: space && new DeviceNotesGetNotesBySpaceId(space.id),
    initialState: []
  }, [space]);

  const spaceContextProviderValue = useMemo(() => {
    // if space doesn't exist then set temporary initial space to resolve type error (but will not render as will return null if space is null)
    const initialSpace = space ?? { id: 0, name: '', spaceType: SpaceType.Other, occupied: false };

    return { space: initialSpace, devices: devices, spaceClimateControl, deviceNotes, refreshSpaceClimateControl }
  }, [space, devices, spaceClimateControl, deviceNotes, refreshSpaceClimateControl]);

  if (loadingSpace) {
    return (
      <LoadingWidget
        label={t('LoadingSpace', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  if (loadingDevices) {
    return (
      <LoadingWidget
        label={t('LoadingDevices', { ns: 'status' })}
        styles={{ marginTop: 80 }}
      />
    )
  }

  if (!space) {
    return (
      <NoData
        label={t('SpaceNotFound', { ns: 'status' })}
        styles={{ margin: 80 }}
      />
    );
  }

  return (
    <SpaceContext.Provider value={spaceContextProviderValue}>
      <FlexRow>
        <Sidebar space={space} devices={devices} />

        <Content>
          <Routes>
            {routes?.map(route => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    route={route}
                    customProps={{ space: space, devices: devices }}
                  />
                }
              />
            ))}
          </Routes>
        </Content>

      </FlexRow>
    </SpaceContext.Provider>
  );
};

export default SpaceProvider;

const FlexRow = styled.div`
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;