import React, { useEffect, useState } from 'react';
import { GroupBase, OptionsOrGroups, SingleValue } from 'react-select';
import { SpaceType } from '@shared/api/enums/SpaceType/SpaceType';
import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { Select } from '@shared/components/atoms/Select/Select';

export type SpaceTypeSelectOption = {
  label: string;
  value: SpaceType;
};

export type SpaceTypeSelectGroup = {
  label: string;
  options: SpaceType[];
};

export type SpaceTypeSelectGroups = {
  defaultValue?: SpaceType;
  groups: SpaceTypeSelectGroup[];
};

interface ISpaceTypeSelect {
  options: SpaceTypeSelectGroups;
  onChange: (selected?: SingleValue<SpaceTypeSelectOption>) => void;
  placeholder?: string;
  value: SpaceType;
  readOnly?: boolean;
}

const SpaceTypeSelect = ({ options, onChange, placeholder, value, readOnly }: ISpaceTypeSelect) => {
  const [filteredOptions, setFilteredOptions] = useState<OptionsOrGroups<SpaceTypeSelectOption, GroupBase<SpaceTypeSelectOption>> | undefined>();
  const [selectedOption, setSelectedOption] = useState<SpaceTypeSelectOption>();

  useEffect(() => {
    const spaceTypeOptions = options.groups.map((group) => ({
      label: group.label,
      options: group.options.map((spaceType) => ({
        label: SpaceType_DisplayString(spaceType),
        value: spaceType,
      })),
    }));

    const defaultOption = spaceTypeOptions.find(x => x.options.find(a => a.value === value))?.options.find(x => x.value === value);
    setSelectedOption(defaultOption);
    setFilteredOptions(spaceTypeOptions);
  }, [options, value]);

  return (
    <Select
      onChange={onChange}
      options={filteredOptions}
      placeholder={placeholder}
      value={selectedOption}
      menuHeight={200}
      menuPlacement='top'
      isSearchable
      readOnly={readOnly}
    />
  );
};

export default React.memo(SpaceTypeSelect);
