import { SpaceType_DisplayString } from '@shared/api/enums/SpaceType/SpaceType_DisplayString';
import { SpaceTypeFairUsePolicy } from '@shared/api/models/FairUse/SpaceTypeFairUsePolicy';
import { Input, Label } from '@shared/components/atoms/Form/Form';
import { stringToNumber } from '@shared/utils/NumberUtils';
import { FormEvent } from 'react'
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FairUseForm } from '@shared/api/models/FairUse/FairUseForm';

type AnnualAllowanceInputProps = {
  spaceTypeFairUsePolicy: SpaceTypeFairUsePolicy;
  energyMeterTypeSuffix: string;
  index: number;
  onValueChange: (updatedValue: number) => void;
}

const AnnualAllowanceInput = ({ spaceTypeFairUsePolicy, energyMeterTypeSuffix, index, onValueChange }: AnnualAllowanceInputProps) => {
  const { register, formState: { errors } } = useFormContext<FairUseForm>();
  const { t } = useTranslation();

  const getInputLabel = (spaceTypeFairUsePolicy: SpaceTypeFairUsePolicy) => {
    if (spaceTypeFairUsePolicy.spaceType) {
      return SpaceType_DisplayString(spaceTypeFairUsePolicy.spaceType)
    } else {
      return `Cluster - ${spaceTypeFairUsePolicy.clusterSize} Bed`
    }
  }

  return (
    <AnnualAllowanceInputWrapper>
      <AnnualAllowanceLabel>
        <span>{getInputLabel(spaceTypeFairUsePolicy)}</span>
        <span>{spaceTypeFairUsePolicy.occurrences} x</span>
      </AnnualAllowanceLabel>
      <AnnualAllowanceInputField error={errors.spaceTypePolicies && errors.spaceTypePolicies[index] ? true : false}
        onInput={(e: FormEvent<HTMLInputElement>) => onValueChange(stringToNumber(e.currentTarget.value) ?? 0)}
        {...register(`spaceTypePolicies.${index}.allowance`, {
          valueAsNumber: true,
          required: {
            value: true,
            message: t('FairUsage.Validation.RequiredSpaceType', { ns: 'molecules', spaceType: SpaceType_DisplayString(spaceTypeFairUsePolicy.spaceType) })
          },
          max: {
            value: 999999,
            message: t('FairUsage.Validation.NumberIsTooLong', { ns: 'molecules', spaceType: SpaceType_DisplayString(spaceTypeFairUsePolicy.spaceType) })
          },
          min: {
            value: 1,
            message: t('FairUsage.Validation.EnterANaturalNumber', { ns: 'molecules', spaceType: SpaceType_DisplayString(spaceTypeFairUsePolicy.spaceType) })
          },
        }
        )}
        type='number'
      />
      <MetricTypeLabel>
        {energyMeterTypeSuffix}
      </MetricTypeLabel>
    </AnnualAllowanceInputWrapper>
  )
};

export default AnnualAllowanceInput

const AnnualAllowanceInputWrapper = styled.div`
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  padding: 5px 0;
  gap: 5px;

  @media (min-width: 1200px) {
    flex: 0 0 45%;
  }
`;

const AnnualAllowanceLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin: 0;
`;

const AnnualAllowanceInputField = styled(Input) <{ error: boolean }>`
  height: 30px;
  width: 90px;
  text-align: center;

  ${p => p.error && css`
    border: 1px solid ${p => p.theme.palette.red};
  `}
`;

const MetricTypeLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.text.fair};
  padding-left: 5px;
`;
