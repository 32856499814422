import { darken, transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
/*#region General */

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    
    font-family: -apple-system, BlinkMacSystemFont, 'DM Sans', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: ${(p) => p.theme.palette.backgrounds.background};
    color: ${(p) => p.theme.palette.text.medium};
    color: ${(p) => p.theme.text.primary};
    font-size: 16px;
    letter-spacing: 0.4px;
  }

  .container {
    padding: 0px
  }

  a,
  a:hover,
  a:focus,
  a:active,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  .numberInputHiddenArrows {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

/*#endregion*/

/*#region Scrollbar styles */

  /* Works on Firefox */
  * {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: ${(p) => p.theme.palette.scrollbar.thumb} ${(p) => p.theme.palette.scrollbar.track}; /* scroll thumb and track */
  }

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    height: 4px; /* height of horizontal scrollbar */
    width: 6px;
  }

  ::-webkit-scrollbar-track { /* Track */
    background: ${(p) => p.theme.palette.scrollbar.track};
  }

  ::-webkit-scrollbar-thumb { /* Handle */
    background: ${(p) => p.theme.palette.scrollbar.thumb};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover { /* Handle on hover */
    background: ${(p) => darken(0.1, p.theme.palette.scrollbar.thumb)};
  }
  
/*#endregion*/

/*#region 'antd' overwrites */

  /* Set height/color of select input fields (default is 32px) */
  .ant-picker,
  .ant-select-selector {
    height: 36px;
    border-radius: 4px;
  }

  .ant-select-selection-placeholder {
    color:  ${(p) => p.theme.palette.text.weak};
  }

  .ant-select-arrow {
    svg > path {
      fill: ${(p) => p.theme.palette.text.weak};
    }
  }

  .ant-select-dropdown {
    background-color: ${(p) => p.theme.palette.backgrounds.surface};
  }

  .ant-select-item.ant-select-item-group {
    color: ${(p) => p.theme.palette.text.weak};
  }

  .ant-select-item.ant-select-item-option {
    color: ${(p) => p.theme.palette.text.medium};
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected,
  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background-color: ${(p) => p.theme.palette.backgrounds.surfaceStrong};
    font-weight: 400;
  }

  /* Style selected multi-select items */
  .ant-select-multiple .ant-select-selection-item {
    color: rgba(114, 32, 255, 0.7);
    background: rgba(114, 32, 255, 0.1);
    border: none;
    font-weight: 500;
    border-radius: 3px;
    height: 26px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 6px;

    /* Set color of the remove icon */
    svg > path {
      fill: rgba(114, 32, 255, 0.7);
    }
  }

  .ant-picker-input > input {
    color: ${(p) => p.theme.palette.text.fair};
    font-family: ${(p) => p.theme.fontFamily} !important;
    font-weight: 500;
    
    &::placeholder {
      color:  ${(p) => p.theme.palette.text.weak};
    }
  }

  .ant-picker-separator,
  .ant-picker-suffix {
    svg > path {
      fill: ${(p) => p.theme.palette.text.fair};
    }
  }

  .ant-select-clear,
  .ant-picker-clear {
    background: ${(p) => p.theme.palette.backgrounds.surfaceStrong};

    svg > path {
      fill: ${(p) => p.theme.palette.text.weak};
    }
  }

  .ant-radio > .ant-radio-inner {
    background-color: ${(p) => p.theme.palette.backgrounds.surfaceStrong};
    border-color: ${(p) => p.theme.palette.borders.medium} !important;
  }

  .ant-radio-wrapper {
    color: ${(p) => p.theme.text.secondary} !important;
  }

  .ant-switch {
    background-color: ${(p) => transparentize(0.4, p.theme.palette.text.weak)};
  }

  .ant-switch.ant-switch-checked {
    background-color: ${(p) => p.theme.palette.primary};
  }

  .ant-table-thead > tr > th {
    color: ${(p) => p.theme.palette.text.fair};  
    background-color: white;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${(p) => p.theme.palette.borders.weak};
  }

  .ant-table-cell {
    color: ${(p) => p.theme.palette.text.medium};
  }

  .ant-table {
    tr:nth-child(odd) { 
      background-color: ${(p) => transparentize(0.4, p.theme.palette.tables.evenRow)}; 
    }

    tr:nth-child(even) { 
      background-color: ${(p) => transparentize(0.4, p.theme.palette.tables.unevenRow)}; 
    }
  } 
  
/*#endregion*/

/*#region Masonry grid styles */

  /* Building/Site edit pages */
  .building-edit-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
  }

  .building-edit-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
  }

  .building-edit-masonry-grid_column>div {
    margin-bottom: 30px;
  }

  /* Floor Dashboard */
  .floor-dashboard-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
  }

  .floor-dashboard-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
  }

  .floor-dashboard-masonry-grid_column>div {
    margin-bottom: 30px;
  }

  /* Space Page */
  .space-page-devices-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -20px;
    /* gutter size offset */
    width: auto;
  }

  .space-page-devices-masonry-grid_column {
    padding-left: 20px;
    /* gutter size */
    width: 370px !important;
    /* forces width of the columns as cards have a fixed width, otherwise they stretch horizontally */
  }

  .space-page-devices-masonry-grid_column > div {
    margin-bottom: 20px;
  }

  /* Modal Select */
  .modal-select-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -20px;
  }

  .modal-select-masonry-grid_column {
    padding-left: 20px;
  }

  .modal-select-masonry-grid_column > div {
    margin-bottom: 20px;
  }

/*#endregion*/

/*#region React-Toastify overwrites */

.Toastify__close-button {
    opacity: 1;
    svg {
      fill: ${(p) => p.theme.palette.text.weak};
    }
  }
 
  .Toastify__toast {
    font-family: inherit;
    font-weight: 500;
    color: ${(p) => p.theme.palette.text.fair};
    background-color: ${(p) => p.theme.palette.backgrounds.surface};
  }

  .Toastify__toast--success {
    .Toastify__toast-icon {
      svg {
        fill: #44A901;
      }
    }
    .Toastify__progress-bar  {
      background: none;
      background-image: ${`linear-gradient(to right, ${transparentize(0.15, '#44A901')}, ${darken(0.05, '#44A901')})`};
    }
  }

  .Toastify__toast--info {
    .Toastify__toast-icon {
      svg {
        fill: ${(p) => p.theme.palette.primary};
      }
    }
    .Toastify__progress-bar  {
      background: none;
      background-image: ${(p) => `linear-gradient(to right, ${transparentize(0.15, p.theme.palette.primary)}, ${darken(0.05, p.theme.palette.primary)})`};
    }
  }

  .Toastify__toast--warning {
    .Toastify__toast-icon {
      svg {
        fill: '#f0c000';
      }
    }
    .Toastify__progress-bar  {
      background: none;
      background-image: ${`linear-gradient(to right, ${transparentize(0.15, '#f0c000')}, ${darken(0.01, '#f0c000')})`};
    }
  }

  .Toastify__toast--error {
    .Toastify__toast-icon {
      svg {
        fill: ${(p) => p.theme.palette.red};
      }
    }
    .Toastify__progress-bar  {
      background: none;
      background-image: ${(p) => `linear-gradient(to right, ${transparentize(0.15, p.theme.palette.red)}, ${darken(0.05, p.theme.palette.red)})`};
    }
  }

/*#endregion*/

`;
