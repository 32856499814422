import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { dateToUtcDate } from '@shared/utils/DateUtils';
import { DatePicker, DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CSSProperties, useTheme } from 'styled-components';

export type TimeRange = {
  from: Date,
  to: Date
}

type PropTypes = {
  value?: [Date, Date],
  onChange: (timeRange?: TimeRange) => void,
  minDate?: Date,
  allowClear?: boolean,
  style?: React.CSSProperties,
  disabled?: boolean;
  maxNumDays?: number;
}

const RangePicker = ({ value, onChange, minDate, allowClear = true, style, disabled, maxNumDays }: PropTypes) => {
  const theme = useTheme();
  const { localisation } = useLocalisation();
  const { RangePicker } = DatePicker;

  const disabledDaysRange: DatePickerProps['disabledDate'] = (current, { from }) => {
    
    const today = dayjs().endOf('day');

    // True if the date is before the minDate or after the current day
    const disabled = (minDate && current < dayjs(minDate)) || current > today;

    if (disabled) {
      return disabled;
    }

    if (!maxNumDays){
      return false
    }

    if (from) {
      return Math.abs(current.diff(from, 'days')) >= maxNumDays;
    }
  
    return false;
  };

  const handleChange = (range: [Dayjs | null, Dayjs | null] | null) => {
    if (range?.[0] && range?.[1]) {
      onChange({
        from: dateToUtcDate(range[0]),
        to: dateToUtcDate(range[1])
      });
    } else {
      onChange(undefined);
    }
  };

  const customStyles: CSSProperties = {
    height: '38px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.action.outlineBorder}`,
    borderRadius: '4px',
    boxShadow: theme.palette.forms.input.boxShadow,
    color: theme.text.secondary
  };

  return (
    <RangePicker
      value={value && [dayjs(value[0]), dayjs(value[1])]}
      format={localisation.dateFormats.date}
      onChange={(range) => handleChange(range)}
      disabledDate={disabledDaysRange}
      allowClear={allowClear}
      style={{ ...customStyles, ...style }}
      disabled={disabled}
      allowEmpty={[true, true]}
    />
  );
};

export default RangePicker;