import { AlertStatus } from '@shared/api/models/Alert/Alert';
import { BulkPatchQuery } from '@shared/api/queries/common/BulkPatchQuery';

export default class BulkAlertUpdateCommand extends BulkPatchQuery<unknown> {
  status: AlertStatus;

  constructor(ids: number[], status: AlertStatus) {
    super(ids);
    this.status = status;
  }

  public targetUrl(): string {
    return '/alerting/BulkUpdateAlert';
  }
}