import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

type RadioInputFormFieldProps = {
  label: ReactNode;
  id: string;
  name: string;
}

const RadioInputFormField = ({ label, id, name }: RadioInputFormFieldProps) => {
  const { register } = useFormContext();

  return (
    <Container>
      {label}
      <RadioInput
        type="radio"
        id={id}
        value={id}
        {...register(name)}
      />
      <Checkmark />
    </Container>
  )
}

export default RadioInputFormField;

const Container = styled.label`
  display: block;
  position: relative;
  width: max-content;
  padding-left: 25px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

/* Hide the browser's default radio button */
const RadioInput = styled.input`
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

/* Create a custom radio button */
const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 2px solid ${p => p.theme.action.active};
  border-radius: 50%;

  /* On mouse-over, add a grey background color */
  ${Container}:hover & {
    border-color: ${p => p.theme.primary.main};
  }

  /* When the radio button is checked, add a blue background */
  ${RadioInput}:checked ~ & {
    border-color: ${p => p.theme.primary.main};
    background-color: ${p => p.theme.primary.main};
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  ${RadioInput}:checked ~ &:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  &:after {
    transform: translate(50%, 50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${p => p.theme.primary.contrast};
  }
`;