import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type CategoryOptions = {
  title: string;
  icon: IconDefinition;
  path?: string;
  disabled?: boolean;
}

const Category: FC<CategoryOptions> = ({ title, icon, path, disabled }) => {

  return (
    <FlexItem>
      {path &&
        <Link to={path} style={{ cursor: 'auto' }}>
          <CategoryButton disabled={disabled}>
            <Icon icon={icon} />
          </CategoryButton>
        </Link>
      }

      {!path &&
        <CategoryButton disabled={disabled}>
          <Icon icon={icon} />
        </CategoryButton>
      }

      <CategoryName disabled={disabled}>
        {title}
      </CategoryName>
    </FlexItem>
  )
}

const BuildingPage_Devices = () => {
  const { t } = useTranslation(['organisms']);

  return (
    <FlexRow>
      <Category title={t('All', { ns: 'common' })} path="all" icon={solid('sensor-on')} />
      <Category title={t('Building.Environmental', { ns: 'organisms' })} path="environmental" icon={solid('leaf')} />
      <Category title={t('Building.Metering', { ns: 'organisms' })} path="metering" icon={solid('gauge-high')} />
      <Category title={t('Building.Occupancy', { ns: 'organisms' })} path="occupancy" icon={solid('walking')} />
      <Category title={t('Building.Status', { ns: 'organisms' })} path="status" icon={solid('cogs')} />
      <Category title={t('ClimateControl.TemperatureControl', { ns: 'molecules' })} path="climate-control" icon={solid('thermometer-half')} />
      <Category title={t('Building.Gateways', { ns: 'organisms' })} path="gateway" icon={solid('router')} />
      <Category disabled title={t('Building.LeakDetection', { ns: 'organisms' })} icon={solid('droplet')} />
      <Category disabled title={t('Building.FireDoors', { ns: 'organisms' })} icon={solid('block-brick-fire')} />
      <Category disabled title={t('Building.SmartCleaning', { ns: 'organisms' })} icon={solid('hand-sparkles')} />
      <Category disabled title={t('Building.LegionellaTesting', { ns: 'organisms' })} icon={solid('vial-virus')} />
      <Category disabled title={t('Building.SecurityAndSafety', { ns: 'organisms' })} icon={solid('shield-alt')} />
      <Category disabled title={t('Building.ParkingManagement', { ns: 'organisms' })} icon={solid('car')} />
      <Category disabled title={t('Building.Alexa', { ns: 'organisms' })} icon={solid('microphone')} />
      <FlexItem />
    </FlexRow>
  );
}

export default BuildingPage_Devices;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 100px 40px;
  
  margin: 40px;
`;

const FlexItem = styled.div`
  position: relative;
  width: 200px;
  text-align: center;
  flex-basis: calc(25% - 40px);
`;

const CategoryButton = styled.div<{ disabled?: boolean }>`
  display: grid;

  background-color: ${p => p.theme.palette.backgrounds.surface};

  border-radius: 50%;
  box-shadow: 0px 4px 12px ${p => p.theme.palette.shadows.medium};

  box-sizing: border-box;

  width: 140px;
  height: 140px;
  margin: 20px auto;

  opacity: 0.5;
  
  ${p => !p.disabled && css`
    cursor: pointer;
    opacity: 1;
    
    &:hover {
      background-color: ${p.theme.palette.primary};

      svg {
        color: ${p.theme.palette.text.onPrimary};
      }
    }
  `}
`;

const CategoryName = styled.div<{ disabled?: boolean }>`
  font-weight: 500;
  font-size: 20px;

  user-select: none;

  color: ${p => p.disabled ? p.theme.palette.text.weak : p.theme.palette.text.medium};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2.5rem;
  color: ${p => p.theme.palette.primary};
  margin: auto;
`;
