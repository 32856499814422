import { SpaceTypeThresholdDto } from '@shared/api/models/ResidentApp/SpaceTypeThresholdDto';
import { OperatorPatchQuery } from '@shared/api/queries/ResidentApp/common/OperatorPatchQuery';

export default class SpaceTypeThresholdsResidentAppUpdateCommand extends OperatorPatchQuery<SpaceTypeThresholdDto[]> {
  siteId: number;
  spaceTypeThresholds?: SpaceTypeThresholdDto[];

  constructor(siteId: number, spaceTypeThresholds?: SpaceTypeThresholdDto[]) {
    super();
    this.siteId = siteId;
    this.spaceTypeThresholds = spaceTypeThresholds;
  }

  public targetUrl(): string {
    return '/SpaceTypeThresholds/Update';
  }
}
