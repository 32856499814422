import { Button } from '@shared/components/atoms/Button/Button';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@shared/hooks/useApi';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { submitSiteClimateControlForm, temperaturesWereIncreased } from '../ClimateControlUtils';
import TemperatureRanges from '../Setup/TemperatureRanges/TemperatureRanges';
import { TemperatureRangeForm } from '../Setup/types/TemperatureRangeForm';
import SetupInformation from '../SetupInformation';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { useModal } from '@shared/hooks/useModal';

const TemperatureControls = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { execute } = useApi();
  const { fromLocale } = useLocalisation();
  const navigate = useNavigate();
  const { isOpen, toggle, ref } = useModal({});
  const { siteClimateControl, refreshSiteClimateControl } = useSiteContext();
  const temperatureRangeFormMethods = useForm<TemperatureRangeForm>({ mode: 'onChange' });
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

  if (!siteClimateControl) {
    return null;
  }

  const onUpdate = async (): Promise<void> => {
    if (savingInProgress) {
      return;
    }

    setSavingInProgress(true);
    try {
      await submitSiteClimateControlForm(siteClimateControl, temperatureRangeFormMethods.getValues(), temperatureRangeFormMethods.formState, fromLocale, t, execute);
    } catch (error) {
      console.error(error);
    } finally {
      refreshSiteClimateControl();
      setSavingInProgress(false);
      navigate('./..');
    }
  };

  const tempIncreased = temperaturesWereIncreased(siteClimateControl, temperatureRangeFormMethods.getValues());

  return (
    <>
      <WarningDialog
        modalRef={ref}
        isOpen={isOpen}
        sectionOne={<DialogTitle>{t('UpdateSettings', { ns: 'common' })}?</DialogTitle>}
        sectionTwo={tempIncreased ? t('ClimateControl.UpdateSettingsWarningIncreased', { ns: 'molecules' }) : t('ClimateControl.UpdateSettingsWarningGeneral', { ns: 'molecules' })}
        confirmButton={t('Update', { ns: 'common' })}
        confirmButtonColor={theme.primary.main}
        cancelButton={t('Cancel', { ns: 'common' })}
        cancelButtonType='tertiary'
        cancelButtonColor={theme.error.main}
        onCancel={toggle}
        onConfirm={onUpdate}
      />

      <ResponsiveContainer>
        <Title
          size='lg'
          text={t('ClimateControl.EditTemperatureControls', { ns: 'molecules' })}
        />
        <FormProvider {...temperatureRangeFormMethods}>
          <TemperatureRanges siteClimateControl={siteClimateControl} />
        </FormProvider>
        <SetupInformation
          label={t('ClimateControl.ClimateControlUpdateInformationText', { ns: 'molecules' })}
        />
        <ButtonContainer>
          <Button
            tertiary
            label={t('Cancel', { ns: 'common' })}
            onClick={() => navigate('./..')}
            color={theme.palette.red}
          />
          <Button
            label={t('Update', { ns: 'common' })}
            onClick={toggle}
            disabled={savingInProgress}
            analytics={{ action: 'update_temperature_controls', category: 'climate_control' }}
          />
        </ButtonContainer>
      </ResponsiveContainer>
    </>
  )
}

export default TemperatureControls

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  @media print {
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

const DialogTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;