import { useCallback, useEffect, useRef, useState } from 'react';

type PropTypes = {
  disableCloseOnClickOutside?: boolean;
  disableCloseOnEscape?: boolean;
};

export const useModal = ({ disableCloseOnClickOutside, disableCloseOnEscape }: PropTypes) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggle = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleClick = useCallback((e: MouseEvent) => {
    if (!ref?.current?.contains(e.target as HTMLDivElement)) {
      setIsOpen(false);
    }
  }, []);

  const handleEscapeKey = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape' && !disableCloseOnEscape) {
      setIsOpen(false);
    }
  }, [disableCloseOnEscape]);

  useEffect(() => {
    if (isOpen && !disableCloseOnClickOutside)
      document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick, isOpen, disableCloseOnClickOutside]);

  useEffect(() => {
    if (isOpen) document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey, isOpen]);

  return {
    isOpen,
    toggle,
    setIsOpen,
    ref
  };
};
