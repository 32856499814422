import DeviceCreateCommand from '@settings/api/queries/Devices/DeviceCreateCommand';
import { PanelHeaterMetadata } from '@settings/api/queries/Devices/Metadata/PanelHeaterMetadata';
import { HierarchySpace } from '@shared/api/models/Hierarchy/Hierarchy';
import { ErrorMessage, Label } from '@shared/components/atoms/Form/Form';
import { Select } from '@shared/components/atoms/Select/Select';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { IDeviceConfig } from '@shared/contexts/DeviceConfigContext/IDeviceConfig/IDeviceConfig';
import { useEffect, useState } from 'react';
import { useFormContext, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Option = {
  label: string;
  value: number;
}

const PowerRatings = [
  500,
  750,
  1000,
  1500,
  2000,
  2500,
];

const PowerRatingOptions = PowerRatings.map(x => ({ label: `${x}W`, value: x }));

type PropTypes = {
  deviceConfig: IDeviceConfig;
  selectedSpace?: HierarchySpace;
  setValue: UseFormSetValue<DeviceCreateCommand>;
}

const DeviceCreatePanelHeater = ({ deviceConfig, selectedSpace, setValue }: PropTypes) => {
  const { t } = useTranslation();
  const { register, clearErrors, formState: { errors } } = useFormContext<DeviceCreateCommand>();
  const { getDisplayString } = useDeviceConfigContext();
  const [selected, setSelected] = useState<Option>();

  register('metadata', { required: t('RequiredField', { ns: 'validation' }) });

  useEffect(() => {
    const model = getDisplayString(deviceConfig.deviceModel);
    const power = selected ? `- ${selected.label}` : '';
    const space = selectedSpace?.name ? `- ${selectedSpace?.name}` : '';

    setValue('friendlyName', `${model} ${power} ${space}`);
    clearErrors('friendlyName');

    if (selected) {
      const metadata: PanelHeaterMetadata = { powerRating: selected.value };
      setValue('metadata', JSON.stringify(metadata));
      clearErrors('metadata');
    }
  }, [selected, deviceConfig, selectedSpace, errors, getDisplayString, setValue, clearErrors]);

  return (
    <FlexRow>
      <Column>
        <Label>{t('PowerRating', { ns: 'common' })}</Label>
        <Select
          options={PowerRatingOptions}
          isSearchable={true}
          onChange={selected => selected && setSelected(selected)}
        />
        <ErrorMessage>{errors.metadata?.message}</ErrorMessage>
      </Column>
    </FlexRow>
  );
};

export default DeviceCreatePanelHeater;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`;

const Column = styled.div`
  width: 100%;
`;