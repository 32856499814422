import { ReportEnergyConsumptionUsageDto } from '@dashboard/api/models/ReportEnergyConsumptionDto';
import { calculateM3ToKwh } from '@shared/utils/EnergyUtils';
import { applyModifierToEnergyConsumptionUsageDto } from './ModifierHelpers';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { LocalisationFunction } from '@shared/contexts/LocalisationContext/LocalisationContext';

// Gas specific modifer to convert m³ to kWh
export const applyEnergyConsumptionGasModifier = (dto: ReportEnergyConsumptionUsageDto, gasCalorificValue: number, fromLocale: LocalisationFunction): ReportEnergyConsumptionUsageDto => {

  const modifier = (value: number): number => calculateM3ToKwh(gasCalorificValue, fromLocale(MetricType.GasVolume, value));

  return applyModifierToEnergyConsumptionUsageDto(dto, modifier);
};