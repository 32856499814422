import styled, { useTheme } from 'styled-components';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Title } from '@shared/components/atoms/Title/Title';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import IReportDto from '@dashboard/api/models/IReportDto';
import { useApiState } from '@shared/hooks/useApiState';
import { format } from 'date-fns';
import { Site } from '@shared/api/models/Site/Site';
import { Select } from '@shared/components/atoms/Select/Select';
import ReportsGetAllBySiteIdQuery from '@dashboard/api/queries/reports/ReportsGetAllBySiteIdQuery';
import { useThemeContext } from '@shared/contexts/ThemeContext/ThemeContext';
import { Button } from '@shared/components/atoms/Button/Button';
import ReportGetBydIdQuery from '@dashboard/api/queries/reports/ReportGetByIdQuery';
import { Loading } from '@shared/components/atoms/Loading/Loading';
import EsgReport from '@dashboard/components/molecules/EsgReport/EsgReport';
import PrintCheck from '@dashboard/components/molecules/EsgReport/PrintCheck';
import IconTooltip from '@shared/components/atoms/IconTooltip/IconTooltip';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';
import { SingleValue } from 'react-select';

type PropTypes = {
  site: Site
}

type ReportSelectOption = {
  label: string,
  value: IReportDto
}

const SitePage_ESG = ({ site }: PropTypes) => {
  const { t } = useTranslation(['organisms']);
  const theme = useTheme();
  const { trackAction } = useAnalytics();
  const { isDarkTheme, toggleTheme } = useThemeContext();
  const [inPrintMode, setInPrintMode] = useState(false);
  const [themeChangedForPrintMode, setThemeChangedForPrintMode] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ReportSelectOption>();

  const { transform: reportOptions, loading: loadingReports } = useApiState({
    query: new ReportsGetAllBySiteIdQuery(site.id),
    transformFunc: reports => [...reports]
      .sort((a, b) => (new Date(b.reportDate)).getTime() - (new Date(a.reportDate)).getTime())
      .map(x => ({ label: format(new Date(x.reportDate), 'MMMM yyy'), value: x }))
  }, []);

  const { data: esgReport, loading: loadingEsgReport } = useApiState({
    query: selectedReport && new ReportGetBydIdQuery(selectedReport.value.id)
  }, [selectedReport]);

  useEffect(() => {
    if (reportOptions && reportOptions.length > 0) {
      setSelectedReport(reportOptions[0])
    }
  }, [reportOptions]);

  const handleReportSelect = (selected: SingleValue<ReportSelectOption>) => {
    if (selected) {
      setSelectedReport(selected);
      trackAction('select_month', 'esg_report', { esg_report_month: selected.value.reportDate });
    }
  };

  const handleExport = () => {
    if (isDarkTheme) {
      toggleTheme();
      setThemeChangedForPrintMode(true);
    }

    setInPrintMode(true);
    trackAction('export_pdf', 'esg_report', { esg_report_month: selectedReport?.value.reportDate });
  };

  const handlePrintModeExit = () => {
    if (themeChangedForPrintMode) {
      toggleTheme();
      setThemeChangedForPrintMode(false);
    }

    setInPrintMode(false);
  };

  return (
    <PaddedContainer>
      <TitleRow>
        <Title
          size='lg'
          text={t('Site.EnvironmentalSocialAndGovernance', { ns: 'organisms' })}
        />

        <RightAligned>
          <IconTooltip
            icon={regular('circle-info')}
            tooltip={{
              content: <Trans i18nKey='molecules:ESG.ReportExplanation'><sup></sup></Trans>,
              placement: TooltipPlacement.Left
            }}
            tooltipStyles={{ width: '280px' }}
            wrapperStyles={{ margin: '4px -3px 0 0' }}
          />

          <SelectWrapper
            data-cy={'ESG_Site_MonthSelect'}
          >
            <Select
              value={selectedReport}
              options={reportOptions}
              onChange={handleReportSelect}
              isSearchable={false}
              placeholder={t('Site.SelectReport', { ns: 'organisms' })}
              isLoading={loadingReports}
              background={theme.action.filled}
            />
          </SelectWrapper>

          <Button
            label={t('Building.ExportPDF', { ns: 'organisms' })}
            onClick={handleExport}
            style={{ padding: '6px 12px' }}
            disabled={!selectedReport}
          />
        </RightAligned>
      </TitleRow>

      {loadingEsgReport &&
        <Loading />
      }


      {!loadingEsgReport && selectedReport && esgReport &&
        <EsgReport
          site={site}
          report={esgReport}
          inPrintMode={inPrintMode}
        />
      }

      <PrintCheck inPrintMode={inPrintMode} onPrintModeExit={handlePrintModeExit} />

    </PaddedContainer>
  )
}

export default SitePage_ESG

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  @media print {
    display: none;
  }
`;

const RightAligned = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SelectWrapper = styled.div`
  width: 171px;
`;