/* eslint-disable  @typescript-eslint/no-explicit-any */

import ReactSelect, { GroupBase, GroupHeadingProps, Props } from 'react-select';
import { useTheme } from 'styled-components';
import { ISelect } from './Select.types';

/**
 * react-select component wrapper
 * https://react-select.com/typescript#typescript-usage
 */
export const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: Props<Option, IsMulti, Group> & ISelect<Option, IsMulti, Group>) => {
  const globalTheme = useTheme();

  const getSelectBackground = (isDisabled: boolean, isReadOnly?: boolean, customBackground?: string) => {
    if (isReadOnly) {
      return 'transparent';
    }
    if (isDisabled) {
      return globalTheme.palette.forms.input.disabledBackground;
    }
    return customBackground ?? globalTheme.palette.forms.input.background;
  }

  const getSelectBoxShadow = (isFocused: boolean, isReadOnly?: boolean) => {
    if (isReadOnly) {
      return 'none';
    }

    return globalTheme.palette.forms.input.boxShadow;
  }

  const getOptionBackground = (isDisabled: boolean, isSelected: boolean) => {
    if (isDisabled) {
      return globalTheme.palette.backgrounds.surface;
    }

    if (isSelected) {
      return globalTheme.palette.backgrounds.surfaceStrong;
    }

    return globalTheme.palette.backgrounds.surface;
  }

  const getSelectedColor = (isDisabled: boolean, isReadOnly?: boolean) => {
    if (isReadOnly) {
      return globalTheme.text.secondary;
    }

    if (isDisabled) {
      return globalTheme.palette.forms.input.disabledText;
    }

    return globalTheme.text.secondary;
  }

  const getValueContainerPadding = (isReadOnly?: boolean, isSmall?: boolean) => {
    if (isReadOnly) {
      return '0px';
    }

    if (isSmall) {
      return '0 6px';
    }

    return '0 8px';
  }

  const customStyles = {
    // Select box element
    control: (styles: any, { isFocused, isDisabled }: any) => ({
      ...styles,
      height: props.small ? '30px' : '38px',
      minHeight: props.small ? '30px' : '38px',
      fontSize: '14px',
      fontWeight: 500,
      backgroundColor: getSelectBackground(isDisabled, props.readOnly, props.background),
      border: props.readOnly
        ? 'transparent'
        : `1px solid ${props.borderColor ?? globalTheme.palette.forms.input.border}`,
      cursor: 'pointer',
      boxShadow: props.boxShadow ?? getSelectBoxShadow(isFocused, props.readOnly),
      '&:hover': {
        borderColor: globalTheme.primary.outlinedBorder,
      }
    }),

    // Dropdown menu
    menu: (styles: any) => ({
      ...styles,
      fontSize: '14px',
      fontWeight: 400,
      backgroundColor: globalTheme.palette.backgrounds.surface,
      boxShadow: ` 0px 3px 8px 0px ${globalTheme.palette.shadows.medium}`,
      zIndex: 3,
      width: props.menuWidth ?? '100%' // width of dropdown
    }),

    menuList: (styles: any) => ({
      ...styles,
      maxHeight: props.menuHeight ?? 200 // max height of dropdown
    }),

    // Option in dropdown menu that can be selected.
    option: (styles: any, { isSelected, isDisabled }: any) => ({
      ...styles,
      cursor: 'pointer',
      padding: '8px 10px 8px 20px',
      color: isDisabled ? globalTheme.palette.text.weak : globalTheme.palette.text.fair,
      backgroundColor: getOptionBackground(isDisabled, isSelected),
      borderLeft: '2px solid transparent',
      borderColor: isSelected && globalTheme.palette.primary,
      '&:hover': {
        backgroundColor: globalTheme.palette.backgrounds.surfaceStrong,
        borderColor: globalTheme.palette.primary
      }
    }),

    // Text from user typing in select box when isSearchable is true.
    input: (styles: any) => ({
      ...styles,
      color: globalTheme.text.secondary,
      margin: '0px',
      padding: '0px'
    }),

    valueContainer: (styles: any) => ({
      ...styles,
      overflowY: 'auto !important',
      maxHeight: '94px',
      height: props.small ? '24px' : '38px',
      padding: getValueContainerPadding(props.readOnly, props.small),
    }),

    // Selected value displayed in select box
    singleValue: (styles: any, { isDisabled }: any) => ({
      ...styles,
      color: getSelectedColor(isDisabled, props.readOnly),
    }),

    // Selected multi-value displayed in select box
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: globalTheme.palette.backgrounds.surfaceStrong,
      border: `1px solid ${globalTheme.palette.borders.weak}`,
    }),

    multiValueLabel: (styles: any) => ({
      ...styles,
      color: globalTheme.palette.text.fair,
    }),

    multiValueRemove: (styles: any) => ({
      ...styles,
      color: globalTheme.palette.text.weak,

      '&:hover': {
        color: globalTheme.palette.text.fair,
        backgroundColor: globalTheme.palette.backgrounds.surfaceStrong,
      }
    }),

    placeholder: (styles: any) => ({
      ...styles,
      display: props.readOnly ? 'none' : 'unset',
      color: globalTheme.palette.text.weak,
      fontWeight: 400
    }),

    group: (styles: any) => ({
      ...styles,
      padding: '0px',
    }),

    groupHeading: (styles: any, { data }: GroupHeadingProps<Option, IsMulti, Group>) => ({
      ...styles,
      margin: 0,
      padding: data.label ? '8px 10px' : 0,
      fontSize: '12px',
      color: globalTheme.palette.text.weak
    }),

    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
      backgroundColor: globalTheme.palette.borders.weak,
    }),

    clearIndicator: (styles: any) => ({
      ...styles,
      color: globalTheme.action.active,
    }),

    dropdownIndicator: (styles: any) => ({
      ...styles,
      display: props.readOnly ? 'none' : 'unset',
      color: globalTheme.action.active,

      'svg': {
        width: 18,
        height: 18,
        marginTop: 1
      }
    }),

    indicatorsContainer: (styles: any) => ({
      ...styles,
      height: props.small ? '24px' : 'unset',
      paddingTop: props.small ? '12px' : 'unset'
    }),
  }

  return (
    <ReactSelect
      {...props}
      ref={props.innerRef}
      isDisabled={props.isDisabled ?? props.readOnly}
      styles={{ ...customStyles, ...props.customStyles }}
    />
  );
}