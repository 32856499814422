import { formatDistance } from 'date-fns';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type PropTypes = {
  lastMeasuredOn?: string
}

const MeasuredOn = ({ lastMeasuredOn }: PropTypes) => {
  const { t } = useTranslation(['molecules']);
  const measuredOnDistance = lastMeasuredOn && formatDistance(new Date(lastMeasuredOn), new Date(), { addSuffix: true });

  return (
    <>
      <MeasureOn>
        {t('Widgets.Measured', { ns: 'molecules' })} {measuredOnDistance}
      </MeasureOn>
    </>
  );
};

export default MeasuredOn;

const MeasureOn = styled.div`
  color: ${p => transparentize(0.2, p.theme.palette.text.weak)};
  font-size: 13px;

  margin: 0px 20px 10px auto;
`;