import AlertRulesGetBySiteQuery from '@settings/api/queries/AlertRules/AlertRulesGetBySiteQuery';
import { AlertRule, AlertRuleActionType, AlertRuleListItem } from '@shared/api/models/AlertRule/AlertRule';
import { useApiState } from '@shared/hooks/useApiState';
import { Title } from '@shared/components/atoms/Title/Title';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useSiteContext } from '../SitePage/SiteProvider';
import styled from 'styled-components';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { BreadcrumbHeight, TopBarHeight } from '@src/constants/LayoutConstants';
import { includesCI } from '@shared/utils/StringUtils';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { Table } from '@shared/components/molecules/Table/Table';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { ProtectedLink } from '@shared/components/navigation/ProtectedLink/ProtectedLink';
import { Button } from '@shared/components/atoms/Button/Button';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';
import { useTranslation } from 'react-i18next';
import AlertingRuleActionsV2 from '../../../apps/Settings/components/organisms/AlertRulesManagement/AlertRuleList/AlertListActionsV2';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import NoResultsError from '@dashboard/components/atoms/Errors/NoResultsError';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const AlertsRulesListPage = () => {
  const { t } = useTranslation(['settingsAlerting']);
  const navigate = useNavigate();
  const { site } = useSiteContext();
  const [searchString, setSearchString] = useState<string>('');
  const { data: alertingRules, loading, execute: refreshRules } = useApiState({
    query: new AlertRulesGetBySiteQuery(site.id),
  }, [site]);

  const searchFilter = useCallback((x: AlertRuleListItem) => {
    return includesCI(x.name ? x.name : '', searchString)
      || includesCI(x.lastUpdated ? x.lastUpdated : '', searchString)
      || includesCI(x.status ? x.status : '', searchString)
      || includesCI(x.updatedBy ? x.updatedBy : '', searchString);
  }, [searchString]);

  const alertRuleListItems: AlertRuleListItem[] | undefined = alertingRules?.map((rule: AlertRule) => {
    return {
      id: rule.id,
      name: rule.name,
      lastUpdated: rule.modifiedOn && format(new Date(rule.modifiedOn), "d MMM yyyy 'at' h:mmaaa"),
      updatedBy: rule.modifiedByName,
      status: rule.enabled ? 'Enabled' : 'Disabled',
      actions: rule.configuration.actions
        .map(action => action.type ?? AlertRuleActionType.Email)
    };
  });

  const findRule = useCallback((alertRuleListItem: AlertRuleListItem): AlertRule | undefined => {
    const rule = alertingRules?.find(x => x.id === alertRuleListItem.id);
    return rule;
  }, [alertingRules]);

  const createActions = useCallback((alertRuleListItem: AlertRuleListItem): ReactNode => {
    return (
      <AlertingRuleActionsV2
        rule={findRule(alertRuleListItem) ?? undefined}
        refreshRules={refreshRules}
      />
    )
  }, [refreshRules, findRule]);

  const createActionsColumnItem = useCallback((alertRuleListItem: AlertRuleListItem): ReactNode => {
    return (
      <>
        {createActions(alertRuleListItem)}
      </>
    )
  }, [createActions]);

  const tableColumns: ITableColumn<AlertRuleListItem>[] = useMemo(() => ([
    {
      label: 'Name',
      key: 'name',
      width: 4
    },
    {
      label: 'Last Updated',
      key: 'lastUpdated',
      width: 3
    },
    {
      label: 'Updated By',
      key: 'updatedBy',
      width: 2
    },
    {
      label: 'Status',
      key: 'status',
      width: 1
    },
    {
      customElement: (alertRuleListItem) => createActionsColumnItem(alertRuleListItem),
      width: 1,
      rightAlign: true
    }
  ]), [createActionsColumnItem]);

  return (
    <PaddedContainer>
      <BackButton
        label={t('BackToAlerts', { ns: 'navigation' })}
        url='./..'
      />

      <FlexRow>
        <Title
          size='lg'
          text={
            <>
              {'Alert Rules'} {<ThemeColoredSpan>({alertingRules?.length})</ThemeColoredSpan>}
            </>
          }
        />

        <InputWrapper>
          <SearchField
            placeholder={'Search'}
            onSearchChange={setSearchString}
            width='240px'
            style={{ marginRight: '15px' }}
          />

          <ProtectedLink link={{ path: 'rules/new', analytics: { action: 'alerts_list', category: 'alerts' } }}>
            <Button
              secondary
              label={'Create alert rule'}
              style={{ height: '39px' }}
            />
          </ProtectedLink>
        </InputWrapper>
      </FlexRow>

      {alertingRules?.length === 0 &&
        <NoResultsError noResultsError={{
          errorIcon: solid('pen-ruler'),
          errorTitle: t('Alerting.NoRulesExist', { ns: 'molecules' }),
          errorSubtitle: t('Alerting.CreateNewRule', { ns: 'molecules' })
        }} />
      }

      {alertingRules?.length !== 0 && alertRuleListItems &&
        <Table
          columns={tableColumns}
          records={alertRuleListItems}
          recordKey={'id'}
          defaultSortColumn={'lastUpdated'}
          emptyMessage={'No alerting rules'}
          highlightString={searchString}
          filters={[searchFilter]}
          cardEffect
          fullHeightSubtractor={TopBarHeight + BreadcrumbHeight + 220}
          loading={loading}
          onRowClick={(rule) => navigate(`../alerts/ruleslist/rules/${rule.id}`)}
        />
      }
    </PaddedContainer >
  )
}

export default AlertsRulesListPage

const FlexRow = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding-bottom: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ThemeColoredSpan = styled.span`
  color: ${p => p.theme.palette.primary};
`;

