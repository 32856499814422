import { Slide, toast, ToastContainer as Container } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastContainer = () => {
  return (
    <Container
      position={toast.POSITION.BOTTOM_RIGHT}
      autoClose={2000}
      transition={Slide}
      closeOnClick
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
    />
  );
};
