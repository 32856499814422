import { DeviceNote } from '@dashboard/api/models/DeviceNote';
import { DeviceNotesGetNotesByDeviceId } from '@dashboard/api/queries/deviceNotes/DeviceNotesGetNotesByDeviceId';
import SingleDeviceNote from '@dashboard/components/molecules/DeviceNotes/SingleDeviceNote';
import SingleDeviceNoteCreate from '@dashboard/components/molecules/DeviceNotes/SingleDeviceNoteCreate';
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeviceWithMetrics } from '@shared/api/models/Device/DeviceWithMetrics';
import { Button } from '@shared/components/atoms/Button/Button';
import LoadingWidget from '@shared/components/atoms/LoadingWidget/LoadingWidget';
import NoData from '@shared/components/atoms/NoData/NoData';
import { Title } from '@shared/components/atoms/Title/Title';
import { Card } from '@shared/components/molecules/Card/Card';
import { Paging } from '@shared/components/molecules/Table/Paging';
import { useApiState } from '@shared/hooks/useApiState';
import { sub } from 'date-fns';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const shouldUseModifedDate = (note: DeviceNote) => note && new Date(note.modifiedOn) > sub(new Date(), { years: 100 });

type PropTypes = {
  device: DeviceWithMetrics;
};

const DevicePage_Notes = ({ device }: PropTypes) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [createMode, setCreateMode] = useState(false);
  const { data: notes, execute: refreshNotes, loading } = useApiState({
    query: new DeviceNotesGetNotesByDeviceId(device.id),
    initialState: []
  }, [device]);

  const pageSizeOptions = useMemo(() => [
    { label: 'Show 5', value: 5 },
    { label: 'Show 10', value: 10 },
    { label: 'Show 20', value: 20 },
    { label: 'Show All', value: notes?.length ?? 5 }
  ], [notes]);

  /**
   * Sort by the modifiedOn date if that date is newer than "0001-01-01 00:00:00.000000" which is the default value
   * in the database if it has never been updated, otherwise sort by the createdOn date.
   */
  const sortedNotes = useMemo(() => orderBy(notes, x => shouldUseModifedDate(x) ? x.modifiedOn : x.createdOn, 'desc'), [notes]);

  const pagedNotes = useMemo(() => {
    const startIndex = page * pageSize;
    return sortedNotes.slice(startIndex, startIndex + pageSize);
  }, [sortedNotes, page, pageSize]);

  const component = useMemo(() => {
    if (loading) {
      return (
        <LoadingWidget
          label={t('LoadingDeviceNotes', { ns: 'status' })}
          icon={duotone('note-sticky')}
          styles={{ margin: '80px auto' }}
        />
      );
    }

    return (
      <>
        <List>
          {createMode &&
            <SingleDeviceNoteCreate
              deviceId={device.id}
              refreshNotes={refreshNotes}
              onCancel={() => setCreateMode(false)}
            />
          }

          {notes.length === 0 &&
            <NoData
              label={t('NoDeviceNotesFound', { ns: 'status' })}
              icon={duotone('note-sticky')}
              styles={{ margin: '80px auto' }}
            />
          }

          {pagedNotes.map(note => (
            <SingleDeviceNote
              key={note.id}
              note={note}
              refreshNotes={refreshNotes}
            />
          ))}
        </List>

        {notes.length > 0 &&
          <PagingWrapper>
            <Paging
              numItems={notes.length}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              pageSizeOptions={pageSizeOptions}
            />
          </PagingWrapper>
        }
      </>
    )
  }, [loading, notes, pagedNotes, pageSizeOptions, createMode, device, t, refreshNotes]);


  return (
    <>
      <FlexRow>
        <Title text={t('Notes', { ns: 'common' })} />

        <Button
          secondary
          label={<><FontAwesomeIcon icon={solid('plus')} />&nbsp;{t('Add', { ns: 'common' })}</>}
          onClick={() => setCreateMode(true)}
          style={{ marginLeft: 'auto' }}
        />
      </FlexRow>

      <Card>
        {component}
      </Card>
    </>
  );
};

export default DevicePage_Notes;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PagingWrapper = styled.div`
  width: fit-content;
  margin: 20px 0 0 auto;
`;