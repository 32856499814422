import styled from 'styled-components';

type PropTypes = {
  currentStep: number;
  numSteps: number;
  style?: React.CSSProperties;
}

const StepCount = ({ currentStep, numSteps, style }: PropTypes) => {
  const steps = Array.from(Array(numSteps).keys());

  return (
    <FlexRow style={style}>
      {steps.map((x, i) => (
        <Dot key={x} $dark={currentStep === i} />
      ))}
    </FlexRow>
  );
};

export default StepCount;

const FlexRow = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 6px;
`;

const Dot = styled.div<{ $dark: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${p => p.$dark ? p.theme.primary.dark : p.theme.primary.background};
`;