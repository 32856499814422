import Devices_ClimateControl from '@dashboard/components/molecules/DeviceLists/Devices_ClimateControl';
import Devices_Environmental from '@dashboard/components/molecules/DeviceLists/Devices_Environmental';
import Devices_Gateway from '@dashboard/components/molecules/DeviceLists/Devices_Gateway';
import Devices_Metering from '@dashboard/components/molecules/DeviceLists/Devices_Metering';
import Devices_Occupancy from '@dashboard/components/molecules/DeviceLists/Devices_Occupancy';
import Devices_Status from '@dashboard/components/molecules/DeviceLists/Devices_Status';
import BuildingPage_Devices from '@dashboard/components/organisms/BuildingPage/BuildingPage_Devices';
import BuildingDeviceList from '@settings/components/organisms/BuildingManagement/BuildingPage/Devices/BuildingDeviceList';
import BuildingEdit from '@settings/components/organisms/BuildingManagement/BuildingPage/Edit/BuildingEdit';
import BuildingEnergyMeters from '@settings/components/organisms/BuildingManagement/BuildingPage/EnergyMeters/BuildingEnergyMeters';
import EnergyMeterList from '@settings/components/organisms/BuildingManagement/BuildingPage/EnergyMeters/List/EnergyMeterList';
import EnergyMeterTopology from '@settings/components/organisms/BuildingManagement/BuildingPage/EnergyMeters/topology/EnergyMeterTopology';
import BuildingFloorList from '@settings/components/organisms/BuildingManagement/BuildingPage/Floors/BuildingFloorList';
import BuildingSpaces from '@settings/components/organisms/BuildingManagement/BuildingPage/Spaces/BuildingSpaces';
import BuildingSpaceList from '@settings/components/organisms/BuildingManagement/BuildingPage/Spaces/List/BuildingSpaceList';
import SpaceLocations from '@settings/components/organisms/BuildingManagement/BuildingPage/Spaces/Locations/SpaceLocations';
import { Route } from '@shared/types/Route';
import BuildingManagement from '@src/components/pages/BuildingPage/BuildingManagement/BuildingManagement';

export const BuildingManagementRoutes: Route[] = [
  {
    path: 'building/:buildingId/settings/*',
    element: BuildingManagement,
    analytics: { page: 'manage_building', category: 'buildings' },
    children: [
      {
        path: 'building',
        element: BuildingEdit,
        link: {
          label: 'Building',
          path: 'building'
        }
      },
      {
        path: 'floors/*',
        element: BuildingFloorList,
        link: {
          label: 'Floors',
          path: 'floors'
        }
      },
      {
        path: 'spaces/*',
        element: BuildingSpaces,
        link: {
          label: 'Spaces',
          path: 'spaces',
          showChildrenInDropdown: true
        },
        children: [
          {
            path: '',
            element: BuildingSpaceList,
            link: {
              label: 'List',
              path: 'spaces',
              end: true
            }
          },
          {
            path: 'locations',
            element: SpaceLocations,
            link: {
              label: 'Locations',
              path: 'spaces/locations'
            }
          },
        ]
      },
      {
        path: 'devices',
        element: BuildingPage_Devices,
        link: {
          label: 'Devices',
          path: 'devices'
        }
      },
      {
        path: 'devices/all',
        element: BuildingDeviceList,
      },
      {
        path: 'devices/environmental',
        element: Devices_Environmental,
      },
      {
        path: 'devices/metering',
        element: Devices_Metering,
      },
      {
        path: 'devices/occupancy',
        element: Devices_Occupancy,
      },
      {
        path: 'devices/status',
        element: Devices_Status,
      },
      {
        path: 'devices/climate-control',
        element: Devices_ClimateControl,
      },
      {
        path: 'devices/gateway',
        element: Devices_Gateway,
      },
      {
        path: '/energy-meters/topology',
        element: EnergyMeterTopology,
      },
      {
        path: 'energy-meters/*',
        element: BuildingEnergyMeters,
        link: {
          label: 'EnergyMeters',
          path: 'energy-meters',
          showChildrenInDropdown: true
        },
        children: [
          {
            path: '',
            element: EnergyMeterList,
            link: {
              label: 'List',
              path: 'energy-meters',
              end: true
            }
          },
          {
            path: 'topology',
            element: EnergyMeterTopology,
            link: {
              label: 'Topology',
              path: 'energy-meters/topology'
            }
          }
        ]
      }
    ]
  }
];