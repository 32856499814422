import styled from 'styled-components';
import coverPageOverlay from '@dashboard/assets/images/report-cover-page-overlay.png'
import coverPagePlaceholder from '@dashboard/assets/images/report-cover-page-placeholder.jpg'
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Site } from '@shared/api/models/Site/Site';
import { Building } from '@shared/api/models/Building/Building';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useFileFromBlobStorage } from '@shared/hooks/useFileFromBlobStorage';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';

interface IReportCoverPage {
  site: Site;
  building?: Building;
  reportDate: string;
}

const ReportCoverPage = ({ site, building, reportDate }: IReportCoverPage) => {
  const isSiteLevelReport = !building;

  const { t } = useTranslation(['molecules']);
  const { tenant } = useTenantContext();
  const { blobUri: tenantLogoBlobUri } = useFileFromBlobStorage(tenant.operatorLogoId, BlobStorageContainerType.Shared);

  const overviewImageId = (isSiteLevelReport || !building.buildingImageId) ? site.siteImageId : building.buildingImageId;
  const { blobUri: overviewImageBlobUri } = useFileFromBlobStorage(overviewImageId, BlobStorageContainerType.Tenant);

  const address = isSiteLevelReport ? site.address : building.address;

  return (
    <CoverPageWrapper>
      <OverviewImage src={overviewImageBlobUri ?? coverPagePlaceholder} />
      <CoverImage src={coverPageOverlay} />

      <CoverPageTextLeft>
        <CoverPageTitle>
          {t('ESG.EnvironmentalSocialGovernance', { ns: 'molecules' })}
        </CoverPageTitle>
        <CoverPageSubTitle>
          {format(new Date(reportDate), 'MMMM yyy')}
        </CoverPageSubTitle>
        <CoverPageDivider />
      </CoverPageTextLeft>

      <CoverPageTextRight>
        <CoverPageLogoImage src={tenantLogoBlobUri} />
        <CoverPageBuildingName>
          {isSiteLevelReport ? site.name : building.name}
        </CoverPageBuildingName>
  
        <CoverPageBuildingAddress>
          {address.addressLine1}
          {address.addressLine2 ? ', ' + address.addressLine2 : ''}
          <br />
          {address.city},&nbsp;
          {address.postCode}
        </CoverPageBuildingAddress>
        
      </CoverPageTextRight>
    </CoverPageWrapper>
  );
}

export default ReportCoverPage;

const CoverPageWrapper = styled.div`
  display: none;

  @media print {
    display: block;

    width: 210mm;
    height: 297mm;
    background-color: #fff;
    position: relative;
  }
`;

const CoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const OverviewImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 225mm;
  object-fit: cover;
  z-index: 1;
`;

const CoverPageTextLeft = styled.div`
  position: absolute;
  top: 210mm;
  left: 10mm;
  z-index: 3;
  width: 288px;
  height: 220px;
  overflow: hidden;
`;

const CoverPageTitle = styled.div`
  font-size: 22pt;
  line-height: 42px;
  font-weight: 500;
  color: #fff;
`;

const CoverPageSubTitle = styled.div`
  font-size: 12pt;
  font-weight: 400;
  color: #f1f2f4;
  margin-top: 15px;
  padding-bottom: 3px;
  border-bottom: 1px solid line;
`;

const CoverPageDivider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: linear-gradient(to right, #00b8f1, #007CF1);
`

const CoverPageTextRight = styled.div`
  position: absolute;
  top: 235mm;
  right: 20mm;
  z-index: 3;
  width: 190px;
  height: 170px;
  text-align: right;
  overflow: hidden;
  color: ${p => p.theme.palette.printStyles.text.medium};
`;

const CoverPageBuildingName = styled.div`
  font-size: 13pt;
  font-weight: 500;
`;

const CoverPageBuildingAddress = styled.div`
  font-size: 10pt;
  font-weight: 400;
`;

const CoverPageLogoImage = styled.img`
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 30px;
  margin-bottom: 25px;
`;
