/**
 * Default heatmap scale colors
 */
const DefaultScaleColors = {
  RED: '#fe6767',
  AMBER: '#FFA339',
  ORANGE: '#fe7b58',
  YELLOW: '#FFF008',
  LIGHTGREEN: '#b8e94a',
  MIDGREEN: '#4fe94a',
  DARKGREEN: '#0cc320',
  BLUE: '#2289eb',

  LIGHTTEXT: '#fff',
  DARKTEXT: '#525666'
};

export default Object.freeze(DefaultScaleColors);